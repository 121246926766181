import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './styles.scss'
import { MedicalScreening } from 'antidote-medical-screening';

export default class CEGASession extends Component {
  static propTypes = {
    index: PropTypes.number,
    dispatch: PropTypes.func,
    screening: PropTypes.object.isRequired,
    finishScreening: PropTypes.func.isRequired
  }

  render() {
    const { screening, finishScreening } = this.props

    return (
      <div className="screening-session-cega">
        <MedicalScreening
          url={screening.url}
          sessionId={screening.token}
          onFinish={finishScreening}
        />
      </div>
    )
  }
}
