import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormGroup, Col, ControlLabel } from 'react-bootstrap'
import SelectMenu from '../SelectMenu/SelectMenu'
import { applyFilter } from '../SelectMenu/SelectMenuRedux'

export const SELECT_IDENTIFIER = 'user'

class SelectMenuUser extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    label: PropTypes.string,
    labelSize: PropTypes.number,
    input: PropTypes.object.isRequired,
    infoButton: PropTypes.element,
    name: PropTypes.string,
    meta: PropTypes.object,
    labelKeys: PropTypes.array,
    object: PropTypes.bool,
    multi: PropTypes.bool,
    filters: PropTypes.array
  };

  componentWillReceiveProps(newProps) {
    if (newProps.organisation !== this.props.organisation) {
      this.props.dispatch(applyFilter(SELECT_IDENTIFIER, 'filter[organisation]', newProps.organisation))

      // clear any previously selected values
      this.props.input.onChange('')
    }
  }

  render() {
    const { input, infoButton, name, meta, label, labelSize, filters, object, multi, labelKeys, ...props } = this.props
    let source = 'users'
    let filterType = ''
    let filter = ''

    if (this.props.organisation) {
      filterType = 'filter[organisation]'
      filter = this.props.organisation
    }

    if (Array.isArray(filters)) {
      filters.map(filter => {
        source += (source.includes('?') ? '&' : '?') + filter.filter + '=' + filter.value
      })
    }

    return (
      <FormGroup controlId={name} className={((meta && meta.touched && meta.error) ? ' has-error col-sm-6' : 'col-sm-6')}>
        {label && (
          <Col componentClass={ControlLabel} sm={2}>
            { label } { infoButton }
          </Col>
        )}
        <Col sm={10}>
          <SelectMenu
            source={source}
            object={object}
            multi={multi}
            name={SELECT_IDENTIFIER}
            field={input}
            autoFilterType={filterType}
            autoFilter={filter}
            labelKeys={labelKeys}
            async={true}
            {...props}
          />

          {(meta && meta.touched && meta.error) && <span className="input-error">{meta.error}</span>}
        </Col>
      </FormGroup>
    )
  }
}

export default connect()(SelectMenuUser)
