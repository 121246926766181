import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box, DataTable } from '../../../../common/components'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { FormattedDate, FormattedTime } from 'react-intl'
import { refreshData } from '../../../../common/components/DataTable/redux/dataTableActions'
import getIncludedResource from '../../../../helpers/getIncludedResource'

export default class PolicyAudit extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    reference: PropTypes.string.isRequired
  };

  formatDate(cell) {
    return (
      <span>
           <FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/>
        </span>
    )
  }

  refreshList() {
    this.props.dispatch(refreshData('audit'))
  }

  render() {
    const {reference, resource} = this.props
    const premiums = getIncludedResource(resource.data, resource.included, 'premiums')

    return (
      <div>
        <Box>
          <h2 className="resource-name">{reference}</h2>

          <DataTable
            source={'/events'}
            autoFilters={[
              {type: 'filter[resource]', value: this.props.resource.data.id},
              {type: 'related', value: true}
            ]}
            name="audit"
            showTotal={true}>

            <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
              ID
            </TableHeaderColumn>

            <TableHeaderColumn dataField="occurred_at" dataFormat={this.formatDate} width={'180'} dataSort={true}>
              Occurred at
            </TableHeaderColumn>

            <TableHeaderColumn dataField="action" width={'280'} dataSort={true}>
              Action
            </TableHeaderColumn>

            <TableHeaderColumn dataField="description" width={'450'} dataSort={true}>
              Description
            </TableHeaderColumn>

            <TableHeaderColumn dataField="user_name">
              User
            </TableHeaderColumn>

          </DataTable>
        </Box>
      </div>
    )
  }
}
