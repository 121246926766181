import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { Button, Modal, DatePicker } from '../../../../common/components'
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux'
import HorizontalFormControl from '../../../../common/components/HorizontalFormControl/HorizontalFormControl'
import { activateScheme } from '../../redux/schemeActions'
import _ from 'lodash'
import './styles.scss'
import moment from 'moment'

const FORM_NAME = 'activateSchemeForm'

const validate = values => {
  const errors = {}

  if (!_.get(values, 'meta.date')) {
    _.set(errors, 'meta.date', 'Start date is required')
  }
  return errors
}

class ActivateSchemeButton extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    callback: PropTypes.func,
    label: PropTypes.string
  };

  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded, callback } = this.props
    if (newProps.submitSucceeded && !submitSucceeded) {
      if (callback) {
        callback('')
      }
      dispatch(closeModal(FORM_NAME))
    }
  }

  handleSubmit(values) {
    return this.props.dispatch(activateScheme(values, this.props.resource))
  }

  handleOpen() {
    const { dispatch, reset } = this.props
    dispatch(reset())
    dispatch(openModal(FORM_NAME))
  }

  getMinTime() {
    const {  formValues } = this.props;
    const formDate = moment(formValues).startOf('day');
    const currentTime = moment().startOf('day');

    if (formDate.isSame(currentTime)) {
      return moment().startOf('minute').toDate();
    } else {
      return moment().startOf('day').toDate();
    }


  }

  render() {
    const { label, handleSubmit, submitting, pristine, formValues } = this.props
    const roundDownToClosestMin = value => value && moment(value).startOf('minute');
    const minimumDate = moment().add(1, 'd');
    const submitButton = (
      <Button
        bsStyle="primary"
        disabled={submitting || pristine}
        isLoading={submitting}
        label="Activate"
        handleClick={handleSubmit(::this.handleSubmit)}
      />)

    return (
        <div className={'activate-scheme-btn-wrapper ' + this.props.className}>
          <Button
              bsStyle="primary"
              label={label}
              isLoading={submitting}
              rightIcon="check"
              handleClick={::this.handleOpen}
          />

          <Modal
              name={FORM_NAME}
              title={label}
              close={true}
              footer={submitButton}
          >
            <div className="form-horizontal">
              <div className="col-xs-12">
                <p><strong>Please note:</strong> once the scheme has been
                  made active it will no longer be available for editing.</p>

                <Field
                    name={'meta.date'}
                    label="Start date"
                    labelSize={3}
                    minDate={minimumDate}
                    component={DatePicker}
                    showTime={false}
                    normalize={roundDownToClosestMin}
                />
              </div>
            </div>
            <div className="form-horizontal">
              <div className="col-xs-12">
                <p><strong>Please note:</strong>
                  <ul>
                    <li>Initial policy number will only be
                      reset if prefix has been changed.
                    </li>
                    <li>
                      Number must be at least 6 digits long.
                    </li>
                  </ul>
                  </p>
                <Field
                    name={'meta.start_value'}
                    label="Starting Policy Number"
                    labelSize={3}
                    component={HorizontalFormControl}
                />
              </div>
            </div>
          </Modal>
        </div>
    )
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(ActivateSchemeButton)
const selector = formValueSelector(FORM_NAME)
const mapStateToProps = (state, props) => {
  const values = selector(state,
    'meta.date',
  )

  return {
    initialValues: props.resource,
    formValues: values
  }
}

export default connect(mapStateToProps)(form)
