import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Box } from '../../../common/components'
import InternalContainer from '../../layout/containers/Internal'
import ProductDetails from '../components/ProductDetails'
import getIncludedResource from '../../../helpers/getIncludedResource'
import { clearData } from '../redux/productActions'
import { get, remove, sortBy } from 'lodash'


export class ProductListContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
  };

  /**
   * Clean out the data when the component unmounts
   */
  componentWillUnmount() {
    this.props.dispatch(clearData())
  }

  render() {
    const { auth } = this.props
    const products = sortBy(getIncludedResource(auth.user.data, auth.user.included, 'available_products'), 'attributes.name')

    return (
      <InternalContainer title="Products">
        <Box>
          {products.length ? products.map((product, i) => {
            const insurers = []
            const schemes = getIncludedResource(product, auth.user.included, 'active_schemes')
            schemes.length > 0 && schemes.map(scheme => {
              const insurer = getIncludedResource(scheme, auth.user.included, 'insurer')
              let documents = getIncludedResource(scheme, auth.user.included, 'documents')
              remove(documents, (document) => get(document, 'attributes.short_name', '') === 'Information')
              const logo = get(scheme, 'attributes.logo')

              insurers[insurer.data.id] = {
                insurer,
                documents,
                logo,
                claims_handlers: get(scheme, 'attributes.metadata.claims_handlers', []),
                assistance_handlers: get(scheme, 'attributes.metadata.assistance_handlers', [])
              }
            })

            if (!get(product, 'attributes.metadata.isActive', false)) {
              return;
            }
            return (
              <ProductDetails key={i} product={product} insurers={insurers}/>
            )
          }) : (
            <div>
              <h2>No Products Available</h2>
            </div>
          )}
        </Box>
      </InternalContainer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    product: state.product,
    auth: state.auth
  }
}

export default connect(mapStateToProps)(ProductListContainer)

