import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box, DataTable } from '../../../../common/components'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { push } from 'react-router-redux'
import { FormattedDate, FormattedTime } from 'react-intl'

export default class OrganisationBrokers extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired
  };

  formatDate(cell, row) {
    return (
      <span><FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/></span>
    )
  }

  handleRowClick(row) {
    this.props.dispatch(push('/brokers/' + row.id))
  }

  render() {
    const resource = this.props.resource.data.attributes

    return (
      <div>
        <Box>
          <h2 className="resource-name">{resource.name}</h2>
          <DataTable
            source="/brokers"
            name="brokers"
            autoFilter={this.props.resource.data.id}
            autoFilterType="filter[organisation]"
            showTotal={true}
            onRowSelect={this.handleRowClick.bind(this)}>

            <TableHeaderColumn dataField="id" isKey={true} hidden={true}>ID</TableHeaderColumn>
            <TableHeaderColumn dataField="name" width={'380px'}>Name</TableHeaderColumn>
            <TableHeaderColumn dataField="branch_name" width={'200px'}>Branch</TableHeaderColumn>
            <TableHeaderColumn dataField="status" dataFormat={this.brokerStatus} dataSort={true}
                               width={'100px'}>Status</TableHeaderColumn>
            <TableHeaderColumn dataField="created_at" dataFormat={this.formatDate} width={'180px'} dataSort={true}>Created</TableHeaderColumn>
          </DataTable>
        </Box>
      </div>
    )
  }
}
