import React from 'react'
import PropTypes from 'prop-types'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import './Tooltip.scss'

const Tooltip = ({ content, placement }) => {

  const popover = (<Popover id={'popover-' + content}>{ content }</Popover>)

  return (
      <OverlayTrigger trigger="click" placement={ placement ? placement : 'right' } overlay={ popover }>
        <span className="tooltip-trigger">?</span>
      </OverlayTrigger>
  )
}

Tooltip.propTypes = {
  content: PropTypes.string.isRequired,
  placement: PropTypes.string
}

export default Tooltip
