import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Modal } from '../../components'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { openModal, closeModal } from '../Modal/ModalRedux'
import AvatarEditor from 'react-avatar-editor'
import Slider from 'react-rangeslider'
import axios from 'axios'
import { resetFileUploadTrigger } from './UploadRedux'

export class UploadCrop extends Component {
  static MODAL_IDENTIFIER = 'upload-crop';

  static initialState = {
    file: null,
    image: null,
    scale: 1.2,
    borderRadius: 360,
    preview: null,
    submitting: false,
  };

  constructor() {
    super()

    this.state = {
      ...UploadCrop.initialState
    }
  }

  handleOnHide() {
    const { actions } = this.props

    this.setState({
      ...UploadCrop.initialState
    })
  }

  componentWillUpdate(nextProps, nextState) {
    const { actions, triggerUpload } = this.props
    const { file } = this.state

    nextState.borderRadius = nextProps.circle ? 360 : 0

    if (!file && nextState.file) {
      actions.openModal(UploadCrop.MODAL_IDENTIFIER)
    }

    if (!triggerUpload && nextProps.triggerUpload) {
      this.refs.file.click()
      actions.resetFileUploadTrigger()
    }
  }

  handleFileChange(event) {
    const file = event.target.files[0]

    this.setFile(file)
  }

  setFile(file) {
    const reader = new FileReader()

    reader.onload = (e) => {
      this.setState({
        file,
        image: e.target.result
      })
    }

    reader.readAsDataURL(file)
  }

  dataURItoBlob(dataURI) {
    const binary = atob(dataURI.split(',')[1])
    const array = []
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i))
    }
    return new Blob([new Uint8Array(array)], { type: 'image/png' })
  }

  uploadImage() {
    const { onComplete, actions } = this.props

    var img = this.refs.avatar.getImageScaledToCanvas().toDataURL()
    this.setState({ preview: img, submitting: true })

    const data = new FormData()
    data.append('file', this.dataURItoBlob(img))

    const request = axios
        .post('/files', data)
        .then(response => {
          onComplete(response.data)
          actions.closeModal(UploadCrop.MODAL_IDENTIFIER)
          this.handleOnHide()
        })
        .catch(error => {
          this.setState({ submitting: false })
        })

  }

  renderFooter() {
    const { submitting } = this.state

    return (
        <Button
            bsStyle="primary"
            className="pull-right"
            disabled={submitting}
            handleClick={::this.uploadImage}>
          {submitting ? <i className="fa fa-cog fa-spin"/> : ('')} upload
        </Button>
    )
  }

  render() {
    const { actions, bsSize, height, width, minScale } = this.props

    const hidden = {
      display: 'none'
    }

    const avatarStyle = {
      display: 'block',
      margin: '0 auto'
    }

    return (
        <div>
          <Modal
              name={UploadCrop.MODAL_IDENTIFIER}
              title="Upload Image"
              close={true}
              footer={this.renderFooter()}
              onHide={::this.handleOnHide}
              backdrop="static"
              bsSize={bsSize}
          >
            <AvatarEditor
                ref="avatar"
                scale={parseFloat(this.state.scale)}
                borderRadius={this.state.borderRadius}
                onDropFile={::this.setFile}
                image={this.state.image}
                style={avatarStyle}
                width={width}
                height={height}
            />

            <Slider
                value={this.state.scale * 100}
                step={1}
                onChange={(value) => this.setState({ scale: value / 100 })}
                min={minScale ? minScale : 100}
                max={300}
            />
          </Modal>

          <input
              type="file"
              ref="file"
              style={hidden}
              onChange={::this.handleFileChange}
              accept="image/*"
          />
        </div>
    )
  }

  handleScale() {
    var scale = parseFloat(this.refs.scale.value)
    this.setState({ scale: scale })
  }

  handleBorderRadius() {
    var borderRadius = parseInt(this.refs.borderRadius.value)
    this.setState({ borderRadius: borderRadius })
  }
}

function mapStateToProps(state) {
  return {
    triggerUpload: state.upload.triggerUpload,
    modals: state.modal.modals
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators({ openModal, closeModal, resetFileUploadTrigger }, dispatch) }
}

export default connect(mapStateToProps, mapDispatchToProps)(UploadCrop)
