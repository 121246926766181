import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ButtonGroup, Button } from 'react-bootstrap'
import { fetchBrokerStats } from '../../redux/dashboardActions'
import { Box, BarChart } from '../../../../common/components'
import _ from 'lodash'
import './styles.scss'

class StarBrokerStats extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    dashboard: PropTypes.object.isRequired,
  };

  state = {
    range: 'day'
  }

  componentWillMount() {
    this.props.dispatch(fetchBrokerStats(this.state.range))
  }

  setRange(range) {
    this.setState({ range }, () => {
      this.props.dispatch(fetchBrokerStats(range))
    })
  }

  render() {
    const { dashboard } = this.props

    const stats = _.get(dashboard.broker_stats, 'meta[0].broker', null)

    if (!stats) return null;

    return (
      <Box className="broker-activity">
        <div className="options pull-right">
          <ButtonGroup bsSize="small">
            <Button bsStyle={this.state.range == 'day' ? 'primary' : 'default'}
                    onClick={() => this.setRange('day')}>Day</Button>
            <Button bsStyle={this.state.range == 'week' ? 'primary' : 'default'}
                    onClick={() => this.setRange('week')}>Week</Button>
            <Button bsStyle={this.state.range == 'month' ? 'primary' : 'default'}
                    onClick={() => this.setRange('month')}>Month</Button>
          </ButtonGroup>
        </div>
        <h4>Top Broker Activity - {stats.name}</h4>
        {stats ? (
          <div>
            <BarChart data={stats.historical}/>
          </div>
      ) : (
          <h4>No broker activity for the given period</h4>
      )}
      </Box>
    )
  }
}

function mapStateToProps(state) {
  return {
    dashboard: state.dashboard
  }
}

export default connect(mapStateToProps)(StarBrokerStats)
