import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import {
  HorizontalFormControl,
  NameFields,
  PhoneFormControl,
  DatePicker,
  Button,
  Icon,
} from '../../../../common/components';
import { AddressFields } from '../../../../common/components/ReduxFormField';
import PostcodeList from '../../../../common/components/Postcode/PostcodeList';
import moment from 'moment';
import 'moment/locale/en-gb';
import * as _ from 'lodash';
import './styles.scss';
import { isOwner } from '../../../auth/redux/authActions';

const travellerDefaults = {
  title: '',
  first_name: '',
  last_name: '',
  dob: '',
  email: '',
  phone1: '',
  phone2: '',
  conditions_covered: true,
  screening: '',
  screening_id: '',
};

export default class Travellers extends Component {

  static propTypes = {
    fields: PropTypes.object.isRequired,
    formValues: PropTypes.array,
    travellerGroup: PropTypes.string,
    displayGroups: PropTypes.array,
    child: PropTypes.bool,
    label: PropTypes.string,
    buttonLabel: PropTypes.string,
    leadAddress: PropTypes.bool,
    leadContact: PropTypes.bool,
    change: PropTypes.func,
    noTravellers: PropTypes.object,
  };

  componentWillReceiveProps(props) {
    const { fields, formValues, travellerGroup, child } = props;
    if (travellerGroup !== this.props.travellerGroup) {
      fields.removeAll();

      if (!child) {
        fields.push(formValues ? formValues[0] : travellerDefaults);

        if (travellerGroup === 'couple') {
          fields.push(formValues && formValues.length >= 2 ? formValues[1] : travellerDefaults);
        } else if (travellerGroup === 'family') {
          if (formValues && formValues.length >= 2) {
            fields.push(formValues[1]);
          }
        } else if (travellerGroup === 'group') {
          if (formValues && formValues.length > 0) {
            formValues.forEach((formValue, i) => {
              if (i > 0) fields.push(formValue);
            });
          } else {
            for (let i = 0; i < 4; i++) {
              fields.push(travellerDefaults);
            }
          }
        }
      } else {
        if (travellerGroup === 'family') {
          if (formValues && formValues.length > 0) {
            fields.push(formValues ? formValues[0] : travellerDefaults);
            formValues.forEach((formValue, i) => {
              if (i > 0) fields.push(formValue);
            });
          }
        }
      }
    }
  }


  render() {
    const { fields, leadAddress, formValues, child, label, buttonLabel, travellerGroup, noTravellers, displayGroups, extendedFamily, childrenMaxAge, cloned, currentProduct } = this.props;
    let showButton = true;

    if (noTravellers) {
      if (travellerGroup === 'individual' || travellerGroup === 'couple') {
        showButton = false;
      }

      if (travellerGroup === 'family') {
        showButton = (!child && noTravellers.adults < (extendedFamily ? 4 : 2)) || (child && noTravellers.children > 0);
      }

      if (travellerGroup === 'group' && child) {
        // const maxChildren = isOwner() ? 10 : 5;
        const maxChildren = 10;
        showButton = fields.length > 0 && noTravellers.children < maxChildren;
      }

      if (_.get(currentProduct, 'data.attributes.product_code') === 'BST') {
        showButton = true;
      }
    }

    // Hide if only set to display for specific groups
    if (displayGroups && displayGroups.indexOf(travellerGroup) === -1) {
      return (
        <div/>
      );
    }

    return (
      <div className="travellers">
        {(child && !fields.length && _.get(currentProduct, 'data.attributes.product_code') !== 'BST') && (
          <div className="row">
            <div className="col-xs-12 m-b-10">
              <strong>
                Please add any dependent children under 18 or under {childrenMaxAge} if they are in full time education
              </strong>
            </div>
            <div className="col-xs-12">
              <Button bsStyle="primary" size="sm"
                      label='Add a Child'
                      handleClick={() => fields.push(travellerDefaults)}/>
            </div>
          </div>
        )}
        <div className="row">
          {fields.map((insured, index) => {
            return (
              <div key={index} className={'traveller-row col-xs-12 traveller' + index + (child ? ' child' : '')}>
                {!index && !child && !label ? (
                  <h4><strong>Lead Traveller</strong></h4>
                ) : (
                  <div>
                    <span className="remove-traveller pull-right" onClick={() => fields.remove(index)}>
                      <Icon name="times"/>
                    </span>
                    <h4>{label ? label : (child ? 'Child' : 'Adult')} {(index + 1)}</h4>
                  </div>
                )}
                <NameFields
                  baseName={insured}
                  label="Name"
                  labelSize={2}
                  fieldComponent={Field}
                  child={child}
                  readOnly={!index && cloned}
                />
                <div className="form-group">
                  <div className="col-xs-12">
                    <Field
                      name={insured + '.dob'}
                      label="Date of birth"
                      showYear={true}
                      labelSize={2}
                      minDate={moment().subtract(child ? childrenMaxAge : 100, 'years').add(1, 'days').format()}
                      maxDate={child ? moment().format() : moment().subtract(18, 'years').format()}
                      component={DatePicker}
                      readOnly={!index && cloned}
                    />
                  </div>
                </div>

                {!child && !index && (
                  <div className="col-xs-12">
                    <div className="form-group">
                      <div className="address-group row">
                        <div className="col-xs-12">
                          <Field
                            name={insured + '.email'}
                            label="Email"
                            type="email"
                            labelSize={2}
                            mdFieldSize={7}
                            component={HorizontalFormControl}
                            randomMaxWidth={true}
                          />
                        </div>
                        <div className="col-xs-12">
                          <Field
                            name={insured + '.phone1'}
                            label="Phone 1"
                            labelSize={2}
                            placeholder="Mobile"
                            mdFieldSize={7}
                            component={PhoneFormControl}
                            type="mobile"
                          />
                        </div>
                        <div className="col-xs-12">
                          <Field
                            name={insured + '.phone2'}
                            label="Phone 2"
                            labelSize={2}
                            placeholder="Landline"
                            mdFieldSize={7}
                            component={PhoneFormControl}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {(!index && leadAddress) && (
                  <div className="row form-group">
                    <div className="col-xs-12">
                      <PostcodeList
                        name={insured + '.address.postcode'}
                        label="Postcode"
                        change={this.props.change}
                        labelSize={2}
                        value={_.get(formValues, '[' + index + '].address.postcode', '')}
                        targetFields={{
                          line1: insured + '.address.line1',
                          line2: insured + '.address.line2',
                          line3: insured + '.address.line3',
                          town: insured + '.address.town',
                          county: insured + '.address.county',
                          postcode: insured + '.address.postcode',
                        }}
                      />
                    </div>
                    <div className="col-xs-12">
                      <AddressFields
                        baseName={insured + 'address'}
                        label="Address"
                        labelSize={2}
                        mdFieldSize={7}
                      />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
        {showButton && (
          <div className="travellers row">
            <div className="col-xs-12">
              <Button bsStyle="primary" size="sm"
                      label={buttonLabel ? buttonLabel : 'Add ' + (child ? 'a Child' : 'an Adult')}
                      handleClick={() => fields.push(travellerDefaults)}/>
            </div>
          </div>
        )}
      </div>
    );
  }
}
