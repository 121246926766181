import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { RadioGroup, CheckboxGroup } from '../../../../common/components/ReduxFormField'
import { HorizontalFormControl, Button, Modal, InfoButton, DatePicker } from '../../../../common/components'
import { openModal } from '../../../../common/components/Modal/ModalRedux'
import { issuePolicy, saveQuotes, patchQuotes } from '../../redux/productActions'
import displayCurrency from '../../../../helpers/displayCurrency'
import getIncludedResource from '../../../../helpers/getIncludedResource'
import { hasPermission } from '../../../auth/redux/authActions'
import * as productActions from '../../redux/productActions'
import Markdown from 'react-commonmark'
import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/en-gb'
import './styles.scss'
import axios from 'axios'
import ExpiryDateFields from '../../../../common/components/ReduxFormField/ExpiryDateFields';

const FORM_NAME = 'issueForm'

const validate = values => {
  const errors = {}

  if (!_.get(values, 'meta.purchase_declaration', false)) {
    _.set(errors, 'meta.purchase_declaration', 'Required')
  }

  if (_.get(values, 'meta.product_code') !== 'ETIR') {
    if (!_.get(values, 'meta.start_date', false)) {
      _.set(errors, 'meta.start_date', 'Required')
    }
  }

  if (parseFloat(_.get(values, 'meta.amount')) != 0.00 && !_.get(values, 'meta.payment_type', false)) {
    _.set(errors, 'meta.payment_type', 'Required')
  }

  if (_.get(values, 'meta.payment_type', false) === 'card') {
    if (!_.get(values, 'meta.card_number', false)) {
      _.set(errors, 'meta.card_number', 'Required')
    }
    if (!_.get(values, 'meta.cvv', false)) {
      _.set(errors, 'meta.cvv', 'Required')
    }
    if (!_.get(values, 'meta.expiry_month', false)) {
      _.set(errors, 'meta.expiry_month', 'Required')
    }
    if (!_.get(values, 'meta.expiry_year', false)) {
      _.set(errors, 'meta.expiry_year', 'Required')
    }
  }

  if (_.get(values, 'meta.payment_type', false) === 'recharge') {
    if (!_.get(values, 'meta.transaction_id', false)) {
      _.set(errors, 'meta.transaction_id', 'Required')
    }
    if (!_.get(values, 'meta.cvv', false)) {
      _.set(errors, 'meta.cvv', 'Required')
    }
  }

  return errors
}

class IssueButton extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    schemeId: PropTypes.string.isRequired,
    mta: PropTypes.bool,
    policyId: PropTypes.string,
    application: PropTypes.object,
    product: PropTypes.object,
    saveApplication: PropTypes.func,
    declaration: PropTypes.object,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.string,
    bsStyle: PropTypes.string,
    block: PropTypes.bool
  };

  constructor(props) {
    super(props)

    this.state = {
      premium: null,
      currency: null,
      loading: false,
      application: null,
      applicationErrors: []
    }

    this.setPremium = this.setPremium.bind(this)
    this.handleWindowMessage = this.handleWindowMessage.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.transformDocLinks = this.transformDocLinks.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
  }

  setPremium(application, openAfter = true, errors = []) {
    const { dispatch, change, mta, product,formValues } = this.props
    let schemeId = this.props.schemeId

    if (application) {
      const premiums = getIncludedResource(application.data, application.included, 'premiums')
      const customer = getIncludedResource(application.data, application.included, 'customer')
      const validationErrors = application.data.attributes.validation
      let selectedPremium = null

      // Populate dates
      if (_.get(product, 'data.attributes.metadata.adjustable_end_date', false)) {
        const schemes = getIncludedResource(application.data, application.included, 'schemes')
        if (schemes && schemes.length && schemes[0].attributes.to_date) {
          this.setState({
            maxAdjustableStartDate: schemes[0].attributes.to_date,
          });
        }
        if (_.get(application, 'data.attributes.ends_at')) {
          dispatch(change('meta.start_date', application.data.attributes.starts_at))
          dispatch(change('meta.end_date', application.data.attributes.ends_at))
        } else {
          const startDate = moment().format()
          dispatch(change('meta.start_date', startDate))
          dispatch(change('meta.end_date', moment(startDate).add(1, 'years').subtract(1, 'days').format()))
        }
      }

      if (premiums.length) {
        if (premiums.length === 1) {
          selectedPremium = premiums[0]
          dispatch(change('data.relationships.premium.data.id', premiums[0].id))
          dispatch(change('meta.amount', premiums[0].attributes.gross))
        } else {
          premiums.map(premium => {
            if (_.get(premium, 'attributes.scheme.id') === schemeId
              || _.get(premium, 'attributes.premium_type') === 'return premium') {
              selectedPremium = premium
              dispatch(change('data.relationships.premium.data.id', premium.id))
              dispatch(change('meta.amount', premium.attributes.gross))
            }
          })
        }
      }

      if (customer) {
        dispatch(change('meta.sms_number', customer.data.attributes.phone1))
      }

      if (validationErrors) {
        Object.keys(validationErrors).map((errorKey) => {
          if (!validationErrors[errorKey]) {
            errors.push(errorKey + ' required')
          }
        })
      }

      this.setState({
        premium: selectedPremium,
        currency: _.get(selectedPremium, 'attributes.currency'),
        loading: false,
        application
      })
    }

    this.setState({ loading: false, applicationErrors: errors })

    if (openAfter) {
      dispatch(openModal(FORM_NAME + schemeId))
    }
  }

  handleSubmit(values) {
    if (!this.state.application || !this.state.application.data.id) {
      return false
    }
    return this.props.dispatch(issuePolicy(this.state.application.data.id, values))
  }

  handleOpen() {
    const { dispatch, reset, saveApplication, product, mta, policyId, application } = this.props

    // dispatch(reset())

    if (saveApplication) {
      this.setState({ loading: true, premium: null, errors: [] })
      const applicationId = this.state.application ? this.state.application.data.id : null

      return dispatch(saveApplication((values) => {
        if (mta && policyId && ! _.get(values, 'data.relationships.policy.data.id')) {
          _.set(values, 'data.relationships.policy.data.id', policyId)
        }

        if (applicationId) {
          _.set(values, 'data.id', applicationId)
        }

        dispatch(applicationId ? patchQuotes(values, this.setPremium) : saveQuotes(values, product, this.setPremium))
      }))
    } else {
      this.setPremium(application)
    }
  }

  transformDocLinks(url) {
    const { schemeId, productState: { quotes }, application } = this.props

    let documents = {}
    if (application) {
      const applicationDocuments = getIncludedResource(application.data, application.included, 'documents')

      applicationDocuments.forEach((document) => {
        documents[document.attributes.short_name] = document.attributes.file_path
      })
    } else {
      const currentQuoteId = Object.keys(quotes).filter(quoteId => {
        return _.get(quotes[quoteId], 'meta.scheme.id') === schemeId
      })

      documents = _.get(quotes[currentQuoteId], 'meta.scheme.documents')
    }

    if (url === 'policy_wording') {
      return _.get(documents, 'Policy Wording', url)
    } else if (url === 'policy_summary') {
      return _.get(documents, 'Policy Summary', url)
    } else if (url === 'tobca') {
      return _.get(documents, 'Terms of Business Customer Agreement', url)
    } else if (url === 'ipid') {
      return _.get(documents, 'Insurance Product Information Document', url)
    }

    return url
  }

  componentDidMount () {
    if (process.env.BROWSER) {
      window.addEventListener('message', this.handleWindowMessage)
    }
  }

  componentWillUnmount() {
    const { dispatch, product } = this.props

    if (process.env.BROWSER) {
      window.removeEventListener('message', this.handleWindowMessage)
    }

    if (product.isCompletingTransaction) {
      dispatch(productActions.transactionEmergencyBrakes())
    }
  }

  handleWindowMessage(event)  {
    const { dispatch } = this.props

    // For Chrome, the origin property is in the event.originalEvent object.
    const origin = event.origin || event.originalEvent.origin

    if (origin !== axios.defaults.baseURL) {
      return
    }

    const data = event.data

    if (data.status !== 'complete') {
      dispatch(productActions.failTransaction(data.message))
      return
    }

    dispatch(productActions.completeTransaction(data.policies[0].policy_id))
  }

  render() {
    const { schemeId, label, block, bsStyle, disabled, submitting, handleSubmit, productState } = this.props
    const title = label || 'Issue Policy'

    let isValid = false
    let submitButton = (<div/>)

    if (_.get(this.state, 'application.data.attributes.valid')) {
      isValid = true
      submitButton = (<Button
        type="submit"
        bsStyle="primary"
        isLoading={submitting}
        handleClick={handleSubmit(this.handleSubmit)}
        label="Issue Policy"
      />)
    }

    return (
      <div className="issue-btn-wrapper">
        <Button
          className={this.props.className}
          block={block}
          rightIcon="check-square-o"
          label={title}
          bsStyle={bsStyle || 'primary'}
          disabled={this.state.loading ? this.state.loading : disabled}
          isLoading={this.state.loading}
          handleClick={this.handleOpen}
        />

        <Modal
          name={FORM_NAME + schemeId}
          title={title}
          close={! productState.isCompletingTransaction}
          footer={productState.isCompletingTransaction ? null: submitButton}
          bsSize="lg"
        >
          {productState.isCompletingTransaction ?
            this.renderCompleteTransactionFrame()
            : this.renderForm(isValid)}
        </Modal>
      </div>
    )
  }

  renderCompleteTransactionFrame() {
    const { productState } = this.props

    const url = axios.defaults.baseURL + '/public/transactions/' + productState.transactionId + ':pay'

    return (
      <div className="payment__container">
        <iframe src={url} className="payment__iframe" />
      </div>
    )
  }

  renderForm(isValid) {
    const { formValues, handleSubmit, declaration, product } = this.props
    const taxType = product.data.attributes.metadata.tax_type
    const selectedPremium = this.state.premium
    const currency = this.state.currency

    const paymentInfo = '**Broker Account** \n\nWill place the premium on your account with Arch, with settlement due to Arch within 30 days. \n\n**Card** \n\nAllows your client to settle premium directly to Arch by credit/debit card. Your commissions will be paid by Arch on a monthly basis.'
    const cardPayments = _.get(product, 'data.attributes.metadata.card_payments', true)


    const paymentOptions = [{ label: 'Broker Account', value: 'account' }]
    if (cardPayments) {
      if (selectedPremium && selectedPremium.attributes.gross > 0) {
        paymentOptions.push({ label: 'Card', value: 'card' })
      }
    }


    const luhn = require('luhn');
    const isLuhnHappy = () => (value) => {
      const val = value && value.replace(/\s/g, '');

      return luhn.validate(val) ? '' : 'This does not look like a valid card number';
    };

    const adjustableEndDate = _.get(product, 'data.attributes.metadata.adjustable_end_date', false);
    let maxAdjustableStartDate = null;
    if (adjustableEndDate) {
      maxAdjustableStartDate = moment().add(90, 'days').format();
      if (this.state.maxAdjustableStartDate) {
        maxAdjustableStartDate = this.state.maxAdjustableStartDate;
      }
    }

    return (
      <form onSubmit={handleSubmit(this.handleSubmit)}>
        <div className="row issue-btn">
          {!isValid && (
            <div className="col-xs-12">
              <h4>Not ready for issuing</h4>
              {this.state.applicationErrors.length > 0 && (
                <div>
                  <p>Please resolve the following issues:</p>
                  <ul>
                    {this.state.applicationErrors.map((errorItem, i) => (
                      <li key={i}>{errorItem}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}
          {isValid && selectedPremium && (
            <div className="col-xs-12">

              <h4>Declaration</h4>
              {declaration ? (
                <div>
                  {Object.keys(declaration).map((declarationSection, i) => (
                    <div className="declaration" key={i}><Markdown source={declaration[declarationSection]} linkTarget="_blank" transformLinkUri={this.transformDocLinks} /></div>
                  ))}
                </div>
              ) : (
                <ul>
                  <li>The Insured is resident in the United Kingdom.</li>
                  <li>Formal instructions have been received to bind cover.</li>
                </ul>
              )}
              <div className="row">
                <div className="col-xs-12">
                  <div className="col-xs-12">
                    <Field
                      name={'meta.purchase_declaration'}
                      inline={true}
                      options={[
                        { label: 'Please tick to confirm you agree to the above declaration', value: true }
                      ]}
                      component={CheckboxGroup}
                    />
                  </div>
                </div>
              </div>

              {adjustableEndDate && (
                <div>
                  <div className="hr"/>
                  <div className="row">
                    <div className="col-xs-12">
                      <h4>Cover dates</h4>
                      <p>Please select when you would like your cover to start.</p>
                    </div>
                  </div>
                  <div className="row datepicker-container">
                    <div className="col-xs-12">
                      <Field
                        name="meta.start_date"
                        label="Start date"
                        minDate={hasPermission('policy.backdate_cover') ? moment().subtract(1, 'year').format() : moment().startOf('day').format()}
                        maxDate={maxAdjustableStartDate}
                        labelSize={3}
                        component={DatePicker}
                      />
                    </div>
                  </div>
                  <div className="row datepicker-container">
                    <div className="col-xs-12">
                      <Field
                        name="meta.end_date"
                        label="End date"
                        minDate={moment(_.get(formValues, 'meta.start_date', '')).format()}
                        maxDate={moment(_.get(formValues, 'meta.start_date', '')).add(2, 'years').subtract(1, 'days').format()}
                        labelSize={3}
                        component={DatePicker}
                      />
                    </div>
                  </div>
                </div>
              )}

              {selectedPremium.attributes.gross != 0.00 && (
                <div>
                  <div className="hr"/>

                  <div className="row">
                    <div className="col-sm-6">
                      <h4>Payment details</h4>
                      <div className="col-xs-12">
                        <span>Please select a payment method:</span><InfoButton content={paymentInfo}/>
                      </div>
                    </div>
                    <div className="col-sm-6">
                          <span className="pull-right">
                              Premium: <span
                            className="premium">{displayCurrency(selectedPremium.attributes.gross, currency)}</span> Inc. {_.get(selectedPremium,'attributes.metadata.rates.tax_rate')}% {taxType}
                          </span>
                    </div>
                    <div className="col-sm-12">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <Field
                            name={'meta.payment_type'}
                            inline={true}
                            options={paymentOptions}
                            component={RadioGroup}
                          />
                        </div>
                        {_.get(formValues, 'meta.payment_type') === 'card' && (
                          <div className="card-details form-horizontal">
                            <div className="row">
                              <div className="col-xs-12">
                                <Field
                                  name={'meta.card_number'}
                                  label="Card number"
                                  labelSize={3}
                                  type="cardNumber"
                                  cleaveOptions={{ creditCard: true, creditCardStrictMode: true }}
                                  validate={[isLuhnHappy()]}
                                  component={HorizontalFormControl}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-6">
                                <ExpiryDateFields
                                  baseName={'meta'}
                                  label="Expiry date"
                                  labelSize={6}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-6">
                                <Field
                                  className="text-center"
                                  name={'meta.cvv'}
                                  label="CVC/CV2"
                                  labelSize={6}
                                  component={HorizontalFormControl}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="hr"/>
              <div className="row">
                <div className="col-xs-12">
                  <div className="col-xs-12">
                    <Field
                      name={'meta.send_sms'}
                      inline={true}
                      options={[
                        { label: 'Notify customer by SMS', value: true }
                      ]}
                      component={CheckboxGroup}
                    />
                  </div>
                </div>
              </div>

              {_.get(formValues, 'meta.send_sms') && (
                <div className="row">
                  <div className="col-xs-12">
                    <Field
                      name={'meta.sms_number'}
                      label="Phone number"
                      labelSize={3}
                      component={HorizontalFormControl}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </form>
    )
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(IssueButton)
const selector = formValueSelector(FORM_NAME)
const mapStateToProps = (state, props) => {
  const values = selector(state,
    'meta.product_code',
    'meta.payment_type',
    'meta.send_sms',
    'meta.amount',
    'meta.start_date',
    'data.relationships'
  )

  return {
    initialValues: {
      relationships: {
        premium: {
          data: {
            type: 'premium',
            id: null
          }
        }
      },
      meta: {
        product_code: _.get(props.product, 'data.attributes.product_code', ''),
        send_sms: false,
        start_date: ''
      }
    },
    formValues: values,
    productState: state.product,
  }
}

export default connect(mapStateToProps)(form)
