import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button } from '../../../common/components'
import { hasPermission } from '../../auth/redux/authActions'
import InternalContainer from '../../layout/containers/Internal'
import BrokerList from '../components/BrokerList/BrokerList'

class BrokerListContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    broker: PropTypes.object.isRequired,
  }

  render() {

    const buttons = (hasPermission('broker.create') ? (
            <Button
                link={true}
                to="/brokers/new"
                className="pull-right"
                bsStyle="primary"
                label="new broker"
            />
        ) : (''))

    return (
        <InternalContainer title="Brokers" buttons={buttons}>
          <BrokerList {...this.props} />
        </InternalContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    broker: state.broker
  }
}

export default connect(mapStateToProps)(BrokerListContainer)
