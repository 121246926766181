import React, { Component, contextTypes } from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router'
import Icon from '../Icon/Icon'
import './Breadcrumb.scss'

export default class Breadcrumb extends Component {
  static propTypes = {
    location: PropTypes.object,
    name: PropTypes.string
  }

  render() {
    let pattern = /(.*)\/\/[a-zA-Z:.0-9-@]+\//
    let path = ''
    let segments = []

    if (typeof window !== 'undefined' && typeof window.location !== 'undefined') {
      segments = window.location.href.split(/[?#]/)[0].replace(pattern, '').split('/')
    }

    const capitalise = (s) => {
      return s[0].toUpperCase() + s.slice(1)
    }

    const filteredSegment = (s) => {
      let uuidPattern = /[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89aAbB][a-f0-9]{3}-[a-f0-9]{12}/

      if (uuidPattern.test(s)) {
        return 'resource'
      }
      return s
    }
    const excludedPaths = []
    const notClickablePaths = ['resource']

    return (
        <div className="row">
          <div className="col-xs-12 breadcrumb-box">
            <ul className="breadcrumb">
              <li><Link to="/dashboard"><Icon name="home"/></Link></li>
                {segments.map((segment, part) => {
                  path += '/' + segment
                  segment = filteredSegment(segment)
                  if (!segment) {
                    return
                  }
                  if (segments.length === (part + 1) || excludedPaths.indexOf(segment) !== -1) {
                    return <li key={part} className="li-active"><span className="last-child-padding" />{capitalise(segment)}</li>
                  }
                  if (notClickablePaths.indexOf(segment) !== -1) {
                    return <li key={part}><Link to={''} activeClassName="active">{capitalise(segment)}</Link></li>
                  }
                  return <li key={part}><Link to={path}>{capitalise(segment)}</Link></li>
              })}
            </ul>
          </div>
        </div>
    )
  }
}
