import React from 'react'

const PlainTextField = ({ input, placeholder, type, showErrorLabel, meta: { touched, error }, ...props }) => (
    <div className={(touched && error ? ' has-error' : '')}>
      <input {...props} className="form-control" {...input} placeholder={placeholder || ''} type={type} />

      {showErrorLabel && touched && error && <span className="input-error">{error}</span>}
    </div>
)

export default PlainTextField
