import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { InfoLabel, DataTable, Alert, Box, Button, CreateQuoteFromCustomerButton } from '../../../../common/components'
import { TableHeaderColumn } from 'react-bootstrap-table'
import displayAddress from '../../../../helpers/displayAddress'
import { FormattedDate } from 'react-intl'
import { hasPermission } from '../../../auth/redux/authActions'
import { push } from 'react-router-redux'
import './styles.scss'
import { createCustomerAccount } from '../../redux/customerActions'
import MergeCustomersButton from '../MergeCustomersButton/MergeCustomersButton';

export default class CustomerDetails extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    customer: PropTypes.object.isRequired,
    resource: PropTypes.object.isRequired
  };

  formatDate(cell, row) {
    return (
        <FormattedDate value={new Date(cell)}/>
    )
  }

  formatName(cell, row) {
    return (
        row.title + ' ' + row.first_name + ' ' + row.last_name
    )
  }

  formatAddress(cell, row) {
    return (
        row.address.postcode
    )
  }

  handleRowClick(row) {
    return this.props.dispatch(push('/policies/issued/' + row.id))
  }

  handleCreateCustomerAccount() {
    this.props.dispatch(createCustomerAccount(this.props.resource.data.id))
  }


  render() {
    const { resource } = this.props

    const resourceData = resource.data.attributes
    const address = displayAddress(resourceData.address)

    return (
        <Box>
          <h2 className="resource-name">{ resourceData.title + ' ' + resourceData.first_name + ' ' + resourceData.last_name }</h2>

          <div className="row">
            <div className="col-xs-12">
              <Alert />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-horizontal">
                {resourceData.company_name && (
                    <InfoLabel label="Company Name" value={ resourceData.company_name } labelSize={4}/>
                )}
                <InfoLabel label="Name" value={ resourceData.title + ' ' + resourceData.first_name + ' ' + resourceData.last_name }
                           labelSize={4}/>
                <InfoLabel label="Date of Birth" value={ resourceData.dob } date={true} labelSize={4}/>
                <InfoLabel label="Email" value={ resourceData.email } labelSize={4}/>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-horizontal">
                <InfoLabel label="Address" value={ address } labelSize={4}/>
                <InfoLabel label="Phone" value={ resourceData.phone1 } labelSize={4}/>
                <InfoLabel label="Alternative Phone" value={ resourceData.phone2 } labelSize={4}/>

              </div>
            </div>
          </div>

          <div className="hr transparent"/>

          <div className="row">
            <div className="col-xs-12">
              <div className="form-horizontal">
                <h3>Policies</h3>
                <DataTable
                    source="/policies"
                    name="policies"
                    autoFilterType="filter[customer]"
                    autoFilter={resource.data.id}
                    showTotal={true}
                    onRowSelect={::this.handleRowClick}>

                  <TableHeaderColumn dataField="id" isKey={true} hidden={true}>ID</TableHeaderColumn>
                  <TableHeaderColumn dataField="policy_number" dataSort={true}>Policy Number</TableHeaderColumn>
                  <TableHeaderColumn dataField="insured" dataSort={true}>Insured</TableHeaderColumn>
                  <TableHeaderColumn dataField="status" dataSort={true}>Status</TableHeaderColumn>
                  <TableHeaderColumn dataField="created_at" dataFormat={this.formatDate} dataSort={true}>Quote
                    Date</TableHeaderColumn>
                  <TableHeaderColumn dataField="issued_at" dataFormat={this.formatDate} dataSort={true}>Issue
                    Date</TableHeaderColumn>
                </DataTable>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              <CreateQuoteFromCustomerButton
                resource={resource}
                className="pull-right"
              />
              { hasPermission('customer.edit') && (
                <Button
                  link={true}
                  to={'/customers/' + resource.data.id + '/edit'}
                  className="pull-right  m-r-10"
                  bsStyle="primary"
                  rightIcon="pencil-square-o">Edit Customer</Button>
              )}
              { hasPermission('customer.edit') && (
                <MergeCustomersButton
                  customer={resource.data.attributes}
                  customerId={resource.data.id}
                  className="pull-right pad-right"
                />
              )}
              { resourceData.status === 'not applicable' && hasPermission('customer.edit') && (
                <Button
                  className="pull-right m-r-10"
                  bsStyle="primary"
                  handleClick={::this.handleCreateCustomerAccount}
                  rightIcon="address-card-o"
                >
                  Create Customer Account
                </Button>
              )}
            </div>
          </div>
        </Box>
    )
  }
}
