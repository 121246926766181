import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { Button, Modal } from '../../../../common/components'
import { TextField } from '../../../../common/components/ReduxFormField'
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux'
import { ControlLabel } from 'react-bootstrap'
import { unmatchBatch } from '../../redux/accountActions'
import * as _ from 'lodash'

const FORM_NAME = 'unmatchForm'


class UnmatchBatchButton extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    batch: PropTypes.object.isRequired,
    callback: PropTypes.func
  }

  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded, callback } = this.props
    if (newProps.submitSucceeded && !submitSucceeded) {
      if (callback) {
        callback('')
      }
      dispatch(closeModal(FORM_NAME))
    }
  }

  handleSubmit(values) {
    return this.props.dispatch(unmatchBatch(this.props.batch.id, values))
  }

  handleOpen() {
    const { dispatch, reset } = this.props
    dispatch(reset())
    dispatch(openModal(FORM_NAME))
  }

render() {
  const { handleSubmit, submitting } = this.props
  const submitButton = (<Button
      type="submit"
      bsStyle="primary"
      isLoading={submitting}
      handleClick={handleSubmit(::this.handleSubmit)}
      label="Un-match"
    />)

    return (
      <div className="email-btn-wrapper pull-right">
        <Button
          type="button"
          bsStyle="primary"
          label="Un-match Entries"
          rightIcon="ban"
          handleClick={::this.handleOpen}
        />

        <Modal
          name={FORM_NAME}
          title="Un-match Batch"
          close={true}
          footer={submitButton}
        >
          <form onSubmit={handleSubmit(::this.handleSubmit)}>
            <div className="col-xs-12">
              <ControlLabel className="filter-label">Reason for un-matching entries</ControlLabel>
              <Field
                name="data.attributes.content"
                type="textarea"
                component={TextField}
              />
            </div>
            <br/>
          </form>
        </Modal>
      </div>
    )
  }
}

const form = reduxForm({ form: FORM_NAME })(UnmatchBatchButton)

export default connect()(form)
