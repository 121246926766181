import React from 'react'
import _ from 'lodash'
import './styles.scss'
import { Icon } from "../index";

const RadioGroupDisgusedAsButton = ({input, label, labelSize, mdFieldSize, infoButton, options, objectKey, inline, width, meta: {touched, error}}) => {
  let labelClass = 'col-sm-12', wrapperClass = 'radio-group';
  if (labelSize) {
    labelClass = ' col-sm-' + labelSize;
    wrapperClass += ' col-sm-' + (12 - labelSize);

    if (mdFieldSize) {
      wrapperClass += ' col-sm-' + mdFieldSize;
    }
  }

  return (
    <div className={(touched && error ? 'form-group has-error' : 'form-group')}>
      {label && (
        <div className={labelClass}>
          <div className="label-unbreake">
            <label className={'control-label' + inline ? ' inline-radio-label' : ''}>{label}</label> {infoButton}
          </div>
        </div>
      )}

      <div className={wrapperClass}>

        <div className="radio-control radio-group-disgused-as-buttons">
          {options.map((option, key) => {
            const value = objectKey ? _.get(option.value, objectKey) : option.value;
            return (
              <div key={key}>
                <input
                  {...input}
                  type="radio"
                  id={input.name + option.label}
                  value={option.value}
                  checked={input.value.toString() == value.toString()}
                />
                <div className="radio-label" onClick={() => input.onChange(option.value)}>
                  <Icon customIconSet={option.customIconSet} name={option.icon}/>
                  {option.label}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
};

export default RadioGroupDisgusedAsButton
