export const SET_ORGANISATION = 'socrates-online/organisation/SET_ORGANISATION';
export const SET_ORGANISATIONS = 'socrates-online/organisation/SET_ORGANISATIONS';
export const CLEAR_ORGANISATION = 'socrates-online/organisation/CLEAR_ORGANISATION';
export const IS_SUBMITTING = 'socrates-online/organisation/IS_SUBMITTING';
export const IS_REACTIVATING = 'socrates-online/organisation/IS_REACTIVATING';
export const IS_DEACTIVATING = 'socrates-online/organisation/IS_DEACTIVATING';
export const SET_BROKERID = 'socrates-online/organisation/SET_BROKERID';
export const SET_NETWORK = 'socrates-online/organisation/SET_NETWORK';
export const SET_INSURERS = 'socrates-online/organisation/SET_INSURERS';

export const initialState = {
  isSubmitting: false,
  isDeactivating: false,
  isReactivating: false,
  resources: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.status,
      };
    case IS_DEACTIVATING:
      return {
        ...state,
        isDeactivating: action.status,
      };
    case SET_INSURERS:
      return {
        ...state,
        resources: {
          ...state.resources,
          insurers: action.data,
        },
      };
    case IS_REACTIVATING:
      return {
        ...state,
        isReactivating: action.status,
      };
    case SET_ORGANISATION:
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.data.data.id]: {
            ...action.data,
          },
        },
      };
    case SET_ORGANISATIONS:
      return {
        ...state,
        resources: {
          ...action.data,
        },
      };
    case SET_BROKERID:
      return {
        ...state,
        broker_id: action.id,
      };
    case SET_NETWORK:
      return {
        ...state,
        network: action.id,
      };
    case CLEAR_ORGANISATION:
      return initialState;
    default:
      return state;
  }
}
