import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SchemeDetailsForm from '../components/SchemeDetailsForm'
import { updateScheme } from '../redux/schemeActions'
import { reset } from 'redux-form'

class SchemeDetailsFormContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    scheme: PropTypes.object.isRequired,
    productId: PropTypes.string.isRequired,
    schemeStatus: PropTypes.string.isRequired,
    schemeLabel: PropTypes.object.isRequired,
  }

  handleSubmit(scheme) {
    const { dispatch, productId } = this.props

    dispatch(reset('schemeDetailsForm'))
    return dispatch(updateScheme(scheme, productId, this.props.scheme))
  }

  render() {
    return (
        <SchemeDetailsForm
            scheme={this.props.scheme}
            productId={this.props.productId}
            onSubmit={::this.handleSubmit}
            schemeStatus={this.props.schemeStatus}
            schemeLabel={this.props.schemeLabel}
        />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    scheme: state.scheme,
  };
};

export default connect(mapStateToProps)(SchemeDetailsFormContainer);
