import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, FieldArray, formValueSelector } from 'redux-form'
import { Button } from '../../../../common/components'
import displayName from '../../../../helpers/displayName'
import Options from './Options'
import * as _ from 'lodash'
import './styles.scss'

const FORM_NAME = 'schemeOptionsForm'

class SchemeOptionsForm extends Component {
  static propTypes = {
    scheme: PropTypes.object.isRequired,
    schemeStatus: PropTypes.string.isRequired,
    schemeLabel: PropTypes.object.isRequired,
    formValues: PropTypes.object.isRequired
  }

  render() {
    const { scheme, submitting, handleSubmit, schemeStatus, schemeLabel, formValues } = this.props
    const editable = schemeStatus === 'test'
    const disableButtons = true;
    const disableFields = true;

    return (
      <div className="scheme-details-form">
        <form onSubmit={handleSubmit}>
          <h2 className="resource-name">{scheme.data.attributes.name}&nbsp;
            ({scheme.data.attributes.cover_level}){schemeLabel}</h2>

          <FieldArray
            name="meta.options"
            items={_.get(formValues, 'meta.options', [])}
            editable={editable}
            disableFields={disableFields}
            disableButtons={disableButtons}
            component={Options}
          />

          <div className="hr transparent"/>

          <div className="row actions">
            {editable && (
              <Button
                type="submit"
                label="Save Changes"
                bsStyle="primary"
                className="pull-right"
                isLoading={submitting}
              />
            )}
          </div>
        </form>
      </div>
    )
  }
}

const form = reduxForm({ form: FORM_NAME })(SchemeOptionsForm)
const selector = formValueSelector(FORM_NAME)
const mapStateToProps = (state, props) => {
  const values = selector(state,
    'data.id',
    'meta.options'
  )

  _.get(props.scheme, 'meta.options', []).map((option, key) => {
    _.set(props.scheme, 'meta.options[' + key + '].name', displayName(option.name, true))
  })

  return {
    initialValues: props.scheme,
    formValues: values
  }
}
export default connect(mapStateToProps)(form)

