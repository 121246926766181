import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { Loader } from '../../../common/components'
import InternalContainer from '../../layout/containers/Internal'
import { getProducts, clearData } from '../redux/schemeActions'
import { Button } from '../../../common/components'
import Markdown from 'react-remarkable'
import { get } from 'lodash'

export class SchemeProductsContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    scheme: PropTypes.object.isRequired
  };

  componentWillMount() {
    this.props.dispatch(getProducts())
  }

  /**
   * Clean out the data when the component unmounts
   */
  componentWillUnmount() {
    this.props.dispatch(clearData())
  }

  render() {
    const { scheme } = this.props

    return (
      <div className="admin-area">
        <InternalContainer title="Product management">
          {(scheme.isLoading || !scheme.products.data) ? (
            <Loader/>
          ) : (
            <div className="scheme-list">
              {scheme.products.data.map((product, key) => {
                  if (get(product, 'attributes.metadata.isActive', false)) {
                    return (
                      <div key={key} className="scheme-block">
                        <h2>{product.attributes.name}</h2>
                        <Markdown source={product.attributes.description}/>
                        <Link to={'/admin/products/' + product.id + '/edit'}>
                          <Button bsStyle="primary" label="edit"/>
                        </Link>
                      </div>
                    )
                  }
                }
              )}
            </div>
          )}
        </InternalContainer>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    scheme: state.scheme
  }
}

export default connect(mapStateToProps)(SchemeProductsContainer)

