export const IS_APPROVING = 'socrates-online/broker/IS_APPROVING'
export const IS_DECLINING = 'socrates-online/broker/IS_DECLINING'
export const IS_DEACTIVATING = 'socrates-online/broker/IS_DEACTIVATING'
export const IS_REACTIVATING = 'socrates-online/broker/IS_REACTIVATING'
export const IS_SENDING_APPROVAL = 'socrates-online/broker/IS_SENDING_APPROVAL'
export const IS_SUBMITTING = 'socrates-online/broker/IS_SUBMITTING'
export const SET_BROKER = 'socrates-online/broker/SET_BROKER'
export const CLEAR_BROKER = 'socrates-online/broker/CLEAR_BROKER'
export const SET_ALL_PRODUCTS = 'socrates-online/broker/SET_ALL_PRODUCTS'
export const SET_PDF = 'socrates-online/broker/SET_PDF'

export const initialState = {
  isApproving: false,
  isDeclining: false,
  isSendingApproval: false,
  isSubmitting: false,
  resources: {},
  allProducts: {
    data: []
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case IS_APPROVING:
      return {
        ...state,
        isApproving: action.status
      }
    case IS_DECLINING:
      return {
        ...state,
        isDeclining: action.status
      }
    case IS_DEACTIVATING:
      return {
        ...state,
        isDeclining: action.status
      }
    case IS_REACTIVATING:
      return {
        ...state,
        isDeclining: action.status
      }
    case IS_SENDING_APPROVAL:
      return {
        ...state,
        isSendingApproval: action.status
      }
    case IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.status
      }
    case SET_BROKER:
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.data.data.id]: {
            ...action.data
          }
        }
      }
    case SET_PDF:
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.brokerId]: {
            ...state.resources[action.brokerId],
            pdf_title: action.title,
            pdf_content: action.content
          }
        }
      }
    case SET_ALL_PRODUCTS:
      return {
        ...state,
        allProducts: action.data
      }
    case CLEAR_BROKER:
      return initialState
    default:
      return state
  }
}
