import React, { Component, createElement } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { BootstrapTable } from 'react-bootstrap-table'
import {
  initTable,
  fetchData,
  setSource,
  setFilters,
  applyFilter,
  clearData,
  setDataFirstLoadingCompleted
} from './redux/dataTableActions'
import Icon from '../Icon/Icon'
import Loader from '../Loader/Loader'
import './DataTable.scss'
import { getPolicyData } from '../../../modules/policy/redux/policyActions';
import _ from 'lodash'


const MAX_ATTEMPTS = 5
let timeout = null

class DataTable extends Component {

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    dataTables: PropTypes.object,
    source: PropTypes.string,
    autoFilterType: PropTypes.string,
    autoFilter: PropTypes.string,
    autoFilters: PropTypes.array,
    onRowSelect: PropTypes.func,
    waitForItems: PropTypes.number,
    waitForItemsMessage: PropTypes.string,
    perPage: PropTypes.number,
    component: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
  };

  state = {
    attempts: 0,
    loadingCompleted: false,
    awaitingRecentDocuments: true
  }

  /**
   * Component will mount
   * Called just before the component mounts on the page
   */
  componentWillMount() {
    const { name, source, dispatch, perPage } = this.props

    dispatch(initTable(name, source, perPage))
  }

  componentDidMount() {
    const { name, source, autoFilterType, autoFilter, autoFilters, dispatch } = this.props
    if (source) {
      if (autoFilter) {
        dispatch(setSource(name, source))
        dispatch(setFilters(name, autoFilterType, autoFilter))
      } else if (autoFilters) {
        autoFilters.map(filter => {
          dispatch(setFilters(name, filter.type, filter.value))
        })
      }

      this.getDocumentGenerationStatus();

    }
  }

  updateTable() {
    const { dispatch, waitForItems, dataTables, name, maxAttempts = MAX_ATTEMPTS, waitForNewestPolicyDocuments = false } = this.props;
    const table = dataTables.tables[name]

    // If max attempts is reached then class the datatable as being loaded to prevent it endlessly trying to load if something goes wrong
    const finishedLoading = this.state.attempts >= maxAttempts || table && table.data && (!waitForItems && !waitForNewestPolicyDocuments || table.data.length >= waitForItems && !waitForNewestPolicyDocuments || waitForNewestPolicyDocuments && !this.state.awaitingRecentDocuments && table.data.length);

    if (!table) {
      return;
    }

    if (table) {

      if (finishedLoading) {
        dispatch(setDataFirstLoadingCompleted(name, true));
        this.setState({
          loadingCompleted: true
        });
      }

      if (timeout && finishedLoading) {
        return clearTimeout(timeout)
      }

      if ((!table.isFetching && !finishedLoading)) {
        timeout = setTimeout(() => {
          this.setState({ attempts: (this.state.attempts + 1) }, () => {
            this.getDocumentGenerationStatus()
          })
        }, 2000)
      }
    }
  }

  getDocumentGenerationStatus() {

    const { policy, resource, dispatch, name, waitForNewestPolicyDocuments } = this.props;

    if (waitForNewestPolicyDocuments) {
      const policyId = resource.data.id;

      if (!policy.isGettingPolicy) {
        Promise.resolve(
          this.props.dispatch(getPolicyData(policyId, [])),
        ).then((response) => {
            if (_.get(response.data.attributes.metadata, 'documents_generated') || _.get(response.data.attributes.metadata, 'documents_generated') === undefined) {
              Promise.resolve(dispatch(fetchData(name))).then(() => {
                this.setState({ awaitingRecentDocuments: false }, () => {
                  this.updateTable()
                })
              });
            } else {

              Promise.resolve(dispatch(fetchData(name))).then(() => {
                this.updateTable()
              });
            }
          }
        );
      }
    } else {
      Promise.resolve(dispatch(fetchData(name))).then(() => {
        this.updateTable()
      });
    }

  }

  /**
   * Clean out the data when the component unmounts
   */
  componentWillUnmount() {
    this.props.dispatch(clearData(this.props.name))
  }

  renderTotal(from, to, total) {
    if (!this.props.showTotal) return

    return (
      <div>Showing {from} to {to} of {total} entries</div>
    )
  }

  formatData(jsonApiData = []) {
    let tableData = jsonApiData.map(data => {
      return {
        id: data.id,
        relationships: data.relationships,
        ...data.attributes
      }
    })

    return tableData
  }

  onSortChange(sortName, sortOrder) {
    const { name, dispatch } = this.props
    const sortBy = sortOrder == 'asc' ? '-' + sortName : sortName
    dispatch(applyFilter(name, 'sort', sortBy))
  }

  onPageChange(page, sizePerPage) {
    const { name, dispatch } = this.props
    const nextPage = page ? page : 1
    const currentIndex = (nextPage - 1) * sizePerPage
    const offset = currentIndex

    dispatch(setFilters(name, 'page[offset]', offset ? offset : 0))
    dispatch(setFilters(name, 'page[limit]', sizePerPage))
    dispatch(fetchData(name))
  }

  onSizePerPageList(sizePerPage) {
    const { name, dispatch, dataTables: { tables: { [name]: { currentPage } } } } = this.props
    const currentIndex = (currentPage - 1) * sizePerPage
    const offset = currentIndex

    dispatch(setFilters(name, 'page[offset]', offset ? offset : 0))
    dispatch(setFilters(name, 'page[limit]', sizePerPage))

    return sizePerPage
  }

  /**
   * Render
   * Renders the markup for this component
   *
   * @returns {XML}
   */
  render() {
    if (!this.props.dataTables.tables[this.props.name]) {
      return null
    }

    const { waitForItems, pageOff, customHeight, maxAttempts = MAX_ATTEMPTS, waitForItemsMessage, waitForNewestPolicyDocuments = false, component, name, onSelect, notesName, dataTables: { tables: { [name]: { data, included, currentPage, total, filters, isFetching } } } } = this.props
    const limit = filters['page[limit]'] ? filters['page[limit]'] : 10

    const finishedLoading = this.state.attempts >= maxAttempts || data && (!waitForItems && !waitForNewestPolicyDocuments || data.length >= waitForItems && !waitForNewestPolicyDocuments || waitForNewestPolicyDocuments && !this.state.awaitingRecentDocuments && data.length );

    const selectRowProps = this.props.onRowSelect ? {
      mode: 'radio',
      className: 'selected',
      clickToSelect: true,
      hideSelectColumn: true,
      onSelect: this.props.onRowSelect
    } : {}

    const tableOptionProps = {
      noDataText: isFetching ? (
        <div className="text-center bs-table-placeholder"><Icon name="spinner" spin={true} size="fa-1x"/> Loading
          results...
        </div>) : <div className="text-center bs-table-placeholder">No results found</div>,
      sizePerPage: limit,
      onPageChange: ::this.onPageChange,
      sizePerPageList: [10, 20, 30, 40, 50],
      pageStartIndex: 1,
      page: currentPage,
      onSizePerPageList: ::this.onSizePerPageList,
      onSortChange: ::this.onSortChange
    }

    return (
      <div>
        {finishedLoading ? (
          <div className={'data-table' + (this.props.onRowSelect ? ' select-enabled' : ' select-disabled')}>
            {component ? (
              createElement(component, { data, included, isFetching, onSelect, dataTable: this, notesName })
            ) : (
              <BootstrapTable
                ref={this.props.name}
                data={data ? this.formatData(data) : []}
                remote={true}
                fetchInfo={{ dataTotalSize: total }}
                options={tableOptionProps}
                striped={true}
                hover={true}
                bordered={false}
                selectRow={selectRowProps}
                height={customHeight ? customHeight : ''}
                pagination={pageOff ? false : true}
              >
                {this.props.children}
              </BootstrapTable>
            )}
          </div>
        ) : (
          <Loader message={waitForItemsMessage}/>
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    dataTables: state.dataTables,
    policy: state.policy
  }
}

export default connect(mapStateToProps)(DataTable)
