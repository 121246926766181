import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box, DataTable, Button } from '../../../../common/components'
import {
  applyFilter,
  refreshData,
  setDataFirstLoadingCompleted
} from '../../../../common/components/DataTable/redux/dataTableActions'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { Col, FormControl, FormGroup, ControlLabel } from 'react-bootstrap'
import { FormattedDate, FormattedTime } from 'react-intl'
import { generateDocuments, getPolicy, sendCustomerResetPasswordEmail, } from '../../redux/policyActions'
import getIncludedResource from '../../../../helpers/getIncludedResource'
import EmailCustomerDocumentsButton from '../EmailCustomerDocumentsButton'
import { policyStatuses } from '../../../../schemas/policy'
import EmailDocumentsButton from '../EmailDocumentsButton/EmailDocumentsButton';
import _ from 'lodash'

const TABLE_NAME = 'documents'

export default class PolicyDocuments extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    reference: PropTypes.string.isRequired,
    isGeneratingDocs: PropTypes.bool,
    dataTables: PropTypes.object
  };

  formatDate(cell) {
    return (
        <span><FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/></span>
    )
  }

  handleFilterUpdate(event) {
    this.props.dispatch(applyFilter('documents', 'filter[version]', event.target.value))
  }

  handleRowClick(row) {
    if (window) {
      window.open(row.file_path, '_blank')
    }

    return false
  }

  showSendResetEmailButton() {
    const { resource } = this.props;

    const productName = resource.data && resource.data.attributes && resource.data.attributes.product_name;
    const showButtonOn = ['Salt', 'Salt Long Stay', 'Salt - OM', 'Salt OM Long Stay', 'Groupsure'];
    if ( showButtonOn.includes(productName) ) {
      return true;
    }
    return false;
  }

  handleCustomerResetPasswordEmail() {
    const { resource, dispatch } = this.props
    const customer = getIncludedResource(resource.data, resource.included, 'customer')
    const policyId = resource.data.id
    const type = resource.data.type
    dispatch(sendCustomerResetPasswordEmail(customer.data.id, type, policyId))
  }

  handleGenerateDocuments() {
    const isPolicy = this.props.resource.data.attributes.policy_number;
    this.props.dispatch(setDataFirstLoadingCompleted(TABLE_NAME, false))
    this.props.dispatch(generateDocuments(
        this.props.resource,
      isPolicy ? 'policies' : 'policies/applications',
        () => {
          this.props.dispatch(refreshData('documents'));
          this.props.dispatch(getPolicy(this.props.resource.data.id, !isPolicy));
          this.props.dispatch(setDataFirstLoadingCompleted(TABLE_NAME, true))
        }
    ))
  }

  getMinimumDocumentsCount() {
    const { resource } = this.props;
    return _.get(resource, 'data.attributes.minimum_document_count', 0);
  }

  documentsLoaded() {
    return this.props.dataTables && this.props.dataTables.tables && this.props.dataTables.tables.documents
      && (this.props.dataTables.tables.documents.dataFirstLoadingCompleted || this.props.dataTables.tables.documents.data.length === this.getMinimumDocumentsCount());
  }

  getDocumentName(cell, row) {
    if (! _.isUndefined(row.display_name) && row.display_name.length > 0) {
      return row.display_name;
    }

    return cell;
  }

  render() {
    const { reference, resource, isGeneratingDocs } = this.props

    if (!resource.data?.id) {
      return null;
    }

    const premiums = getIncludedResource(this.props.resource.data, this.props.resource.included, 'premiums')
    const user = getIncludedResource(this.props.resource.data, this.props.resource.included, 'user')
    const customer = getIncludedResource(this.props.resource.data, this.props.resource.included, 'customer')
    const policyNumber =resource.data.attributes.policy_number;

    this.showSendResetEmailButton();
    return (
        <div>
          <Box>
            <h2 className="resource-name">{reference}</h2>

            <div className="row">
              <div className="col-sm-4 col-sm-offset-8">
                <FormGroup controlId="select-status">
                  <Col componentClass={ControlLabel} sm={4}>Versions</Col>
                  <Col sm={8}>
                    <FormControl
                        componentClass="select"
                        type="select"
                        onChange={::this.handleFilterUpdate}
                        placeholder="select"
                    >
                      <option value="">Current</option>
                      <option value="all">All</option>
                    </FormControl>
                  </Col>
                </FormGroup>
              </div>
            </div>

            <div className="hr transparent"/>

            <DataTable
              waitForItems={this.getMinimumDocumentsCount()}
              waitForItemsMessage="Please wait while your documents are generated"
                source="documents"
                autoFilters={[
                  {
                    type: 'filter[' + (this.props.resource.data.attributes.policy_number ?
                        'policy' : 'policy_application'
                    ) + ']',
                    value: this.props.resource.data.id
                  },
                ]}
                name={TABLE_NAME}
                showTotal={true}
                onRowSelect={this.handleRowClick.bind(this)}
            >

              <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
                ID
              </TableHeaderColumn>
              <TableHeaderColumn dataField="name" dataSort={true} dataFormat={this.getDocumentName}>
                Name
              </TableHeaderColumn>
              <TableHeaderColumn dataField="version_number" dataSort={true} width={'100px'}>
                Version
              </TableHeaderColumn>
              <TableHeaderColumn dataField="from_date" dataFormat={this.formatDate} dataSort={true} width={'180px'}>
                Effective Date
              </TableHeaderColumn>
              <TableHeaderColumn dataField="created_at" dataFormat={this.formatDate} dataSort={true} width={'180px'}>
                Created
              </TableHeaderColumn>
            </DataTable>
            <div className="row actions">
              <Button
                  bsStyle="primary"
                  className="pull-right"
                  label="Generate New Documents"
                  rightIcon="file-o"
                  isLoading={isGeneratingDocs || !this.documentsLoaded()}
                  handleClick={::this.handleGenerateDocuments}
              />

              { (policyNumber
                || (resource.data.attributes.status === policyStatuses.quote
                || resource.data.attributes.status === policyStatuses.renewalInvited
                || resource.data.attributes.status === policyStatuses.referralApproved)
                && premiums.length)
                && (
                <div>
                  <EmailDocumentsButton
                    label="Email Broker Documents"
                    className="pull-right"
                    resource={resource}
                    user={user}
                    resourceType={policyNumber ? 'brokers/policies' : 'brokers/policy-applications'}
                    disabled={isGeneratingDocs || !this.documentsLoaded()}
                  />
                  <EmailCustomerDocumentsButton
                    label="Email Customer Documents"
                    className="pull-right"
                    resource={resource}
                    user={customer}
                    resourceType={policyNumber ? 'customers/policies' : 'customers/policy-applications'}
                    disabled={isGeneratingDocs || !this.documentsLoaded()}
                  />
                  {this.showSendResetEmailButton() &&
                    <Button
                      label="Send Reset Password Email"
                      bsStyle="primary"
                      className="pull-right pad-right"
                      rightIcon="envelope-o"
                      handleClick={::this.handleCustomerResetPasswordEmail}
                      disabled={isGeneratingDocs || !this.documentsLoaded()}
                    />
                  }
                </div>
              )}
            </div>
          </Box>
        </div>
    )
  }
}
