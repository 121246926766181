import React, { Component } from 'react'
import { Box } from '../../../../common/components';
import Slider from 'react-slick/lib';
import { Link } from 'react-router';
import Icon from '../../../../common/components/Icon/Icon';
import { get } from 'lodash';

class ProductsCarousel extends Component {

  render() {

    const PrevArrow = props => {
      return (
        <div className='quotes-box__prev' onClick={props.onClick}>
          <Icon {...props} name="arrow-left"/>
        </div>
      );
    };

    const NextArrow = props => {
      return (
        <div className='quotes-box__next' onClick={props.onClick}>
          <Icon name="arrow-right"/>
        </div>
      );
    };

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      nextArrow: <NextArrow/>,
      prevArrow: <PrevArrow/>


    };

    const { products } = this.props;
    const productsName = products.map(product => product.attributes.name)
    const productsId = products.map(product => product.id)

    return (
      <div className='shadow-box'>
        <div className="width-100 get-quotes-header">
          <h3> Get a Quote </h3>
        </div>
        <Box className="quotes-box">
          <div className="tabbable">
            <ul className="nav nav-pills nav-stacked">
              <Slider {...settings}>
                {this.generateProductsList(productsId, productsName, products)}
              </Slider>
            </ul>
          </div>
        </Box>
      </div>
    )
  }

  generateProductsList = (productsId, productsName, products) => {

    const productsList = [];
    let children = [];
    let icon;

    products.forEach((product, index) => {
      if (!get(product, 'attributes.metadata.isActive', false)) {
        return;
      }

      if (index === 0 || index % 3 === 0) {
        icon = 'plane';
      } else if (index !== 0 && index % 2 === 0) {
        icon = 'suitcase';
      } else if (index !== 0 && index % 1 === 0) {
        icon = 'shield';
      }

      children.push(
        <li className="quotes-menu-item" key={product.id}>
          {productsId[0] ? (
            <Link to={'products/' + product.id + '/quote'}>
              <div className='quotes-menu-item__wrapper'>
                <div className='quotes-menu-item__title'>
                  <Icon circle={true} name={icon}/>
                  &nbsp;{product.attributes.name}
                </div>
                <span className="icon-right"><Icon name="chevron-right"/></span>
              </div>
            </Link>) : (<div className='quotes-menu-no-quotes'>No Quotes Available</div>)
          }
        </li>
      )

      if (children.length === 3 || index === products.length - 1) {
        productsList.push(<div key={index}>{children}</div>)
        children = [];
      }

    });

    return productsList;

  }
}


export default ProductsCarousel
