import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'react-bootstrap'
import { Box, ButtonBar, Button, Alert as Notification, HorizontalFormControl } from '../../../../common/components'
import { reduxForm, Field } from 'redux-form'
import '../../../brochure/brochure.scss'

const validate = values => {
  const errors = {}

  if (!values.email) {
    errors.email = 'Required'
  }

  return errors
}


class PasswordReminderForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
  };

  render() {
    const { error, handleSubmit, submitting } = this.props

    return (
        <div className="full-width-section">
          <div className="container relative remind-pass">
            <div className="row">
              <div className="col-md-offset-3 col-md-6 col-sm-9">
                <Box>
                  <h2>Forgotten your password</h2>

                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-xs-12">
                        <Notification/>

                        {error && (
                            <Alert bsStyle="danger">
                              <p>{error}</p>
                            </Alert>
                        )}

                        <div className="form-horizontal">
                          <Field
                              name="email"
                              type="email"
                              label="Email"
                              placeholder="Email address"
                              disabled={submitting}
                              component={HorizontalFormControl}
                          />
                        </div>
                      </div>
                    </div>
                    <ButtonBar>
                      <Button
                          type="submit"
                          bsStyle="primary"
                          className="pull-right"
                          disabled={submitting}>
                        {submitting ? <i className="fa fa-cog fa-spin"/> :
                            <i className="fa"/>} send password reset email
                      </Button>
                    </ButtonBar>
                  </form>
                </Box>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

export default reduxForm({
  form: 'password_reminder',
  validate
})(PasswordReminderForm)
