import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box, DataTable, CreateNoteButton, NotesList } from '../../../../common/components'
import { FormattedDate } from 'react-intl'
import { refreshData } from '../../../../common/components/DataTable/redux/dataTableActions'

export default class CustomerNotes extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired
  };

  formatDate(cell, row) {
    return (
        <FormattedDate value={new Date(cell)}/>
    )
  }

  handleRowClick(row) {
    //this.props.dispatch(push('/admin/users/' + row.id));
    return false
  }

  refreshList() {
    this.props.dispatch(refreshData('notes'))
  }

  render() {
    const resource = this.props.resource.data.attributes

    return (
        <div>
          <Box>
            <h2 className="resource-name">{ resource.title + ' ' + resource.first_name + ' ' + resource.last_name }</h2>

            <DataTable
                source={'/notes'}
                autoFilters={[
                  { type: 'filter[resource]', value: this.props.resource.data.id },
                  { type: 'include', value: 'attachments' }
                ]}
                name="notes"
                showTotal={true}
                onRowSelect={this.handleRowClick.bind(this)}
                component={NotesList}/>

            <div className="row actions">
              <div className="col-xs-12">
                <CreateNoteButton
                    label="Add a Note"
                    notableResource={this.props.resource}
                    notableResourceType="customers"
                    callback={::this.refreshList}
                />
              </div>
            </div>
          </Box>
        </div>
    )
  }
}
