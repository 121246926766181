import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Axios from 'axios'
import Markdown from 'react-remarkable'
import CenteredSection from '../components/CenteredSection/CenteredSection'

export default class RequirementsContainer extends Component {

  constructor(props, context) {
    super(props, context)
    this.state = {
      content: {}
    }
  }

  componentWillMount() {
    Axios.get('public/contents?filter[slug]=requirements').then(response => {
      this.setState({ content: response.data.data[0] })
    }).catch(error => {
      //this.props.dispatch(replace('/404'));
    })
  }

  render() {
    const content = this.state.content
    return (
        <div>
          <CenteredSection className="white">
            {content.attributes && (
                <div>
                  <Markdown source={content.attributes.sections['body']}/>
                </div>
            )}
          </CenteredSection>
        </div>
    )
  }
}
