import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import PlainTextField from '../../../../common/components/ReduxFormField'

export default class LlpPartner extends Component {

  static propTypes = {
    fields: PropTypes.object.isRequired,
    fieldComponent: PropTypes.object.isRequired
  };

  componentWillMount() {
    if (!this.props.fields.length) {
      this.props.fields.push('')
    }
  }

  render() {
    const { fields, fieldComponent } = this.props
    const Field = fieldComponent

    return (
        <div>
          {!fields.length && <div>No Partners</div>}
          {fields.map((partner, index) => {
            return (
                <div key={index}>
                  <div className="col-xs-10">
                    <Field
                        name={partner}
                        type="text"
                        placeholder="Please state full names of partners"
                        component={PlainTextField}
                    />
                  </div>
                  <div className="col-xs-2">
                    {fields.length == index + 1 ? (
                            <div className="add-button" onClick={() => {
                              fields.push('')
                            }
                            }><span>&#43;</span></div>
                        ) : (
                            <div className="add-button" onClick={() => {
                              fields.remove(index)
                            }
                            }><span>&#8211;</span></div>
                        )}
                  </div>
                </div>
            )
          })}
        </div>
    )
  }
}
