import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import { StickyContainer, Sticky } from 'react-sticky'
import BenefitsModal from '../BenefitsModal'
import { Field } from 'redux-form'
import { TextField } from '../../../../common/components/ReduxFormField'
import getIncludedResource from '../../../../helpers/getIncludedResource'
import listContains from '../../../../helpers/listContains'
import benefitByKey from '../../../../helpers/benefitByKey'
import displayCurrency from '../../../../helpers/displayCurrency'
import { saveReferral } from '../../redux/productActions'
import { hasPermission } from '../../../auth/redux/authActions'
import { Icon, Button } from '../../../../common/components'
import './styles.scss'

class AvailableQuotes extends Component {
  static propTypes = {
    product: PropTypes.object.isRequired,
    currentProduct: PropTypes.object.isRequired,
    availableQuotes: PropTypes.array.isRequired,
    selectedSchemes: PropTypes.array.isRequired,
    updateSelection: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isTestCenter: PropTypes.bool,
    schemeType: PropTypes.string
  }

  saveReferral(application) {
    // make a deep clone of the application object before updating
    const referredApplication = JSON.parse(JSON.stringify(application))

    const referrals = this.productReferrals(this.props.availableQuotes)
    if (referrals.length) {
      _.set(referredApplication, 'data.attributes.metadata.referrals', referrals)

      const note = _.get(referredApplication, 'meta.note.content', '')
      _.set(referredApplication, 'meta.note.content',  referrals.join(', ') + (note ? '\n' + note : ''))
    }

    return this.props.dispatch(saveReferral(referredApplication, this.props.currentProduct))
  }

  handleSchemeSelect(scheme) {
    const { updateSelection, selectedSchemes } = this.props
    const index = listContains(scheme, selectedSchemes)

    if (index !== false) {
      updateSelection.remove('data.relationships.schemes.data', index)
    } else {
      if (scheme) {
        updateSelection.push('data.relationships.schemes.data', scheme)
      }
    }
  }

  schemeIsSelected(scheme) {
    const { selectedSchemes } = this.props

    if (scheme) {
      const index = listContains(scheme, selectedSchemes)
      if (index !== false) {
        return true
      }
    }

    return false
  }

  hasAnnualScheme(scheme, quotes) {
    let annualScheme = false
    Object.keys(quotes).forEach(quotedScheme => {
      const quote = quotes[quotedScheme]

      if (quote.meta.scheme.scheme_type === 'annual'
        && quote.meta.scheme.insurer === scheme.insurer
        && quote.meta.scheme.cover_level === scheme.cover_level) {
        annualScheme = quote
      }
      else if(quote.meta.scheme.scheme_type === 'annual'
        && quote.meta.scheme.insurer === scheme.insurer
        && quote.meta.scheme.cover_level === 'Band 4'
        && scheme.cover_level === 'Premier') {
        annualScheme = quote
      }
    })

    return annualScheme
  }

  componentWillMount() {
    const { updateSelection } = this.props
    updateSelection.removeAll('data.relationships.schemes.data')
  }

  componentWillUpdate(nextProps) {
    const { dispatch, availableQuotes, change, selectedSchemes, updateSelection, product } = this.props
    if (!_.isEqual(nextProps.availableQuotes, availableQuotes)) {
      /* Update any referrals */
      const referrals = this.productReferrals(availableQuotes)
      if (referrals.length) {
        dispatch(change('data.attributes.metadata.referrals', referrals))
      } else {
        dispatch(change('data.attributes.metadata.referrals', []))
      }

      availableQuotes.map((quote) => {
        if (quote.meta.premium.gross === 0) {
          const schemeObject = getIncludedResource(quote.meta.scheme.id, product.schemes, false, 'products/schemes')
          const index = listContains(schemeObject, selectedSchemes)
          if (index !== false) {
            updateSelection.remove('data.relationships.schemes.data', index)
          }
        }
      })
    }

    /* If there is one scheme and it has calculated, pre-select it. */
    if (!nextProps.isTestCenter) {
      const nextReferrals = this.productReferrals(nextProps.availableQuotes)
      const requiresReferral = nextReferrals.length && !hasPermission('policy.handle_all_referrals')

      if (nextProps.availableQuotes.length === 1 && !requiresReferral) {
        const scheme = nextProps.availableQuotes[0].meta.scheme

        const isSelected = listContains(scheme, nextProps.selectedSchemes)

        if (!nextProps.product.isCalculating && isSelected === false) {
          updateSelection.removeAll('data.relationships.schemes.data')
          this.handleSchemeSelect(scheme)
        }
      }
    }
  }

  renderDiscount (quote) {
    const { mta } = this.props
    const gross = _.get(quote, 'meta.premium.gross', false)
    const discount = _.get(quote, 'meta.premium_before_adjustments.gross', false)

    if (!mta && gross !== discount) {
      return (
        <span className='previousPremium'>{displayCurrency(discount)}</span>
      )
    }
  }

  productReferrals(quotes) {
    const referrals = []
    Object.keys(quotes).forEach(quotedScheme => {
      const quote = quotes[quotedScheme]
      if (quote.meta.referrals && quote.meta.referrals.length > 0) {
        quote.meta.referrals.map(referral => {
          if (referral && referrals.indexOf(referral.reason) === -1) {
            referrals.push(referral.reason)
          }
        })
      }
    })

    return referrals
  }

  render() {
    const { product, currentProduct, availableQuotes, schemeType, handleSubmit } = this.props
    const referrals = this.productReferrals(availableQuotes)
    const requiresReferral = referrals.length && !hasPermission('policy.handle_all_referrals')
    const taxType = currentProduct.data.attributes.metadata.tax_type

    let displayTripDuration = null
    if (currentProduct.data.attributes.name === 'Salt' && schemeType === 'single'){
      availableQuotes.forEach((quote) => {
        const duration = _.split(_.get(quote, 'meta.breakdown.rate_base', '') , '|', 4)
        if (duration[3] <= 60 ) {
          displayTripDuration = ('60 days')
        } else if (duration[3] <= 90 ){
          displayTripDuration = ('90 days')
        }
      })
    }

    return (
      <div>
        {availableQuotes.length !== 0 && !requiresReferral ? (
          <div className="col-xs-12 available-quotes">
            <StickyContainer className="quotes">
              <Sticky>
                <div className="row quotes-header availiable-quotes-table">
                  <div className="flex2">
                    Underwriter
                  </div>
                  <div className="flex2 hide-query-4rd">
                    Scheme
                  </div>
                  <div className="flex2 hide-query-4rd">
                    Medical
                  </div>
                  {schemeType !== 'single' && (
                    <div className="flex2 hide-query-1st">
                      Personal Accident
                    </div>
                  )}
                  <div className="flex2 hide-query-2nd">
                    Cancellation
                  </div>
                  <div className="flex2 hide-query-1st">
                    Baggage Limit
                  </div>
                  <div className="flex1">
                    Benefits
                  </div>
                  {schemeType === 'single' && (
                    <div className="flex2">
                      Single
                    </div>
                  )}
                  <div className="flex2">
                    Annual <br/>
                    {displayTripDuration}
                  </div>
                </div>
              </Sticky>

              {availableQuotes.map(scheme => {
                const quote = scheme
                const isAnnual = schemeType !== 'single'
                const annualQuote = !isAnnual ? this.hasAnnualScheme(quote.meta.scheme, availableQuotes) : false

                if (quote.meta.scheme.scheme_type === null) {
                  return;
                }

                if (quote.meta.premium && (!schemeType || (isAnnual && quote.meta.scheme.scheme_type === 'annual'
                  || !isAnnual && quote.meta.scheme.scheme_type !== 'annual' || quote.meta.scheme.scheme_type === 'longstay'))) {
                  return (
                    <div key={quote.meta.scheme.id}>
                    <div
                         className={'row quoted-scheme availiable-quotes-table' + (quote.meta.scheme.status === 'test' ? ' test-scheme' : '')}>
                      <div className="flex2 quote-logo-container ">
                        <div className="quote-logo">
                          <img src={quote.meta.scheme.logo}/>
                        </div>
                      </div>
                      <div className="flex2 quote-value-container hide-query-4rd">
                        <span>{quote.meta.scheme.cover_level}{quote.meta.scheme.status === 'test' && (' (Test)')}</span>
                      </div>
                      <div className="flex2 quote-value-container hide-query-4rd">
                        <span
                          className="benefit">{benefitByKey(quote.meta.benefits, 'Medical')}</span>
                      </div>
                      {isAnnual && (
                        <div className="flex2 quote-value-container hide-query-1st">
                          <span
                            className="benefit multi-line">{benefitByKey(quote.meta.benefits, 'Personal Accident')}</span>
                        </div>
                      )}
                      <div className="flex2 quote-value-container hide-query-2nd">
                        <span
                          className="benefit">{benefitByKey(quote.meta.benefits, 'Cancellation')}</span>
                      </div>
                      <div className="flex2 quote-value-container hide-query-1st">
                        <span
                          className="benefit">{benefitByKey(quote.meta.benefits, 'Baggage')}</span>
                      </div>
                      <div className="flex1 quote-value-container">
                        { /* Benefits Icon*/ }
                        <BenefitsModal scheme={quote.meta.scheme} benefits={quote.meta.benefits}/>
                      </div>

                      {!isAnnual && (
                        <div className="flex2 quote-value-container">
                          {this.renderDiscount(quote)}
                          <span
                            className={quote.meta.premium.gross ? (this.schemeIsSelected(quote.meta.scheme) ? 'premium selected' : 'premium') : 'premium unquoted'}
                            onClick={() => {
                              quote.meta.premium.gross ? this.handleSchemeSelect(quote.meta.scheme) : false
                            }}>
                            <div className={(quote.meta.premium.gross && this.schemeIsSelected(quote.meta.scheme)) ? ('quote-value-selected') :('quote-value')} >
                              {quote.meta.premium.gross ? displayCurrency(quote.meta.premium.gross, quote.meta.premium.currency) : 'N/A'}
                              {(quote.meta.premium.gross && this.schemeIsSelected(quote.meta.scheme)) ? (
                                <span className="icon-padding">
                                  <Icon name="check-circle"/>
                                </span>
                              ) : (
                                <span className="icon-padding">
                                  <Icon name="circle-thin"/>
                                </span>
                              )}
                            </div>
                          </span>
                          {quote.meta.premium.gross ? ( <span className="incl-ipt-mssg">incl. {taxType}</span> ) : '' }
                        </div>
                      )}

                      <div className="flex2 quote-value-container">
                        {!isAnnual ? (
                          <div>
                            {this.renderDiscount(annualQuote)}
                            <span
                              className={(annualQuote && annualQuote.meta.premium.gross) ? (this.schemeIsSelected(annualQuote.meta.scheme) ? 'premium selected' : 'premium') : 'premium unquoted'}
                              onClick={() => {
                                (annualQuote && annualQuote.meta.premium.gross) ? this.handleSchemeSelect(annualQuote.meta.scheme) : false
                              }}>
                              <div className={(annualQuote && annualQuote.meta.premium.gross) && this.schemeIsSelected(annualQuote.meta.scheme) ? ('quote-value-selected') :('quote-value')} >
                                {(annualQuote && annualQuote.meta.premium.gross) ? displayCurrency(annualQuote.meta.premium.gross, annualQuote.meta.premium.currency) : 'N/A'}
                                {((annualQuote && annualQuote.meta.premium.gross) && this.schemeIsSelected(annualQuote.meta.scheme)) ? (
                                  <span className="icon-padding">
                                    <Icon name="check-circle"/>
                                  </span>
                                ) : (
                                  <span className="icon-padding">
                                    <Icon name="circle-thin"/>
                                  </span>
                                )}
                              </div>
                            </span>
                          </div>
                        ) : (
                          <div>
                            {this.renderDiscount(quote)}
                            <span
                              className={quote.meta.premium.gross ? (this.schemeIsSelected(quote.meta.scheme) ? 'premium selected' : 'premium') : 'premium unquoted'}
                              onClick={() => {
                                quote.meta.premium.gross ? this.handleSchemeSelect(quote.meta.scheme) : false
                              }}>
                              <div className={(quote.meta.premium.gross && this.schemeIsSelected(quote.meta.scheme)) ? ('quote-value-selected') :('quote-value')} >
                              {quote.meta.premium.gross ? displayCurrency(quote.meta.premium.gross, quote.meta.premium.currency) : 'N/A'}
                              {(quote.meta.premium.gross && this.schemeIsSelected(quote.meta.scheme)) ? (
                                <span className="icon-padding">
                                    <Icon name="check-circle"/>
                                  </span>
                              ) : (
                                <span className="icon-padding">
                                    <Icon name="circle-thin"/>
                                  </span>
                              )}
                              </div>
                            </span>
                          </div>
                        )}
                        {quote.meta.premium.gross ? ( <span className="incl-ipt-mssg">incl. {taxType}</span> ) : '' }
                      </div>
                    </div>
                    <div className="">
                      {quote.meta.referrals.length > 0 && (
                        <div className="quote-error-box">
                          <ul>

                            {quote.meta.referrals.map((referral, i) => {
                              if (referral) {
                                return (<li key={i}><strong>Referral:</strong> {referral.reason}</li>)
                              }
                            })}
                          </ul>
                        </div>
                      )}

                      {quote.meta.errors.length > 0 && (
                        <div className="quote-error-box">
                          <ul>
                            {quote.meta.errors.map((error, i) => {
                              return (<li key={i}>{error}</li>)
                            })}
                          </ul>
                        </div>
                      )}

                      {quote.meta.declines.length > 0 && (
                        <div className="quote-error-box">
                          <ul>
                            {quote.meta.declines.map((declines, i) => {
                              return (<li key={i}>{declines.reason}</li>)
                            })}
                          </ul>
                        </div>
                      )}

                      {_.get(quote, 'meta.information.medical', []).length > 0 && (
                        <div className='quote-info-box'>
                          <ul>
                            {quote.meta.information.medical.map((info, i) => {
                              return (<li key={i}>{info}</li>)
                            })}
                          </ul>
                        </div>
                      )}

                    </div>
                  </div>
                  )
                }
              })}
            </StickyContainer>
          </div>
        ) : !product.isCalculating && (
            <div>
              {requiresReferral ? (
                <div>
                  <h3>This quote needs to be referred to the underwriter for approval</h3>

                  <ul>
                    {referrals.map((referral, i) => (
                      <li key={i}>{referral}</li>
                    ))}
                  </ul>

                  <div className="row">
                    <div className="col-xs-12">
                      <label className="control-label">Please add any notes for the underwriter</label>
                      <div className="col-xs-12">
                        <Field
                          name={'meta.note.content'}
                          type="textarea"
                          component={TextField}
                        />
                      </div>
                    </div>
                  </div>

                  <Button
                    label="Save and request approval"
                    bsStyle="primary"
                    className="pull-right"
                    isLoading={product.isSaving}
                    handleClick={handleSubmit(::this.saveReferral)}
                  />
                </div>
              ) : (
                <div>
                  <h3>No products are available</h3>
                  <p>There are no schemes available based on your quotation criteria.</p>
                </div>
              )}
            </div>
          )}
      </div>
    )
  }
}

export default connect()(AvailableQuotes)
