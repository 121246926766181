import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector, change } from 'redux-form'
import { TextField, RadioGroup, CheckboxGroup } from '../../../../common/components/ReduxFormField'
import displayName from '../../../../helpers/displayName'
import { Box, Button, ButtonBar, HorizontalFormControl, DatePicker } from '../../../../common/components'
import { FormGroup, Col, ControlLabel } from 'react-bootstrap'
import TextArea from '../TextArea/TextArea'
import { openModal } from '../../../../common/components/Modal/ModalRedux'
import UploadModalComponent, { UploadModal } from '../../../../common/components/Upload/UploadModal'
import { setUploadAccept } from '../../../../common/components/Upload/UploadRedux'
import { clearFiles } from '../../../../common/components/Upload/UploadRedux'
import './ContentForm.scss'
import _ from 'lodash'

const FORM_NAME = 'contentForm'

class ContentForm extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    formValues: PropTypes.object,
    template: PropTypes.object.isRequired,
    resource: PropTypes.object
  }

  constructor() {
    super()

    this.state = {
      uploadKey: null
    }
  }

  resetState() {
    const { dispatch } = this.props

    this.setState({ uploadKey: null })
    dispatch(clearFiles())
  }

  componentWillReceiveProps(newProps) {
    if (newProps.template.id != this.props.template.id) {
      this.props.dispatch(change(FORM_NAME, 'data.relationships.template.data.id', newProps.template.id))
    }
  }

  handleUploadComplete() {
    const { dispatch, resource, change } = this.props
    const file = this.props.files[this.props.files.length - 1]

    if (!file) {
      return
    }

    dispatch(change('data.attributes.sections.' + this.state.uploadKey, file.id))
    dispatch(change('data.relationships.files.data', [
      ...resource.data.relationships.files.data,
      file
    ]))

    this.resetState()
  }

  handleUpload(uploadKey) {
    return () => {
      const { dispatch, reset, change } = this.props
      dispatch(setUploadAccept('*'))
      dispatch(openModal(UploadModal.MODAL_IDENTIFIER))

      this.setState({ uploadKey })
    }
  }

  getFileName(path) {
    const { resource, formValues, values } = this.props
    const fileId = _.get(formValues, path)

    if (!fileId) {
      return null
    }

    let files = []

    if (formValues.data.relationships.files) {
      files = formValues.data.relationships.files.data
        .filter(file => {
          return file.id === fileId && file.attributes
        })
    }

    if (files.length === 0 && resource.included) {
      files = resource.included
        .filter(file => {
          return file.id === fileId && file.type === 'files'
        })
    }

    return files.length > 0 ? files[0].attributes.name : null
  }

  render() {
    const { template, handleSubmit, submitting, formValues, uploadAccept } = this.props
    const includeSlug = ['article', 'page']

    const getInputType = (key, options, name) => {
      switch (options.fieldtype) {
        case 'date':
          return (
            <div key={key} className="row form-group">
              <div className="col-sm-8 form-horizontal">
                <Field
                  name={name}
                  label={displayName(key)}
                  placeholder={displayName(key)}
                  labelSize={2}
                  component={DatePicker}
                />
              </div>
            </div>
          )

        case 'text':
        case 'event':
          return (
            <div key={key} className="row form-group">
              <div className="col-sm-8 form-horizontal">
                <Field
                  name={name}
                  label={displayName(key)}
                  placeholder={displayName(key)}
                  labelSize={2}
                  component={HorizontalFormControl}
                />
              </div>
            </div>
          )

        case 'file':
          return (
            <div key={key} className="row form-group">
              <div className="col-sm-8 form-horizontal">
                <FormGroup>
                  <Col componentClass={ControlLabel} sm={2}>{displayName(key)}</Col>
                  <Col sm={10}>
                    {this.getFileName(name)} &nbsp;

                    <Button
                      type="button"
                      bsStyle="primary"
                      className="pad-right"
                      handleClick={::this.handleUpload(key)}
                      disabled={submitting}>
                      {submitting ? <i className="fa fa-cog fa-spin"/> : <i className="fa fa-upload"/> } Upload File
                    </Button>
                  </Col>
                </FormGroup>
              </div>
            </div>
          )

        case 'textarea':
          return (
            <div key={key} className="row form-group">
              <div className="col-sm-8 form-horizontal">
                <Field
                  name={name}
                  label={displayName(key)}
                  placeholder={displayName(key)}
                  labelSize={2}
                  type="textarea"
                  component={TextField}
                />
              </div>
            </div>
          )

        case 'markdown':
        default:
          return (
            <div key={key}>
              <label for={key} className="control-label">{displayName(key)}</label>
              <Field
                name={name}
                label={displayName(key)}
                placeholder={displayName(key)}
                labelSize={2}
                component={TextArea}
              />
            </div>
          )
      }
    }

    return (
      <Box>
        <h2>Content Details</h2>
        {template.id ? (
          <form className="content-form" onSubmit={handleSubmit}>
            <div className="row form-group">
              <div className="col-sm-9 form-horizontal">
                <Field
                  name="data.attributes.name"
                  label="Content"
                  placeholder="Add a unique name for this content..."
                  labelSize={2}
                  component={HorizontalFormControl}
                />
              </div>

              {/* PRIORITY OPTIONS */}
              <div className="row form-group">
                <div className="col-sm-9">
                  <div className="row form-group">
                    <div className="col-sm-10 adjust-form">
                      <div className="adjust-form-label">
                        Priority
                      </div>
                      <div className="adjust-form-field">
                        <Field
                          name="data.attributes.metadata.priority"
                          label=""
                          component={CheckboxGroup}
                          inline={true}
                          labelSize={2}
                          options={[
                            { label: 'High Alert', value: 'true' }
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>



              {(template.attributes.template_type == 'announcement') && (
                <div className="row form-group">
                  <div className="col-sm-9">
                    <div className="row form-group">
                      <div className="col-sm-10 adjust-form">
                        <div className="adjust-form-label">
                          Type
                        </div>
                        <div className="adjust-form-field">
                          <Field
                            name="data.attributes.announcement_type"
                            label=""
                            component={RadioGroup}
                            inline={true}
                            labelSize={3}
                            options={[
                              { label: 'Automatic', value: 'auto' },
                              { label: 'Temporary', value: 'temporary' }
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row form-group">
                      <div className="col-md-2">
                        <label className="date-control-label">Temporary Announcement</label>
                      </div>
                      <div className="col-sm-5 col-md-5">
                        <Field
                          name="data.attributes.from_date"
                          label="Start date"
                          minDateToday={true}
                          labelSize={4}
                          component={DatePicker}
                        />
                      </div>
                      <div className="col-sm-5 col-md-5">
                        <Field
                          name="data.attributes.to_date"
                          label="End date"
                          minDate={_.get(formValues, 'data.attributes.to_date')}
                          labelSize={4}
                          component={DatePicker}
                        />
                      </div>
                    </div>
                    <div>
                      <div className="row form-group">
                        <div className="col-sm-10 adjust-form">
                          <div className="adjust-form-label">
                            Fixed Announcement
                          </div>
                          <div className="adjust-form-field">
                            <Field
                              name="data.attributes.duration"
                              label=""
                              component={RadioGroup}
                              inline={true}
                              labelSize={3}
                              options={[
                                { label: '3 days', value: 3 },
                                { label: '7 days', value: 7 },
                                { label: '14 days', value: 14 }
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                      {_.get(formValues, 'data.attributes.duration') > 0 && (
                        <div className="row form-group">
                          <div className="col-sm-10 adjust-form">
                            <div className="adjust-form-label">
                              Prompt
                            </div>
                            <div className="adjust-form-field">
                              <Field
                                name="data.attributes.event"
                                label=""
                                component={RadioGroup}
                                inline={true}
                                labelSize={3}
                                options={[
                                  { label: 'Account opened', value: 'broker_approved' },
                                  { label: 'User added', value: 'user_created' }
                                ]}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {(includeSlug.indexOf(template.attributes.template_type) != -1) && (
                <div className="col-sm-8 form-horizontal">
                  <Field
                    name="data.attributes.slug"
                    label="Slug"
                    placeholder="my-article-url"
                    labelSize={2}
                    component={HorizontalFormControl}
                  />
                  <div className="help-block">the Url slug can only contain letters, numbers and hyphens</div>
                </div>
              )}
            </div>
            <div>
              {Object.keys(template.attributes.sections).map(section => {
                return getInputType(
                  section,
                  template.attributes.sections[section],
                  'data.attributes.sections.' + section
                )
              })}
            </div>

            <ButtonBar>
              <Button
                type="submit"
                bsStyle="primary"
                className="pull-right"
                disabled={submitting}>
                {submitting ? <i className="fa fa-cog fa-spin"/> : ('')} Save Content
              </Button>
            </ButtonBar>
          </form>
        ) : (
          <p>Please select a template to continue...</p>
        )}

        <UploadModalComponent onComplete={::this.handleUploadComplete} accept={uploadAccept} />
      </Box>
    )
  }
}

const form = reduxForm({ form: FORM_NAME })(ContentForm)
const selector = formValueSelector(FORM_NAME)
const mapStateToProps = (state, props) => {
  const values = selector(state,
    'data'
  )

  return {
    initialValues: props.resource,
    formValues: {
      data: values
    },
    files: state.upload.files,
    uploadAccept: state.upload.accept
  }
}

export default connect(mapStateToProps)(form)
