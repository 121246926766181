import React from 'react';
import { Icon } from '../../../../../common/components';
import { Button } from '../../../../../common/components/Button/Button';

class ActiveProducts extends React.Component {
  render() {
    return (
      <div className="mt-15" />
    );
  }
}

export default ActiveProducts;
