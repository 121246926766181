import React from 'react'

export const books = (
   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95 95">
     <title>Icons</title>
     <path d="M85.1,34.33,72.57,75.62A10,10,0,0,1,63.5,82.5H21.44c-4.65,0-9.62-3.69-11.3-8.43A8.44,8.44,0,0,1,10,68.28c.09-.91.27-1.82.32-2.92,0-.73-.36-1.32-.27-1.87.18-1.09,1.14-1.87,1.87-3.1a32.54,32.54,0,0,0,3.42-8.34c.23-.87-.23-1.87,0-2.64s1.09-1.5,1.55-2.32A27.4,27.4,0,0,0,20,38.8c.09-1-.36-2-.09-2.73.32-1,1.32-1.5,2-2.42a24,24,0,0,0,3.19-8.25c.09-.77-.36-1.55-.23-2.37s1.28-1.78,2-2.83c1.91-2.83,2.28-9.07,8.07-7.43l0,.14a10.39,10.39,0,0,1,2.32-.41H71.88a6.31,6.31,0,0,1,5.2,2.55A6.71,6.71,0,0,1,77.9,21L65.41,62.27c-2.14,7-3.33,8.57-9.11,8.57H16.7a2.11,2.11,0,0,0-1.73.68c-.36.55-.41,1,0,2,.91,2.64,4.06,3.19,6.56,3.19H63.55c1.69,0,3.65-1,4.15-2.6l13.67-45a7.25,7.25,0,0,0,.23-2.6,6.07,6.07,0,0,1,2.69,2A6.78,6.78,0,0,1,85.1,34.33ZM33,41.67H60.72a2.2,2.2,0,0,0,1.91-1.46l1-2.92a1,1,0,0,0-1-1.46H34.88A2.19,2.19,0,0,0,33,37.29l-1,2.92A1,1,0,0,0,33,41.67ZM36.79,30H64.5a2.19,2.19,0,0,0,1.91-1.46l1-2.92a1,1,0,0,0-1-1.46H38.66a2.2,2.2,0,0,0-1.91,1.46l-1,2.92A1,1,0,0,0,36.79,30Z"/>
   </svg>
)

export default books


