import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { Button, Modal, HorizontalFormControl, SelectInsurer, DatePicker } from '../../../../common/components'
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux'
import { copyScheme } from '../../redux/schemeActions'
import _ from 'lodash'

const FORM_NAME = 'copySchemeForm'

const validate = values => {
  const errors = {}

  if (!_.get(values, 'data.attributes.name', false)) {
    _.set(errors, 'data.attributes.name', 'Name Required')
  }

  if (!_.get(values, 'data.relationships.insurer.data.id', false)) {
    _.set(errors, 'data.relationships.insurer.data.id', 'Insurer Required')
  }

  if (!_.get(values, 'data.attributes.prefix', false)) {
    _.set(errors, 'data.attributes.prefix', 'Policy Prefix Required')
  }

  if (!_.get(values, 'data.attributes.from_date', false)) {
    _.set(errors, 'data.attributes.from_date', 'Start Date Required')
  }

  return errors
}

class SchemeCopyButton extends Component {
  static MODAL_IDENTIFIER = 'copy-scheme-modal';
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    productId: PropTypes.string.isRequired,
    submitting: PropTypes.bool.isRequired,
    formValues: PropTypes.object,
    handleSubmit: PropTypes.func
  }

  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded, callback } = this.props
    if (newProps.submitSucceeded && !submitSucceeded) {
      if (callback) {
        callback('')
      }
      dispatch(closeModal(SchemeCopyButton.MODAL_IDENTIFIER))
    }
  }

  handleSubmit(values) {
    const { resource, productId } = this.props
    return this.props.dispatch(copyScheme(resource.scheme.data.id, values, productId))
  }

  handleOpen() {
    this.props.dispatch(openModal(SchemeCopyButton.MODAL_IDENTIFIER))
  }

  render() {
    const { resource, handleSubmit, submitting } = this.props
    const scheme = resource.scheme

    const submitButton = (<Button
      type="submit"
      bsStyle="primary"
      isLoading={submitting}
      handleClick={handleSubmit(::this.handleSubmit)}
      label="Copy Scheme"
    />)

    return (
      <div className={this.props.className}>
        <Button
          type="button"
          label="Copy Scheme"
          bsStyle="primary"
          rightIcon="files-o"
          isLoading={resource.isCopying}
          handleClick={::this.handleOpen}
        />

        <Modal
          name={SchemeCopyButton.MODAL_IDENTIFIER}
          title={'Copy Scheme: ' + scheme.data.attributes.name}
          close={true}
          footer={submitButton}
        >
          <form onSubmit={handleSubmit(::this.handleSubmit)}>
            <div className="form-horizontal">
              <div className="col-xs-12">
                <Field
                  name={'data.attributes.name'}
                  label="New Scheme Name"
                  labelSize={4}
                  component={HorizontalFormControl}
                />
                <Field
                  name={'data.relationships.insurer.data.id'}
                  label="Insurer / Brand"
                  labelSize={4}
                  component={SelectInsurer}
                />
                <Field
                  name={'data.attributes.prefix'}
                  label="Policy Prefix"
                  labelSize={4}
                  component={HorizontalFormControl}
                />
                <Field
                  name={'data.attributes.from_date'}
                  label="Start Date"
                  labelSize={4}
                  minDate
                  component={DatePicker}
                />
              </div>
            </div>
          </form>
        </Modal>
      </div>
    )
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(SchemeCopyButton)
const selector = formValueSelector(FORM_NAME)
function mapStateToProps(state, props) {
  const values = selector(state,
    'data.id',
    'data.attributes.name',
    'data.attributes.prefix'
  )

  return {
    initialValues: {
      data: {
        attributes: {
          name: _.get(props.resource.scheme, 'data.attributes.name'),
          prefix: _.get(props.resource.scheme, 'data.attributes.prefix')
        },
        relationships: {
          insurer: {
            data: {
              id: _.get(props.resource.scheme, 'data.relationships.insurer.data.id')
            }
          }
        }
      }
    },
    formValues: values
  }
}

export default connect(mapStateToProps)(form)
