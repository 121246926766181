import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Tabs, Tab } from 'react-bootstrap'
import { getCustomer } from '../redux/customerActions'
import { isOwner, hasPermission } from '../../auth/redux/authActions'
import { Button, Loader } from '../../../common/components'
import InternalContainer from '../../layout/containers/Internal'
import CustomerDetails from '../components/CustomerDetails'
import CustomerApplications from '../components/CustomerApplications'
import CustomerTransactions from '../components/CustomerTransactions'
import CustomerNotes from '../components/CustomerNotes'
import CustomerAudit from '../components/CustomerAudit'

class CustomerShowContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    customer: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired
  }

  constructor(props, context) {
    super(props, context)

    this.state = {
      activeTab: 1
    }
  }

  componentWillMount() {
    this.props.dispatch(getCustomer(this.props.params.id))
  }

  handleTab(key) {
    this.setState({ activeTab: key })
  }

  render() {
    const resource = this.props.customer.resources[this.props.params.id]
    const buttons = (
      <Button
        bsStyle="default"
        label="Back"
        isBackButton={true}
        leftIcon="caret-left"
      />
    );
    let eventKey = 0;
    return (
      <InternalContainer title="Customer" buttons={buttons}>
        {resource ? (
          <Tabs activeKey={this.state.activeTab}
                id="customer-tabs"
                unmountOnExit={true}
                onSelect={::this.handleTab}
          >
            <Tab eventKey={++eventKey} title="Details">
              <CustomerDetails {...this.props} resource={resource}/>
            </Tab>
            <Tab eventKey={++eventKey} title="Quote History">
              <CustomerApplications {...this.props} resource={resource}/>
            </Tab>
            <Tab eventKey={++eventKey} title="Transactions">
              <CustomerTransactions {...this.props} resource={resource}/>
            </Tab>
            {isOwner() && (
              <Tab eventKey={++eventKey} title="Notes">
                <CustomerNotes {...this.props} resource={resource}/>
              </Tab>
            )}
            {isOwner() && hasPermission('system.audit_logs_view') && (
              <Tab eventKey={++eventKey} title="Audit">
                <CustomerAudit {...this.props} resource={resource}/>
              </Tab>
            )}
          </Tabs>
        ) : (
          <Loader />
        )}
      </InternalContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    customer: state.customer
  }
}

export default connect(mapStateToProps)(CustomerShowContainer)
