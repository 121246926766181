import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import InternalContainer from '../../layout/containers/Internal'
import ChallengeList from '../components/ChallengeList'

class ChallengeListContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    challenge: PropTypes.object.isRequired,
  }

  render() {

    return (
        <InternalContainer title="Challenge us">
          <ChallengeList {...this.props} />
        </InternalContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    challenge: state.challenge
  }
}

export default connect(mapStateToProps)(ChallengeListContainer)
