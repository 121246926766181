import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box, DataTable } from '../../../../common/components'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { FormattedDate, FormattedTime } from 'react-intl'
import { refreshData } from '../../../../common/components/DataTable/redux/dataTableActions'

export default class AccountTransactions extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    accountLabel: PropTypes.object.isRequired,
  };

  formatDate(cell) {
    return (
        <span><FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/></span>
    )
  }

  refreshList() {
    this.props.dispatch(refreshData('transactions'))
  }

  render() {
    const resource = this.props.resource.data.attributes

    return (
        <div>
          <Box>
            <h2 className="resource-name">{resource.name}{this.props.accountLabel}</h2>
            <DataTable
                source={'/accounts/ledger-entries'}
                autoFilter={this.props.resource.data.id}
                autoFilterType="filter[account]"
                name="transactions"
                showTotal={true}>

              <TableHeaderColumn dataField="id" isKey={true} hidden={true}>ID</TableHeaderColumn>
              <TableHeaderColumn dataField="created_at" dataFormat={this.formatDate}
                                 width={'180px'}>Date</TableHeaderColumn>
              <TableHeaderColumn dataField="description" width={'500px'}>Description</TableHeaderColumn>
              <TableHeaderColumn dataField="currency" dataAlign="center" width={'100px'}>Currency</TableHeaderColumn>
              <TableHeaderColumn dataField="formatted_amount" dataAlign="right" width={'200px'}>Amount</TableHeaderColumn>
              <TableHeaderColumn dataField="matched" width={'200px'}>Matched</TableHeaderColumn>
            </DataTable>
          </Box>
        </div>
    )
  }
}
