import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import {
  DatePicker,
  DatePickerLeisure,
  HorizontalFormControl,
  InfoButton,
  SelectCountry
} from '../../../../../../common/components'
import {
  RadioGroup,
  RadioGroupDisgusedAsButtons,
  SelectField
} from '../../../../../../common/components/ReduxFormField'
import { hasPermission } from '../../../../../auth/redux/authActions'
import _ from 'lodash'
import Markdown from 'react-markdown'
import moment from 'moment'

const TMK_PRODUCT = ['TMK', 'TMKH', 'TMKC', 'TMKA']

export class TripDetails extends Component {
  constructor(props) {
    super(props)
    this.transformDocLinks = this.transformDocLinks.bind(this)
  }

  transformDocLinks(url) {
    const documents = this.props.currentProduct.data.attributes.documents
    const documentKeys = _.keys(documents)

    let documentName = ''
    documentKeys.forEach((documentKey) => {
      if (url === 'data_protection_notice' && documentKey.includes('Data Protection Notice')) {
        documentName = documentKey;
      }
    })

    if (documentName) {
      return documents[documentName]
    }

    return url
  }

  render() {
    const { currentProduct, formValues, mta } = this.props
    const tripType = () => _.get(formValues, 'data.attributes.metadata.scheme_type')
    const regions = _.map(currentProduct.data.attributes.metadata.regions || {}, ((name, key) => {
      return {
        label: name,
        value: key,
      }
    }))
    const isMultipleAnnualOption = () => annualTripDuration.length > 1
    const annualTripDuration = _.map(_.get(currentProduct, 'data.attributes.metadata.trip_durations_annual', []), ((name, key) => {
      return {
        label: name,
        value: key,
      }
    }))
    const productCode = _.get(currentProduct, 'data.attributes.product_code')
    const isTmkProduct = () => TMK_PRODUCT.indexOf(productCode) !== -1
    const isHHPolicyRef = _.get(currentProduct, 'data.attributes.metadata.hh_policy_ref')
    const isSendingOrg = _.get(currentProduct, 'data.attributes.metadata.sending_organisation')
    const isServingCountry = _.get(currentProduct, 'data.attributes.metadata.serving_country')
    const isSendingField = _.get(currentProduct, 'data.attributes.metadata.sending_field')
    const tripTypeOptions = currentProduct.data.attributes.metadata.trip_type || {}
    const advancedDays = _.get(currentProduct, 'data.attributes.metadata.advanced_days')
    const startDate = _.get(formValues, 'data.attributes.metadata.start_date', null)
    const residency = _.get(formValues, 'data.attributes.metadata.residency', '')

    const tripOptions = [
      { label: 'Single Trip', value: 'single' },
      { label: 'Annual Multi-trip', value: 'annual' }
    ];

    if (productCode === 'BST') {
      tripOptions.pop();
    }

    const isTripLongStay = () => {
      return _.has(tripTypeOptions, 'longstay')
    }

    const definitions = _.get(currentProduct, 'data.attributes.metadata.definitions', {})
    const quotationDeclaration = _.get(currentProduct, 'data.attributes.metadata.quotation_declaration')
    const residencies = _.map(currentProduct.data.attributes.metadata.residencies || {}, ((name, key) => {
      return {
        label: name,
        value: key,
      }
    }))

    if (isTmkProduct && mta && startDate && residency && residency === 'channel islands') {
      if (moment(startDate).isBefore(moment('12/01/2020'))) {
        residencies.push({ label: 'Channel Islands', value: 'channel islands' })
      }
    }

    const minDate = hasPermission('policy.backdate_cover') ? moment().subtract(1, 'year').format() : moment().startOf('day').format()

    return (
      <div>
        <h5><strong>Declaration</strong></h5>
        <div className='quotation-declaration-container '>
          <div className='quotation-declaration'>
            <Markdown
              source={quotationDeclaration || 'I currently reside in the UK.'}
              linkTarget='_blank'
              transformLinkUri={this.transformDocLinks}
            />
          </div>
          <div className='agree-radio'>
            <Field
              name={'data.attributes.metadata.quotation_declaration'}
              component={RadioGroupDisgusedAsButtons}
              inline
              options={[
                { label: 'Yes', value: true, icon: 'check' },
                { label: 'No', value: false, icon: 'times' }
              ]}
            />
          </div>
        </div>

        {mta ? (
          <Field
            name='data.attributes.effective_date'
            label='Effective Date'
            labelSize={2}
            minDate={minDate}
            maxDate={moment().add(90, 'days').format()}
            component={DatePicker}
          />) : null
        }
        {productCode !== 'BST' && (
          <Field
            label='Residence'
            labelSize={2}
            name='data.attributes.metadata.residency'
            infoButton={<InfoButton content={definitions.residency}/>}
            component={SelectField}
            inline
            options={residencies}
          />

        )}

        {!isTmkProduct() && !isTripLongStay() && (
          <div className='form-horizontal margin-bottom'>
            <Field
              name='data.attributes.metadata.scheme_type'
              label='Trip Type'
              infoButton={<InfoButton content={definitions.trip_type}/>}
              labelSize={2}
              component={RadioGroup}
              inline
              options={tripOptions}
            />
          </div>
        )}

        {isHHPolicyRef && (
          <Field
            name={'data.attributes.metadata.hh_policy_ref'}
            label="HH Policy Ref"
            mdFieldSize={5}
            labelSize={2}
            component={HorizontalFormControl}
          />
        )}

        {isSendingOrg && (
          <Field
            name={'data.attributes.metadata.sending_organisation'}
            infoButton={<InfoButton content={definitions.sending_field}/>}
            label="Sending Organisation"
            mdFieldSize={5}
            labelSize={2}
            component={HorizontalFormControl}
          />
        )}

        {isServingCountry && (
          <Field
            name={'data.attributes.metadata.serving_country'}
            label="Serving Country"
            infoButton={<InfoButton content={definitions.serving_country}/>}
            mdFieldSize={5}
            labelSize={2}
            component={HorizontalFormControl}
          />
        )}

        {isSendingField && (
          <Field
            name={'data.attributes.metadata.sending_field'}
            label="Sending Field"
            mdFieldSize={5}
            labelSize={2}
            component={HorizontalFormControl}
          />
        )}

        { /* Cover Dates */}
        <div className='form-horizontal'>
          <div className=''>
            {tripType() === 'annual' || tripType() === 'longstay' ? (
              <Field
                name='data.attributes.metadata.start_date'
                label='Start date'
                labelSize={2}
                minDate={minDate}
                maxDate={moment().add(90, 'days').format()}
                component={DatePicker}
              />
            ) : (
              <div className='row select-data'>
                <div className='date-label'>
                  Start date
                </div>
                <div className='date-box'>
                  <div className='select-data-field1'>
                    <Field
                      infoButton={<InfoButton content={definitions.travel_dates}/>}
                      name='data.attributes.metadata.start_date'
                      label=''
                      minDate={minDate}
                      maxDate={moment().add(advancedDays ? advancedDays : 180, 'days').format()}
                      labelSize={2}
                      component={DatePickerLeisure}
                    />
                  </div>
                  <div className='label2'>
                    To
                  </div>
                  <div className='select-data-field2'>
                    <Field
                      name='data.attributes.metadata.end_date'
                      label=''
                      minDate={_.get(formValues, 'data.attributes.metadata.start_date')}
                      labelSize={2}
                      component={DatePickerLeisure}
                    />
                  </div>
                </div>
              </div>
            )}
            {tripType() === 'single' && (
              <div className='form-horizontal'>
                <Field
                  name='data.attributes.metadata.destinations'
                  label='Destinations'
                  placeholder='Search for countries...'
                  component={SelectCountry}
                  labelSize={2}
                  multi
                />
              </div>
            )}
            { /* Regions of Travel */}
            {(tripType() === 'annual' || tripType() === 'longstay') && (
              <div className='form-horizontal'>
                <Field
                  name='data.attributes.metadata.region'
                  label='Region:'
                  labelSize={2}
                  infoButton={<InfoButton content={definitions.region}/>}
                  mdColumnSize={4}
                  component={SelectField}
                  options={regions}
                />
              </div>)}
            { /* Display if there is more than one option in annualTripDuration */}
            <div className='form-horizontal'>
              {(tripType() === 'annual' || tripType() === 'longstay') && isMultipleAnnualOption() && (
                <Field
                  name='data.attributes.metadata.max_trip_duration'
                  label='Duration:'
                  labelSize={2}
                  infoButton={<InfoButton content={definitions.max_duration}/>}
                  mdColumnSize={3}
                  component={RadioGroup}
                  inline
                  options={annualTripDuration}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const FORM_NAME = 'LeisureTravelForm'
const form = reduxForm({ form: FORM_NAME })(TripDetails)
const selector = formValueSelector(FORM_NAME)
const mapStateToProps = (state, props) => {
  const values = selector(state,
    'data.id',
    'data.attributes.metadata.adults[0].address',
    'data.relationships.schemes.data',
    'data.attributes.metadata.adults',
    'data.attributes.metadata.children',
    'data.attributes.metadata.options',
    'data.attributes.metadata.scheme_type',
    'data.attributes.metadata.start_date',
    'data.attributes.metadata.end_date',
    'data.attributes.metadata.destinations',
    'data.attributes.metadata.region',
    'data.attributes.metadata.traveller_group',
    'data.attributes.metadata.residency',
    'data.attributes.metadata.trip_durations_annual',
  )

  return {
    auth: state.auth,
    initialValues: props.resource,
    form: 'LeisureTravelForm',
    formValues: values,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    product: state.product
  }
}

export default connect(mapStateToProps)(form)
