import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'moment/locale/en-gb';
import Cleave from 'cleave.js/react';


// TODO: INTL SUPPORT
const DATE_FORMAT = 'DD/MM/YYYY';
const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';

export default class SimpleDatePicker extends Component {
  static propTypes = {
    selected: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    showYear: PropTypes.bool,
    showTime: PropTypes.bool,
    minTime: PropTypes.string,
    maxTime: PropTypes.string,

  };

  getMoment(string) {
    moment.locale('en-gb');
    return string && moment(string, [moment.ISO_8601, DATE_FORMAT]);
  }

  onChange(date) {
    if (this.props.readOnly) {
      return;
    }

    this.props.onChange(date && date.format());
  }

  getDateCleave({ ...props }) {
    if (this.props.showTime) {
      return (
        <Cleave
          {...props}
          className={'form-control'}
          placeholder='Select Date and Time'
          options={{
            delimiters: ['/', '/', ' ', ':'],
            blocks: [2, 2, 4, 2, 2]
          }}
        />
      );
    }
    return (
      <Cleave
        {...props}
        className={'form-control'}
        placeholder='Select Date'
        options={{
          date: true,
          delimiter: '/',
          datePattern: ['d', 'm', 'Y'],
        }}
      />
    );

  }

  render() {
    return (<DatePicker
      customInput={this.getDateCleave()}
      className="form-control"
      placeholderText={this.props.showTime ? 'dd/mm/yyyy hh:mm'  : DATE_FORMAT.toLowerCase()}
      dateFormat={this.props.showTime ? DATE_TIME_FORMAT : DATE_FORMAT}
      showYearDropdown={this.props.showYear}
      showMonthDropdown={this.props.showYear}
      dropdownMode="select"
      showTimeSelect={this.props.showTime}
      selected={this.getMoment(this.props.selected)}
      startDate={this.getMoment(this.props.startDate)}
      endDate={this.getMoment(this.props.endDate)}
      minDate={this.getMoment(this.props.minDate)}
      maxDate={this.getMoment(this.props.maxDate)}
      minTime={this.getMoment(this.props.minTime)}
      maxTime={this.getMoment(this.props.maxTime)}
      timeFormat="HH:mm"
      onChange={::this.onChange}/>);
  }
}
