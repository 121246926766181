import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {login} from '../../redux/authActions'
import {Box, ButtonBar, Button, HorizontalFormControl} from '../../../../common/components'
import {reduxForm, Field} from 'redux-form'
import '../../../brochure/brochure.scss'
import './LoginForm.scss'

const validate = values => {
  const errors = {}

  if (!values.email) {
    errors.email = 'Required'
  }

  if (!values.password) {
    errors.password = 'Required'
  }

  return errors
}

class LoginForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired
  };

  render() {
    const { handleSubmit, submitting, error } = this.props

    return (
      <div className="full-width-section">
        <div className="login-box relative m-b-75">
          <div className="row">
            <div className="shadow-box">
              <div className="head">
                <h2>Agent Login</h2>
              </div>
              <Box className={'p-t-50 p-b-50 p-l-75 p-r-75'}>
                <div className='container-fluid'>
                  <form onSubmit={handleSubmit} className="login-form" method="post">
                    <div className="row">

                      <div className="col-xs-12">
                        <div className="row">
                          <div className="col-xs-12 m-b-15">
                            <div className='form-horizontal'>
                              <div className="invalid-error">
                                {error && <strong>{error}</strong>}
                              </div>
                              <Field
                                name="email"
                                type="email"
                                labelSize={0}
                                placeholder="Email address"
                                disabled={submitting}
                                component={HorizontalFormControl}
                                addonBefore={<i className="fa fa-envelope fa-2"/>}

                              />
                            </div>
                          </div>
                          <div className="col-xs-12 m-b-15">
                            <div className='form-horizontal'>
                              <Field
                                name="password"
                                labelSize={0}
                                type="password"
                                placeholder="Password"
                                disabled={submitting}
                                component={HorizontalFormControl}
                                addonBefore={<i className="fa fa-lock fa-2"/>}
                              />
                            </div>
                          </div>
                          <div className="col-xs-12">
                            <div className='form-horizontal'>
                              <Button
                                type="submit"
                                bsStyle="default"
                                className="pull-right m-l-10"
                                disabled={submitting}
                              >
                                {submitting ? <i className="fa fa-cog fa-spin"/> : <i className="fa fa-sign-in"/>} login
                              </Button>

                              <Button
                                link={true}
                                bsStyle="primary"
                                className="m-b-10 pull-right"
                                label="forgotten password?"
                                //size="xm"
                                to="/password/remind"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </Box>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'login',
  validate
})(LoginForm)
