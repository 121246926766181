import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { InfoLabel, Box, Button, ButtonBar } from '../../../../common/components'
import getIncludedResource from '../../../../helpers/getIncludedResource'
import { hasPermission } from '../../../auth/redux/authActions'
import { suspendAccount, reinstateAccount, viewStatement } from '../../redux/accountActions'
import displayName from '../../../../helpers/displayName'
import './styles.scss'

export default class AccountDetails extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    account: PropTypes.object.isRequired,
    resource: PropTypes.object.isRequired,
    accountLabel: PropTypes.object.isRequired,
  };

  suspendAccount() {
    this.props.dispatch(suspendAccount(this.props.resource.data.id))
  }

  reinstateAccount() {
    this.props.dispatch(reinstateAccount(this.props.resource.data.id))
  }

  viewStatement() {
    this.props.dispatch(viewStatement(this.props.resource.data.id))
  }

  render() {
    const resource = this.props.resource.data.attributes
    const organisation = getIncludedResource(this.props.resource.data, this.props.resource.included, 'organisation')
    const contacts = getIncludedResource(organisation.data, this.props.resource.included, 'accounts_contacts')

    return (
        <Box>
          <h2 className="resource-name">{resource.name}{this.props.accountLabel}</h2>

          <div className="row">
            <div className="col-xs-12">
              <div className="col-sm-7">
                <div className="row padded-row">
                  <div className="form-horizontal">
                    <InfoLabel label="Name" value={ resource.name } labelSize={2}/>
                  </div>
                </div>
                <div className="row padded-row">
                  <div className="form-horizontal">
                    {organisation.data.attributes && (
                        <InfoLabel
                            label="Organisation"
                            value={ organisation.data.attributes.name }
                            link={'/admin/organisations/' + organisation.data.id }
                            linkLabel="View organisation"
                            labelSize={2}/>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-sm-5">
                <div className="row padded-row">
                  <div className="form-horizontal">
                    <InfoLabel label="Account Type" value={ displayName(resource.account_type) } labelSize={6}/>
                  </div>
                </div>
                <div className="row padded-row">
                  <div className="form-horizontal">
                    <InfoLabel label="Accounting System Reference" value={ resource.accounting_system_reference }
                               labelSize={6}/>
                  </div>
                </div>
                <div className="row padded-row">
                  <div className="form-horizontal">
                    <InfoLabel label="Limit" value={ resource.limit } labelSize={6}/>
                  </div>
                </div>
                <div className="row padded-row">
                  <div className="form-horizontal">
                    <InfoLabel label="Status" value={ resource.suspended ? 'Suspended' : 'Active' } labelSize={6}/>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {contacts && contacts.length > 0 && (
              <div>
                <div className="hr"/>
                <h2>Accounts contacts</h2>
              </div>
          )}

          {Array.isArray(contacts) && contacts.map((contact, i) => (
              <div key={i} className="form-horizontal">
                <div className="row">
                  <div className="col-sm-6">
                    <InfoLabel label="Name" value={ contact.attributes.first_name + ' ' + contact.attributes.last_name }
                               labelSize={4}/>
                  </div>
                  <div className="col-sm-6">
                    <InfoLabel label="Phone" value={ contact.attributes.phone1 } labelSize={4}/>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <InfoLabel label="Email" value={ contact.attributes.email } labelSize={4}/>
                  </div>
                </div>

                <div className="hr transparent"/>
              </div>
          ))}

          <ButtonBar>
            { hasPermission('account.edit') && (
                <Button
                    link={true}
                    to={'/accounts/ledger/' + this.props.resource.data.id + '/edit'}
                    className="pull-right"
                    bsStyle="primary"
                    rightIcon="pencil-square-o">Edit Account</Button>
            )}
            { (hasPermission('account.edit') && !resource.suspended && !resource.locked) && (
                <Button
                    className="pull-right"
                    bsStyle="primary"
                    rightIcon="bomb"
                    isLoading={this.props.account.isSuspending}
                    handleClick={::this.suspendAccount}>Suspend Account</Button>
            )}
            { (hasPermission('account.edit') && resource.suspended) && (
                <Button
                    className="pull-right"
                    bsStyle="primary"
                    rightIcon="thumbs-up"
                    isLoading={this.props.account.isReinstating}
                    handleClick={::this.reinstateAccount}>Reinstate Account</Button>
            )}
            { (resource.account_type == 'debtor') && (
                <Button
                    className="pull-right"
                    bsStyle="primary"
                    rightIcon="list"
                    isLoading={this.props.account.isSubmitting}
                    handleClick={::this.viewStatement}>View Statement</Button>
            )}
          </ButtonBar>
        </Box>
    )
  }
}
