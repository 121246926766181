import React from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Col, FormControl, ControlLabel, InputGroup } from 'react-bootstrap'
import displayCurrency from '../../../helpers/displayCurrency'
import { Icon } from '../../components'
import './HorizontalFormControl.scss'
import Cleave from 'cleave.js/react';

const InputGroupAddon = InputGroup.Addon

const handleOnChange = (customOnChange, input, currency) => {
  return (event) => {
    if (currency) {
      event.target.value = event.target.value.replace(/[^\d.-]/g, '')
    }

    if (input) {
      input.onChange(event)
    }

    if (customOnChange) {
      customOnChange(event)
    }
  }
}

const filterCurrency = (value) => {
  if (! value) {
    return ''
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

const HorizontalFormControl = ({ input, meta, type, labelSize, mdFieldSize, label, infoButton, placeholder, addonAfter, addonBefore, monetaryValue, rows, disabled, note, currency, percentage, inputOnly, onChange, readOnly, isCalc, className, CleaveOptions, randomMaxWidth, currencyType}) => {
  const size = labelSize >= 0 ? labelSize : 2

  if (!readOnly) readOnly = false
  if (!isCalc) isCalc = false

  const formControlComponent = addonBefore || addonAfter || monetaryValue || currency || percentage ? (
          <InputGroup>
            { currency && (<InputGroupAddon><Icon name={ isCalc ? 'spinner' : (currencyType ? currencyType : 'gbp') } spin={isCalc}/></InputGroupAddon>) }
            { percentage && (<InputGroupAddon><Icon name={ isCalc ? 'spinner' : 'percent' } spin={isCalc}/></InputGroupAddon>) }
            { addonBefore && (<InputGroupAddon>{ addonBefore }</InputGroupAddon>)}
            <FormControl
                {...input}
                type={type}
                className={className}
                placeholder={currency ? filterCurrency(placeholder) : placeholder}
                value={currency ? filterCurrency(input.value) : input.value}
                rows={rows}
                disabled={disabled}
                onChange={handleOnChange(onChange, input, currency)}
                onBlur={handleOnChange(onChange, input, currency)}
                readOnly={readOnly}
            />
            { monetaryValue ? (<InputGroupAddon className="monetary-value">{ displayCurrency(monetaryValue) }</InputGroupAddon>) : ('') }
            { addonAfter ? (<InputGroupAddon>{ addonAfter }</InputGroupAddon>) : ('') }
          </InputGroup>
      ) : (
          <FormControl
              {...input}
              type={type}
              placeholder={placeholder}
              rows={rows}
              disabled={disabled}
              onChange={handleOnChange(onChange, input, currency)}
              onBlur={handleOnChange(onChange, input, currency)}
              readOnly={readOnly}
          />
      )

  if (inputOnly) {
    return formControlComponent
  }

  const cardNumberField = (
    <Cleave
      className={className + ' form-control'}
      placeholder={placeholder}
      options={{ creditCard: true, creditCardStrictMode: true }}
      {...input}
    />
  )

  return (
    <FormGroup
      controlId={input ? input.name : label}
      className={(meta && meta.touched && meta.error ? ' has-error' : '')}
    >
      {!!size &&
        <Col sm={size}>
          <ControlLabel>
            {label}
          </ControlLabel>
          {' '}{infoButton}
        </Col>
      }
      <Col sm={(12 - size)} md={mdFieldSize ? mdFieldSize : 0} className={randomMaxWidth ? 'address-fields' : ''}>
        {type === 'cardNumber' ? cardNumberField : formControlComponent}
        {note && (
          <div className="note">
            {note}
          </div>
        )}
        {(meta && meta.touched && meta.error) && (
          <span className="input-error">
            {meta.error}
          </span>
        )}
      </Col>
    </FormGroup>
  )
}

HorizontalFormControl.propTypes = {
  input: PropTypes.object,
  meta: PropTypes.object,
  labelSize: PropTypes.number,
  mdFieldSize: PropTypes.number,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  addonAfter: PropTypes.string,
  addonBefore: PropTypes.string,
  currency: PropTypes.bool,
  percentage: PropTypes.bool,
  inputOnly: PropTypes.bool,
  rows: PropTypes.number,
  disabled: PropTypes.bool,
  monetaryValue: PropTypes.string,
  note: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  isCalc: PropTypes.bool,
  className: PropTypes.string
}

export default HorizontalFormControl
