import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, FieldArray, formValueSelector, reset } from 'redux-form'
import { RadioGroup, SelectField } from '../../../../common/components/ReduxFormField'
import {
  Button,
  HorizontalFormControl,
  SelectInsurer,
  SelectCurrency,
  SelectTaxRate,
  InfoLabel
} from '../../../../common/components'
import ActivateSchemeButton from '../ActivateSchemeButton'
import EndSchemeButton from '../EndSchemeButton'
import Handlers from '../Handlers'
import { editRates, importRates } from '../../redux/schemeActions'
import { SchemeCopyButton } from '../SchemeCopyButton'
import { FormattedDate, FormattedTime } from 'react-intl'
import getIncludedResource from '../../../../helpers/getIncludedResource'
import { SchemeAssignClaimsHandler } from '../SchemeAssignClaimsHandler'
import { SchemeAssignAssistanceHandler } from '../SchemeAssignAssistanceHandler'
import _ from 'lodash'
import './styles.scss'

const FORM_NAME = 'schemeDetailsForm'

class SchemeDetailsForm extends Component {
  static propTypes = {
    scheme: PropTypes.object.isRequired,
    productId: PropTypes.string.isRequired,
    formValues: PropTypes.object.isRequired,
    schemeStatus: PropTypes.string.isRequired,
    schemeLabel: PropTypes.object.isRequired,
  }

  clearForm () {
    this.props.dispatch(reset('schemeDetailsForm'))
  }

  render() {
    const { scheme, submitting, handleSubmit, formValues, schemeStatus, schemeLabel, productId, pristine } = this.props
    const resource = scheme.scheme
    const claimsHandler = getIncludedResource(resource.data, resource.included, 'claims_handler')
    const assistanceHandler = getIncludedResource(resource.data, resource.included, 'assistance_handler')
    const editable = schemeStatus === 'test'

    return (
      <div className="scheme-details-form">
        <form onSubmit={handleSubmit}>
          <h2 className="resource-name">{resource.data.attributes.name}&nbsp;
            ({resource.data.attributes.cover_level}){schemeLabel}</h2>

          <div className="row form-horizontal">
            <div className="col-sm-6">
              <Field
                name="data.attributes.name"
                label="Scheme Name"
                labelSize={4}
                component={editable ? HorizontalFormControl : InfoLabel}
              />
              <Field
                name="data.attributes.cover_level"
                label="Cover Level"
                labelSize={4}
                component={editable ? HorizontalFormControl : InfoLabel}
              />
              <Field
                name="data.attributes.prefix"
                label="Policy Prefix"
                labelSize={4}
                component={editable ? HorizontalFormControl : InfoLabel}
              />
              <Field
                name="data.attributes.from_date"
                label="Start Date"
                labelSize={4}
                date={true}
                component={InfoLabel}
              />
            </div>

            <div className="col-sm-6">
              <InfoLabel
                label="Status"
                labelSize={4}
                value={resource.data.attributes.status}
              />
              <Field
                name="data.relationships.insurer.data.id"
                label="Insurer / Brand"
                labelSize={4}
                component={editable ? SelectInsurer : InfoLabel}
              />
              <InfoLabel
                label="Underwriter"
                value={_.get(formValues, 'data.meta.underwriter', 'Default insurer')}
                labelSize={4}
              />
              <Field
                name="data.attributes.to_date"
                label="End Date"
                labelSize={4}
                date={true}
                component={InfoLabel}
              />
            </div>
          </div>

          <div className="hr"/>

          <div className="row form-horizontal">
            <div className="col-sm-6">
              <Field
                name="meta.currency"
                label="Currency"
                labelSize={4}
                component={editable ? SelectCurrency : InfoLabel}
              />
              <Field
                name="meta.tax_rate"
                label="Tax Rate"
                labelSize={4}
                valueKey="attributes.name"
                component={editable ? SelectTaxRate : InfoLabel}
              />
              <Field
                name="meta.tax_type"
                label="Tax Type"
                labelSize={4}
                options={[
                  { label: 'Inclusive', value: 'inclusive' },
                  { label: 'Exclusive', value: 'exclusive' }
                ]}
                component={editable ? SelectField : InfoLabel}
              />
            </div>

            <div className="col-sm-6">
              <Field
                name="meta.commission_rate"
                label="Commission Rate"
                labelSize={4}
                component={editable ? HorizontalFormControl : InfoLabel}
              />
              <Field
                name="meta.commission_type"
                label="Commission Type"
                labelSize={4}
                options={[
                  { label: 'Margin', value: 'margin' },
                  { label: 'Markdown', value: 'markdown' },
                  { label: 'Simple', value: 'simple' }
                ]}
                component={editable ? SelectField : InfoLabel}
              />
              <Field
                name="meta.standard_excess"
                label="Standard Excess"
                labelSize={4}
                component={editable ? HorizontalFormControl : InfoLabel}
              />
              <p><strong>Note:</strong> Please enter £/€ follow by a space and the desired amount - example: £ 50</p>
            </div>
          </div>

          <div className="hr"/>

          <div className="row form-horizontal">
            <div className="col-sm-6">
              <Field
                name="meta.rules.minimum_premium"
                label="Minimum Premium"
                labelSize={4}
                currency={true}
                component={editable ? HorizontalFormControl : InfoLabel}
              />
              <Field
                name="meta.rules.maximum_premium"
                label="Maximum Premium"
                labelSize={4}
                currency={true}
                component={editable ? HorizontalFormControl : InfoLabel}
              />
              <Field
                name="meta.rules.maximum_premium_per_insured"
                label="Maximum (Per insured)"
                labelSize={4}
                currency={true}
                component={editable ? HorizontalFormControl : InfoLabel}
              />
              {/* This is removed temporarily as breaks when there are arguments */}
              {/*<Field*/}
                {/*name="meta.rules.max_cover_period"*/}
                {/*label="Maximum cover period"*/}
                {/*labelSize={4}*/}
                {/*component={editable ? HorizontalFormControl : InfoLabel}*/}
              {/*/>*/}
            </div>

            <div className="col-sm-6">
              <Field
                name="meta.rules.cancellation_period"
                label="Cancellation period"
                labelSize={4}
                component={editable ? HorizontalFormControl : InfoLabel}
              />
              <Field
                name="meta.rules.max_group_size"
                label="Maximum group size"
                labelSize={4}
                component={editable ? HorizontalFormControl : InfoLabel}
              />
              <Field
                name="meta.rules.quote_valid_for_days"
                label="Quote validity days"
                labelSize={4}
                component={editable ? HorizontalFormControl : InfoLabel}
              />
              {editable ? (
                <Field
                  name={'data.attributes.renews'}
                  label="Renewable"
                  labelSize={4}
                  inline={true}
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false }
                  ]}
                  component={RadioGroup}
                />
              ) : (
                <InfoLabel
                  label="Renewable"
                  labelSize={4}
                  value={_.get(formValues, 'data.attributes.renews') === true ? 'Yes' : 'No' }
                />
              )}

            </div>
          </div>

          <div className="hr"/>

          <div className="row">
            <div className="col-xs-12">
              <Field
                name="data.attributes.metadata.sms_message"
                label="SMS Message Text"
                labelSize={2}
                type="textarea"
                component={editable ? HorizontalFormControl : InfoLabel}
              />
            </div>
          </div>

          <div className="hr"/>

          <div className="row claims-handlers">
            <div className="col-sm-4 col-md-2">
              <label className="control-label">Claims handlers</label>
            </div>
            <div className="col-sm-8 col-md-10">
              <div className="assign-handler-container">
                <div className="row">
                  <div className="col-xs-4">
                    <SchemeAssignClaimsHandler
                      resource={resource}
                    />
                  </div>
                  <div className="col-xs-8">
                    {claimsHandler && (
                      <InfoLabel
                        value={_.get(claimsHandler, 'data.attributes.name', 'Claims Handler not assigned')}
                      />
                    )}
                  </div>
                </div>
              </div>
              <FieldArray
                name="data.attributes.metadata.claims_handlers"
                component={Handlers}
                disabled={!editable}
              />
            </div>
          </div>

          <div className="hr"/>

          <div className="row assistance-handlers">
            <div className="col-sm-4 col-md-2">
              <label className="control-label">Assistance handlers</label>
            </div>
            <div className="col-sm-8 col-md-10">
              <div className="assign-handler-container">
                <div className="row">
                  <div className="col-xs-4">
                    <SchemeAssignAssistanceHandler
                      resource={resource}
                    />
                  </div>
                  <div className="col-xs-8">
                    {assistanceHandler && (
                      <InfoLabel
                        value={_.get(assistanceHandler, 'data.attributes.name', 'Assistance Handler not assigned')}
                      />
                    )}
                  </div>
                </div>
              </div>
              <FieldArray
                name="data.attributes.metadata.assistance_handlers"
                component={Handlers}
                disabled={!editable}
              />
            </div>
          </div>

          <div className="hr"/>

          <div className="row scheme-rates">
            <div className="col-sm-3 col-md-2 hidden-xs scheme-logo-container">
              <img src={resource.data.attributes.logo} className="img-responsive insurer-logo"/>
            </div>
            <div className="col-xs-6 col-sm-3 col-md-4">
              <p><strong>Created at:</strong> <FormattedDate value={resource.data.attributes.created_at}/>&nbsp;
                <FormattedTime value={resource.data.attributes.created_at}/></p>
              <p><strong>Last modified:</strong> <FormattedDate value={resource.data.attributes.updated_at}/>&nbsp;
                <FormattedTime value={resource.data.attributes.updated_at}/></p>
              <p><strong>Rates last updated:</strong> { resource.data.attributes.metadata.rates_updated ?
                <span><FormattedDate value={resource.data.attributes.metadata.rates_updated}/>&nbsp;<FormattedTime value={resource.data.attributes.metadata.rates_updated}/></span> : 'N/A' }
              </p>
            </div>
            <div className="col-xs-6">
              <Button
                type="button"
                label={schemeStatus === 'test' ? 'View/Edit rates' : 'View rates'}
                bsStyle={schemeStatus === 'test' ? 'primary' : 'default'}
                className="pull-right"
                isLoading={scheme.isEditing}
                handleClick={() => {
                  this.props.dispatch(editRates(resource))
                }}
              />
              {schemeStatus === 'test' && (
                <Button
                  type="button"
                  label="Import rates"
                  bsStyle="primary"
                  className="pull-right pad-right"
                  isLoading={scheme.isImporting}
                  handleClick={() => {
                    this.props.dispatch(importRates(resource))
                  }}
                />
              )}

            </div>
          </div>

          <div className="hr transparent"/>

          <div className="row actions">
            <div className="col-xs-12 clearfix">
              <SchemeCopyButton
                resource={scheme}
                productId={productId}
                className="pull-left pad-right"
              />

              {schemeStatus === 'test' && (
                <div>
                {pristine && (
                  <ActivateSchemeButton
                    resource={resource}
                    label="Activate Scheme"
                    bsStyle="primary"
                    className="pull-left pad-right"
                  />
                  )}
                  <EndSchemeButton
                    resource={resource}
                    label="Deactivate Scheme"
                    bsStyle="primary"
                    className="pull-left"
                  />
                </div>
              )}

              {schemeStatus === 'active' && (
                <EndSchemeButton
                  resource={resource}
                  label="End Scheme"
                  bsStyle="primary"
                  className="pull-left"
                />
              )}

              {schemeStatus === 'test' ? (
                <Button
                  type="submit"
                  label="Save Changes"
                  bsStyle="primary"
                  className="pull-right"
                  isLoading={!scheme.isCopying && submitting}
                />
              ) : (
                schemeStatus !== 'pending'  && (
                <Button
                  type="submit"
                  label="Create New Version"
                  bsStyle="primary"
                  rightIcon="plus-circle"
                  className="pull-right"
                  isLoading={!scheme.isCopying && submitting}

                />  )
              )}
              {!pristine && (
                <Button
                  label="Clear Changes"
                  bsStyle="primary"
                  className="pull-right pad-right"
                  handleClick={::this.clearForm}
                />
              )}
            </div>
          </div>
        </form>
      </div>
    )
  }
}

const form = reduxForm({ form: FORM_NAME })(SchemeDetailsForm)
const selector = formValueSelector(FORM_NAME)
const mapStateToProps = (state, props) => {
  const values = selector(state,
    'data.id',
    'meta.underwriter',
    'data.attributes.renews'
  )

  return {
    initialValues: props.scheme.scheme,
    formValues: values,
    enableReinitialize: true
  }
}
export default connect(mapStateToProps)(form)
