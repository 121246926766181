import React from 'react'
import _ from 'lodash'
import { Field } from 'redux-form'
import { connect } from 'react-redux'
import { Row, Col, FormControl } from 'react-bootstrap'
import { Box } from '../../../../../../common/components'
import SelectField from '../../../../../../common/components/ReduxFormField/SelectField'
import { CheckboxGroup, TextField } from '../../../../../../common/components/ReduxFormField'

class Products extends React.Component {
  constructor(props) {
    super(props)
    this.state = { enableAll: true }
  }

  componentWillMount() {
    const { dispatch, change, products, formValues } = this.props

    {products && _.map(products, (product, i) => {
        dispatch(change('data.attributes.broker_commission[' + i + '].product', product.id))

        if (! _.get(formValues, 'data.attributes.broker_commission[' + i + '].enabled', false)) {
          dispatch(change('data.attributes.broker_commission[' + i + '].enabled', false))
        }
    })}
  }

  handleCommissionDefault(event) {
    const { formValues, dispatch, change } = this.props
    _.get(formValues, 'data.attributes.broker_commission', []).map((commission, i) => {
      dispatch(change('data.attributes.broker_commission[' + i + '].rate', event.target.value))
    })
  }

  handleEnableAll() {
    const { formValues, dispatch, change } = this.props
    this.setState({ enableAll: !this.state.enableAll })

    _.get(formValues, 'data.attributes.broker_commission', []).map((commission, i) => {
      dispatch(change('data.attributes.broker_commission[' + i + '].enabled', this.state.enableAll))
    })
  }

  handleChangeEnableBrokerCommission = (value, key) => {
    const { dispatch, change } = this.props
    dispatch(change('data.attributes.broker_commission[' + key + '].enabled', value))
  }

  render() {
    const { products, formValues, dispatch, change } = this.props
    const commissionDefaults = [
      { label: 'Level one', value: 20 },
      { label: 'Level two', value: 25 },
      { label: 'Level three', value: 30 }
    ]

    return (
      <Box>
        <Row>
          <Col sm={12}>
            <h4 className='page-title'>Products</h4>
            <hr/>
            <div className="row">
              <div className="col-sm-3">
                <label className="control-label">Enable All</label>
              </div>
              <div className="col-sm-2">
                <div className="radio-container inline">
                  <div className="form-radio">
                    <input
                      id="enableAll"
                      type="checkbox"
                      checked={!this.state.enableAll}
                      onChange={::this.handleEnableAll}
                    />
                    <label htmlFor="enableAll"/>
                  </div>
                  <div className="radio-label"/>
                </div>
              </div>
              <div className="col-sm-3">
                <label className="control-label">Default Commission Level</label>
              </div>
              <div className="col-sm-4">
                <FormControl
                  type="select"
                  placeholder="select"
                  componentClass="select"
                  onChange={::this.handleCommissionDefault}
                >
                  <option value="">Select...</option>
                  {commissionDefaults.map((commissionDefault, key) => {
                    return (
                      <option key={'defaults' + key} value={commissionDefault.value}>
                        {commissionDefault.label}
                      </option>
                    )
                  })}
                </FormControl>
              </div>
            </div>
            <hr/>

            <div>
              <div className="commission-headings">
                <div className="row">
                  <div className="col-sm-6">
                    <label className="control-label">Product</label>
                  </div>
                  <div className="col-sm-2">
                    <label className="control-label">Enabled</label>
                  </div>
                  <div className="col-sm-4 ">
                    <label className="control-label">Commission Rate</label>
                  </div>
                </div>
              </div>

              {products && _.map(products, (product, i) => {
                return (
                  <div className="commission-row" key={i}>
                    <div className="row">
                      <div className="col-sm-6">
                        <label className="control-label">{product.attributes.name}</label>
                      </div>
                      <div className="col-sm-2">
                        <Field
                          labelSize={1}
                          inline={true}
                          component={CheckboxGroup}
                          name={'data.attributes.broker_commission[' + i + '].enabled'}
                          onChange={(value) => this.handleChangeEnableBrokerCommission(value, i)}
                          value={_.get(formValues, 'data.attributes.broker_commission[' + i + '].enabled', '')}
                          options={[
                            { label: '', value: true }
                          ]}
                        />
                      </div>
                      <div className="col-sm-4">
                        <div className="row">
                          <div className="col-xs-6">
                            <SelectField
                              options={commissionDefaults}
                              name={'data.attributes.broker_commission[' + i + '].rate'}
                              input={{
                                value: _.get(formValues, 'data.attributes.broker_commission[' + i + '].rate', ''),
                                onChange: (value) => {
                                  dispatch(change('data.attributes.broker_commission[' + i + '].rate', value))
                                }
                              }}
                            />
                          </div>
                          <div className="col-xs-6">
                            <Field
                              component={TextField}
                              name={'data.attributes.broker_commission[' + i + '].rate'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </Col>
        </Row>
      </Box>
    )
  }
}

export default connect()(Products)
