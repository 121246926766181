import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Pie } from 'react-chartjs-2'
import _ from 'lodash'
import './styles.scss'


export default class PieChart extends Component {

  static propTypes = {
    data: PropTypes.object.isRequired
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (_.isEqual(nextProps.data, this.props.data)) {
      return false
    }

    return true
  }

  chartData(data) {
    return {
      labels: [
        'Unconverted',
        'Issued',
        'Renewals'
      ],
      datasets: [
        {
          backgroundColor: [
            '#B3CF81',
            '#186DBF',
            '#191919'
          ],
          hoverBackgroundColor: [
            '#7eac49',
            '#164991',
            '#343434'
          ],
          data: [
            _.get(data, 'quotes.unconverted', 0),
            _.get(data, 'quotes.issued', 0),
            _.get(data, 'quotes.renewals', 0)
          ]
        }
      ]
    }
  }

  render() {
    const { data } = this.props

    return (
        <div className="chart-wrapper pie">
          <Pie
              data={this.chartData(data)}
              options={{
                responsive: true,
                cutoutPercentage: -1,
                legend: {
                  labels: {
                    boxWidth: 10,
                    fontSize: 10,
                  }
                }
              }}
          />
        </div>
    )
  }
}
