import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, InfoLabel, Modal, Loader } from '../../../../common/components'
import { getLedgerEntry } from '../../redux/accountActions'
import getIncludedResource from '../../../../helpers/getIncludedResource'
import { openModal } from '../../../../common/components/Modal/ModalRedux'
import { LedgerEntriesModal } from '../LedgerEntriesModal'
import _ from 'lodash'

class PremiumModal extends Component {
  static MODAL_IDENTIFIER = 'premium-modal';

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    ledgerPremium: PropTypes.object,
    ledgerEntry: PropTypes.object
  }

  componentWillUpdate(newProps) {
    const { ledgerPremium, dispatch } = this.props

    if (newProps.ledgerPremium !== ledgerPremium) {
      dispatch(getLedgerEntry(newProps.ledgerPremium.id, ['account', 'journal', 'batch', 'premium']))
    }
  }

  handleOpen() {
    this.props.dispatch(openModal(LedgerEntriesModal.MODAL_IDENTIFIER))
  }

  render() {
    const { ledgerPremium, ledgerEntry } = this.props
    let id = null
    let attributes = {}
    let account = {
      data: {
        id: ''
      }
    }
    let journal = {}
    let batch = {}

    if (_.get(ledgerEntry, 'data.attributes')) {
      id = ledgerEntry.data.id
      attributes = ledgerEntry.data.attributes
    }

    if (_.get(ledgerEntry, 'included')) {
      account = getIncludedResource(ledgerEntry.data, ledgerEntry.included, 'account')
      journal = getIncludedResource(ledgerEntry.data, ledgerEntry.included, 'journal')
      batch = getIncludedResource(ledgerEntry.data, ledgerEntry.included, 'batch')
    }

    const modalFooter = (
      <div>
        {_.get(account, 'data') && (
          <Button
            type="button"
            bsStyle="primary"
            className="pull-right"
            link={true}
            to={'accounts/ledger/' + account.data.id}
            label="View Account"
          />
        )}
        {!_.get(batch, 'data.attributes') && (
          <Button
            type="button"
            bsStyle="primary"
            className="pull-right pad-right"
            link={true}
            to={'accounts/' + account.data.id + '/matching'}
            label="Match"
          />
        )}
        {_.get(batch, 'data.attributes') && (
          <div>
            <Button
              type="button"
              bsStyle="primary"
              className="pull-right pad-right"
              handleClick={::this.handleOpen}
              label="Un-match"
            />
            <LedgerEntriesModal dispatch={this.props.dispatch} batch={batch.data}/>
          </div>
        )}
      </div>
    )

    return (
      <Modal
        name={PremiumModal.MODAL_IDENTIFIER}
        title={ledgerPremium.description + ' | ' + ledgerPremium.currency + ' ' + ledgerPremium.formatted_amount}
        footer={modalFooter}
        close={true}
      >
        { (!ledgerPremium || !_.get(ledgerEntry, 'data.attributes') || id !== ledgerPremium.id ) ? (
          <Loader />
        ) : (
          <div>
            <div className="row">
              <div className="col-sm-12">
                <div className="form-horizontal">
                  <InfoLabel label="Account" labelSize={4} value={attributes.account_name}/>
                  <InfoLabel label="Sub Account (Broker)" labelSize={4} value={attributes.broker_name}/>
                  <InfoLabel label="Reference" labelSize={4} value={attributes.accounting_reference}/>
                  <InfoLabel label="Date" labelSize={4} date={true} value={attributes.date}/>
                  <InfoLabel label="Description" labelSize={4} value={attributes.description}/>
                  <InfoLabel label="Amount" labelSize={4} value={attributes.formatted_amount}/>
                  <InfoLabel label="Created By" labelSize={4} value={journal.data.attributes.user_name}/>
                  <InfoLabel label="Date Created" labelSize={4} date={true} value={attributes.created_at}/>
                  { _.get(batch, 'data.attributes') && (
                    <div>
                      <InfoLabel label="Matched By" labelSize={4} value={batch.data.attributes.user_name}/>
                      <InfoLabel label="Date Matched" labelSize={4} date={true} value={batch.data.attributes.created_at}/>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    ledgerEntry: state.account.ledgerEntry
  }
}

export default connect(mapStateToProps)(PremiumModal)
