import * as _ from 'lodash'
import moment from 'moment'

const validate = values => {
  const errors = {}
  const mta = _.get(values, 'data.mta')

  let requiredFields = [
    { field: 'data.attributes.metadata.scheme_type', name: 'Type of cover' },
    { field: 'data.attributes.metadata.start_date', name: 'Start date' }
  ]

  const currentTab = _.get(values, 'data.current_tab', 1);

  if (currentTab !== 1) {
    requiredFields = [
      { field: 'data.attributes.metadata.scheme_type', name: 'Type of cover' },
      { field: 'data.attributes.metadata.start_date', name: 'Start date' },
      { field: 'data.attributes.metadata.traveller_group', name: 'Group type' }
    ]

    if (_.get(values, 'data.attributes.metadata.traveller_group') === 'family') {
      if (_.get(values, 'data.attributes.metadata.children').length < 1) {
        _.set(errors, 'data.attributes.metadata.children', 'Family policy must have at least one child')
      }
    }

    _.get(values, 'data.attributes.metadata.adults', []).map((adult, i) => {
      if (i === 0 && !adult.title) {
        _.set(errors, 'data.attributes.metadata.adults[' + i + '].title', 'Title is required')
      }

      if (i === 0 && !adult.first_name) {
        _.set(errors, 'data.attributes.metadata.adults[' + i + '].first_name', 'First name is required')
      }

      if (i === 0 && !adult.last_name) {
        _.set(errors, 'data.attributes.metadata.adults[' + i + '].last_name', 'Last name is required')
      }

      if (adult.dob === '' || !adult.dob) {
        _.set(errors, 'data.attributes.metadata.adults[' + i + '].dob', 'Date of birth is required')
      }
    })

    _.get(values, 'data.attributes.metadata.children', []).map((child, i) => {
      if (child.dob === '') {
        _.set(errors, 'data.attributes.metadata.children[' + i + '].dob', 'Date of birth is required')
      }
    })

    if (currentTab === 3) {
      _.get(values, 'data.attributes.metadata.medical_declaration_questions', []).map((declaration, i) => {
        if (declaration.type === 'question' && declaration.answer === '') {
          _.set(errors, 'data.attributes.metadata.medical_declaration_questions[' + i + '].answer', 'Medical declaration is required')
        }
      })
    }

    // Effective Date for MTA
    if(mta) {
      if (!_.get(values, 'data.attributes.effective_date') && currentTab > 1) {
        _.set(errors, 'data.attributes.effective_date', 'Effective date is required')
      }
    }

    /* Validation to check if answer is YES for medical there is a condition added: */
    let requiresScreening = false
    let isConditionAdded = false
    _.get(values, 'data.attributes.metadata.medical_declaration_questions', []).map((declaration, i) => {
      if (_.get(values, 'data.attributes.metadata.medical_declaration_questions[' + i + '].answer', false) === 'true') {
        requiresScreening = true
      }
    });

    // Verify if a condition is added for Adult
    if (requiresScreening) {
      _.map(_.get(values, 'data.attributes.metadata.adults', []), (adult) => {
        if (!_.isEmpty(_.get(adult, 'screening.meta'))) {
          isConditionAdded = true
        }
      })
    }
    // Verify if a condition is added for Children
    if (requiresScreening) {
      _.map(_.get(values, 'data.attributes.metadata.children', []), (children) => {
        if (!_.isEmpty(_.get(children, 'screening.meta'))) {
          isConditionAdded = true
        }
      })
    }

    const childrenMaxAge = _.get(values, 'data.children_max_age', 18);
    _.get(values, 'data.attributes.metadata.children', []).map((child, i) => {
      const age = moment().diff(moment(child.dob), 'years');
      if (age >= childrenMaxAge) {
        _.set(errors, 'data.attributes.metadata.children[' + i + '].dob', 'Child is older that maximum age')
      }
    })

    // Add the error in case of no condition is added:
    if (requiresScreening === true &&  isConditionAdded === false) {
      _.set(errors, 'data.attributes.metadata.declaration', 'Please add a condition')
    }


  }

  requiredFields.map(required => {
    if (!_.get(values, required.field)) {
      _.set(errors, required.field, required.name + ' is required')
    }
  })

  if (_.get(values, 'data.attributes.metadata.scheme_type') === 'annual' || _.get(values, 'data.attributes.metadata.scheme_type') === 'longstay') {
    if (!_.get(values, 'data.attributes.metadata.region')) {
      _.set(errors, 'data.attributes.metadata.region', 'Please select a region')
    }
  } else {
    if (_.get(values, 'data.attributes.metadata.destinations', []).length === 0) {
      _.set(errors, 'data.attributes.metadata.destinations', 'Please select a destination')

      if (!_.get(values, 'data.attributes.metadata.end_date')) {
        _.set(errors, 'data.attributes.metadata.end_date', 'Please select an end date')
      }
    }
  }

  if (_.get(values, 'data.attributes.metadata.serving_country') === '') {
    _.set(errors, 'data.attributes.metadata.serving_country', 'Serving Country' + ' is required')
  }

  if (_.get(values, 'data.attributes.metadata.sending_field') === '') {
    _.set(errors, 'data.attributes.metadata.sending_field', 'Serving Field' + ' is required')
  }

  const quotationDeclaration = (_.get(values, 'data.attributes.metadata.quotation_declaration') === true
    || _.get(values, 'data.attributes.metadata.quotation_declaration') === 'true')

  /* Quotation checkbox */
  if (! quotationDeclaration) {
    _.set(errors, 'data.attributes.metadata.quotation_declaration', 'Please agree to quotation declaration')
  }



  errors._error = errors.data

  return errors
}
export default validate
