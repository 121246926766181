import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

export default class Error404Container extends Component {
  render() {
    return (
      <div>
        <div className="full-width-section">
          <div className="container">
            <div className="row">
              <div className="col-sm-offset-2 col-sm-6 hero">
                <h2 className="faded">404 - not found</h2>
                <h1>Whoops, we didn't expect to see you here...</h1>
                <h4>The page you where looking for could not be found. Please click back or return to
                  the <Link to="/">homepage</Link>.</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
