import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchAnnouncements } from '../../../../dashboard/redux/dashboardActions'
import { openModal } from '../../../../../common/components/Modal/ModalRedux'
import { Button, Modal } from '../../../../../common/components/index'
import Markdown from 'react-remarkable'
import moment from 'moment'
import getIncludedResponse from '../../../../../helpers/getIncludedResource'

class Announcements extends Component {

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    store: PropTypes.object.isRequired
  };

  componentWillMount() {
    this.props.dispatch(fetchAnnouncements())
  }

  openAnnouncementModal(id) {
    this.props.dispatch(openModal(id))
  }

  render() {
    const { store } = this.props
    const enabled = store.announcements.data

    return (
      <div>
        <table className="table reminders-list">
          <thead />
          <tbody>
          { enabled && enabled.map(announcement => {
            if ((!announcement.attributes.to_date &&
              moment().isAfter(moment(announcement.attributes.from_date).add(announcement.attributes.duration, 'days' )) ||
              moment().isAfter(announcement.attributes.to_date))) {
              return null;
            }


            let attachments = getIncludedResponse(announcement, store.announcements.included, 'files') || []
            return (
              <tr key={ announcement.id }>
                <td className="reminder-date">{ announcement.attributes.name }</td>
                <td>{announcement.attributes.sections.preview}</td>
                <td className="reminder-action">
                  <Button
                    size="sm"
                    bsStyle="link"
                    label="more details"
                    handleClick={() => this.openAnnouncementModal(announcement.id)}
                  />
                  <Modal name={ announcement.id } title={ announcement.attributes.name } close={true}>
                    <div><Markdown source={announcement.attributes.sections.content}/></div>
                    { attachments.length > 0 ? (
                      <p className="note-attachments">
                        <strong>Attachments: </strong>
                        {attachments.map((data, i) => {
                          return (
                            <span className="attachment-link" key={i}>
                        <a href={data.attributes.url}
                           target="_blank">{data.attributes.name}</a>{attachments.length - 1 !== i ? (
                              <span>, &nbsp;</span>) : null}
                      </span>
                          )
                        })}
                      </p>
                    ) : null }
                  </Modal>
                </td>
              </tr>
            )
          })}

          {enabled && enabled.length === 0 && (
            <tr key={1}>
              <td colSpan="2">You have no announcements</td>
              <td />
            </tr>
          )}
          </tbody>
        </table>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    store: state.dashboard
  }
}

export default connect(mapStateToProps)(Announcements)
