import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { AddressFields, RadioGroup } from '../../../../common/components/ReduxFormField'
import PostcodeList from '../../../../common/components/Postcode/PostcodeList'
import {
  Box,
  Button,
  ButtonBar,
  HorizontalFormControl,
  SelectBrokerRegion,
  SelectNetwork,
  SelectOrganisation,
  SelectUser,
  DatePicker
} from '../../../../common/components'
import { hasPermission } from '../../../auth/redux/authActions'
import _ from 'lodash'
import './styles.scss'

const FORM_NAME = 'brokerForm'

const validate = values => {
  const errors = {}
  const requiredFields = [
    { field: 'data.relationships.organisation.data.id', name: 'Organisation' },
    { field: 'data.relationships.organisation.data', name: 'Organisation' },
  ]

  requiredFields.map(required => {
    if (!_.get(values, required.field)) {
      _.set(errors, required.field, required.name + ' is required')
    }
  })

  return errors
}

class BrokerForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    resource: PropTypes.object.isRequired,
    formValues: PropTypes.object,
    brokerLabel: PropTypes.object.isRequired
  };

  render() {
    const { formValues, handleSubmit, submitting } = this.props
    const resource = this.props.resource.data.attributes

    return (
      <div className="broker-form">
        <Box>
          <h2 className="resource-name">{resource.name} {this.props.brokerLabel}</h2>
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-horizontal">
                  <Field
                    name="data.attributes.trading_name"
                    label="Trading Name"
                    labelSize={4}
                    component={HorizontalFormControl}
                  />

                  <Field
                    name="data.attributes.branch_name"
                    label="Branch Name"
                    labelSize={4}
                    component={HorizontalFormControl}
                  />

                  <Field
                    name="data.relationships.broker_region.data"
                    label="Region"
                    labelSize={4}
                    object={true}
                    component={SelectBrokerRegion}
                  />

                  <div className="adjust-broker-postcode">
                    <div className="adjust-broker-postcode-label">
                      Postcode
                    </div>
                    <div className="adjust-broker-postcode-field">
                      <PostcodeList
                        name="data.attributes.address.postcode"
                        label=""
                        labelSize={1}
                        change={this.props.change}
                        value={_.get(formValues, 'data.attributes.address.postcode', '')}
                        targetFields={{
                          line1: 'data.attributes.address.line1',
                          line2: 'data.attributes.address.line2',
                          line3: 'data.attributes.address.line3',
                          town: 'data.attributes.address.town',
                          county: 'data.attributes.address.county',
                          postcode: 'data.attributes.address.postcode'
                        }}
                      />
                    </div>
                  </div>
                  <div className="adjust-broker-address">
                    <div className="adjust-broker-address-label">
                      Address
                    </div>
                    <div className="adjust-broker-address-field">
                      <AddressFields
                        baseName="data.attributes.address"
                        label=""
                        labelSize={1}
                      />
                    </div>
                  </div>

                  <Field
                    name="data.attributes.phone_number"
                    label="Phone Number"
                    labelSize={4}
                    object={true}
                    component={HorizontalFormControl}
                  />

                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-horizontal">

                  <Field
                    name="data.attributes.metadata.agency_number"
                    label="Agency Number"
                    labelSize={4}
                    component={HorizontalFormControl}
                  />

                  <Field
                    name="data.attributes.metadata.hold_client_money"
                    label="Hold Client Money"
                    component={RadioGroup}
                    inline={true}
                    labelSize={4}
                    options={[
                      { label: 'Yes', value: 'Yes' },
                      { label: 'No', value: 'No' },
                      { label: 'N/A', value: 'N/A' },
                    ]}
                  />

                  <Field
                    name="data.attributes.metadata.last_meeting"
                    label="Last Meeting"
                    labelSize={4}
                    component={DatePicker}
                  />

                </div>
              </div>
            </div>

            <div className="hr"/>

            <div className="row">
              <div className="col-sm-6">
                <div className="form-horizontal">

                  <Field
                    name="data.relationships.networks.data"
                    label="Networks"
                    labelSize={4}
                    object={true}
                    multi={true}
                    component={SelectNetwork}
                  />

                  <Field
                    name="data.attributes.metadata.network_reference"
                    label="Network Reference"
                    labelSize={4}
                    component={HorizontalFormControl}
                  />

                  <Field
                    name="data.attributes.metadata.other_networks"
                    label="Other Networks"
                    labelSize={4}
                    component={HorizontalFormControl}
                  />
                </div>
              </div>

            </div>

            <div className="hr"/>

            <div className="row">
              {!this.props.resource.data.id && (
                <div className="col-xs-12">
                  <div className="form-horizontal">
                    <Field
                      name="data.relationships.organisation.data.id"
                      label="Organisation"
                      labelSize={2}
                      component={SelectOrganisation}
                    />
                  </div>
                </div>
              )}

              {_.get(formValues, 'data.relationships.organisation.data.id') && (
                <div className="col-xs-12">
                  <div className="form-horizontal">
                    <Field
                      name="data.relationships.users.data"
                      label="Users"
                      labelSize={2}
                      organisation={_.get(formValues, 'data.relationships.organisation.data.id', '')}
                      labelKeys={['attributes.first_name', 'attributes.last_name']}
                      multi={true}
                      object={true}
                      component={SelectUser}
                    />
                  </div>
                </div>
              )}
            </div>
            <ButtonBar>
              { hasPermission('broker.edit') && (
                <Button
                  className="pull-right"
                  type="submit"
                  bsStyle="primary"
                  label="Save Broker"
                  isLoading={submitting}
                />
              )}
            </ButtonBar>
          </form>
        </Box>
      </div>
    )
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(BrokerForm)
const selector = formValueSelector(FORM_NAME)
const mapStateToProps = (state, props) => {
  const values = selector(state,
    'data.id',
    'data.attributes.address.postcode',
    'data.relationships.organisation.data.id',
    'data.attributes.metadata.hold_client_money',
    'data.attributes.metadata.software_house'
  )

  if (Array.isArray(props.resource.data.attributes.metadata) && !props.resource.data.attributes.metadata.length) {
    props.resource.data.attributes.metadata = {}
  }

  return {
    initialValues: props.resource,
    formValues: values
  }
}

export default connect(mapStateToProps)(form)
