import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getDocument } from '../redux/documentActions'
import { getProduct } from '../../product/redux/productActions'
import { Box, Button, Loader } from '../../../common/components'
import InternalContainer from '../../layout/containers/Internal'
import DocumentDetails from '../components/DocumentDetails'

class DocumentEditContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    document: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired
  }

  constructor(props, context) {
    super(props, context)

    this.state = {
      activeTab: 1
    }
  }

  componentWillMount() {
    this.props.dispatch(getDocument(this.props.params.id))
    this.props.dispatch(getProduct(this.props.params.productId))
  }

  handleTab(key) {
    this.setState({ activeTab: key })
  }

  render() {
    const resource = this.props.document.resources[this.props.params.id]
    const product = this.props.product.resources[this.props.params.productId]

    const buttons = (
        <Button
            bsStyle="default"
            label="Back"
            isBackButton={true}
            leftIcon="caret-left"
        />
    )

    return (
        <InternalContainer title="Document" buttons={buttons}>
          <Box>
            {resource && product ? (
                    <DocumentDetails
                        {...this.props}
                        resource={resource}
                        product={product}
                    />
                ) : (
                    <Loader />
                )}
          </Box>
        </InternalContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    document: state.document,
    product: state.product
  }
}

export default connect(mapStateToProps)(DocumentEditContainer)
