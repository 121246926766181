import React from 'react';
import { Icon } from '../../../../../common/components';
import { Button } from '../../../../../common/components/Button/Button';

class Guides extends React.Component {
  render() {
    return (
      <div className="mt-15">
        <h5>Guides</h5>
        <div className="active-Product-row">
          <div className="flex-left">Version 1</div>
          <div className="row-actions">+</div>
        </div>
      </div>
    );
  }
}

export default Guides;
