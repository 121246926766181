import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions as trackingLinksActions } from '../redux/TrackingLinkReducer'
import InternalContainer from '../../layout/containers/Internal'
import TrackingLinksNewForm from '../components/TrackingLinksNewForm'

class TrackingLinksContainer extends React.Component {
  handleSubmit = (resource) => {
    const { actions } = this.props
    actions.track.submitForm(resource)
  }

  render () {
    return (
      <InternalContainer title="New Tracking Link">
        <TrackingLinksNewForm onSubmit={this.handleSubmit} {...this.props}/>
      </InternalContainer>
    )
  }
}

export default connect(
  null,
  (dispatch) => ({
    actions: {
      track: bindActionCreators({ ...trackingLinksActions }, dispatch),
    }
  }),
)(TrackingLinksContainer)
