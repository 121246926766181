import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../../../common/components'
import './styles.scss'
import HealixSession from '../HealixSession/HealixSession'
import CEGASession from '../CEGASession/CEGASession'
import { isOwner } from '../../../auth/redux/authActions';

const MAX_MEDICAL_SCORE = 25;
const CEGA = 'cega';
const HEALIX = 'healix';

export default class ScreeningSession extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    traveller: PropTypes.object.isRequired,
    screening: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    startScreening: PropTypes.func.isRequired,
    finishScreening: PropTypes.func.isRequired,
    changeConditionsCovered: PropTypes.func,
    conditionsCovered: PropTypes.bool,
  }

  isScreeningScoreCovered(screening, service) {
    if (service !== CEGA) {
      return true;
    }

    if (isOwner()) {
      return true;
    }

    const totalScore = screening
      && screening.meta
      && screening.meta.response
      && screening.meta.response.totalScore || false;

    return totalScore <= MAX_MEDICAL_SCORE;
  }

  componentWillReceiveProps(nextProps) {
    const { traveller, screening } = nextProps;
    if (screening.service !== CEGA) {
      return;
    }

    const screeningScoreCovered = this.isScreeningScoreCovered(
      traveller.screening,
      screening.service
    );

    if (!screeningScoreCovered && !traveller.conditions_covered) {
      this.props.changeConditionsCovered(true);
    }
  }

  render() {
    const { index, traveller, screening, dispatch, startScreening, finishScreening, changeConditionsCovered, conditionsCovered } = this.props
    return (
      <div key={index} className={'traveller-row col-xs-12 traveller' + index}>
        {(screening.token && screening.index == index) ? (
          <div className="screening-row screening-session">
            {!traveller.first_name ? (
              <p>Screening for Adult {index}</p>
            ) : (
              <p className="screening-row__traveller-name">Screening
                for {traveller.title + ' ' + traveller.first_name + ' ' + traveller.last_name}</p>
            )}

            {screening.service === CEGA && (
              <CEGASession
                screening={screening}
                finishScreening={finishScreening}
              />
            )}

            {screening.service === HEALIX && (
              <HealixSession
                index={index}
                screening={screening}
                dispatch={dispatch}
                finishScreening={finishScreening}
              />
            )}
          </div>
        ) : (
          <div className="screening-row">
            {(!screening.index || screening.index == index) && (
              <Button
                className="pull-right"
                bsStyle="success"
                label={traveller.screening_id ? 'Edit Conditions' : 'Add conditions'}
                isLoading={(screening.isSubmitting && screening.index == index)}
                disabled={screening.index !== 0 && screening.index != index}
                handleClick={startScreening}
              />
            )}

            {!traveller.first_name ? (
              <h4><strong>Adult {index}</strong></h4>
            ) : (
              <h4><strong>{traveller.title + ' ' + traveller.first_name + ' ' + traveller.last_name}</strong></h4>
            )}

            <ul className={'declared-conditions-list' + (traveller.screening && traveller.screening.meta ? '' : ' empty') }>
              {traveller.screening && traveller.screening.meta ? (
                traveller.screening.meta.conditions.map((condition, i) => {
                  return (
                    <li key={i}>{condition}</li>
                  )
                })
              ) : (
                <li>No conditions declared</li>
              )}
            </ul>

            {traveller.screening && traveller.screening.meta && this.isScreeningScoreCovered(traveller.screening, screening.service) && (
              <div className="conditions-covered-toggle">
                <label className="control-label">
                  <input
                    type="checkbox"
                    value={true}
                    checked={conditionsCovered == true}
                    onChange={() => changeConditionsCovered(!conditionsCovered)}
                  /> Include cover for these conditions
                </label>
                {conditionsCovered != true && (
                  <p>
                    <strong>Warning</strong> – By selecting to exclude cover, you will not be covered for any claims caused by or resulting from any pre-existing medical condition(s) for which treatment is being received/monitored.
                  </p>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}
