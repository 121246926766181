import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Col, FormControl, FormGroup, ControlLabel } from 'react-bootstrap'
import getIncludedResource from '../../../../helpers/getIncludedResource'
import _ from 'lodash'

class ProductFilter extends Component {
  static propTypes = {
    label: PropTypes.string,
    labelSize: PropTypes.number,
    infoButton: PropTypes.element
  }

  render() {
    const { auth, onChange, label, labelSize, infoButton } = this.props
    const products = _.sortBy(
        getIncludedResource(auth.user.data, auth.user.included, 'available_products'),
        'attributes.name'
    )

    return (
        <FormGroup controlId="select-status">
          {label && (
            <Col componentClass={ControlLabel} sm={labelSize}>
              { label } { infoButton }
            </Col>
          )}
          <Col sm={(12 - labelSize)}>
            <FormControl
                componentClass="select"
                type="select"
                onChange={onChange}
                placeholder="select"
            >
              <option value="">All</option>
              {products.map((product, i) => {
                return (<option key={i} value={product.id}>{product.attributes.name}</option>)
              })}
            </FormControl>
          </Col>
        </FormGroup>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps)(ProductFilter)
