import { randomBytes, createCipheriv, createDecipheriv } from 'crypto';

// The APP_KEY needs to be 32 byte
const key = process.env.APP_KEY;
const algorithm = 'aes-256-cbc';
const inputEncoding = 'utf8';
const outputEncoding = 'base64';

export function encrypt(value) {
  const iv = Buffer.from(randomBytes(16));
  const cipher = createCipheriv(algorithm, key, iv);

  const encrypted = cipher.update(value, inputEncoding, outputEncoding)
    + cipher.final(outputEncoding);

  return `${iv.toString(outputEncoding)}:${encrypted.toString()}`;
}

export function decrypt(value) {
  const textParts = value.split(':');

  // extract the IV from the first half of the value
  const IV = Buffer.from(textParts.shift(), outputEncoding);

  // extract the encrypted text without the IV
  const encryptedText = Buffer.from(textParts.join(':'), outputEncoding);

  // decipher the string
  const decipher = createDecipheriv(algorithm, key, IV);

  let decrypted = decipher.update(encryptedText,  outputEncoding, inputEncoding);
  decrypted += decipher.final(inputEncoding);

  return decrypted.toString();
}
