import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box, DataTable, CreateNoteButton, NotesList } from '../../../../common/components'
import { FormattedDate, FormattedTime } from 'react-intl'
import { refreshData } from '../../../../common/components/DataTable/redux/dataTableActions'
import {getResourceNotesCount, setResourceNotesCount} from "../../redux/policyActions";

export default class PolicyNotes extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    reference: PropTypes.string.isRequired
  };

  formatDate(cell) {
    return (
      <span>
          <FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/>
        </span>
    )
  }

  refreshList() {
    this.props.dispatch(refreshData('notes'))
    this.props.dispatch(getResourceNotesCount(this.props.resource.data.id))
  }

  render() {
    const { reference } = this.props

    const notesFilterKey = this.props.resource.data.attributes.policy_number ? 'filter[resource]' : 'filter[quote_reference]';
    const notesFilterValue = this.props.resource.data.attributes.policy_number ? this.props.resource.data.id : this.props.resource.data.attributes.quote_reference;

    return (
      <div>
        <Box>
          <h2 className="resource-name">{reference}</h2>

          <DataTable
            source={'/notes'}
            autoFilters={[
              { type: notesFilterKey, value: notesFilterValue },
              { type: 'include', value: 'attachments' }
            ]}
            name="notes"
            showTotal={true}
            component={NotesList}/>

          <div className="row actions">
            <div className="col-xs-12">
              <CreateNoteButton
                label="Add a Note"
                notableResource={this.props.resource}
                notableResourceType={
                  this.props.resource.data.attributes.policy_number ? 'policies' : 'policy-applications'
                }
                callback={::this.refreshList}
              />
            </div>
          </div>
        </Box>
      </div>
    )
  }
}
