import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import PlainTextField from './PlainTextField'
import './styles.scss'

const ExpiryDateFields = ({baseName, label, labelSize, mdFieldSize}) => {
  let labelClass = 'control-label', wrapperClass = 'expiry-fields'
  if (labelSize) {
    labelClass += ' col-xs-' + labelSize
    wrapperClass += ' col-xs-' + (12 - labelSize)

    if (mdFieldSize) {
      wrapperClass += ' col-md-' + mdFieldSize
    }
  }

  return (
    <div className="form-group expiry-group">
      {label && (
        <label className={labelClass}>{label}</label>
      )}

      <div className={wrapperClass}>

        <Field
          className="text-center"
          name={baseName + '.expiry_month'}
          placeholder="mm"
          component={PlainTextField}
        />

        <Field
          className="text-center"
          name={baseName + '.expiry_year'}
          placeholder="yy"
          component={PlainTextField}
        />

      </div>
    </div>
  )
}

export default ExpiryDateFields
