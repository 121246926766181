import Axios from 'axios'
import { toastr } from 'react-redux-toastr'

export function createNote(note, application, resourceType) {
  return dispatch => {
    return Axios.post(resourceType + "/" + application.data.id + '/notes', note).then(response => {
      toastr.success('Success', 'Your note has been saved')
    }).catch(error => {
      console.error(error)
    })
  }
}
