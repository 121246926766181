import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { Button, Modal, HorizontalFormControl } from '../../../../common/components'
import { TextField, SelectField } from '../../../../common/components/ReduxFormField'
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux'
import { ControlLabel } from 'react-bootstrap'
import { cancelPolicy, getDetailsForCancellation } from '../../redux/policyActions'
import TextArea from '../../../content/components/TextArea/TextArea'
import * as _ from 'lodash'
import './styles.scss'

const FORM_NAME = 'cancelForm'

const validate = values => {
  const errors = {}

  if (!_.get(values, 'meta.reason', false) && !_.get(values, 'meta.note.content', false)) {
    _.set(errors, 'meta.reason', 'Reason for cancellation is required')
  }

  if (parseFloat(_.get(values, 'meta.refund_amount', 0)) > parseFloat(_.get(values, 'meta.max', 0))) {
    _.set(errors, 'meta.refund_amount', 'Max refund of £' + _.get(values, 'meta.max', 0))
  }

  return errors
}

class CancelButton extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    resource: PropTypes.object.isRequired,
    policy: PropTypes.object.isRequired,
    phoneNumber: PropTypes.string,
    callback: PropTypes.func,
    label: PropTypes.string
  };

  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded, callback } = this.props
    if (newProps.submitSucceeded && !submitSucceeded) {
      if (callback) {
        callback('')
      }
      dispatch(closeModal(FORM_NAME))
    }
  }

  handleSubmit(values) {
    return this.props.dispatch(cancelPolicy(values, this.props.resource))
  }

  handleOpen() {
    const { dispatch, change, reset } = this.props
    dispatch(reset())

    dispatch(getDetailsForCancellation(this.props.resource, (details) => {
      const maxRefundAmount = Math.abs(_.get(details, 'meta.return_premium.premium.gross', 0.00)).toFixed(2)
      change('meta.max', maxRefundAmount)
      change('meta.gross_return_premium', maxRefundAmount)
      dispatch(openModal(FORM_NAME))
    }))
  }

  render() {
    const reasons = [
      { label: 'Product unsuitable / misunderstood ', value: 'Product unsuitable / misunderstood ' },
      { label: 'Product too expensive ', value: 'Product too expensive ' },
      { label: 'Alternative product purchased ', value: 'Alternative product purchased ' },
      { label: 'Cover overlapped with pre-existing cover ', value: 'Cover overlapped with pre-existing cover ' },
      { label: 'Product cancelled within cooling off period ', value: 'Product cancelled within cooling off period ' },
      { label: 'Product cancelled by underwriter', value: 'Product cancelled by underwriter' },
      { label: 'Poor service/complaint ', value: 'Poor service/complaint ' },
      { label: 'Cover no longer required ', value: 'Cover no longer required ' },
      { label: 'Other', value: 'Other' },
      { label: 'Not known', value: 'Not known' }
    ]
    const { handleSubmit, submitting, policy, formValues } = this.props

    const submitButton = (<Button
        type="submit"
        bsStyle="primary"
        isLoading={submitting}
        handleClick={handleSubmit(::this.handleSubmit)}
        label="Cancel Policy"
    />)


    return (
      <div className="email-btn-wrapper pull-right">
        <Button
            type="button"
            bsStyle="primary"
            label="Cancel"
            rightIcon="ban"
            isLoading={policy.isCheckingCancel}
            handleClick={::this.handleOpen}
        />

        <Modal
            name={FORM_NAME}
            title="Cancel Policy"
            close={true}
            footer={submitButton}
        >
          <form onSubmit={handleSubmit(::this.handleSubmit)}>
            <div className="col-xs-12">
              <ControlLabel className="filter-label">Cancellation reason (for internal records)</ControlLabel>
              <Field
                name="meta.reason"
                options={reasons}
                component={SelectField}
              />

              {_.get(formValues, 'meta.reason') === 'Other' && (
                <div>
                  <ControlLabel className="filter-label">Other reason not listed</ControlLabel>
                  <Field
                    name="meta.note.content"
                    type="textarea"
                    component={TextField}
                  />
                </div>
              )}
            </div>

            <div className="col-xs-12">
              <div className="row">
                <div className="col-xs-12">
                  <ControlLabel className="filter-label">Cancellation Endorsement</ControlLabel>
                  <Field
                    name="meta.endorsement.content"
                    component={TextArea}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <Field
                    name="meta.gross_return_premium"
                    label="Refund amount (inc. IPT)"
                    labelSize={8}
                    currency={true}
                    component={HorizontalFormControl}
                  />
                </div>
              </div>
              <br/>
            </div>
          </form>
        </Modal>
      </div>
    )
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(CancelButton)
const selector = formValueSelector(FORM_NAME)
const mapStateToProps = (state, props) => {
  const values = selector(state,
    'meta.reason',
    'meta.max',
    'meta.refund_amount'
  )

  return {
    policy: state.policy,
    formValues: values,
    initialValues: {
      meta: {
        refund_amount: '0.00',
        max: '0.00',
        endorsement: {
          title: 'Policy Cancelled',
          content: 'It has hereby been agreed that policy ' +
          _.get(props.resource, 'data.attributes.policy_number') + " has been cancelled in it's entirety."
        }
      }
    }
  }
}

export default connect(mapStateToProps)(form)
