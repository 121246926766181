import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'

const SelectField = ({ input, label, placeholder, infoButton, options, labelSize, mdFieldSize, meta, clearable, readOnly }) => {
  let labelClass = 'select-field-label', wrapperClass = ''
  if (labelSize) {
    labelClass += ' col-xs-' + labelSize
    wrapperClass += ' col-xs-' + (12 - labelSize)

    if (mdFieldSize) {
      wrapperClass += ' col-md-' + mdFieldSize
    }
  }

  const change = (data) => input.onChange(data ? data.value : '')


  return (
    <div className={(meta && meta.touched && meta.error ? 'form-group has-error' : 'form-group')}>
      {label && (
        <div className={labelClass}>
          <div className="label-unbreake">
            <label className="control-label">{label}</label> {infoButton}
          </div>
        </div>
      )}
      <div className={wrapperClass}>
        <Select
          {...input}
          placeholder={placeholder}
          clearable={clearable}
          value={input.value || ''}
          onBlur={() => input.onBlur ? input.onBlur(input.value) : ''}
          onChange={! readOnly ? change : () => {}}
          options={options}
        />

        {meta && meta.touched && meta.error && <span className="input-error">{meta.error}</span>}
      </div>
    </div>
  )
}

export default SelectField
