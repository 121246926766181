import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ProductDefinitionsForm from '../components/ProductDefinitionsForm'
import { updateProduct } from '../redux/schemeActions'

class ProductDefinitionsFormContainer extends Component {
  static propTypes = {
    product: PropTypes.object.isRequired
  }

  handleSubmit(product) {
    return this.props.dispatch(updateProduct(product))
  }

  render() {
    return (
        <ProductDefinitionsForm
            product={this.props.product}
            onSubmit={::this.handleSubmit}
        />
    )
  }
}

export default connect()(ProductDefinitionsFormContainer)
