import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Tabs, Tab } from 'react-bootstrap'
import { Button, Loader, Box } from '../../../common/components'
import InternalContainer from '../../layout/containers/Internal'
import ProductDetailsFormContainer from './ProductDetailsFormContainer'
import ProductDeclarationsFormContainer from './ProductDeclarationsFormContainer'
import ProductDefinitionsFormContainer from './ProductDefinitionsFormContainer'
import ProductRulesFormContainer from './ProductRulesFormContainer'
import ProductDocumentPacksFormContainer from './ProductDocumentPacksFormContainer'
import SchemeList from '../components/SchemeList'
import { getProduct, clearData } from '../redux/schemeActions'
import DocumentsList from '../../document/components/DocumentsList'

class SchemeProductEditContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    scheme: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired
  }

  constructor(props, context) {
    super(props, context)

    this.state = {
      activeTab: 1
    }
  }

  componentWillMount() {
    this.props.dispatch(getProduct(this.props.params.id, ['document_packs.scheme_documents']))
  }

  /**
   * Clean out the data when the component unmounts
   */
  componentWillUnmount() {
    this.props.dispatch(clearData())
  }

  handleTab(key) {
    this.setState({ activeTab: key })
  }

  render() {
    const resource = this.props.scheme.product
    const title = (resource.data && resource.data.id === this.props.params.id) ? resource.data.attributes.name : 'Manage product'
    const buttons = (
      <Button
        bsStyle="default"
        label="back"
        isBackButton={true}
        leftIcon="caret-left"
      />
    )

    return (
      <InternalContainer title={title} buttons={buttons}>
        {(resource.data && resource.data.id == this.props.params.id) ? (
          <Tabs activeKey={this.state.activeTab}
            id="scheme-tabs"
            unmountOnExit={true}
            onSelect={::this.handleTab}
          className='shadow-box'>

            <Tab eventKey={1} title="Schemes">
              <Box>
                <SchemeList product={resource}/>
              </Box>
            </Tab>

            <Tab eventKey={2} title="Details">
              <Box>
                <ProductDetailsFormContainer product={resource}/>
              </Box>
            </Tab>

            <Tab eventKey={3} title="Definitions">
              <Box>
                <ProductDefinitionsFormContainer product={resource}/>
              </Box>
            </Tab>

            <Tab eventKey={4} title="Declarations">
              <Box>
                <ProductDeclarationsFormContainer product={resource}/>
              </Box>
            </Tab>

            <Tab eventKey={5} title="Documents">
              <Box>
                <DocumentsList product={resource}/>
              </Box>
            </Tab>

            <Tab eventKey={6} title="Document Packs">
              <Box>
                <ProductDocumentPacksFormContainer product={resource}/>
              </Box>
            </Tab>

            <Tab eventKey={7} title="Rules">
              <Box>
                <ProductRulesFormContainer product={resource}/>
              </Box>
            </Tab>

          </Tabs>
        ) : (
            <Loader />
        )}
      </InternalContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    scheme: state.scheme
  }
}

export default connect(mapStateToProps)(SchemeProductEditContainer)
