import * as actions from './accountReducer'
import Axios from 'axios'
import { push } from 'react-router-redux'
import { toastr } from 'react-redux-toastr'
import { keys } from 'lodash';
import queryString from '../../../helpers/queryString'
import handleErrorResponse from '../../../helpers/handleErrorResponse'
import _ from 'lodash'
import moment from 'moment';

const defaultIncludes = ['organisation', 'organisation.accounts_contacts']

export function clearAccount() {
  return {
    type: actions.CLEAR_ACCOUNT
  }
}

export function isSubmitting(status) {
  return {
    type: actions.IS_SUBMITTING,
    status
  }
}

export function isGeneratingReport(status) {
  return {
    type: actions.IS_GENERATING_REPORT,
    status
  }
}

export function isSuspending(status) {
  return {
    type: actions.IS_SUSPENDING,
    status
  }
}

export function isReinstating(status) {
  return {
    type: actions.IS_REINSTATING,
    status
  }
}

export function getSummaries(includes = '') {
  return (dispatch) => {
    let endpoint = 'accounts/ledger-entries/summary'
    endpoint += queryString(includes)

    return Axios.get(endpoint).then(response => {
      dispatch({
        type: actions.SET_SUMMARIES,
        data: response.data.meta
      })
      return true
    }).catch(error => {
      console.error(error)
    })
  }
}

export function getAccount(id, includes = defaultIncludes) {
  return (dispatch) => {
    let endpoint = 'accounts/' + id
    endpoint += queryString(includes)

    return Axios.get(endpoint).then(response => {
      dispatch({
        type: actions.SET_ACCOUNT,
        data: response.data
      })
      return true
    }).catch(error => {
      console.error(error)
    })
  }
}

export function getTransactions(accountId, filters = '') {
  return (dispatch) => {
    let endpoint = 'accounts/' + accountId + '/payments-and-receipts' + (filters ? '?' + filters : '')

    return Axios.get(endpoint).then(response => {
      dispatch({
        type: actions.SET_TRANSACTIONS,
        data: response.data
      })
      return true
    }).catch(error => {
      console.error(error)
    })
  }
}

export function getPremiums(accountId, premiumType = 'net-premiums', filters = '') {
  return (dispatch) => {
    dispatch({
      type: actions.SET_PREMIUM_TYPE,
      premiumType: premiumType
    })

    let endpoint = 'accounts/' + accountId + '/' + premiumType + (filters ? '?' + filters : '')

    return Axios.get(endpoint).then(response => {
      dispatch({
        type: actions.SET_PREMIUMS,
        premiumType: premiumType,
        data: response.data,
        filters
      })
      return true
    }).catch(error => {
      console.error(error)
    })
  }
}

export function createAccount(resource) {
  return dispatch => {
    dispatch(isSubmitting(true))
    return Axios.post('accounts', resource).then(response => {
      dispatch(isSubmitting(false))
      dispatch(push('/accounts/ledger/' + response.data.data.id))
      return true
    }).catch(error => {
      dispatch(isSubmitting(false))
      handleErrorResponse(error, 'Account could not be created')
    })
  }
}

export function updateAccount(resource, includes = defaultIncludes) {
  return dispatch => {
    dispatch(isSubmitting(true))
    const id = resource.data.id
    let endpoint = 'accounts/' + id
    endpoint += queryString(includes)

    return Axios.patch(endpoint, resource).then(() => {
      dispatch(isSubmitting(false))
      dispatch(push('/accounts/ledger/' + id))
      return true
    }).catch(error => {
      dispatch(isSubmitting(false))
      handleErrorResponse(error, 'Account could not be updated')
    })
  }
}

export function updateAccountingPeriod(resource, cb) {
  return dispatch => {
    const id = resource.data.id
    let endpoint = 'accounts/accounting-periods/' + id + ':' + (resource.data.attributes.open === 'true' ? 'unlock' : 'lock')
    return Axios.post(endpoint, resource).then(() => {
      if (cb) {
        dispatch(cb())
      }
      toastr.success('Success', 'Accounting period updated')
      return true
    }).catch(error => {
      handleErrorResponse(error, 'Accounting period could not be updated')
    })
  }
}

export function suspendAccount(id) {
  return dispatch => {
    dispatch(isSuspending(true))
    return Axios.post('accounts/' + id + ':suspend', {
      data: {
        metadata: {
          send_email: false
        }
      }
    }).then(() => {
      dispatch(getAccount(id)).then(() => {
        dispatch(isSuspending(false))
        toastr.success('Suspended', 'The account has now been suspended')
        return true
      })
    }).catch(error => {
      dispatch(isSuspending(false))
      handleErrorResponse(error, 'There was an error suspending this account')
    })
  }
}

export function reinstateAccount(id) {
  return dispatch => {
    dispatch(isReinstating(true))
    return Axios.post('accounts/' + id + ':reinstate', {
      data: {
        metadata: {
          send_email: false
        }
      }
    }).then(() => {
      dispatch(getAccount(id)).then(() => {
        dispatch(isReinstating(false))
        toastr.success('Suspended', 'The account has now been re-instated')
        return true
      })
      return true
    }).catch(error => {
      dispatch(isReinstating(false))
      handleErrorResponse(error, 'There was an error re-instating this account')
    })
  }
}

export function saveAccountMatching(matchedValues, accountId, cb) {
  return (dispatch, getState) => {
    let endpoint = 'accounts/' + accountId + ':match'
    return Axios.post(endpoint, matchedValues).then(() => {
      toastr.success('Success', 'The premiums have been matched')

      dispatch(getTransactions(accountId))
      dispatch(getPremiums(accountId, getState().account.premiumType))

      if (cb) {
        cb()
      }
      return true
    }).catch(error => {
      handleErrorResponse(error, 'Account could not be updated')
    })
  }
}

export function savePaymentsAndReceipts(paymentsAndReceipts) {
  return dispatch => {
    let endpoint = 'accounts/payments-and-receipts'
    return Axios.post(endpoint, paymentsAndReceipts).then(() => {
      dispatch(push('/accounts/bookkeeping'))
      return true
    }).catch(error => {
      handleErrorResponse(error, 'Error saving transactions')
    })
  }
}

export function postJournal(journal) {
  return dispatch => {
    let endpoint = 'accounts/journals?approve=true'
    return Axios.post(endpoint, journal).then(() => {
      dispatch(push('/accounts/bookkeeping'))
      return true
    }).catch(error => {
      handleErrorResponse(error, 'Error saving transactions')
    })
  }
}

export function postAdjustment(values, resource) {
  return dispatch => {
    const accountId = resource.data.id
    let endpoint = 'accounts/' + accountId + '/transaction-adjustment'
    return Axios.post(endpoint, values).then(() => {
      dispatch(getTransactions(accountId))
      toastr.success('Success', 'Your adjustment has been saved')
      return true
    }).catch(error => {
      handleErrorResponse(error, 'Error saving transactions')
    })
  }
}

export function makePayment(values, resource) {
  return () => {
    const accountId = resource.data.id
    let endpoint = 'accounts/' + accountId + ':payment'

    return Axios.post(endpoint, values).then(() => {
      toastr.success('Success', 'Your payment has been saved')
      return true
    }).catch(error => {
      handleErrorResponse(error, 'Error saving payment')
    })
  }
}

export function generateReport(values) {
  const _values = {
    ...values,
    from_date: moment(values.from_date).format('YYYY-MM-DD'),
    to_date: moment(values.to_date).format('YYYY-MM-DD'),
  };

  let filters = '';
  keys(values).map((key) => {
    filters ? (filters += '&') : (filters += '?');
    if (key === 'generator' && _values[key]) {
      filters += key + '=' + _values[key];
    } else {
      filters += 'params[' + key + ']=' + encodeURIComponent(_values[key]);
    }
  });

  return (dispatch) => {
    dispatch(isGeneratingReport(true));
    return Axios.get('reports' + filters)
      .then((response) => {
        dispatch(isGeneratingReport(false));
        if (response.status === 202) {
          toastr.success('The report request has been submitted and you will be notified once it has completed');
        } else if (window && response.data.meta.file_name) {
          window.open(response.data.meta.file_name, '_blank');
        }
        return true;
      })
      .catch((error) => {
        dispatch(isGeneratingReport(false));
        handleErrorResponse(error, 'Error generating report');
      });
  };
}

export function viewStatement(id) {
  return () => {
    return Axios.get('accounts/' + id + '/statement').then(response => {
      if (window && response.data.meta.file_name) {
        window.open(response.data.meta.file_name, '_blank')
      }
      return true
    }).catch(error => {
      handleErrorResponse(error, 'Error generating statement')
    })
  }
}

export function getLedgerEntry(id, includes = ['account']) {
  return (dispatch) => {
    let endpoint = 'accounts/ledger-entries/' + id
    endpoint += queryString(includes)

    return Axios.get(endpoint).then(response => {
      dispatch({
        type: actions.SET_LEDGER_ENTRY,
        data: response.data
      })
      return true
    }).catch(error => {
      console.error(error)
    })
  }
}

export function unmatchBatch(id, reason) {
  return (dispatch) => {
    dispatch(isSubmitting(true))
    return Axios.delete('accounts/batches/' + id).then(() => {
      dispatch(isSubmitting(false))
      toastr.success('Success', 'Batch has been un-matched')

      if (_.get(reason, 'data', false)) {
        return Axios.post('accounts/batches/' + id + '/notes', reason).then(() => {
          return true
        }).catch(error => {
          handleErrorResponse(error, 'Error saving note')
        })
      }

      return true
    }).catch(error => {
      dispatch(isSubmitting(false))
      handleErrorResponse(error, 'Error un-matching')
    })
  }
}
