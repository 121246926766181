import React, { Component } from 'react'
import LoginContainer from '../../auth/containers/Login'

export default class HomepageContainer extends Component {
  render() {
    return (
      <div className="home-page-box">
        <div className="guest-title">Welcome to Arch insurance (UK) Limited</div>
          <div className="home-page">
            <LoginContainer {...this.props}/>
          </div>
      </div>
    )
  }
}
