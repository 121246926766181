import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box, DataTable } from '../../../../common/components'
import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { FormControl, ControlLabel } from 'react-bootstrap'
import { push } from 'react-router-redux'
import { FormattedDate } from 'react-intl'

export default class ChallengeList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    challenge: PropTypes.object.isRequired
  };

  formatDate(cell, row) {
    return (
      <FormattedDate value={new Date(cell)}/>
    )
  }

  handleRowClick(row) {
    this.props.dispatch(push('/admin/challenges/' + row.id))
  }

  handleFilterUpdate(event) {
    this.props.dispatch(applyFilter('challenges', 'filter[status]', event.target.value))
  }

  handleSearchUpdate(event) {
    this.props.dispatch(applyFilter('challenges', 'filter[name]', event.target.value))
  }

  render() {

    const challengeStatuses = [
      { label: 'received', value: 'received' },
      { label: 'responded', value: 'responded' },
    ]

    return (
      <div>
        <Box>
          <div className="row">
            <div className="col-sm-4">
              <ControlLabel className="filter-label">Search</ControlLabel>
              <FormControl onChange={ ::this.handleSearchUpdate }/>
            </div>
            <div className="col-sm-4"/>
            <div className="col-sm-4">
              <ControlLabel className="filter-label">Status</ControlLabel>
              <FormControl componentClass="select"
                           type="select"
                           onChange={::this.handleFilterUpdate}
                           placeholder="select"
              >
                <option value="">Select...</option>
                {challengeStatuses.map((status, key) => {
                  return ( <option key={key} value={status.value}>{status.label}</option>)
                })}
              </FormControl>
            </div>
          </div>
        </Box>

        <Box>
          <DataTable
              source="/challenge-alpha"
              name="challenges"
              showTotal={true}
              onRowSelect={this.handleRowClick.bind(this)}>

            <TableHeaderColumn dataField="id" isKey={true} hidden={true}>ID</TableHeaderColumn>
            <TableHeaderColumn dataField="name" dataSort={true}>Name</TableHeaderColumn>
            <TableHeaderColumn dataField="company_name" dataSort={true}>Company</TableHeaderColumn>
            <TableHeaderColumn dataField="email" dataSort={true}>Email</TableHeaderColumn>
            <TableHeaderColumn dataField="phone" dataSort={true}>Phone</TableHeaderColumn>
            <TableHeaderColumn dataField="status" dataSort={true} width={'180px'}>Status</TableHeaderColumn>
            <TableHeaderColumn dataField="created_at" dataFormat={this.formatDate} width={'180px'}>Created
              At</TableHeaderColumn>
          </DataTable>
        </Box>
      </div>
    )
  }
}
