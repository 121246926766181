import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field, FieldArray } from 'redux-form'
import { HorizontalFormControl, Button, Icon, InfoLabel } from '../../../../common/components'
import { SelectField } from '../../../../common/components/ReduxFormField'
import displayName from '../../../../helpers/displayName'
import './styles.scss'

export default class Benefits extends Component {

  static propTypes = {
    fields: PropTypes.object.isRequired,
    schemeOptions: PropTypes.array,
    subBenefits: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  render() {
    const { fields, subBenefits, disabled, schemeOptions } = this.props

    return (
      <div className="form-horizontal">
        {fields.map((benefit, index) => {
          return (
            <div key={index} className="row benefit-row">
              <div className={'col-xs-12 benefit benefit' + index}>

                {!disabled && (
                  <span className={'remove-benefit' + (subBenefits ? ' sub-benefit-remove' : '')} onClick={() => fields.remove(index)} title={subBenefits ? 'remove sub-benefit' : 'remove benefit'}>
                    <Icon name={subBenefits ? 'times' : 'trash'}/>
                  </span>
                )}

                <div className="row">
                  <div className="col-sm-6">
                    <Field
                      name={benefit + '.name'}
                      label="Name"
                      labelSize={3}
                      component={disabled ? InfoLabel : HorizontalFormControl}
                    />
                  </div>

                  <div className="col-sm-6">
                    <Field
                      name={benefit + '.text'}
                      label="Limit"
                      labelSize={2}
                      component={disabled ? InfoLabel : HorizontalFormControl}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <Field
                      name={benefit + '.excess'}
                      label="Excess"
                      labelSize={3}
                      component={disabled ? InfoLabel : HorizontalFormControl}
                    />
                  </div>

                  {!subBenefits && (
                    <div className="col-sm-6">
                      <Field
                        name={benefit + '.option'}
                        label="Option"
                        labelSize={2}
                        options={schemeOptions.map(option => {
                          return { label: displayName(option.name, true), value: option.name }
                        })}
                        clearable={true}
                        useDisplayName={true}
                        component={disabled ? InfoLabel : SelectField}
                      />
                    </div>
                  )}
                </div>

                {!subBenefits && (
                  <div className="row sub-benefits">
                    <div className="col-sm-10 col-sm-offset-2">
                      <FieldArray
                        name={benefit + '.sub_benefits'}
                        subBenefits={true}
                        component={Benefits}
                        disabled={disabled}
                      />
                    </div>
                  </div>
                )}

              </div>
            </div>
          )
        })}

        {!disabled && (
          <Button className="benefit-btn pull-left" bsStyle="primary" size="sm"
                  label={'Add a' + (subBenefits ? ' Sub Benefit' : ' Benefit')} handleClick={() => fields.push({
            name: '',
            text: '',
            option: '',
            excess: '',
            sub_benefits: []
          })}/>
        )}
      </div>
    )
  }
}
