import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal as BootstrapModal } from 'react-bootstrap'
import { initModal, closeModal } from './ModalRedux'
import { Button } from '../'
import './modal.scss'

const ModalHeader = BootstrapModal.Header
const ModalTitle = BootstrapModal.Title
const ModalBody = BootstrapModal.Body
const ModalFooter = BootstrapModal.Footer

class Modal extends Component {
  static propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    modals: PropTypes.object,
    footer: PropTypes.node,
    close: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    children: PropTypes.node,
    handleSubmit: PropTypes.func,
    backdrop: PropTypes.string,
    bsSize: PropTypes.string,
    onHide: PropTypes.func,
    onShow: PropTypes.func
  };

  componentWillMount() {
    this.props.dispatch(initModal(this.props.name))
  }

  closeModal(e) {
    const { dispatch, onHide } = this.props

    dispatch(closeModal(this.props.name))

    if (onHide) {
      onHide()
    }
  }

  // componentWillReceiveProps(nextProps) {
  //   const { onShow, name, modals } = this.props;
  //
  //   if (!onShow) {
  //     return;
  //   }
  //
  //   if ((!modals[name] || modals[name].visible === false) && nextProps.modals[name].visible) {
  //     onShow();
  //   }
  // }

  handleSubmit(e) {
    const { handleSubmit } = this.props

    if (!handleSubmit) {
      e.preventDefault()
      return null
    }

    return handleSubmit(e)
  }

  render() {
    const { name, title, modals, footer, close, children, bsSize, onShow, backdrop } = this.props

    const modal = (
        <div>
          <ModalHeader closeButton>
            <ModalTitle>{ title }</ModalTitle>
          </ModalHeader>
          <ModalBody>
            { children }
          </ModalBody>
          <ModalFooter>
            {
              close ? (<Button
                      className="pull-left settings-button"
                      bsStyle="primary"
                      leftIcon="times"
                      label="Close"
                      handleClick={ ::this.closeModal }/>)
                  : ('')
            }
            { footer }
          </ModalFooter>
        </div>
    )


    return (
        <BootstrapModal show={ modals[name] ? modals[name].visible : false } onEntered={onShow}
                        onHide={ ::this.closeModal } bsSize={bsSize} backdrop={backdrop}>
          {this.props.handleSubmit ? (
                  <form onSubmit={ ::this.handleSubmit }>
                    {modal}
                  </form>
              ) : (
                  <div>
                    {modal}
                  </div>
              )}
        </BootstrapModal>
    )
  }
}

function mapStateToProps(state) {
  return {
    modals: state.modal.modals
  }
}

export default connect(mapStateToProps)(Modal)
