import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { initSelect, fetchData, setSource, applyFilter, refreshData, clearData } from './SelectMenuRedux'
import Select from '../ReactSelect/ReactSelect'
import moment from 'moment'
import 'moment/locale/en-gb'
import _ from 'lodash'

class SelectMenu extends Component {

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    source: PropTypes.string.isRequired,
    selectMenus: PropTypes.object,
    autoFilterType: PropTypes.string,
    autoFilter: PropTypes.string,
    sort: PropTypes.string,
    limit: PropTypes.array,
    limitBy: PropTypes.string,
    labelKey: PropTypes.string,
    labelKeys: PropTypes.array,
    valueKeys: PropTypes.string,
    exclude: PropTypes.array,
    object: PropTypes.bool,
    async: PropTypes.bool,
    asyncFilter: PropTypes.string
  };

  state = {
    value: ''
  }

  /**
   * Component will mount
   * Called just before the component mounts on the page
   */
  componentWillMount() {
    const { name, source, dispatch } = this.props

    if (!source) {
      return
    }

    dispatch(initSelect(name, source))
  }

  /**
   * Component did mount
   * Called once the component has finished mounting on the page
   */
  componentDidMount() {
    const { name, source, autoFilterType, autoFilter, dispatch } = this.props

    if (source) {
      if (autoFilter) {
        dispatch(setSource(name, source))
        dispatch(applyFilter(name, autoFilterType, autoFilter))
      } else {
        dispatch(fetchData(name, source))
      }
    }
  }

  /**
   * Refresh the data is the source has changed
   * @param newProps
   */
  componentWillUpdate(newProps) {
    const { name, source, dispatch } = newProps

    if (source !== this.props.source) {
      dispatch(setSource(name, source))
      dispatch(refreshData(name))
    }
  }

  /**
   * Clean out the data when the component unmounts
   */
  componentWillUnmount() {
    this.props.dispatch(clearData(this.props.name))
  }

  /**
   * This method deals with updating the redux-store with the new values
   * after it is updated with react-select's values.
   *
   * @param event
   * @param multi
   * @param object
   * @param callback
   *
   * @returns {*}
   */
  handleCallback(event, multi, object, callback) {
    const select = this.props.selectMenus.selects[this.props.name]
    const included = select ? select.included : []

    if (!callback || !event) {
      this.setState({ value: null })

      if (!event && callback) {
        return callback(event, included)
      }
      return
    }

    if (multi) {
      const values = []

      if (Array.isArray(event)) {
        event.forEach(val => {
          values.push(object ? JSON.parse(val.value) : (_.isObject(val) ? val.value : val))
        })
      }

      return callback(values, included)
    }

    if (object) {
      return callback(JSON.parse(event.value), included)
    }


    this.setState({ value: event.value })

    return callback(event.value, included)
  }

  handleChange(event) {
    const { field: { onChange }, object, multi } = this.props
    return this.handleCallback(event, multi, object, onChange)
  }

  handleBlur(event) {
    const { field: { onBlur }, object, multi } = this.props
    return this.handleCallback(event, multi, object, onBlur)
  }

  getOptions(value) {
    const { name, dispatch, asyncFilter } = this.props
    const filterType = asyncFilter || 'filter[name]'

    dispatch(applyFilter(name, filterType, value))

    return new Promise(function (resolve) {
      resolve({
        options: ''
      })
    })
  }

  /**
   * Render
   * Renders the markup for this component
   *
   * @returns {XML}
   */
  render() {
    if (!this.props.selectMenus.selects[this.props.name]) {
      return null
    }

    const { name, field, async, object, mapOptions, limit, limitBy, labelKey, labelKeys, labelFromToDates, valueKey, placeholder, multi, exclude, other, selectMenus: { selects: { [name]: { data, isFetching } } } } = this.props
    let value = field.value === undefined ? this.state.value : field.value

    let labels = {}
    const options = data ? data.map(mapOptions ? mapOptions : (option) => {

      // Get the label for the menu item
      if (labelKey) {
        labels[option.id] = _.get(option, labelKey)
      } else {
        labels[option.id] = _.get(option, 'attributes.name')
      }

      if (labelKeys) {
        labels[option.id] = labelKeys.map((key, index) => {
          if (index) {
            return ' ' + _.get(option, key)
          }
          return _.get(option, key)
        })
      }

      if (labelFromToDates) {
        let label =_ .get(option, 'attributes.name')
        const fromDate = _.get(option, 'attributes.from_date')
        const toDate = _.get(option, 'attributes.to_date')
        const status = _.get(option, 'attributes.status')

        if (fromDate) {
          label += ' ('
          if (status) {
            label += status + ' : '
          }

          label += ' ' + moment(fromDate).format('DD/MM/YY')

          if (toDate) {
            label += ' to ' + moment(toDate).format('DD/MM/YY')
          }
          label += ')'
        }

        labels[option.id] = label
      }

      // Remove the item if its to be excluded or filtered out
      if ((exclude && exclude.indexOf(labels[option.id]) !== -1) || (limit && limit.indexOf(option.attributes[limitBy]) === -1)) {
        return null
      }

      return {
        value: valueKey ? _.get(option, valueKey) : (object ? JSON.stringify(option) : option.id),
        label: labels[option.id]
      }
    }) : []

    if (other) {
      labels['other'] = 'Other'
    }

    if (multi && object) {
      let values = []
      if (Array.isArray(value)) {
        value.forEach(val => {
          values.push({ label: labels[val.id], value: JSON.stringify(val) })
        })
      }
      value = values
    } else if (object && value) {
      value = {
        label: labels[value.id],
        value: JSON.stringify(value)
      }
    }

    if (other) {
      options.push({
        label: 'Other',
        value: object ? JSON.stringify({ id: 'other', attributes: { name: 'Other' } }) : 'other'
      })
    }

    return (
        <Select
            {...field}
            async={async}
            loadOptions={::this.getOptions}
            options={_.without(options, null)}
            value={value}
            multi={multi}
            onChange={::this.handleChange}
            onBlur={::this.handleBlur}
            disabled={isFetching}
            placeholder={placeholder ? placeholder : 'Please select...'}
        />
    )
  }
}

function mapStateToProps(state) {
  return {
    selectMenus: state.selectMenus
  }
}

export default connect(mapStateToProps)(SelectMenu)
