import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './Box.scss'

const Box = ({ minHeight, doubleHeight, className, children }) => {
  let style = 'box' + (minHeight ? ' min-height' : '') + (doubleHeight ? ' double-height' : '') + (className ? ' ' + className : '')

  return (
      <div className={ style }>
        {children}
      </div>
  )
}

Box.propTypes = {
  minHeight: PropTypes.bool
}

export default Box
