import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { RadioGroup, SelectField } from '../../../../common/components/ReduxFormField'
import {
  Icon,
  DatePicker,
  SelectAccount,
  SelectBroker,
  SelectCurrency,
  Button,
  HorizontalFormControl
} from '../../../../common/components'
import './styles.scss'

const transactionDefaults = {
  date: '',
  account: '',
  description: '',
  currency: 'GBP',
  amount: 0,
}

export default class PaymentReceipt extends Component {
  static propTypes = {
    fields: PropTypes.object.isRequired,
    formValues: PropTypes.object
  };

  componentWillMount() {
    this.props.fields.push(transactionDefaults)
  }

  render() {
    const { fields, formValues } = this.props

    const paymentTypes = [
      { label: 'BACS', value: 'BACS' },
      { label: 'Cheque', value: 'Cheque' }
    ]

    return (
      <div>
        {fields.map((entry, index) => {
          return (
            <div key={index} className="transaction-entry">
              {index > 0 && (
                <span className="remove-entry" onClick={() => fields.remove(index)}>
                  <Icon name="times"/>
                </span>
              )}
            <div className="row form-group">
              <div className="col-sm-6">
                <Field
                    name={entry + '.transaction_type'}
                    component={RadioGroup}
                    inline={true}
                    options={[
                      { label: 'Receipt', value: 'receipt' },
                      { label: 'Payment', value: 'payment' }
                    ]}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  name={entry + '.account'}
                  id={index}
                  label="Account"
                  labelSize={3}
                  component={SelectAccount}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-xs-4">
                <Field
                    name={entry + '.date'}
                    label="Date"
                    labelSize={3}
                    component={DatePicker}
                />
              </div>
              <div className="col-xs-4">
                <Field
                    name={entry + '.description'}
                    label="Description"
                    labelSize={3}
                    component={HorizontalFormControl}
                />
              </div>
              <div className="col-xs-4">
                <Field
                    name={entry + '.payment_type'}
                    label="Type"
                    labelSize={3}
                    options={paymentTypes}
                    component={SelectField}
                />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-xs-4">
                <Field
                    name={entry + '.broker'}
                    id={index}
                    label="Broker"
                    labelSize={3}
                    component={SelectBroker}
                    filters={[
                      { filter: 'filter[account]', value: formValues[index].account }
                    ]}
                />
              </div>
              <div className="col-xs-4">
                <Field
                    name={entry + '.currency'}
                    label="Currency"
                    labelSize={3}
                    component={SelectCurrency}
                />
              </div>
              <div className="col-xs-4">
                <Field
                    name={entry + '.amount'}
                    label="Amount"
                    labelSize={3}
                    currency={true}
                    component={HorizontalFormControl}
                />
              </div>
            </div>
          </div>
          )
        }
      )}
      <div className="actions">
        <Button bsStyle="primary" size="sm" label="Add a new line"
                handleClick={() => fields.push(transactionDefaults)}/>
      </div>
    </div>
    )
  }
}
