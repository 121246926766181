import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box, ButtonBar, Button, HorizontalFormControl } from '../../../../common/components'
import { Alert } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import '../../../brochure/brochure.scss'

const validate = values => {
  const errors = {}
  if (!values.email) {
    errors.email = 'Registered email address is required'
  }
  if (!values.password) {
    errors.password = 'The new password is required'
  } else if (values.password.length < 8) {
    errors.password = 'Password must be 8 characters or more'
  }
  if (!values.password_confirmation) {
    errors.password_confirmation = 'The password must be confirmed'
  }
  if (values.password != values.password_confirmation) {
    errors.password_confirmation = 'Password does not match confirmation'
  }
  return errors
}

class ResetPasswordForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired
  };

  render() {
    const { error, handleSubmit, submitting } = this.props

    return (
      <div className="full-width-section">
        <div className="container relative remind-pass">
          <div className="row">
            <div className="col-md-offset-3 col-md-6 col-sm-9">
              <Box>
                <h2>Reset your password</h2>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-xs-12">

                      {error && (
                        <Alert bsStyle="danger">
                          <p>{error}</p>
                        </Alert>
                      )}

                      <div className="form-horizontal">
                        <Field
                          name="email"
                          type="email"
                          label="Registered email"
                          labelSize={4}
                          placeholder="Registered email address"
                          disabled={submitting}
                          component={HorizontalFormControl}
                        />

                        <Field
                          name="password"
                          type="password"
                          label="New password"
                          labelSize={4}
                          placeholder="New password"
                          disabled={submitting}
                          component={HorizontalFormControl}
                        />

                        <Field
                          name="password_confirmation"
                          type="password"
                          label="Confirm new password"
                          labelSize={4}
                          placeholder="Confirm new password"
                          disabled={submitting}
                          component={HorizontalFormControl}
                        />
                      </div>
                    </div>
                  </div>
                  <ButtonBar>
                    <Button
                      type="submit"
                      bsStyle="primary"
                      className="pull-right"
                      disabled={submitting}>
                      {submitting ? <i className="fa fa-cog fa-spin"/> : <i className="fa"/>} reset password
                    </Button>
                  </ButtonBar>
                </form>
              </Box>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'reset_password',
  validate
})(ResetPasswordForm)
