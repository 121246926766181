import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import InternalContainer from '../../layout/containers/Internal'
import * as Icons from '../../../common/components/SvgIcons'

export class PolicyTypesContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  render() {
    return (
      <InternalContainer title="Policies">
        <div className="row image-links">
          <div className="col-xs-6 col-sm-3 image-item">
            <Link to="/policies/applications">
              {Icons.quote}
              <label>Quotes</label>
            </Link>
          </div>
          <div className="col-xs-6 col-sm-3 image-item">
            <Link to="/policies/issued">
              {Icons.policy}
              <label>Policies</label>
            </Link>
          </div>
          <div className="col-xs-6 col-sm-3 image-item">
            <Link to="/policies/mid-term-adjustments">
              {Icons.mta}
              <label>Mid Term Adjustments</label>
            </Link>
          </div>
          <div className="col-xs-6 col-sm-3 image-item">
            <Link to="/policies/referrals">
              {Icons.referral}
              <label>Referrals</label>
            </Link>
          </div>
          <div className="col-xs-6 col-sm-3 image-item">
            <Link to="/policies/renewals">
              {Icons.renewal}
              <label>Renewals</label>
            </Link>
          </div>
          <div className="col-xs-6 col-sm-3 image-item">
            <Link to="/customers">
              {Icons.customer}
              <label>Customers</label>
            </Link>
          </div>
        </div>
      </InternalContainer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    product: state.product,
    policy: state.policy
  }
}

export default connect(mapStateToProps)(PolicyTypesContainer)

