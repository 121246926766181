import * as actions from './brokerApplicationReducer'
import Axios from 'axios'
import { push } from 'react-router-redux'
import queryString from '../../../helpers/queryString'
import handleErrorResponse from '../../../helpers/handleErrorResponse'
import { login } from '../../auth/redux/authActions';

export const defaultIncludes = []
// export const defaultIncludes = ['parent', 'principal', 'organisation_type', 'legal_entity_type', 'users', 'primary_contacts', 'accounts_contacts', 'network.standard_commission', 'insurers.authorised_users']

// + '?include=brokers,channels'
export function getBrokerApplication(id, includes = defaultIncludes) {
  return (dispatch) => {
    let endpoint = '/brokers/applications/' + id + '?include=organisation,networks,broker_region,broker_category,legal_entity_type'
    endpoint += queryString(includes)

    return Axios.get(endpoint).then(response => {
      dispatch({
        type: actions.SET_BROKER_APPLICATION,
        data: response.data
      })
      return true
    }).catch(error => {
      console.error(error)
    })
  }
}

export function createBrokerApplication(resource) {
  return dispatch => {
    dispatch(isSubmitting(true))
    return Axios.post('brokers/applications', resource).then(response => {
      dispatch(push('/admin/broker-applications/'))
      dispatch(isSubmitting(false))
      return true
    }).catch(error => {
      dispatch(isSubmitting(false))
      handleErrorResponse(error, 'There was an error creating the broker application')
    })
  }
}

export function createPublicBrokerApplication(resource) {
  return dispatch => {
    dispatch(isSubmitting(true))

    if(resource) {
      _.set(resource, 'meta.send_email', true)
    }

    return Axios.post('public/brokers/applications', resource).then(response => {
      dispatch({ type: actions.PUBLIC_SUBMIT_SUCCESS })
      dispatch(isSubmitting(false))
      return true
    }).catch(error => {
      dispatch(isSubmitting(false))
      handleErrorResponse(error, 'There was an error creating the broker application')
    })
  }
}

export function updateBrokerApplication(resource, includes = defaultIncludes) {
  return dispatch => {
    dispatch(isSubmitting(true))
    const id = resource.data.id
    let endpoint = '/brokers/applications/' + id
    endpoint += queryString(includes)

    return Axios.patch(endpoint, resource).then(response => {
      dispatch({
        type: actions.SET_BROKER_APPLICATION,
        data: response.data
      })
      dispatch(isSubmitting(false))
      dispatch(push('/admin/broker-applications'))
      return true
    }).catch(error => {
      dispatch(isSubmitting(false))
      handleErrorResponse(error, 'There was an error updating the broker application')
    })
  }
}

export function declineBrokerApplication(resource, includes = defaultIncludes) {
  return dispatch => {
    dispatch(isSubmitting(true))
    const id = resource.data.id
    let endpoint = '/brokers/applications/' + id + ':decline'

    return Axios.post(endpoint, resource).then(response => {
      // dispatch({
      //   type: actions.SET_BROKER_APPLICATION,
      //   data: response.data
      // })
      dispatch(isSubmitting(false))
      dispatch(push('/admin/broker-applications/'))
      return true
    }).catch(error => {
      dispatch(isSubmitting(false))
      handleErrorResponse(error, 'There was an error updating the broker application')
    })
  }
}

export function approveBrokerApplication(resource, includes = defaultIncludes) {
  return dispatch => {
    dispatch(isSubmitting(true))
    const id = resource.data.id
    let endpoint = '/brokers/applications/' + id + ':approve'

    if(resource) {
      _.set(resource, 'meta.send_email', true)
    }

    return Axios.post(endpoint, resource).then(response => {
      // dispatch({
      //   type: actions.SET_BROKER_APPLICATION,
      //   data: response.data
      // })
      dispatch(isSubmitting(false))
      dispatch(push('/admin/broker-applications/'))
      return true
    }).catch(error => {
      dispatch(isSubmitting(false))
      handleErrorResponse(error, 'There was an error updating the broker application')
    })
  }
}

export function resendBrokerApplication(resource, includes = defaultIncludes) {
  return dispatch => {
    dispatch(isSubmitting(true))
    const id = resource.data.id
    let endpoint = '/brokers/applications/' + id + ':send-approval-email'

    return Axios.post(endpoint, resource).then(response => {
      // dispatch({
      //   type: actions.SET_BROKER_APPLICATION,
      //   data: response.data
      // })
      dispatch(isSubmitting(false))
      dispatch(push('/admin/broker-applications/'))
      return true
    }).catch(error => {
      dispatch(isSubmitting(false))
      handleErrorResponse(error, 'There was an error updating the broker application')
    })
  }
}

export function saveAndApproveBrokerApplication(resource) {
  return dispatch => {
    return Axios.post('brokers/applications', resource).then(response => {
     // dispatch(approveBrokerApplication(response))

      return true
    }).catch(error => {
      handleErrorResponse(error, 'There was an error creating the broker application')
    })
  }
}


export function createTrackingLinkBroker(resource) {
  return dispatch => {
    return Axios.post('brokers', resource).then(response => {
      dispatch(push('/brokers/' + response.data.data.id)) //redirect to broker page
      return true
    }).catch(error => {
      handleErrorResponse(error, 'There was an error creating the broker')
    })
  }
}

export function isSubmitting(status) {
  return {
    type: actions.IS_SUBMITTING,
    status
  }
}

export function getProducts() {
  return dispatch => {
    return Axios.get('products?page[limit]=500').then(response => {
      dispatch({
        type: actions.SET_PRODUCTS,
        data: response.data
      })
      return true
    }).catch(error => {
      console.error(error)
    })
  }
}

export function submitBrokerActivation(token, resource) {
  return dispatch => {
    dispatch(isSubmitting(true))
    return Axios.post(`public/brokers/applications/${token}:activate`, resource).then(() => {
      // dispatch({ type: actions.APPLICATION_SUBMITTED, values: response.data })
      dispatch(login(resource.meta.email, resource.meta.password))
      dispatch(isSubmitting(false))
      return true
    }).catch(error => {
      handleErrorResponse(error, 'There was an error submitting your broker activation, please contact Arch Insurance (UK) Limited.')
    })
  }
}
