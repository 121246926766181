export const customer = {
  data: {
    type: 'customer',
    attributes: {
      title: '',
      first_name: '',
      last_name: '',
      dob: '',
      company_name: '',
      email: '',
      phone1: '',
      phone2: '',
      address: {
        line1: '',
        line2: '',
        line3: '',
        town: '',
        county: '',
        postcode: ''
      }
    }
  }
};

export const customerRules = {};

export default customer;
