import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box, DataTable } from '../../../../common/components'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { FormattedDate, FormattedTime } from 'react-intl'
// import { FormControl, ControlLabel } from 'react-bootstrap'
// import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions'
import { refreshData } from '../../../../common/components/DataTable/redux/dataTableActions'
import { openModal } from '../../../../common/components/Modal/ModalRedux'
import { LedgerEntriesModal } from '../LedgerEntriesModal'

export default class AccountBatchList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props)

    this.state = {
      batch: {}
    }
  }

  formatDate(cell) {
    return (
        <span><FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/></span>
    )
  }

  refreshList() {
    this.props.dispatch(refreshData('batches'))
  }

  // handleSearchUpdate(event) {
  //   this.props.dispatch(applyFilter('batches', 'filter[description]', event.target.value))
  // }
  //
  // handleJournalTypeUpdate(event) {
  //   this.props.dispatch(applyFilter('journals', 'filter[manual]', event.target.value))
  // }

  handleRowClick(row) {
    if (row.batch_size === 0) {
      return
    }

    this.setState({ batch: row })
    this.props.dispatch(openModal(LedgerEntriesModal.MODAL_IDENTIFIER))
  }

  render() {
    return (
      <Box>
        <h2>Batches</h2>
        {/*<div className="row table-filters">*/}
          {/*<div className="col-sm-4">*/}
            {/*<ControlLabel className="filter-label">Description</ControlLabel>*/}
            {/*<FormControl onChange={ ::this.handleSearchUpdate } />*/}
          {/*</div>*/}
          {/*<div className="col-sm-2">*/}
            {/*<ControlLabel className="filter-label">Type</ControlLabel>*/}
            {/*<FormControl*/}
              {/*componentClass="select"*/}
              {/*type="select"*/}
              {/*onChange={ ::this.handleJournalTypeUpdate }*/}
            {/*>*/}
              {/*<option value="true">Manual</option>*/}
              {/*<option value="false">System</option>*/}
              {/*<option value="all">All</option>*/}
            {/*</FormControl>*/}
          {/*</div>*/}
        {/*</div>*/}

        <div className="row">
          <div className="col-xs-12">
            <DataTable
                source="/accounts/batches"
                name="batches"
                showTotal={true}
                onRowSelect={this.handleRowClick.bind(this)}
            >

              <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
                ID
              </TableHeaderColumn>

              <TableHeaderColumn dataField="batch_type" width={'180px'} dataSort={true}>
                Type
              </TableHeaderColumn>

              <TableHeaderColumn dataField="batch_size" width={'180px'}>
                No. Entries
              </TableHeaderColumn>

              <TableHeaderColumn dataField="user_name" width={'180px'}>
                User
              </TableHeaderColumn>

              <TableHeaderColumn dataField="date" dataFormat={this.formatDate} width={'100px'} dataSort={true}>
                Batch Date
              </TableHeaderColumn>

              <TableHeaderColumn dataField="created_at" dataFormat={this.formatDate} width={'100px'} dataSort={true}>
                Created At
              </TableHeaderColumn>

            </DataTable>
            <LedgerEntriesModal dispatch={this.props.dispatch} batch={this.state.batch} callback={::this.refreshList}/>
          </div>
        </div>
      </Box>
    )
  }
}
