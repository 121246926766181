import React, { Component } from 'react'
import PropTypes from 'prop-types'
import OrganisationSettingsForm from '../components/MySettingsForm/OrganisationSettingsForm'
import { switchOrganisation } from '../redux/authActions'

export default class OrganisationSettings extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
  };

  handleSubmit(resource) {
    return this.props.dispatch(switchOrganisation(resource))
  }

  render() {
    return (
      <OrganisationSettingsForm resource={this.props.auth.user} onSubmit={::this.handleSubmit}/>
    )
  }
}
