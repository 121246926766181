import React, { Component } from 'react'
import { push } from 'react-router-redux'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { ControlLabel, FormControl } from 'react-bootstrap'
import { FormattedDate, FormattedTime } from 'react-intl'
import { Box, Button, DataTable } from '../../../../common/components'
import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions'
import './styles.scss'


/*****************************
 BrokersApplication DataTable
 *****************************/

export default class  BrokersApList extends Component {
  constructor(props) {
    super(props)
    this.handleSearchUpdate = this.handleSearchUpdate.bind(this)
  }

  formatDate(cell) {
    return (
      cell ? (
        <span>
          <FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/>
        </span>
      ) : (
        <span/>
      )
    )
  }


  handleRowClick(row) {
    this.props.dispatch(push('/admin/broker-applications/' + row.id))
  }

  handleFilterUpdate(event) {
    this.setState({ selection: event.target.value })
    switch (event.target.value) {
      case 'partners':
        this.setState({
          partner: true,
          personnel: false
        })
        break
      case 'keyPersonnel':
        this.setState({
          personnel: true,
          partner: false
        })
        break
      default:
        this.props.dispatch(applyFilter('content', 'filter[template.type]', event.target.value))
        this.setState({
          partner: false,
          personnel: false
        })
    }
  }

  handleRender() {
    return (
      <div>
        <Box>
          <div className="row no-bottom-margin">
            <div className="col-sm-5">
              <ControlLabel className="filter-label">Search</ControlLabel>
              <FormControl onChange={this.handleSearchUpdate} disabled/>
            </div>
            <div className="col-sm-3">
              <ControlLabel className="filter-label">Type</ControlLabel>
            </div>
          </div>
        </Box>
      </div>
    )
  }

  handleSearchUpdate(event) {
    this.props.dispatch(applyFilter('content', 'filter[name]', event.target.value))
  }

  render() {
    return (
      <div>
        <Box>
          <div className="row">
            <div className="col-sm-4">
              <ControlLabel className="filter-label">Search</ControlLabel>
              <FormControl onChange={this.handleSearchUpdate}/>
            </div>
          </div>

          <DataTable
            name="content"
            showTotal={true}
            source="/brokers/applications/"
            onRowSelect={this.handleRowClick.bind(this)}
          >
            <TableHeaderColumn
              isKey={true}
              hidden={true}
              dataField="id"
            >
              ID
            </TableHeaderColumn>
            <TableHeaderColumn
              width={'100px'}
              dataSort={false}
              dataField="reference"
            >
              Reference
            </TableHeaderColumn>
            <TableHeaderColumn
              width={'280px'}
              dataField="name"
              dataSort={false}
            >
              Name
            </TableHeaderColumn>
            <TableHeaderColumn
              width={'100px'}
              dataSort={false}
              dataField="status"
            >
              <span style={{ paddingLeft: 30 }}>
                Status
              </span>
            </TableHeaderColumn>
            <TableHeaderColumn
              dataSort={false}
              width={'180px'}
              dataField="activated_at"
              dataFormat={this.formatDate}
            >
              Activation Date
            </TableHeaderColumn>
          </DataTable>

          <div className="row actions">
            <Button
              link={true}
              bsStyle="primary"
              className="pull-right"
              label="new Application"
              to="/admin/broker-applications/new"
            />
          </div>
        </Box>
      </div>
    )
  }
}
