import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import HTML5Backend from 'react-dnd-html5-backend'
import { DragDropContext } from 'react-dnd'
import flow from 'lodash/flow'
import TextArea from '../../../content/components/TextArea'
import { Button, Icon } from '../../../../common/components'
import SortableItem from './SortableItem'
import './styles.scss'


class MedicalDeclarationQuestions extends Component {
  static propTypes = {
    fields: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      isHovering: false
    }
  }

  handleSort(originalPosition, newPosition) {
    const { fields } = this.props
    fields.move(originalPosition, newPosition)
  }

  handleIsReadOnly(isHovering) {
    this.setState({
      isHovering: isHovering
    })
  }

    render() {
      const { fields } = this.props
      const { isHovering } = this.state
        return (
          <div className="admin-medical-questions">
            <div className="sortable-list">
              <div className='list'>
                {fields.map((medicalQuestion, index) => {
                  const fieldType = fields.get(index).type
                  const title = fieldType && fieldType === 'header' ? ' Header' : ' Medical question'
                  return (
                    <SortableItem
                        key={index}
                        sortId={index}
                        updateState={::this.handleSort}
                        isReadOnly={::this.handleIsReadOnly}
                    >
                      <div key={index} className="row">
                        <div className="col-xs-12">
                          <span
                              className="remove-medical-question pull-right"
                              onClick={() => fields.remove(index)}>
                            <Icon name="times"/>
                          </span>
                          <label className="control-label">{'Item ' + (index + 1) + title}</label>
                        </div>
                        <div className="overlay-wrapper col-xs-12">
                          <div className="overlay"/>
                            <Field
                                name={medicalQuestion + '.content'}
                                type="textarea"
                                component={TextArea}
                                readOnly={isHovering}
                            />
                          </div>
                        </div>
                        </SortableItem>
                   )})}
               </div>
             </div>
             <div>
               <div className="row">
                 <div className="col-xs-4 col-md-3 col-lg-2">
                   <Button type="button" handleClick={() => fields.push({ type: 'question' })}>Add Medical Question</Button>
                 </div>
                 <div className="col-xs-4 col-md-3 col-lg-2">
                   <Button type="button" handleClick={() => fields.push({ type: 'header' })}>Add Header</Button>
                 </div>
             </div>
            </div>
          </div>
        )
    }
}

export default flow(
    connect(),
    DragDropContext(HTML5Backend),
)(MedicalDeclarationQuestions)
