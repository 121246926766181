import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Tabs, Tab } from 'react-bootstrap'
import InternalContainer from '../../layout/containers/Internal'
import AccountJournalList from '../components/AccountJournalList'
import AccountPaymentList from '../components/AccountPaymentList'
import AccountPeriodList from '../components/AccountPeriodList'
import AccountBatchList from '../components/AccountBatchList'
import AccountPremiumList from '../components/AccountPremiumList/'

class AccountBookkeepingContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context)

    this.state = {
      activeTab: 1
    }
  }

  handleTab(key) {
    this.setState({ activeTab: key })
  }

  render() {
    return (
      <InternalContainer title="Bookkeeping">
        <Tabs activeKey={this.state.activeTab}
              id="bookkeeping-tabs"
              unmountOnExit={true}
              onSelect={::this.handleTab}
              className='shadow-box'
        >
          <Tab eventKey={1} title="Payments &amp; Receipts">
            <AccountPaymentList {...this.props} />
          </Tab>
          <Tab eventKey={2} title="Premiums">
            <AccountPremiumList {...this.props} />
          </Tab>
          <Tab eventKey={3} title="Journals">
            <AccountJournalList {...this.props} />
          </Tab>
          <Tab eventKey={4} title="Batches">
            <AccountBatchList {...this.props} />
          </Tab>
          <Tab eventKey={5} title="Accounting Periods">
            <AccountPeriodList {...this.props} />
          </Tab>
        </Tabs>
      </InternalContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    account: state.account
  }
}

export default connect(mapStateToProps)(AccountBookkeepingContainer)
