import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { Button, Modal, HorizontalFormControl } from '../../../../common/components'
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux'
import { getNetwork, updateNetwork } from '../../redux/organisationActions'
import getIncludedResource from '../../../../helpers/getIncludedResource'
import * as _ from 'lodash'

const FORM_NAME = 'editNetwork'

const validate = values => {
  const errors = {}

  if (!_.get(values, 'data.attributes.name')) {
    _.set(errors, 'data.attributes.name', 'Network Name is required')
  }

  return errors
}

class EditNetworkButton extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    networkId: PropTypes.string.isRequired,
    callback: PropTypes.func,
  };

  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded, callback } = this.props
    if (newProps.submitSucceeded && !submitSucceeded) {
      if (callback) {
        callback('')
      }
      dispatch(closeModal(FORM_NAME))
    }
  }

  handleSubmit(values) {
    return this.props.dispatch(updateNetwork(this.props.networkId, values))
  }

  handleOpen() {
    const { dispatch, change, reset } = this.props
    dispatch(reset())

    dispatch(getNetwork(this.props.networkId, (data) => {
      const commission = getIncludedResource(data.data, data.included, 'standard_commission', 'commissions')
      const standardCommission = _.get(commission, 'data.attributes.rate', 0.00).toFixed(2)

      change('data.attributes.name', _.get(data, 'data.attributes.name'))
      change('meta.standard_commission', standardCommission)
    }))
      dispatch(openModal(FORM_NAME))
  }

  render() {
    const { handleSubmit, submitting } = this.props

    const submitButton = (<Button
      type="submit"
      bsStyle="primary"
      isLoading={submitting}
      handleClick={handleSubmit(::this.handleSubmit)}
      label="Update"
    />)

    return (
      <div className="email-btn-wrapper pull-right">
        <Button
          type="button"
          bsStyle="primary"
          label="Edit Network"
          rightIcon="pencil-square-o"
          // isLoading={resource.isEditing}
          handleClick={::this.handleOpen}
        />

        <Modal
          name={FORM_NAME}
          title="Edit Network"
          close={true}
          footer={submitButton}
        >
          <form onSubmit={handleSubmit(::this.handleSubmit)}>
            <div className="form-horizontal">
              <div className="row">
                <div className="col-xs-12">
                  <Field
                    name="data.attributes.name"
                    label="Name"
                    labelSize={4}
                    component={HorizontalFormControl}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <Field
                    name="meta.standard_commission"
                    label="Commission"
                    labelSize={8}
                    percentage={true}
                    component={HorizontalFormControl}
                  />
                </div>
              </div>
            </div>
            <br/>
          </form>
        </Modal>
      </div>
    )
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(EditNetworkButton)
const selector = formValueSelector(FORM_NAME)
const mapStateToProps = (state, props) => {
  const values = selector(state,
    'data.attributes.name',
    'meta.standard_commission',
  )

  return {
    formValues: values,
    initialValues: {
      meta: {
        standard_commission: '0.00',
      }
    }
  }

}

export default connect(mapStateToProps)(form)
