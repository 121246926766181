import React, { Component } from 'react'
import PropTypes from 'prop-types'
import '../../brochure.scss'
import './CenteredSection.scss'
//
export default class CenteredSection extends Component {
  static propTypes = {
    children: PropTypes.object,
  }

  render() {
    const { children } = this.props

    return (
        <div id={this.props.id} className={'full-width-section centered-section ' + (this.props.className || '')}>
          {(this.props.className && this.props.className != 'anchor') && (
              <div />
          )}

          <div className="container relative">
            <div className="row">
              <div className="col-md-offset-2 col-md-8 col-sm-9">
                {children}
              </div>
            </div>
          </div>

          {(this.props.className && this.props.className != 'anchor') && (
              <div />
          )}
        </div>
    )
  }
}
