import { Tabs, Tab, Row } from 'react-bootstrap';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, Field, FieldArray, formValueSelector } from 'redux-form';
import { RadioGroup, RadioGroupDisgusedAsButtons, SelectField } from '../../../../common/components/ReduxFormField';
import { Panel } from 'react-bootstrap';
import { StickyContainer, Sticky } from 'react-sticky';
import { getQuotes, clearQuotes, calculateAdjustment } from '../../redux/productActions';
import { hasPermission, isOwner, isBroker } from '../../../auth/redux/authActions';
import pluralize from 'pluralize';
import flatten from 'flat';
import * as _ from 'lodash';
import {
  Box,
  Button,
  Icon,
  SelectCountry,
  DatePickerLeisure,
  InfoButton, SelectCampaign, TravelFormSelectBroker, TravelFormSelectUser,
} from '../../../../common/components';
import CommissionAdjust from '../CommissionAdjust';
import PremiumAdjust from '../PremiumAdjust';
import { FormattedDate } from 'react-intl';
import Travellers from '../Travellers';
import AvailableQuotes from '../AvailableQuotes';
import SelectedQuotes from '../SelectedQuotes';
import AdjustmentForm from '../AdjustmentForm';
import QuoteActions from '../QuoteActions';
import displayName from '../../../../helpers/displayName';
import * as Icons from '../../../../common/components/SvgIcons';
import '../LeisureTravelForm/styles/LeisureTravelForm.scss';
import Markdown from 'react-markdown';
import { SelectBroker, SelectUser } from '../../../../common/components';
import Select from 'react-select';
import { getCampaigns } from '../../../campaigns/redux/campaignActions';
import BenefitsModal from '../BenefitsModal/BenefitsModal';
import displayCurrency from '../../../../helpers/displayCurrency';
import PremiumBreakdown from '../PremiumBreakdown';
import PaymentTab from '../LeisureTravelForm/Components/TabsContent/PaymentTab';
import IssueButton from '../IssueButton';
import Endorsements from '../Endorsements';


const META = 'data.attributes.metadata';

const FORM_NAME = 'ProCycleForm';
const TOTAL_PANELS = 5;

export class ProCycleForm extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    formValues: PropTypes.object.isRequired,
    screening: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    currentProduct: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    mta: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      enableQuotesTab: false,
      disabled: false,
      value: [],
      options: [],
      override: '', // used by MTA AdjustmentForm
    };
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.calculateQuotes = this.calculateQuotes.bind(this);
    this.transformDocLinks = this.transformDocLinks.bind(this);
    this.reCalculateForAdjustments = this.reCalculateForAdjustments.bind(this)
    this.setStateOverride = this.setStateOverride.bind(this)
    this.getQuoteAdjustment = this.getQuoteAdjustment.bind(this)
  }

  componentWillMount() {
    const { currentProduct, dispatch, change, mta, initialValues } = this.props;
    const getActivities = (currentProduct.data.attributes.metadata.activities || []).map((name) => {
      return {
        label: name,
        value: name,
      };
    });

    const annualTripDurations = _.get(currentProduct, 'data.attributes.metadata.trip_durations_annual', []);
    const activities = _.get(initialValues, 'data.attributes.metadata.activities', []);

    this.setState({
      options: getActivities,
      value: activities,
    });

    // Set default value for trip duration if there is only one option
    const maxTripDurations = _.keys(annualTripDurations);
    if (!mta && maxTripDurations.length === 1) {
      dispatch(change('data.attributes.metadata.max_trip_duration', maxTripDurations[0]));
    }
  }

  componentWillReceiveProps(nextProps) {
    const { formValues, dispatch } = this.props;

    const oldCampaign = _.get(formValues, `${META}.campaign`);
    const nextCampaign = _.get(nextProps.formValues, `${META}.campaign`);

    if (nextCampaign !== null && oldCampaign !== nextCampaign) {
      dispatch(getCampaigns(nextCampaign, ''));
    }
  }

  setStateOverride (override) {
    this.setState({
      override
    })
  }

  reCalculateForAdjustments () {
    // called as callback by PremiumAdjust component
    const { dispatch, handleSubmit } = this.props
    dispatch(handleSubmit(this.getQuoteAdjustment))
  }

  getQuoteAdjustment (application) {
    // called as callback by PremiumAdjust component
    const { dispatch, product, change, mta } = this.props
    if (!mta) {
      dispatch(getQuotes(product.productId, application))
    }
    if (mta) {
      let policyId = _.get(application, 'data.id')
      if (application.data.type === 'policies/applications') {
        policyId = _.get(application, 'data.relationships.policy.data.id')
      }
      dispatch(calculateAdjustment(policyId, application, change))
    }
  }

  handleTab(key) {
    // Change the tab and invokes the handleSubmit and calculateQuotes to refresh automatically
    this.setState({
      activeTab: key,
    }, () => {
      if (this.state.activeTab === 4) {
        this.props.dispatch(this.props.handleSubmit(::this.calculateQuotes));
      }
    });
  }

  openSpecificTab(key) {
    this.setState({
      activeTab: key,
    }, () => {
      // this.props.dispatch(clearQuotes());
    });
  }

  handleSelectChange(value) {
    this.setState({ value });
    let valuesArr = value.split(',');
    this.props.dispatch(this.props.change('data.attributes.metadata.activities', valuesArr));
  }

  calculateQuotes(application) {
    const { change, dispatch, product, mta } = this.props;
    if (mta) {
      let policyId = _.get(application, 'data.id');
      if (application.data.type === 'policies/applications') {
        policyId = _.get(application, 'data.relationships.policy.data.id');
      }
      // calculate adjustment
      dispatch(calculateAdjustment(policyId, application, change));
    } else {
      // Get quotes and reset any previously selected schemes
      /* commented to fix for ability to keep show quotes selected and saved on leisure travel */
      // dispatch(change('data.relationships.schemes.data', []))
      // _.set(application, 'data.relationships.schemes.data ', [])

      // Calculate
      dispatch(getQuotes(product.productId, application));
    }

    this.setState({
      activeTab: 4,
      enableQuotesTab: true,
    });
  }

  transformDocLinks(url) {
    const documents = this.props.currentProduct.data.attributes.documents;

    if (url === 'recreational_activities_list') {
      return documents['Information: Pro Cycle Recreational Activities'];
    } else if (url === 'cycle_activity_list') {
      return documents['Information: Pro Cycle Cycling Activities'];
    }
    return url;
  }

  render() {
    const { currentProduct, formValues, handleSubmit, product, resource, screening, error, mta, submitFailed, valid, campaign, change } = this.props;
    const taxType = _.get(currentProduct, 'data.attributes.metadata.tax_type');
    const childrenMaxAge = _.get(currentProduct, 'data.attributes.metadata.children.max_age');
    const formErrors = error && error instanceof Object ? flatten(error) : false;
    const renderMultiActivities = ({ input }) =>
      (<Select
        {...input}
        multi
        simpleValue disabled={this.state.disabled}
        value={this.state.value}
        options={this.state.options}
        onChange={this.handleSelectChange}
        dispatch={this.props.dispatch}
        change={this.props.change}
      />);

    const titleSection = (name, index, showCalc) => {
      return (
        <div className="header-title">
          {(showCalc && product.isCalculating) && (
            <div className="item-status">
              <Icon name="refresh" spin={true}/>
            </div>
          )}

          {(valid && showCalc && this.state.activePanel !== TOTAL_PANELS && !product.isCalculating) && (
            <div style={screening.index !== 0 ? { visibility: 'hidden' } : {}}>
              <Button
                className="quote-button"
                label={mta ? 'Calculate adjustment' : 'Get your quotes'}
                bsStyle="primary"
                size="xs"
                handleClick={handleSubmit(::this.calculateQuotes)}
              />
            </div>
          )}

          <div className="item-number">
            <span>{index}</span>
          </div>
          {name}
        </div>
      );
    };

    const availableQuotes = _.sortBy(product.quotes, 'meta.premium.gross');
    const productIcon = _.get(currentProduct, 'data.attributes.icon', '');
    const definitions = _.get(currentProduct, 'data.attributes.metadata.definitions', {});
    const isMultipleAnnualOption = () => annualTripDuration.length > 1;
    const tripType = () => _.get(formValues, 'data.attributes.metadata.scheme_type');
    const quotationDeclaration = _.get(currentProduct, 'data.attributes.metadata.quotation_declaration');
    const displayedErrors = [];
    const annualTripDuration = currentProduct.data.attributes.metadata.trip_durations_annual || [];
    const hazardousActivities = _.get(currentProduct, 'data.attributes.metadata.hazardous_activities', []);
    const productDocuments = _.get(currentProduct, 'data.attributes.documents');
    const residencies = _.map(currentProduct.data.attributes.metadata.residencies || {}, ((name, key) => {
      return {
        label: name,
        value: key,
      };
    }));
    const regions = _.map(currentProduct.data.attributes.metadata.regions || {}, ((name, key) => {
      return {
        label: name,
        value: key,
      };
    }));

    const cloned = _.get(formValues, 'data.attributes.metadata.cloned', false);

    const campaignId = _.get(formValues, 'data.attributes.metadata.campaign');
    const campaignInfo = _.get(campaign, `resources[${campaignId}].data.attributes`);
    let scheme = _.get(formValues, 'data.relationships.schemes.data', []);
    const selectedScheme = scheme && scheme.length === 1 && scheme[0].id ? product.quotes[scheme[0].id] : false;
    const schemeId = selectedScheme && selectedScheme.meta && selectedScheme.meta.scheme.id;
    const travellerGroup = _.get(formValues, 'data.attributes.metadata.traveller_group');
    const displayBox = (isOwner() && resource.data.id && resource.data.attributes.broker_name) || (resource && resource.data.attributes.policy_number);

    return (
      <div>
        <form>
          <div>
            { /* Broker Details Box */}
            {isOwner() && (

              <Box className='broker-details-box'>
                <Row>
                  <Field
                    name='data.relationships.broker.data.id'
                    label='Broker'
                    filters={[
                      { filter: 'filter[product]', value: currentProduct.data.id },
                    ]}
                    component={TravelFormSelectBroker}
                  />
                  {_.get(formValues, 'data.relationships.broker.data.id') && (
                    <Field
                      name='data.relationships.user.data.id'
                      filters={[
                        { filter: 'filter[broker]', value: _.get(formValues, 'data.relationships.broker.data.id') },
                      ]}
                      labelKeys={[
                        'attributes.first_name',
                        'attributes.last_name',
                      ]}
                      component={TravelFormSelectUser}
                      label='User'
                    />
                  )}
                </Row>
              </Box>
            )}


            <div
              className={_.get(formValues, 'data.relationships.broker.data.id') && _.get(formValues, 'data.relationships.user.data.id') || isBroker() ? '' : 'hidden'}>
              <div className="quotes-tabs">
                <div className="quote-tabs-content">
                  <Tabs activeKey={this.state.activeTab}
                        id="leisure-travel"
                        unmountOnExit={true}
                        onSelect={::this.handleTab}>
                    { /* Tab 01: Trip Details */}
                    <Tab eventKey={1} title="Trip&#160;Details">
                      <div className="leisure-tab-content clearfix">
                        {/* Quotation Declaration */}

                        <h5><strong>Declaration</strong></h5>
                        <div className='quotation-declaration-container '>
                          <div className='quotation-declaration'>
                            <Markdown
                              source={quotationDeclaration || 'I currently reside in the UK.'}
                              linkTarget='_blank'
                              transformLinkUri={this.transformDocLinks}
                            />
                          </div>
                          <div className='agree-radio'>
                            <Field
                              name={'data.attributes.metadata.quotation_declaration'}
                              component={RadioGroupDisgusedAsButtons}
                              inline
                              options={[
                                { label: 'Yes', value: true, icon: 'check' },
                                { label: 'No', value: false, icon: 'times' },
                              ]}
                            />
                          </div>
                        </div>

                        { /* Residence  */}
                        <div className='form-horizontal'>
                          <Field
                            label="Residence"
                            infoButton={<InfoButton content={definitions.residency}/>}
                            labelSize={2}
                            name="data.attributes.metadata.residency"
                            component={SelectField}
                            inline={true}
                            options={residencies}
                          />
                        </div>
                        <div className="form-horizontal">
                          <Field
                            name="data.attributes.metadata.scheme_type"
                            label="Trip Type"
                            infoButton={<InfoButton content={definitions.trip_type}/>}
                            labelSize={2}
                            component={RadioGroup}
                            inline={true}
                            options={[
                              { label: 'Single Trip', value: 'single' },
                              { label: 'Annual Multi-trip', value: 'annual' },
                            ]}
                          />
                        </div>
                        { /* Cover Dates */}
                        <div className="form-horizontal">
                          <div className="">
                            {_.get(formValues, 'data.attributes.metadata.scheme_type') === 'annual' || _.get(formValues, 'data.attributes.metadata.scheme_type') === 'longstay' ? (
                              <Field
                                name="data.attributes.metadata.start_date"
                                label="Start date"
                                minDateToday
                                labelSize={2}
                                component={DatePickerLeisure}
                              />
                            ) : (
                              <div className="row select-data">
                                <div className="date-label">
                                  Start date
                                </div>
                                <div className="date-box">
                                  <div className="select-data-field1">
                                    <Field
                                      infoButton={<InfoButton content={definitions.travel_dates}/>}
                                      name="data.attributes.metadata.start_date"
                                      label=""
                                      minDateToday
                                      labelSize={2}
                                      component={DatePickerLeisure}
                                    />
                                  </div>
                                  <div className="label2">
                                    To
                                  </div>
                                  <div className="select-data-field2">
                                    <Field
                                      name="data.attributes.metadata.end_date"
                                      label=""
                                      minDate={_.get(formValues, 'data.attributes.metadata.start_date')}
                                      labelSize={2}
                                      component={DatePickerLeisure}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        { /* Destination Countries -  */}
                        {_.get(formValues, 'data.attributes.metadata.scheme_type') === 'single' && (
                          <div className="form-horizontal">
                            <Field
                              name="data.attributes.metadata.destinations"
                              label="Destinations"
                              placeholder="Search for countries..."
                              component={SelectCountry}
                              labelSize={2}
                              multi
                            />
                          </div>
                        )}
                        { /* If annual display the Regions of Travel */}
                        {(_.get(formValues, 'data.attributes.metadata.scheme_type') === 'annual' || _.get(formValues, 'data.attributes.metadata.scheme_type') === 'longstay') && (
                          <div className="form-horizontal">
                            <Field
                              name="data.attributes.metadata.region"
                              label="Region:"
                              labelSize={2}
                              infoButton={<InfoButton content={definitions.region}/>}
                              mdColumnSize={4}
                              component={SelectField}
                              options={regions}
                            />
                          </div>
                        )}
                        { /* Display if there is more than one option in annualTripDuration*/}
                        <div className="form-horizontal">
                          {(tripType() === 'annual' || tripType() === 'longstay') && isMultipleAnnualOption() && (
                            <Field
                              name="data.attributes.metadata.max_trip_duration"
                              label="Duration:"
                              labelSize={2}
                              infoButton={<InfoButton content={definitions.max_duration}/>}
                              mdColumnSize={3}
                              component={RadioGroup}
                              inline={true}
                              options={annualTripDuration}
                            />
                          )}
                        </div>
                        <Button className="pull-right btn-action" label="Travellers" bsStyle="success"
                                handleClick={() => ::this.openSpecificTab(2)}
                        />
                      </div>
                    </Tab>
                    { /* Tab 02: Travellers */}
                    <Tab eventKey={2} title="Travellers">
                      <div className="leisure-tab-content clearfix">
                        <div className="">
                          <div className='form-horizontal'>
                            <Row className='group-types'>
                              <Field
                                name='data.attributes.metadata.traveller_group'
                                label='Group Type'
                                labelSize={2}
                                infoButton={<InfoButton content={definitions.group_type}/>}
                                mdColumnSize={3}
                                component={RadioGroupDisgusedAsButtons}
                                options={[
                                  {
                                    label: 'Individual',
                                    icon: Icons.individuals,
                                    value: 'individual',
                                    icon: 'user',
                                    customIconSet: false,
                                  },
                                  {
                                    label: 'Couple',
                                    icon: Icons.couple,
                                    value: 'couple',
                                    icon: 'icon-couple',
                                    customIconSet: true,
                                  },
                                  {
                                    label: 'Family',
                                    icon: Icons.family,
                                    value: 'family',
                                    icon: 'icon-family',
                                    customIconSet: true,
                                  },
                                  {
                                    label: 'Group',
                                    icon: Icons.group,
                                    value: 'group',
                                    icon: 'icon-group',
                                    customIconSet: true,
                                  },
                                ]}
                              />
                            </Row>
                          </div>
                        </div>

                        <div style={!travellerGroup ? { display: 'none' } : {}} className="clearfix">
                            <div className="form-horizontal">
                              <FieldArray
                                name="data.attributes.metadata.adults"
                                formValues={_.get(formValues, 'data.attributes.metadata.adults')}
                                travellerGroup={_.get(formValues, 'data.attributes.metadata.traveller_group')}
                                noTravellers={{
                                  adults: _.get(formValues, 'data.attributes.metadata.adults', []).length,
                                  children: _.get(formValues, 'data.attributes.metadata.children', []).length,
                                }}
                                leadAddress={true}
                                change={this.props.change}
                                component={Travellers}
                                cloned={cloned}
                              />
                            </div>
                            <div className="form-horizontal">
                              <FieldArray
                                name='data.attributes.metadata.children'
                                formValues={_.get(formValues, 'data.attributes.metadata.children')}
                                travellerGroup={_.get(formValues, 'data.attributes.metadata.raveller_group')}
                                noTravellers={{
                                  adults: _.get(formValues, 'data.attributes.metadata.adults', []).length,
                                  children: _.get(formValues, 'data.attributes.metadata.children', []).length,
                                }}
                                displayGroups={['family']}
                                child
                                component={Travellers}
                                childrenMaxAge={childrenMaxAge}
                              />
                            </div>
                          </div>

                        <div className="row">

                          {!travellerGroup &&
                            <hr/>
                          }

                          <div className="actions">
                            <Button className="btn-action-back pull-left" label="Trip Details" bsStyle="primary"
                                    handleClick={() => ::this.openSpecificTab(1)}/>
                            <Button className="pull-right btn-action" label="Options" bsStyle="success" disabled={!travellerGroup}
                                    handleClick={() => ::this.openSpecificTab(3)}/>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    { /* Tab 03 Options  */}
                    <Tab eventKey={3} title="Options">
                      <div className='leisure-tab-content clearfix'>
                        <div className="col-sm-12">
                          <div className="row">
                                <h4>Please select the cycling activities you will be participating in.</h4>
                              </div>
                              <div className="row">
                                {_.get(productDocuments, 'Information: Pro Cycle Cycling Activities') && (
                                  <div className='info-doc'>
                                    <Markdown
                                      source={hazardousActivities.cycling_activities}
                                      linkTarget='_blank'
                                      transformLinkUri={this.transformDocLinks}/>
                                  </div>
                                )}
                              </div>
                              <div className="row">
                                <p>Depending on the activities you select below, you will be automatically offered either
                                  Touring or Adventure cover.</p>
                              </div>
                              <div className="row">
                                <label className='control-label margin-bottom'>Start typing the activity name and select
                                  all activities that require cover below:
                                  <Field
                                    name='data.attributes.metadata.activities'
                                    component={renderMultiActivities}
                                    options={this.state.options}
                                  />
                                </label>
                              </div>
                              <div className="row">
                                {_.get(productDocuments, 'Information: Pro Cycle Recreational Activities') && (
                                  <div className='info-doc'>
                                    <Markdown
                                      source={hazardousActivities.recreational_activities}
                                      linkTarget='_blank'
                                      transformLinkUri={this.transformDocLinks}/>
                                  </div>
                                )}
                              </div>

                          <div className="row">
                            <div className="button-bar-options">
                              <Button
                                className="btn-action-back" label="Travellers" bsStyle="secondary"
                                handleClick={() => ::this.openSpecificTab(2)}/>
                              <Button
                                className="pull-right btn-action" label="Quotes" bsStyle="success"
                                handleClick={handleSubmit(::this.calculateQuotes)}/>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    { /* Tab 04 Quotes  */}
                    <Tab eventKey={4} title={mta ? 'Calculate Adjustment' : 'Quotes'}>
                      <div className="leisure-tab-content clearfix">
                        <Panel header={titleSection(mta, (isOwner()), true)}
                               className='panel-transparent'
                               eventKey="5">

                          {mta ? (
                            <div>
                              <FieldArray
                                name='data.attributes.metadata.endorsements'
                                formValues={_.get(formValues, 'data.attributes.metadata.endorsements', [])}
                                component={Endorsements}
                                fieldComponent={Field}
                              />
                              <AdjustmentForm
                                setStateOverride={this.setStateOverride}
                                overrideState={this.state.override}
                                product={product}
                                currentProduct={currentProduct}
                                image={productIcon}
                                change={this.props.change}
                                applicationId={resource.data.id}
                                selectedSchemes={_.get(formValues, 'data.relationships.schemes.data', [])}
                                handleSubmit={handleSubmit}
                              />
                            </div>

                          ) : (
                            <div>
                              <AvailableQuotes
                                product={product}
                                currentProduct={currentProduct}
                                availableQuotes={availableQuotes}
                                selectedSchemes={_.get(formValues, 'data.relationships.schemes.data', [])}
                                updateSelection={this.props.array}
                                change={this.props.change}
                                schemeType={_.get(formValues, 'data.attributes.metadata.scheme_type', 'annual')}
                                handleSubmit={handleSubmit}
                              />
                              <SelectedQuotes
                                product={product}
                                currentProduct={currentProduct}
                                declaration={_.get(currentProduct, 'data.attributes.metadata.purchase_declaration', '')}
                                image={productIcon}
                                selectedSchemes={_.get(formValues, 'data.relationships.schemes.data', [])}
                                handleSubmit={handleSubmit}
                              />
                            </div>
                          )}
                        </Panel>

                        <div class="clearfix" />
                        <Button className='btn-action-back' label={'Options'} bsStyle='secondary'
                                handleClick={() => this.openSpecificTab(3)}/>
                        <Button
                          className='pull-right btn-action '
                          label='Payment'
                          bsStyle='success'
                          handleClick={() => this.openSpecificTab(5)}
                          disabled={schemeId ? false : true}
                        />

                      </div>
                    </Tab>
                    <Tab eventKey={5} title={'Payment'}>
                      <div className='leisure-tab-content clearfix'>
                        <div>
                          <PaymentTab
                            currentProduct={currentProduct}
                            product={product}
                            declaration={_.get(currentProduct, 'data.attributes.metadata.purchase_declaration', '')}
                            selectedSchemes={_.get(formValues, 'data.relationships.schemes.data', [])}
                            image={productIcon}
                            schemeId={schemeId}
                            saveApplication={handleSubmit}
                            mta={mta}
                            procycle={true}
                          />
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
                <div className="clearfix"/>
                {displayBox &&
                  <Box className='box--top-border'>
                    {resource && resource.data.attributes.policy_number ? (
                      <div>
                        <h2>Policy number: {resource.data.attributes.policy_number}</h2>
                      </div>
                    ) : resource.data.attributes.quote_reference && (
                      <div>
                        { /*  <h2>Reference: {resource.data.attributes.quote_reference}</h2> */}
                      </div>
                    )}
                    {(isOwner() && resource.data.id && resource.data.attributes.broker_name) && (
                      <div>
                        <h3>Broker: {resource.data.attributes.broker_name} ({resource.data.attributes.user_name})</h3>
                      </div>
                    )}
                  </Box>
                }

                {(!error && Object.keys(formErrors).length === 0) && (
                  <div className={mta ? 'hidden premium-adjust-box' : 'premium-adjust-box'}>
                    { (Object.keys(product.quotes).length > 0 && _.get(formValues, 'data.relationships.schemes.data', []).length === 1 &&
                      this.state.activeTab !== TOTAL_PANELS) && (
                        <PremiumAdjust
                          taxType={taxType}
                          baseName='data.attributes.metadata.overrides'
                          formName={FORM_NAME}
                          change={change}
                          setStateOverride={this.setStateOverride}
                          scheme={_.get(formValues, 'data.relationships.schemes.data')[0]}
                          open={!_.isEmpty(_.get(formValues, 'data.attributes.metadata.overrides', {}))}
                          reCalculateForAdjustments={this.reCalculateForAdjustments}
                        />
                    )}
                  </div>
                )}
              </div>

            </div>
          </div>
        </form>

        { /*  CALCULATE QUOTE SIDEBAR */}

        <div
          className={_.get(formValues, 'data.relationships.broker.data.id') && _.get(formValues, 'data.relationships.user.data.id') || isBroker() ? '' : 'hidden'}>
          <StickyContainer className="calculate-quote-sidebar">
            <div className="calculate-quote-sidebar__heading">
              Quote Summary
            </div>

            <Sticky>
              <div className="row sticky-inner-container">
                {resource.data.attributes.policy_number ? (
                  <div className="col-xs-12 quote-divider">
                    <div className="quote-title">Policy number</div>
                    <div className="quote-info">{resource.data.attributes.policy_number}</div>
                  </div>
                ) : resource.data.attributes.quote_reference && (
                  <div className="col-xs-12 quote-divider">
                    <div className="quote-title">Reference:</div>
                    <div className="quote-info">{resource.data.attributes.quote_reference}</div>
                  </div>
                )}

                {isOwner() && (
                  <div className='col-xs-12 quote-divider'>
                    <Field
                      label={'Campaign:'}
                      labelSize={3}
                      name='data.attributes.metadata.campaign'
                      filters={[
                        { filter: 'filter[product]', value: currentProduct.data.id },
                      ]}
                      component={SelectCampaign}
                    />
                  </div>
                )}

                {submitFailed && error && formErrors ? (
                  <div className="col-xs-12 quote-divider">
                    <div className="error-box">
                      <h4>Attention required</h4>
                      <ul>
                        {Object.keys(formErrors).map((key, i) => {
                          if (displayedErrors.indexOf(formErrors[key]) === -1) {
                            displayedErrors.push(formErrors[key]);
                            return (
                              <li key={i}>{formErrors[key]}</li>
                            );
                          }
                        })}
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div className="">
                    {hasPermission('policy.handle_all_referrals') && _.get(resource, 'data.attributes.metadata.referrals', []).length > 0 && (
                      <div className="col-xs-12 quote-divider">
                        <div className="quote-title">Referral reasons:</div>
                        <div className="quote-info">
                          <ul>
                            {_.get(resource, 'data.attributes.metadata.referrals', []).map((reason, i) => (
                              <li key={i}>{reason}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}

                    {_.get(formValues, 'data.attributes.metadata.scheme_type') && (
                      <div className="col-xs-12 quote-divider">
                        <div className="quote-title">Type of cover:</div>
                        <div
                          className="quote-info">{formValues.data.attributes.metadata.scheme_type === 'single' ? ('Single Trip Travel') : ('Annual Multi-trip Travel')}</div>
                      </div>
                    )}

                    {_.get(formValues, 'data.attributes.metadata.traveller_group') && (
                      <div className="col-xs-12 quote-divider">
                        <div className="quote-title">Travellers:</div>
                        <div className="quote-info">
                          {displayName(formValues.data.attributes.metadata.traveller_group)}<br/>
                          {formValues.data.attributes.metadata.adults.length} x {pluralize('adult', formValues.data.attributes.metadata.adults.length)}
                          {_.get(formValues, 'data.attributes.metadata.children', []).length !== 0 ? ', ' + formValues.data.attributes.metadata.children.length + ' x ' +
                            pluralize('children', formValues.data.attributes.metadata.children.length) : ''}
                        </div>
                      </div>
                    )}

                    {(campaignInfo) && (
                      <div className='col-xs-12 quote-divider'>
                        <div className='quote-title'>Campaign:</div>
                        <div className='quote-info'>
                          <span>{campaignInfo.name}</span>
                          <span>{' '}({campaignInfo.code})</span>
                        </div>
                      </div>
                    )}

                    {(_.get(formValues, 'data.attributes.metadata.destinations', []).length > 0 || _.get(formValues, 'data.attributes.metadata.region')) && (
                      <div className="col-xs-12 quote-divider">
                        <div className="quote-title">Destinations:</div>
                        <div className="quote-info">
                          {tripType() === 'single' ? (
                            <span>
                            {formValues.data.attributes.metadata.destinations.map((destination, i) => {
                              let detail = i ? ', ' : '';
                              return (detail + destination);
                            })}
                          </span>
                          ) : (
                            <span>
                            {displayName(formValues.data.attributes.metadata.region)}
                          </span>
                          )}
                        </div>
                      </div>
                    )}

                    {_.get(formValues, 'data.attributes.metadata.activities') && (
                      <div className="col-xs-12 quote-divider">
                        <div className="quote-title">Activities</div>
                        <div className="quote-info">
                        <span>
                           {formValues.data.attributes.metadata.activities.map((activity, i) => {
                             let detail = i ? ', ' : '';
                             return (detail + activity);
                           })}
                        </span>
                          <br/>
                        </div>
                      </div>
                    )}

                    {((tripType() === 'annual' || tripType() === 'longstay') && _.get(formValues, 'data.attributes.metadata.max_trip_duration')) && (
                      <div className="col-xs-12 quote-divider">
                        <div className="quote-title">Max trip duration:</div>
                        <div className="quote-info">{formValues.data.attributes.metadata.max_trip_duration} Days</div>
                      </div>
                    )}

                    {_.get(formValues, 'data.attributes.metadata.start_date') && (
                      <div className="col-xs-12 quote-divider">
                        <div className="quote-title">Dates of cover:</div>
                        <div className="quote-info">
                          <FormattedDate
                            value={new Date(formValues.data.attributes.metadata.start_date)}/>{formValues.data.attributes.metadata.end_date && tripType() === 'single' && (
                          <span> to <FormattedDate
                            value={new Date(formValues.data.attributes.metadata.end_date)}/></span>)}
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {scheme.length === 1 &&
                _.get(product, 'premiumIndication.meta.scheme.scheme_type', false) ? ( // use premium indication
                  <div className="schemeSelected__wrapper">
                    {this.renderSchemeSummary(_.get(product, 'premiumIndication'), 0, scheme)}
                  </div>
                ) : (
                  <div className="schemeSelected__wrapper">
                    {scheme.map((scheme, i) => {
                      const selection = scheme.id ? product.quotes[scheme.id] : null;
                      if (selection) {
                        return this.renderSchemeSummary(selection, i);
                      }
                    })}
                  </div>
                )}

                {(!error && Object.keys(formErrors).length === 0) && (
                  <div className="col-xs-12 bottom-bar">

                    {!mta && (
                      <QuoteActions
                        product={product}
                        currentProduct={currentProduct}
                        declaration={_.get(currentProduct, 'data.attributes.metadata.purchase_declaration', '')}
                        image={productIcon}
                        selectedSchemes={_.get(formValues, 'data.relationships.schemes.data', [])}
                        handleSubmit={handleSubmit}
                      />
                    )}

                    {!mta && (Object.keys(product.quotes).length > 0 || product.isCalculating) && (
                      <Field
                        name="data.attributes.metadata.overrides.commission_broker"
                        product={product}
                        currentProduct={currentProduct}
                        calculate={handleSubmit(::this.calculateQuotes)}
                        component={CommissionAdjust}
                      />
                    )}
                  </div>
                )}
              </div>
            </Sticky>
          </StickyContainer>
        </div>
      </div>
    );
  }


  renderSchemeSummary(selection, i) {
    const { product, mta, showBlocker } = this.props;
    const originalFee = _.get(selection, 'meta.premium.fees', 0);
    if (mta) {
      return;
    }
    if (product.isSaving) {
      return;
    }
    if (showBlocker) {
      return;
    }
    return (
      <div className="col-xs-12 schemeSelected" key={i}>
        <div className="row">
          <div className="col-md-12">
            Chosen Scheme{' '}
            <BenefitsModal
              scheme={selection.meta.scheme}
              benefits={selection.meta.benefits}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">{selection.meta.scheme.name} &nbsp;</div>
        </div>
        <div className="row">
          <div className="col-md-8">Base Premium</div>
          <div className="col-md-4 sidebar-summary-premium">
            {' '}
            {displayCurrency(_.get(selection.meta.premium, 'principal', ''))}
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            IPT ({_.get(selection, 'meta.rates.tax_rate', 0)}%)
          </div>
          <div className="col-md-4 sidebar-summary-premium">
            {' '}
            {displayCurrency(_.get(selection.meta.premium, 'tax', ''))}{' '}
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">Admin Fees</div>
          <div className="col-md-4 sidebar-summary-premium">
            {' '}
            {displayCurrency(originalFee)}{' '}
          </div>
          {/*<div className='col-md-8'>Override Fees</div>*/}
          {/*<div className='col-md-4 sidebar-summary-premium'> {displayCurrency(overridefee)} </div>*/}
        </div>

        <div className="row">
          <div className="col-md-8">Total Payable</div>
          <div className="col-md-4 sidebar-summary-premium">
            <PremiumBreakdown
              premium={selection.meta}
              className="small-premium"
              showIpt={false}
              taxType={_.get(product, 'data.attributes.metadata.tax_type')}
            />
          </div>
        </div>

        {_.get(selection.meta.premium, 'discount', false) ? (
          <div className="row">
            <div className="col-md-8">Discount</div>
            <div className="col-md-4 sidebar-summary-premium">
              (
              {displayCurrency(
                _.get(selection.meta.premium_before_adjustments, 'gross', 0) -
                _.get(selection.meta.premium, 'gross', 0),
              )}
              )
            </div>
          </div>
        ) : null}
      </div>
    );
  }

}

const validate = values => {
  const errors = {};

  const requiredFields = [
    { field: 'data.attributes.metadata.scheme_type', name: 'Type of cover' },
    { field: 'data.attributes.metadata.traveller_group', name: 'Group type' },
    { field: 'data.attributes.metadata.start_date', name: 'Start date' },
  ];

  requiredFields.map(required => {
    if (!_.get(values, required.field)) {
      _.set(errors, required.field, required.name + ' is required');
    }
  });

  if ((_.get(values, 'data.attributes.metadata.scheme_type') === 'annual' || _.get(values, 'data.attributes.metadata.scheme_type') === 'longstay')) {
    if (!_.get(values, 'data.attributes.metadata.region')) {
      _.set(errors, 'data.attributes.metadata.region', 'Please select a region');
    }
  } else {
    if (_.get(values, 'data.attributes.metadata.destinations', []).length === 0) {
      _.set(errors, 'data.attributes.metadata.destinations', 'Please select a destination');

      if (!_.get(values, 'data.attributes.metadata.end_date')) {
        _.set(errors, 'data.attributes.metadata.end_date', 'Please select an end date');
      }
    }
  }

  const quotationDeclaration = (_.get(values, 'data.attributes.metadata.quotation_declaration') === true
    || _.get(values, 'data.attributes.metadata.quotation_declaration') === 'true');

  /* Quotation checkbox */
  if (!quotationDeclaration) {
    _.set(errors, 'data.attributes.metadata.quotation_declaration', 'Please agree to quotation declaration');
  }

  _.get(values, 'data.attributes.metadata.adults', []).map((adult, i) => {
    if (i === 0 && !adult.title) {
      _.set(errors, 'data.attributes.metadata.adults[' + i + '].title', 'Title is required');
    }

    if (i === 0 && !adult.first_name) {
      _.set(errors, 'data.attributes.metadata.adults[' + i + '].first_name', 'First name is required');
    }

    if (i === 0 && !adult.last_name) {
      _.set(errors, 'data.attributes.metadata.adults[' + i + '].last_name', 'Last name is required');
    }

    if (adult.dob === '') {
      _.set(errors, 'data.attributes.metadata.adults[' + i + '].dob', 'Date of birth is required');
    }
  });

  _.get(values, 'data.attributes.metadata.children', []).map((child, i) => {
    if (child.dob === '') {
      _.set(errors, 'data.attributes.metadata.children[' + i + '].dob', 'Date of birth is required');
    }
  });

  _.get(values, 'data.attributes.metadata.medical_declaration_questions', []).map((declaration, i) => {
    if (declaration.answer === '') {
      _.set(errors, 'data.attributes.metadata.medical_declaration_questions[' + i + '].answer', 'Medical declaration is required');
    }
  });

  errors._error = errors.data;

  return errors;
};

const form = reduxForm({ form: FORM_NAME, validate })(ProCycleForm);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, props) => {
  const values = selector(state,
    'data.id',
    'data.attributes.metadata.adults[0].address',
    'data.relationships.schemes.data',
    'data.attributes.metadata.adults',
    'data.attributes.metadata.children',
    'data.attributes.metadata.medical_declaration_questions',
    'data.attributes.metadata.options',
    'data.attributes.metadata.cloned',
    'data.attributes.metadata.scheme_type',
    'data.attributes.metadata.start_date',
    'data.attributes.metadata.end_date',
    'data.attributes.metadata.destinations',
    'data.attributes.metadata.region',
    'data.attributes.metadata.traveller_group',
    'data.attributes.metadata.endorsements',
    'data.attributes.metadata.subjectivities',
    'data.relationships.broker.data.id',
    'data.relationships.user.data.id',
    'data.attributes.metadata.residency',
    'data.attributes.metadata.activities',
    'data.attributes.metadata.overrides',
    'data.attributes.metadata.campaign',
  );

  return {
    initialValues: props.resource,
    formValues: values,
    campaign: state.campaign,
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(form);
