import { Panel, Row, Tab, Tabs } from 'react-bootstrap';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, FieldArray, formValueSelector, reduxForm } from 'redux-form';
import {
  calculateAdjustment,
  getQuotes,
  saveQuotesOnly
} from '../../redux/productActions';
import { getCampaigns } from '../../../campaigns/redux/campaignActions';
import { isBroker, isOwner } from '../../../auth/redux/authActions';
import { toastr } from 'react-redux-toastr';
import flatten from 'flat';
import {
  Box,
  Button,
  Icon,
  TravelFormSelectBroker,
  TravelFormSelectUser,
} from '../../../../common/components';
import PremiumAdjust from '../PremiumAdjust';
import AvailableQuotes from '../AvailableQuotes';
import SelectedQuotes from '../SelectedQuotes';
import AdjustmentForm from '../AdjustmentForm';
import './styles/LeisureTravelForm.scss';
import TripDetailsTab from './Components/TabsContent/TripDetailsTab';
import TravellersTab from './Components/TabsContent/TravellersTab';
import MedicalTab from './Components/TabsContent/MedicalTab';
import OptionsTab from './Components/TabsContent/OptionsTab';
import PaymentTab from './Components/TabsContent/PaymentTab';
import validate from './ReduxForm/validate';
import SideBar from './Components/SideBar/SideBar';
import * as _ from 'lodash';
import Loader from '../../../../common/components/Loader/Loader';
import Endorsements from '../Endorsements';
import SignPostingText from './Components/Signpost/Signpost';


const META = 'data.attributes.metadata';

const FORM_NAME = 'LeisureTravelForm';
const TOTAL_PANELS = 6;
const TMK_PRODUCT = ['TMK', 'TMKH', 'TMKC', 'TMKA'];

export class LeisureTravelForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 1,
      override: '', // used by MTA AdjustmentForm
    };
    this.calculateQuotes = this.calculateQuotes.bind(this);
    this.handleTab = this.handleTab.bind(this);
    this.openSpecificTab = this.openSpecificTab.bind(this);
    this.triggerValidation = this.triggerValidation.bind(this);
    this.reCalculateForAdjustments = this.reCalculateForAdjustments.bind(this);
    this.setStateOverride = this.setStateOverride.bind(this);
    this.getQuoteAdjustment = this.getQuoteAdjustment.bind(this);
  }

  componentWillMount() {
    const { currentProduct, change, dispatch, mta } = this.props;

    const tripTypeOptions = _.get(currentProduct, 'data.attributes.metadata.trip_type', {});
    const longStayTrip = !!tripTypeOptions['longstay'];
    const productCode = _.get(currentProduct, 'data.attributes.product_code', '');
    const annualTripDurations = _.get(currentProduct, 'data.attributes.metadata.trip_durations_annual', []);

    // if product is a longStay dispatch to longstay and set trip duration to 365 days
    if (!mta && longStayTrip) {
      dispatch(change('data.attributes.metadata.scheme_type', 'longstay'));
      dispatch(change('data.attributes.metadata.trip_duration', 365));
    }
    // if product is a TMK set the Trip Type to annual and region to worldwide
    if (!mta && TMK_PRODUCT.indexOf(productCode) !== -1) {
      dispatch(change('data.attributes.metadata.scheme_type', 'annual'));
      dispatch(change('data.attributes.metadata.region', 'worldwide'));
    }
    // Set default value for trip duration if there is only one option
    const maxTripDurations = _.keys(annualTripDurations);
    if (!mta && maxTripDurations.length === 1) {
      dispatch(change('data.attributes.metadata.max_trip_duration', maxTripDurations[0]));
    }

    const childrenMaxAge = _.get(currentProduct, 'data.attributes.metadata.children.max_age');
    if (childrenMaxAge) {
      dispatch(change('data.children_max_age', childrenMaxAge));
    }

    if (mta) {
      dispatch(change('data.mta', true));

    }
  }

  componentWillUpdate(nextProps, nextState) {
    const { change, dispatch } = this.props;
    const { activeTab } = this.state;
    if (nextState.activeTab !== activeTab) {
      dispatch(change('data.current_tab', nextState.activeTab));
    }
  }

  componentWillReceiveProps(nextProps) {
    const { formValues, dispatch, change, mta } = this.props;
    const nextSchemeType =_.get(nextProps.formValues, `${META}.scheme_type`, '');

    // Clear overrides if there is any change on metadata
    if (!mta &&
      LeisureTravelForm.propsChanged(`${META}`, formValues, nextProps)) {
      if (LeisureTravelForm.propsChanged(`${META}.overrides`, formValues, nextProps)) {
        return;
      } else {
        dispatch(change(`${META}.overrides`, {}));
      }
    }

    if (LeisureTravelForm.propsChanged(`${META}.start_date`, formValues, nextProps) &&
      (nextSchemeType === 'annual' || nextSchemeType === 'longstay') ) {
      dispatch(change('data.attributes.metadata.end_date', null));
      dispatch(change('data.attributes.ends_at', null));
    }

    // Remove max_trip_duration and region for trip type in case of change. Re-screen
    if (LeisureTravelForm.propsChanged(`${META}.scheme_type`, formValues, nextProps) &&
      (nextSchemeType === 'single')) {
      dispatch(change(`${META}.max_trip_duration`, ''));
      dispatch(change(`${META}.region`, ''));
    }

    // Remove Destination if user clicks on Annual Multi-trip
    if (LeisureTravelForm.propsChanged(`${META}.scheme_type`, formValues, nextProps) &&
      (nextSchemeType === 'annual')) {
      dispatch(change(`${META}.destinations`, []));
      dispatch(change(`${META}.end_date`, ''));
    }

    const oldCampaign = _.get(formValues, `${META}.campaign`);
    const nextCampaign = _.get(nextProps.formValues, `${META}.campaign`);

    if (nextCampaign !== null && oldCampaign !== nextCampaign) {
      this.props.dispatch(getCampaigns(nextCampaign, ''));
    }
  }

  static propsChanged(formField, formValues, nextProps) {
    const oldProps = _.get(formValues, formField, null);
    const newProps = _.get(nextProps.formValues, formField, null);

    return (oldProps !== 0 && newProps !== 0) && !_.isEqual(oldProps, newProps);
  }

  setStateOverride(override) {
    this.setState({
      override,
    });
  }

  handleTab(key) {
    const { screening } = this.props;

    if (screening.index > 0) {
      return toastr.error('Screening session active', 'Please finish your current screening session before continuing');
    }

    // Change the tab and invokes the handleSubmit and calculateQuotes to refresh automatically
    this.setState({
      activeTab: key,
    }, () => {
      if (this.state.activeTab === 5) {
        this.props.dispatch(this.props.handleSubmit(this.calculateQuotes));
      }
    });
  }

  openSpecificTab(key) {
    this.setState({
      activeTab: key,
    }, () => {
      // this.props.dispatch(clearQuotes())
    });
  }

  calculateQuotes(application) {
    const { change, dispatch, product, mta, policyId } = this.props;
    let applicationId = _.get(application, 'data.id', null);
    if (mta) {
      // calculate adjustment
      if (applicationId && applicationId === policyId) { // if the application id is the same as the policy id then this is not a saved mta quote
        delete application.data.id
        applicationId = null;
      }
      if (!applicationId) {
        Promise.resolve(dispatch(saveQuotesOnly(application, product.productId, FORM_NAME))).then((resp) => {
          dispatch(calculateAdjustment(policyId, resp, change, FORM_NAME))
        })
      } else {
        dispatch(calculateAdjustment(policyId, application, change, FORM_NAME))
      }
    } else {
      // Save the quote for the first time the QUOTES_TAB is accessed
      if (applicationId) {
        dispatch(getQuotes(product.productId, application, null, FORM_NAME))
      } else {
        Promise.resolve(dispatch(saveQuotesOnly(application, product.productId, FORM_NAME))).then((resp) => {
          dispatch(getQuotes(product.productId, resp, null, FORM_NAME))
        })
      }

    }

    this.setState({
      activeTab: 5,
    });
  }

  triggerValidation() {
    const activeTab = this.state.activeTab;

    this.setState({
      activeTab: activeTab < TOTAL_PANELS ? activeTab + 1 : TOTAL_PANELS,
    });
  }

  reCalculateForAdjustments() {
    // called as callback by PremiumAdjust component
    const { dispatch, handleSubmit } = this.props;
    dispatch(handleSubmit(this.getQuoteAdjustment));
  }

  getQuoteAdjustment(application) {
    // called as callback by PremiumAdjust component
    const { dispatch, product, change, mta, policyId } = this.props;
    if (!mta) {
      dispatch(getQuotes(product.productId, application));
    }
    if (mta) {
      dispatch(calculateAdjustment(policyId, application, change));
    }
  }


  render() {

    const {
      currentProduct,
      formValues,
      handleSubmit,
      product,
      resource,
      screening,
      error,
      mta,
      valid,
      campaign,
      policyId,
      auth,
      change
    } = this.props;
    const formErrors = error && error instanceof Object ? flatten(error) : false;
    const taxType = _.get(currentProduct, 'data.attributes.metadata.tax_type');
    const availableQuotes = _.sortBy(product.quotes, 'meta.premium.gross');
    const productIcon = _.get(currentProduct, 'data.attributes.icon', '');

    const campaignId = _.get(formValues, 'data.attributes.metadata.campaign');
    const campaignInfo = _.get(campaign, `resources[${campaignId}].data.attributes`);
    const selectedSchemes = _.get(formValues, 'data.relationships.schemes.data', []);
    const selectedScheme = selectedSchemes && selectedSchemes.length === 1 && selectedSchemes[0].id ? product.quotes[selectedSchemes[0].id] : false;
    const schemeId = selectedScheme && selectedScheme.meta && selectedScheme.meta.scheme.id;
    const travellerGroup = _.get(formValues, 'data.attributes.metadata.traveller_group');

    const displayBox = (isOwner() && resource.data.id && resource.data.attributes.broker_name) || (resource && resource.data.attributes.policy_number);
    const signPost = product.signpost;
    const isCalculating = product.isCalculating

    const titleSection = (name, index, showCalc) => {
      return (
        <div className='header-title'>
          {(showCalc && product.isCalculating) && (
            <div className='item-status'>
              <Icon name='refresh' spin/>
            </div>
          )}
          {(valid && showCalc && this.state.activePanel !== TOTAL_PANELS && !product.isCalculating) && (
            <div style={screening.index !== 0 ? { visibility: 'hidden' } : {}}>
              <Button
                className='quote-button'
                label={mta ? 'Calculate adjustment' : 'Get your quotes'}
                bsStyle='primary'
                size='xs'
                handleClick={handleSubmit(this.calculateQuotes)}
              />
            </div>
          )}
          <div className='item-number'>
            <span>{index}</span>
          </div>
          {name}
        </div>
      );
    };

    return (
      <div>
        <form>
          <div>
            { /* Broker Details Box */}
            {isOwner() && (

              <Box className='broker-details-box'>
                <Row>
                  <Field
                    name='data.relationships.broker.data.id'
                    label='Broker'
                    filters={[
                      { filter: 'filter[product]', value: currentProduct.data.id },
                    ]}
                    component={TravelFormSelectBroker}
                  />
                  {_.get(formValues, 'data.relationships.broker.data.id') && (
                    <Field
                      name='data.relationships.user.data.id'
                      filters={[
                        {
                          filter: 'filter[broker]',
                          value: _.get(formValues, 'data.relationships.broker.data.id')
                        },
                      ]}
                      labelKeys={[
                        'attributes.first_name',
                        'attributes.last_name',
                      ]}
                      component={TravelFormSelectUser}
                      label='User'
                    />
                  )}
                </Row>
              </Box>
            )}

            <div
              className={_.get(formValues, 'data.relationships.broker.data.id') && _.get(formValues, 'data.relationships.user.data.id') || isBroker() ? '' : 'hidden'}>
              <div className='quotes-tabs shadow-box'>
                <div className='quote-tabs-content'>
                  <Tabs activeKey={this.state.activeTab}
                        id='leisure-travel'
                        unmountOnExit
                        onSelect={this.handleTab}>
                    { /* Tab 01: Trip Details */}
                    <Tab eventKey={1} title='Trip&#160;Details'>
                      <div className='leisure-tab-content clearfix'>
                        <div className='form-horizontal'>

                          <TripDetailsTab
                            currentProduct={currentProduct}
                            mta={mta}
                          />
                          <div className="clearfix"/>

                        </div>
                        <Button className='pull-right btn-action' label='Travellers'
                                bsStyle='success'
                                handleClick={handleSubmit(() => this.openSpecificTab(2))}/>
                      </div>
                    </Tab>
                    { /* Tab 02: Travellers */}

                    <Tab eventKey={2} title='Travellers'>
                      <div className='leisure-tab-content clearfix'>
                        <TravellersTab currentProduct={currentProduct}/>
                        <div className="clearfix"/>
                        <div className='row'>

                          {!travellerGroup &&
                          <hr/>
                          }
                          <div className='actions'>
                            <Button className='btn-action-back pull-left'
                                    label='Trip Details' bsStyle='primary'
                                    handleClick={() => this.openSpecificTab(1)}/>

                            <Button className='pull-right btn-action' label='Medical'
                                    bsStyle='success'
                                    disabled={!travellerGroup}
                                    handleClick={handleSubmit(() => this.openSpecificTab(3))}/>

                          </div>
                        </div>
                      </div>
                    </Tab>
                    { /* Tab 03: Medical tab */}
                    <Tab eventKey={3} title='Medical'>
                      <div className='leisure-tab-content clearfix'>

                        <MedicalTab
                          currentProduct={currentProduct}
                          change={this.props.change}
                          dispatch={this.props.dispatch}
                        />

                        <div className="clearfix"/>
                        <div
                          style={screening.index !== 0 ? { visibility: 'hidden' } : {}}>
                          <Button
                            className='pull-left btn-action-back'
                            label='Travellers'
                            bsStyle='secondary'
                            disabled={(screening.index !== 0)}
                            handleClick={() => this.openSpecificTab(2)}
                          />
                          <Button
                            className='pull-right btn-action'
                            label='Options'
                            bsStyle='success'
                            disabled={(screening.index !== 0)}
                            handleClick={!mta ? handleSubmit(this.triggerValidation) : () => this.openSpecificTab(4)}
                          />
                        </div>
                      </div>
                    </Tab>
                    { /* Tab 04 Options */}

                    <Tab eventKey={4} title='Options'>
                      <div className='leisure-tab-content clearfix'>
                        <OptionsTab currentProduct={currentProduct}/>
                        <div className="clearfix"/>
                        <Button className='btn-action-back' label='Medical'
                                bsStyle='secondary'
                                handleClick={() => this.openSpecificTab(3)}/>
                        <Button
                          className='pull-right btn-action '
                          label='Quotes'
                          bsStyle='success'
                          handleClick={handleSubmit(this.calculateQuotes)}
                        />
                      </div>
                    </Tab>
                    { /* Tab 05 Quotes  */}
                    <Tab eventKey={5} title={mta ? 'Calculate Adjustment' : 'Quotes'}>
                      <div className='leisure-tab-content clearfix'>
                        <Panel header={titleSection(mta, (isOwner()), true)}
                               className='panel-transparent'
                               eventKey='5'>

                          <SignPostingText
                            isCalculating={isCalculating}
                            content={signPost}
                          />

                          {mta ? (
                            <div>
                              <FieldArray
                                name='data.attributes.metadata.endorsements'
                                formValues={_.get(formValues, 'data.attributes.metadata.endorsements', [])}
                                component={Endorsements}
                                fieldComponent={Field}
                              />
                              <AdjustmentForm
                                setStateOverride={this.setStateOverride}
                                overrideState={this.state.override}
                                product={product}
                                currentProduct={currentProduct}
                                image={productIcon}
                                change={this.props.change}
                                applicationId={resource.data.id}
                                selectedSchemes={_.get(formValues, 'data.relationships.schemes.data', [])}
                                handleSubmit={handleSubmit}
                              />
                            </div>
                          ) : (
                            <div>
                              {availableQuotes.length > 0 ? (
                                <AvailableQuotes
                                  product={product}
                                  currentProduct={currentProduct}
                                  availableQuotes={availableQuotes}
                                  selectedSchemes={_.get(formValues, 'data.relationships.schemes.data', [])}
                                  updateSelection={this.props.array}
                                  change={this.props.change}
                                  schemeType={_.get(formValues, 'data.attributes.metadata.scheme_type', 'annual')}
                                  handleSubmit={handleSubmit}
                                />
                              ) : (

                                <div>
                                  {
                                    product.isCalculating ? (
                                      <Loader/>
                                    ) : (
                                      <div>
                                        <h3>No products are available</h3>
                                        <p>There are no schemes available based on your
                                          quotation criteria.</p>
                                      </div>
                                    )
                                  }
                                </div>
                              )}
                              {!product.isCalculating &&
                              <SelectedQuotes
                                product={product}
                                currentProduct={currentProduct}
                                declaration={_.get(currentProduct, 'data.attributes.metadata.purchase_declaration', '')}
                                image={productIcon}
                                selectedSchemes={_.get(formValues, 'data.relationships.schemes.data', [])}
                                handleSubmit={handleSubmit}
                              />
                              }
                            </div>
                          )}
                        </Panel>

                        <div className="clearfix"/>
                        <Button className='btn-action-back' label={'Options'}
                                bsStyle='secondary'
                                handleClick={() => this.openSpecificTab(4)}/>
                        <Button
                          className='pull-right btn-action '
                          label='Payment'
                          bsStyle='success'
                          handleClick={() => this.openSpecificTab(6)}
                          disabled={schemeId ? false : true}
                        />
                      </div>
                    </Tab>
                    <Tab eventKey={6} title={'Payment'}>
                      <div className='leisure-tab-content clearfix'>
                        <div>
                          <PaymentTab
                            currentProduct={currentProduct}
                            product={product}
                            declaration={_.get(currentProduct, 'data.attributes.metadata.purchase_declaration', '')}
                            selectedSchemes={_.get(formValues, 'data.relationships.schemes.data', [])}
                            image={productIcon}
                            schemeId={schemeId}
                            saveApplication={handleSubmit}
                            openSpecificTab={this.openSpecificTab}
                          />
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
                <div className="clearfix"/>
                {displayBox &&
                <Box className='box--top-border'>
                  {resource && resource.data.attributes.policy_number && (
                    <div>
                      <h2>Policy number: {resource.data.attributes.policy_number}</h2>
                    </div>
                  )}
                  {(isOwner() && resource.data.id && resource.data.attributes.broker_name) && (
                    <div>
                      <h5>
                        <strong>Broker:</strong> {resource.data.attributes.broker_name} ({resource.data.attributes.user_name})
                      </h5>
                    </div>
                  )}
                </Box>
                }
                {(!error && Object.keys(formErrors).length === 0) && (
                  <div
                    className={mta ? 'hidden premium-adjust-box' : 'premium-adjust-box'}>
                    {(Object.keys(product.quotes).length > 0 && _.get(formValues, 'data.relationships.schemes.data', []).length === 1 &&
                      this.state.activeTab !== TOTAL_PANELS) && (
                      <PremiumAdjust
                        taxType={taxType}
                        baseName='data.attributes.metadata.overrides'
                        formName={FORM_NAME}
                        change={change}
                        setStateOverride={this.setStateOverride}
                        scheme={_.get(formValues, 'data.relationships.schemes.data')[0]}
                        open={!_.isEmpty(_.get(formValues, 'data.attributes.metadata.overrides', {}))}
                        reCalculateForAdjustments={this.reCalculateForAdjustments}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
        <div
          className={_.get(formValues, 'data.relationships.broker.data.id') && _.get(formValues, 'data.relationships.user.data.id') || isBroker() ? '' : 'hidden'}>
          <SideBar
            campaignInfo={campaignInfo}
            mta={mta}
            policyId={policyId}
            resource={resource}
            currentProduct={currentProduct}
          />
        </div>
      </div>
    );
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(LeisureTravelForm);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, props) => {
  const values = selector(state,
    'data.current_tab',
    'data.id',
    'data.attributes.metadata.adults[0].address',
    'data.relationships.schemes.data',
    'data.attributes.metadata.adults',
    'data.attributes.metadata.children',
    'data.attributes.metadata.medical_declaration_questions',
    'data.attributes.metadata.options',
    'data.attributes.metadata.scheme_type',
    'data.attributes.metadata.start_date',
    'data.attributes.metadata.end_date',
    'data.attributes.metadata.destinations',
    'data.attributes.metadata.region',
    'data.attributes.metadata.traveller_group',
    'data.attributes.metadata.endorsements',
    'data.attributes.metadata.subjectivities',
    'data.relationships.broker.data.id',
    'data.relationships.user.data.id',
    'data.attributes.metadata.residency',
    'data.attributes.metadata.trip_durations_annual',
    'data.attributes.metadata.overrides',
    'data.attributes.metadata.sending_organisation',
    'data.attributes.metadata.campaign',
  );
  return {
    initialValues: props.resource,
    formValues: values,
    campaign: state.campaign,
    auth: state.auth,
  };
};
export default connect(mapStateToProps)(form);
