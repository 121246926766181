export const IS_SUBMITTING = 'socrates-online/document/IS_SUBMITTING'
export const SET_DOCUMENT = 'socrates-online/document/SET_DOCUMENT'
export const CLEAR_DOCUMENT = 'socrates-online/document/CLEAR_DOCUMENT'

export const initialState = {
  isSubmitting: false,
  resources: {}
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.status
      }
    case SET_DOCUMENT:
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.data.data.id]: {
            ...action.data
          }
        }
      }
    case CLEAR_DOCUMENT:
      return initialState
    default:
      return state
  }
}
