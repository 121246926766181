import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { createOrganisation, updateOrganisation } from '../redux/organisationActions'
import OrganisationForm from '../components/OrganisationForm/OrganisationForm'

export default class OrganisationFormContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    organisation: PropTypes.object.isRequired,
    resource: PropTypes.object,
  };

  handleSubmit(resource) {
    if (resource.data.id) {
      return this.props.dispatch(updateOrganisation(resource))
    }

    return this.props.dispatch(createOrganisation(resource))
  }

  render() {
    return (
        <OrganisationForm
            {...this.props}
            onSubmit={::this.handleSubmit}
        />
    )
  }
}
