import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {reduxForm, Field, formValueSelector} from 'redux-form'
import {Alert, FormGroup, Col, ControlLabel, Panel} from 'react-bootstrap'
import getIncludedResource from '../../../../helpers/getIncludedResource'
import {
  Button,
  Modal,
  NameFields,
  HorizontalFormControl,
} from '../../../../common/components'
import * as _ from 'lodash'
import UploadCropComponent from '../../../../common/components/Upload/UploadCrop'
import {triggerFileUpload} from '../../../../common/components/Upload/UploadRedux'
import defaultAvatar from '../../../../images/avatars/user_default.svg'
import InfoLabel from '../../../../common/components/InfoLabel/InfoLabel'
import {hasPermission} from '../../../auth/redux/authActions'
import './SettingsForm.scss'

const FORM_NAME = 'mySettings'

const validate = values => {
  const errors = {}

  if (!_.get(values, 'data.attributes.email')) {
    _.set(errors, 'data.attributes.email', 'Required')
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,40}$/i.test(_.get(values, 'data.attributes.email'))) {
    _.set(errors, 'data.attributes.email', 'Invalid email address')
  }

  if (_.get(values, 'data.attributes.password') && _.get(values, 'data.attributes.password.length', '') < 8) {
    _.set(errors, 'data.attributes.password', 'Must be 8 characters or more')
  }

  if (_.get(values, 'data.attributes.password') && !/[a-z]/.test(_.get(values, 'data.attributes.password'))) {
    _.set(errors, 'data.attributes.password', 'Must contain at least one lowercase character')
  }

  if (_.get(values, 'data.attributes.password') && !/[A-Z]/.test(_.get(values, 'data.attributes.password'))) {
    _.set(errors, 'data.attributes.password', 'Must contain at least one uppercase character')
  }

  if (_.get(values, 'data.attributes.password') && !/[0-9]/.test(_.get(values, 'data.attributes.password'))) {
    _.set(errors, 'data.attributes.password', 'Must contain at least one number')
  }

  if (_.get(values, 'data.attributes.password') && !/[ !@#$£%€^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(_.get(values, 'data.attributes.password'))) {
    _.set(errors, 'data.attributes.password', 'Must contain at least one special character')
  }

  if (_.get(values, 'data.attributes.password') && _.get(values, 'data.attributes.password_confirmation') && _.get(values, 'data.attributes.password_confirmation') !== _.get(values, 'data.attributes.password')) {
    _.set(errors, 'data.attributes.password_confirmation', 'Confirmation does not match password')
  }

  return errors
}

export class MySettingsForm extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired
  };

  handleUpload() {
    const {dispatch} = this.props

    dispatch(triggerFileUpload())
  }

  handleUploadComplete(data) {
    const {dispatch, change} = this.props

    dispatch(change('data.relationships.avatar.data', data.data))
  }

  handleOnHide() {
    const {dispatch, reset} = this.props

    dispatch(reset())
  }

  render() {
    const {error, resource, formValues, submitting, handleSubmit} = this.props
    const avatar = getIncludedResource(resource.data, resource.included, 'avatar')

    let avatarSrc = ''

    if (_.get(formValues, 'data.relationships.avatar.data.attributes')) {
      avatarSrc = formValues.data.relationships.avatar.data.attributes.url
    } else if (avatar.data.attributes) {
      avatarSrc = avatar.data.attributes.url
    } else {
      avatarSrc = defaultAvatar
    }

    const modalFooter = (
      <div>
        <Button
          type="submit"
          bsStyle="success settings-button"
          className="pull-right"
          disabled={submitting}>
          {submitting ? <i className="fa fa-cog fa-spin"/> : ('')} Save Settings
        </Button>
        <Button
          type="button"
          bsStyle="success settings-button"
          className="pull-right pad-right"
          disabled={submitting}
          handleClick={::this.handleUpload}>
          {submitting ? <i className="fa fa-cog fa-spin"/> : <i className="fa fa-user"/>} Upload Avatar
        </Button>
      </div>
    )

    return (
      <Modal {...this.props} name="settings" title="Personal Settings" close={true} onHide={::this.handleOnHide}
             footer={modalFooter} handleSubmit={handleSubmit}>
        <div className="row">
          <div className="col-xs-12">
            <div className="form-horizontal">

              {error && (
                <Alert bsStyle="danger">
                  <p>{error}</p>
                </Alert>
              )}

              {hasPermission('user.edit_own') ? (
                <div>
                  <NameFields
                    baseName="data.attributes"
                    label="Name"
                    fieldComponent={Field}
                    disabled={submitting}
                  />
                  <Field
                    name="data.attributes.email"
                    type="email"
                    label="Email"
                    placeholder="Email"
                    component={HorizontalFormControl}
                    disabled={submitting}
                  />
                </div>
              ) : (
                <div>
                  <InfoLabel
                    label="Name"
                    value={resource.data.attributes.full_name}
                  />
                  <InfoLabel
                    label="Email"
                    value={resource.data.attributes.email}
                  />
                </div>
              )}

              <FormGroup>
                <Col componentClass={ControlLabel} sm={2}>Avatar:</Col>
                <Col sm={10}>
                  {/* This should get an avatar component */}
                  <img src={avatarSrc} className="avatar img-circle" style={{ maxWidth: 64, maxHeight: 64 }}/>
                </Col>
              </FormGroup>

              <div className="hr transparent"/>

              <h4>Update your password (leave blank unless you intend to change)</h4>
              <Alert bsStyle="info">
                 Your password must be a minimum of 8 characters long, with at least one lowercase, one uppercase letter, one special character and one digit. These are various common words also excluded from being used as part of your password.
              </Alert>
              <Field
                name="data.attributes.current_password"
                type="password"
                label="Current Password"
                labelSize={4}
                placeholder="Current Password"
                disabled={submitting}
                component={HorizontalFormControl}
              />
              <Field
                name="data.attributes.password"
                type="password"
                label="Password"
                placeholder="New Password"
                labelSize={4}
                component={HorizontalFormControl}
                disabled={submitting}
              />
              <Field
                name="data.attributes.password_confirmation"
                type="password"
                label="Password"
                placeholder="Re-enter New Password"
                labelSize={4}
                component={HorizontalFormControl}
                disabled={submitting}
              />
            </div>
          </div>
        </div>

        <UploadCropComponent onComplete={::this.handleUploadComplete} width={200} height={200} bsSize="sm"
                             circle={true}/>
      </Modal>
    )
  }
}

const form = reduxForm({
  form: FORM_NAME,
  validate
})(MySettingsForm)

const selector = formValueSelector(FORM_NAME)
export default connect(
  (state, props) => {
    const values = selector(state,
      'data.id',
      'data.relationships.avatar.data',
    )
    return {
      initialValues: props.resource,
      formValues: values,
      files: state.upload.files,
    }
  },
)(form)
