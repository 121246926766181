import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getRole } from '../redux/roleActions'
import { Button, Loader } from '../../../common/components'
import InternalContainer from '../../layout/containers/Internal'
import RoleFormContainer from './RoleFormContainer'

export class RoleEditContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    role: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired
  };

  componentWillMount() {
    this.props.dispatch(getRole(this.props.params.id))
  }

  render() {
    const resource = this.props.role.resources[this.props.params.id]

    const buttons = (
        <Button
            bsStyle="default"
            label="back"
            isBackButton={true}
            leftIcon="caret-left"
        />
    )

    return (
        <InternalContainer title="Role" buttons={buttons}>
          {resource ? (
                  <RoleFormContainer {...this.props} resource={resource}/>
              ) : (
                  <Loader />
              )}
        </InternalContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    role: state.role
  }
}

export default connect(mapStateToProps)(RoleEditContainer)
