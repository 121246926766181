import React, { Component } from 'react'
import { push } from 'react-router-redux'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { ControlLabel, FormControl } from 'react-bootstrap'
import { FormattedDate, FormattedTime } from 'react-intl'
import { Box, Button, DataTable } from '../../../../common/components'
import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions'
import './styles.scss'


/********************
 Campaign DataTable
 *********************/

export default class Campaigns extends Component {
  constructor(props) {
    super(props)
    this.handleSearchUpdate = this.handleSearchUpdate.bind(this)
  }

  formatDate(cell) {
    if (cell == null) {
      return ''
    }
    return (
      <span><FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/></span>
    )
  }

  handleRowClick(row) {
    this.props.dispatch(push('/admin/campaigns/' + row.id))
  }

  handleFilterUpdate(event) {
    this.setState({ selection: event.target.value })
    switch(event.target.value) {
      case 'partners':
        this.setState({
          partner: true,
          personnel: false
        })
        break
      case 'keyPersonnel':
        this.setState({
          personnel: true,
          partner: false
        })
        break
      default:
        this.props.dispatch(applyFilter('content', 'filter[template.type]', event.target.value))
        this.setState({
          partner: false,
          personnel: false
        })
    }
  }

  handleRender() {
    return(
      <div>
        <Box>
          <div className="row no-bottom-margin">
            <div className="col-sm-5">
              <ControlLabel className="filter-label" >Search</ControlLabel>
              <FormControl onChange={this.handleSearchUpdate} disabled/>
            </div>
            <div className="col-sm-3">
              <ControlLabel className="filter-label">Type</ControlLabel>
            </div>
          </div>
        </Box>
      </div>
    )
  }

  handleSearchUpdate(event) {
    this.props.dispatch(applyFilter('content', 'filter[name]', event.target.value))
  }

  handleUserStatusFilterUpdate(event) {
    this.props.dispatch(applyFilter('content', 'filter[status]', event.target.value))
  }

  render() {
    return (
      <div>
        <Box>
          <div className="row">
            <div className="col-sm-4">
              <ControlLabel  className="filter-label">Search</ControlLabel>
              <FormControl onChange={this.handleSearchUpdate }/>
            </div>

            <div className="col-sm-2">
              <ControlLabel className="filter-label">Status</ControlLabel>
              <FormControl
                componentClass="select"
                type="select"
                onChange={::this.handleUserStatusFilterUpdate}
                placeholder="select"
              >
                <option value="">Select...</option>
                <option value="pending">Pending</option>
                <option value="active">Active</option>
                <option value="ended">Ended</option>
              </FormControl>
            </div>
          </div>

          <DataTable
            source="/campaigns"
            name="content"
            showTotal={true}
            onRowSelect={this.handleRowClick.bind(this)}
            /*  autoFilters={[
                { type: 'filter[template.type]', value: 'page,article' }
              ]}*/
          >

            <TableHeaderColumn dataField="id" isKey={true} hidden={true}>ID</TableHeaderColumn>
            <TableHeaderColumn dataField="name" dataSort={true} width={'250px'}>Name</TableHeaderColumn>
            <TableHeaderColumn dataField="code" dataSort={true} width={'180px'}>Code</TableHeaderColumn>
            <TableHeaderColumn dataField="campaign_type" dataSort={true} width={'180px'}>Type</TableHeaderColumn>
            <TableHeaderColumn dataField="status" dataSort={false} width={'180px'}>
              <span style={{ paddingLeft: 0 }}>Status</span></TableHeaderColumn>
            <TableHeaderColumn dataField="from_date" dataSort={true} dataFormat={this.formatDate}
                               width={'150px'}><span style={{ paddingLeft: 10 }}>Start Date</span></TableHeaderColumn>
            <TableHeaderColumn dataField="to_date" dataSort={true} dataFormat={this.formatDate}
                               width={'100px'}>End Date</TableHeaderColumn>
          </DataTable>

          <div className="row actions">
            <Button
              link={true}
              to="/admin/campaign/new"
              className="pull-right"
              bsStyle="primary"
              label="new campaign"
            />
          </div>
        </Box>
      </div>
    )
  }
}
