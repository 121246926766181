import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { fetchReminders, fetchStats, fetchBrokerStats  } from '../../../../dashboard/redux/dashboardActions'
import { hasPermission } from '../../../../auth/redux/authActions'
import _ from 'lodash'
import './Notifications.scss'

class Notifications extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    dashboard: PropTypes.object.isRequired,
  };

  componentWillMount() {
    this.props.dispatch(fetchReminders())
    this.props.dispatch(fetchStats())
    this.props.dispatch(fetchBrokerStats())
  }

  getNoteAttachment(index) {
    return _.get(this.props.dashboard.reminders.included[index], 'attributes.url', '')
  }

  render() {
    const { dashboard } = this.props

    return (
      <div>
        <table className="table reminders-list">
          <thead />
          <tbody>
          {_.get(dashboard.stats, 'meta.referrals_requiring_approval', 0) != 0 && (
            <tr>
              <td colSpan="2">You have&nbsp;<span
                className="text-secondary">{_.get(dashboard.stats, 'meta.referrals_requiring_approval')}</span>
                &nbsp;referrals requiring approval
              </td>
              <td className="reminder-action"><Link to="/policies/referrals">View referrals</Link></td>
            </tr>
          )}
          {_.get(dashboard.stats, 'meta.mtas_requiring_approval', 0) !== 0 && (
            <tr>
              <td colSpan="2">You have&nbsp;<span
                className="text-secondary">{_.get(dashboard.stats, 'meta.mtas_requiring_approval')}</span>
                &nbsp;mid term adjustments requiring approval
              </td>
              <td className="reminder-action"><Link to="/policies/mid-term-adjustments?status=quote">View MTAs</Link></td>
            </tr>
          )}
          {_.get(dashboard.stats, 'meta.renewals_awaiting_conversion', 0) != 0 && (
            <tr>
              <td colSpan="2">There are&nbsp;<span
                className="text-secondary">{_.get(dashboard.stats, 'meta.renewals_awaiting_conversion')}</span>
                &nbsp;renewals awaiting conversion
              </td>
              <td className="reminder-action"><Link to="/policies/renewals">View renewals</Link></td>
            </tr>
          )}

          {(hasPermission('system.handle_challenge_requests') && _.get(dashboard.stats, 'meta.challenge_alpha_requests', 0) != 0) && (
            <tr>
              <td colSpan="2">You have&nbsp;<span
                className="text-secondary">{_.get(dashboard.stats, 'meta.challenge_alpha_requests')}</span>
                &nbsp;challenge alpha requests
              </td>
              <td className="reminder-action"><Link to="/admin/challenges">View challenges</Link></td>
            </tr>
          )}

          {hasPermission('broker.approve') && _.get(dashboard.stats, 'meta.brokers_awaiting_approval', 0) != 0 && (
            <tr>
              <td colSpan="2">You have&nbsp;<span
                className="text-secondary">{_.get(dashboard.stats, 'meta.brokers_awaiting_approval')}</span>
                &nbsp;brokers awaiting approval
              </td>
              <td className="reminder-action"><Link to="/brokers?status=applied">View Brokers</Link></td>
            </tr>
          )}

          {hasPermission('user.edit') && _.get(dashboard.stats, 'meta.users_awaiting_activation', 0) != 0 && (
            <tr>
              <td colSpan="2">You have&nbsp;<span
                className="text-secondary">{_.get(dashboard.stats, 'meta.users_awaiting_activation')}</span>&nbsp;users
                yet to log in
              </td>
              <td className="reminder-action"><Link to="/admin/users?status=pending">View Users</Link></td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    dashboard: state.dashboard
  }
}

export default connect(mapStateToProps)(Notifications)
