import './style.scss';
import React from 'react';
import Markdown from 'react-markdown';
import FaqsForm from '../FaqsForm/index';
import displayName from '../../../../../helpers/displayName';
import Icon from '../../../../../common/components/Icon/Icon';

class CollapsibleField extends React.PureComponent {
  state = {
    showField: false,
    enableEditing: false,
  };

  componentWillMount() {
    const { faq } = this.props;

    if (!faq.question) {
      setTimeout(() => {
        this.setState({
          showField: true,
          enableEditing: true,
        });
      }, 1530);
    }
  }

  renderQuestions = () => {
    const { faq, onSubmit, submitting, categories } = this.props;
    const { enableEditing } = this.state;
    const hasAnswer = faq.answer.length > 2;

    return (
      <div
        className="animated fadeInDown help-centre-content-box"
        onClick={this._handleEdit}
      >
        {enableEditing ? (
          <FaqsForm
            faq={faq}
            onSubmit={onSubmit}
            submitting={submitting}
            categories={categories}
            onCancel={this._handleCancelEditing}
          />
        ) : (
          <div>
            {hasAnswer ? <Markdown source={faq.answer} /> : 'No answer provided.'}
          </div>
        )}
      </div>
    );
  };

  render() {
    const { faq, isSystemAdmin } = this.props;
    const { showField } = this.state;

    if (!isSystemAdmin && !faq.display) {
      return null;
    }

    return (
      <div className={`help-centre-question-container ${!faq.display ? 'disabled' : ''}`}>
        <label
          onClick={this._toggleShowField}
          className={showField ? 'expanded-title' : 'collapsed-title'}
        >
          {faq.question ? displayName(`${faq.question}`) : '...'}{' '}
          {!faq.display && ' (Disabled)'}
          <div className="pull-right">
            <Icon name={showField ? 'minus-circle' : 'plus-circle'} />
          </div>
        </label>
        {showField && this.renderQuestions()}
      </div>
    );
  }

  _toggleShowField = () => {
    this.setState((prevState) => ({
      showField: !prevState.showField,
      enableEditing: false,
    }));
  };

  _handleEdit = () => {
    const { isSystemAdmin } = this.props;
    const { enableEditing } = this.state;

    if (isSystemAdmin && !enableEditing) {
      this.setState({
        enableEditing: true,
      });
    }
  };

  _handleCancelEditing = () => {
    this.setState({
      enableEditing: false,
    });
  };
}

export default CollapsibleField;
