import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ProductDocumentPacksForm from '../components/ProductDocumentPacksForm'
import getIncludedResource from '../../../helpers/getIncludedResource'
import { updateProductDocumentPacks } from '../redux/schemeActions'

class ProductDocumentPacksFormContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired
  };

  handleSubmit(packs) {
    return this.props.dispatch(updateProductDocumentPacks(packs, this.props.product.data.id))
  }

  render() {
    const { product } = this.props
    const documentPacks = getIncludedResource(product.data, product.included, 'document_packs')

    return (
      <ProductDocumentPacksForm
        product={product}
        documentPacks={{ data: (documentPacks || []) }}
        onSubmit={::this.handleSubmit}
      />
    )
  }
}

export default connect()(ProductDocumentPacksFormContainer)
