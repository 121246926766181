import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  DataTable,
  ButtonBar,
  Button,
  SelectBroker,
  SelectAccount
} from '../../../../common/components'
import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { FormControl, ControlLabel } from 'react-bootstrap'
import { FormattedDate } from 'react-intl'
import { openModal } from '../../../../common/components/Modal/ModalRedux'
import ReceiptModal from '../ReceiptModal'
import './styles.scss'

export default class AccountPaymentList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props)

    this.state = {
      account: '',
      broker: '',
      matched: 'matched',
      receipt: {}
    }
  }

  formatDate(cell) {
    return (
        <span><FormattedDate value={new Date(cell)}/></span>
    )
  }

  handleRowClick(row) {
    this.setState({ receipt: row })
    this.props.dispatch(openModal(ReceiptModal.MODAL_IDENTIFIER))
  }

  handleSearchUpdate(event) {
    this.props.dispatch(applyFilter('payments-and-receipts', 'filter[reference]', event.target.value))
  }

  handleAccountUpdate(value) {
    this.setState({ account: value })
    this.props.dispatch(applyFilter('payments-and-receipts', 'filter[account]', value))
  }

  handleBrokerUpdate(value) {
    this.setState({ broker: value })
    this.props.dispatch(applyFilter('payments-and-receipts', 'filter[broker]', value))
  }

  handleMatchedUpdate(event) {
    this.props.dispatch(applyFilter('payments-and-receipts', 'filter[matched]', event.target.value))
  }

  render() {
    return (
      <Box>
        <h2 className="resource-name">Payments & Receipts</h2>
        <div className="row table-filters">
          <div className="col-sm-2">
            <ControlLabel className="filter-label">Reference</ControlLabel>
            <FormControl onChange={ ::this.handleSearchUpdate } />
          </div>
          <div className="col-sm-4">
            <ControlLabel className="filter-label">Account</ControlLabel>
            <SelectAccount
              labelKeys={['attributes.name']}
              filters={[{ filter: 'filter[type]', value: 'debtor' }]}
              input={{
                onChange: ::this.handleAccountUpdate,
                value: this.state.account
              }}
            />
          </div>
          <div className="col-sm-4">
            <ControlLabel className="filter-label">Sub Account (Broker)</ControlLabel>
            <SelectBroker
              labelKeys={['attributes.name']}
              input={{
                onChange: ::this.handleBrokerUpdate,
                value: this.state.broker
              }}
            />
          </div>
          <div className="col-sm-2">
            <ControlLabel className="filter-label">Matched</ControlLabel>
            <FormControl
              componentClass="select"
              type="select"
              onChange={ ::this.handleMatchedUpdate }
            >
              <option value="false">Unmatched</option>
              <option value="true">Matched</option>
              <option value="all">All</option>
            </FormControl>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <DataTable
                source="/accounts/payments-and-receipts"
                autoFilter="debtor"
                autoFilterType="filter[account_type]"
                name="payments-and-receipts"
                showTotal={true}
                onRowSelect={this.handleRowClick.bind(this)}>

              <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
                ID
              </TableHeaderColumn>

              <TableHeaderColumn dataField="date" dataFormat={this.formatDate} width={'100px'}>
                Date
              </TableHeaderColumn>

              <TableHeaderColumn dataField="accounting_reference" width={'120px'} dataSort={true}>
                Reference
              </TableHeaderColumn>

              <TableHeaderColumn dataField="account_name" width={'250px'}>
                Account
              </TableHeaderColumn>

              <TableHeaderColumn dataField="broker_name" width={'250px'}>
                Broker/Branch
              </TableHeaderColumn>

              <TableHeaderColumn dataField="description" width={'150px'} dataSort={true}>
                Description
              </TableHeaderColumn>

              <TableHeaderColumn dataField="currency" width={'50px'}>
                Currency
              </TableHeaderColumn>

              <TableHeaderColumn dataField="formatted_amount" dataAlign="right" width={'100px'}>
                Amount
              </TableHeaderColumn>

              <TableHeaderColumn dataField="matched" dataAlign="right" width={'80px'}>
                Matched
              </TableHeaderColumn>

            </DataTable>
          </div>
          <ReceiptModal dispatch={this.props.dispatch} receipt={this.state.receipt}/>
          <ButtonBar>
            <Button
                className="pull-right"
                link={true}
                to="/accounts/payments-and-receipts/new"
                label="New Payment/Receipt"
                bsStyle="primary"
            />
          </ButtonBar>
        </div>
      </Box>
    )
  }
}
