export const INIT_MODAL = 'socrates-online/modal/INIT_MODAL';
export const OPEN_MODAL = 'socrates-online/modal/OPEN_MODAL';
export const CLOSE_MODAL = 'socrates-online/modal/CLOSE_MODAL';

const initialState = {
  modals: {}
};

const initialModalState = {
  visible: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case INIT_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          [action.modal]: {
            ...initialModalState
          }
        }
      };
    case OPEN_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          [action.modal]: {
            visible: true
          }
        }
      };
    case CLOSE_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          [action.modal]: {
            visible: false
          }
        }
      };
    default:
      return state;
  }
}

export function initModal(modal) {
  return {
    type: INIT_MODAL,
    modal: modal
  }
}

export function openModal(modal) {
  return {
    type: OPEN_MODAL,
    modal: modal
  };
}

export function closeModal(modal) {
  return {
    type: CLOSE_MODAL,
    modal: modal
  };
}
