import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Tabs } from 'react-bootstrap'
import { Button, Loader } from '../../../common/components'
import InternalContainer from '../../layout/containers/Internal'
import LeisureTravelFormContainer from './LeisureTravelFormContainer'
import ProCycleFormContainer from './ProCycleFormContainer'
import {
  getProduct,
  getRegions,
  clearData,
  setPolicyForAdjustment
} from '../redux/productActions'
import { getPolicy } from '../../policy/redux/policyActions'
import { get } from 'lodash'

export class ProductMTAContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    policy: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context)

    this.state = {
      activeTab: 1
    }
  }

  componentWillMount() {
    const { dispatch, params } = this.props
    if (params.applicationId) {
      dispatch(getPolicy(params.applicationId, false, ['customer', 'broker', 'user']))
    }

    dispatch(setPolicyForAdjustment(params.applicationId))
    dispatch(getRegions())
    dispatch(getProduct(params.id))
  }

  /**
   * Clean out the data when the component unmounts
   */
  componentWillUnmount() {
    this.props.dispatch(clearData())
  }

  handleTab(key) {
    this.setState({ activeTab: key })
  }

  render() {
    const { product, policy, params } = this.props
    const resource = product.resources[params.id]
    const application = policy.resources[params.applicationId]
    const title = resource ? resource.data.attributes.name : ''
    const buttons = (
      <Button
        bsStyle="default"
        label="Back"
        isBackButton={true}
        leftIcon="caret-left"
      />
    )

    const renewal = get(application, 'data.attributes.quote_type', 'new business') === 'renewal'

    // Clear any overrides before editing
    // _.set(application, 'data.attributes.metadata.overrides', {})

    return (
      <InternalContainer title={title} buttons={buttons}>
        {(resource && (!params.applicationId || application)) ? (
          <Tabs activeKey={this.state.activeTab}
                id="broker-tabs"
                unmountOnExit={true}
                onSelect={::this.handleTab}>
            {(() => {
              switch (true) {
                case resource.data.attributes.product_code === 'PROC':
                  return (
                    <ProCycleFormContainer
                      {...this.props}
                      mta={true}
                      application={application}
                      currentProduct={resource}
                    />
                  )
                default:
                  return (
                    <LeisureTravelFormContainer
                      {...this.props}
                      mta={true}
                      application={application}
                      currentProduct={resource}
                      renewal={renewal}
                      policyId={params.applicationId}
                    />
                  )
              }
            })()}
          </Tabs>
        ) : (
          <Loader/>
        )}
      </InternalContainer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    product: state.product,
    policy: state.policy
  }
}

export default connect(mapStateToProps)(ProductMTAContainer)

