import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { RadioGroup } from '../../../../common/components/ReduxFormField'
import { Button } from '../../../../common/components'

const FORM_NAME = 'productRulesForm'

class ProductRulesForm extends Component {
  static propTypes = {
    product: PropTypes.object.isRequired
  }

  render() {
    const { product, submitting, handleSubmit } = this.props

    return (
        <div>
          <form onSubmit={handleSubmit}>
            <h2 className="resource-name">{product.data.attributes.name}</h2>

            <div className="row">
              <div className="col-sm-12">
                <label className="control-label">Apply network commission where applicable?</label>
                <Field
                    name="data.attributes.metadata.apply_network_commission"
                    inline={true}
                    options={[
                      { label: 'Yes', value: 'true' },
                      { label: 'No', value: 'false' },
                    ]}
                    component={RadioGroup}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <label className="control-label">Apply broker commission where applicable?</label>
                <Field
                    name="data.attributes.metadata.apply_broker_commission"
                    inline={true}
                    options={[
                      { label: 'Yes', value: 'true' },
                      { label: 'No', value: 'false' },
                    ]}
                    component={RadioGroup}
                />
              </div>
            </div>

            <div className="row actions">
              <div className="col-xs-12">
                <Button
                    type="submit"
                    label="Save Changes"
                    bsStyle="primary"
                    className="pull-right"
                    isLoading={submitting}
                />
              </div>
            </div>
          </form>
        </div>
    )
  }
}

const form = reduxForm({ form: FORM_NAME })(ProductRulesForm)
const mapStateToProps = (state, props) => {
  return {
    initialValues: props.product
  }
}

export default connect(mapStateToProps)(form)
