import React from 'react'
import PropTypes from 'prop-types'
import './icons.scss'

const Icon = ({ name, size, spin, align, circle, customIconSet }) => {
  const iconClass = 'fa fa-' + name +
      (size ? ' ' + size : '') +
      (spin ? (name === 'spinner' ? ' fa-pulse fa-fw' : ' fa-spin fa-fw') : '') +
      (align ? ' ' + align : '')

  if (customIconSet) {
    return (
      <span className='icon'>
        <i className={name}/>
      </span>
      )
  }
  return (
      <span className={'icon ' + (circle ? 'icon--white-circle' : '') }>
        <i className={iconClass}/>
      </span>
  )
}

Icon.propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
  spin: PropTypes.bool,
  align: PropTypes.string
}

export default Icon
