import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import InternalContainer from '../../layout/containers/Internal'
import PaymentReceiptsForm from '../components/PaymentReceiptsForm'

class AccountPaymentReceiptsContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context)
  }

  render() {
    return (
        <InternalContainer title="Payments and Receipts">
          <PaymentReceiptsForm {...this.props}/>
        </InternalContainer>
    )
  }
}

function mapStateToProps(state) {
  return {}
}

export default connect(mapStateToProps)(AccountPaymentReceiptsContainer)
