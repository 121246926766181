import React, {Component} from 'react'
import {Field, formValueSelector, reduxForm} from 'redux-form'
import {connect} from 'react-redux'
import {Sticky, StickyContainer} from 'react-sticky'
import {hasPermission, isOwner} from '../../../../../auth/redux/authActions'
import {FormattedDate} from 'react-intl'
import flatten from 'flat'
import displayName from '../../../../../../helpers/displayName'
import pluralize from 'pluralize'
import QuoteActions from '../../../QuoteActions'
import CommissionAdjust from '../../../CommissionAdjust'
import { calculateAdjustment, getQuotes, saveQuotes } from '../../../../redux/productActions';
import * as _ from 'lodash'
import { Button, SelectCampaign } from '../../../../../../common/components';
import displayCurrency from '../../../../../../helpers/displayCurrency'
import PremiumBreakdown from '../../../../../product/components/PremiumBreakdown'
import BenefitsModal from '../../../BenefitsModal/BenefitsModal';




const TOTAL_PANELS = 5

export class Sidebar extends Component {
  constructor(props) {
    super(props)
    this.calculateQuotes = this.calculateQuotes.bind(this)
  }

  calculateQuotes(application) {
    const {change, dispatch, product, mta, policyId} = this.props
    if (mta) {
      // calculate adjustment
      dispatch(calculateAdjustment(policyId, application, change))
    } else {
      // Get quotes
      // Reset any previously selected schemes
      dispatch(change('data.relationships.schemes.data', []))
      _.set(application, 'data.relationships.schemes.data ', [])

      // Calculate
      dispatch(getQuotes(product.productId, application))
    }

    this.setState({
      activeTab: TOTAL_PANELS
    })
  }

  saveQuotes(application) {
    return this.props.dispatch(saveQuotes(application, this.props.currentProduct))
  }

  renderSchemeSummary(selection, i) {
    const { product, mta, showBlocker } = this.props;
    const originalFee = _.get(selection, 'meta.premium.fees', 0);
    if (mta) {
      return;
    }
    if (product.isSaving) {
      return;
    }
    if (showBlocker) {
      return;
    }
    return (
      <div className="col-xs-12 schemeSelected" key={i}>
        <div className="row">
          <div className="col-md-12">
            Chosen Scheme{' '}
            <BenefitsModal
              scheme={selection.meta.scheme}
              benefits={selection.meta.benefits}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">{selection.meta.scheme.name} &nbsp;</div>
        </div>
        <div className="row">
          <div className="col-md-8">Base Premium</div>
          <div className="col-md-4 sidebar-summary-premium">
            {' '}
            {displayCurrency(_.get(selection.meta.premium, 'principal', ''),selection.meta.premium.currency)}
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            IPT ({_.get(selection, 'meta.rates.tax_rate', 0)}%)
          </div>
          <div className="col-md-4 sidebar-summary-premium">
            {' '}
            {displayCurrency(_.get(selection.meta.premium, 'tax', ''), selection.meta.premium.currency)}{' '}
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">Admin Fees</div>
          <div className="col-md-4 sidebar-summary-premium">
            {' '}
            {displayCurrency(originalFee, selection.meta.premium.currency)}{' '}
          </div>
          {/*<div className='col-md-8'>Override Fees</div>*/}
          {/*<div className='col-md-4 sidebar-summary-premium'> {displayCurrency(overridefee)} </div>*/}
        </div>

        <div className="row">
          <div className="col-md-8">Total Payable</div>
          <div className="col-md-4 sidebar-summary-premium">
            <PremiumBreakdown
              premium={selection.meta}
              className="small-premium"
              showIpt={false}
              taxType={_.get(product, 'data.attributes.metadata.tax_type')}
            />
          </div>
        </div>

        {_.get(selection.meta.premium, 'discount', false) ? (
          <div className="row">
            <div className="col-md-8">Discount</div>
            <div className="col-md-4 sidebar-summary-premium">
              (
              {displayCurrency(
                _.get(selection.meta.premium_before_adjustments, 'gross', 0) -
                _.get(selection.meta.premium, 'gross', 0),
              )}
              )
            </div>
          </div>
        ) : null}
      </div>
    );
  }


  render() {
    const {currentProduct, formValues, handleSubmit, product, resource, error, mta, submitFailed, screening, campaignInfo} = this.props
    const productIcon = _.get(currentProduct, 'data.attributes.icon', '')
    const tripType = () => _.get(formValues, 'data.attributes.metadata.scheme_type')
    const formErrors = error && error instanceof Object ? flatten(error) : false
    const displayedErrors = []
    let selectedOptions = 0
    const productName = _.get(currentProduct, 'data.attributes.name');

    const showErrors = mta && formErrors ? true : (submitFailed && error && formErrors);
    let scheme = _.get(formValues, 'data.relationships.schemes.data', []);

    return (
      <div>
        <StickyContainer className='calculate-quote-sidebar'>
          <div className="calculate-quote-sidebar__heading">
            Quote Summary
          </div>


          <Sticky>
            <div className='row sticky-inner-container'>
              {resource.data.attributes.policy_number ? (
                <div className='col-xs-12 quote-divider'>
                  <div className='quote-title'>Policy number</div>
                  <div className='quote-info'>{resource.data.attributes.policy_number}</div>
                </div>
              ) : resource.data.attributes.quote_reference && (
                <div className='col-xs-12 quote-divider'>
                  <div className='quote-title'>Reference:</div>
                  <div className='quote-info'>{resource.data.attributes.quote_reference}</div>
                </div>
              )}

              <div className="col-xs-12 quote-divider">
                <div className="quote-title">Product: </div>
                <div className="quote-info">{productName}</div>
              </div>


              {isOwner() && (
                <div className='col-xs-12 quote-divider'>
                  <Field
                    label={'Campaign:'}
                    labelSize={3}
                    name='data.attributes.metadata.campaign'
                    filters={[
                      {filter: 'filter[product]', value: currentProduct.data.id}
                    ]}
                    component={SelectCampaign}
                  />
                </div>
              )}

              {showErrors ? (
                <div className='col-xs-12 quote-divider'>
                  <div className='error-box'>
                    <h4>Attention required</h4>
                    <ul>
                      {Object.keys(formErrors).map((key, i) => {
                        if (displayedErrors.indexOf(formErrors[key]) === -1) {
                          displayedErrors.push(formErrors[key])
                          return (
                            <li key={i}>{formErrors[key]}</li>
                          )
                        }
                      })}
                    </ul>
                  </div>
                </div>
              ) : (
                <div className=''>
                  {hasPermission('policy.handle_all_referrals') && _.get(resource, 'data.attributes.metadata.referrals', []).length > 0 && (
                    <div className='col-xs-12 quote-divider'>
                      <div className='quote-title'>Referral reasons:</div>
                      <div className='quote-info'>
                        <ul>
                          {_.get(resource, 'data.attributes.metadata.referrals', []).map((reason, i) => (
                            <li key={i}>{reason}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}

                  {_.get(formValues, 'data.attributes.metadata.scheme_type') && (
                    <div className='col-xs-12 quote-divider'>
                      <div className='quote-title'>Type of cover:</div>
                      <div className='quote-info'>
                        {tripType() === 'single' && ('Single Trip Travel')}
                        {tripType() === 'annual' && ('Annual Multi-trip Travel')}
                        {tripType() === 'longstay' && ('Long Term')}
                      </div>
                    </div>
                  )}

                  {_.get(formValues, 'data.attributes.metadata.traveller_group') && (
                    <div className='col-xs-12 quote-divider'>
                      <div className='quote-title'>Travellers:</div>
                      <div className='quote-info'>
                        {displayName(formValues.data.attributes.metadata.traveller_group)}<br/>
                        {formValues.data.attributes.metadata.adults.length} x {pluralize('adult', formValues.data.attributes.metadata.adults.length)}
                        {_.get(formValues, 'data.attributes.metadata.children', []).length !== 0 ? ', ' + formValues.data.attributes.metadata.children.length + ' x ' +
                          pluralize('children', formValues.data.attributes.metadata.children.length) : ''}
                      </div>
                    </div>
                  )}

                  {(campaignInfo) && (
                    <div className='col-xs-12 quote-divider'>
                      <div className='quote-title'>Campaign:</div>
                      <div className='quote-info'>
                        <span>{campaignInfo.name}</span>
                        <span>{' '}({campaignInfo.code})</span>
                      </div>
                    </div>
                  )}

                  {(_.get(formValues, 'data.attributes.metadata.destinations', []).length > 0 || _.get(formValues, 'data.attributes.metadata.region')) && (
                    <div className='col-xs-12 quote-divider'>
                      <div className='quote-title'>Destinations:</div>
                      <div className='quote-info'>
                        {tripType() === 'single' ? (
                          <span>
                            {formValues.data.attributes.metadata.destinations.map((destination, i) => {
                              let detail = i ? ', ' : ''
                              return (detail + destination)
                            })}
                          </span>
                        ) : (
                          <span>
                            {displayName(formValues.data.attributes.metadata.region)}
                          </span>
                        )}
                      </div>
                    </div>
                  )}

                  {((tripType() === 'annual' || tripType() === 'longstay') && _.get(formValues, 'data.attributes.metadata.max_trip_duration')) && (
                    <div className='col-xs-12 quote-divider'>
                      <div className='quote-title'>Max trip duration:</div>
                      <div className='quote-info'>{formValues.data.attributes.metadata.max_trip_duration} Days</div>
                    </div>
                  )}

                  {_.get(formValues, 'data.attributes.metadata.start_date') && (
                    <div className='col-xs-12 quote-divider'>
                      <div className='quote-title'>Dates of cover:</div>
                      <div className='quote-info dates-of-cover'>
                        <FormattedDate
                          value={new Date(formValues.data.attributes.metadata.start_date)}/>{formValues.data.attributes.metadata.end_date && tripType() === 'single' && (
                        <div className='to-date'> to <FormattedDate
                          value={new Date(formValues.data.attributes.metadata.end_date)}/>
                        </div>)}
                      </div>
                    </div>
                  )}

                  {(_.get(formValues, 'data.attributes.metadata.options.winter_sports') === 'true' ||
                    _.get(formValues, 'data.attributes.metadata.options.hazardous_activities') === 'A') && (
                    <div className='col-xs-12 quote-divider'>
                      <div className='quote-title'>Winter sports and activities:</div>
                      <div className='quote-info'>
                        {_.get(formValues, 'data.attributes.metadata.options.winter_sports') === 'true' && (
                          <p>Winter Sports Cover</p>
                        )}
                        {_.get(formValues, 'data.attributes.metadata.options.hazardous_activities') === 'A' && (
                          <p>Activity
                            category {_.get(formValues, 'data.attributes.metadata.options.hazardous_activities')}</p>
                        )}
                      </div>
                    </div>
                  )}

                  {(_.get(formValues, 'data.attributes.metadata.options.golf_cover') === 'true' ||
                    _.get(formValues, 'data.attributes.metadata.options.excess_waiver') === 'true' ||
                    _.get(formValues, 'data.attributes.metadata.options.business_equipment') === 'true' ||
                    _.get(formValues, 'data.attributes.metadata.options.natural_catastrophe') === 'true' ||
                    _.get(formValues, 'data.attributes.metadata.options.travel_dispute') === 'true' ||
                    _.get(formValues, 'data.attributes.metadata.options.scheduled_airline_failure') === 'true' ||
                    _.get(formValues, 'ata.attributes.metadata.options.manual_business_trips') === 'true' ||
                    _.get(formValues, 'data.attributes.metadata.options.cruise') === 'true' ||
                    _.get(formValues, 'data.attributes.metadata.options.wedding_cover') === 'true' ||
                    _.get(formValues, 'data.attributes.metadata.options.gadget_cover') === 'true' ||
                    _.get(formValues, 'data.attributes.metadata.options.pet_cover') === 'true') && (
                    <div className='col-xs-12 quote-divider'>
                      <div className='quote-title'>Additional cover:</div>
                      <div className='quote-info'>
                        <p>{Object.keys(_.get(formValues, 'data.attributes.metadata.options', {})).map((option) => {
                          if (option !== 'winter_sports' && option !== 'hazardous_activities' && formValues.data.attributes.metadata.options[option] === 'true') {
                            let detail = selectedOptions ? ', ' : ''
                            selectedOptions++
                            return (detail + displayName(option))
                          }
                        })}</p>
                      </div>
                    </div>
                  )}
                  {_.get(formValues, 'data.attributes.metadata.options.personal_accident') && (
                    <div className='col-xs-12 quote-divider'>
                      <div className='quote-title'>Personal Accident</div>
                      <div className='quote-info'>
                        {_.get(currentProduct, 'data.attributes.metadata.options.personal_accident.items.' + formValues.data.attributes.metadata.options.personal_accident)}
                      </div>
                    </div>
                  )}
                </div>
              )}


              {scheme.length === 1 &&
              _.get(product, 'premiumIndication.meta.scheme.scheme_type', false) ? ( // use premium indication
                <div className="schemeSelected__wrapper">
                  {this.renderSchemeSummary(_.get(product, 'premiumIndication'), 0, scheme)}
                </div>
              ) : (
                <div className="schemeSelected__wrapper">
                  {scheme.map((scheme, i) => {
                    const selection = scheme.id ? product.quotes[scheme.id] : null;
                    if (selection) {
                      return this.renderSchemeSummary(selection, i);
                    }
                  })}
                </div>
              )}

              {(!error && Object.keys(formErrors).length === 0) && (
                <div className='col-xs-12 bottom-bar'>

                  {mta && (
                    <div className="quote-actions">
                      <Button
                        label="Save MTA Quote"
                        bsStyle="primary"
                        rightIcon="fa-arrow-right"
                        block={true}
                        isLoading={product.isSaving}
                        handleClick={handleSubmit(::this.saveQuotes)}
                      />
                    </div>
                  )}

                  {!mta && !screening.index > 0 && (
                    <QuoteActions
                      product={product}
                      currentProduct={currentProduct}
                      declaration={_.get(currentProduct, 'data.attributes.metadata.purchase_declaration', '')}
                      image={productIcon}
                      selectedSchemes={_.get(formValues, 'data.relationships.schemes.data', [])}
                      handleSubmit={handleSubmit}
                    />
                  )}

                  {!mta && (Object.keys(product.quotes).length > 0 || product.isCalculating) && (
                    <Field
                      name='data.attributes.metadata.overrides.commission_broker'
                      product={product}
                      currentProduct={currentProduct}
                      calculate={handleSubmit(this.calculateQuotes)}
                      component={CommissionAdjust}
                    />
                  )}
                </div>
              )}


            </div>
          </Sticky>
        </StickyContainer>
      </div>
    )
  }
}

const FORM_NAME = 'LeisureTravelForm'

const form = reduxForm({form: FORM_NAME})(Sidebar)
const selector = formValueSelector(FORM_NAME)
const mapStateToProps = (state, props) => {
  const values = selector(state,
    'data.id',
    'data.attributes.metadata.adults[0].address',
    'data.relationships.schemes.data',
    'data.attributes.metadata.adults',
    'data.attributes.metadata.children',
    'data.attributes.metadata.medical_declaration_questions',
    'data.attributes.metadata.options',
    'data.attributes.metadata.scheme_type',
    'data.attributes.metadata.start_date',
    'data.attributes.metadata.end_date',
    'data.attributes.metadata.destinations',
    'data.attributes.metadata.region',
    'data.attributes.metadata.traveller_group',
    'data.attributes.metadata.endorsements',
    'data.attributes.metadata.subjectivities',
    'data.relationships.broker.data.id',
    'data.relationships.user.data.id',
    'data.attributes.metadata.residency',
    'data.attributes.metadata.trip_durations_annual',
    'data.attributes.metadata.options'
  )

  return {
    auth: state.auth,
    initialValues: props.resource,
    form: 'LeisureTravelForm',
    formValues: values,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    product: state.product,
    screening: state.screening
  }
}

export default connect(mapStateToProps)(form)
