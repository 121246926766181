import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router'
import MySettings from '../../../auth/containers/MySettings'
import OrganisationSettings from '../../../auth/containers/OrganisationSettings'
import InternalNavigation from '../InternalNavigation/InternalNavigation'
import logo from '../../../../images/brand/logo.svg'
import { getOrganisationTypes } from '../../redux/layoutActions'
import { logOut, isOwner } from '../../../auth/redux/authActions'
import { fetchStats } from '../../../dashboard/redux/dashboardActions'
import QuickSearch from '../../../dashboard/components/QuickSearch'
import Icon from '../../../../common/components/Icon/Icon'
import './InternalHeader.scss'
import { Avatar } from '../../../../common/components'
import getIncludedResource from '../../../../helpers/getIncludedResource'

export default class InternalHeader extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    layout: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      mobileDropdown: false
    }
  }

  handleLogout (e) {
    e.preventDefault()
    this.props.dispatch(logOut())
  }

  componentWillMount () {
    this.props.dispatch(fetchStats())
    this.props.dispatch(getOrganisationTypes())
  }

  render () {
    const { auth } = this.props
    const currentOrganisation = getIncludedResource(this.props.auth.user.data, this.props.auth.user.included, 'current_organisation')
    return (
      <header className='header'>
        <div className='row'>
          <div className='col-md-12'>
            <div className='navbar'>
              <Link to='/dashboard'>
                <img className='logo' src={logo} />
              </Link>
              <div className='search-tab'>
                <QuickSearch />
              </div>
              <div className='user-avatar'>
                <Avatar user={auth.user} />
              </div>
              <div className='user-info'>
                { auth.user.data.attributes.first_name + ' ' + auth.user.data.attributes.last_name }
                <span className='date'>{new Date().getUTCDate()}/{new Date().getMonth() + 1}/{new Date().getUTCFullYear()} </span>
                <br />
                { isOwner() ? (
                  <Link to={'/admin/organisations/' + currentOrganisation.data.id}>
                    { currentOrganisation.data.attributes.name }
                  </Link>) : (currentOrganisation.data.attributes.name) }

                <p><span className='logout-bt' onClick={this.handleLogout.bind(this)}>Logout</span></p>
              </div>
              <div className='burgerMenu'>
                <Button className='burgerMenuBt' onClick={() => this.setState({ mobileDropdown: !this.state.mobileDropdown })}>
                  <Icon name='bars' />
                </Button>
                <div className='dropmenu mobile-dropdown-menu-box'>
                  {this.state.mobileDropdown && (
                    <div>
                      <InternalNavigation {...this.props} />
                      <div className='divider' />
                      <span className='logout-bt-mobile' onClick={this.handleLogout.bind(this)}>Logout</span>
                      <span className='close-area-mobile-menu' onClick={() => this.setState({ mobileDropdown: !this.state.mobileDropdown })} />
                    </div>
                  )}
                </div>
                <MySettings {...this.props} />
                <OrganisationSettings {...this.props} />
              </div>
            </div>
          </div>
        </div>
      </header>
    )
  }
}
