import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { TextField } from '../../../../common/components/ReduxFormField'
import { HorizontalFormControl, Icon } from '../../../../common/components'
import getIncludedResource from '../../../../helpers/getIncludedResource'
import { getPremiumOverrideCalculation } from '../../redux/productActions'
import { formValueSelector } from 'redux-form'
import * as _ from 'lodash'
import './styles.scss'

class PremiumAdjust extends Component {
  static propTypes = {
    baseName: PropTypes.string.isRequired,
    formName: PropTypes.string.isRequired,
    formValues: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]).isRequired,
    scheme: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    showDiscount: PropTypes.bool,
    taxType: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      open: false
    }
  }

  componentWillMount() {
    const { open } = this.props
    if (open) {
      this.setState({ open: open })
    }
    this.premiumWillUpdate = _.debounce(this.premiumWillUpdate, 800)
  }

  componentWillUpdate (nextProps, nextState) {
    const { formValues, scheme, dispatch } = this.props
    const optionsNewProps = _.get(nextProps.formValues, 'data.attributes.metadata.options')
    const optionsOldProps = _.get(formValues, 'data.attributes.metadata.options')

    // re-calculate if options change
    if (nextState.open && !_.isEqual(optionsNewProps, optionsOldProps)) {
      this.updatePremiumOnly(nextProps)
    }

    // re-calculate any premium adjustment change
    if (!_.isEqual(nextProps.formValuesMta, this.props.formValuesMta)) {
      this.premiumWillUpdate(nextProps)
    }
    if (!_.isEqual(this.state.open, nextState.open)) {
      dispatch(getPremiumOverrideCalculation(scheme, this.props.formName));
    }
  }


  componentDidMount() {
    const { scheme, dispatch } = this.props

    // Causing a loop
    // this.updatePremium(this.props)
   // dispatch(getPremiumOverrideCalculation(scheme, this.props.formName));
  }

  componentDidUpdate(nextProps) {
    if (!_.isEqual(nextProps.formValues, this.props.formValues)) {
      this.premiumWillUpdate(nextProps)
    }
  }

  /**
   * De-bounced premium update method
   * @param nextProps
   */
  premiumWillUpdate(nextProps) {
    this.updatePremium(nextProps)
  }


  updatePremium (nextProps) {
    const { scheme, dispatch, change, setStateOverride, reCalculateForAdjustments } = this.props
    if (nextProps.formName) {
      dispatch(getPremiumOverrideCalculation(scheme, nextProps.formName, (success) => {
        if (success) {
          reCalculateForAdjustments(); // Call back to Update the Parent (SideBar)
        } else {
          setStateOverride({ override: 0 });
          dispatch(change('data.attributes.metadata.mta_overrides.gross_premium', null));
        }
      }));
    } else {
      reCalculateForAdjustments(); // Call back to Update the Parent (SideBar)
    }

  }

  getCommission(commissions, type) {
    let values = { rate: 0, amount: 0 }
    commissions.forEach(commission => {
      if (commission.commission_type === type) {
        values.amount = commission.amount.toFixed(2)
        values.rate = (_.get(commission, 'effective_rate', commission.rate)  * 100).toFixed(2)
      }
    })
    return values
  }

  render() {
    const { baseName, premiumOverrideCalculation, user, product, showDiscount, taxType } = this.props
    const broker = getIncludedResource(user.data, user.included, 'current_broker')
    const currencyType = _.get(product, 'premiumOverrideCalculation.meta.premium.currency', 'gbp').toLowerCase();

    // Return empty if no current broker is set
    if (broker.data.id || Array.isArray(broker.data) && broker.data.length > 0) {
      return (
        <div/>
      )
    }

    return (
      <div className="premium-adjust form-horizontal">

          <h4 className="toggle-display" onClick={() => this.setState({ open: this.state.open === false })}>
            Premium Adjustment {this.state.open ? (<Icon className="premium-chevron-icon" name="chevron-up" />) : (<Icon className="premium-chevron-icon" name="chevron-down" />)}
          </h4>

        {this.state.open && (
          <div className="premium-adjust-form">
            <div className="col-sm-4">
              <Field
                  name={baseName + '.commission_broker'}
                  label="Broker %"
                  labelSize={3}
                  percentage={true}
                  placeholder={this.getCommission(_.get(premiumOverrideCalculation, 'meta.commissions', []), 'broker').rate.toString()}
                  monetaryValue={this.getCommission(_.get(premiumOverrideCalculation, 'meta.commissions', []), 'broker').amount.toString()}
                  component={HorizontalFormControl}
                  isCalc={product.isPremiumAdjusting}
              />
              <Field
                name={baseName + '.commission_rate'}
                label="Total %"
                labelSize={3}
                percentage={true}
                placeholder={
                  _.get(
                    premiumOverrideCalculation,
                    'meta.rates.effective_commission_rate',
                    _.get(premiumOverrideCalculation, 'meta.rates.commission_rate', 0)
                  ).toFixed(2).toString()
                }
                monetaryValue={_.get(premiumOverrideCalculation, 'meta.premium.commission', 0).toString()}
                component={HorizontalFormControl}
                isCalc={product.isPremiumAdjusting}
              />
              <Field
                name={baseName + '.net'}
                label="Net"
                labelSize={3}
                currency={true}
                placeholder={_.get(premiumOverrideCalculation, 'meta.premium.net', 0).toFixed(2).toString()}
                component={HorizontalFormControl}
                isCalc={product.isPremiumAdjusting}
                className="text-right"
                currencyType={currencyType}
              />
            </div>


            <div className="col-sm-4">
              <Field
                name={baseName + '.commission_owner'}
                label="Axiom %"
                labelSize={3}
                percentage={true}
                placeholder={this.getCommission(_.get(premiumOverrideCalculation, 'meta.commissions', []), 'owner').rate.toString()}
                monetaryValue={this.getCommission(_.get(premiumOverrideCalculation, 'meta.commissions', []), 'owner').amount.toString()}
                component={HorizontalFormControl}
                isCalc={product.isPremiumAdjusting}
              />

              <HorizontalFormControl
                  disabled={true}
                  label={taxType}
                  labelSize={3}
                  input={{ value: '' }}
                  percentage={true}
                  placeholder={_.get(premiumOverrideCalculation, 'meta.rates.tax_rate', 0).toFixed(2).toString()}
                  monetaryValue={_.get(premiumOverrideCalculation, 'meta.premium.tax', 0).toString()}
                  isCalc={product.isPremiumAdjusting}
              />
              <div className="total-gross-override">
                <Field
                    name={baseName + '.gross_premium'}
                    label={<span>Gross</span>}
                    labelSize={3}
                    currency={true}
                    placeholder={_.get(premiumOverrideCalculation, 'meta.premium.gross', 0).toFixed(2).toString()}
                    component={HorizontalFormControl}
                    isCalc={product.isPremiumAdjusting}
                    className="text-right"
                    currencyType={currencyType}
                />
              </div>
            </div>
            <div className="col-sm-4">

              {showDiscount && (
                <div className="discount">
                  <Field
                    name={baseName + '.discount_rate'}
                    label={<span>Discount</span>}
                    labelSize={6}
                    percentage={true}
                    placeholder={_.get(premiumOverrideCalculation, 'meta.premium.discount_rate', 0).toFixed(2).toString()}
                    component={HorizontalFormControl}
                    isCalc={product.isPremiumAdjusting}
                    className="text-right"
                  />
                </div>
              )}

              <div className="field-wrapper">
                <Field
                    className="premium-textarea"
                    name={baseName + '.note'}
                    placeholder="Reason for override..."
                    type="textarea"
                    component={TextField}
                />
              </div>
            </div>
            <span className="please-note-mssg"><strong>Please note:</strong> the reason given above is for internal purposes only, please
          add an endorsement to the policy if required.</span>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    product: state.product,
    user: state.auth.user,
    premiumOverrideCalculation: state.product.premiumOverrideCalculation,
    formValues: formValueSelector(props.formName)(state, 'data.attributes.metadata.overrides', {}),
    formValuesMta: formValueSelector(props.formName)(state, 'data.attributes.metadata.mta_overrides', {}),

  }
}
export default connect(mapStateToProps)(PremiumAdjust)
