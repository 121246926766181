import React, { Component } from 'react'
import _ from 'lodash'
import './styles.scss'
import { Field } from 'redux-form'
import { HorizontalFormControl, NameFields } from '../../../../common/components'
import { SelectField, TextField } from '../../../../common/components/ReduxFormField'
import { Row, Col, FormGroup, ControlLabel } from 'react-bootstrap'

const METADATA = 'data.attributes.organisation.metadata'

export default class Directors extends Component {
  constructor(props) {
    super(props)

    this.state = { numberOfDirectors: 1 }
  }

  componentWillMount () {
    const { fields, dispatch, change } = this.props

    if (fields.length === 0) {
      dispatch(change(`${METADATA}.count_directors`, 1))
      fields.push()
    }
  }

  componentWillReceiveProps (nextProps) {
    const { fields, formValues } = this.props
    const noDirectors = _.get(formValues, 'attributes.organisation.metadata.count_directors')
    const nextNoDirectors = _.get(nextProps.formValues, 'attributes.organisation.metadata.count_directors')

    if (noDirectors !== nextNoDirectors) {

      if (noDirectors < nextNoDirectors) {
        const fieldsToAdd = nextNoDirectors - noDirectors
        for (let i = 0; i < fieldsToAdd; i++) {
          fields.push()
        }
      }

      if (noDirectors > nextNoDirectors) {
        const fieldsToRemove = noDirectors - nextNoDirectors
        for (let i = 0; i < fieldsToRemove; i++) {

          if (nextProps.fields.length >= nextNoDirectors) {
            const difference = nextProps.fields.length - nextNoDirectors;
            const totalToRemove = nextProps.fields.length - difference;

            fields.remove(totalToRemove);
          }
        }
      }
    }
  }

  render () {
    const { fields } = this.props
    return (
      <div>
        <div className='directors'>
          <Row>
            <Col sm={8}>
              <Field
                name={`${METADATA}.count_directors`}
                label='No. Directors'
                labelSize={2}
                component={ SelectField }
                // onChange={ this.handleChange }
                clearable={false}
                options={[
                  { value: 1, label: 'One' },
                  { value: 2, label: 'Two' },
                  { value: 3, label: 'Three' },
                  { value: 4, label: 'Four' }
                  ]}
              />
            </Col>
          </Row>
          {fields.map((insured, index) => {
            return (
              <Row key={index}>
                <Col sm={12}>
                  <div>
                    <Col sm={12} className="directors-container">
                      <Row>
                        <div key={index}  className={ 'director-row col-xs-12 director' + index + ' adult'}>
                          <h4 className={'director-header-number'}>
                           <strong>Director {index + 1}</strong>
                         </h4>
                        </div>
                        <Col sm={12}>
                          <div className="director-name-field">
                            <NameFields
                              baseName={insured}
                              label='Name'
                              labelSize={2}
                              fieldComponent={Field}
                              mdFieldSize={10}
                            />
                          </div>
                        </Col>
                        <Col sm={6}>
                          {/*<Field*/}
                            {/*name={`${METADATA}.directors[${index}].individual_fca_number`}*/}
                            {/*mdFieldSize={8}*/}
                            {/*component={HorizontalFormControl}*/}
                            {/*labelSize={4}*/}
                            {/*label='Individual FCA Number'*/}
                          {/*/>*/}
                          <Field
                            name={`${METADATA}.directors[${index}].position`}
                            mdFieldSize={8}
                            component={HorizontalFormControl}
                            labelSize={4}
                            label='Position'
                          />
                        </Col>
                        <Col sm={6}>
                          <Field
                            name={`${METADATA}.directors[${index}].year_of_appointment`}
                            mdFieldSize={8}
                            component={HorizontalFormControl}
                            labelSize={4}
                            label='Year of appointment'
                          />
                        </Col>
                        <Col sm={6}>
                          <Field
                            name={`${METADATA}.directors[${index}].actively_involved`}
                            mdFieldSize={8}
                            component={HorizontalFormControl}
                            labelSize={4}
                            label='Actively involved?'
                          />
                        </Col>
                        <Col sm={6}>
                          <FormGroup controlId="qualification">
                            <Col sm={4}>
                              <ControlLabel>
                                Qualification?
                              </ControlLabel>
                            </Col>
                            <Col sm={8}>
                              <Col sm={12}>
                                <Field
                                  name={`${METADATA}.directors[${index}].qualifications`}
                                  type={'textarea'}
                                  component={TextField}
                                />
                              </Col>
                            </Col>
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </div>
                </Col>
              </Row>
            )
          })}
        </div>
      </div>
    )
  }
}
