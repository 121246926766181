import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, FieldArray } from 'redux-form'
import { Button } from '../../../../common/components'
import Benefits from '../Benefits'
import _ from 'lodash'

const FORM_NAME = 'schemeBenefitsListForm'

class SchemeBenefitsForm extends Component {
  static propTypes = {
    scheme: PropTypes.object.isRequired,
    schemeStatus: PropTypes.string.isRequired,
    schemeLabel: PropTypes.object.isRequired,
  };

  render() {
    const { scheme, submitting, handleSubmit, schemeStatus, schemeLabel } = this.props
    const editable = schemeStatus === 'test'

    return (
      <div className="scheme-details-form">
        <form onSubmit={handleSubmit}>
          <h2 className="resource-name">{scheme.data.attributes.name}&nbsp;
            ({scheme.data.attributes.cover_level}) {schemeLabel}</h2>

          <div className="row scheme-benefits">
            <div className="col-xs-12">
              <FieldArray
                name="meta.benefits"
                schemeOptions={_.get(scheme, 'meta.options', {})}
                component={Benefits}
                disabled={!editable}
              />
            </div>
          </div>

          <div className="hr transparent"/>

          <div className="row actions">
            {editable && (
              <Button
                type="submit"
                label="Save Changes"
                bsStyle="primary"
                className="pull-right"
                isLoading={submitting}
              />
            )}
          </div>
        </form>
      </div>
    )
  }
}

const form = reduxForm({ form: FORM_NAME })(SchemeBenefitsForm)
const mapStateToProps = (state, props) => {
  return {
    initialValues: props.scheme
  }
}
export default connect(mapStateToProps)(form)
