import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, FormGroup, ControlLabel } from 'react-bootstrap'
import SimpleDatePicker from '../SimpleDatePicker/SimpleDatePicker'
import moment from 'moment'
import 'moment/locale/en-gb'
import './styles.scss'

class DatePickerLeisure extends Component {
  static propTypes = {
    label: PropTypes.string,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object,
    fieldSize: PropTypes.string,
    labelSize: PropTypes.number,
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    minDateToday: PropTypes.bool,
    showYear: PropTypes.bool
  };

  onChange(date) {
    if (this.props.input && this.props.input.onChange) {
      this.props.input.onChange(date)
    }
  }

  render() {
    const { input, name, meta, label, labelSize, minDate, maxDate, minDateToday, showYear } = this.props
    const selected = input.value ? input.value : null
    const size = labelSize ? labelSize : 2

    return (
        <FormGroup controlId={name ? name : label}
                   className={((meta && meta.touched && meta.error) ? ' has-error' : '')}>
          <Col componentClass={ControlLabel} sm={labelSize}>{label}</Col>
          <div className="select-date-input">
            <SimpleDatePicker
                minDate={minDateToday ? moment().format() : minDate}
                maxDate={maxDate}
                showYear={showYear}
                selected={selected}
                onChange={::this.onChange}
            />
            {(meta && meta.touched && meta.error) && <div><span className="input-error">{meta.error}</span></div>}
          </div>
        </FormGroup>
    )
  }
}

export default DatePickerLeisure
