import React from 'react';
import { Icon } from '../../../common/components';
import GeneralInfo from './components/GeneralInfo';
import Box from '../../../common/components/Box/Box';
import InternalContainer from '../../layout/containers/Internal';
import ActiveProducts from './components/ActiveProducts';
import Guides from './components/Guides';

class SystemInfo extends React.PureComponent {
  render() {
    return (
      <InternalContainer title={'System'} buttons={null}>
        <Box className="help-centre-container">
          <h3>
            <Icon name="bar-chart" /> System Information
          </h3>
          <GeneralInfo />
          {/*<div className="divider-border mt-15 mb-15"/>*/}
          {/*<ActiveProducts />*/}
          {/*<div className="divider-border mt-15 mb-15"/>*/}
          {/*<Guides />*/}
        </Box>
      </InternalContainer>
    );
  }
}

export default SystemInfo;
