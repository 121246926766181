import React, { Component } from 'react'
import PropTypes from 'prop-types'
import InternalNavigation from '../InternalNavigation/InternalNavigation'
import { getOrganisationTypes } from '../../redux/layoutActions'
import './SideBar.scss'


export default class SideBar extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    layout: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
  };

  componentWillMount() {
    this.props.dispatch(getOrganisationTypes())
  }

  render() {
    return (
      <div className="sidebar">
        <InternalNavigation {...this.props} />
      </div>

    )
  }
}

