import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button } from '../../../common/components'
import InternalContainer from '../../layout/containers/Internal'
import CustomerFormContainer from './CustomerForm'
import { clearData } from '../redux/customerActions'

class CustomerCreateContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    customer: PropTypes.object.isRequired
  }

  render() {
    const buttons = (
        <Button
            bsStyle="default"
            label="Back"
            isBackButton={true}
            leftIcon="caret-left"
        />
    )

    return (
        <InternalContainer title="Customer" buttons={buttons}>
          <CustomerFormContainer {...this.props} />
        </InternalContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    customer: state.customer
  }
}

export default connect(mapStateToProps)(CustomerCreateContainer)
