import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { Button, Modal, SelectRole, SelectUser } from '../../../../common/components'
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux'
import { assignUserToBroker } from '../../redux/brokerActions'
import _ from 'lodash'

const FORM_NAME = 'assignUserToBroker'

const validate = values => {
  const errors = {}

  if (!_.get(values, 'data.relationships.user.data.id', false)) {
    _.set(errors, 'data.relationships.user.data.id', 'A user is required')
  }

  return errors
}

class BrokerAssignUser extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resourceId: PropTypes.string.isRequired,
    callback: PropTypes.func,
  };

  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded, callback } = this.props
    if (newProps.submitSucceeded && !submitSucceeded) {
      if (callback) {
        callback('')
      }
      dispatch(closeModal(FORM_NAME))
    }
  }

  handleSubmit(values) {
    return this.props.dispatch(assignUserToBroker(this.props.resourceId, values))
  }

  handleOpen() {
    const { dispatch, reset } = this.props
    dispatch(reset())
    dispatch(openModal(FORM_NAME))
  }

  render() {
    const { handleSubmit } = this.props

    const submitButton = (<Button
      type="submit"
      bsStyle="primary"
      handleClick={handleSubmit(::this.handleSubmit)}
      label="Assign User"
    />)

    return (
      <div className="email-btn-wrapper pull-right">
        <Button
          type="button"
          bsStyle="primary"
          label="Assign Existing User"
          rightIcon="user"
          handleClick={::this.handleOpen}
        />

        <Modal
          name={FORM_NAME}
          title="Assign User to Broker"
          close={true}
          footer={submitButton}
        >
          <form onSubmit={handleSubmit(::this.handleSubmit)}>
            <div className="col-xs-12 form-horizontal">
              <div className="col-xs-12">

                <Field
                  label="Assign User"
                  labelKeys={[
                    'attributes.first_name',
                    'attributes.last_name'
                  ]}
                  labelSize={3}
                  name={'data.relationships.user.data.id'}
                  component={SelectUser}
                />

                <Field
                  name={'data.relationships.roles.data'}
                  label="Roles"
                  labelSize={3}
                  multi={true}
                  object={true}
                  autoFilterType={'filter[name]'}
                  autoFilter={'broker'}
                  component={SelectRole}
                />

              </div>
            </div>
          </form>
        </Modal>
      </div>
    )
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(BrokerAssignUser)
const mapStateToProps = (state, props) => {

  return {
  }
}

export default connect(mapStateToProps)(form)
