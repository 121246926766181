import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import InternalContainer from '../../layout/containers/Internal'
import RenewalList from '../components/RenewalList/RenewalList'
import _ from 'lodash'

class RenewalListContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
  }

  render() {
    const currentBroker = _.get(this.props.auth, 'user.data.relationships.current_broker.data.id', null)

    return (
        <InternalContainer title="Renewals">
          <RenewalList {...this.props} currentBroker={currentBroker}/>
        </InternalContainer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps)(RenewalListContainer)
