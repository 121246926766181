export default function displayName(value, titleCase = false) {
  if (!value) {
    return ''
  }

  value = value.replace(/-/g, ' ').replace(/_/g, ' ')
  if (titleCase) {
    return value.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()})
  }

  return value.substring(0, 1).toLocaleUpperCase() + value.substring(1)
}
