import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../../../common/components'
import { finishDeclaration } from '../../redux/screeningActions'
import ConditionSearch from '../ConditionSearch'
import ConditionQuestions from '../ConditionQuestions'
import DeclaredConditions from '../DeclaredConditions'
import getIncludedResponse from '../../../../helpers/getIncludedResource'

export default class HealixSession extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    screening: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    finishScreening: PropTypes.func.isRequired
  }

  render() {
    const { index, screening, dispatch, finishScreening } = this.props
    const conditions = getIncludedResponse(screening.declaration.data, screening.declaration.included, 'conditions')

    return (
      <div className="screening-session-healix">
        {conditions.length ? conditions.map((condition, i) => {
          return (
            <div key={i} className="row">
              <div className="col-xs-12">
                <ConditionQuestions condition={condition} dispatch={dispatch} screening={screening}/>
              </div>
            </div>
          )
        }) : (
          <div className="row">
            <div className="col-xs-12">
              <ConditionSearch index={index} dispatch={dispatch} screening={screening}/>
            </div>
          </div>
        )}

        {!screening.complete
        && screening.declaration.data.attributes.state === 'ready' && (
          <div className="row declaration-actions">
            <div className="col-xs-12">
              <Button
                bsStyle="primary"
                isLoading={screening.isSubmitting}
                label="Continue"
                className="pull-right"
                handleClick={() => dispatch(finishDeclaration())}
              />
            </div>
          </div>
        )}

        {screening.complete && (
          <div className="row">
            <div className="col-xs-12">
              <DeclaredConditions dispatch={dispatch} screening={screening}/>
            </div>
          </div>
        )}

        {screening.complete && (
          <div className="row declaration-actions">
            <div className="col-xs-12">
              <Button
                className="pull-right"
                bsStyle="primary"
                label="Finish Screening"
                isLoading={(screening.isSubmitting && screening.index == index)}
                handleClick={finishScreening}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}
