import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box, DataTable, Button, ButtonBar } from '../../../../common/components'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { push } from 'react-router-redux'
import { FormattedDate, FormattedTime } from 'react-intl'
import OrganisationAssignUser from '../OrganisationAssignUser/OrganisationAssignUser'
import { refreshData } from '../../../../common/components/DataTable/redux/dataTableActions'
import * as _ from 'lodash'

export default class OrganisationUsers extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
  };

  formatDate(cell, row) {
    return (
      <span><FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/></span>
    )
  }

  refreshList() {
    this.props.dispatch(refreshData('users'))
  }

  handleRowClick(row) {
    this.props.dispatch(push('/admin/users/' + row.id))
  }

  render() {
    const resource = this.props.resource.data.attributes

    return (
      <div>
        <Box>
          <h2 className="resource-name">{resource.name}</h2>

          <DataTable
            source="/users"
            name="users"
            showTotal={true}
            autoFilter={this.props.resource.data.id}
            autoFilterType="filter[organisation]"
            onRowSelect={this.handleRowClick.bind(this)}>

            <TableHeaderColumn dataField="id" isKey={true} hidden={true}>ID</TableHeaderColumn>
            <TableHeaderColumn dataField="first_name">First Name</TableHeaderColumn>
            <TableHeaderColumn dataField="last_name">Last Name</TableHeaderColumn>
            <TableHeaderColumn dataField="email" dataSort={true}>Email</TableHeaderColumn>
            <TableHeaderColumn dataField="status" dataSort={true} width={'180px'}>Status</TableHeaderColumn>
            <TableHeaderColumn dataField="created_at" dataFormat={this.formatDate} width={'180px'} dataSort={true}>Created</TableHeaderColumn>
          </DataTable>

          <ButtonBar>
            <OrganisationAssignUser
              className="pull-right"
              resource={this.props.resource}
              callback={::this.refreshList}
            />
            <Button
              link={true}
              to={'/admin/users/new/' + this.props.resource.data.id + '?type=' + _.get(this.props.resource, 'data.attributes.organisation_type_name') + '&contact=true'}
              className="pull-right"
              bsStyle="primary"
              rightIcon="user"
              label="Add a New Contact"
            />
            <Button
              link={true}
              to={'/admin/users/new/' + this.props.resource.data.id + '?type=' + _.get(this.props.resource, 'data.attributes.organisation_type_name')}
              className="pull-right"
              bsStyle="primary"
              rightIcon="user"
              label="Add a New User"
            />
          </ButtonBar>
        </Box>
      </div>
    )
  }
}
