import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, InfoLabel } from '../../../../common/components';
import DeclarationQuestions from '../DeclarationQuestions';
import displayName from '../../../../helpers/displayName';
import * as _ from 'lodash';
import './styles.scss';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import { createAccount } from '../../redux/policyActions';

export default class LeisureTravelDetails extends Component {
  constructor(props) {
    super(props);
    this.renderCustomerStatus = this.renderCustomerStatus.bind(this)
    this.handleCreateUser = this.handleCreateUser.bind(this)
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    resourceData: PropTypes.object.isRequired,
    resourceIncluded: PropTypes.array.isRequired,
  };

  handleCreateUser(customerId) {
    const { dispatch } = this.props
    dispatch(createAccount(customerId))
  }

  renderCustomerStatus() {
    const { resourceData, resourceIncluded } = this.props;
    const NOT_USER = 'No Account'
    const NOT_APPLICABLE = 'not applicable'
    const customer = getIncludedResource(resourceData, resourceIncluded, 'customer')
    const customerStatus = _.get(customer, 'data.attributes.status')
    const customerId = _.get(customer, 'data.id')
    if (customerStatus !== NOT_APPLICABLE) {
      return (
        <InfoLabel
          label="Account status"
          value={_.capitalize(customerStatus)}
          labelSize={4}
        />
      )
    } else {
      return (
        <div>
          <InfoLabel
            label="Account status"
            value={NOT_USER}
            labelSize={4}
            wrapperClass={'margin-label-fix'}
          />
          <Button
            label={'Create An Account'}
            bsStyle='primary'
            className='pull-right'
            handleClick={() => this.handleCreateUser(customerId)}
          />
        </div>
      )
    }
  }

  render() {
    const { resource, campaignInfo, schemes, premiums, product, renewalStatus } = this.props;
    const campaign = {
      name: _.get(campaignInfo, 'data.attributes.name'),
      code: _.get(campaignInfo, 'data.attributes.code'),
    };

    return (
      <div className="form-horizontal">
        <div className="row">
          <div className="col-sm-6">
            <h3>Summary</h3>
            <div className="form-horizontal">

              <InfoLabel
                label="Start Date"
                date={true}
                value={resource.commences_at || resource.starts_at || _.get(resource, 'metadata.start_date')}
                labelSize={4}
              />
              <InfoLabel
                label="End Date"
                date={true}
                value={resource.expires_at || resource.ends_at || _.get(resource, 'metadata.end_date')}
                labelSize={4}
              />
              <InfoLabel
                label="Activities"
                value={
                  _.get(resource, 'metadata.activities') ?
                    _.get(resource, 'metadata.activities') && _.get(resource, 'metadata.activities', []).map((activity, i) => {
                      return (<p key={i}>{activity}</p>);
                    }) :
                    'N/A'
                }
                labelSize={4}
              />
              <InfoLabel
                label="Referred Activities"
                value={
                  _.get(resource, 'metadata.referred_activities') ?
                    _.get(resource, 'metadata.referred_activities') :
                    'N/A'
                }
                labelSize={4}
              />

              <InfoLabel
                label="Destination"
                labelSize={4}
                value={
                  _.get(resource, 'metadata.scheme_type') !== 'single'
                    ? displayName(_.get(resource, 'metadata.region'), true)
                    : _.get(resource, 'metadata.destinations', []).map((destination, i) => {
                      let detail = i ? ', ' : '';
                      return (detail + destination);
                    })
                }
              />
              <InfoLabel
                label="Traveller Group"
                value={displayName(_.get(resource, 'metadata.traveller_group'), true)}
                labelSize={4}
              />
              <InfoLabel
                label="Winter Sports"
                value={String(_.get(resource, 'metadata.options.winter_sports')).toLowerCase() === 'true' ? 'Yes' : 'No'}
                labelSize={4}
              />
              <InfoLabel
                label="Additional options"
                value={Object.keys(_.get(resource, 'metadata.options', {})).map((key, i) => {
                  const option = resource.metadata.options[key];
                  if (option !== null && option !== 'false' && key !== 'hazardous_activities' && key !== 'winter_sports') {
                    return (
                      <li className="option-list" key={i}>{displayName(key, true)}</li>
                    );
                  }
                })}
                labelSize={4}
              />

              <DeclarationQuestions
                declaration={_.get(resource, 'metadata.medical_declaration')}
                questions={_.get(resource, 'metadata.medical_declaration_questions', [])}
              />
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-horizontal">

              {_.isArray(schemes) && resource.policy_number && schemes.map((scheme, i) => {
                return (
                  <InfoLabel
                    label="Scheme Name"
                    value={scheme.attributes.name}
                    labelSize={4}
                    key={i}
                  />
                )
              })}

              <InfoLabel
                label="Product"
                value={_.get(product, 'data.attributes.name', 'Product')}
                labelSize={4}
              />
              <InfoLabel
                label="Issue Date"
                date={true}
                value={resource.issued_at}
                labelSize={4}
              />
              <InfoLabel
                label="Quote Date"
                date={true}
                value={this.getQuoteDate(premiums)}
                labelSize={4}
              />

              {!resource.policy_number &&
              <InfoLabel
                label="Quote Valid Until"
                date={true}
                value={this.getQuoteValidUntil(premiums)}
                labelSize={4}
              />
              }

              {this.renderCustomerStatus()}

              {renewalStatus &&
              <div>
                <InfoLabel
                  label="Renewal Status"
                  value={this.formatRenewalStatus(renewalStatus)}
                  labelSize={4}
                />
              </div>
              }

            </div>
            {(this.props.campaignInfo && campaign.name && campaign.code) && (
              <InfoLabel
                label="Campaign"
                wrapperClass={'clear-margin'}
                value={`${campaign.name} (${campaign.code})`}
                labelSize={4}
              />
            )}
          </div>
        </div>

      </div>
    );
  }

  getQuoteValidUntil(premiums) {
    let quoteDate = ''
    Array.isArray(premiums) && premiums.map((premium) => {
      if (premium.attributes.premium_type === 'initial premium') {
        quoteDate = premium.attributes.valid_until
      }
    })

    if (!quoteDate) {
      Array.isArray(premiums) && premiums.map((premium) => {
        if (premium.attributes.premium_type === 'additional premium') {
          quoteDate = premium.attributes.valid_until
        }
      })
    }

    return quoteDate
  }

  getQuoteDate(premiums) {
    let quoteDate = ''
    Array.isArray(premiums) && premiums.map((premium) => {
      if (premium.attributes.premium_type === 'initial premium') {
        quoteDate = premium.attributes.created_at
      }
    })

    if (!quoteDate) {
      Array.isArray(premiums) && premiums.map((premium) => {
        if (premium.attributes.premium_type === 'additional premium') {
          quoteDate = premium.attributes.created_at
        }
      })
    }

    return quoteDate
  }

  formatRenewalStatus(renewal_status) {
    const status = renewal_status === 'manual' ? 'non auto-renewal' : renewal_status;

    return _.capitalize(status);
  }
}
