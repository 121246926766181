import React, { Component } from 'react'
import './styles.scss'
import * as _ from 'lodash'
import { connect } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import InfoButton from '../../../../../../common/components/InfoButton/InfoButton'
import PostcodeList from '../../../../../../common/components/Postcode/PostcodeList'
import SelectBrokerRegion from '../../../../../../common/components/Selects/SelectBrokerRegion'
import { AddressFields, CheckboxUnlabeled } from '../../../../../../common/components/ReduxFormField'
import { Box, HorizontalFormControl, InfoLabel, SelectOrganisation } from '../../../../../../common/components'
import { clearSelection as clearAddressFields } from '../../../../../../common/components/Postcode/redux/PostcodeActions'

const ATTRIBUTES = 'data.attributes'
const RELATIONSHIPS = 'data.relationships'
const NEW_ORGANISATION = `${ATTRIBUTES}.organisation`
const EXISTING_ORGANISATION = `${RELATIONSHIPS}.organisation.data`

export class General extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(clearAddressFields())
  }

  organisationPopulate(organisationData) {
    const { dispatch, change } = this.props

    if (organisationData) {
      dispatch(change(`${NEW_ORGANISATION}`, organisationData))
      return
    }

    dispatch(change(`${NEW_ORGANISATION}`, null))
  }

  toggleExistingOrganisation(e) {
    const { dispatch, change, formValues } = this.props
    if (!e.currentTarget.checked) {
      dispatch(change(formValues, `${EXISTING_ORGANISATION}`, null))
      this.organisationPopulate(null)
    }
  }

  componentWillReceiveProps(nextProps) {
    const { dispatch, formValues, change, brokerType, productId } = this.props
    const isTrackingLink = brokerType === 'tracking'
    const nextValue = nextProps.formValues;


    if (_.get(nextValue, `${ATTRIBUTES}.metadata.select_organisation`, false) !== true) {
      dispatch(change(`${ATTRIBUTES}.metadata.select_organisation`, false))
    }

    if (_.get(nextValue, `${ATTRIBUTES}.metadata.same_address`, false) !== true) {
      dispatch(change(`${ATTRIBUTES}.metadata.same_address`, false))
    }

    if (_.get(nextValue, `${EXISTING_ORGANISATION}.id`) !== _.get(formValues, `${EXISTING_ORGANISATION}.id`)) {

      //if organisation has changed update relationship
      const selectOrg = _.get(nextValue, `${EXISTING_ORGANISATION}.attributes`)
      if (selectOrg || _.get(formValues, `${EXISTING_ORGANISATION}.id`)) {
        this.organisationPopulate(selectOrg)
      }
    }

    // duplicate organisation address to the broker address
    if (_.get(nextValue, `${ATTRIBUTES}.metadata.same_address`) !== _.get(formValues, `${ATTRIBUTES}.metadata.same_address`)) {

      if (_.get(nextValue, 'data.attributes.metadata.same_address')) {
        dispatch(change(`${ATTRIBUTES}.address`, _.get(nextValue, `${NEW_ORGANISATION}.address`)))
      } else {
        dispatch(change(`${ATTRIBUTES}.address`, ''))
      }
    }

    if (isTrackingLink && _.get(nextValue, `${NEW_ORGANISATION}.address`) !== _.get(formValues, `${NEW_ORGANISATION}.address`)) {
      dispatch(change(`${ATTRIBUTES}.address`, _.get(nextValue, `${NEW_ORGANISATION}.address`)))
    }

    if (!_.get(nextValue, 'data.attributes.metadata.productId', false)) {
      dispatch(change('data.attributes.metadata.productId', productId))
    }
  }

  render() {
    const { formValues, brokerType, auth } = this.props
    const isTrackingLink = brokerType === 'tracking'
    const duplicateAddress = !!_.get(formValues, 'data.attributes.metadata.same_address')
    const selectOrganisation = _.get(formValues, 'data.attributes.metadata.select_organisation')

    return (
      <Box>
        <Row>
          <Col sm={12}>
            <h4 className='page-title'>General Information</h4>
            <hr/>
          </Col>
          <Col sm={6}>
            <div className='form-horizontal'>
              <Field
                labelSize={4}
                mdFieldSize={8}
                component={HorizontalFormControl}
                name={`${ATTRIBUTES}.trading_name`}
                label={isTrackingLink ? 'Name' : 'Trading name'}
                infoButton={<InfoButton content={'Trading name if different to Organisation Name'} />}
              />
              {isTrackingLink ? (
                <Field
                  labelSize={4}
                  mdFieldSize={8}
                  label='Display Phone'
                  component={HorizontalFormControl}
                  name={`${ATTRIBUTES}.whitelabel.phone`}
                />
              ) : (
                <Field
                  object
                  label='Region'
                  labelSize={4}
                  mdFieldSize={8}
                  component={SelectBrokerRegion}
                  name={`${RELATIONSHIPS}.broker_region.data`}
                  />
                )
              }
              {auth.authenticated && !isTrackingLink && (
                <Field
                  object
                  labelSize={4}
                  mdFieldSize={8}
                  label='Reference'
                  name={`${ATTRIBUTES}.reference`}
                  component={HorizontalFormControl}
                />
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <hr/>
          </Col>
        </Row>
        {auth.authenticated && (
          <Row>
            <Col sm={6}>
              <div className='form-horizontal'>
                <Field
                  inline
                  positionTop
                  labelSize={11}
                  mdFieldSize={1}
                  component={CheckboxUnlabeled}
                  name={`${ATTRIBUTES}.metadata.select_organisation`}
                  onChange={(e) => this.toggleExistingOrganisation(e)}
                  label={`Is the ${isTrackingLink ? 'tracking link for' : 'broker a branch of'} an existing organisation?`}
                  options={[
                    { label: '', value: true }
                  ]}
                />
              </div>
            </Col>
          </Row>
        )}
        {auth.authenticated && (
          <Row>
            <Col sm={6}>
              <div className='form-horizontal'>
                <div className="adjust-organisation-select">
                  {selectOrganisation && (
                    <Field
                      object
                      labelSize={4}
                      mdFieldSize={8}
                      label='Organisation'
                      include={'legal_entity_type'}
                      component={SelectOrganisation}
                      name={`${RELATIONSHIPS}.organisation.data`}
                      filters={[{ filter: 'filter[type]', value: 'Broker' }]}
                      className={selectOrganisation ? 'organisation-section' : ''}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col sm={6}>
            <div className='form-horizontal'>
              {(!auth.authenticated || !selectOrganisation) ? (
                <Field
                  labelSize={4}
                  mdFieldSize={8}
                  label='Organisation Name'
                  disabled={selectOrganisation}
                  component={HorizontalFormControl}
                  name={`${NEW_ORGANISATION}.name`}
                />
              ) : (
                <Field
                  labelSize={4}
                  mdFieldSize={8}
                  label='Branch Name'
                  component={HorizontalFormControl}
                  name={`${ATTRIBUTES}.branch_name`}
                />
              )}
              <Field
                labelSize={4}
                mdFieldSize={8}
                label='Contact Number 1'
                disabled={selectOrganisation}
                component={HorizontalFormControl}
                name={`${NEW_ORGANISATION}.phone1`}
              />
            </div>
          </Col>
          <Col sm={6}>
            <div className='form-horizontal'>
              <Field
                labelSize={4}
                mdFieldSize={8}
                label='Website'
                disabled={selectOrganisation}
                component={HorizontalFormControl}
                name={`${NEW_ORGANISATION}.metadata.website`}
              />

              {!isTrackingLink && (
                <Field
                  label='Fax'
                  labelSize={4}
                  mdFieldSize={8}
                  disabled={selectOrganisation}
                  name={`${NEW_ORGANISATION}.fax`}
                  component={HorizontalFormControl}
                />
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <div className='col-sm-6'>
            <div className='form-horizontal'>
              <span className='registered-title'>
                <Field
                  name={'test'}
                  labelSize={4}
                  component={InfoLabel}
                  label={'Registered Address'}
                />
              </span>

              <div className='adjust-postcode'>
                <div className='adjust-postcode-field'>
                  <PostcodeList
                    adjustWidth
                    labelSize={4}
                    label="Post Code"
                    key={'postcode1'}
                    context={'organisation'}
                    change={this.props.change}
                    disabled={selectOrganisation}
                    name={`${NEW_ORGANISATION}.address.postcode`}
                    value={_.get(formValues, `${NEW_ORGANISATION}.address.postcode`, '')}
                    targetFields={{
                      line1: `${NEW_ORGANISATION}.address.line1`,
                      line2: `${NEW_ORGANISATION}.address.line2`,
                      line3: `${NEW_ORGANISATION}.address.line3`,
                      town: `${NEW_ORGANISATION}.address.town`,
                      county: `${NEW_ORGANISATION}.address.county`,
                      postcode: `${NEW_ORGANISATION}.address.postcode`
                    }}
                  />
                </div>
              </div>
              <AddressFields
                label="Address"
                labelSize={4}
                mdFieldSize={8}
                disabled={selectOrganisation}
                baseName={`${NEW_ORGANISATION}.address`}
              />
            </div>
          </div>
          {isTrackingLink ? <div /> : (
            <div className='col-sm-6'>
              <div className='form-horizontal'>
                <Field
                  inline
                  positionTop
                  labelSize={11}
                  component={CheckboxUnlabeled}
                  name={`data.attributes.metadata.same_address`}
                  label='Is the Branch Address the same as the registered address?'
                  options={[
                    { label: '', value: true }
                  ]}
                />
                <div className="adjust-postcode">
                  <div className="adjust-postcode-field">
                    <Field
                      labelSize={4}
                      mdFieldSize={4}
                      label="Post Code"
                      component={HorizontalFormControl}
                      name={`${ATTRIBUTES}.address.postcode`}
                    />
                  </div>
                </div>
                <AddressFields
                  labelSize={4}
                  mdFieldSize={8}
                  label="Address"
                  disabled={duplicateAddress}
                  baseName={`${ATTRIBUTES}.address`}
                />
              </div>
            </div>
          )}
        </Row>
      </Box>
    )
  }
}

const form = reduxForm()(General)
const mapStateToProps = (state, props) => {
  const values = (formValueSelector(props.form))(state,
    'data',
    'data.attributes.address.postcode',
  )

  return {
    auth: state.auth,
    initialValues: props.resource,
    form: props.form,
    formValues: values,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    organisation: state.organisation,
    product: state.product
  }
}

export default connect(mapStateToProps)(form)
