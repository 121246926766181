import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, FieldArray, formValueSelector } from 'redux-form'
import { Button } from '../../../../common/components'
import TextArea from '../../../content/components/TextArea'
import MedicalDeclarationQuestions from '../MedicalDeclarationQuestions'
import _ from 'lodash'
import displayName from '../../../../helpers/displayName'

const FORM_NAME = 'productDeclarationsForm'

class ProductDeclarationsForm extends Component {
  static propTypes = {
    product: PropTypes.object.isRequired
  }

  render() {
    const { product, submitting, handleSubmit, formValues } = this.props
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <h2 className="resource-name">Declarations</h2>
          <div className="row">
            <label className="control-label col-sm-2">Quotation Declaration</label>
            <div className="col-sm-10">
              {<Field
                name="data.attributes.metadata.quotation_declaration"
                component={TextArea}
              />}
            </div>
          </div>
          <div className="row">
            <h2 className="resource-name">Purchase Declaration</h2>
            {product.data.attributes.metadata.purchase_declaration && Object.keys(product.data.attributes.metadata.purchase_declaration).map((key, i) => (
              <div key={i} className="row">
                <label className="control-label col-sm-2">{displayName(key)}</label>
                <div className="col-sm-10">
                  <Field
                    name={'data.attributes.metadata.purchase_declaration.' + key}
                    component={TextArea}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            <label className="control-label col-sm-2">Medical Declaration</label>
            <div className="col-sm-10">
              <Field
                name="data.attributes.metadata.medical_declaration"
                component={TextArea}
              />
            </div>
          </div>
          <div className="row actions">
            <label className="control-label col-sm-2">Medical Declaration Questions</label>
            <div className="col-sm-10">
              <FieldArray
                name="data.attributes.metadata.medical_declaration_questions"
                items={_.get(formValues, 'data.attributes.metadata.medical_declaration_questions', [])}
                component={MedicalDeclarationQuestions}
              />
            </div>
          </div>
          <div className="row actions">
            <div className="col-xs-12">
              <Button
                type="submit"
                label="Save Changes"
                bsStyle="primary"
                className="pull-right"
                isLoading={submitting}
              />
            </div>
          </div>
        </form>
      </div>
    )
  }
}

const selector = formValueSelector(FORM_NAME)
const mapStateToProps = (state, props) => {
  const values = selector(state,
      'data.id',
      'data.attributes.metadata.medical_declaration_questions'
  )

  return {
    initialValues: props.product,
    formValues: values
  }
}

export default _.flow(
    reduxForm({ form: FORM_NAME }),
    connect(mapStateToProps)
)(ProductDeclarationsForm)
