import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Box, DataTable, SelectBroker, SelectUser } from '../../../../common/components'
import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { FormControl, ControlLabel } from 'react-bootstrap'
import ProductFilter from '../ProductFilter'
import { push } from 'react-router-redux'
import { isOwner, isBroker, authUserId } from '../../../auth/redux/authActions'
import { FormattedDate, FormattedTime } from 'react-intl'

class MtaList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    currentBroker: PropTypes.object,
    auth: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props)

    this.state = {
      brokerId: '',
      userId: isBroker() ? authUserId() : ''
    }
  }

  componentWillMount() {
    this.setState({
      values: {
        ...this.state.values,
        status: this.props.location.query.status ? this.props.location.query.status : ''
      }
    })
  }

  componentDidMount() {
    if (! isBroker()) {
      return
    }
    this.handleUserUpdate(this.props.auth.user.data.id)
  }

  formatDate(cell) {
    return (
      <span><FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/></span>
    )
  }

  handleFilterUpdate(event) {
    this.props.dispatch(applyFilter('mtas', 'filter[product]', event.target.value))
  }

  handleRowClick(row) {
    this.props.dispatch(push('/policies/applications/' + row.id))
  }

  handleSearchUpdate(event) {
    this.props.dispatch(applyFilter('mtas', 'filter[quote.reference]', event.target.value))
  }

  handleBrokerUpdate(value) {
    this.setState({ brokerId: value })
    this.props.dispatch(applyFilter('mtas', 'filter[broker]', value))
  }

  handleUserUpdate(value) {
    this.setState({ userId: value })
    this.props.dispatch(applyFilter('mtas', 'filter[user]', value))
  }

  render() {
    return (
      <div>
        <Box>
          <div className="row">
            <div className="col-sm-3">
              <ControlLabel className="filter-label">Reference</ControlLabel>
              <FormControl onChange={ ::this.handleSearchUpdate } />
            </div>
            {isOwner() && (
              <div className="col-sm-3">
                <ControlLabel className="filter-label">Broker</ControlLabel>
                <SelectBroker
                  labelKeys={['attributes.name']}
                  input={{
                    onChange: ::this.handleBrokerUpdate,
                    value: this.state.brokerId
                  }}
                />
              </div>
            )}
            {(isOwner() || isBroker()) && (
              <div className="col-sm-3">
                <ControlLabel className="filter-label">User</ControlLabel>
                <SelectUser
                  labelKeys={['attributes.first_name', 'attributes.last_name']}
                  input={{
                    onChange: ::this.handleUserUpdate,
                    value: this.state.userId
                  }}/>
              </div>
            )}
            <div className="col-sm-3">
              <ControlLabel className="filter-label">Product</ControlLabel>
              <ProductFilter onChange={::this.handleFilterUpdate} />
            </div>
          </div>
        </Box>

        <Box>
          <DataTable
            source="/policies/applications"
            name="mtas"
            autoFilterType="filter[quote.type]"
            autoFilters={[
              {
                type: 'filter[status]',
                value: this.state.values.status ? this.state.values.status : 'applied,quote,issued'
              },
              { type: 'filter[quote.type]', value: 'mid term adjustment' },
              { type: 'filter[user]', value: this.state.userId }
            ]}
            showTotal={true}
            onRowSelect={this.handleRowClick.bind(this)}
          >
            <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
              ID
            </TableHeaderColumn>

            <TableHeaderColumn dataField="quote_reference" width={'150px'}>
              Reference
            </TableHeaderColumn>

            <TableHeaderColumn dataField="mta_policy_number" width={'200px'}>
              Policy Number
            </TableHeaderColumn>

            <TableHeaderColumn dataField="insured" width={'180px'}>
              Insured
            </TableHeaderColumn>

            <TableHeaderColumn dataField="broker_name" width={'180px'}>
              Broker
            </TableHeaderColumn>

            <TableHeaderColumn dataField="user_name" width={'150px'}>
              User
            </TableHeaderColumn>

            <TableHeaderColumn dataField="status" dataSort={true} width={'100px'}>
              Status
            </TableHeaderColumn>

            <TableHeaderColumn dataField="created_at" dataFormat={this.formatDate} dataSort={true} width={'100px'}>
              Quote Date
            </TableHeaderColumn>

          </DataTable>
        </Box>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps)(MtaList)
