import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, DataTable } from '../../../../common/components'
import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { ControlLabel, FormControl } from 'react-bootstrap'
import { push } from 'react-router-redux'
import { FormattedDate, FormattedTime } from 'react-intl'

export default class Underwriting extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    content: PropTypes.object.isRequire
  };

  constructor(props) {
    super(props)

    this.state=({
      email: 'Email',
      document: 'Document',
      endorsement: 'Endorsement'
    })
  };

  formatDate(cell, row) {
    return (
        <span><FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/></span>
    )
  }

  handleRowClick(row) {
    this.props.dispatch(push('/admin/content/' + row.id))
  }

  handleFilterUpdate(event) {
    this.props.dispatch(applyFilter('content', 'filter[template.type]', event.target.value))
  }

  handleSearchUpdate(event) {
    this.props.dispatch(applyFilter('content', 'filter[name]', event.target.value))
  }

  render() {
    return (
      <div>
        <Box>
          <div className="row">
            <div className="col-sm-5">
              <ControlLabel className="filter-label">Search</ControlLabel>
              <FormControl onChange={::this.handleSearchUpdate }/>
            </div>
            <div className="col-sm-3">
              <ControlLabel className="filter-label">Type</ControlLabel>
              <FormControl
                componentClass="select"
                type="select"
                onChange={::this.handleFilterUpdate}
                placeholder="select"
              >
                <option value="">All</option>
                {Object.keys(this.state).map((key, i) => {
                  return (<option key={i} value={key}>{this.state[key]}</option>)
                })}
              </FormControl>
            </div>
          </div>

          <DataTable
            source="/contents"
            name="content"
            showTotal={true}
            onRowSelect={this.handleRowClick.bind(this)}>

            <TableHeaderColumn dataField="id" isKey={true} hidden={true}>ID</TableHeaderColumn>
            <TableHeaderColumn dataField="name" dataSort={true}>Name</TableHeaderColumn>
            <TableHeaderColumn dataField="content_type" dataSort={true} width={'180px'}>Type</TableHeaderColumn>
            <TableHeaderColumn dataField="created_at" dataSort={true} dataFormat={this.formatDate}
                               width={'180px'}>Created</TableHeaderColumn>
          </DataTable>

          <div className="row actions">
            <Button
              link={true}
              to="/admin/content/new"
              className="pull-right"
              bsStyle="primary"
              label="New Content"
            />
          </div>
        </Box>
      </div>
    )
  }
}
