import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getOrganisation } from '../redux/organisationActions'
import { Button, Loader } from '../../../common/components'
import InternalContainer from '../../layout/containers/Internal'
import OrganisationFormContainer from './OrganisationForm'

class OrganisationEditContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    organisation: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired
  }

  componentWillMount() {
    this.props.dispatch(getOrganisation(this.props.params.id))
  }

  render() {
    const resource = this.props.organisation.resources[this.props.params.id]
    const buttons = (
        <Button
            bsStyle="default"
            label="Back"
            isBackButton={true}
            leftIcon="caret-left"
        />
    )

    return (
        <InternalContainer title="Organisation" buttons={buttons}>
          {resource ? (
                  <OrganisationFormContainer {...this.props} resource={resource}/>
              ) : (
                  <Loader />
              )}
        </InternalContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    organisation: state.organisation
  }
}

export default connect(mapStateToProps)(OrganisationEditContainer)
