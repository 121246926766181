import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import flow from 'lodash/flow'
import { Button, Icon, HorizontalFormControl } from '../../../../common/components/index'
import './OptionsForm.scss'

class SubOptionsForm extends Component {
  static propTypes = {
    fields: PropTypes.object.isRequired
  }

  render() {
    const { fields } = this.props
    return (
        <div className='admin-cover-sub-options'>
          <div className='list'>
            {fields.map((options, index) => {
              return (
                <div key={index} className='row'>
                  <div className='col-xs-12'>
                    <span
                      className='remove-cover-options pull-right'
                      onClick={() => fields.remove(index)}>
                      <Icon name='times' />
                    </span>
                    {<label className="control-label">{'Sub Cover Option ' + (index + 1)}</label>}
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <Field
                        label="Name"
                        name={options + '.value'}
                        type="textarea"
                        component={HorizontalFormControl}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <Field
                        label="Description"
                        name={options + '.label'}
                        type="textarea"
                        component={HorizontalFormControl}
                      />
                    </div>
                  </div>
                </div>
              )
            })}
            <div>
              <div className="row">
                {/*<div className="col-xs-2 col-md-2 col-lg-2"/>*/}
                <div className="col-xs-4 col-md-3 col-lg-2 add-sub-options-button">
                  <Button type="button" handleClick={() => fields.push({ type: 'header' })}>Add Sub Options</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
  }
}

export default flow(
  connect()
)(SubOptionsForm)
