import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button } from '../../../common/components'
import { hasPermission } from '../../auth/redux/authActions'
import InternalContainer from '../../layout/containers/Internal'
import UninvitedRenewalList from '../components/UninvitedRenewalList/UninvitedRenewalList'

class UninvitedRenewalListContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
  }

  render() {
    const buttons = (hasPermission('policy.renew') ? (
        <Button
          className="pull-right"
          bsStyle="primary"
          label="invite selected"
        />
      ) : (''))

    return (
      <InternalContainer title="Uninvited Renewals">
        <UninvitedRenewalList {...this.props}/>
      </InternalContainer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps)(UninvitedRenewalListContainer)
