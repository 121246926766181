import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import BenefitsModal from '../BenefitsModal'
import PremiumBreakdown from '../PremiumBreakdown'
import { Icon } from '../../../../common/components'
import './styles.scss'

class SelectedQuotes extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    currentProduct: PropTypes.object.isRequired,
    selectedSchemes: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    declaration: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image: PropTypes.string,
  }

  render() {
    const { product, currentProduct, selectedSchemes } = this.props
    const selectedScheme = selectedSchemes && selectedSchemes.length === 1 && selectedSchemes[0].id ? product.quotes[selectedSchemes[0].id] : 0
    const taxType = currentProduct.data.attributes.metadata.tax_type

    return (
      <div className="selected-quotes">
        {(selectedSchemes.length > 0) && (
          <div className="quote-actions">
            <div className="row">
              <div className="col-xs-11 col-sm-12 scheme-for-purchase">
                <div className="row">
                  <div className="col-xs-12">
                    {selectedScheme ? (
                      <div className="row">
                        <div className="col-md-7">
                          <h5>You have selected:</h5>
                          <p className="scheme-name">{selectedScheme.meta.scheme.name}</p>
                          <div className="wordings-for-purchase">
                            <span className="info">
                              <BenefitsModal
                                scheme={selectedScheme.meta.scheme}
                                benefits={selectedScheme.meta.benefits}
                                label="View Full Benefits"
                              />
                            </span>
                            <span
                              className="info"
                              onClick={() => {
                                window.open(selectedScheme.meta.scheme.documents['Insurance Product Information Document'])
                              }}
                            >
                              <Icon name="info-circle"/> View IPID
                            </span>
                            <span
                              className="info"
                              onClick={() => {
                                window.open(selectedScheme.meta.scheme.documents['Policy Wording'])
                              }}
                            >
                              <Icon name="info-circle"/> View Policy wording
                            </span>
                          </div>
                        </div>
                        <div className="col-md-5 text-right">
                          <PremiumBreakdown premium={selectedScheme.meta} block={true} showIpt={true} taxType={taxType}/>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <h5>Your chosen premiums</h5>
                        <ul className="text-left selected-premiums-list">
                          {selectedSchemes.map((scheme, i) => {
                            const selection = scheme.id ? product.quotes[scheme.id] : null
                            if (selection) {
                              return (
                                <li key={i}>
                                  {selection.meta.scheme.name}
                                  <span className='selected-quote-premium'>
                                  <PremiumBreakdown premium={selection.meta} className='small-premium' showIpt={true} taxType={taxType}/>
                                </span>
                                </li>
                              )
                            }
                          })}
                        </ul>

                        <span className="selection-note">
                        <strong>Please note:</strong> you must select 1 scheme only from the panel in order
                        to issue a policy.
                      </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default connect()(SelectedQuotes)
