import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import SideBarContainer from './SideBarContainer'
import Icon from '../../../common/components/Icon/Icon'



class BodyContainer extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    children: PropTypes.object,
    location: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      show: true
    }
    this.toggleMenu = this.toggleMenu.bind(this)
  }

  toggleMenu() {
    this.setState(prevState => ({
      show: !prevState.show
    }))
  }

  render() {
    const { show } = this.state

    if (this.props.auth.authenticated) {
      return (
        <>
          <div className={show ? 'width-80' : 'width-95' }>
              {React.cloneElement(this.props.children, { key: this.props.location.pathname })}
          </div>

          <div className={show ? 'width-20' : 'width-5' }>
            <div className={show ? 'collapsed-sidebar-hided' : 'collapsed-sidebar' } >
              <span className="collapse-btn" onClick={this.toggleMenu}>
                {show ? (<Icon className="collapse-btn" name="chevron-circle-left"/>) : (<Icon name="chevron-circle-right"/>) }
              </span>
              {show ? '' : (
                <div className="collapsed-menu">
                  <Link to="/dashboard">
                    <Icon name="home"/>
                  </Link>
                </div>
              ) }
            </div>
            {show ? (<SideBarContainer {...this.props} />) : '' }
          </div>
        </>
      )
    }
    return (
      <div className="responsive-page-full-width">
        <div>
          {React.cloneElement(this.props.children, { key: this.props.location.pathname })}
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    layout: state.layout
  }
}

export default connect(mapStateToProps)(BodyContainer)
