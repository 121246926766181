import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import InternalContainer from '../../layout/containers/Internal'
import CustomerList from '../components/CustomerList/CustomerList'
import { clearData } from '../redux/customerActions'

class CustomerListContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  }

  render() {
    return (
        <InternalContainer title="Customers">
          <CustomerList {...this.props} />
        </InternalContainer>
    )
  }
}

export default connect()(CustomerListContainer)
