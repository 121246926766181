import * as actions from './organisationReducer';
import Axios from 'axios';
import { push } from 'react-router-redux';
import { toastr } from 'react-redux-toastr';
import queryString from '../../../helpers/queryString';
import handleErrorResponse from '../../../helpers/handleErrorResponse';
import { forEach } from 'lodash';

export const defaultIncludes = ['parent', 'principal', 'organisation_type', 'legal_entity_type', 'users', 'primary_contacts', 'accounts_contacts', 'network.standard_commission', 'insurers.authorised_users'];

export function isSubmitting(status) {
  return {
    type: actions.IS_SUBMITTING,
    status,
  };
}

export function isReactivating(status) {
  return {
    type: actions.IS_REACTIVATING,
    status,
  };
}

export function isDeactivating(status) {
  return {
    type: actions.IS_DEACTIVATING,
    status,
  };
}

export const getOrganisations = (includes = defaultIncludes) => async (dispatch) => {
  let endpoint = 'organisations';
  endpoint += queryString(includes);

  try {
    const response = await Axios.get(endpoint);
    const data = response.data.data;
    let organisations = {};
    forEach(data, (v) => {
      organisations[v.id] = v;
    });
    dispatch({
      type: actions.SET_ORGANISATIONS,
      data: organisations,
    });
  } catch (e) {
    console.error(e);
  }
};

export function getOrganisation(id, includes = defaultIncludes) {
  return (dispatch) => {
    let endpoint = 'organisations/' + id;
    endpoint += queryString(includes);

    return Axios.get(endpoint).then(response => {
      dispatch({
        type: actions.SET_ORGANISATION,
        data: response.data,
      });
      return true;
    }).catch(error => {
      console.error(error);
    });
  };
}

export function createOrganisation(resource) {
  return dispatch => {
    return Axios.post('organisations', resource).then(response => {
      dispatch(push('/admin/organisations/' + response.data.data.id));
      return true;
    }).catch(error => {
      handleErrorResponse(error, 'There was an error creating the organisation');
    });
  };
}

export function updateOrganisation(resource, includes = defaultIncludes) {
  return dispatch => {
    dispatch(isSubmitting(true));
    const id = resource.data.id;
    let endpoint = 'organisations/' + id;
    endpoint += queryString(includes);

    return Axios.patch(endpoint, resource).then(response => {
      dispatch({
        type: actions.SET_ORGANISATION,
        data: response.data,
      });
      dispatch(isSubmitting(false));
      dispatch(push('/admin/organisations/' + id));
      return true;
    }).catch(error => {
      dispatch(isSubmitting(false));
      handleErrorResponse(error, 'There was an error updating the organisation');
    });
  };
}

export function updateInsurer(insurer) {
  return dispatch => {
    const id = insurer.data.id;
    let endpoint = 'organisations/insurers/' + id;

    return Axios.patch(endpoint, insurer).then(() => {
      toastr.success('Success', 'Insurer ' + insurer.data.id + ' has been updated');
      return true;
    }).catch(error => {
      dispatch(isSubmitting(false));
      handleErrorResponse(error, 'There was an error updating the organisation');
    });
  };
}

export function reactivateOrganisation(id) {
  return dispatch => {
    dispatch(isReactivating(true));
    return Axios.post('organisations/' + id + ':reactivate').then(() => {
      dispatch(isReactivating(false));
      dispatch(getOrganisation(id));
      toastr.success('User Reactivated', 'Organisation has been successfully reactivated');
      return true;
    }).catch(error => {
      dispatch(isReactivating(false));
      handleErrorResponse(error, 'There was an error reactivating organisation');
    });
  };
}

export function deactivateOrganisation(id) {
  return dispatch => {
    dispatch(isDeactivating(true));
    return Axios.post('organisations/' + id + ':deactivate').then(() => {
      dispatch(isDeactivating(false));
      dispatch(getOrganisation(id));
      toastr.success('User Deactivated', 'Organisation has been successfully deactivated');
      return true;
    }).catch(error => {
      dispatch(isReactivating(false));
      handleErrorResponse(error, 'There was an error deactivating organisation');
    });
  };
}

export function getNetwork(id, cb) {
  return (dispatch) => {
    let endpoint = 'brokers/networks/' + id;
    endpoint += queryString(['standard_commission']);

    return Axios.get(endpoint).then(response => {
      cb(response.data);
      return true;
    }).catch(error => {
      console.error(error);
    });
  };
}

export function updateNetwork(id, network) {
  return (dispatch) => {
    dispatch(isSubmitting(true));
    let endpoint = 'brokers/networks/' + id;
    return Axios.patch(endpoint, network).then(() => {
      toastr.success('Success', 'Network ' + id + ' has been updated');
      return true;
    }).catch(error => {
      dispatch(isSubmitting(false));
      handleErrorResponse(error, 'There was an error updating the network');
    });
  };
}

export function assignUserToOrganisation(id, values) {
  return () => {
    return Axios.post('organisations/' + id + ':add-user', values).then(() => {
      toastr.success('Success', 'User has been successfully assigned to Organisation');
      return true;
    }).catch(error => {
      handleErrorResponse(error, 'Failed to assign User to Organisation');
    });
  };
}
