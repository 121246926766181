import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ProCycleForm from '../components/ProCycleForm'
import { getQuotes, clearData } from '../redux/productActions'
import _ from 'lodash'

class ProCycleFormContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    screening: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    currentProduct: PropTypes.object.isRequired,
    application: PropTypes.object,
    mta: PropTypes.bool.isRequired
  };

  /**
   * Clean out the data when the component unmounts
   */
  componentWillUnmount() {
    this.props.dispatch(clearData())
  }

  handleSubmit(resource) {
    return this.props.dispatch(getQuotes(this.props.product.schemes, resource))
  }

  render() {
    const { product, currentProduct, screening, application, mta } = this.props
    let adults = [{
      title: '',
      first_name: '',
      last_name: '',
      dob: '',
      email: '',
      phone1: '',
      phone2: '',
      conditions_covered: true,
      screening: '',
      screening_id: ''
    }]

    let children = []

    if(application) {
      adults = _.get(application, 'data.attributes.metadata.adults', adults)
      children = _.get(application, 'data.attributes.metadata.children', children)
    }

    const medicalQuestions = []
    _.get(currentProduct, 'data.attributes.metadata.medical_declaration_questions', []).map((declaration, key) => {
      medicalQuestions.push({ question: declaration.question, answer: '' })
    })

    const resource = {
      data: {
        type: 'application',
        attributes: {
          quote_reference: product.nextQuoteReference,
          quote_type: '',
          metadata: {
            scheme_type: '',
            traveller_group: '',
            region: '',
            destinations: [],
            max_trip_duration: '',
            start_date: '',
            end_date: '',
            referred_activities: '',
            activities: '',
            occupations: '',
            adults: adults,
            children: children,
            options: {},
            subjectivities: [],
            medical_declaration_questions: medicalQuestions,
            overrides: {}
          }
        },
        relationships: {
          schemes: {
            data: []
          }
        }
      }
    }

    if (application && application.data.attributes.quote_type === 'renewal') {
      _.set(application, 'data.attributes.metadata.medical_declaration_questions', medicalQuestions)
    }

    if (currentProduct.data.attributes.metadata && currentProduct.data.attributes.metadata.subjectivities) {
      resource.data.attributes.metadata.subjectivities = currentProduct.data.attributes.metadata.subjectivities
    }

    return (
      <ProCycleForm
        resource={application ? application : resource}
        screening={screening}
        product={product}
        mta={mta}
        currentProduct={currentProduct}
        handleSave={::this.handleSubmit}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    product: state.product,
    screening: state.screening
  }
}

export default connect(mapStateToProps)(ProCycleFormContainer)
