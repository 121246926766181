import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { InfoLabel, Box, Button } from '../../../../common/components'
import { hasPermission } from '../../../auth/redux/authActions'

export default class UserDetails extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    resource: PropTypes.object.isRequired
  };

  render() {
    const resource = this.props.resource.data.attributes
    const organisationRoles = this.props.user.organisationRoles.data.meta

    return (
      <Box>
        <h2 className="resource-name">{resource.name}</h2>

        {organisationRoles && organisationRoles.map((organisationRole, i) => {
          let roles = ''
          organisationRole.roles.data.map((relation, i) => {
            if (i) {
              roles += ', '
            }
            roles += relation.attributes.name
          })

          return (
            <div className="row" key={i}>
              <div className="col-xs-12">
                <div className="form-horizontal">
                  <h3>{ organisationRole.organisation.data.attributes.name }</h3>
                  <InfoLabel label="Roles" value={ roles } labelSize={1}/>

                  { hasPermission('user.edit') && (
                    <div className="row">
                      <div className="col-xs-12">
                        <Button
                            link={true}
                            to={'/admin/users/' + this.props.resource.data.id + '/organisations/' + organisationRole.organisation.data.id + '/edit'}
                            bsStyle="primary"
                            className="pull-right"
                            rightIcon="pencil-square-o">Edit Roles</Button>
                      </div>
                    </div>
                  )}
                </div>

                <div className="hr"/>
              </div>
            </div>
          )
        })}
      </Box>
    )
  }
}
