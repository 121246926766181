import React, { Component } from 'react'
import CampaignNewForm from '../components/CampaignNewForm/CampaignNewForm'
import { connect } from 'react-redux'
import { createCampaign } from '../redux/campaignActions'


/***********************************
Container for the New Campaigns Form
************************************/

class CampaignContainer extends Component {
  handleSubmit(resource) {
    return this.props.dispatch(createCampaign(resource))
  }

  render() {
    return (
      <CampaignNewForm
        {...this.props}
        saving={this.props.campaign.isSaving}
        onSubmit={this.handleSubmit.bind(this)}
      />
    )
  }
}

function mapStateToProps(state) {
  return {
    campaign: state.campaign
  }
}

export default connect(mapStateToProps)(CampaignContainer)
