import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { setAnswer } from '../../redux/screeningActions'
import { Button } from '../../../../common/components'
import { Col, ControlLabel, FormControl, FormGroup, InputGroup } from 'react-bootstrap'

export default class BmiQuestion extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    screening: PropTypes.object.isRequired,
    condition: PropTypes.object.isRequired,
    question: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      dirty: false,
      isMetricSystem: false,
      height1: '',
      height2: '',
      weight1: '',
      weight2: ''
    }
  }

  componentWillReceiveProps(props) {
    if (!this.state.dirty) {
      this.setState({
        dirty: false,
        ...props.question.attributes.data,
      })
    }
  }

  render() {
    const { screening, condition, question, dispatch } = this.props
    const { isMetricSystem, height1, height2, weight1, weight2 } = this.state

    return (
      <div className="single-choice-question">
        <div className="col-xs-12">
          <div className="form-group">
            <label className="control-label">Please enter your height and weight.</label>
            {question.attributes.info ? (
              <p className="text-info text-justify" style={{ 'font-size': '14px' }}>
                {question.attributes.info}
                <br/>
              </p>
            ) : ''}
          </div>

          <div className="row">
            <div className="row">
              <div className="col-sm-12">
                <div className={'radio-container radio-inline'}>
                  <div className={'form-radio '}>
                    <input type="checkbox"
                           id={'metric-bmi-choice'}
                           checked={isMetricSystem}
                           onChange={() => {
                             this.setState({
                               isMetricSystem: true,
                               height1: '',
                               height2: '',
                               weight1: '',
                               weight2: ''
                             })
                           }}
                    />
                    <label htmlFor={'metric-bmi-choice'}/>
                  </div>
                  <label className="radio-label" htmlFor={'metric-bmi-choice'} style={{
                    width: 150,
                    paddingLeft: 10
                  }}>Metric measure?</label>
                </div>
                <div className={'radio-container radio-inline'}>
                  <div className={'form-radio radio-inline'}>
                    <input type="checkbox"
                           id={'imperial-bmi-choice'}
                           checked={!isMetricSystem}
                           onChange={() => {
                             this.setState({
                               isMetricSystem: false,
                               height1: '',
                               height2: '',
                               weight1: '',
                               weight2: ''
                             })
                           }}
                    />
                    <label htmlFor={'imperial-bmi-choice'}/>
                  </div>
                  <label className="radio-label" htmlFor={'imperial-bmi-choice'} style={{
                    width: 150,
                    paddingLeft: 10
                  }}>Imperial measure?</label>
                </div>
              </div>
            </div>
          </div>

          {isMetricSystem ? (
            <FormGroup controlId="formHeight">
              <Col componentClass={ControlLabel} sm={2}>
                Height
              </Col>
              <Col sm={2}>
                <InputGroup>
                  <FormControl type="number" value={height1} onChange={::this.handleOnChange('height1')} style={{ width: 60 }}/>
                  <InputGroup.Addon>cm</InputGroup.Addon>
                </InputGroup>
              </Col>
            </FormGroup>
          ) : (
            <FormGroup controlId="formHeight">
              <Col componentClass={ControlLabel} sm={2}>
                Height
              </Col>
              <Col sm={2}>
                <InputGroup>
                  <FormControl type="number" value={height1} onChange={::this.handleOnChange('height1')}/>
                  <InputGroup.Addon>ft</InputGroup.Addon>
                </InputGroup>
              </Col>
              <Col sm={2}>
                <InputGroup>
                  <FormControl type="number" value={height2} onChange={::this.handleOnChange('height2')}/>
                  <InputGroup.Addon>in</InputGroup.Addon>
                </InputGroup>
              </Col>
            </FormGroup>
          )}

          {isMetricSystem ? (
            <FormGroup controlId="formWeight">
              <Col componentClass={ControlLabel} sm={2}>
                Weight
              </Col>
              <Col sm={2}>
                <InputGroup>
                  <FormControl type="number" value={weight1} onChange={::this.handleOnChange('weight1')} style={{ width: 60 }}/>
                  <InputGroup.Addon>kg</InputGroup.Addon>
                </InputGroup>
              </Col>
            </FormGroup>
          ) : (
            <FormGroup controlId="formWeight">
              <Col componentClass={ControlLabel} sm={2}>
                Weight
              </Col>
              <Col sm={2}>
                <InputGroup>
                  <FormControl type="number" value={weight1} onChange={::this.handleOnChange('weight1')}/>
                  <InputGroup.Addon>st</InputGroup.Addon>
                </InputGroup>
              </Col>
              <Col sm={2}>
                <InputGroup>
                  <FormControl type="number" value={weight2} onChange={::this.handleOnChange('weight2')}/>
                  <InputGroup.Addon>lb</InputGroup.Addon>
                </InputGroup>
              </Col>
            </FormGroup>
          )}
        </div>

        <Button
          bsStyle="primary"
          isLoading={screening.isSubmitting}
          label="Set Height and Weight"
          handleClick={() => dispatch(setAnswer(condition, question, this.state, true))}
        />
      </div>
    )
  }

  handleOnChange(value) {
    return (event) => {
      this.setState({
        dirty: true,
        [value]: event.target.value
      })
    }
  }
}
