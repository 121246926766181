import React from 'react'

export const ledger = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95 95">
    <title>Icons</title>
    <path d="M77,49.57H49.5a2.52,2.52,0,0,0-2.5,2.5v30a2.52,2.52,0,0,0,2.5,2.5H77a2.52,2.52,0,0,0,2.5-2.5v-30A2.52,2.52,0,0,0,77,49.57ZM52,82.11a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,52,82.11Zm0-7.51a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,52,74.6Zm0-7.51a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,52,67.09Zm7.51,15a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,59.51,82.11Zm0-7.51A2.5,2.5,0,1,1,62,72.1,2.5,2.5,0,0,1,59.51,74.6Zm0-7.51a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,59.51,67.09Zm7.51,15a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,67,82.11Zm0-7.51a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,67,74.6Zm0-7.51a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,67,67.09ZM77,79.61a2.5,2.5,0,0,1-5,0V72.1a2.5,2.5,0,0,1,5,0Zm-2.5-12.52a2.5,2.5,0,1,1,2.5-2.5A2.5,2.5,0,0,1,74.53,67.09ZM77,58.33a1.26,1.26,0,0,1-1.25,1.25h-25a1.26,1.26,0,0,1-1.25-1.25v-5a1.26,1.26,0,0,1,1.25-1.25h25A1.26,1.26,0,0,1,77,53.32Z"/>
    <path d="M47,63.84H21.49V15.42H45.71V28.53a3,3,0,0,0,3,3H61.85v18h4v-21a8.46,8.46,0,0,0-2.14-5.17L53.9,13.53a8.46,8.46,0,0,0-5.17-2.14H20.48a3,3,0,0,0-3,3V64.85a3,3,0,0,0,3,3H47Zm2.74-48.17a4,4,0,0,1,1.29.69l9.87,9.87a4,4,0,0,1,.69,1.29H49.74Z"/>
    <rect x="29.56" y="43.67" width="24.21" height="4.04" rx="1.01" ry="1.01"/>
    <rect x="29.56" y="35.59" width="24.21" height="4.04" rx="1.01" ry="1.01"/>
    <path d="M29.56,54.76a1,1,0,0,0,1,1H47v-3.7a2.42,2.42,0,0,1,0-.34H30.57a1,1,0,0,0-1,1Z"/></svg>
)

export default ledger


