import React from 'react';
import { map, filter } from 'lodash';
import { connect } from 'react-redux';
import { actions } from '../redux/reducer';
import { bindActionCreators } from 'redux';
import { Tab, Tabs } from 'react-bootstrap';
import FaqsArray from './components/FaqsArray';
import { Box } from '../../../common/components';
import { isSystemAdmin } from '../../auth/redux/authActions';
import InternalContainer from '../../layout/containers/Internal';

class Faqs extends React.PureComponent {
  componentDidMount() {
    const { actions } = this.props;

    actions.getContent();
    actions.getTemplate('page');
  }

  render() {
    const { helpCentre } = this.props;
    const loading = helpCentre.loading;
    const content = helpCentre.content;
    const hasPermission = isSystemAdmin();
    const submitting = helpCentre.submitting;
    const categories = helpCentre.categories;

    return (
      <InternalContainer title={'FAQs'} buttons={null}>
        <Tabs defaultActiveKey={1} id="help-centre-tabs" unmountOnExit={true}>
          {map(helpCentre.categories, (item, key) => {
            const contentFiltered = filter(content, (c) => c.category === item.value);

            if (contentFiltered) {
              return (
                <Tab key={key} eventKey={key + 1} title={item.label}>
                  <Box className="box-shadow">
                    <FaqsArray
                      loading={loading}
                      category={item.value}
                      categories={categories}
                      submitting={submitting}
                      content={contentFiltered}
                      onSubmit={this._handleSubmit}
                      onAddNewFaq={this._handleAdd}
                      hasPermission={hasPermission}
                    />
                  </Box>
                </Tab>
              );
            }
          })}
        </Tabs>
      </InternalContainer>
    );
  }

  _handleSubmit = (formValues) => {
    const { actions } = this.props;

    if (!formValues.data.id) {
      actions.createNewFaqContent(formValues);
    }

    if (formValues.data.id) {
      actions.updateFaqContent(formValues);
    }
  };

  _handleAdd = (category) => {
    const { actions } = this.props;
    actions.addNewContent(category);
  };
}

export default connect(
  (state) => ({ helpCentre: state.helpCentre, auth: state.auth }),
  (dispatch) => ({ actions: bindActionCreators(actions, dispatch) }),
)(Faqs);
