import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box, DataTable } from '../../../../common/components'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { FormattedDate, FormattedTime } from 'react-intl'

export default class PolicyAccountsList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    reference: PropTypes.string.isRequired
  };

  formatDate(cell) {
    return (
        <span>
          <FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/>
        </span>
    )
  }

  render() {
    const { reference } = this.props

    return (
      <div>
        <Box>
          <h2 className="resource-name">{reference}</h2>

          <DataTable
              source={'/accounts/ledger-entries'}
              autoFilters={[
                { type: 'filter[policy]', value: this.props.resource.data.id },
                { type: 'page[limit]', value: 100 },
              ]}
              name="accounts"
              showTotal={true}
          >
            <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
              ID
            </TableHeaderColumn>

            <TableHeaderColumn dataField="created_at" dataSort={true} width={'100px'} dataFormat={::this.formatDate}>
              Transacted at
            </TableHeaderColumn>

            <TableHeaderColumn dataField="account_name" width={'175px'}>
              Account
            </TableHeaderColumn>

            <TableHeaderColumn dataField="description" width={'300px'} dataSort={true}>
              Description
            </TableHeaderColumn>

            <TableHeaderColumn dataField="formatted_amount" width={'100px'} dataAlign="right">
              Amount
            </TableHeaderColumn>

          </DataTable>
        </Box>
      </div>
    )
  }
}

