import React from 'react';
import { map } from 'lodash';
import { Col, Row } from 'react-bootstrap';
import CollapsibleField from '../CollapsibleField';
import { Loader } from '../../../../../common/components';
import Button from '../../../../../common/components/Button/Button';

class FaqsArray extends React.PureComponent {
  renderDefinitionFields = () => {
    const { content, onSubmit, hasPermission, submitting, categories } = this.props;

    return map(content, (faq, key) => {
      return (
        <Row key={key}>
          <Col sm={12}>
            <CollapsibleField
              faq={faq}
              fieldKey={key}
              onSubmit={onSubmit}
              submitting={submitting}
              categories={categories}
              isSystemAdmin={hasPermission}
              {...this.props}
            />
          </Col>
        </Row>
      );
    });
  };

  render() {
    const { content, hasPermission, loading } = this.props;
    const hasContent = content.length > 0;

    if (loading) {
      return (
        <div className="new-box">
          <Loader />
        </div>
      );
    }

    return (
      <div className="new-box">
        <div className="animated fadeIn">
          {hasContent && this.renderDefinitionFields()}
          {!hasContent && <h4>No content found</h4>}
        </div>
        <div className="divider margin-bottom" />
        {hasPermission && (
          <Row>
            <Col sm={12}>
              <Button type="button" bsStyle="primary" handleClick={this._handleAdd}>
                Add New Question
              </Button>
            </Col>
          </Row>
        )}
      </div>
    );
  }

  _handleAdd = () => {
    const { category, onAddNewFaq } = this.props;
    onAddNewFaq(category);
  };
}

export default FaqsArray;
