import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormControl, FormGroup } from 'react-bootstrap'
import Markdown from 'react-markdown'
import { Collapse } from 'react-bootstrap'

import { Button } from '../../../../common/components'

import './styles.scss'

const FormControlStatic = FormControl.Static

export default class DeclarationQuestions extends Component {
  static propTypes = {
    declaration: PropTypes.string,
    questions: PropTypes.array.isRequired
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      openMedicalDeclaration: false
    };
  }

  render() {
    const { declaration, questions } = this.props

    if (!questions.length) {
      return null;
    }

    return (
        <div>
            <Button handleClick={() => this.setState({ openMedicalDeclaration: !this.state.openMedicalDeclaration })} rightIcon="plus">
              View Medical Declaration
            </Button>
            <Collapse in={this.state.openMedicalDeclaration}>
              <div className="col-sm-12">
                <div>
                  {declaration && (
                    <Markdown source={declaration} />
                  )}

                  {questions.map((declaration, i) => (
                    <FormGroup key={i} className="infolabel-wrapper">
                      {declaration.type === 'question' ? (
                        <FormControlStatic>
                          {declaration.content} - <strong>{declaration.answer === 'true' || declaration.answer === true ? 'Yes' : 'No' }</strong>
                        </FormControlStatic>
                      ) : (
                        <FormControlStatic>
                          <strong>{declaration.content}</strong>
                        </FormControlStatic>
                      )}
                    </FormGroup>
                  ))}
                </div>
              </div>
            </Collapse>
        </div>
    )
  }
}
