import React from 'react'
import { Field } from 'redux-form'
import { Col, Row } from 'react-bootstrap'
import Box from '../../../../../../common/components/Box/Box'
import Checkbox from '../../../../../../common/components/ReduxFormField/Checkbox'

class Declaration extends React.Component {
  render() {
    return (
      <Box>
        <Row>
          <Col sm={12} className="margin-top">
            <h4 className='page-title'>Declaration</h4>
            <hr/>
            <div className="margin-bottom">
              I/We apply for an agency with Arch Insurance (UK) Limited and declare
              that the information given above is correct and that nothing has been
              withheld which might influence the acceptance of this application.
            </div>

            <Field
              inline
              positionTop
              labelSize={0}
              component={Checkbox}
              checkboxValue={true}
              label='I/We agree that:'
              name={'data.attributes.metadata.declaration'}
            />
            <ul>
              <li>
                A client's account will be maintained for premiums payable to
                the Company and such premiums are held in trust for the Company.
              </li>
              <li>
                I/We will advise the Company of any alterations in the above information.
              </li>
            </ul>
          </Col>
        </Row>
      </Box>
    )
  }
}

export default Declaration
