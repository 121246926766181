import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux';
import {
  Button,
  Modal,
  SelectOrganisation,
  SelectBroker,
} from '../../../../common/components';
import { getOrganisations } from '../../../organisation/redux/organisationActions';
import { push } from 'react-router-redux';
import { get, filter } from 'lodash';
import { Form } from 'react-bootstrap';

class CreateUserButton extends Component {
  state = {
    orgId: '',
    brokerId: '',
    orgType: '',
  };

  componentWillMount() {
    const { getOrganisations } = this.props.actions;
    getOrganisations();
  }

  getResourceFromSelects(select, id) {
    const { selects,  } = this.props;

    const filteredAsync = filter(get(selects, select, []), (o) => {
      return o.id === id;
    })[0];

    return filteredAsync;
  }

  handleSelectOrganisation(id) {

    const organisation = this.getResourceFromSelects(
      'selects.organisation.data',
      id,
    );

    this.setState({
      orgId: id,
      orgType: get(organisation, 'attributes.organisation_type_name'),
    });
  }

  handleSelectBroker(id) {
    this.getResourceFromSelects('selects.brokerselect-broker-new-user.data', id);
    this.setState({ brokerId: id });
  }

  handleSubmit = () => {
    const { dispatch } = this.props;
    const { orgId, orgType, brokerId } = this.state;
    let path = '/admin/users/new/' + orgId;

    if (orgType === 'Broker') {
      path += '/' + brokerId;
    }
    path += '?organisationTypeName=' + orgType;

    dispatch(closeModal('CreateUserButton'));
    dispatch(push(path));
  };

  handleOpen = () => {
    const { dispatch } = this.props;
    dispatch(openModal('CreateUserButton'));
  };

  render() {
    const { orgId, orgType, brokerId } = this.state;

    const modalFooter = (
      <div>
        {this.state.orgId && (
          <Button
            type="submit"
            bsStyle="primary"
            className="pull-right"
            handleClick={this.handleSubmit}
            label="Create User"
          />
        )}
      </div>
    );

    return (
      <div>
        <Button
          className="pull-right"
          {...this.props}
          bsStyle="primary"
          rightIcon="user"
          label="Add a New User"
          handleClick={this.handleOpen}
        />

        <Modal
          name={'CreateUserButton'}
          title="Add a New User"
          close
          footer={modalFooter}
        >
          <Form horizontal>
            <SelectOrganisation
              async
              label={'Organisation'}
              id={'select-organisation-new-user'}
              key={'select-organisation-new-user'}
              labelSize={3}
              input={{
                onChange: ::this.handleSelectOrganisation,
                value: orgId,
              }}
              clearable={false}
            />
            {orgType === 'Broker' && (
              <SelectBroker
                async
                label={'Broker'}
                labelSize={3}
                id={'select-broker-new-user'}
                byOrganisation={orgId}
                key={'selectBroker' + orgId}
                input={{
                  onChange: ::this.handleSelectBroker,
                  value: brokerId,
                }}
              />
            )}
          </Form>
        </Modal>
      </div>
    );
  }
}

CreateUserButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
  organisation: PropTypes.object,
};

const mapDispatch = (dispatch) => ({
  actions: bindActionCreators({ getOrganisations }, dispatch),
});

const mapStateToProps = (state) => ({
  organisation: state.organisation,
  selects: state.selectMenus,
});

export default connect(
  mapStateToProps,
  mapDispatch,
)(CreateUserButton);
