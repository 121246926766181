export const INIT_FAQS = 'socrates-online/faq/INIT_FAQS';
export const OPEN_FAQ = 'socrates-online/faq/OPEN_FAQ';
export const CLOSE_FAQ = 'socrates-online/faq/CLOSE_FAQ';

// TODO these are temporary hardcoded faqs - eventually pull these from content management system in api
const signUpFaqs = [
  {
    q: 'How do I access Axiom\'s products?',
    a: 'The vast majority of our products are available online via our website. To complement this, our in-house underwriters are always available to assist you in placing the more complex risks.',
    state: 'closed'
  },
  {
    q: 'What type of regulation do I require?',
    a: 'You will have, or will obtain and maintain, authorisation with the FCA to transact General Insurance Business and will be able to fulfil your duties and obligations under this Agreement.',
    state: 'closed'
  },
  {
    q: 'I operate as an appointed representative (AR). Can I obtain an agency with Axiom?',
    a: 'Yes, providing that your Principal is authorised with the FCA to transact General Insurance Business. We will need written consent from them to confirm that they accept responsibility for the activities you perform under this agreement.',
    state: 'closed'
  },
  {
    q: 'I am not able to hold client money. Does Axiom offer risk transfer?',
    a: 'For the majority of policies issued by Us, risk transfer will cascade down to You as our agent and premiums, return premiums and claims monies held by You on these policies would be treated as Insurer monies. On the rare occasions where this is not the case, you will be notified at quotation stage and alternative arrangements can be made.',
    state: 'closed'
  },
  {
    q: 'How do I make payment to Axiom?',
    a: 'At the beginning of each month we will issue you with a statement highlighting the preceding month\'s business. Our account details will be noted on this document.',
    state: 'closed'
  },
  {
    q: 'What are Axiom\'s payment terms?',
    a: 'Unless otherwise agreed. Your Terms of Credit are strictly 30 days from the end of the month in which the transaction is issued.',
    state: 'closed'
  },
  {
    q: 'What commission will I earn?',
    a: 'Please contact Axiom on 01992 877 129 for further details.',
    state: 'closed'
  }
];

const initialState = {
  values: []
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case INIT_FAQS:
      return {
        ...state,
        values: action.faqs
      };
    case OPEN_FAQ:
      return Object.assign({}, state, {
        values: state.values.map((value, index) => {
          if (index === action.index) {
            return Object.assign({}, value, {
              state: 'open'
            })
          }
          return value;
        })
      });
    case CLOSE_FAQ:
      return Object.assign({}, state, {
        values: state.values.map((value, index) => {
          if (index === action.index) {
            return Object.assign({}, value, {
              state: 'closed'
            })
          }
          return value;
        })
      });
    default:
      return state;
  }
}

export function initFaqs() {
  return {
    type: INIT_FAQS,
    faqs: signUpFaqs
  }
}

export function openFaq(index) {
  return {
    type: OPEN_FAQ,
    index: index
  }
}

export function closeFaq(index) {
  return {
    type: CLOSE_FAQ,
    index: index
  }
}
