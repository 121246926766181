import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Tabs, Tab } from 'react-bootstrap'
import InternalContainer from '../../layout/containers/Internal'
import Campaigns from '../components/Campaigns/Campaigns'

class CampaignsList extends Component {
  state = {
    activeTab: 1
  }

  handleTab (key) {
    this.setState({ activeTab: key })
  }

  render () {
    return (
      <InternalContainer title='Campaigns'>
        <Tabs activeKey={this.state.activeTab}
          id='content-tabs'
          unmountOnExit
          onSelect={this.handleTab.bind(this)}
          className='shadow-box'
        >
          <Tab eventKey={1} title='Campaigns'>
            <Campaigns {...this.props} />
          </Tab>
        </Tabs>
      </InternalContainer>
    )
  }
}

function mapStateToProps (state) {
  return {
    content: state.content
  }
}

CampaignsList.propTypes = {
  dispatch: PropTypes.func.isRequired,
  content: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(CampaignsList)
