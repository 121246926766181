import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { createCustomer, updateCustomer, clearData } from '../redux/customerActions'
import CustomerForm from '../components/CustomerForm/CustomerForm'
import mapFields from '../../../helpers/mapFields'
import customerSchema from '../../../schemas/customer'

export default class CustomerFormContainer extends Component {
  static propTypes = {
    resource: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    customer: PropTypes.object.isRequired,
  };

  handleSubmit(resource) {
    if (resource.data.id) {
      return this.props.dispatch(updateCustomer(resource))
    }

    return this.props.dispatch(createCustomer(resource))
  }

  render() {
    const fields = mapFields(customerSchema, this.props.resource)

    return (
        <CustomerForm
            {...this.props}
            fields={fields}
            onSubmit={::this.handleSubmit}
            submitting={this.props.customer.isSubmitting}
        />
    )
  }
}
