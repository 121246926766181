import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, formValueSelector } from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import { InfoLabel } from '../../../../common/components'
import { Button, Modal } from '../../../../common/components'
import { closeModal } from '../../../../common/components/Modal/ModalRedux'
import confirm from 'react-confirm2'
import { refundCustomerTransaction } from '../../redux/customerActions'

const FORM_NAME = 'transactionForm'

class UpdateTransactionModal extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    callback: PropTypes.func,
    label: PropTypes.string
  }

  componentWillUpdate(newProps) {
    const { dispatch, customer, callback, reset } = this.props

    if (newProps.customer.submitSucceeded && !customer.submitSucceeded) {
      if (callback) {
        callback('')
      }
      dispatch(reset(FORM_NAME))
      dispatch(closeModal(FORM_NAME))
    }
  }

  handleSubmit() {
    const { refundTransaction } = this.props

    confirm('Warning', {
      description: 'Are you sure you want to refund £' + refundTransaction.amount,
      done: () => {
        return this.props.dispatch(refundCustomerTransaction(refundTransaction.id))
      }
    })
  }

  render() {
    const { label, handleSubmit, submitting, refundTransaction } = this.props
    const title = label || 'Update Transaction'
    const checkForKey = _.includes(refundTransaction.reference, 'transactionId')

    const reference = checkForKey
      ? JSON.parse(refundTransaction.reference).transactionId
      : refundTransaction.reference

    const submitButton = (
      <Button
        type="submit"
        bsStyle="primary"
        disabled={submitting}
        isLoading={submitting}
        label="refund transaction"
      />
    )

    return (
      <div className="source-btn-wrapper pull-right">
        <Modal
          name={FORM_NAME}
          title={title}
          close={true}
          handleSubmit={handleSubmit(::this.handleSubmit)}
          footer={submitButton}
        >
          <Row>
            <Col sm={12}>
              <InfoLabel
                label="Reference"
                value={reference}
                labelSize={4}
                wrapperClass={'clear-margin'}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <InfoLabel
                label="Amount"
                value={'£' + refundTransaction.amount}
                labelSize={4}
                wrapperClass={'clear-margin'}
              />
            </Col>
          </Row>
        </Modal>
      </div>
    )
  }
}

const form = reduxForm({ form: FORM_NAME })(UpdateTransactionModal)
const mapStateToProps = (state, props) => {
  return {
    customer: state.customer
  }
}

const selector = formValueSelector(FORM_NAME)

export default connect(mapStateToProps)(form)
