import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Tabs, Tab } from 'react-bootstrap'
import InternalContainer from '../../layout/containers/Internal'
import BrokersApList from '../components/BrokersApList/BrokersApList'
import  './BrokerApplicationForm.scss'

class BrokersApListContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    content: PropTypes.object.isRequired,
  }

  state = {
    activeTab: 1
  }

  handleTab(key) {
    this.setState({ activeTab: key })
  }

  render() {
    return (
      <InternalContainer title="Broker Applications">
        <BrokersApList {...this.props} />
      </InternalContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    content: state.content
  }
}

export default connect(mapStateToProps)(BrokersApListContainer)
