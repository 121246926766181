import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { Box, Button, ButtonBar, SelectRole } from '../../../../common/components'

const FORM_NAME = 'userRolesForm'

class UserRolesForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    resource: PropTypes.object,
    formValues: PropTypes.object
  };

  render() {
    const { resource, handleSubmit, submitting, organisation } = this.props

    return (
      <Box>
        <h2>{resource ? resource.data.attributes.first_name + ' ' + resource.data.attributes.last_name : 'New user'}</h2>
        <form onSubmit={handleSubmit}>

          <div className="row">
            <div className="col-xs-12">
              <div className="form-horizontal">
                <h3>{ organisation.data.attributes.name }</h3>
                <Field
                    name="data.relationships.roles.data"
                    label="Roles"
                    labelSize={2}
                    multi={true}
                    object={true}
                    component={SelectRole}
                />
              </div>
            </div>
          </div>

          <ButtonBar>
            <Button
                className="pull-right"
                type="submit"
                bsStyle="primary"
                label="Save Changes"
                isLoading={submitting}
            />
          </ButtonBar>
        </form>
      </Box>
    )
  }
}

const form = reduxForm({ form: FORM_NAME })(UserRolesForm)
const mapStateToProps = (state, props) => {
  const initialValues = {
    data: {
      relationships: {
        organisation: {
          data: {
            'id': props.organisation.data.id
          }
        },
        roles: {
          data: props.roles.data
        }
      }
    }
  }

  return {
    initialValues
  }
}

export default connect(mapStateToProps)(form)
