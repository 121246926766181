export const IS_SUBMITTING = 'socrates-online/account/IS_SUBMITTING'
export const IS_SUSPENDING = 'socrates-online/account/IS_SUSPENDING'
export const IS_REINSTATING = 'socrates-online/account/IS_REINSTATING'
export const SET_ACCOUNT = 'socrates-online/account/SET_ACCOUNT'
export const SET_TRANSACTIONS = 'socrates-online/account/SET_TRANSACTIONS'
export const SET_PREMIUMS = 'socrates-online/account/SET_PREMIUMS'
export const SET_SUMMARIES = 'socrates-online/account/SET_SUMMARIES'
export const SET_PREMIUM_TYPE = 'socrates-online/account/SET_PREMIUM_TYPE'
export const SET_PAYMENTS_AND_RECEIPTS = 'socrates-online/account/SET_PAYMENTS_AND_RECEIPTS'
export const CLEAR_ACCOUNT = 'socrates-online/account/CLEAR_ACCOUNT'
export const IS_GENERATING_REPORT = 'socrates-online/account/IS_GENERATING_REPORT '
export const SET_LEDGER_ENTRY = 'socrates-online/account/SET_LEDGER_ENTRY'

export const initialState = {
  isSubmitting: false,
  isGeneratingReport: false,
  isSuspending: false,
  isReinstating: false,
  premiumType: 'net-premiums',
  filters: '',
  resources: {},
  summaries: {},
  transactions: {
    data: [],
  },
  premiums: {
    data: [],
  },
  ledgerEntry: {}
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.status
      }
    case IS_GENERATING_REPORT:
      return {
        ...state,
        isGeneratingReport: action.status
      }
    case IS_SUSPENDING:
      return {
        ...state,
        isSuspending: action.status
      }
    case IS_REINSTATING:
      return {
        ...state,
        isReinstating: action.status
      }
    case SET_SUMMARIES:
      return {
        ...state,
        summaries: action.data
      }
    case SET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.data
      }
    case SET_PREMIUMS:
      return {
        ...state,
        premiumType: action.premiumType,
        filters: action.filters,
        premiums: action.data
      }
    case SET_PREMIUM_TYPE:
      return {
        ...state,
        premiumType: action.premiumType,
      }
    case SET_LEDGER_ENTRY:
      return {
        ...state,
        ledgerEntry: action.data,
      }
    case SET_ACCOUNT:
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.data.data.id]: {
            ...action.data
          }
        }
      }
    case CLEAR_ACCOUNT:
      return initialState
    default:
      return state
  }
}