import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Box, DataTable, SelectBroker, SelectUser } from '../../../../common/components'
import { applyFilter, removeFilter } from '../../../../common/components/DataTable/redux/dataTableActions'
import ProductFilter from '../ProductFilter'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { FormControl, ControlLabel } from 'react-bootstrap'
import { push } from 'react-router-redux'
import { isOwner, isBroker, authUserId } from '../../../auth/redux/authActions'
import { FormattedDate, FormattedTime } from 'react-intl'
import getUpdatedAtFrom from '../../../../helpers/getUpdatedAtFrom';


class PolicyList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    currentBroker: PropTypes.string,
    auth: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props)

    this.state = {
      brokerId: '',
      userId: null
    }
  }

  formatDate(cell) {
    return (
      <span><FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/></span>
    )
  }

  handleFilterUpdate(event) {
    this.props.dispatch(applyFilter('policies', 'filter[product]', event.target.value))
  }

  handleRowClick(row) {
    this.props.dispatch(push('/policies/issued/' + row.id))
  }

  handleSearchUpdate(event) {
    Promise.resolve(
      this.props.dispatch(removeFilter('policies', 'filter[updated_at:from]'))
    ).then(
      this.props.dispatch(applyFilter('policies', 'filter[policy_number]', event.target.value))
    );
  }

  handleBrokerUpdate(value) {
    this.setState({ brokerId: value })
    this.props.dispatch(applyFilter('policies', 'filter[broker]', value))

  }

  handleUserUpdate(value) {
    this.setState({ userId: value })
    this.props.dispatch(applyFilter('policies', 'filter[user]', value))
  }

  handleStatusUpdate(event) {
    this.props.dispatch(applyFilter('policies', 'filter[status]', event.target.value))
  }
  render() {
    return (
      <div>
        <Box>
          <div className="row">
            <div className="col-sm-3">
              <ControlLabel className="filter-label">Reference</ControlLabel>
              <FormControl onChange={ ::this.handleSearchUpdate } />
            </div>
            {isOwner() && (
              <div className="col-sm-3">
                <ControlLabel className="filter-label">Broker</ControlLabel>
                <SelectBroker
                  labelKeys={['attributes.name']}
                  input={{
                    onChange: ::this.handleBrokerUpdate,
                    value: this.state.brokerId
                  }}/>
              </div>
            )}
            {(isOwner() || isBroker()) && (
              <div className="col-sm-3">
                <ControlLabel className="filter-label">User</ControlLabel>
                <SelectUser
                  labelKeys={['attributes.first_name', 'attributes.last_name']}
                  input={{
                    onChange: ::this.handleUserUpdate,
                    value: this.state.userId
                  }}/>
              </div>
            )}
            <div className="col-sm-3">
              <ControlLabel className="filter-label">Product</ControlLabel>
              <ProductFilter onChange={::this.handleFilterUpdate} />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3">
              <ControlLabel className="filter-label">Status</ControlLabel>
              <FormControl
                componentClass="select"
                type="select"
                onChange={ ::this.handleStatusUpdate }
              >
                <option value="all">All</option>
                <option value="pending">Pending</option>
                <option value="issued">Issued</option>
                <option value="cancelled">Cancelled</option>
                <option value="renewed">Renewed</option>
                <option value="on cover">On Cover</option>
                <option value="expired">Expired</option>
                <option value="active">Active</option>
              </FormControl>
            </div>
          </div>
        </Box>
        <Box>
          <DataTable
            source="/policies"
            name="policies"
            showTotal={true}
            onRowSelect={this.handleRowClick.bind(this)}
            autoFilters={[
              { type: 'filter[user]', value: this.state.userId },
              { type: 'filter[updated_at:from]', value: getUpdatedAtFrom() },
            ]}
          >
            <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
              ID
            </TableHeaderColumn>

            <TableHeaderColumn dataField="policy_number" dataSort={true} width={'200px'}>
              Policy Number
            </TableHeaderColumn>

            <TableHeaderColumn dataField="insured" width={'200px'}>
              Insured
            </TableHeaderColumn>

            <TableHeaderColumn dataField="broker_name" width={'200px'}>
              Broker
            </TableHeaderColumn>

            <TableHeaderColumn dataField="user_name" width={'150px'}>
              User
            </TableHeaderColumn>

            <TableHeaderColumn dataField="status" dataSort={true} width={'100px'}>
              Status
            </TableHeaderColumn>

            <TableHeaderColumn dataField="created_at" dataFormat={this.formatDate} dataSort={true} width={'100px'}>
              Quote Date
            </TableHeaderColumn>

            <TableHeaderColumn dataField="issued_at" dataFormat={this.formatDate} dataSort={true} width={'100px'}>
              Issue Date
            </TableHeaderColumn>

          </DataTable>
        </Box>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps)(PolicyList)
