import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CampaignEditForm from '../components/CampaignEditForm/CampaignEditForm'
import { updateCampaign } from '../redux/campaignActions'

export default class CampaignFormContainer extends Component {
  handleSubmit (resource) {
    if (resource.data.id) {
      return this.props.dispatch(updateCampaign(resource))
    }
  }

  render () {
    return (
      <CampaignEditForm {...this.props} onSubmit={this.handleSubmit.bind(this)} />
    )
  }
}

CampaignFormContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  resource: PropTypes.object
}
