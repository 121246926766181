import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Col, FormControl, ControlLabel } from 'react-bootstrap'
import './AutoCapsInput.scss'

class AutoCapsInput extends Component {
  static propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    labelSize: PropTypes.number,
    input: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    className: PropTypes.string,
    note: PropTypes.string
  };

  handleKeyUp(e) {
    const v = e.target.value
    if (v) {
      e.target.value = v.replace(/^(.)|(\s|\-)(.)/g, (letter) => {
        return letter.toUpperCase()
      })
    }
  }

  render() {
    const { label, placeholder, labelSize, input, meta, className, note, readOnly } = this.props

    return (
        <FormGroup controlId={input.name} className={((meta && meta.touched && meta.error) ? ' has-error' : '')}>
          <Col componentClass={ControlLabel} sm={labelSize}>
            { label }
          </Col>
          <Col sm={(12 - labelSize)}>
            <FormControl
                {...input}
                type="text"
                placeholder={ placeholder }
                onBlur={ this.handleKeyUp.bind(this) }
                readOnly={readOnly || false}
                className={className}/>
            { note ? <div className="note">{ note }</div> : ('') }
            {meta.touched && meta.error && <span className="input-error">{meta.error}</span>}
          </Col>
        </FormGroup>
    )
  }
}

export default AutoCapsInput
