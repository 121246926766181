import React, { Component } from 'react'
import { Nav, NavItem } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { Icon } from '../../../../common/components'
import { Link } from 'react-router';

export default class GuestNavigation extends Component {
  render() {
    return (
      <div>
        <Nav className="guest-nav">
          <LinkContainer to="/">
            <NavItem eventKey={9}><Icon name="home"/></NavItem>
          </LinkContainer>
          <LinkContainer to="/contact">
            <NavItem eventKey={8}>Contact Us</NavItem>
          </LinkContainer>
          <li role={'presentation'}>
            <Link to={'https://www.archgroup.com/cookie-policy/'} target={'_blank'}
                  onlyActiveOnIndex>Cookies</Link>
          </li>
          <li role={'presentation'}>
            <Link to={'https://www.archgroup.com/privacy-policy/'}
                  target={'_blank'} onlyActiveOnIndex>Privacy </Link>
          </li>
        </Nav>
      </div>
    )
  }
}
