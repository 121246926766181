import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Tabs, Tab, Label } from 'react-bootstrap'
import { getBroker, getAllProducts } from '../redux/brokerActions'
import { isOwner } from '../../auth/redux/authActions'
import { Button, Loader } from '../../../common/components'
import { brokerStatuses } from '../../../schemas/broker'
import InternalContainer from '../../layout/containers/Internal'
import BrokerDetails from '../components/BrokerDetails/BrokerDetails'
import BrokerContracts from '../components/BrokerContracts/BrokerContracts'
import BrokerProducts from '../components/BrokerProducts'
import BrokerUsers from '../components/BrokerUsers/BrokerUsers'
import BrokerNotes from '../components/BrokerNotes/BrokerNotes'
import BrokerAudit from '../components/BrokerAudit'
import getIncludedResource from '../../../helpers/getIncludedResource'
import displayName from '../../../helpers/displayName'
import _ from 'lodash'

class BrokerShowContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    broker: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired
  }

  constructor(props, context) {
    super(props, context)

    this.state = {
      activeTab: 1
    }
  }

  componentWillMount() {
    this.props.dispatch(getBroker(this.props.params.id))
    this.props.dispatch(getAllProducts())
  }

  handleTab(key) {
    this.setState({ activeTab: key })
  }

  render() {
    const resource = this.props.broker.resources[this.props.params.id]
    const buttons = (
      <Button
        bsStyle="default"
        label="Back"
        isBackButton={true}
        leftIcon="caret-left"
      />
    )

    const brokerStatus = brokerStatuses[_.get(resource, 'data.attributes.status', 'applied')]
    const labelStyle = () => {
      if (brokerStatus == 'Pending' || brokerStatus == 'Awaiting Approval') {
        return 'warning'
      }
      if (brokerStatus == 'Live') {
        return 'success'
      }
      if (brokerStatus == 'Declined' || brokerStatus == 'Inactive' || brokerStatuses == 'Cancelled') {
        return 'danger'
      }
    }
    const brokerLabel = (<Label className="pull-right" bsStyle={labelStyle()}>{displayName(brokerStatus)}</Label>)

    return (
      <InternalContainer title="Broker" buttons={buttons}>
        {resource ? (
          <Tabs activeKey={this.state.activeTab}
                id="broker-tabs"
                unmountOnExit={true}
                onSelect={::this.handleTab}
          className='shadow-box'>

            <Tab eventKey={1} title="Details">
              <BrokerDetails {...this.props} resource={resource} brokerLabel={brokerLabel}/>
            </Tab>

            <Tab eventKey={4} title="Users">
              <BrokerUsers {...this.props} resource={resource} brokerLabel={brokerLabel}/>
            </Tab>

            <Tab eventKey={3} title="Products">
              <BrokerProducts
                {...this.props}
                resource={resource}
                brokerLabel={brokerLabel}
                commissions={getIncludedResource(resource.data, resource.included, 'active_commissions')}
              />
            </Tab>

            <Tab eventKey={2} title="Contracts">
              <BrokerContracts {...this.props} resource={resource} brokerLabel={brokerLabel}/>
            </Tab>

            <Tab eventKey={5} title="Notes">
              <BrokerNotes {...this.props} resource={resource} brokerLabel={brokerLabel}/>
            </Tab>

            {isOwner() && (
                <Tab eventKey={6} title="Audit">
                  <BrokerAudit {...this.props} resource={resource} brokerLabel={brokerLabel}/>
                </Tab>
            )}
          </Tabs>
          ) : (
            <Loader />
          )}
      </InternalContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    broker: state.broker
  }
}

export default connect(mapStateToProps)(BrokerShowContainer)
