import React, { Component } from 'react'
import { FormattedDate, FormattedTime } from 'react-intl'
import { Icon } from '../../../../common/components'
import displayName from '../../../../helpers/displayName'
import './styles.scss'

class LedgerEntry extends Component {
  render() {
    const { item, selected, onItemSelect } = this.props

    return (
      <div className="row sortable-container-item">
        <div className="col-xs-12">
          <span className={'item-checkbox' + (selected ? ' selected' : '')} onClick={onItemSelect}>
            {selected && (
                <Icon name={selected ? 'check' : 'cross'} />
            )}
          </span>

          <span className="pull-left">
              {item.attributes.premium_element_type ? (
                displayName(item.attributes.premium_element_type)
              ) : (
                <span>{item.attributes.amount > 0 ? 'Payment' : 'Receipt' }</span>
              )}
            &nbsp;<strong>{item.attributes.policy_number ? ' - ' + item.attributes.policy_number : ''}</strong>
          </span>

          <span className={'pull-right item-amount-' + (item.attributes.amount > 0 ? 'dr' : 'cr')}>
          <strong>
            {item.attributes.currency} {item.attributes.formatted_amount}
          </strong>
          </span>
        </div>
        <div className="col-xs-12 description">
          <FormattedDate value={item.attributes.created_at}/> <FormattedTime
            value={item.attributes.created_at} /> | {item.attributes.summary}
        </div>
      </div>
    )
  }
}

export default LedgerEntry
