import React, { Component } from 'react'
import 'moment/locale/en-gb'
import { connect } from 'react-redux'
import { Tab, Tabs } from 'react-bootstrap'
import { formValueSelector, reduxForm } from 'redux-form'
import { Button, ButtonBar } from '../../../../common/components'
import {
  getProducts,
  createBrokerApplication,
  updateBrokerApplication,
  resendBrokerApplication,
  declineBrokerApplication,
  approveBrokerApplication,
} from '../../redux/brokerApplicationActions'
import getIncludedResource from '../../../../helpers/getIncludedResource'
import { getBrokerApplicationQuestions } from '../../../brochure/redux/brochureActions'
import './styles.scss'
import _ from 'lodash'
import Users from './components/tabsContent/Users'
import Notes from './components/tabsContent/Notes'
import General from './components/tabsContent/General'
import Company from './components/tabsContent/Company'
import Products from './components/tabsContent/Products'
import Management from './components/tabsContent/Management'
import Declaration from './components/tabsContent/Declaration'
// import Regulation from './components/tabsContent/Regulation'

const FORM_NAME = 'BrokerApplication'

class BrokersApNewForm extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      activeTab: '1',
    }
    this.handleApprove = this.handleApprove.bind(this)
    this.handleDecline = this.handleDecline.bind(this)
    this.handleResendApproval = this.handleResendApproval.bind(this)
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(getBrokerApplicationQuestions())
    dispatch(getProducts())
  }

  handleTab(key) {
    this.setState({ activeTab: key })
  }

  goNextTab() {
    this.setState((prevState) => ({
      activeTab: '' + (parseInt(prevState.activeTab) + 1)
    }))
  }

  goPreviousTab() {
    this.setState((prevState) => ({
      activeTab: '' + (parseInt(prevState.activeTab) - 1)
    }))
  }

  handleSave() {
    const { formValues, dispatch } = this.props
    if (! _.get(formValues, 'data.id')) {
      dispatch(createBrokerApplication(formValues))
      return
    }

    dispatch(updateBrokerApplication(formValues))
  }


  handleApprove() {
    const { formValues, dispatch } = this.props
    dispatch(approveBrokerApplication(formValues))
  }

  handleDecline() {
    const { formValues, dispatch } = this.props
    dispatch(declineBrokerApplication(formValues))
  }

  handleResendApproval() {
    const { formValues, dispatch } = this.props
    dispatch(resendBrokerApplication(formValues))
  }


  renderActionButtons() {
    const { brokerApplication, formValues } = this.props
    const getStatus = _.get(formValues, 'data.attributes.status')
    const hasId = _.get(formValues, 'data.id')
    if(!hasId) { return }

    let buttonSet = []
    const decline = (
      <Button
        size='large'
        key={'decline'}
        bsStyle='primary'
        label={'Decline'}
        handleClick={this.handleDecline}
        isLoading={brokerApplication.isSubmitting}
      />
    )

    const approve = (
      <Button
        size='large'
        key={'approve'}
        bsStyle='primary'
        label={'Approve'}
        handleClick={this.handleApprove}
        isLoading={brokerApplication.isSubmitting}
      />
    )

    const resend = (
      <Button
        size='large'
        key={'resend'}
        bsStyle='primary'
        label={'Resend Approval'}
        handleClick={this.handleResendApproval}
        isLoading={brokerApplication.isSubmitting}
      />
    )

    if(getStatus === 'declined') {
      buttonSet.push(approve)
    }

    if(getStatus === 'applied') {
      buttonSet.push(approve, decline)
    }

    if(getStatus === 'approved') {
      buttonSet.push(resend)
    }

    return buttonSet.length ? (
      <div>
        {buttonSet}
      </div>
    ) : null
  }

  render() {
    const { handleSubmit, formValues, organisation, brokerApplication } = this.props
    const { activeTab } = this.state
    const hasId = _.get(formValues, 'data.id')

    const finalPage = activeTab < (hasId ? 7 : 6);

    return (
      <form onSubmit={handleSubmit} className='shadow-box'>
        <div className='tab-container'>
          <Tabs
            justified
            unmountOnExit={true}
            activeKey={activeTab}
            id='broker-application'
            onSelect={::this.handleTab}
          >
            <Tab eventKey={'1'} title={'General'}>
              <General form={FORM_NAME} brokerType={'broker'} organisation={organisation} />
            </Tab>
            <Tab eventKey={'2'} title={'Company'}>
              <Company form={FORM_NAME} organisation={organisation} />
            </Tab>
            <Tab eventKey={'3'} title={'Management'}>
              <Management form={FORM_NAME} {...this.props} />
            </Tab>
            {/* THIS TAB WILL BE USED LATER ON */}
            {/*<Tab eventKey={'4'} title={'Regulation'}>*/}
              {/*<Regulation content={brochure} form={FORM_NAME}/>*/}
            {/*</Tab>*/}
            {hasId && (
              <Tab eventKey={'4'} title={'Documents'}>
                <Notes formValues={formValues} id={hasId} {...this.props} />
              </Tab>
            )}
            <Tab eventKey={hasId ? '5' : '4'} title={'Products'}>
              <Products form={FORM_NAME} products={brokerApplication.products} {...this.props} />
            </Tab>
            <Tab eventKey={hasId ? '6' : '5'} title={'Users'}>
              <Users isLogedIn={true} form={FORM_NAME}/>
            </Tab>
            <Tab eventKey={hasId ? '7' : '6'} title={'Declaration'}>
              <Declaration formValues={formValues}/>
            </Tab>
          </Tabs>
        </div>

        <div className="btn-bar-container">
          <ButtonBar>
            {activeTab > 1 && (
              <Button
                label='Back'
                bsStyle='default'
                className='btn-action-back'
                handleClick={::this.goPreviousTab}
              />
            )}
            {activeTab < (hasId ? 7 : 6) && (
              <Button
                label='Next'
                bsStyle='primary'
                handleClick={::this.goNextTab}
                className='btn-action pull-right'
              />
            )}
            <Button
              size='large'
              label={finalPage ? 'Save' : 'Save & Submit' }
              bsStyle='primary'
              className='pull-right'
              handleClick={::this.handleSave}
              isLoading={brokerApplication.isSubmitting}
              disabled={!finalPage && !_.get(formValues, 'data.attributes.metadata.declaration')}
            />
            {hasId && (
              <div>
                {this.renderActionButtons()}
              </div>
            )}
          </ButtonBar>
        </div>
      </form>
    )
  }
}

const form = reduxForm({ form: FORM_NAME })(BrokersApNewForm)
const selector = formValueSelector(FORM_NAME)

const mapStateToProps = (state, props) => {
  const values = selector(state,
    'meta',
    'data',
    'metadata',
    'data.attributes'
  )

  const products = getIncludedResource(state.auth.user.data, state.auth.user.included, 'available_products')
  const product = _.find(products, (product) => {
    return _.get(product, 'attributes.product_code') === 'TRAV'
  })

  return {
    product,
    products,
    auth: state.auth,
    formValues: values,
    broker: state.broker,
    brochure: state.brochure,
    enableReinitialize: true,
    dashboard: state.dashboard,
    initialValues: props.resource,
    organisation: state.organisation,
    brokerApplication: state.brokerApplication,
  }
}

export default  connect(mapStateToProps)(form)
