import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { saveQuotes, patchQuotes } from '../../redux/productActions'
import { Button } from '../../../../common/components'
import _ from 'lodash'
import './styles.scss'

class QuoteActions extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    currentProduct: PropTypes.object.isRequired,
    selectedSchemes: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    declaration: PropTypes.object,
  }

  saveQuotes(application) {
    return this.props.dispatch(saveQuotes(application, this.props.currentProduct))
  }

  approveReferral(application) {
    if (application.data.id) {
      return this.props.dispatch(patchQuotes(application))
    }

    return this.props.dispatch(saveQuotes(application, this.props.currentProduct))
  }

  render() {
    const { product, selectedSchemes, handleSubmit, declaration, currentProduct } = this.props
    const selectedScheme = selectedSchemes && selectedSchemes.length === 1 && selectedSchemes[0].id ? product.quotes[selectedSchemes[0].id] : false
    const isReferral = selectedScheme && _.get(selectedScheme, 'meta.referrals', []).length

    return (
      <div className="quote-actions">
        <Button
          label={isReferral ? 'Approve Quote' : 'Save and Exit'}
          bsStyle={isReferral ? 'success' : 'primary'}
          rightIcon={isReferral ? 'check-square-o' : 'arrow-right'}
          block={true}
          isLoading={product.isSaving}
          handleClick={handleSubmit(isReferral ? ::this.approveReferral : ::this.saveQuotes)}
        />
      </div>
    )
  }
}

export default connect()(QuoteActions)
