import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { goBack } from 'react-router-redux'
import { Icon } from '../../components'
import { Link } from 'react-router'
import './Button.scss'

export class Button extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    bsStyle: PropTypes.string,
    size: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    handleClick: PropTypes.func,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    isBackButton: PropTypes.bool,
    children: PropTypes.node,
    label: PropTypes.string,
    leftIcon: PropTypes.string,
    rightIcon: PropTypes.string,
    link: PropTypes.bool,
    block: PropTypes.bool,
    to: PropTypes.string
  }

  handleClick(e) {
    const { dispatch, handleClick, isBackButton } = this.props
    if (isBackButton) {
      dispatch(goBack())
    }
    if (handleClick) {
      handleClick(e)
    }
  }

  render() {
    const { bsStyle, size, type, className, disabled, isLoading, children, label, leftIcon, rightIcon, link, block, to } = this.props

    const buttonClass = 'btn btn-' + (bsStyle ? bsStyle : 'default') + (size ? ' btn-' + size : '')
        + (className ? ' ' + className : '') + (block ? ' btn-block' : '')

    if (link) {
      return (
          <Link className={buttonClass} to={to}>
            {isLoading ? (
                    <span className="btn-loading">
            <Icon spin name="spinner"/>
            <span>{children || label}</span>
          </span>
                ) : (
                    <span>
            {leftIcon && ( <Icon name={leftIcon} align="left"/> )}
                      {children || label}
                      {rightIcon && ( <Icon name={rightIcon} align="right"/> )}
          </span>
                )}
          </Link>
      )
    }

    return (
        <button type={type || 'button'} className={buttonClass} onClick={::this.handleClick}
                disabled={disabled == true || isLoading == true}>
          {isLoading ? (
                  <span className="btn-loading">
          <Icon spin name="spinner"/>
          <span>{children || label}</span>
        </span>
              ) : (
                  <span>
          {leftIcon && ( <Icon name={leftIcon} align="left"/> )}
                    {children || label}
                    {rightIcon && ( <Icon name={rightIcon} align="right"/> )}
        </span>
              )}
        </button>
    )
  }
}

export default connect()(Button)
