export const SET_USER = 'socrates-online/user/SET_USER'
export const SET_USER_ORGANISATION_ROLES = 'socrates-online/user/SET_USER_ORGANISATION_ROLES'
export const IS_SUBMITTING = 'socrates-online/user/IS_SUBMITTING'
export const IS_RESETTING = 'socrates-online/user/IS_RESETTING'
export const IS_REACTIVATING = 'socrates-online/user/IS_REACTIVATING'
export const IS_DEACTIVATING = 'socrates-online/user/IS_DEACTIVATING'
export const CLEAR_USER = 'socrates-online/user/CLEAR_USER'

export const initialState = {
  resources: {},
  organisationRoles: {
    organisation: null,
    data: []
  },
  isSubmitting: false,
  isResetting: false,
  isReactivating: false,
  isDeactivating: false,
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.status
      }
    case IS_RESETTING:
      return {
        ...state,
        isResetting: action.status
      }
    case IS_DEACTIVATING:
      return {
        ...state,
        isDeactivating: action.status
      }
    case IS_REACTIVATING:
      return {
        ...state,
        isReactivating: action.status
      }
    case SET_USER:
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.data.data.id]: {
            ...action.data
          }
        }
      }
    case SET_USER_ORGANISATION_ROLES:
      return {
        ...state,
        organisationRoles: {
          organisation: action.organisation,
          data: action.data
        }
      }
    case CLEAR_USER:
      return initialState
    default:
      return state
  }
}
