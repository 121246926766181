import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {InfoLabel, ButtonBar, Box, Button, YesNoFormat} from '../../../../common/components'
import getIncludedResource from '../../../../helpers/getIncludedResource'
import displayAddress from '../../../../helpers/displayAddress'
import {hasPermission, isBroker} from '../../../auth/redux/authActions'
import {passwordReminder, reactivateUser, deactivateUser} from '../../redux/userActions'
import Markdown from 'react-remarkable'
import {userStatuses} from '../../../../schemas/user'
import displayName from '../../../../helpers/displayName'
import _ from 'lodash'

export default class UserDetails extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    resource: PropTypes.object.isRequired,
    isInOwnerOrganisation: PropTypes.bool,
  };

  sendPasswordResetEmail() {
    this.props.dispatch(passwordReminder(this.props.resource.data.attributes.email))
  }

  reactivateUser() {
    this.props.dispatch(reactivateUser(this.props.resource.data.id))
  }

  deactivateUser() {
    this.props.dispatch(deactivateUser(this.props.resource.data.id))
  }

  render() {
    const { isInOwnerOrganisation } = this.props
    const resource = this.props.resource.data.attributes
    const organisation = getIncludedResource(this.props.resource.data, this.props.resource.included, 'organisations')
    const role = getIncludedResource(this.props.resource.data, this.props.resource.included, 'roles')
    const address = displayAddress(this.props.resource.data.attributes.address)
    const contact = resource.status == 'contact'

    let organisations = ''
    if (Array.isArray(organisation)) {
      organisation.map((relation, i) => {
        if (i) {
          organisations += ', '
        }
        organisations += relation.attributes.name
      })
    }

    let roles = ''
    if (Array.isArray(role)) {
      role.map((relation, i) => {
        if (i) {
          roles += ', '
        }
        roles += relation.attributes.name
      })
    }

    return (
      <Box>
        <h2 className="resource-name">{resource.name}</h2>

        <div className="row">

          <div className="col-sm-6">
            <div className="form-horizontal">
              <InfoLabel label="Name" value={ resource.first_name + ' ' + resource.last_name } labelSize={4}/>
              <InfoLabel label="Address" value={ address } labelSize={4}/>
              <InfoLabel label="Email" value={ resource.email } labelSize={4}/>
              <InfoLabel label="Phone" value={ resource.phone1 } labelSize={4}/>
              <InfoLabel label="Alternative Phone" value={ resource.phone2 } labelSize={4}/>
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-horizontal">
              <InfoLabel label="Organisations" value={ organisations } labelSize={4}/>
              <InfoLabel label="Position" value={ resource.position } labelSize={4}/>
              <InfoLabel label="Status" value={ displayName(resource.status) } labelSize={4}/>
              <InfoLabel label="Created At" date={true} value={ resource.created_at } labelSize={4}/>
              <InfoLabel label="Updated At" date={true} value={ resource.updated_at } labelSize={4}/>
            </div>
          </div>
        </div>

        {!isBroker() && !contact && (
          <div>
            <div className="hr"/>

            <div className="row">
              <div className="col-xs-12">
                <div className="form-horizontal">
                  <InfoLabel label="Other notes" value={ _.get(resource, 'metadata.notes', '') } labelSize={2}/>
                </div>
              </div>
            </div>
          </div>
        )}

        {!isBroker() && !contact && isInOwnerOrganisation && (
          <div>
            <div className="hr"/>

            <div className="row">
              <div className="col-xs-12">
                <div className="form-horizontal">
                  <InfoLabel label="Biography"
                             value={ <Markdown source={resource.metadata ? resource.metadata.biography : ''}/> }
                             labelSize={2}/>
                </div>
              </div>
            </div>
          </div>
        )}

        <ButtonBar>
          { hasPermission('user.edit') && (
            <Button
              link={true}
              to={'/admin/users/' + this.props.resource.data.id + (contact ? '/edit?contact=true' : '/edit')}
              className="pull-right"
              bsStyle="primary"
              rightIcon="pencil-square-o">Edit { contact ? 'Contact' : 'User' }</Button>
          )}
          { (hasPermission('user.view')) && !contact && (
            <Button
              className="pull-right"
              bsStyle="primary"
              rightIcon="envelope"
              isLoading={this.props.user.isResetting}
              handleClick={::this.sendPasswordResetEmail}>Send Password Reset Email</Button>
          )}
          { (hasPermission('user.create') && resource.status == userStatuses.inactive) && !contact && (
            <Button
              className="pull-right"
              bsStyle="primary"
              rightIcon="check"
              isLoading={this.props.user.isReactivating}
              handleClick={::this.reactivateUser}>Reactivate User</Button>
          )}
          { (hasPermission('user.deactivate') && resource.status != userStatuses.inactive) && !contact && (
            <Button
              className="pull-right"
              bsStyle="primary"
              rightIcon="ban"
              isLoading={this.props.user.isDeactivating}
              handleClick={::this.deactivateUser}>Deactivate User</Button>
          )}
        </ButtonBar>
      </Box>
    )
  }
}
