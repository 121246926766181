import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { required } from 'redux-form-validators'
import {
  CheckboxUnlabeled
} from '../../../../common/components/ReduxFormField'
import InternalContainer from '../../../layout/containers/Internal'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import {
  Col,
  ControlLabel,
  FormGroup
} from 'react-bootstrap'
import UploadCropComponent from '../../../../common/components/Upload/UploadCrop'
import { triggerFileUpload } from '../../../../common/components/Upload/UploadRedux'
import {
  Box,
  Button,
  HorizontalFormControl,
  SelectChannel
} from '../../../../common/components'
import SelectField from '../../../../common/components/ReduxFormField/SelectField'
import Select from 'react-select'
import './styles.scss'
import DatePickerLeisure from '../../../../common/components/DatePicker/DatePickerLeisure';
import SelectProduct from '../../../../common/components/Selects/SelectProduct';
import moment from 'moment'
import 'moment/locale/en-gb'

const templateDefaults = {
  id: null,
  type: 'templates',
  attributes: {
    name: '',
    slug: '',
    sections: {}
  }
}

class CampaignNewForm extends Component {
  constructor (props, context) {
    super(props, context)
    this.state = {
      template: templateDefaults,
      valueRegions: [],
      valueTravellerGroup: [],
      allAges: false,
      allScores: false,
      allDurations: false,
      banner: false
    }
    this.renderField = this.renderField.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.handleSelectChangeDestinations = this.handleSelectChangeDestinations.bind(this)
    this.familyGroupsSelection = this.familyGroupsSelection.bind(this)
    this.destinationSelection = this.destinationSelection.bind(this)
    this.allAges = this.allAges.bind(this)
    this.allScores = this.allScores.bind(this)
    this.allDurations = this.allDurations.bind(this)
    this.handleUpload = this.handleUpload.bind(this)
    this.handleUploadComplete = this.handleUploadComplete.bind(this)
    // this.coverSelection = this.coverSelection.bind(this)
  }

  handleSelectChange (valueTravellerGroup) {
    this.setState({ valueTravellerGroup })

    let valuesArr = valueTravellerGroup.split(',')
    this.props.dispatch(this.props.change('data.attributes.criterion.traveller_group.in', valuesArr))
  }

  handleSelectChangeDestinations (valueRegions) {
    this.setState({ valueRegions })
    let valuesArr = valueRegions.split(',')
    this.props.dispatch(this.props.change('data.attributes.criterion.region.in', valuesArr))
  }

  handleUpload () {
    const { dispatch } = this.props
    dispatch(triggerFileUpload())
  }

  handleUploadComplete (data) {
    const { dispatch, change } = this.props

    dispatch(change('data.relationships.avatar.data', data.data))
  }

  renderField (field) {
    const { input, type, meta } = field
    const className = (meta.submitFailed && meta.touched && meta.error && meta.error.length > 0) ? 'form-control has-error' : 'form-control'
    let placeHolder = field.placeholder
    switch (field.id) {
      case 'ageStart':
        placeHolder = '-'
        break
      case 'ageEnd':
        placeHolder = '-'
        break
    }
    return (
      <div>
        <input disabled={field.disabled} {...input} className={className} type={type}
          placeholder={this.state.allAges ? placeHolder : ''}
        />
        {(meta && meta.touched && meta.error) && <span className='input-campaign-error'>{meta.error}</span>}
      </div>
    )
  }

  familyGroupsSelection ({ input, label, meta, options }) {
    return (
      <div className='form-group'>
        <div className='col-xs-4'>
          <label className='control-label'>{label}</label>
        </div>
        <div className='col-xs-8'>
          <Select
            {...input}
            multi
            simpleValue
            autoBlur
            value={this.state.valueTravellerGroup}
            options={options}
            onChange={this.handleSelectChange}
            dispatch={this.props.dispatch}
            change={this.props.change}
          />
          {(meta && meta.touched && meta.error) && <span className='input-campaign-error'>{meta.error}</span>}
        </div>
      </div>
    )
  }

  destinationSelection ({ input, label, meta, options }) {
    return (
      <div className='form-group'>
        <div className='col-xs-4'>
          <label className='control-label'>{label}</label>
        </div>
        <div className='col-xs-8'>
          <Select
            {...input}
            autoBlur
            multi
            simpleValue disabled={this.state.disabled}
            value={this.state.valueRegions}
            options={options}
            onChange={this.handleSelectChangeDestinations}
            dispatch={this.props.dispatch}
            change={this.props.change}
          />
          {(meta && meta.touched && meta.error) && <span className='input-campaign-error'>{meta.error}</span>}
        </div>
      </div>
    )
  }

  allAges () {
    const { dispatch, change } = this.props
    Promise.resolve(
      dispatch(change('data.attributes.criterion.min_age.min', ''))
    )
      .then(dispatch(change('data.attributes.criterion.max_age.max', '')))
      .then(() =>
        this.setState(prevState => ({
          allAges: !prevState.allAges
        }))
      )
  }

  allDurations () {
    const { dispatch, change } = this.props
    Promise.resolve(
      dispatch(change('data.attributes.criterion.duration.min', ''))
    )
      .then(dispatch(change('data.attributes.criterion.duration.max', '')))
      .then(() =>
        this.setState(prevState => ({
          allDurations: !prevState.allDurations
        }))
      )
  }

  allScores () {
    const { dispatch, change } = this.props
    Promise.resolve(
      dispatch(change('data.attributes.criterion.max_score.min', ''))
    )
      .then(dispatch(change('data.attributes.criterion.max_score.max', '')))
      .then(() =>
        this.setState(prevState => ({
          allScores: !prevState.allScores
        }))
      )
  }
  render () {
    const { handleSubmit, saving, formValues } = this.props
    const upper = value => value && value.toUpperCase()
    const submitting = '' // placeholder
    const buttons = (
      <Button
        bsStyle='default'
        label='back'
        isBackButton
        leftIcon='caret-left'
      />
    )

    const product = _.get(formValues, 'data.relationships.product.data');

    const allSchemeTypes = [
      { label: 'Annual', value: 'annual' },
      { label: 'Single', value: 'single' },
      { label: 'Longstay', value: 'longstay' },
    ]

    const discountType = [
      { label: 'Gross Selling Price Discount', value: 'Gross Selling Price Discount' },
      { label: 'Net Discount', value: 'Net Discount' }
    ]

    const productTripTypes = _.chain(allSchemeTypes).map('value').filter((schemeType) => {
      return _.get(product, 'attributes.metadata.group_type_trip_' + schemeType);
    }).value();

    const schemeTypes = _.filter(allSchemeTypes, (schemeType) => {
      return productTripTypes.includes(schemeType.value);
    })

    const schemeType = _.get(formValues, 'data.attributes.criterion.scheme_type.equal');
    const groupTypes = _.get(product, 'attributes.metadata.group_type_trip_' + schemeType)

    const parsedGroupTypes = _.chain(groupTypes).keys().map((key) => {
      return { label: groupTypes[key], value: key };
    }).value();

    const destinationOptions = _.get(product, 'attributes.metadata.regions');

    const parsedDestinationOptions = _.chain(destinationOptions).keys().map((key) => {
      return { label: destinationOptions[key], value: key };
    }).value();

    const maxStartDate = formValues && formValues.data && formValues.data.attributes && formValues.data.attributes.to_date;
    const minEndDate = formValues && formValues.data && formValues.data.attributes && formValues.data.attributes.from_date;

    return (
      <InternalContainer title='Admin' buttons={buttons}>
        <Box className={saving ? 'currently-saving' : ''}>
          <h4 className='title-campaign'>New Campaign</h4>
          <hr id='first-row' />
          <form onSubmit={handleSubmit} autoComplete='off'>
            <div className='row'>
              <div className='col-sm-6'>
                <div className='form-horizontal'>
                  <Field
                    name='data.attributes.name'
                    label='Name'
                    labelSize={4}
                    component={HorizontalFormControl}
                    validate={[required({ msg: 'Field is required' })]}
                  />
                  <Field
                    name='data.attributes.code'
                    label='Voucher Code'
                    normalize={upper}
                    labelSize={4}
                    component={HorizontalFormControl}
                    validate={[required({ msg: 'Field is required' })]}
                  />
                  <Field
                    name='data.attributes.exclusive'
                    label={<strong>Exclusive</strong>}
                    component={CheckboxUnlabeled}
                    positionTop
                    inline
                    labelSize={4}
                    options={[
                      { label: '', value: true }
                    ]}
                  />
                  <Field
                    name='data.attributes.has_url'
                    label={<strong>Has url?</strong>}
                    component={CheckboxUnlabeled}
                    positionTop
                    inline
                    labelSize={4}
                    options={[
                      { label: '', value: true }
                    ]}
                  />
                  <Field
                    name='data.attributes.metadata.prevent_display'
                    label={<strong>Hide Code</strong>}
                    component={CheckboxUnlabeled}
                    positionTop
                    inline
                    labelSize={4}
                    options={[
                      { label: '', value: true }
                    ]}
                  />
                </div>
              </div>

              <div className='col-sm-6'>
                <div className='form-horizontal'>
                  <Field
                    name='data.attributes.discount_type'
                    label='Discount Type'
                    multi
                    object
                    labelSize={4}
                    options={discountType}
                    component={SelectField}
                    validate={[required({ msg: 'Field is required' })]}
                  />
                  <Field
                    name='data.attributes.discount'
                    label='Discount'
                    labelSize={4}
                    object
                    mdFieldSize={4}
                    addonAfter={'%'}
                    component={HorizontalFormControl}
                  />

                  <Field
                    name='data.attributes.from_date'
                    label='Start Date'
                    labelSize={4}
                    mdFieldSize={7}
                    component={DatePickerLeisure}
                    showTime
                    timeFormat='HH:mm'
                    validate={[required({ msg: 'Field is required' })]}
                    maxDate={maxStartDate ? moment(maxStartDate).format() : null}
                  />
                  <Field
                    name='data.attributes.to_date'
                    label='End Date'
                    labelSize={4}
                    mdFieldSize={7}
                    component={DatePickerLeisure}
                    showTime
                    timeFormat='HH:mm'
                    minDate={minEndDate ? moment(minEndDate).format() : null}
                  />
                </div>
              </div>
            </div>

            <div>
              <hr id='second-row' />
              <div className='row'>
                <div className='col-sm-6'>
                  <div className='form-horizontal'>
                    <Field
                      name='data.relationships.channels.data'
                      label='Channels'
                      component={SelectChannel}
                      multi
                      object
                      labelSize={4}
                    />
                  </div>
                </div>
              </div>
            </div>

            <hr id='second-row' />
            <div className='row'>
              <div className='col-sm-6'>
                <div className='form-horizontal'>
                  <Field
                    name='data.relationships.product.data'
                    label='Products'
                    component={SelectProduct}
                    object
                    labelSize={4}
                  />
                </div>
              </div>
            </div>

            {product && (
              <div>
                <hr id='second-row' />
                <div className='row'>
                  <div className='col-sm-6'>
                    <div className='form-horizontal'>
                      <Field
                        name='data.attributes.criterion.scheme_type.equal'
                        label='Scheme Type'
                        multi
                        object
                        labelSize={4}
                        options={schemeTypes}
                        component={SelectField}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {product && schemeType && (
              <div>
                <hr id='third-row-banner' />
                <div className='row'>
                  <div className='col-sm-6'>
                    <div className='form-horizontal'>
                      <Field
                        label='Group Type'
                        name='data.attributes.criterion.traveller_group.in'
                        component={this.familyGroupsSelection}
                        options={parsedGroupTypes}
                      />
                      <Field
                        label='Destinations'
                        name='data.attributes.criterion.region.in'
                        component={this.destinationSelection}
                        options={parsedDestinationOptions}
                      />
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <div className='form-horizontal'>
                      <FormGroup controlId='age'>
                        <Col componentClass={ControlLabel} sm={4}>
                          Target Ages
                        </Col>
                        <Col sm={3}>
                          <Field
                            name='data.attributes.criterion.min_age.min'
                            type='text'
                            id='ageStart'
                            component={this.renderField}
                            disabled={this.state.allAges}
                          />
                        </Col>
                        <Col sm={1}>
                          <p className='text-adjust'>to</p>
                        </Col>
                        <Col sm={3}>
                          <Field
                            name='data.attributes.criterion.max_age.max'
                            type='text'
                            id='ageEnd'
                            component={this.renderField}
                            disabled={this.state.allAges}
                          />
                        </Col>
                        <Col sm={1}>
                          <p className={'select-all-link' + (this.state.allAges ? ' all-visited' : '')} onClick={() => this.allAges()}>All</p>
                        </Col>
                      </FormGroup>
                      <FormGroup controlId='range-fields'>
                        <Col componentClass={ControlLabel} sm={4}>
                          Target Duration
                        </Col>
                        <Col sm={3}>
                          <Field
                            name='data.attributes.criterion.duration.min'
                            type='text'
                            component={this.renderField}
                            disabled={this.state.allDurations}
                          />
                        </Col>
                        <Col sm={1}>
                          <p className='text-adjust'>to</p>
                        </Col>
                        <Col sm={3}>
                          <Field
                            name='data.attributes.criterion.duration.max'
                            type='text'
                            component={this.renderField}
                            disabled={this.state.allDurations}
                          />
                        </Col>
                        <Col sm={1}>
                          <p className={'select-all-link' + (this.state.allDurations ? ' all-visited' : '')} onClick={() => this.allDurations()}>All</p>
                        </Col>
                      </FormGroup>
                      <FormGroup controlId=''>
                        <Col componentClass={ControlLabel} sm={4}>
                          Target Cega Scores
                        </Col>
                        <Col sm={3}>
                          <Field
                            name='data.attributes.criterion.max_score.min'
                            type='text'
                            disabled={this.state.allScores}
                            id='cegaStart'
                            component={this.renderField}
                          />
                        </Col>
                        <Col sm={1}>
                          <p className='text-adjust'>to</p>
                        </Col>
                        <Col sm={3}>
                          <Field
                            name='data.attributes.criterion.max_score.max'
                            type='text'
                            disabled={this.state.allScores}
                            id='cegaEnd'
                            component={this.renderField}
                          />
                        </Col>
                        <Col sm={1}>
                          <p className={'select-all-link' + (this.state.allScores ? ' all-visited' : '')} onClick={() => this.allScores()}>All</p>
                        </Col>
                      </FormGroup>

                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.banner && (
              <div className='banner-container'>
                <hr id='fourth-row-banner' />
                <h4 className='title-campaign'>Banner</h4>
                <div className='row'>
                  <div className='col-sm-6'>
                    <div className='form-horizontal'>
                      <Field
                        name='data.attributes.banner_enabled'
                        label='Enabled'
                        component={CheckboxUnlabeled}
                        positionTop
                        inline
                        labelSize={4}
                        options={[
                          { label: '', value: true }
                        ]}
                      />
                      <Field
                        name='data.attributes.page'
                        label='Page'
                        labelSize={4}
                        component={HorizontalFormControl}
                      />
                      <Field
                        name='data.attributes.banner'
                        label='Banner Text'
                        labelSize={4}
                        component={HorizontalFormControl}
                      />
                    </div>
                  </div>
                  <div className='col-sm-6'>
                    <div className='form-horizontal'>
                      <FormGroup controlId='range-fields'>

                        <Col sm={4}>
                          <p className='image-upload-text'>Image Upload</p>
                        </Col>
                        <Col sm={4}>
                          <Button
                            type='button'
                            bsStyle='primary settings-button'
                            className='pull-right'
                            // disabled={submitting}
                            handleClick={this.handleUpload}>
                            {submitting ? <i className='fa fa-cog fa-spin' /> : <i className='fa' />} Select File
                          </Button>
                        </Col>
                      </FormGroup>
                      <Field
                        name='data.attributes.time_idle'
                        label='Time Idle'
                        labelSize={4}
                        mdFieldSize={4}
                        component={HorizontalFormControl}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className='row actions'>
              <div className='col-xs-12 clearfix'>
                <Button
                  size='large'
                  type='submit'
                  className='pull-right pad-right'
                  label='SAVE'
                  isLoading={saving}
                  bsStyle='primary'
                />
              </div>
            </div>
          </form>
        </Box>

        <UploadCropComponent onComplete={this.handleUploadComplete} width={200} height={200} bsSize='sm'
          circle={false} />
      </InternalContainer>
    )
  }
}

const validate = values => {
  const errors = {}
  const getChannel = _.get(values, 'data.relationships.channels.data')

  if (_.isEmpty(getChannel)) {
    _.set(errors, 'data.relationships.channels.data', 'Field is required')
  }
  const getProduct = _.get(values, 'data.relationships.product.data')

  if (_.isEmpty(getProduct)) {
    _.set(errors, 'data.relationships.product.data', 'Field is required')
  }

  return errors
}

const FORM_NAME = 'CampaignNewForm'
const form = reduxForm({ form: FORM_NAME, validate })(CampaignNewForm)
const selector = formValueSelector(FORM_NAME)

const mapStateToProps = (state, props) => {
  const values = selector(state,
    'data',
    'metadata'
  )

  return {
    auth: state.auth,
    dashboard: state.dashboard,
    initialValues: props.resource,
    formValues: values
  }
}

export default connect(mapStateToProps)(form)
