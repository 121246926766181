import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { InfoLabel, Box, Button, ButtonBar } from '../../../../common/components'
import displayName from '../../../../helpers/displayName'
import { hasPermission } from '../../../auth/redux/authActions'
import { respondToChallenge } from '../../redux/challengeActions'
import './styles.scss'

export default class ChallengeDetails extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    challenge: PropTypes.object.isRequired,
    resource: PropTypes.object.isRequired
  };

  markAsResponded() {
    this.props.dispatch(respondToChallenge(this.props.resource))
  }

  render() {
    const resource = this.props.resource.data.attributes

    return (
        <Box>
          <h2 className="resource-name">Challenge Request</h2>

          <div className="row">
            <div className="col-sm-6">
              <div className="form-horizontal">
                <InfoLabel label="Name" value={ resource.name } labelSize={4}/>
                <InfoLabel label="Email" value={ resource.email } labelSize={4}/>
                <InfoLabel label="Phone" value={ resource.phone1 } labelSize={4}/>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-horizontal">
                <InfoLabel label="Company" value={ resource.companyName } labelSize={4}/>
                <InfoLabel label="Status" value={ displayName(resource.status) } labelSize={4}/>
                <InfoLabel label="Created At" dateTime={true} value={ resource.created_at } labelSize={4}/>
              </div>
            </div>
          </div>

          <div className="hr"/>

          <div className="row">
            <div className="col-xs-12">
              <div className="form-horizontal challenge-display">
                <InfoLabel label="Challenge" value={ resource.message }/>
              </div>
            </div>
          </div>

          <ButtonBar>

            { (hasPermission('broker.edit') && resource.status == 'received') && (
                <Button
                    className="pull-right"
                    bsStyle="primary"
                    rightIcon="check"
                    isLoading={this.props.challenge.isSubmitting}
                    handleClick={::this.markAsResponded}>Mark as responded</Button>
            )}
          </ButtonBar>
        </Box>
    )
  }
}
