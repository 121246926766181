import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Field, formValueSelector, reduxForm } from 'redux-form'
import {
  Col,
  ControlLabel,
  FormGroup
} from 'react-bootstrap'
import displayName from '../../../../helpers/displayName'
import {
  InfoLabel,
  Button
} from '../../../../common/components'
import getIncludedResource from '../../../../helpers/getIncludedResource'
import './CampaignDetails.scss'

/******************************
 Campaign Details - Read Only
 *****************************/

class CampaignDetails extends Component {
  render () {
    const { resource: fullResource, initialValues } = this.props
    const urls = _.get(fullResource, 'data.attributes.urls')
    const channels = getIncludedResource(initialValues.data, initialValues.included, 'channels')
    const product = getIncludedResource(initialValues.data, initialValues.included, 'product')
    const resource = _.get(fullResource, 'data.attributes')

    return (
      <div>
        <div className='campaign-details-header'>
          <h4>Campaign Details</h4>
        </div>
        <div className='campaign-details-container'>
          <form autoComplete='off'>
            <div className='row'>
              <div className='col-sm-6'>
                <div className='form-horizontal'>
                  <Field
                    name='data.attributes.name'
                    label='Name'
                    labelSize={4}
                    component={InfoLabel}
                  />
                  <Field
                    name='data.attributes.code'
                    label='Voucher Code'
                    labelSize={4}
                    component={InfoLabel}
                  />
                  <InfoLabel
                    name='data.attributes.metadata.prevent_display'
                    label='Hide Code'
                    labelSize={4}
                    value={_.get(resource, 'metadata.prevent_display', false) ? 'Yes' : 'No'}
                  />
                  <InfoLabel
                    name='data.attributes.has_url'
                    label='Has url?'
                    labelSize={4}
                    value={_.get(resource, 'has_url', true) ? 'Yes' : 'No'}
                  />
                  <div>Url(s): </div>
                  <ul className='list-group'>
                    {
                      _.map(urls, (attr, key) => {
                        return (
                          <li key={key} style={{ listStyleType: 'none' }}>{attr.url}</li>
                        )
                      })
                    }
                  </ul>
                </div>
              </div>

              <div className='col-sm-6'>
                <div className='form-horizontal'>
                  <InfoLabel
                    name='data.attributes.discount_type'
                    label='Discount Type'
                    value={_.get(fullResource, 'data.attributes.discount_type')}
                    labelSize={4}
                  />
                  <Field
                    name='data.attributes.discount'
                    label='Discount'
                    labelSize={4}
                    object
                    mdFieldSize={4}
                    addonAfter={'%'}
                    component={InfoLabel}
                  />
                  <InfoLabel
                    value={_.get(fullResource, 'data.attributes.exclusive') === true ? 'Enabled' : 'Disable'}
                    label='Exclusive'
                    labelSize={4}
                    mdFieldSize={7}
                  />
                  <Field
                    name='data.attributes.from_date'
                    dateTime
                    label='Start Date'
                    labelSize={4}
                    mdFieldSize={7}
                    component={InfoLabel}
                  />
                  <Field
                    name='data.attributes.to_date'
                    dateTime
                    label='End Date'
                    labelSize={4}
                    mdFieldSize={7}
                    component={InfoLabel}
                  />
                </div>
              </div>
            </div>
            <hr id='second-row' />
            <div className='row'>
              <div className='col-sm-6'>
                <div className='form-horizontal'>
                  <InfoLabel
                    label='Product'
                    labelSize={4}
                    value={_.get(product, 'data.attributes.name')}
                  />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-sm-6'>
                <div className='form-horizontal'>
                  <InfoLabel
                    label='Channels'
                    labelSize={4}
                    value={
                      _.map(channels, (channel, i) => {
                        let detail = i ? ', ' : ''
                        return channel.attributes ? (detail + channel.attributes.name) : detail + channel
                      })
                    }
                  />
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='form-horizontal'>
                  <InfoLabel
                    name='data.attributes.criterion.scheme_type.equal'
                    label='Scheme Type'
                    value={displayName(_.get(fullResource, 'data.attributes.criterion.scheme_type.equal'))}
                    labelSize={4}
                  />
                </div>
              </div>
            </div>
            <hr id='third-row' />
            <div className='row'>
              <div className='col-sm-6'>
                <div className='form-horizontal'>
                  <InfoLabel
                    label='Family Group'
                    labelSize={4}
                    name='data.attributes.criterion.traveller_group.in'
                    value={
                      _.map(_.get(resource, 'criterion.traveller_group.in', []), (traveller, i) => {
                        let detail = i ? ', ' : ''
                        return traveller.attributes ? (detail + displayName(traveller.attributes.name)) : detail + displayName(traveller)
                      })
                    }
                  />
                  <InfoLabel
                    label='Destinations'
                    labelSize={4}
                    name='data.attributes.criterion.region.in'
                    value={
                      _.map(_.get(resource, 'criterion.region.in', []), (destination, i) => {
                        let detail = i ? ', ' : ''
                        return destination.attributes ? (detail + displayName(destination.attributes.name)) : detail + displayName(destination)
                      })
                    }
                  />
                </div>
              </div>
              <div className='col-sm-6'>
                <div className='form-horizontal'>

                  <FormGroup controlId='age'>
                    <Col componentClass={ControlLabel} sm={4}>
                      Target Ages
                    </Col>
                    <Col sm={3}>
                      <Field
                        name='data.attributes.criterion.min_age.min'
                        type='text'
                        id='ageStart'
                        component={InfoLabel}
                      />
                    </Col>
                    {_.get(this.props.formValues, 'data.attributes.criterion.max_age.max') &&
                    <Col sm={1}>
                      <p className='text-adjust'>to</p>
                    </Col>
                    }
                    <Col sm={3}>
                      <Field
                        name='data.attributes.criterion.max_age.max'
                        type='text'
                        id='ageEnd'
                        component={InfoLabel}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup controlId='range-fields'>
                    <Col componentClass={ControlLabel} sm={4}>
                      Target Duration
                    </Col>
                    <Col sm={3}>
                      <Field
                        name='data.attributes.criterion.duration.min'
                        type='text'
                        component={InfoLabel}
                      />
                    </Col>
                    {_.get(this.props.formValues, 'data.attributes.criterion.duration.max') &&
                    <Col sm={1}>
                      <p className='text-adjust'>to</p>
                    </Col>
                    }
                    <Col sm={3}>
                      <Field
                        name='data.attributes.criterion.duration.max'
                        type='text'
                        component={InfoLabel}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup controlId=''>
                    <Col componentClass={ControlLabel} sm={4}>
                      Target Healix Scores
                    </Col>
                    <Col sm={3}>
                      <Field
                        name='data.attributes.criterion.max_score.min'
                        type='text'
                        id='healixStart'
                        component={InfoLabel}
                      />
                    </Col>
                    {_.get(this.props.formValues, 'data.attributes.criterion.max_score.max') &&
                    <Col sm={1}>
                      <p className='text-adjust'>to</p>
                    </Col>
                    }
                    <Col sm={3}>
                      <Field
                        name='data.attributes.criterion.max_score.max'
                        type='text'
                        id='healixEnd'
                        component={InfoLabel}
                      />
                    </Col>
                  </FormGroup>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className='row actions'>
          <div className='clearfix'>
            <Button
              link
              label='Create New Version'
              bsStyle='primary'
              to={'/admin/campaigns/' + fullResource.data.id + '/edit'}
              rightIcon='plus-circle'
              className='pull-right'
            />
          </div>
        </div>
      </div>
    )
  }
}

const FORM_NAME = 'CampaignDetails'
const form = reduxForm({ form: FORM_NAME })(CampaignDetails)
const selector = formValueSelector(FORM_NAME)
const mapStateToProps = (state, props) => {
  if (
    Array.isArray(props.resource.data.attributes.metadata) &&
    !props.resource.data.attributes.metadata.length
  ) {
    props.resource.data.attributes.metadata = {}
  }

  const initialValues = props.resource
  const values = selector(state, 'data', 'metadata')
  return {
    initialValues,
    formValues: values
  }
}

export default connect(mapStateToProps)(form)
