import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import GuestHeader from '../components/GuestHeader/GuestHeader'
import InternalHeader from '../components/InternalHeader/InternalHeader'

class HeaderContainer extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
  }

  render() {
    if (this.props.auth.authenticated) {
      return (<InternalHeader {...this.props} /> )
    }
    return ( <GuestHeader {...this.props} /> )
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    layout: state.layout
  }
}

export default connect(mapStateToProps)(HeaderContainer)
