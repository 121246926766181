import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import { MenuItem, InputGroup } from 'react-bootstrap'
import Button from '../Button/Button'
import { PlainTextField } from '../ReduxFormField'
import './PostcodeList.scss'
import { getAddresses, getAddress } from './redux/PostcodeActions'

const InputGroupButton = InputGroup.Button

class PostcodeList extends Component {

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    value: PropTypes.string,
    name: PropTypes.string.isRequired,
    change: PropTypes.func.isRequired,
    targetFields: PropTypes.object.isRequired,
    selections: PropTypes.object,
    addresses: PropTypes.object,
    infoButton: PropTypes.object,
    labelSize: PropTypes.number,
    mdFieldSize: PropTypes.number
  };

  handlePostcodeSearch(postcode) {
    return () => {
      this.props.dispatch(getAddresses(postcode))
    }
  }

  handleAddressSelect(addressId) {
    const fieldId = this.props.name
    return () => {
      this.props.dispatch(getAddress(addressId, fieldId))
    }
  }

  componentWillReceiveProps({ dispatch, change, selections, targetFields, name }) {
    const fieldId = name
    const availableFields = ['organisation', 'line1', 'line2', 'line3', 'town', 'county', 'country', 'postcode']
    const address = selections[fieldId]

    if (address && address != this.props.selections[fieldId] && address.data) {
      availableFields.map(targetField => {

        if (targetField == 'organisation' && address.data.attributes[targetField] == '') {
          return
        }

        if (targetFields[targetField]) {
          dispatch(change(targetFields[targetField], address.data.attributes[targetField]))
        }

      })
    }
  }

  render() {
    const { addresses, label, name, value, labelSize, mdFieldSize, infoButton, isLoading, menuOpen } = this.props

    let labelClass = '', wrapperClass = ''
    if (labelSize) {
      labelClass += ' col-xs-' + labelSize
      wrapperClass += ' col-xs-' + (12 - labelSize)

      if (mdFieldSize) {
        wrapperClass += ' col-md-' + mdFieldSize
      }
    }

    return (
        <div className="form-group postcode-list">
          <div className={labelClass}>
            <label className="control-label">{ label }</label> {infoButton}
          </div>
          <div className={wrapperClass}>
            <Field
                name={name}
                type="text"
                component={PlainTextField}
            />
              <Button type="button" className="search-btn btn btn-primary" handleClick={::this.handlePostcodeSearch(value)}
                      label="Search" isLoading={isLoading}/>



              <ul className={'dropdown-menu' + (menuOpen ? ' open' : '')}>
                <MenuItem
                    header>{ addresses.data && Object.keys(addresses.data).length ? 'Please select your address from the following...' : 'No addresses found for postcode' }</MenuItem>
                { addresses.data && Object.keys(addresses.data).map(key => {
                  return (
                      <MenuItem key={key} onClick={::this.handleAddressSelect(addresses.data[key].id)}>
                        { addresses.data[key].attributes.name }
                      </MenuItem>
                  )
                })}
              </ul>

          </div>
        </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    addresses: state.postcode.addresses,
    selections: state.postcode.selections,
    isLoading: state.postcode.isLoading,
    menuOpen: state.postcode.menuOpen,
  }
}

export default connect(mapStateToProps)(PostcodeList)
