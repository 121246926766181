import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import {
  RadioGroup,
  CheckboxGroup
} from '../../../../../../common/components/ReduxFormField'
import {
  HorizontalFormControl,
  Button,
  InfoButton,
  DatePicker
} from '../../../../../../common/components'
import { openModal } from '../../../../../../common/components/Modal/ModalRedux'
import { issuePolicy, saveQuotes, patchQuotes } from '../../../../redux/productActions'
import displayCurrency from '../../../../../../helpers/displayCurrency'
import getIncludedResource from '../../../../../../helpers/getIncludedResource'
import { hasPermission } from '../../../../../auth/redux/authActions'
import * as productActions from '../../../../redux/productActions'
import Markdown from 'react-commonmark'
import moment from 'moment'
import 'moment/locale/en-gb'
// eslint-disable-next-line you-dont-need-lodash-underscore/get
import { get, set } from 'lodash'
// import './styles.scss'
import axios from 'axios'
import ExpiryDateFields
  from '../../../../../../common/components/ReduxFormField/ExpiryDateFields';
import Loader from '../../../../../../common/components/Loader/Loader';


const validate = values => {
  const errors = {}

  if (!get(values, 'meta.purchase_declaration', false)) {
    set(errors, 'meta.purchase_declaration', 'Required')
  }

  if (get(values, 'meta.product_code') !== 'ETIR') {
    if (!get(values, 'meta.start_date', false)) {
      set(errors, 'meta.start_date', 'Required')
    }
  }

  if (parseFloat(get(values, 'meta.amount')) != 0.00 && !get(values, 'meta.payment_type', false)) {
    set(errors, 'meta.payment_type', 'Required')
  }

  if (get(values, 'meta.payment_type', false) === 'card') {
    if (!get(values, 'meta.card_number', false)) {
      set(errors, 'meta.card_number', 'Required')
    }
    if (!get(values, 'meta.cvv', false)) {
      set(errors, 'meta.cvv', 'Required')
    }
    if (!get(values, 'meta.expiry_month', false)) {
      set(errors, 'meta.expiry_month', 'Required')
    }
    if (!get(values, 'meta.expiry_year', false)) {
      set(errors, 'meta.expiry_year', 'Required')
    }
  }

  if (get(values, 'meta.payment_type', false) === 'recharge') {
    if (!get(values, 'meta.transaction_id', false)) {
      set(errors, 'meta.transaction_id', 'Required')
    }
    if (!get(values, 'meta.cvv', false)) {
      set(errors, 'meta.cvv', 'Required')
    }
  }

  return errors
}

class PaymentTab extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    mta: PropTypes.bool,
    policyId: PropTypes.string,
    application: PropTypes.object,
    currentProduct: PropTypes.object,
    saveApplication: PropTypes.func,
    declaration: PropTypes.object,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.string,
    bsStyle: PropTypes.string,
    block: PropTypes.bool
  };

  constructor(props) {
    super(props)

    this.state = {
      premium: null,
      currency: null,
      loading: false,
      application: null,
      applicationErrors: []
    }

    this.setPremium = this.setPremium.bind(this)
    this.handleWindowMessage = this.handleWindowMessage.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.transformDocLinks = this.transformDocLinks.bind(this)
  }

  setPremium(application, openAfter = true, errors = []) {
    const { dispatch, change, currentProduct, schemeId } = this.props
    if (application) {
      const premiums = getIncludedResource(application.data, application.included, 'premiums')
      const customer = getIncludedResource(application.data, application.included, 'customer')
      const validationErrors = application.data.attributes.validation
      let selectedPremium = null

      // Populate dates
      if (get(currentProduct, 'data.attributes.metadata.adjustable_end_date', false)) {
        const schemes = getIncludedResource(application.data, application.included, 'schemes')
        if (schemes && schemes.length && schemes[0].attributes.to_date) {
          this.setState({
            maxAdjustableStartDate: schemes[0].attributes.to_date,
          });
        }
        if (get(application, 'data.attributes.ends_at')) {
          dispatch(change('meta.start_date', application.data.attributes.starts_at))
          dispatch(change('meta.end_date', application.data.attributes.ends_at))
        } else {
          const startDate = moment().format()
          dispatch(change('meta.start_date', startDate))
          dispatch(change('meta.end_date', moment(startDate).add(1, 'years').subtract(1, 'days').format()))
        }
      }

      if (premiums.length) {

        if (premiums.length === 1) {

          selectedPremium = premiums[0]
          dispatch(change('data.relationships.premium.data.id', premiums[0].id))
          dispatch(change('meta.amount', premiums[0].attributes.gross))
        } else {
          premiums.map(premium => {
            if (get(premium, 'attributes.scheme.id') === schemeId
              || get(premium, 'attributes.premium_type') === 'return premium') {
              selectedPremium = premium

              dispatch(change('data.relationships.premium.data.id', premium.id))
              dispatch(change('meta.amount', premium.attributes.gross))
            }
          })
        }
      }

      if (customer) {
        dispatch(change('meta.sms_number', customer.data.attributes.phone1))
      }

      if (validationErrors) {
        Object.keys(validationErrors).map((errorKey) => {
          if (!validationErrors[errorKey]) {
            errors.push(errorKey + ' required')
          }
        })
      }

      this.setState({
        premium: selectedPremium,
        currency: get(selectedPremium, 'attributes.currency'),
        loading: false,
        application
      })
    }

    this.setState({ loading: false, applicationErrors: errors })

    if (openAfter) {
      dispatch(openModal(FORM_NAME + schemeId))
    }
  }

  handleSubmit(values) {
    if (!this.state.application || !this.state.application.data.id) {
      return false
    }
    return this.props.dispatch(issuePolicy(this.state.application.data.id, values))
  }

  transformDocLinks(url) {
    const { productState: { quotes }, application, schemeId } = this.props

    let documents = {}
    if (application) {
      const applicationDocuments = getIncludedResource(application.data, application.included, 'documents')

      applicationDocuments.forEach((document) => {
        documents[document.attributes.short_name] = document.attributes.file_path
      })
    } else {
      const currentQuoteId = Object.keys(quotes).filter(quoteId => {
        return get(quotes[quoteId], 'meta.scheme.id') === schemeId
      })

      documents = get(quotes[currentQuoteId], 'meta.scheme.documents')
    }

    if (url === 'policy_wording') {
      return get(documents, 'Policy Wording', url)
    } else if (url === 'policy_summary') {
      return get(documents, 'Policy Summary', url)
    } else if (url === 'tobca') {
      return get(documents, 'Terms of Business Customer Agreement', url)
    } else if (url === 'ipid') {
      return get(documents, 'Insurance Product Information Document', url)
    }

    return url
  }

  componentWillMount() {
    if (process.env.BROWSER) {
      window.addEventListener('message', this.handleWindowMessage)
    }

    const {
      dispatch,
      saveApplication,
      currentProduct,
      mta,
      policyId,
      application
    } = this.props

    if (saveApplication) {
      this.setState({ loading: true, premium: null, errors: [] })
      const applicationId = this.state.application ? this.state.application.data.id : null

      return dispatch(saveApplication((values) => {
        if (mta && policyId && !get(values, 'data.relationships.policy.data.id')) {
          set(values, 'data.relationships.policy.data.id', policyId)
        }

        if (applicationId) {
          set(values, 'data.id', applicationId)
        }

        dispatch(applicationId ? patchQuotes(values, this.setPremium) : saveQuotes(values, currentProduct, this.setPremium))
      }))
    } else {
      this.setPremium(application)
    }
  }

  componentWillUnmount() {
    const { dispatch, currentProduct } = this.props

    if (process.env.BROWSER) {
      window.removeEventListener('message', this.handleWindowMessage)
    }

    if (currentProduct.isCompletingTransaction) {
      dispatch(productActions.transactionEmergencyBrakes())
    }
  }

  handleWindowMessage(event) {
    const { dispatch } = this.props

    // For Chrome, the origin property is in the event.originalEvent object.
    const origin = event.origin || event.originalEvent.origin

    if (origin !== axios.defaults.baseURL) {
      return
    }

    const data = event.data

    if (data.status !== 'complete') {
      dispatch(productActions.failTransaction(data.message))
      return
    }

    dispatch(productActions.completeTransaction(data.policies[0].policy_id))
  }

  render() {
    const {
      submitting,
      handleSubmit,
      productState,
      openSpecificTab,
      procycle
    } = this.props

    let isValid = false
    let submitButton = (<div/>)

    if (get(this.state, 'application.data.attributes.valid') === undefined) {
      return <Loader/>
    }

    if (get(this.state, 'application.data.attributes.valid')) {
      isValid = true
      submitButton = (<Button
        type="submit"
        bsStyle="success"
        isLoading={submitting}
        handleClick={handleSubmit(this.handleSubmit)}
        label="Issue Policy"
        className="pull-right"
        rightIcon="check-circle"
      />)
    }

    return (

      <div>
        {productState.isCompletingTransaction ?
          this.renderCompleteTransactionFrame()
          : this.renderForm(isValid)}
        <div className="clearfix m-b-20"/>
        <Button className="btn-action-back" label={'Back'} bsStyle="secondary"
                handleClick={() => openSpecificTab(procycle ? 4 : 5)}/>
        {submitButton}
      </div>
    )
  }

  renderCompleteTransactionFrame() {
    const { productState } = this.props

    const url = axios.defaults.baseURL + '/public/transactions/' + productState.transactionId + ':pay'

    return (
      <div className="payment__container">
        <iframe src={url} className="payment__iframe"/>
      </div>
    )
  }

  renderForm(isValid) {
    const { formValues, declaration, currentProduct } = this.props
    if (!currentProduct || !currentProduct.data || !currentProduct.data.attributes) {
      return (
        <p>no currentProduct found</p>
      )
    }

    const taxType = currentProduct.data.attributes.metadata.tax_type
    const selectedPremium = this.state.premium
    const currency = this.state.currency

    const paymentInfo = '**Broker Account** \n\nWill place the premium on your account with Arch, with settlement due to Arch within 30 days. \n\n**Card** \n\nAllows your client to settle premium directly to Arch by credit/debit card. Your commissions will be paid by Arch on a monthly basis.'
    const cardPayments = get(currentProduct, 'data.attributes.metadata.card_payments', true)
    const premiumValue = get(selectedPremium, 'attributes.gross', 0);
    const isRefund = premiumValue < 0;
    let paymentOptions = [{ label: 'Broker Account', value: 'account' }]

    if (premiumValue < 0) {
      paymentOptions = [{ label: 'Broker Account', value: 'no refund' }]
    }

    if (cardPayments) {
      if (premiumValue !== 0) {
        paymentOptions.push({ label: 'Card', value: 'card' })
      }
    }

    const luhn = require('luhn');
    const isLuhnHappy = () => (value) => {
      const val = value && value.replace(/\s/g, '');

      return luhn.validate(val) ? '' : 'This does not look like a valid card number';
    };

    const adjustableEndDate = get(currentProduct, 'data.attributes.metadata.adjustable_end_date', false);
    let maxAdjustableStartDate = null;
    if (adjustableEndDate) {
      maxAdjustableStartDate = moment().add(90, 'days').format();
      if (this.state.maxAdjustableStartDate) {
        maxAdjustableStartDate = this.state.maxAdjustableStartDate;
      }
    }

    return (
      <div className="row issue-btn">
        {!isValid && (
          <div className="col-xs-12">
            <h4>Not ready for issuing</h4>
            {this.state.applicationErrors.length > 0 && (
              <div>
                <p>Please resolve the following issues:</p>
                <ul>
                  {this.state.applicationErrors.map((errorItem, i) => (
                    <li key={i}>{errorItem}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        )}
        {isValid && selectedPremium && (
          <div className="col-xs-12">

            <h4>Declaration</h4>
            {declaration ? (
              <div>
                {Object.keys(declaration).map((declarationSection, i) => (
                  <div className="declaration" key={i}><Markdown
                    source={declaration[declarationSection]} linkTarget="_blank"
                    transformLinkUri={this.transformDocLinks}/></div>
                ))}
              </div>
            ) : (
              <ul>
                <li>The Insured is resident in the United Kingdom.</li>
                <li>Formal instructions have been received to bind cover.</li>
              </ul>
            )}
            <div className="row">
              <div className="col-xs-12">
                <Field
                  name={'meta.purchase_declaration'}
                  inline={true}
                  options={[
                    {
                      label: 'Please tick to confirm you agree to the above declaration',
                      value: true
                    }
                  ]}
                  component={CheckboxGroup}
                />
              </div>
            </div>

            {adjustableEndDate && (
              <div>
                <div className="hr"/>
                <div className="row">
                  <div className="col-xs-12">
                    <h4>Cover dates</h4>
                    <p>Please select when you would like your cover to start.</p>
                  </div>
                </div>
                <div className="row datepicker-container">
                  <div className="col-xs-12">
                    <Field
                      name="meta.start_date"
                      label="Start date"
                      minDate={hasPermission('policy.backdate_cover') ? moment().subtract(1, 'year').format() : moment().startOf('day').format()}
                      maxDate={maxAdjustableStartDate}
                      labelSize={3}
                      component={DatePicker}
                    />
                  </div>
                </div>
                <div className="row datepicker-container">
                  <div className="col-xs-12">
                    <Field
                      name="meta.end_date"
                      label="End date"
                      minDate={moment(get(formValues, 'meta.start_date', '')).format()}
                      maxDate={moment(get(formValues, 'meta.start_date', '')).add(2, 'years').subtract(1, 'days').format()}
                      labelSize={3}
                      component={DatePicker}
                    />
                  </div>
                </div>
              </div>
            )}

            {premiumValue !== 0.00 && (
              <div>
                <div className="row">
                  <div className="col-sm-12">
                    <h4>Payment details</h4>
                    <div className="hr"/>
                  </div>
                  <div className="col-sm-12 m-b-20">
                    <div className="premium-title">Premium:{' '}</div>
                    <span
                      className="premium">{displayCurrency(selectedPremium.attributes.gross, currency)}</span> Inc. {get(selectedPremium, 'attributes.metadata.rates.tax_rate')}% {taxType}
                  </div>
                  <div className="col-sm-12">

                    <div>
                      <span>Please select a payment method:</span><InfoButton
                      content={paymentInfo}/>
                    </div>

                    <div className="form-group">
                      <Field
                        name={'meta.payment_type'}
                        inline={true}
                        options={paymentOptions}
                        component={RadioGroup}
                      />
                    </div>
                    {get(formValues, 'meta.payment_type') === 'card' && !isRefund && (
                      <div className="card-details form-horizontal">
                        <div className="row">
                          <div className="col-xs-12">
                            <Field
                              name={'meta.card_number'}
                              label="Card number"
                              labelSize={3}
                              type="cardNumber"
                              cleaveOptions={{
                                creditCard: true,
                                creditCardStrictMode: true
                              }}
                              validate={[isLuhnHappy()]}
                              component={HorizontalFormControl}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <ExpiryDateFields
                              baseName={'meta'}
                              label="Expiry date"
                              labelSize={6}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-6">
                            <Field
                              className="text-center"
                              name={'meta.cvv'}
                              label="CVC/CV2"
                              labelSize={6}
                              component={HorizontalFormControl}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            <div className="hr"/>
            <div className="row">
              <div className="col-xs-12">
                <Field
                  name={'meta.send_sms'}
                  inline={true}
                  options={[
                    { label: 'Notify customer by SMS', value: true }
                  ]}
                  component={CheckboxGroup}
                />
              </div>
            </div>

            {get(formValues, 'meta.send_sms') && (
              <div className="row">
                <div className="col-xs-12">
                  <Field
                    name={'meta.sms_number'}
                    label="Phone number"
                    labelSize={3}
                    component={HorizontalFormControl}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

const FORM_NAME = 'PaymentForm'
const form = reduxForm({ form: FORM_NAME, validate })(PaymentTab)
const selector = formValueSelector(FORM_NAME)
const mapStateToProps = (state, props) => {
  const values = selector(state,
    'meta.product_code',
    'meta.payment_type',
    'meta.send_sms',
    'meta.amount',
    'meta.start_date',
    'data.relationships'
  )

  return {
    initialValues: {
      relationships: {
        premium: {
          data: {
            type: 'premium',
            id: null
          }
        }
      },
      meta: {
        product_code: get(props.currentProduct, 'data.attributes.product_code', ''),
        send_sms: false,
        start_date: ''
      }
    },
    formValues: values,
    productState: state.product,
  }
}

export default connect(mapStateToProps)(form)
