import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import './ButtonBar.scss'

const ButtonBar = (props) => {
  const { children } = props;
  return (
      <div className="row button-bar">
          <div className="col-xs-12 button-bar-box">
            {Array.isArray(children) && children.map((child, key) => (
              <Fragment key={key}>{child}</Fragment>
            ))}
            {!Array.isArray(children) && (
              <Fragment>{children}</Fragment>
            )}
          </div>
        </div>
  )
}

ButtonBar.propTypes = {
  children: PropTypes.node
}

export default ButtonBar
