import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { SelectField } from '../../../../common/components/ReduxFormField'
import { Box, ButtonBar, Button, DatePicker } from '../../../../common/components'
import { generateReport } from '../../redux/accountActions'
import _ from 'lodash'
import './styles.scss'

const FORM_NAME = 'performanceReportForm'

const validate = values => {
  const errors = {}

  if (!_.get(values, 'report')) {
    errors.report = 'Report type is required'
  }

  return errors
}

class PerformanceReports extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    isGeneratingReport: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      queue: false
    };
  }

  handleSubmit(values) {
    this.props.dispatch(generateReport(values))
  }

  handleGenerateOnQueue() {
    const { change, dispatch } = this.props;

    if (this.state.queue) {
      dispatch(change('generator', null));
    } else {
      dispatch(change('generator', 'queue'));
    }

    this.setState({ queue: !this.state.queue });
  }

  render() {
    const { formValues, handleSubmit, isGeneratingReport } = this.props

    const reportTypes = [
      { label: 'Agency Status Report', value: 'agency_status_report' },
      { label: 'Broker Quote Report', value: 'quote_report:bespoke' },
      { label: 'Log Ins', value: 'login_report' },
      { label: 'Referrers Report', value: 'referrers_report' },
      { label: 'Quotes Screening Performance Report', value: 'screening_performance_report_quotes:bespoke' },
      { label: 'Policies Screening Performance Report', value: 'screening_performance_report_policies:bespoke' },
    ]

    return (
      <Box>
        <h2 className="resource-name">Performance</h2>
        <form onSubmit={handleSubmit(::this.handleSubmit)}>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-horizontal">
                <Field
                    name="report"
                    label="Report Type"
                    labelSize={3}
                    component={SelectField}
                    options={reportTypes}
                />

                <div className="row">
                  <div className="col-sm-12 date-group">
                    <div className="title-1">
                      From Date
                    </div>
                    <div className="date-box">
                      <div className="date-1">
                        <Field
                          name="from_date"
                          label=""
                          labelSize={1}
                          component={DatePicker}
                        />
                      </div>
                      <div className="title-2">
                        To
                      </div>
                      <div className="date-2">
                        <Field
                          name="to_date"
                          label=""
                          minDate={_.get(formValues, 'from_date')}
                          labelSize={1}
                          component={DatePicker}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="col-sm-3">
                    Queue
                  </div>
                  <div className="col-sm-9">
                    <div className="queue-checkbox">
                      <div className="checkbox-container inline">
                        <div className="form-radio">
                          <input
                            id="Queue"
                            type="checkbox"
                            checked={this.state.queue}
                            onChange={() => this.handleGenerateOnQueue()}
                            value="generator"
                          />
                          <label htmlFor="Queue"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <ButtonBar>
            <Button
              className="pull-right"
              type="submit"
              label="Generate Report"
              isLoading={isGeneratingReport}
              bsStyle="primary"
            />
          </ButtonBar>
        </form>
      </Box>
    )
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(PerformanceReports)
const selector = formValueSelector(FORM_NAME)
const mapStateToProps = state => {
  const values = selector(state,
      'from_date',
      'to_date'
  )
  return {
    formValues: values,
    isGeneratingReport: state.account.isGeneratingReport
  }
}
export default connect(mapStateToProps)(form)
