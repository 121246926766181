import React, { Component } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Alert, Form, FormGroup, ControlLabel, Row, Col } from 'react-bootstrap';
import { FormattedDate } from 'react-intl';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { isEmpty } from 'lodash';
import flatten from 'flat';
import moment from 'moment';
import { Button, DataTable } from '../../../../common/components';
import { hasPermission } from '../../../auth/redux/authActions';
import validate from '../../../dashboard/containers/validate';
import {
  clearCustomerSearch,
  storeCustomerSearch,
} from '../../../dashboard/containers/searchActions/storeCustomerSearch';
import DatePickerDobMasked from '../../../../common/components/DatePicker/DatePickerDobMasked';
import { fetchData } from '../../../../common/components/DataTable/redux/dataTableActions';
import './CustomerMergeList.scss';

class CustomerMergeList extends Component {
  constructor(props) {
    super(props);
    this.handleSearchButton = this.handleSearchButton.bind(this);
    this.renderField = this.renderField.bind(this);
    this.handleAlertDismiss = this.handleAlertDismiss.bind(this);
    this.handleLastNameUpdate = this.handleLastNameUpdate.bind(this);
    this.handleNameUpdate = this.handleNameUpdate.bind(this);
    this.handleEmailUpdate = this.handleEmailUpdate.bind(this);
    this.handleDOBUpdate = this.handleDOBUpdate.bind(this);
    this.handlePostcodeUpdate = this.handlePostcodeUpdate.bind(this);
    this.state = {
      customFilter: '',
      alertVisible: true,
      name: '',
      surname: '',
      email: '',
      dob: '',
      postcode: '',
    };
  }

  componentDidMount() {
    this.props.dispatch(clearCustomerSearch());
  }

  handleAlertDismiss() {
    this.setState({
      alertVisible: false,
    });
  }

  renderField(field) {
    const { input, type, meta } = field;
    const className =
      meta.submitFailed && meta.touched && meta.error && meta.error.length > 0
        ? 'form-control has-error'
        : 'form-control';
    return (
      <div>
        <input {...input} className={className} type={type}/>
      </div>
    );
  }

  formatDob(cell) {
    return (
      <span>
        <FormattedDate value={new Date(cell)}/>
      </span>
    );
  }

  formatAddress(cell, row) {
    return row.address.postcode;
  }

  handleNameUpdate(event) {
    this.setState(() => ({
      name: event.target.value,
    }));
  }

  handleLastNameUpdate(event) {
    this.setState(() => ({
      surname: event.target.value,
    }));
  }

  handleEmailUpdate(event) {
    this.setState(() => ({
      email: event.target.value,
    }));
  }

  handleDOBUpdate(event) {
    const formattedDate = moment(event.target.value, 'DD/MM/YYYY').utcOffset(0, true);
    if (formattedDate.isValid()) {
      this.setState(() => ({
        dob: formattedDate.format(),
      }));
    } else {
      this.setState(() => ({
        dob: '',
      }));
    }
  }

  handlePostcodeUpdate(event) {
    this.setState(() => ({
      postcode: event.target.value,
    }));
  }

  handleSearchButton() {
    const { dispatch, formValues } = this.props;

    let filter1 = '';
    let filter2 = '';
    let filter3 = '';
    let filter4 = '';
    let filter5 = '';

    if (this.state.name != null) {
      filter1 = `&filter[name.first_name]=${this.state.name}`;
    }

    if (this.state.surname != null) {
      filter2 = `&filter[name.last_name]=${this.state.surname}`;
    }

    if (this.state.email != null) {
      filter3 = `&filter[email]=${this.state.email}`;
    }

    if (this.state.dob != null) {
      filter4 = `&filter[dob]=${this.state.dob}`;
    }

    if (this.state.postcode != null) {
      filter5 = `&filter[address.postcode]=${this.state.postcode}`;
    }

    const filters = [filter1, filter2, filter3, filter4, filter5];
    const customFilter = filters.reduce((carry, current) => {
      return carry + current;
    });

    this.setState(() => ({
      customFilter: customFilter,
    }));

    dispatch(fetchData('customers', customFilter));
    dispatch(storeCustomerSearch(formValues));
  }

  handleRowClick(row) {
    this.props.dispatch(push('/customers/' + row.id));
  }

  render() {
    const {
      handleSubmit,
      error,
      submitFailed,
      submitSucceeded,
      showCreateCustomer = true,
      handleRowClick,
      internalContainer = 'internal-container',
    } = this.props;

    const formErrors = error && error instanceof Object ? flatten(error) : false;

    let buttons = null;
    if (hasPermission('customer.create') && showCreateCustomer) {
      buttons = (
        <Button
          link
          to="/customers/new"
          className="pull-right"
          bsStyle="primary"
          label="New Customer"
          plane
        />
      );
    }
    return (
      <div>
        <div className={'col-sm-12 ' + internalContainer} style={{ background: 'white' }}>
          <div>
            <div className="get-quotes-header m-b-15">{buttons}</div>
            <div className="customer-box">
              <Form onSubmit={handleSubmit(this.handleSearchButton)}>
                <Row>
                  <Col sm={12}>
                    <h3 className="search-title m-t-30">
                      Searching for an Existing Customer to Merge
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <FormGroup>
                    <Col sm={2}>
                      <ControlLabel>First Name</ControlLabel>
                    </Col>
                    <Col sm={4}>
                      <Field
                        name="metadata.name"
                        type="text"
                        component={this.renderField}
                        placeholder="First Name"
                        onChange={this.handleNameUpdate}
                      />
                    </Col>

                    <Col sm={2}>
                      <ControlLabel>Surname</ControlLabel>
                    </Col>
                    <Col sm={4}>
                      <Field
                        name="metadata.surname"
                        type="text"
                        component={this.renderField}
                        placeholder="surname"
                        onChange={this.handleLastNameUpdate}
                      />
                    </Col>
                    <div className='clearfix'/>
                  </FormGroup>
                  <FormGroup>
                    <Col sm={2}>
                      <ControlLabel>Email</ControlLabel>
                    </Col>
                    <Col sm={4}>
                      <Field
                        name="metadata.email"
                        type="email"
                        component={this.renderField}
                        placeholder="email"
                        onChange={this.handleEmailUpdate}
                      />
                    </Col>

                    <Col sm={2}>
                      <ControlLabel>Date of Birth</ControlLabel>
                    </Col>
                    <Col sm={4}>
                      <Field
                        name="metadata.dob"
                        type="text"
                        showYear
                        maxDate={moment().format()}
                        component={DatePickerDobMasked}
                        placeholder="dob"
                        onChange={this.handleDOBUpdate}
                      />
                    </Col>
                    <div className='clearfix'/>
                  </FormGroup>
                  <FormGroup>
                    <Col sm={3} className="col-lg-2 col-md-3">
                      <ControlLabel>Postcode</ControlLabel>
                    </Col>
                    <Col sm={4}>
                      <Field
                        name="metadata.postcode"
                        type="text"
                        component={this.renderField}
                        placeholder="postcode"
                        onChange={this.handlePostcodeUpdate}
                      />
                    </Col>
                    <Col sm={6}>
                      <Button
                        type="submit"
                        leftIcon="search"
                        className={'merge-customer-search-customers-button'}
                        label={<span className="placeholder">Search Customers</span>}
                        bsStyle="primary"
                        search
                      />
                    </Col>
                  </FormGroup>
                </Row>
              </Form>
              {submitFailed && formErrors && this.state.alertVisible === true ? (
                <div className="search-has-error">
                  <Alert bsStyle="danger" onDismiss={this.handleAlertDismiss}>
                    Please complete at least 1 pieces of information.
                  </Alert>
                </div>
              ) : (
                <div/>
              )}
              <div className={!submitSucceeded ? 'table-hidden' : ''}>
                <hr />
                <DataTable
                  customFilter={this.state.customFilter}
                  source="/customers"
                  name="customers"
                  showTotal
                  onRowSelect={handleRowClick || this.handleRowClick.bind(this)}
                  //pageOff
                  customHeight={!submitSucceeded ? '79px' : ''}
                  style={{ minHeight: '5px' }}
                >
                  <TableHeaderColumn dataField="id" isKey hidden/>
                  <TableHeaderColumn dataField="first_name" width={'13%'}>
                    First Name
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField="last_name" width={'13%'}>
                    Surname
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="dob"
                    dataFormat={this.formatDob}
                    width={'10%'}
                  >
                    Date of Birth
                  </TableHeaderColumn>
                  <TableHeaderColumn dataField="email" width={'22%'} dataSort>
                    Email
                  </TableHeaderColumn>
                  <TableHeaderColumn
                    dataField="address"
                    width={'10%'}
                    dataFormat={this.formatAddress}
                  >
                    Postcode
                  </TableHeaderColumn>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const FORM_NAME = 'CustomerMergeList';
const form = reduxForm({ form: FORM_NAME, validate })(CustomerMergeList);
const selector = formValueSelector(FORM_NAME);

const mapStateToProps = (state, props) => {
  const values = selector(state, 'data', 'metadata');

  return {
    auth: state.auth,
    dashboard: state.dashboard,
    initialValues: props.resource,
    formValues: values,
  };
};

export default connect(mapStateToProps)(form);
