import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { push } from 'react-router-redux'
import { Button, Modal } from '../'
import { openModal, closeModal } from '../Modal/ModalRedux'
import * as _ from 'lodash'
import SelectProduct from '../Selects/SelectProduct';

const FORM_NAME = 'selectProductForm'

const validate = values => {
  const errors = {}

  if (!_.get(values, 'data.attributes.product.id', false)) {
    _.set(errors, 'data.attributes.product.id', 'Required')
  }
  return errors
}

class CreateQuoteFromCustomerButton extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    resource: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleOpen = this.handleOpen.bind(this)
  }

  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded, callback } = this.props
    if (newProps.submitSucceeded && !submitSucceeded) {
      if (callback) {
        callback('')
      }
      dispatch(closeModal(FORM_NAME))
    }
  }

  handleSubmit(values) {
    const productId = _.get(values, 'data.attributes.product.id');
    const reference = this.props.resource.data.id

    this.props.dispatch(push(`/products/${productId}/quote/?customer=${reference}`))
  }

  handleOpen() {
    this.props.dispatch(openModal(FORM_NAME))
  }

  render() {
    const { handleSubmit, submitting } = this.props

    const modalFooter = (
      <div>
        <Button
          type='submit'
          bsStyle='primary'
          className='pull-right'
          isLoading={submitting}
          handleClick={handleSubmit(this.handleSubmit)}
          label='Create Quote'
        />
      </div>
    )

    return (
      <div>
        <Button
          bsStyle='primary'
          {...this.props}
          label={'New Quote'}
          handleClick={this.handleOpen}
        />

        <Modal
          name={FORM_NAME}
          title='New Quote'
          close
          footer={modalFooter}
        >
          <form>
            <div className='form-horizontal create-note-btn'>
              <div className='col-xs-12'>
                <Field
                  name={'data.attributes.product.id'}
                  label="Select Product"
                  labelSize={3}
                  type='textarea'
                  component={SelectProduct}
                />
              </div>
            </div>
          </form>
        </Modal>
      </div>
    )
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(CreateQuoteFromCustomerButton)
const selector = formValueSelector(FORM_NAME)
const mapStateToProps = (state, props) => {
  const values = selector(state,
    'data.attributes.product.id',
  )

  return {
    formValues: values,
  }
}

export default connect(mapStateToProps)(form)
