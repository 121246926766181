import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { login } from '../redux/authActions'
import LoginForm from '../components/LoginForm/LoginForm'

class LoginContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };

  handleSubmit(values) {
    return this.props.dispatch(login(values.email, values.password))
  }

  render() {
    return (
      <div className="login-margin-top">
        <LoginForm onSubmit={this.handleSubmit.bind(this)}/>
      </div>
    )
  }
}

export default connect()(LoginContainer)
