import React, {Component} from 'react'
import {Breadcrumb, ButtonBar} from '../../../../common/components'
import './InternalSection.scss'
import {browserHistory} from 'react-router'
import InternalNotifications from '../InternalNotifications/InternalNotifications'


export default class InternalSection extends Component {

  render() {
    const getCurrentUrl = browserHistory ? browserHistory.getCurrentLocation() : ''

    return (
      <div className="main-container">
        <div className="row">
          <div className="internal-title">
            <h2>{this.props.title ? this.props.title : ' '}</h2>
            <InternalNotifications {...this.props}/>
          </div>

          {(getCurrentUrl.pathname !== '/dashboard') && (
            <Breadcrumb path={this.props.breadcrumb}/>
          )}

          <div className="internal-section">
            {this.props.children}
          </div>
        </div>
        {this.props.buttons && (
          <div className="bt-bar-responsive">
            <ButtonBar>
              {this.props.buttons}
            </ButtonBar>
          </div>
        )}
        {this.props.bottomBar && (
          <div className="invisible-bottom-bar">
            <ButtonBar/>
          </div>
        )}
      </div>
    )
  }
}
