export const SET_ORGANISATION_TYPES = 'socrates-online/layout/SET_ORGANISATION_TYPES'

export const initialState = {
  organisationTypes: []
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_ORGANISATION_TYPES:
      return {
        ...state,
        organisationTypes: action.data
      }
    default:
      return state
  }
}
