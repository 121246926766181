import React, { Component } from 'react'
import { isBroker } from '../../../auth/redux/authActions'
import _ from 'lodash'
import './StatsSectionTable.scss'

export default class StatsSectionTable extends Component {
  render() {
    const { stats } = this.props
    return (

      <div className="stats-section-table">
        <table className="table-dashboard">
          <thead>
          <tr>
            <td />
            <td>IP/AP</td>
            <td>RP</td>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td className="caption">GWP</td>
            <td>&pound;{(_.get(stats, 'gross_premiums.initial', 0) + _.get(stats, 'gross_premiums.additional', 0)).toFixed(2)}</td>
            <td>&pound;{(_.get(stats, 'gross_premiums.return', 0)).toFixed(2)}</td>
          </tr>

          {!isBroker() && (
            <tr>
              <td className="caption">Axiom %</td>
              <td>&pound;{(_.get(stats, 'commissions.owner.initial', 0) + _.get(stats, 'commissions.owner.additional', 0)).toFixed(2)}</td>
              <td>&pound;{(_.get(stats, 'commissions.owner.return', 0)).toFixed(2)}</td>
            </tr>
          )}

          <tr>
            <td className="caption">Broker %</td>
            <td>&pound;{(_.get(stats, 'commissions.brokers.initial',) + _.get(stats, 'commissions.brokers.additional', 0)).toFixed(2)}</td>
            <td>&pound;{(_.get(stats, 'commissions.brokers.return', 0)).toFixed(2)}</td>
          </tr>
          </tbody>
        </table>
      </div>
    )
  }
}