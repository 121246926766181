import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { FormControl, FormGroup } from 'react-bootstrap'
import './styles.scss'

const FormControlStatic = FormControl.Static

export default class Subjectivities extends Component {
  static propTypes = {
    subjectivities: PropTypes.array.isRequired
  };

  render() {
    const { subjectivities } = this.props

    return (
        <div>
          <h3>Subjectivities</h3>
          <div className="col-xs-12">
            {subjectivities.map((subjectivity, i) => (
                <FormGroup key={i} className="infolabel-wrapper">
                  <FormControlStatic>
                    { subjectivity }
                  </FormControlStatic>
                </FormGroup>
            ))}
          </div>
        </div>
    )
  }
}
