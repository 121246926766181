import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '../../components'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import UploadArea from './UploadArea'
import './Upload.scss'
import HTML5Backend from 'react-dnd-html5-backend'
import { DragDropContext } from 'react-dnd'
import axios from 'axios'
import _ from 'lodash'
import { closeModal } from '../Modal/ModalRedux'
//import { FileList } from '../../../modules/broker/BrokerView/FileList';
import { addFiles, removeFile, clearFiles } from './UploadRedux'

export class UploadModal extends Component {
  // TODO: Migrate to redux or move outside of class
  static MODAL_IDENTIFIER = 'upload-modal';
  static UPLOAD_NOT_READY = 'not-ready';
  static UPLOAD_READY = 'ready';
  static UPLOAD_IN_PROGRESS = 'in-progress';
  static UPLOAD_FINISHED = 'finished';

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
    maxFiles: PropTypes.number,
    note: PropTypes.string,
    accept: PropTypes.string,
  };

  static initialState = {
    files: [],
    errors: {},
    uploadState: 'not-ready'
  };

  timers = {};

  constructor(props) {
    super(props)

    this.state = UploadModal.initialState
  }

  addFilesToState(files) {
    if (this.state.uploadState === UploadModal.UPLOAD_IN_PROGRESS) {
      return
    }

    let fileCount = 0

    const filesToAdd = files
        .map(fileToAdd => {
          let skip = false

          if (this.props.maxFiles && (this.state.files.length >= this.props.maxFiles || fileCount >= this.props.maxFiles )) {
            return null
          }

          this.state.files.forEach(file => {
            if (fileToAdd.name === file.name) {
              skip = true
            }
          })

          if (skip) {
            return null
          }

          fileToAdd.progress = 0

          fileCount++

          return fileToAdd
        })
        .filter(file => {
          return file !== null
        })

    const stateFiles = [
      ...filesToAdd,
      ...this.state.files
    ]

    this.setState({
      files: stateFiles,
      uploadState: stateFiles.length > 0 ? UploadModal.UPLOAD_READY : UploadModal.UPLOAD_NOT_READY
    })
  }

  handleDropEvent({ files }) {
    this.addFilesToState(files)
  }

  handleUploadClick() {
    if (this.state.uploadState === UploadModal.UPLOAD_IN_PROGRESS) {
      return
    }

    this.refs.file.click()
  }

  handleFileChange(event) {
    // This is a FileList, it does not expose
    // normal array prototype methods
    const files = event.target.files
    const filesToAdd = []

    for (var i = 0; i < files.length; i++) {
      filesToAdd.push(files[i])
    }

    this.addFilesToState(filesToAdd)
  }

  handleRemoveFile(key) {
    return event => {
      const files = this.state.files.slice()
      files.splice(key, 1)

      this.setState({
        files: files
      })
      this.forceUpdate()
    }
  }

  startUpload(event) {
    const { dispatch, actions } = this.props
    this.setState({
      uploadState: UploadModal.UPLOAD_IN_PROGRESS
    })

    const promise = []
    const errors = []

    this.state.files.forEach((file, key) => {
      const config = {
        onUploadProgress: _.throttle(progressEvent => {
          const percentCompleted = progressEvent.loaded / progressEvent.total
          const { files } = this.state

          files[key].progress = Math.round((percentCompleted * 0.8) * 100)

          this.setState({
            files
          })
        }, 200)
      }

      const data = new FormData()
      data.append('file', file)


      const request = axios
          .post('/files', data, config)
          .then(response => {
            const { files } = this.state

            files[key].progress = 100

            this.setState({
              files
            })

            actions.addFiles([response.data.data])
          })
          .catch(error => {
            errors[file.name] = error.response.status

            const { files } = this.state

            files[key].progress = 0

            this.setState({
              files
            })

            throw error
          })

      promise.push(request)
    })

    Promise.all(promise)
        .then(() => {
          // TODO: Close Modal & Refresh table.
          this.setState({
            uploadState: UploadModal.UPLOAD_FINISHED
          })

          dispatch(closeModal(UploadModal.MODAL_IDENTIFIER))
          this.handleOnHide()
        })
        .catch(() => {
          this.setState({
            uploadState: UploadModal.UPLOAD_FINISHED,
            errors
          })
        })
  }

  renderUploadButton() {
    const label = this.state.uploadState === UploadModal.UPLOAD_IN_PROGRESS ? 'Uploading...' : 'Start Upload'
    const isDisabled = this.state.uploadState === UploadModal.UPLOAD_NOT_READY
    const inProgress = this.state.uploadState === UploadModal.UPLOAD_IN_PROGRESS

    return (
        <button
            type="button"
            className="btn btn-primary pull-right"
            disabled={isDisabled || inProgress}
            onClick={::this.startUpload}
        >
          {inProgress ? <i className="fa fa-circle-o-notch fa-spin"/> : <i className="fa fa-upload"/>} {label}
        </button>
    )
  }

  handleOnHide() {
    // TODO: Gracefully handle in-flight uploads?
    this.setState(UploadModal.initialState)

    if (this.timers.upload) {
      this.timers.upload()
    }

    this.props.onComplete()
  }

  render() {
    const hidden = {
      display: 'none'
    }

    const noFiles = {
      marginTop: '-16px',
      // marginBottom: '18px',
      borderTop: 'none'
    }

    const { accept, note } = this.props

    const { files, errors, uploadState } = this.state

    return (
        <Modal
            name={UploadModal.MODAL_IDENTIFIER}
            title="Upload Files"
            close={true}
            footer={this.renderUploadButton()}
            onHide={::this.handleOnHide}
        >
          <ul className="upload-list" style={files.length > 0 ? {} : hidden}>
            {files.map((file, key) => {
              const progress = file.progress + '%'
              let errorMessage = ''

              if (Object.keys(errors).indexOf(file.name) > -1) {
                const error = errors[file.name]

                if (error === 413) {
                  errorMessage = 'Error uploading: File too large - Max size 10MB'
                }
              }

              return (
                  <li key={key}>
                    <div className="upload-progress" style={{ width: progress }}></div>

                    <a onClick={this.handleRemoveFile(key)} disabled={uploadState === UploadModal.UPLOAD_IN_PROGRESS}><i
                        className="fa fa-times"/></a>

                    <div className="content">
                      <p className="pull-right">{progress}</p> {file.name} {errorMessage ?
                        <i>({errorMessage})</i> : null}
                    </div>
                  </li>
              )
            })}
          </ul>

          <UploadArea
              style={files.length > 0 ? {} : noFiles }
              onDrop={::this.handleDropEvent}
              onClick={::this.handleUploadClick}
              isUploading={uploadState === UploadModal.UPLOAD_IN_PROGRESS}
              accept={accept}
          />

          <input
              type="file"
              ref="file"
              style={hidden}
              onChange={::this.handleFileChange}
              accept={accept}
              multiple
          />

          {note && (
              <p className="small text-center">{note}</p>
          )}
        </Modal>
    )
  }
}

function mapStateToProps(state) {
  return {
    upload: state.upload
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators({ addFiles, clearFiles, removeFile }, dispatch), dispatch }
}

const dragAndDropComponent = DragDropContext(HTML5Backend)(UploadModal)

export default connect(mapStateToProps, mapDispatchToProps)(dragAndDropComponent)
