import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box, DataTable } from '../../../../common/components'
import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions'
import { FormControl, ControlLabel } from 'react-bootstrap'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { FormattedDate, FormattedTime } from 'react-intl'
import getStatus from '../../../../schemas/policy'
import { push } from 'react-router-redux'

export default class CustomerApplications extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired
  };

  formatDate(cell) {
    return (
        <span><FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/></span>
    )
  }

  handleRowClick(row) {
    this.props.dispatch(push('/policies/applications/' + row.id))
  }

  handleFilterUpdate(event) {
    this.props.dispatch(applyFilter('applications', 'filter[version]', event.target.value))
  }

  getStatus(status) {
    const resource = this.props.resource.data.attributes
    if (resource.quote_type === 'mid term adjustment' && status === 'quote') {
      return 'awaiting approval'
    }

    return getStatus(status)
  }

  render() {
    const resource = this.props.resource.data.attributes

    return (
      <div>
        <Box>
          <h2 className="resource-name">{ resource.customer_name }</h2>

          <div className="row table-filters">
            <div className="col-sm-3">
              <ControlLabel className="filter-label">Quote Version</ControlLabel>
              <FormControl componentClass="select"
                           type="select"
                           onChange={::this.handleFilterUpdate}
                           placeholder="select"
              >
                <option value="">Current</option>
                <option value="all">All</option>
              </FormControl>
            </div>
          </div>

          <DataTable
            source={'/policies/applications'}
            autoFilter={this.props.resource.data.id}
            autoFilterType="filter[customer]"
            name="applications"
            showTotal={true}
            onRowSelect={this.handleRowClick.bind(this)}
          >

            <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
              ID
            </TableHeaderColumn>

            <TableHeaderColumn dataField="quote_reference" width={'100px'}>
              Quote Reference
            </TableHeaderColumn>

            <TableHeaderColumn dataField="version" width={'125px'} dataAlign={'center'}>
              Version
            </TableHeaderColumn>

            <TableHeaderColumn dataField="product_name" width={'150px'}>
              Product
            </TableHeaderColumn>
            <TableHeaderColumn dataField="insured" width={'150px'}>
              Insured
            </TableHeaderColumn>

            <TableHeaderColumn dataField="status" dataSort={true} width={'100px'} dataFormat={getStatus}>
              Status
            </TableHeaderColumn>

            <TableHeaderColumn dataField="created_at" dataFormat={this.formatDate} dataSort={true} width={'100px'}>
              Quote Date
            </TableHeaderColumn>
          </DataTable>
        </Box>
      </div>
    )
  }
}
