import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Tabs, Tab } from 'react-bootstrap'
import { getChallenge } from '../redux/challengeActions'
import { Button, Loader } from '../../../common/components'
import InternalContainer from '../../layout/containers/Internal'
import ChallengeDetails from '../components/ChallengeDetails'
import ChallengeNotes from '../components/ChallengeNotes'

class ChallengeShowContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    challenge: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired
  }

  constructor(props, context) {
    super(props, context)

    this.state = {
      activeTab: 1
    }
  }

  componentWillMount() {
    this.props.dispatch(getChallenge(this.props.params.id))
  }

  handleTab(key) {
    this.setState({ activeTab: key })
  }

  render() {
    const resource = this.props.challenge.resources[this.props.params.id]
    const buttons = (
        <Button
            bsStyle="default"
            label="back"
            isBackButton={true}
            leftIcon="caret-left"
        />
    )

    return (
        <InternalContainer title="Challenge Axiom" buttons={buttons}>
          {resource ? (
                  <Tabs activeKey={this.state.activeTab}
                        id="challenge-tabs"
                        unmountOnExit={true}
                        onSelect={::this.handleTab}>
                    <Tab eventKey={1} title="Details">
                      <ChallengeDetails {...this.props} resource={resource}/>
                    </Tab>
                    <Tab eventKey={5} title="Notes">
                      <ChallengeNotes {...this.props} resource={resource}/>
                    </Tab>
                  </Tabs>
              ) : (
                  <Loader />
              )}
        </InternalContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    challenge: state.challenge
  }
}

export default connect(mapStateToProps)(ChallengeShowContainer)
