import filterString from './filterString'

export default function queryString(includes = [], meta = [], filters = []) {
  let string = ''

  if (includes.length > 0) {
    string += '?include='
    includes.forEach((include, includeKey) => {
      if (includeKey) {
        string += ','
      }
      string += include
    })
  }

  if (meta.length > 0) {
    string += (string === '' ? '?' : '&') + 'meta='
    meta.forEach((includeMeta, metaKey) => {
      if (metaKey) {
        string += ','
      }
      string += includeMeta
    })
  }

  if (filters.length > 0) {
    string += filterString(filters, string === '' ? '?' : '&')
  }

  return string
}
