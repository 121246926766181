import React, { Component } from 'react'
import { PieChart } from '../../../../common/components'
import './StatsSection.scss'

export default class StatsSection extends Component {
  render() {
    const { stats } = this.props
    return (
      <div className="stats-section">
        <PieChart data={stats}/>
      </div>
    )
  }
}