import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box, SelectTemplate } from '../../../../common/components'
import './TemplateSelector.scss'

export default class TemplateSelector extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    template: PropTypes.object,
  };

  render() {
    return (
        <Box>
          <div className="template-selector row">
            <SelectTemplate
                label="Template"
                input={{ onChange: ::this.props.onChange, value: this.props.template }}
                object={true}
                labelSize={2}
            />
          </div>
        </Box>
    )
  }
}
