import React, { Component } from 'react'
import { replace } from 'react-router-redux'
import { connect } from 'react-redux'
import Axios from 'axios'


class ContactContainer extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      content: {
        attributes: {}
      }
    }
  }

  componentWillMount() {
    Axios.get('public/contents?filter[slug]=contact').then(response => {
      if (response.data.data.length) {
        return this.setState({ content: response.data.data[0] })
      }
      return this.props.dispatch(replace('/404'))
    }).catch(error => {
      //this.props.dispatch(replace('/404'));
    })
  }

  render() {
    const content = this.state.content.attributes.sections
    return (
      <div>
        {content && (
          <div className="contact-us-box">
            <div className="guest-title"> Contact Us</div>
            <div className="box-frame" >
              <iframe
                className="mapbox"
                width="100%"
                height="800px"
                frameBorder="0"
                src="https://api.mapbox.com/styles/v1/axiomsocrates/cj3cp5myt00052sp78t9ux34p.html?title=true&access_token=pk.eyJ1IjoiYXhpb21zb2NyYXRlcyIsImEiOiJjajNibG1wYnUwMDVrMzJvOW83YXVmbmJwIn0.vyvr60Hxn0es4GUmlyq5Og#15/51.278541854572495/0.478782622600291"/>
            </div>

            <div className="contact-info-page">
              <div className="contact-info-box">
                <div className="head">
                  <h2>Address</h2>
                </div>
                <div className="box-body">
                  <p>First Floor<br/>
                    One Hermitage Court<br/>
                    Hermitage Lane<br/>
                    Maidstone, Kent<br/>
                    ME16 9NT
                  </p>
                </div>
              </div>

              <div className="contact-info-box">
                <div className="head">
                  <h2>Contact Details</h2>
                </div>
                <div className="box-body">
                  <div className="info-lines"><span className="colored">Telephone:</span> 08451 653157 </div>
                  <div className="info-lines"><span className="colored">Email:</span> patraveluw@archinsurance.com </div>
                  <div className="info-lines"><span className="colored">Opening Hours:</span> 9 a.m - 5 p.m Monday - Friday </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default connect()(ContactContainer)
