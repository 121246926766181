import React, { Component } from 'react'
import './styles.scss'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { Box } from '../../../../../../common/components'
import { formValueSelector, FieldArray } from 'redux-form'
import Directors from '../../../../../product/components/Directors'

const METADATA = 'data.attributes.organisation.metadata'

class Management extends Component {
  render () {
    const { formValues } = this.props
    return (
      <Box>
        <Row>
          <Col sm={12}>
            <h4 className='page-title'>Management Information</h4>
            <hr/>
            <div className='form-horizontal'>
              <div>
                <FieldArray
                  component={ Directors }
                  formValues={ formValues }
                  change={this.props.change}
                  dispatch={this.props.dispatch}
                  name={`${METADATA}.directors`}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Box>
    )
  }
}

const mapStateToProps = (state, props) => {
  const values = (formValueSelector(props.form))(state, 'data')

  return {
    auth: state.auth,
    form: props.form,
    formValues: values,
    product: state.product,
    destroyOnUnmount: false,
    initialValues: props.resource,
    forceUnregisterOnUnmount: true,
  }
}

export default connect(mapStateToProps)(Management)
