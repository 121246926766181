export const SET_ALERT = 'socrates-online/alert/SET_ALERT';
export const CLEAR_ALERT = 'socrates-online/alert/CLEAR_ALERT';

export const initialState = {
  visible: false,
  style: 'info',
  message: ''
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_ALERT:
      return {
        ...state,
        visible: true,
        style: action.style,
        message: action.message
      };
    case CLEAR_ALERT:
      return {
        ...state,
        visible: false,
        style: 'info',
        message: ''
      };
    default:
      return state;
  }
}

export function setAlert(style, message) {
  return {
    type: SET_ALERT,
    style: style,
    message: message
  };
}

export function clearAlert() {
  return {
    type: CLEAR_ALERT
  };
}
