import * as actions from './documentReducer'
import Axios from 'axios'
import { toastr } from 'react-redux-toastr'
import queryString from '../../../helpers/queryString'
import handleErrorResponse from '../../../helpers/handleErrorResponse'

const defaultIncludes = ['document_template.latest_content', 'document_type', 'schemes']

export function createDocument(resource) {
  return dispatch => {
    return Axios.post('products/schemes/documents', resource).then(response => {
      toastr.success('Success', 'Your document has been created')
      return true
    }).catch(error => {
      handleErrorResponse(error, 'There was an error creating your document')
    })
  }
}

export function getDocument(id, includes = defaultIncludes) {
  return (dispatch, getState) => {
    let endpoint = 'products/schemes/documents/' + id
    endpoint += queryString(includes)

    return Axios.get(endpoint).then(response => {
      dispatch({
        type: actions.SET_DOCUMENT,
        data: response.data
      })
      return true
    }).catch(error => {
      console.error(error)
    })
  }
}

export function updateContent(resource) {
  return dispatch => {
    return Axios.post('products/schemes/documents', resource).then(response => {
      toastr.success('Success', 'Document content has been updated')
      return true
    }).catch(error => {
      handleErrorResponse(error, 'There was an error creating your document')
    })
  }
}

export function updateDocument(resource) {
  return dispatch => {
    if (resource.data.id) {
      return Axios.patch('documents/' + resource.data.id, resource).then(response => {
        toastr.success('Success', 'Document has been updated')
        return true
      }).catch(error => {
        handleErrorResponse(error, 'There was an error updating your document')
      })
    }

    return Axios.post('documents', resource).then(response => {
      toastr.success('Success', 'New document version as been created')
      return true
    }).catch(error => {
      handleErrorResponse(error, 'There was an error creating your document')
    })
  }
}

