const ADD_FILES = 'socrates-online/upload/ADD_FILES';
const REMOVE_FILE = 'socrates-online/upload/REMOVE_FILE';
const CLEAR_FILES = 'socrates-online/upload/CLEAR_FILES';
const TRIGGER_FILE_UPLOAD = 'socrates-online/upload/TRIGGER_FILE_UPLOAD';
const RESET_FILE_UPLOAD_TRIGGER = 'socrates-online/upload/RESET_FILE_UPLOAD_TRIGGER';
const SET_UPLOAD_ACCEPT = 'socrates-online/upload/SET_UPLOAD_ACCEPT';

const initialState = {
  files: [],
  triggerUpload: false,
  accept: 'image/*'
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_FILES:
      return {
        files: [
          ...state.files,
          ...action.files
        ]
      };
    case SET_UPLOAD_ACCEPT:
      return {
        ...state,
        accept: action.data
      };
    case REMOVE_FILE:
      return {
        files: [
          ...state.files.slice(0, action.index),
          ...state.files.slice(action.index + 1)
        ]
      };
    case CLEAR_FILES:
      return {
        files: []
      };
    case TRIGGER_FILE_UPLOAD:
      return {
        ...state,
        triggerUpload: true
      };
    case RESET_FILE_UPLOAD_TRIGGER:
      return {
        ...state,
        triggerUpload: false
      };
    default:
      return state;
  }
}

export function setUploadAccept(accept) {
  return {
    type: SET_UPLOAD_ACCEPT,
    data: accept
  }
}

export function addFiles(files) {
  return {
    type: ADD_FILES,
    files
  }
}

export function removeFile(index) {
  return {
    type: REMOVE_FILE,
    index
  }
}

export function clearFiles() {
  return {
    type: CLEAR_FILES
  }
}

export function triggerFileUpload() {
  return {
    type: TRIGGER_FILE_UPLOAD
  }
}

export function resetFileUploadTrigger() {
  return {
    type: RESET_FILE_UPLOAD_TRIGGER
  }
}
