import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import InternalContainer from '../../layout/containers/Internal'
import RoleList from '../components/RoleList/RoleList'

class RoleListContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    role: PropTypes.object.isRequired,
  };

  render() {
    return (
        <InternalContainer title="Admin / Roles" >
          <RoleList {...this.props} />
        </InternalContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    role: state.role
  }
}

export default connect(mapStateToProps)(RoleListContainer)
