export const STORE_SEARCH = 'socrates-online/customer_search/STORE_SEARCH';
export const STORE_CLEAR_SEARCH = 'socrates-online/customer_search/STORE_CLEAR_SEARCH';

const searchedData = '';

export default function reducer(state = searchedData, action = {}) {
  switch (action.type) {
    case STORE_SEARCH:
      return {
        ...state,
        searchedData: action.searchedData,
      };
    case STORE_CLEAR_SEARCH:
      return {
        ...state,
        searchedData,
      };
    default:
      return state;
  }
}

export function storeCustomerSearch(searchedData) {
  return {
    type: STORE_SEARCH,
    searchedData: searchedData,
  };
}

export function clearCustomerSearch() {
  return {
    type: STORE_CLEAR_SEARCH,
  };
}
