import React from 'react'
import PropTypes from 'prop-types'
import AutoCapsInput from '../AutoCapsInput/AutoCapsInput'
import { Field } from 'redux-form'
import { SelectField } from '../../../common/components/ReduxFormField'
import './NameFields.scss'

const NameFields = ({ baseName, label, labelSize, mdFieldSize, hideTitle, infoButton, child = false, readOnly }) => {
  const labelClass = 'col-xs-' + (labelSize ? labelSize : 2)
  const wrapperClass = 'col-xs-' + (labelSize ? 12 - labelSize : 10) + (mdFieldSize ? ' col-md-' + mdFieldSize : '')
  let titles = ''

  if (child) {
    titles = [
      { label: 'Master', value: 'Master' },
      { label: 'Miss', value: 'Miss' },
      { label: 'Mr', value: 'Mr' },
    ]
  } else {
    titles = [
      { label: 'Mr', value: 'Mr' },
      { label: 'Mrs', value: 'Mrs' },
      { label: 'Miss', value: 'Miss' },
      { label: 'Ms', value: 'Ms' },
      { label: 'Master', value: 'Master' },
      { label: 'Dr', value: 'Dr' },
      { label: 'Prof', value: 'Prof' },
      { label: 'Rev', value: 'Rev' },
      { label: 'Cpt', value: 'Cpt' },
      { label: 'Sir', value: 'Sir' } ,
      { label: 'Dame', value: 'Dame' },
      { label: 'Lord', value: 'Lord' },
      { label: 'Lady', value: 'Lady' },
      { label: 'Rt Honourable', value: 'Rt Honourable' }
    ]
  }

  return (
    <div className="name-fields">
      <div className="form-group">
        <div className={labelClass}>
          <label className="control-label">{ label }</label> {infoButton}
        </div>
        <div className={ wrapperClass }>
          <div className="row padded-row">
            { hideTitle ? (
                <div>
                  <div className="col-md-6">
                    <Field
                      name={baseName + '.first_name'}
                      placeholder="First Name"
                      component={AutoCapsInput}
                      readOnly={readOnly}
                    />
                  </div>
                  <div className="col-md-6">
                    <Field
                      name={baseName + '.last_name'}
                      placeholder="Surname"
                      component={AutoCapsInput}
                      readOnly={readOnly}
                    />
                  </div>
                </div>
              ) : (
                <div>
                  <div className="col-xs-3">
                    <Field
                      name={baseName + '.title'}
                      placeholder="Title"
                      options={titles}
                      component={SelectField}
                      readOnly={readOnly}
                    />
                  </div>
                  <div className="col-xs-4">
                    <Field
                      name={baseName + '.first_name'}
                      placeholder="First Name"
                      component={AutoCapsInput}
                      readOnly={readOnly}
                    />
                  </div>
                  <div className="col-xs-4">
                    <Field
                      name={baseName + '.last_name'}
                      placeholder="Surname"
                      component={AutoCapsInput}
                      readOnly={readOnly}
                    />
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

NameFields.propTypes = {
  baseName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelSize: PropTypes.number,
  mdFieldSize: PropTypes.number,
  hideTitle: PropTypes.bool
}

export default NameFields
