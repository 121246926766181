import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getAccount, getPremiums, getTransactions } from '../redux/accountActions'
import { Tabs, Tab, Label } from 'react-bootstrap'
import { Button, Loader } from '../../../common/components'
import InternalContainer from '../../layout/containers/Internal'
import AccountDetails from '../components/AccountDetails'
import AccountTransactions from '../components/AccountTransactions'
import AccountNotes from '../components/AccountNotes'
import AccountMatching from '../components/AccountMatching'
import AccountAudit from '../components/AccountAudit'

class AccountShowContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    account: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context)

    this.state = {
      activeTab: 1
    }
  }

  componentWillMount() {
    this.props.dispatch(getAccount(this.props.params.id))
    this.props.dispatch(getTransactions(this.props.params.id))
    this.props.dispatch(getPremiums(this.props.params.id))
  }

  handleTab(key) {
    this.setState({ activeTab: key })
  }

  render() {
    const resource = this.props.account.resources[this.props.params.id]


    const labelStyle = (account) => {
      if (account.data.attributes.suspended) {
        return 'danger'
      } else if (account.data.attributes.limit < account.data.attributes.balance) {
        return 'warning'
      } else {
        return 'success'
      }
    }


    const accountLabel = (account) => {
      return (
          <Label className="pull-right"
                 bsStyle={labelStyle(account)}>{account.data.attributes.formatted_balance}</Label>
      )
    }

    const buttons = (
        <Button
            bsStyle="default"
            label="back"
            isBackButton={true}
            leftIcon="caret-left"
        />
    )

    return (
        <InternalContainer title="Account" buttons={buttons}>
          {resource ? (
                  <Tabs activeKey={this.state.activeTab}
                        id="account-tabs"
                        unmountOnExit={true}
                        onSelect={::this.handleTab}>
                    <Tab eventKey={1} title="Details">
                      <AccountDetails {...this.props} resource={resource} accountLabel={accountLabel(resource)}/>
                    </Tab>
                    <Tab eventKey={2} title="Ledger Entries">
                      <AccountTransactions {...this.props} resource={resource} accountLabel={accountLabel(resource)}/>
                    </Tab>
                    <Tab eventKey={3} title="Notes">
                      <AccountNotes {...this.props} resource={resource} accountLabel={accountLabel(resource)}/>
                    </Tab>
                    <Tab eventKey={4} title="Audit">
                      <AccountAudit {...this.props} resource={resource}/>
                    </Tab>
                  </Tabs>
              ) : (
                  <Loader />
              )}
        </InternalContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    account: state.account
  }
}

export default connect(mapStateToProps)(AccountShowContainer)
