import React from 'react';
import { Field, Form, reduxForm } from 'redux-form';
import { Col, ControlLabel, FormGroup } from 'react-bootstrap';
import Button from '../../../../../common/components/Button/Button';
import TextArea from '../../../../content/components/TextArea';
import { SelectField } from '../../../../../common/components/ReduxFormField';
import CheckboxUnlabeled from '../../../../../common/components/ReduxFormField/CheckboxUnlabeled';
import HorizontalFormControl from '../../../../../common/components/HorizontalFormControl/HorizontalFormControl';

class FaqsForm extends React.PureComponent {
  componentWillMount() {
    const { faq, dispatch, change } = this.props;

    dispatch(change('data.id', faq.id));
    dispatch(change('data.attributes.name', faq.name));
    dispatch(change('data.attributes.sections.answer', faq.answer));
    dispatch(change('data.attributes.sections.display', faq.display));
    dispatch(change('data.attributes.sections.question', faq.question));
    dispatch(change('data.attributes.sections.category', faq.category));
  }

  render() {
    const { handleSubmit, onCancel, submitting, categories } = this.props;
    const name = 'data.attributes.sections';

    return (
      <Form onSubmit={handleSubmit}>
        <div className="animated fadeIn">
          <Field
            label="Name:"
            noLabel={false}
            name={'data.attributes.name'}
            component={HorizontalFormControl}
          />
          <Field
            labelSize={2}
            label="Category"
            options={categories}
            component={SelectField}
            name={`${name}.category`}
          />
          <FormGroup>
            <Col sm={2}>
              <ControlLabel>Display:</ControlLabel>
            </Col>
            <Col sm={10}>
              <Field
                name={`${name}.display`}
                component={CheckboxUnlabeled}
                options={[{ label: '', value: true }]}
              />
            </Col>
          </FormGroup>
          <Field
            noLabel={false}
            label="Question:"
            name={`${name}.question`}
            component={HorizontalFormControl}
          />
          <FormGroup>
            <Col sm={2}>
              <ControlLabel>Answer:</ControlLabel>
            </Col>
            <Col sm={10}>
              <Field name={`${name}.answer`} component={TextArea} />
            </Col>
          </FormGroup>
          <div className="help-centre-btn-bar">
            <Button bsStyle="default" handleClick={onCancel}>
              Cancel
            </Button>
            <Button
              type="submit"
              bsStyle="primary"
              className="pull-right"
              isLoading={submitting}
            >
              Save Changes
            </Button>
          </div>
        </div>
      </Form>
    );
  }
}

export default reduxForm({
  form: 'FaqsForm',
})(FaqsForm);
