import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import InternalContainer from '../../layout/containers/Internal'
import AccountLedger from '../components/AccountLedger'

class AccountLedgerContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };

  render() {


    return (
        <InternalContainer title="Accounts Ledger">
          <AccountLedger {...this.props} />
        </InternalContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    account: state.account
  }
}

export default connect(mapStateToProps)(AccountLedgerContainer)
