import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { Button } from '../../../../common/components'
import displayName from '../../../../helpers/displayName'
import TextArea from '../../../content/components/TextArea'

const FORM_NAME = 'productDefinitionsForm'

class ProductDefinitionsForm extends Component {
  static propTypes = {
    product: PropTypes.object.isRequired
  }

  render() {
    const { product, submitting, handleSubmit, formValues } = this.props

    return (
        <div>
          <form onSubmit={handleSubmit}>
            <h2 className="resource-name">Definitions</h2>

            {product.data.attributes.metadata.definitions && Object.keys(product.data.attributes.metadata.definitions).map((key, i) => (
                <div key={i} className="row">
                  <label className="control-label col-sm-2">{displayName(key)}</label>
                  <div className="col-sm-10">
                    <Field
                        name={'data.attributes.metadata.definitions.' + key}
                        component={TextArea}
                    />
                  </div>
                </div>
            ))}

            <div className="row actions">
              <div className="col-xs-12">
                <Button
                    type="submit"
                    label="Save Changes"
                    bsStyle="primary"
                    className="pull-right"
                    isLoading={submitting}
                />
              </div>
            </div>
          </form>
        </div>
    )
  }
}

const form = reduxForm({ form: FORM_NAME })(ProductDefinitionsForm)
const selector = formValueSelector(FORM_NAME)
const mapStateToProps = (state, props) => {
  const values = selector(state,
      'data.id'
  )

  return {
    initialValues: props.product,
    formValues: values
  }
}
export default connect(mapStateToProps)(form)
