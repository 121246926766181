import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { InfoButton, SelectActivity } from '../../../../../../common/components';
import { RadioGroup, SelectField } from '../../../../../../common/components/ReduxFormField';
import * as _ from 'lodash';
import Markdown from 'react-markdown';
import '../../styles/LeisureTravelForm.scss';

export class OptionsTab extends Component {
  constructor(props) {
    super(props);
    this.transformDocLinks = this.transformDocLinks.bind(this);
  }

  componentWillMount() {
    const { change, dispatch, currentProduct, formValues } = this.props;
    const options = _.get(currentProduct, 'data.attributes.metadata.options', {});
    {
      _.map(options, (option, key) => {
        if (option.type === 'list' && !_.get(formValues, key)) {
          dispatch(change('data.attributes.metadata.options.' + key, option.default));
        }
        if (!_.isUndefined(option.auto_included) && option.auto_included) {
          dispatch(change('data.attributes.metadata.options.' + key, true));
        }
      });
    }
  }

  transformDocLinks(url) {
    const documents = this.props.currentProduct.data.attributes.documents;
    const documentName = _.findKey(documents);

    if (url === 'hazardous_activities') {
      return documents[documentName];
    }
    return url;
  }

  render() {
    const { currentProduct } = this.props;

    const options = _.get(currentProduct, 'data.attributes.metadata.options', {});
    const selectActivities = _.get(currentProduct, 'data.attributes.metadata.select_activities', true);
    const productDocuments = _.get(currentProduct, 'data.attributes.documents');
    const definitions = _.get(currentProduct, 'data.attributes.metadata.definitions', {});
    const hazardousActivities = _.get(currentProduct, 'data.attributes.metadata.hazardous_activities', '');

    return (
      <div>
        <div className='form-horizontal'>
          {_.map(options, (option, key) => {
            if (option.type === 'boolean') {
              const options = [
                { label: 'Yes', value: 'true' },
              ];
              if (_.isUndefined(option.auto_included) || !option.auto_included) {
                options.push({ label: 'No', value: 'false' });
              }
              return (
                <div key={key}>
                  <div className='question-box medical-declaration-question p-l-0 p-r-0'>
                    <row>
                      <div className='question flex-box-questions'>
                        <Markdown source={option.question || ''}/>
                        <InfoButton content={definitions[key]}/>
                      </div>
                    </row>
                    <div className='answer'>
                      <Field
                        name={'data.attributes.metadata.options.' + key}
                        component={RadioGroup}
                        inline
                        options={options}
                      />
                    </div>
                  </div>
                </div>
              );
            }

            if (option.type === 'list') {
              return (
                <div key={key}>
                  <div className='question-box'>
                    <div className='question flex-box-questions'>
                      <Markdown source={option.question || ''}/>
                      <InfoButton content={definitions[key]}/>
                    </div>
                    <div className='answer answer--min-width'>
                      <Field
                        name={'data.attributes.metadata.options.' + key}
                        component={SelectField}
                        inline
                        options={_.map(option.items, ((name, key) => ({ label: name, value: key })))}
                      />
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
        {selectActivities && (
          <div className='row'>
            <div className='col-xs-12 default-text'>
              <div>
                <strong>Is cover required for any other activities?</strong>
                <InfoButton content={definitions.hazardous_activities}/>
              </div>
              <label className='control-label'>
                Alternatively start typing an activity name and select any activities that require cover below to help
                you find the appropriate level of Recreational Activity cover.
              </label>
              <div className=''>

                <Field
                  name='data.attributes.metadata.activities'
                  multi
                  object
                  placeholder='Search for activities...'
                  component={SelectActivity}
                />
              </div>
            </div>
          </div>
        )}
        {productDocuments && (
          <div className='info-doc'>
            <Markdown
              source={hazardousActivities}
              linkTarget='_blank'
              transformLinkUri={this.transformDocLinks}/>
          </div>
        )}
      </div>
    );
  }
}

const FORM_NAME = 'LeisureTravelForm';

const form = reduxForm({ form: FORM_NAME })(OptionsTab);
const selector = formValueSelector(FORM_NAME);
const mapStateToProps = (state, props) => {
  const values = selector(state,
    'data.attributes.metadata.options',
  );

  return {
    auth: state.auth,
    initialValues: props.resource,
    form: 'LeisureTravelForm',
    formValues: values,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    product: state.product,
  };
};

export default connect(mapStateToProps)(form);
