import * as actions from './PostcodeReducer'
import Axios from 'axios'

export function isLoading(status) {
  return {
    type: actions.LOOKUP_QUERY,
    status
  }
}

export function getAddresses(postcode) {
  return dispatch => {
    const endpoint = 'public/postcode/' + encodeURIComponent(postcode)

    if (typeof postcode == typeof undefined || postcode.length < 3) {
      return false
    }

    dispatch({ type: actions.START_LOOKUP })
    dispatch(isLoading(true))

    return Axios.get(endpoint).then(response => {
      dispatch({
        type: actions.SET_ADDRESS_OPTIONS,
        addresses: response.data
      })
    }).catch(error => {
      dispatch(isLoading(false))
      console.error(error)
    })
  }
}

export function getAddress(id, fieldId) {
  return dispatch => {
    const endpoint = 'public/address/' + id

    dispatch(isLoading(true))

    return Axios.get(endpoint).then(response => {
      dispatch({
        type: actions.SELECT_ADDRESS,
        address: response.data,
        id: fieldId
      })
    }).catch(error => {
      dispatch(isLoading(false))
      console.error(error)
    })
  }
}

export function clearSelection() {
  return dispatch => {
    dispatch({ type: actions.CLEAR_SELECTION })
  }
}

