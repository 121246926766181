import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box, DataTable } from '../../../../common/components'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { FormattedDate, FormattedTime } from 'react-intl'
import { refreshData } from '../../../../common/components/DataTable/redux/dataTableActions'

export default class UserAudit extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    reference: PropTypes.string,
  };

  formatDate(cell, row) {
    return (
      <span>
           <FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/>
        </span>
    )
  }

  handleRowClick(row) {
    //this.props.dispatch(push('/admin/users/' + row.id));
    return false
  }

  refreshList() {
    this.props.dispatch(refreshData('audit'))
  }

  render() {
    const { resource } = this.props

    return (
      <div>
        <Box>
          <DataTable
            source={'/events'}
            autoFilters={[
              { type: 'filter[resource]', value: resource.data.id },
              { type: 'related', value: true }
            ]}
            name="audit"
            showTotal={true}
            onRowSelect={this.handleRowClick.bind(this)}>

            <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
              ID
            </TableHeaderColumn>

            <TableHeaderColumn dataField="occurred_at" dataFormat={this.formatDate} width={'180px'} dataSort={true}>
              Occurred&nbsp;at
            </TableHeaderColumn>

            <TableHeaderColumn dataField="action" width={'200px'} dataSort={true}>
              Action
            </TableHeaderColumn>

            <TableHeaderColumn dataField="description" width={'500px'} dataSort={true}>
              Description
            </TableHeaderColumn>

            <TableHeaderColumn dataField="user_name">
              User
            </TableHeaderColumn>
          </DataTable>
        </Box>
      </div>
    )
  }
}
