import * as actions from './challengeReducer'
import Axios from 'axios'
import { push } from 'react-router-redux'
import { toastr } from 'react-redux-toastr'
import queryString from '../../../helpers/queryString'
import handleErrorResponse from '../../../helpers/handleErrorResponse'

const defaultIncludes = ['user']

export function clearChallenge() {
  return {
    type: actions.CLEAR_CHALLENGE
  }
}

export function isSubmitting(status) {
  return {
    type: actions.IS_SUBMITTING,
    status
  }
}

export function getChallenge(id, includes = defaultIncludes) {
  return (dispatch, getState) => {
    let endpoint = 'challenge-alpha/' + id
    endpoint += queryString(includes)

    return Axios.get(endpoint).then(response => {
      dispatch({
        type: actions.SET_CHALLENGE,
        data: response.data
      })
      return true
    }).catch(error => {
      console.error(error)
    })
  }
}

export function createChallenge(resource, publicRequest = true, redirect = false) {
  return dispatch => {
    dispatch(isSubmitting(true))
    return Axios.post((publicRequest ? 'public/' : '/') + 'challenge-alpha', resource).then(response => {
      dispatch(isSubmitting(false))
      toastr.success('Challenge sent', 'Your challenge was submitted to Axiom')

      if (redirect) {
        dispatch(push('/challenge-alpha/' + response.data.data.id))
      }
      return true
    }).catch(error => {
      dispatch(isSubmitting(false))
      handleErrorResponse(error, 'There was an error creating your challenge')
    })
  }
}

export function respondToChallenge(resource) {
  return dispatch => {
    dispatch(isSubmitting(true))
    return Axios.post('/challenge-alpha/' + resource.data.id + ':respond', resource).then(response => {
      dispatch(isSubmitting(false))
      dispatch(getChallenge(resource.data.id))
      toastr.success('Challenge sent', 'The challenge was marked as responded')
      return true
    }).catch(error => {
      dispatch(isSubmitting(false))
      handleErrorResponse(error, 'There was an error update the challenge')
    })
  }
}

