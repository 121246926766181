import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { saveUserOrganisationRoles } from '../redux/userActions'
import UserRolesForm from '../components/UserRolesForm'

export default class UserRolesFormContainer extends Component {
  static propTypes = {
    resource: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
  };

  handleSubmit(values) {
    return this.props.dispatch(saveUserOrganisationRoles(values, this.props.resource.data.id))
  }

  render() {
    return (
        <UserRolesForm
            {...this.props}
            onSubmit={::this.handleSubmit}
        />
    )
  }
}
