export const role = {
  data: {
    type: 'role',
    attributes: {
      name: '',
      description: '',
    },
    relationships: {
      permissions: {
        data: []
      }
    }
  }
};

export const roleRules = {};

export default role;
