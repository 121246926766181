import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { FormControl, Checkbox } from 'react-bootstrap'
import { TextField, CheckboxGroup, SelectField } from '../../../../common/components/ReduxFormField'
import { Box, Button, ButtonBar } from '../../../../common/components'
import { hasPermission } from '../../../auth/redux/authActions'
import _ from 'lodash'
import './styles.scss'

const FORM_NAME = 'brokerCommissionForm'

class BrokerCommissionForm extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    resource: PropTypes.object.isRequired,
    formValues: PropTypes.object,
    brokerLabel: PropTypes.object
  };

  constructor(props) {
    super(props)
    this.state = { enableAll: true }
  }


  handleCommissionDefault(event) {
    _.get(this.props.formValues, 'meta.commissions', []).map((commission, i) => {
      this.props.dispatch(this.props.change('meta.commissions[' + i + '].rate', event.target.value))
    })
  }

  handleEnableAll() {
     this.setState({ enableAll: !this.state.enableAll })

    _.get(this.props.formValues, 'meta.commissions', []).map((commission, i) => {
      this.props.dispatch(this.props.change('meta.commissions[' + i + '].enabled', this.state.enableAll))
    })
  }

  render() {
    const { change, dispatch, formValues, handleSubmit, submitting } = this.props
    const resource = this.props.resource.data.attributes
    const products = this.props.broker.allProducts

    const commissionDefaults = [
      { label: 'Level one', value: 20 },
      { label: 'Level two', value: 25 },
      { label: 'Level three', value: 30 }
    ]

    return (
      <div>

        <Box>
          <div className="row">
            <div className="col-sm-3">
              <label className="control-label">Enable All</label>
            </div>
            <div className="col-sm-2">
              <div className="radio-container inline">
                <div className="form-radio">
                  <input
                    type="checkbox"
                    id="enableAll"
                    onChange={::this.handleEnableAll}
                    checked={!this.state.enableAll}
                  />
                  <label htmlFor="enableAll"></label>
                </div>
                <div className="radio-label"></div>
              </div>
            </div>
            <div className="col-sm-3">
              <label className="control-label">Default Commission Level</label>
            </div>
            <div className="col-sm-4">
              <FormControl
                componentClass="select"
                type="select"
                onChange={::this.handleCommissionDefault}
                placeholder="select"
              >
                <option value="">Select...</option>
                {commissionDefaults.map((commissionDefault, key) => {
                  return (<option key={'defaults' + key} value={commissionDefault.value}>{commissionDefault.label}</option>)
                })}
              </FormControl>
            </div>
          </div>
        </Box>

        <Box>
          <form onSubmit={handleSubmit}>
            <h2 className="resource-name">{resource.trading_name} {this.props.brokerLabel}</h2>

            <div className="row commission-headings">
              <div className="col-sm-6">
                <label className="control-label">Product</label>
              </div>
              <div className="col-sm-2">
                <label className="control-label">Enabled</label>
              </div>
              <div className="col-sm-4 text-center">
                <label className="control-label">Commission Rate</label>
              </div>
            </div>

            {products && products.data.map((product, i) => {
              return (
                <div className="row commission-row" key={i}>
                  <div className="col-sm-6">
                    <label className="control-label">{product.attributes.name}</label>
                  </div>
                  <div className="col-sm-2">
                    <Field
                      name={'meta.commissions[' + i + '].enabled'}
                      labelSize={1}
                      inline={true}
                      options={[
                        { label: '', value: 'true' }
                      ]}
                      component={CheckboxGroup}
                    />
                  </div>
                  <div className="col-sm-4">
                    <div className="row">
                      <div className="col-xs-6">
                        <SelectField
                          options={commissionDefaults}
                          input={{
                            value: _.get(formValues, 'meta.commissions[' + i + '].rate', ''),
                            onChange: (value) => {
                              dispatch(change('meta.commissions[' + i + '].rate', value))
                            }
                          }}
                        />
                      </div>
                      <div className="col-xs-6">
                        <Field
                          name={'meta.commissions[' + i + '].rate'}
                          component={TextField}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
            <ButtonBar>
              { hasPermission('broker.edit') && (
                <Button
                  className="pull-right"
                  type="submit"
                  bsStyle="primary"
                  label="Save Broker Commissions"
                  isLoading={submitting}
                />
              )}
            </ButtonBar>
        </form>
      </Box>
  </div>
  )
  }
}

const form = reduxForm({ form: FORM_NAME })(BrokerCommissionForm)
const selector = formValueSelector(FORM_NAME)
const mapStateToProps = (state, props) => {
  const initialValues = {
    meta: {
      commissions: []
    }
  }

  const values = selector(state,
    'meta[0].rate',
    'meta'
  )

  const getCommission = (productId) => {
    let commission = null
    props.commissions.map(item => {
      if (item.relationships.product.data.id == productId) {
        commission = item
      }
    })
    return commission
  }

  state.broker.allProducts.data.map(product => {
    const commission = getCommission(product.id)
    initialValues.meta.commissions.push(commission ? {
        enabled: true,
        rate: commission.attributes.rate,
        product: commission.relationships.product.data.id
      } : {
        enabled: false,
        rate: '',
        product: product.id,
      })
  })

  return {
    initialValues,
    formValues: values,
    broker: state.broker
  }
}

export default connect(mapStateToProps)(form)
