import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Box, DataTable, HorizontalFormControl } from '../../../../common/components'
import { applyFilter, getFilter } from '../../../../common/components/DataTable/redux/dataTableActions'
import { SelectField } from '../../../../common/components/ReduxFormField'
import { Button } from '../../../../common/components'
import { hasPermission } from '../../../auth/redux/authActions'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { push } from 'react-router-redux'
import { FormattedDate } from 'react-intl'

class AccountList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    dataTables: PropTypes.object.isRequired
  };

  state = {
    accountType: '',
  }

  componentWillMount() {
    this.setState({
      accountType: this.props.location.query.filter ? this.props.location.query.filter : ''
    })
  }

  formatDate(cell, row) {
    return (
        <FormattedDate value={new Date(cell)}/>
    )
  }

  handleRowClick(row) {
    return this.props.dispatch(push('/accounts/ledger/' + row.id))
  }


  handleSearchUpdate(event) {
    this.props.dispatch(applyFilter('accounts', 'filter[name]', event.target.value))
  }

  render() {
    const { dataTables } = this.props

    const buttons = (hasPermission('account.edit') ? (
      <Button
        link={true}
        to="/accounts/ledger/new"
        className="pull-right"
        bsStyle="primary"
        label="New Account"
      />
    ) : (''))

    return (
        <div>
          <Box>
            <div className="row">
              <div className="col-sm-4">
                <HorizontalFormControl labelSize={3} label="Search" input={{ onChange: ::this.handleSearchUpdate }}/>
              </div>
              <div className="col-sm-4">
                <SelectField
                    label="Type"
                    labelSize={2}
                    input={{
                      onChange: (value) => this.props.dispatch(applyFilter('accounts', 'filter[type]', value)),
                      value: getFilter(dataTables, 'accounts', 'filter[type]')
                    }}
                    options={[
                      { label: 'All', value: '' },
                      { label: 'Debtor', value: 'debtor' },
                      { label: 'Creditor', value: 'creditor' },
                      { label: 'Cash', value: 'cash' },
                      { label: 'Control', value: 'control' },
                    ]}
                />
              </div>
              <div className="col-sm-4">
                <SelectField
                    label="Status"
                    labelSize={6}
                    input={{
                      onChange: (value) => this.props.dispatch(applyFilter('accounts', 'filter[suspended]', value)),
                      value: getFilter(dataTables, 'accounts', 'filter[suspended]')
                    }}
                    options={[
                      { label: 'All', value: '' },
                      { label: 'Active', value: 'false' },
                      { label: 'Suspended', value: 'true' },
                    ]}
                />
              </div>
            </div>
          </Box>

          <Box>
            <DataTable
                source="/accounts"
                name="accounts"
                showTotal={true}
                autoFilters={[
                  { type: 'filter[type]', value: this.state.accountType }
                ]}
                onRowSelect={this.handleRowClick.bind(this)}>

              <TableHeaderColumn dataField="id" isKey={true} hidden={true}>ID</TableHeaderColumn>
              <TableHeaderColumn dataField="name" width={'330px'} dataSort={true}>Account Name</TableHeaderColumn>
              <TableHeaderColumn dataField="account_type" width={'150px'}>Account Type</TableHeaderColumn>
              <TableHeaderColumn dataField="formatted_balance" dataAlign="right" width={'120px'} dataSort={true}>Balance</TableHeaderColumn>
              <TableHeaderColumn dataField="limit" dataAlign="right" width={'120px'}
                                 dataSort={true}>Limit</TableHeaderColumn>
            </DataTable>
            <div className="button-bar-inside">
              {buttons}
            </div>
          </Box>
        </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    dataTables: state.dataTables
  }
}

export default connect(mapStateToProps)(AccountList)
