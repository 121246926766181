import { combineReducers } from 'redux'
import content from '../modules/content/redux/contentReducer'
import auth from '../modules/auth/redux/authReducer'
import layout from '../modules/layout/redux/layoutReducer'
import account from '../modules/account/redux/accountReducer'
import dashboard from '../modules/dashboard/redux/dashboardReducer'
import brochure from '../modules/brochure/redux/brochureReducer'
import postcode from '../common/components/Postcode/redux/PostcodeReducer'
import challenge from '../modules/challenge/redux/challengeReducer'
import company from '../common/components/CompanyNumber/redux/CompanyNumberReducer'
import campaign from '../modules/campaigns/redux/campaignReducer'
import dataTables from '../common/components/DataTable/redux/dataTableReducer'
import broker from '../modules/broker/redux/brokerReducer'
import policy from '../modules/policy/redux/policyReducer'
import organisation from '../modules/organisation/redux/organisationReducer'
import customer from '../modules/customer/redux/customerReducer'
import user from '../modules/user/redux/userReducer'
import role from '../modules/role/redux/roleReducer'
import product from '../modules/product/redux/productReducer'
import alert from '../common/components/Alert/AlertRedux'
import modal from '../common/components/Modal/ModalRedux'
import faq from '../common/components/Faq/FaqRedux'
import selectMenus from '../common/components/SelectMenu/SelectMenuRedux'
import screening from '../modules/screening/redux/screeningReducer'
import scheme from '../modules/scheme/redux/schemeReducer'
import document from '../modules/document/redux/documentReducer'
import brokerApplication from '../modules/brokerApplication/redux/brokerApplicationReducer'
import { routerReducer as routing } from 'react-router-redux'
import { reducer as form } from 'redux-form'
import { reducer as toastr } from 'react-redux-toastr'
import { reducer as trackingLinks } from '../modules/trackingLinks/redux/TrackingLinkReducer'
import { reducer as upload } from '../common/components/Upload/UploadRedux'
import { reducer as helpCentre } from '../modules/helpCentre/redux/reducer';


// Combine reducers with routeReducer which keeps track of
// router state
const rootReducer = combineReducers({
  form,
  toastr,
  routing,
  content,
  product,
  screening,
  scheme,
  document,
  brokerApplication,
  faq,
  helpCentre,
  auth,
  layout,
  account,
  challenge,
  dashboard,
  broker,
  organisation,
  user,
  role,
  brochure,
  customer,
  policy,
  dataTables,
  alert,
  modal,
  postcode,
  company,
  selectMenus,
  upload,
  campaign,
  trackingLinks,
});

export default rootReducer;
