import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './Faq.scss'

const Faq = ({ question, answer, handleClick, open }) => {
  return (
      <div className="faqs">
        <h3 onClick={handleClick}>{ question }</h3>
        { open ? (<p>{ answer }</p>) : ('') }
      </div>
  )
}

Faq.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  open: PropTypes.bool
}

export default Faq
