import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getCustomer, clearData } from '../redux/customerActions'
import { Button, Loader } from '../../../common/components'
import InternalContainer from '../../layout/containers/Internal'
import CustomerFormContainer from './CustomerForm'

class CustomerEditContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    customer: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired
  }

  componentWillMount() {
    this.props.dispatch(getCustomer(this.props.params.id))
  }

  render() {
    const resource = this.props.customer.resources[this.props.params.id]
    const buttons = (
        <Button
            bsStyle="default"
            label="Back"
            isBackButton={true}
            leftIcon="caret-left"
        />
    )

    return (
        <InternalContainer title="Customer" buttons={buttons}>
          {resource ? (
                  <CustomerFormContainer {...this.props} resource={resource}/>
              ) : (
                  <Loader />
              )}
        </InternalContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    customer: state.customer
  }
}

export default connect(mapStateToProps)(CustomerEditContainer)
