import React from 'react'
import { Box, Button, HorizontalFormControl } from '../../../common/components'
import { Form, reduxForm, Field } from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import { flow } from 'lodash'
import { connect } from 'react-redux'
import SelectChannel from '../../../common/components/Selects/SelectChannel';
import SelectOrganisation from '../../../common/components/Selects/SelectOrganisation';
import '../../brokerApplication/components/TrackingLinkNewForm/styles.scss'

class TrackingLinksNewForm extends React.Component {
  componentWillMount() {
    const { dispatch, change } = this.props

    dispatch(change('meta.organisation', 'owner'))
    dispatch(change('data.attributes.broker_type', 'tracking'))
  }

  render() {
    const { handleSubmit, trackingLinks } = this.props
    return (
      <Box>
        <h4 className='title-campaign'>New Tracking Link</h4>
        <hr id='first-row' />
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col sm={6}>
              <Field
                name="data.attributes.trading_name"
                mdFieldSize={8}
                component={HorizontalFormControl}
                labelSize={4}
                label="Name"
              />
            </Col>
            <Col sm={6}>
              <Field
                name='data.relationships.b2c_channel.data'
                label='Channels'
                component={SelectChannel}
                object
                labelSize={4}
              />
            </Col>
          </Row>
          <Row className="row-pad-top">
            <Col sm={6}>
              <Field
                name='data.relationships.organisation.data'
                label='Organisation'
                component={SelectOrganisation}
                object
                labelSize={4}
              />
            </Col>
          </Row>
          <Row className='actions'>
            <Col sm={12}>
              <div className='clearfix'>
                <Button
                  size='large'
                  type='submit'
                  className='pull-right pad-right'
                  label='Create Tracking Link'
                  bsStyle='primary'
                  disabled={trackingLinks.isSubmitting}
                />
              </div>
            </Col>
          </Row>
        </Form>
      </Box>
    )
  }
}

export default flow(
  reduxForm({
    form: 'TrackingLinksNewForm'
  }),
  connect(
    (state) => ({
      trackingLinks: state.trackingLinks,
    }),
  ),
)(TrackingLinksNewForm)
