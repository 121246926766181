import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Alert as BootstrapAlert } from 'react-bootstrap'
import { clearAlert } from './AlertRedux'
import { connect } from 'react-redux'

class Alert extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    visible: PropTypes.bool,
    style: PropTypes.string,
    message: PropTypes.string
  };

  componentWillUnmount() {
    this.props.dispatch(clearAlert())
  }

  dismiss() {
    this.props.dispatch(clearAlert())
  }

  render() {
    const { dispatch, visible, style, message } = this.props

    if (visible) {
      return (
          <BootstrapAlert bsStyle={ style } onDismiss={ this.dismiss.bind(this) }>
            { message }
          </BootstrapAlert>
      )
    }
    return (<div></div>)
  }
}

function mapStateToProps(state) {
  return {
    visible: state.alert.visible,
    style: state.alert.style,
    message: state.alert.message
  }
}

export default connect(mapStateToProps)(Alert)
