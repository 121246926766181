import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import confirm from 'react-confirm2';
import { closeModal, openModal } from '../../../../common/components/Modal/ModalRedux';
import Button from '../../../../common/components/Button/Button';
import Modal from '../../../../common/components/Modal/Modal';
import { mergeCustomers } from '../../redux/customerActions';
import CustomerMergeList from '../../components/CustomerMergeList/CustomerMergeList';
import InfoLabel from '../../../../common/components/InfoLabel/InfoLabel';
import { FormGroup, ControlLabel, Col, Row } from 'react-bootstrap';
import './styles.scss';

const FORM_NAME = 'mergeCustomersForm';

class MergeCustomersButton extends Component {
  constructor(props) {
    super(props);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
  }

  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded, callback } = this.props;
    if (newProps.submitSucceeded && !submitSucceeded) {
      if (callback) {
        callback('');
      }
      dispatch(closeModal(FORM_NAME));
      this.resetState();
    }
  }

  handleRowClick(row) {
    const { customerId, customer } = this.props;
    const formattedDOB = new moment(row.dob, 'YYYY-MM-DD').format('DD/MM/YYYY');
    const formattedDOBMerge = new moment(customer.dob, 'YYYY-MM-DD').format('DD/MM/YYYY');

    confirm('Warning', {
      description:
        'Are you sure you want to merge ' +
        row.customer_name +
        ' (' +
        formattedDOB +
        ') into ' +
        customer.customer_name +
        ' (' +
        formattedDOBMerge +
        ')? This action cannot be reversed.',
      done: () => {
        const newCustomer = {
          data: {
            relationships: {
              customer: {
                data: {
                  id: row.id,
                },
              },
            },
          },
        };
        this.props.dispatch(mergeCustomers(customerId, newCustomer));
      },
    });
  }

  handleOpen() {
    const { dispatch } = this.props;
    dispatch(openModal(FORM_NAME));
  }

  render() {
    const { customer } = this.props;
    const title = 'Merge Customers';

    return (
      <div>
        <Button
          bsStyle="primary"
          {...this.props}
          label={title}
          handleClick={this.handleOpen}
          rightIcon="compress-arrows-alt"
        />

        <Modal
          name={FORM_NAME}
          title={title}
          close
          onHide={this.resetState}
          bsSize="large"
        >
          <div className="merge-customers">
            <Row>
              <FormGroup>
                <Col sm={12}>
                  <ControlLabel>
                    <h3 className="customer-title m-l-15">Original Customer</h3>
                  </ControlLabel>
                </Col>
              </FormGroup>
              <FormGroup>
                <Col sm={6}>
                  <InfoLabel
                    label="Name"
                    value={
                      customer.title + ' ' + customer.first_name + ' ' + customer.last_name
                    }
                    labelSize={4}
                  />
                </Col>
                <Col sm={6}>
                  <InfoLabel label="Email" value={customer.email} labelSize={4}/>
                </Col>
              </FormGroup>
              <FormGroup>
                <Col sm={12}>
                  {customer.company_name && (
                    <InfoLabel
                      label="Company Name"
                      value={customer.company_name}
                      labelSize={4}
                    />
                  )}
                </Col>
              </FormGroup>
              <FormGroup>
                <Col sm={6}>
                  <InfoLabel
                    label="Date of Birth"
                    value={customer.dob}
                    //date={true}
                    labelSize={4}
                  />
                </Col>
                <Col sm={6}>
                  <InfoLabel
                    label="Postcode"
                    value={get(customer, 'address.postcode', '')}
                    labelSize={4}
                  />
                </Col>
              </FormGroup>
            </Row>

            <Row>
              <Col sm={12}>
                <CustomerMergeList
                  quoteHeader={'get-customer-header'}
                  showCreateCustomer={false}
                  internalContainer={false}
                  handleRowClick={this.handleRowClick}
                />
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    );
  }
}

MergeCustomersButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
  label: PropTypes.string,
};

const mapStateToProps = () => {
  return {};
};

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators({ mergeCustomers }, dispatch), dispatch };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MergeCustomersButton);
