import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

const CheckboxGroup = ({ input, label, labelSize, mdFieldSize, options, inline, meta: { touched, error } }) => {
  let labelClass = 'control-label', wrapperClass = 'radio-group'
  if (labelSize) {
    labelClass += ' col-xs-' + labelSize
    wrapperClass += ' col-xs-' + (12 - labelSize)

    if (mdFieldSize) {
      wrapperClass += ' col-md-' + mdFieldSize
    }
  }

  return (
      <div className={(touched && error ? 'form-group has-error' : 'form-group')}>
        {label && (
            <label className={labelClass}>{label}</label>
        )}

        <div className={wrapperClass}>
          <div className="row">
            <div className="col-xs-12">
              {options.map((option, key) => {
                return (
                    <div className={'radio-container' + (inline ? ' inline' : '')} key={key}>
                      <div className={'form-radio'}>
                        <input
                            {...input}
                            type="checkbox"
                            id={input.name + option.value}
                            value={option.value}
                            checked={input.value.toString() == option.value.toString()}
                        />
                        <label htmlFor={input.name + option.value}></label>
                      </div>
                      <div className="radio-label"
                           onClick={() => input.onChange(input.value.toString() == option.value.toString() ? option.value : '')}>{option.label}</div>
                    </div>
                )
              })}
            </div>
          </div>
        </div>
        {touched && error && <span className="input-error">{error}</span>}
      </div>
  )
}

export default CheckboxGroup
