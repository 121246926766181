import * as actions from './userReducer'
import Axios from 'axios'
import { push, replace } from 'react-router-redux'
import { toastr } from 'react-redux-toastr'
import queryString from '../../../helpers/queryString'
import handleErrorResponse from '../../../helpers/handleErrorResponse'

export const defaultIncludes = ['notes', 'roles', 'organisations', 'organisation_roles', 'available_products']

export function setUser(response) {
  return {
    type: actions.SET_USER,
    data: response.data
  }
}

export function setUserOrganisationRoles(response, organisation) {
  return {
    type: actions.SET_USER_ORGANISATION_ROLES,
    organisation: organisation,
    data: response.data
  }
}

export function clearUser() {
  return {
    type: actions.CLEAR_USER
  }
}

export function isSubmitting(status) {
  return {
    type: actions.IS_SUBMITTING,
    status
  }
}

export function isResetting(status) {
  return {
    type: actions.IS_RESETTING,
    status
  }
}

export function isReactivating(status) {
  return {
    type: actions.IS_REACTIVATING,
    status
  }
}

export function isDeactivating(status) {
  return {
    type: actions.IS_DEACTIVATING,
    status
  }
}

export function getUser(id, includes = defaultIncludes) {
  return (dispatch, getState) => {
    const endpoint = 'users/' + id + queryString(includes)

    return Axios
        .get(endpoint)
        .then(response => {
          dispatch(setUser(response))
          return true
        }).catch(error => {
          console.error(error)
        })
  }
}

export function getUserOrganisationRoles(id, organisationId) {
  return (dispatch, getState) => {
    const endpoint = 'users/' + id + '/organisations/' + (organisationId ? organisationId + '/roles' : 'roles')

    return Axios
        .get(endpoint)
        .then(response => {
          dispatch(setUserOrganisationRoles(response, organisationId ? organisationId : null))
          return true
        }).catch(error => {
          console.error(error)
        })
  }
}

export function saveUser(resource, includes = defaultIncludes) {
  return dispatch => {
    return Axios
        .patch('users/' + resource.data.id + queryString(includes), resource)
        .then(response => {
          dispatch(setUser(response))
          dispatch(push('/admin/users/' + resource.data.id))
          return true
        }).catch(error => {
          handleErrorResponse(error, 'There was an error updating the user')
        })
  }
}

export function saveUserOrganisationRoles(resource, userId) {
  return dispatch => {
    const endpoint = 'users/' + userId + '/organisations/' + resource.data.relationships.organisation.data.id + '/roles'

    return Axios
        .patch(endpoint, resource)
        .then(() => {
          dispatch(push('/admin/users/' + userId))
          return true
        }).catch(error => {
          handleErrorResponse(error, 'There was an error updating the organisation roles')
        })
  }
}

export function createUser(values, contact = false) {
  return dispatch => {
    return Axios
        .post('users', values).then(response => {
          const path = contact ?
            '/admin/users/' + response.data.data.id + '/organisations/' + values.data.relationships.organisations.data[0].id + '/edit' :
            '/admin/users/' + response.data.data.id
          return dispatch(replace(path))
        }).catch(error => {
          return handleErrorResponse(error, 'There was an error creating the user')
        })
  }
}

export function passwordReminder(email) {
  return dispatch => {
    dispatch(isResetting(true))
    return Axios.post('./password/remind', {
      email: email
    }).then(response => {
      dispatch(isResetting(false))
      toastr.success('Email sent', 'The password reset email has been sent')
      return true
    }).catch(error => {
      handleErrorResponse(error, 'There was an error sending the password reset email')
    })
  }
}

export function reactivateUser(id) {
  return dispatch => {
    dispatch(isReactivating(true))
    return Axios.post('users/' + id + ':reactivate').then(response => {
      dispatch(isReactivating(false))
      toastr.success('User Reactivated', 'User has been successfully reactivated')
      return true
    }).catch(error => {
      handleErrorResponse(error, 'There was an error reactivating user')
    })
  }
}

export function deactivateUser(id) {
  return dispatch => {
    dispatch(isDeactivating(true))
    return Axios.post('users/' + id + ':deactivate').then(response => {
      dispatch(isDeactivating(false))
      toastr.success('User Deactivated', 'User has been successfully deactivated')
      return true
    }).catch(error => {
      handleErrorResponse(error, 'There was an error deactivating user')
    })
  }
}
