export const APPLICATION_SUBMITTED = 'socrates-online/brochure/APPLICATION_SUBMITTED'
export const CHALLENGE_SUBMITTED = 'socrates-online/brochure/CHALLENGE_SUBMITTED'
export const SET_CONTENT = 'socrates-online/brochure/SET_CONTENT'

export const initialState = {
  brokerApplication: {},
  content: {
    key: '',
    page: { attributes: {} }
  }
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case APPLICATION_SUBMITTED:
      return {
        ...state,
        brokerApplication: action.values
      }
    case CHALLENGE_SUBMITTED:
      return {
        ...state,
        challengeForm: action.values
      }
    case SET_CONTENT:
      return {
        ...state,
        content: action.content
      }
    default:
      return state
  }
}
