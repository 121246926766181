import * as actions from './policyReducer'
import Axios from 'axios'
import { toastr } from 'react-redux-toastr'
import queryString from '../../../helpers/queryString'
import handleErrorResponse from '../../../helpers/handleErrorResponse'
import _ from 'lodash'

const defaultIncludes = [
  'customer', 'user', 'broker', 'non_scheme_documents', 'premiums', 'premiums.scheme',
  'premiums.scheme.product', 'schemes.product', 'documents', 'product',
  'mid_term_adjustment_applications', 'policy_applications.transactions', 'campaign'
]

export function clearData() {
  return {
    type: actions.CLEAR_DATA
  }
}

export function isSubmitting(status) {
  return {
    type: actions.IS_SUBMITTING,
    status
  }
}

export function isCheckingCancel(status) {
  return {
    type: actions.IS_CHECKING_CANCEL,
    status
  }
}

export function isGeneratingDocs(status) {
  return {
    type: actions.IS_GENERATING_DOCS,
    status
  }
}

export function isGettingPolicy(status) {
  return {
    type: actions.IS_GETTING_POLICY,
    status
  }
}

export function createAccount(id) {
  const endpoint = `customers/${id}:create-account`

  return () => {
    return Axios.post(endpoint).then(() => {
      toastr.success('Success')
      return true
    }).catch(error => {
      handleErrorResponse(error, 'Failed to create account')
    })
  }
}

export function getPolicyData(id, includes = defaultIncludes) {

  return (dispatch) => {
    dispatch(isGettingPolicy(true));

    let endpoint = 'policies/' + id
    endpoint += queryString(includes)

    return Axios.get(endpoint).then(response => {
      dispatch(isGettingPolicy(false));
      return response.data
    }).catch(error => {
      dispatch(isGettingPolicy(false));
      console.error(error)
      return error;
    })
  }
}


export function getPolicy(id, isApplication = false, includes = defaultIncludes) {
  return (dispatch) => {
    if (isApplication && _.indexOf(includes, 'policy') === -1) {
      includes.push('policy')
    } else {
      includes.push('issued_applications')
    }

    let endpoint = 'policies/' + (isApplication ? 'applications/' : '') + id
    endpoint += queryString(includes)

    return Axios.get(endpoint).then(response => {
      dispatch({
        type: actions.SET_POLICY,
        data: response.data
      })
      return true
    }).catch(error => {
      console.error(error)
    })
  }
}

export function updateRenewalStatus(application, formValues, status) {
  return dispatch => {
    const values = { data: { 'attributes': { 'renewal_status': status } } };
    values.meta = { reason: '' };

    if (formValues.meta) {
      if (formValues.meta.reason) {
        values.meta.reason = formValues.meta.reason;
      }

      if (formValues.meta.note && formValues.meta.note.content) {
        values.meta.reason = formValues.meta.note.content;
      }
    }

    return Axios.post('policies/' + application.data.id + ':update-renewal-status', values).then(() => {
      toastr.success('Success', 'Renewal status changed to ' + status)
      dispatch(getPolicy(application.data.id))
      return true
    }).catch(error => {
      handleErrorResponse(error, 'Error')
    })
  }
}

export function getResourceNotesCount(id) {
  return (dispatch) => {
    let endpoint = 'notes:count';
    const filters = [{ filter: 'filter[resource]', value: id }];
    filters.map(filter => {
      endpoint += (endpoint.includes('?') ? '&' : '?') + filter.filter + '=' + filter.value
    })

    dispatch(setResourceNotesCount(0))
    return Axios.get(endpoint).then(response => {
      dispatch(setResourceNotesCount(response.data.meta.count))
      return true
    }).catch(error => {
      console.error(error)
    })
  }
}

export function setResourceNotesCount(count) {
  return {
    type: actions.SET_RESOURCE_NOTES_COUNT,
    count
  }
}

export function generateDocuments(application, resourceType, callback) {
  return dispatch => {
    dispatch(isGeneratingDocs(true))
    return Axios.post(resourceType + '/' + application.data.id + ':generate-documents', {}).then(() => {
      toastr.success('Success', 'Documents have been generated')
      dispatch(isGeneratingDocs(false))

      if (callback) {
        callback()
      }

      return true
    }).catch(error => {
      dispatch(isGeneratingDocs(false))
      handleErrorResponse(error, 'Documents failed to generate')
    })
  }
}


export function sendCustomerResetPasswordEmail(id, type, value) {
  return () => {
    return Axios.post('/emails/customers/' + id + ':send-reset-password', { 'meta': { type : type, id: value } }).then(() => {
      toastr.success('Success', 'Customer reset password email sent')
      return true
    }).catch(error => {
      handleErrorResponse(error, 'Email failed to send')
    })
  }
}

export function emailDocuments(values, application, resourceType) {
  return () => {
    return Axios.post(resourceType + '/' + application.data.id + ':email-documents', values).then(() => {
      toastr.success('Success', 'Documents have been emailed' + (values.meta.email ? ' to ' + values.meta.email : ''))
      return true
    }).catch(error => {
      handleErrorResponse(error, 'Documents failed to send')
    })
  }
}

export function sendSms(values, application, resourceType) {
  return () => {
    return Axios.post(resourceType + '/' + application.data.id + ':send-sms', values).then(() => {
      toastr.success('Success', 'SMS has been sent' + (values.meta.number ? ' to ' + values.meta.number : ''))
      return true
    }).catch(error => {
      handleErrorResponse(error, 'Failed to send SMS')
    })
  }
}

export function transferPolicy(values, application, resourceType) {
  return () => {
    return Axios.post(resourceType + '/' + application.data.id + ':transfer', values).then(() => {
      toastr.success('Success', 'Policy transferred to Broker')
      return true
    }).catch(error => {
      handleErrorResponse(error, 'Failed to transfer policy to Broker')
    })
  }
}

export function cancelPolicy(values, application) {
  return dispatch => {
    return Axios.post('policies/' + application.data.id + ':cancel', values).then(() => {
      dispatch(getPolicy(application.data.id))
      toastr.success('Success', 'Policy has been cancelled')
      return true
    }).catch(error => {
      handleErrorResponse(error, 'Failed to cancel policy')
    })
  }
}

export function lapseQuote(values, application) {
  return dispatch => {
    return Axios.post('policies/applications/' + application.data.id + ':lapse', values).then(() => {
      dispatch(getPolicy(application.data.id, true))
      toastr.success('Success', 'Quote has been lapsed')
      return true
    }).catch(error => {
      handleErrorResponse(error, 'Failed to lapse quote')
    })
  }
}

export function unlapseQuote(application) {
  return dispatch => {
    return Axios.post('policies/applications/' + application.data.id + ':unlapse').then(() => {
      dispatch(getPolicy(application.data.id, true))
      toastr.success('Success', 'Quote has been unlapsed')
      return true
    }).catch(error => {
      handleErrorResponse(error, 'Failed to unlapse quote')
    })
  }
}

// Receives an array of ID
export function inviteRenewalsArray(applications) {
  return dispatch => {
    return Axios.post('policies/applications:invite-renewals', { 'meta': { 'applications': applications } }).then(() => {
      /*dispatch(getPolicy(applicationId, true))*/
      toastr.success('Success', 'Renewal has been invited')
      dispatch(clearData())
      return true
    }).catch(error => {
      handleErrorResponse(error, 'Failed to invite renewal')
    })
  }
}

export function inviteRenewal(application) {
  return dispatch => {
    return Axios.post('policies/applications:invite-renewals', { 'meta': { 'applications': [application] } }).then(() => {
      dispatch(getPolicy(application, true))
      toastr.success('Success', 'Renewal has been invited')
      return true
    }).catch(error => {
      handleErrorResponse(error, 'Failed to invite renewal')
    })
  }
}

export function getDetailsForCancellation(application, cb) {
  return dispatch => {
    dispatch(isCheckingCancel(true))
    return Axios.post('policies/' + application.data.id + '/premiums/return-premium').then(response => {
      cb(response.data)
      dispatch(isCheckingCancel(false))
      return true
    }).catch(error => {
      dispatch(isCheckingCancel(false))
      handleErrorResponse(error, 'Policy cannot be canceled at this time')
    })
  }
}

export function updateEndorsement(values) {
  return () => {
    return Axios.patch('policies/endorsements/' + values.data.id, values).then(() => {
      toastr.success('Success', 'Endorsement details updated')
      return true
    }).catch(error => {
      handleErrorResponse(error, 'Failed to update endorsement details')
    })
  }
}


