import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import {
  fetchReminders,
  clearReminder,
  agreeContract,
} from '../../../../dashboard/redux/dashboardActions';
import { FormattedDate } from 'react-intl';
import { get } from 'lodash';
import Markdown from 'react-markdown';
import './Reminders.scss';

class Reminders extends Component {
  componentWillMount() {
    this.props.dispatch(fetchReminders());
  }

  getNoteAttachment(index) {
    return get(this.props.dashboard.reminders.included[index], 'attributes.url', '');
  }

  render() {
    const { dashboard } = this.props;

    return (
      <div>
        <table className="table reminders-list-popup">
          <thead />
          <tbody>
          {get(dashboard.reminders, 'data') && dashboard.reminders.data.length > 0 ? (
            dashboard.reminders.data.map((note, index) => {
              const newTo = {
                pathname: note.attributes.forward_url,
                state: 'tab04',
              };

              return (
                <tr key={index}>
                  <td className="reminder-date-column">
                    <FormattedDate value={note.attributes.reminder_at} />
                  </td>
                  <td>
                    <Markdown source={note.attributes.content} />
                  </td>
                  <td className="reminder-action">
                    {note.attributes.forward_url && (
                      <span>
                          {note.attributes.forward_url === 'agree-contract' ? (
                            <Link
                              onClick={() => {
                                window.open(this.getNoteAttachment(index));
                              }}
                            >
                              View
                            </Link>
                          ) : (
                            <Link to={newTo}>View</Link>
                          )}
                        </span>
                    )}

                    {note.attributes.forward_url && <span />}

                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.dispatch(clearReminder(note.id));
                        if (note.attributes.forward_url === 'agree-contract') {
                          this.props.dispatch(
                            agreeContract(note.attributes.resource_id),
                          );
                        }
                      }}
                      className="m-l-10"
                    >
                      {note.attributes.forward_url === 'agree-contract'
                        ? 'Agree'
                        : 'Clear'}
                    </a>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr key={1}>
              <td colSpan="2">You have no reminders</td>
              <td />
            </tr>
          )}
          </tbody>
        </table>
      </div>
    );
  }
}

Reminders.propTypes = {
  dispatch: PropTypes.func.isRequired,
  dashboard: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    dashboard: state.dashboard,
  };
}

export default connect(mapStateToProps)(Reminders);
