import React from 'react'

export const couple = (
    <svg id="couple_layer_1" data-name="Layer 1" viewBox="0 0 95 95">
      <path className="cls-1" d="M95,47.5A47.5,47.5,0,1,1,47.5,0,47.5,47.5,0,0,1,95,47.5"/>
      <circle style={{ fill: '#fff' }} cx="34.41" cy="17.49" r="6.62"/>
      <path style={{ fill: '#fff' }}
            d="M49.3,39.68A46,46,0,0,0,48.45,35a11.62,11.62,0,0,0-2.62-5.05,11,11,0,0,0-3.66-2.65,12.59,12.59,0,0,0-2.94-.91h0l-0.58-.09a5.35,5.35,0,0,0-1.27-.16h-6a5.35,5.35,0,0,0-1.27.16l-0.58.09h0a12.59,12.59,0,0,0-2.94.91A11,11,0,0,0,23,29.95,11.62,11.62,0,0,0,20.38,35a46,46,0,0,0-.85,4.68c-0.68,5-1,10.57-1,10.61a2.69,2.69,0,1,0,5.37.28s0-.14,0-0.41c0.05-1,.21-3.43.49-6.18,0.14-1.37.3-2.81,0.5-4.15a17.39,17.39,0,0,1,.77-3.59,1.83,1.83,0,0,1,1.41-1.37l-1,14.63a5.48,5.48,0,0,0,0,.66c0,0.09,0,.17,0,0.26v27a3.42,3.42,0,1,0,6.85,0V54.82h2.84V77.44a3.42,3.42,0,1,0,6.85,0v-27c0-.09,0-0.18,0-0.26a5.48,5.48,0,0,0,0-.66l-1-14.63a1.83,1.83,0,0,1,1.41,1.37,17.39,17.39,0,0,1,.77,3.59c0.2,1.34.36,2.78,0.5,4.15,0.27,2.75.43,5.22,0.49,6.18,0,0.27,0,.41,0,0.41a2.69,2.69,0,1,0,5.37-.28S50,44.69,49.3,39.68Z"/>
      <circle style={{ fill: '#fff' }} cx="61.06" cy="19.9" r="6.59"/>
      <path style={{ fill: '#fff' }}
            d="M75.54,50.35c-0.82-3.85-2.87-10.82-3.43-13.29a12.63,12.63,0,0,0-1.88-4.48,8.59,8.59,0,0,0-3.95-3.18,7.81,7.81,0,0,0-.9-0.29,4.8,4.8,0,0,0-1.49-.26H57.54a4.8,4.8,0,0,0-1.49.26,7.81,7.81,0,0,0-.9.29,8.59,8.59,0,0,0-3.95,3.18,12.63,12.63,0,0,0-1.88,4.48c-0.56,2.47-2.61,9.44-3.43,13.29a2.19,2.19,0,0,0,4.29.91C51,47.37,53.07,40.42,53.61,38a9.35,9.35,0,0,1,.79-2.25,16.48,16.48,0,0,1,.91,2.86c1,4.14-4,10.17-4.81,21.85h2.87a3.45,3.45,0,0,1,.53,0H54V78.43a2.43,2.43,0,1,0,4.87,0V60.49h3.73V78.43a2.43,2.43,0,1,0,4.87,0V60.49h0.09a3.45,3.45,0,0,1,.53,0H71c-0.85-11.69-5.79-17.72-4.81-21.85a16.48,16.48,0,0,1,.91-2.86A9.35,9.35,0,0,1,67.84,38c0.54,2.4,2.59,9.35,3.42,13.24A2.19,2.19,0,0,0,75.54,50.35Z"/>
    </svg>
)

export default couple
