import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector, FieldArray } from 'redux-form'
import { FormGroup, Col, ControlLabel } from 'react-bootstrap'
import { AddressFields, RadioGroup } from '../../../../common/components/ReduxFormField'
import PostcodeList from '../../../../common/components/Postcode/PostcodeList'
import getIncludedResource from '../../../../helpers/getIncludedResource'
import LlpPartner from '../../../brochure/components/BrokerApplicationForm/LlpPartner'
import { required } from 'redux-form-validators'
import {
  Box,
  Button,
  ButtonBar,
  HorizontalFormControl,
  InfoLabel,
  SelectUser,
  SelectAuthUserOrganisation,
  SelectOrganisationType,
  SelectLegalEntityType
} from '../../../../common/components'
import { hasPermission } from '../../../auth/redux/authActions'
import _ from 'lodash'
import './OrganisationForm.scss'

const FORM_NAME = 'organisationForm'

class OrganisationForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    resource: PropTypes.object.isRequired,
    formValues: PropTypes.object
  };

  render() {
    const { formValues, handleSubmit, submitting } = this.props
    const resource = this.props.resource.data.attributes
    const organisationType = getIncludedResource(this.props.resource.data, this.props.resource.included, 'organisation_type')
    const insurers = getIncludedResource(this.props.resource.data, this.props.resource.included, 'insurers')

    if (insurers.length === 0) {
      insurers.push({
        attributes: {
          code: ''
        }
      })
    }

    return (
      <Box>
        <h2 className="resource-name">{resource.name}</h2>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-horizontal">
                <Field
                  name="data.attributes.name"
                  label="Name"
                  labelSize={4}
                  component={HorizontalFormControl}
                />
                <Field
                  name="data.attributes.phone1"
                  label="Phone 1"
                  labelSize={4}
                  component={HorizontalFormControl}
                />
                <Field
                  name="data.attributes.phone2"
                  label="Phone 2"
                  labelSize={4}
                  component={HorizontalFormControl}
                />
                <Field
                  name="data.attributes.metadata.website"
                  label="Website"
                  labelSize={4}
                  component={HorizontalFormControl}
                />
                <Field
                  name="data.attributes.metadata.parent_company"
                  label="Parent company"
                  labelSize={4}
                  component={HorizontalFormControl}
                />
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-horizontal">
                <Field
                  name="data.relationships.organisation_type.data"
                  label="Organisation Type"
                  labelSize={4}
                  object={true}
                  component={SelectOrganisationType}
                />
                <Field
                  name="data.attributes.regulatory_authority_number"
                  label="FCA Number"
                  labelSize={4}
                  mdFieldSize={7}
                  component={HorizontalFormControl}
                />
                <Field
                  name="data.relationships.legal_entity_type.data"
                  label="Legal Entity Type"
                  labelSize={4}
                  object={true}
                  component={SelectLegalEntityType}
                />
                {(_.get(formValues, 'data.relationships.legal_entity_type.data.attributes.code') === 'lp'
                || _.get(formValues, 'data.relationships.legal_entity_type.data.attributes.code') === 'llp') && (
                  <FormGroup>
                    <Col sm={4}><ControlLabel>Partners</ControlLabel></Col>
                    <Col sm={8}>
                      <FieldArray
                        name="data.attributes.metadata.partners"
                        component={LlpPartner}
                        fieldComponent={Field}
                      />
                    </Col>
                  </FormGroup>
                )}
                {_.get(formValues, 'data.relationships.legal_entity_type.data.attributes.code') === 'soletrader' && (
                  <Field
                    name="data.attributes.metadata.proprietor"
                    label="Proprietor"
                    labelSize={4}
                    component={HorizontalFormControl}
                  />
                )}
                <Field
                  name="data.attributes.legal_entity_number"
                  label="Company Number"
                  labelSize={4}
                  object={true}
                  component={HorizontalFormControl}
                />
                {_.get(formValues, 'data.relationships.organisation_type.data.attributes.name', _.get(organisationType, 'data.attributes.name')) === 'Broker' && (
                  <Field
                    name="data.attributes.metadata.principal_company"
                    label="Principal company"
                    labelSize={4}
                    component={HorizontalFormControl}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="hr"/>

          <div className="row">
            <div className="col-sm-6">
              <div className="ajdust-postcode">
                <div className="adjust-postcode-label">
                  Postcode
                </div>
                <div className="adjust-postcode-field">
                  <PostcodeList
                    name="data.attributes.address.postcode"
                    label=""
                    labelSize={1}
                    change={this.props.change}
                    value={_.get(formValues, 'data.attributes.address.postcode', '')}
                    targetFields={{
                      line1: 'data.attributes.address.line1',
                      line2: 'data.attributes.address.line2',
                      line3: 'data.attributes.address.line3',
                      town: 'data.attributes.address.town',
                      county: 'data.attributes.address.county',
                      postcode: 'data.attributes.address.postcode'
                    }}
                  />
                </div>
              </div>
              <div className="col-xs-12">
                <div className="spacer"/>
              </div>

              <AddressFields
                baseName="data.attributes.address"
                label="Address"
                labelSize={4}
              />

            </div>

            <div className="col-sm-6">
              <div className="row form-group">
                <Field
                  name="data.relationships.primary_contacts.data"
                  label="Primary contacts"
                  labelSize={4}
                  labelKeys={['attributes.first_name', 'attributes.last_name']}
                  object={true}
                  multi={true}
                  organisation={this.props.resource.data.id}
                  component={SelectUser}
                />
              </div>
              <div className="row form-group">
                <Field
                  name="data.relationships.accounts_contacts.data"
                  label="Accounts contacts"
                  labelSize={4}
                  labelKeys={['attributes.first_name', 'attributes.last_name']}
                  object={true}
                  multi={true}
                  organisation={this.props.resource.data.id}
                  component={SelectUser}
                />
              </div>
            </div>
          </div>

          <div className="hr"/>

          {_.get(formValues, 'data.relationships.organisation_type.data.attributes.name', _.get(organisationType, 'data.attributes.name')) === 'Insurer' && insurers.map((insurer, key) => (
              <div key={'insurer' + key} className="row">
                <div className="col-sm-6">
                  <div className="form-horizontal">
                    {_.get(insurer, 'id') ? (
                        <InfoLabel label="Code" labelSize={4} value={_.get(insurer, 'id')}/>
                      ) : (
                        <Field
                          name={'data.relationships.insurers.data.[' + key + '].attributes.code'}
                          label="Insurer Code"
                          labelSize={4}
                          component={HorizontalFormControl}
                          validate={[required({ msg: 'Field is required' })]}
                        />
                      )}
                    <Field
                      name={'data.relationships.insurers.data.[' + key + '].attributes.agreement_number'}
                      label="Agreement Number"
                      labelSize={4}
                      value={_.get(insurer, 'attributes.agreement_number')}
                      component={HorizontalFormControl}
                    />
                  </div>
                  <div className="form-horizontal">
                    <Field
                      name={'data.relationships.insurers.data.[' + key + '].relationships.authorised_users.data'}
                      label="Authorised Users"
                      labelSize={4}
                      labelKeys={['attributes.first_name', 'attributes.last_name']}
                      multi={true}
                      object={true}
                      component={SelectAuthUserOrganisation}
                    />
                    <Field
                      name={'data.relationships.insurers.data[' + key + '].attributes.carry_broker_risk'}
                      label="Carry Broker Risk"
                      component={RadioGroup}
                      inline={true}
                      labelSize={4}
                      options={[
                        { label: 'Yes', value: 'true' },
                        { label: 'No', value: 'false' }
                      ]}
                    />
                    <Field
                      name={'data.relationships.insurers.data.[' + key + '].attributes.max_commission'}
                      label="Max Brokerage"
                      labelSize={4}
                      percentage={true}
                      component={HorizontalFormControl}
                    />
                  </div>
                </div>
              </div>
            )
          )}
          <ButtonBar>
            { hasPermission('organisation.edit') && (
              <Button
                className="pull-right"
                type="submit"
                bsStyle="primary"
                label="Save Organisation"
                isLoading={submitting}
              />
            )}
          </ButtonBar>
        </form>
      </Box>
    )
  }
}

const form = reduxForm({ form: FORM_NAME })(OrganisationForm)
const selector = formValueSelector(FORM_NAME)
const mapStateToProps = (state, props) => {

  if (Array.isArray(props.resource.data.attributes.metadata) && !props.resource.data.attributes.metadata.length) {
    props.resource.data.attributes.metadata = {}
  }

  const initialValues = props.resource
  const values = selector(state,
    'data.id',
    'data.attributes.address.postcode',
    'data.relationships.organisation_type.data',
    'data.relationships.legal_entity_type.data',
    'data.attributes.metadata.software_house'
  )

  // get included resource
  const insurers = getIncludedResource(props.resource.data, props.resource.included, 'insurers')
  initialValues.data.relationships.insurers.data = insurers

  return {
    initialValues,
    formValues: values
  }
}

export default connect(mapStateToProps)(form)
