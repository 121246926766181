export default function changesToContent(changes, content) {

    if (changes.length) {
        changes.map(change => {
            content += (change + '\n\n')
        })
    }

    return content
}
