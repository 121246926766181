import React from 'react'
import Select from 'react-select'

const PlainSelectField = ({ input, placeholder, options, meta: { touched, error } }) => (
    <div className={(touched && error ? ' has-error' : '')}>
      <Select
          {...input}
          value={input.value || ''}
          placeholder={placeholder || 'Select...'}
          onBlur={() => input.onBlur ? input.onBlur(input.value) : ''}
          onChange={(data) => input.onChange(data ? data.value : '')}
          options={options}
      />

      {touched && error && <span className="input-error">{error}</span>}
    </div>
)

export default PlainSelectField
