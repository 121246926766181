import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormGroup, Col, ControlLabel } from 'react-bootstrap'
import SelectMenu from '../SelectMenu/SelectMenu'


export const SELECT_IDENTIFIER = 'authOrganisationUser'

class SelectMenuAuthUserOrganisation extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    label: PropTypes.string,
    labelSize: PropTypes.number,
    input: PropTypes.object.isRequired,
    infoButton: PropTypes.element,
    name: PropTypes.string,
    meta: PropTypes.object,
    labelKeys: PropTypes.array,
    object: PropTypes.bool,
    multi: PropTypes.bool,
    filters: PropTypes.array
  };


  render() {
    const { input, infoButton, name, meta, label, labelSize, filters, object, multi, labelKeys, ...props } = this.props
    let source = 'users'
    const filterType = 'filter[organisation]'
    const filter = this.props.authenticatedOrganisation

    if (Array.isArray(filters)) {
      filters.map(filter => {
        source += (source.includes('?') ? '&' : '?') + filter.filter + '=' + filter.value
      })
    }

    return (
        <FormGroup controlId={name} className={((meta && meta.touched && meta.error) ? ' has-error' : '')}>
          <Col componentClass={ControlLabel} sm={labelSize}>
            { label } { infoButton }
          </Col>
          <Col sm={(12 - labelSize)}>
            <SelectMenu
                source={source}
                object={object}
                multi={multi}
                name={SELECT_IDENTIFIER}
                field={input}
                autoFilterType={filterType}
                autoFilter={filter}
                labelKeys={labelKeys}
                {...props}
            />

            {(meta && meta.touched && meta.error) && <span className="input-error">{meta.error}</span>}
          </Col>
        </FormGroup>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    authenticatedOrganisation: state.auth.user.data.relationships.current_organisation.data.id
  }
}

export default connect(mapStateToProps)(SelectMenuAuthUserOrganisation)
