import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button } from '../../../common/components'
import InternalContainer from '../../layout/containers/Internal'
import OrganisationFormContainer from './OrganisationForm'
import organisationSchema from '../../../schemas/organisation'

class OrganisationCreateContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    organisation: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired
  }

  render() {
    const buttons = (
        <Button
            bsStyle="default"
            label="Back"
            isBackButton={true}
            leftIcon="caret-left"
        />
    )

    return (
        <InternalContainer title="Organisation" buttons={buttons}>
          <OrganisationFormContainer {...this.props} resource={organisationSchema}/>
        </InternalContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    organisation: state.organisation
  }
}

export default connect(mapStateToProps)(OrganisationCreateContainer)
