import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import flow from 'lodash/flow'
import { Button, Icon, HorizontalFormControl } from '../../../../common/components/index'
import './RegionsForm.scss'

class RegionsForm extends Component {
  static propTypes = {
    fields: PropTypes.object.isRequired
  }

  render() {
    const { fields, meta } = this.props
    return (
      <div className='admin-region-options'>
        <div className='list'>
          {fields.map((region, index) => {
            return (
              <div key={index} className='row'>
                <div className='col-xs-12'>
                  <span
                    className='remove-region-options pull-right'
                    onClick={() => fields.remove(index)}>
                    <Icon name='times' />
                  </span>
                  {<label className="control-label">{'Region ' + (index + 1)}</label>}
                </div>
                <div className="row">
                  <div className='col-xs-12'>
                    <Field
                      label="Label"
                      name={region + '.label'}
                      type="textarea"
                      component={HorizontalFormControl}
                    />
                  </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12">
                      <Field
                        label="Value"
                        name={region + '.value'}
                        type="textarea"
                        component={HorizontalFormControl}
                      />
                    </div>
                  </div>
              </div>
            )
          })}
          <div>
            <div className="row">
              <div className="col-xs-2 col-md-2 col-lg-2"/>
              <div className="col-xs-4 col-md-2 col-lg-2 add-region-button">
                <Button type="button" handleClick={() => fields.push({ type: 'header' })}>Add Region</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default flow(
  connect()
)(RegionsForm)
