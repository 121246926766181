import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MultipleChoiceQuestion from '../MultipleChoiceQuestion'
import SingleChoiceQuestion from '../SingleChoiceQuestion'
import BmiQuestion from '../BmiQuestion'
import BooleanQuestion from '../BooleanQuestion'
import './styles.scss'
import getIncludedResponse from '../../../../helpers/getIncludedResource'

export default class ConditionQuestions extends Component {
  static propTypes = {
    condition: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    screening: PropTypes.object.isRequired
  }

  render() {
    const { screening, condition, dispatch } = this.props
    const questions = getIncludedResponse(condition, screening.declaration.included, 'questions')

    return (
      <div className="condition-questions">
        <h4 className="condition-label">Screening questions for {condition.attributes.name}</h4>
        {questions.length > 0 ? Object.keys(questions).map((key, i) => {
          const question = questions[key]

          switch (question.attributes.questionType) {
            case 'bmi':
              return (
                <BmiQuestion
                  key={i}
                  index={i}
                  dispatch={dispatch}
                  screening={screening}
                  condition={condition}
                  question={question}
                />
              )
            case 'bool':
              return (
                <BooleanQuestion
                  key={i}
                  index={i}
                  dispatch={dispatch}
                  screening={screening}
                  condition={condition}
                  question={question}
                />
              )
            case 'multiple-choice':
              return (
                <MultipleChoiceQuestion
                  key={i}
                  index={i}
                  dispatch={dispatch}
                  screening={screening}
                  condition={condition}
                  question={question}
                />
              )
            case 'single-choice':
            default:
              return (
                <SingleChoiceQuestion
                  key={i}
                  index={i}
                  dispatch={dispatch}
                  screening={screening}
                  condition={condition}
                  question={question}
                />
              )
          }
        }) : (
          <p>There are no questions to answer for this condition.</p>
        )}
      </div>
    )
  }
}
