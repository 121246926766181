export const SET_ROLE = 'socrates-online/role/SET_ROLE'
export const SET_PERMISSIONS = 'socrates-online/role/SET_PERMISSIONS'
export const CLEAR_ROLE = 'socrates-online/role/CLEAR_ROLE'
export const IS_SUBMITTING = 'socrates-online/role/IS_SUBMITTING'

export const initialState = {
  isSubmitting: false,
  resources: {},
  permissions: []
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.status
      }
    case SET_ROLE:
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.data.data.id]: {
            ...action.data
          }
        }
      }
    case SET_PERMISSIONS:
      return {
        ...state,
        permissions: action.data
      }
    case CLEAR_ROLE:
      return initialState
    default:
      return state
  }
}
