import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { RadioGroupDisgusedAsButtons } from '../../../../../../common/components/ReduxFormField'
import * as _ from 'lodash'
import Markdown from 'react-commonmark'
import ScreeningContainer from '../../../../../screening/containers/ScreeningContainer'

export class MedicalTab extends Component {
  render () {
    const { currentProduct, formValues } = this.props
    const screeningProduct = _.get(currentProduct, 'data.attributes.metadata.screening', true)
    const medicalDeclarationQuestions = _.get(formValues, 'data.attributes.metadata.medical_declaration_questions', []);
    let requiresScreening = screeningProduct && medicalDeclarationQuestions.some((declaration, i) => {
      const answer = _.get(formValues, 'data.attributes.metadata.medical_declaration_questions[' + i + '].answer', false);
      return answer === 'true' || answer === true
    })
    const covidNoticeAllowedOnProducts = ['Axiom Broker Network']
    const currentProductName = _.get(currentProduct, 'data.attributes.name', '')
    let showCovidNotice = currentProductName && covidNoticeAllowedOnProducts.includes(currentProductName);

    return (
      <div>
        <div className='form-horizontal'>
          <h4><strong>Medical Declaration</strong></h4>
          {currentProduct.data.attributes.metadata.medical_declaration ? (
            <div className='medical-declaration'>
              <Markdown source={currentProduct.data.attributes.metadata.medical_declaration || ''} linkTarget="_blank" />
            </div>
          ) : '' }
          <div className='divider ' />
        </div>
        <div className='questions'>
          {medicalDeclarationQuestions.map((declaration, i) => {
            if (declaration.type && declaration.type === 'header') {
              return (
                <div className='question-box medical-declaration-question-header' key={i}>
                  <div className='question'><Markdown source={declaration.content || ''} /></div>
                </div>
              )
            }
            return (
              <div className='question-box medical-declaration-question' key={i}>
                <div className='question'><Markdown source={declaration.content || ''} /></div>
                <div className='answer'>
                  <Field
                    name={'data.attributes.metadata.medical_declaration_questions[' + i + '].answer'}
                    component={RadioGroupDisgusedAsButtons}
                    inline
                    options={[
                      { label: 'Yes', value: 'true', icon: 'check' },
                      { label: 'No', value: 'false', icon: 'times' }
                    ]}
                  />
                </div>
              </div>
            )
          })}
        </div>

        <hr/>

        {showCovidNotice && (
          <div className='row'>
            <div className='col-12 covid-notice'>
              <h4>CORONAVIRUS NOTICE</h4>
              This Insurance is currently unable to consider cover for those individuals
              who are suffering or have previously suffered from COVID-19. In such cases
              we regret to advise that we are unable to offer a quotation.
            </div>
          </div>
        )}

        {requiresScreening && (
          <div className='row medical-screening m-b-10'>
            <div className='col-xs-12 screening-message'>
              <p className='control-label'>As you answered "yes" to one or more of the medical declaration questions above you are
                required to go
                through the medical screening process for each traveller in order to proceed with your
                quote.</p>
            </div>

            {_.get(formValues, 'data.attributes.metadata.adults', []).map((adult, index) => {
              return (
                <ScreeningContainer
                  key={index}
                  index={(index + 1)}
                  traveller={adult}
                  region={_.get(formValues, 'data.attributes.metadata.region', '')}
                  destinations={_.get(formValues, 'data.attributes.metadata.destinations', [])}
                  conditionsCovered={_.get(formValues, 'data.attributes.metadata.adults[' + index + '].conditions_covered')}
                  changeConditionsCovered={(value) => {
                    this.props.dispatch(this.props.change('data.attributes.metadata.adults[' + index + '].conditions_covered', value))
                  }}
                  callback={(screening, screeningId) => {
                    this.props.dispatch(this.props.change('data.attributes.metadata.adults[' + index + '].screening', screening))
                    this.props.dispatch(this.props.change('data.attributes.metadata.adults[' + index + '].screening_id', screeningId))
                  }}
                />
              )
            })}
            {_.get(formValues, 'data.attributes.metadata.children', []).map((child, index) => {
              return (
                <ScreeningContainer
                  key={index}
                  index={(_.get(formValues, 'data.attributes.metadata.adults', []).length + index + 1)}
                  traveller={child}
                  region={_.get(formValues, 'data.attributes.metadata.region', '')}
                  destinations={_.get(formValues, 'data.attributes.metadata.destinations', [])}
                  conditionsCovered={_.get(formValues, 'data.attributes.metadata.children[' + index + '].conditions_covered')}
                  changeConditionsCovered={(value) => {
                    this.props.dispatch(this.props.change('data.attributes.metadata.children[' + index + '].conditions_covered', value))
                  }}
                  callback={(screening, screeningId) => {
                    this.props.dispatch(this.props.change('data.attributes.metadata.children[' + index + '].screening', screening))
                    this.props.dispatch(this.props.change('data.attributes.metadata.children[' + index + '].screening_id', screeningId))
                  }}
                />
              )
            })}
          </div>
        )}

      </div>
    )
  }
}

const FORM_NAME = 'LeisureTravelForm'
const form = reduxForm({ form: FORM_NAME })(MedicalTab)
const selector = formValueSelector(FORM_NAME)
const mapStateToProps = (state, props) => {
  const values = selector(state,
    'data.id',
    'data.attributes.metadata.adults',
    'data.attributes.metadata.children',
    'data.attributes.metadata.medical_declaration_questions',
    'data.attributes.metadata.destinations',
    'data.attributes.metadata.region',
    'data.attributes.metadata.screeningProduct'
  )

  return {
    auth: state.auth,
    initialValues: props.resource,
    form: 'LeisureTravelForm',
    formValues: values,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    product: state.product
  }
}

export default connect(mapStateToProps)(form)
