import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getUser, getUserOrganisationRoles } from '../redux/userActions'
import { Button, Loader } from '../../../common/components'
import InternalContainer from '../../layout/containers/Internal'
import UserRolesFormContainer from './UserRolesFormContainer'

class UserEditRolesContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired
  };

  componentWillMount() {
    this.props.dispatch(getUser(this.props.params.id))
    this.props.dispatch(getUserOrganisationRoles(this.props.params.id, this.props.params.organisationId))
  }

  render() {
    const resource = this.props.user.resources[this.props.params.id]

    const buttons = (
        <Button
            bsStyle="default"
            label="back"
            isBackButton={true}
            leftIcon="caret-left"
        />
    )


    return (
        <InternalContainer title="User" buttons={buttons}>
          {resource && this.props.user.organisationRoles.organisation ? (
                  <UserRolesFormContainer
                      {...this.props}
                      resource={resource}
                      organisation={this.props.user.organisationRoles.data.meta.organisation}
                      roles={this.props.user.organisationRoles.data.meta.roles}
                  />
              ) : (
                  <Loader />
              )}
        </InternalContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(UserEditRolesContainer)
