import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box } from '../../../../common/components'
import displayName from '../../../../helpers/displayName'
import { push } from 'react-router-redux'
import './styles.scss'

class AccountSummary extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    summaries: PropTypes.object.isRequired
  };

  render () {
    const { summaries } = this.props
    return (
      <div>
        <Box>
          <div className='row'>
            {Object.keys(summaries).map((accountType) => (
              <div key={accountType} className='col-sm-6 col-md-3'>
                <div className='account-summary-box' onClick={() => this.props.dispatch(push('/accounts/ledger?filter=' + accountType))}>
                  <h2>{displayName(accountType)} Accounts</h2>
                  <p className='currency-container-flexbox'>
                    {Object.keys(summaries[accountType]).map((currency) => (
                      <span key={accountType + '-' + currency} className='account-total'>{currency + ' ' + summaries[accountType][currency]}</span>
                    ))}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </Box>
      </div>
    )
  }
}

export default AccountSummary
