import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { Button, Modal, HorizontalFormControl } from '../../../../common/components'
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux'
import { emailDocuments } from '../../redux/policyActions'
import { Checkbox } from '../../../../common/components/ReduxFormField'
import _ from 'lodash'
import './styles.scss'

const FORM_NAME = 'emailCustomerDocumentsForm'

const validate = values => {
  const errors = {}

  if (!_.get(values, 'meta.recipient_name')) {
    _.set(errors, 'meta.recipient_name', 'Name is required')
  }

  if (!_.get(values, 'meta.email')) {
    _.set(errors, 'meta.email', 'Email is required')
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,40}$/i.test(_.get(values, 'meta.email'))) {
    _.set(errors, 'meta.email', 'Invalid email address')
  }

  return errors
}

class EmailCustomerDocumentsButton extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    resourceType: PropTypes.string.isRequired,
    user: PropTypes.object,
    callback: PropTypes.func,
    label: PropTypes.string,
    disabled: PropTypes.bool
  };

  static defaultProps = {
    disabled: false
  };

  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded, callback } = this.props
    if (newProps.submitSucceeded && !submitSucceeded) {
      if (callback) {
        callback('')
      }
      dispatch(closeModal(FORM_NAME))
    }
    if (newProps.submitFailed) {
      dispatch(closeModal(FORM_NAME))
    }
  }

  includeDocument(relation) {
    return relation.type === 'documents'
      && relation.id
      && relation.attributes.name !== 'Broker Issue Letter';
  }

  selectAllDocuments() {
    const { resource, dispatch, change } = this.props;
    if (!resource.included) {
      return;
    }

    const documents = [];
    resource.included.map(relation => {
      if (this.includeDocument(relation)) {
        documents.push(relation.id);
      }
    });
    dispatch(change('meta.documents', documents));
  }

  handleSubmit(values) {
    return this.props.dispatch(emailDocuments(values, this.props.resource, this.props.resourceType))
  }

  handleOpen() {
    const { dispatch, reset } = this.props
    dispatch(reset())
    dispatch(openModal(FORM_NAME))

    this.selectAllDocuments();
  }

  render() {
    const { resource, label, handleSubmit, submitting } = this.props
    let documentIndex = 0
    const title = label || 'Email documents'
    const submitButton = (<Button
      type="submit"
      bsStyle="primary"
      isLoading={submitting}
      label="Send Email"
    />)

    // TODO: Refactor with Email documents button
    return (
      <div className="email-btn-wrapper pull-right">
        <Button
          bsStyle="primary"
          label={title}
          rightIcon="envelope-o"
          handleClick={::this.handleOpen}
          disabled={this.props.disabled}
        />

        <Modal
          name={FORM_NAME}
          title={title}
          close={true}
          handleSubmit={handleSubmit(::this.handleSubmit)}
          footer={submitButton}
        >
          <div className="form-horizontal">
            <div className="col-xs-12">
              <Field
                name={'meta.recipient_name'}
                label="Recipient name"
                labelSize={3}
                component={HorizontalFormControl}
              />
              <Field
                name={'meta.email'}
                label="Email address"
                labelSize={3}
                component={HorizontalFormControl}
              />

              <div className="row document-selection-list">
                {resource.included && resource.included.map(relation => {
                  if (this.includeDocument(relation)) {
                    documentIndex++
                    return (
                      <div className="col-sm-6 document-list" key={documentIndex}>
                        <Field
                          label={relation.attributes.name}
                          checkboxValue={relation.id}
                          name={'meta.documents[' + (documentIndex - 1) + ']'}
                          component={Checkbox}
                        />
                      </div>
                    )
                  }
                })}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(EmailCustomerDocumentsButton)

const selector = formValueSelector(FORM_NAME)
const mapStateToProps = (state, props) => {
  const values = selector(state,
    'meta.recipient_name',
    'meta.email',
  )

  return {
    initialValues: {
      meta: {
        recipient_name: _.get(props.user, 'data.attributes.first_name', '') + ' ' + _.get(props.user, 'data.attributes.last_name', ''),
        email: _.get(props.user, 'data.attributes.email', '')
      }
    },
    formValues: values
  }
}

export default connect(mapStateToProps)(form)
