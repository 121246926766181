import * as actions from './campaignReducer'
import Axios from 'axios'
import { push } from 'react-router-redux'
import handleErrorResponse from '../../../helpers/handleErrorResponse'

export const defaultIncludes = '?include=channels,product'

export function getCampaigns(id, includes = defaultIncludes) {
  return (dispatch) => {
    let endpoint = 'campaigns/' + id + includes
    return Axios.get(endpoint).then(response => {
      dispatch({
        type: actions.SET_CAMPAIGNS,
        data: response.data
      })
      return true
    }).catch(error => {
      console.error(error)
    })
  }
}

export function updateCampaign(resource) {
  return dispatch => {
    const id = resource.data.id
    let endpoint = 'campaigns/' + id + '?include=channels,product'

    return Axios.patch(endpoint, resource).then(response => {
      dispatch({
        type: actions.SET_CAMPAIGNS,
        data: response.data
      })
      dispatch(push('/admin/campaigns/'))
      return true
    }).catch(error => {
      handleErrorResponse(error, 'There was an error updating the campaign')
    })
  }
}

export function isSaving(status) {
  return {
    type: actions.IS_SAVING,
    status
  }
}

export function createCampaign(resource) {
  return dispatch => {
    dispatch(isSaving(true))
    return Axios.post('campaigns?include=channels,product', resource).then(response => {
      dispatch(isSaving(false))
      dispatch(push('/admin/campaigns/' + response.data.data.id))
      return true
    }).catch(error => {
      dispatch(isSaving(false))
      handleErrorResponse(error, 'There was an error creating the campaign')
    })
  }
}
