import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, Loader } from '../../../common/components'
import { hasPermission } from '../../auth/redux/authActions'
import InternalContainer from '../../layout/containers/Internal'
import AccountSummary from '../components/AccountSummary'
import { getSummaries } from '../redux/accountActions'

class AccountLedgerContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };

  componentWillMount() {
    this.props.dispatch(getSummaries())
  }

  render() {
    const { account } = this.props

    return (
        <InternalContainer title="Accounts Summary">
          {account.summaries && account.summaries.ledger_summary ? (
              <AccountSummary {...this.props} summaries={account.summaries.ledger_summary} />
          ) : (
              <loader />
          )}
        </InternalContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    account: state.account
  }
}

export default connect(mapStateToProps)(AccountLedgerContainer)
