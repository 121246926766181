import * as actions from './contentReducer'
import Axios from 'axios'
import queryString from '../../../helpers/queryString'
import { push } from 'react-router-redux'
import handleErrorResponse from '../../../helpers/handleErrorResponse'
import { toastr } from 'react-redux-toastr'

export function isUpdatingKeyPersonnel(status) {
  return {
    type: actions.IS_UPDATING_KEY_PERSONNEL,
    status
  }
}

export function isUpdatingPartners(status) {
  return {
    type: actions.IS_UPDATING_PARTNERS,
    status
  }
}

/**
 * Creates a new content resource
 * @param params
 * @returns {{type, data: *}}
 */
export function createContent(resource, includes = ['template', 'files']) {
  return (dispatch, getState) => {
    const endpoint = 'contents/' + queryString(includes)

    return Axios.post(endpoint, resource).then(response => {
      dispatch({
        type: actions.SET_CONTENT,
        data: response.data
      })

      dispatch(push('/admin/content/' + response.data.data.id))
      return true
    }).catch(error => {
      handleErrorResponse(error, 'There was an error creating your content')
    })
  }
}

/**
 * Loads the content for the requested id
 * @param id
 * @returns {{type, data: *}}
 */
export function getContent(id, includes = ['template', 'files']) {
  return (dispatch, getState) => {
    const endpoint = 'contents/' + id + queryString(includes)

    return Axios.get(endpoint).then(response => {
      dispatch({
        type: actions.SET_CONTENT,
        data: response.data
      })
      return true
    }).catch(error => {
      console.error(error)
    })
  }
}

/**
 * Updates a content resource
 * @param resource
 * @returns {{type, data: *}}
 */
export function updateContent(resource, includes = ['template', 'files']) {
  return (dispatch, getState) => {
    const endpoint = 'contents/' + resource.data.id + queryString(includes)

    return Axios.patch(endpoint, resource).then(response => {
      dispatch({
        type: actions.SET_CONTENT,
        data: response.data
      })

      dispatch(push('/admin/content/' + response.data.data.id))
      return true
    }).catch(error => {
      handleErrorResponse(error, 'There was an error updating your content')
    })
  }
}

/**
 * Loads the template for the requested type
 * @param id
 * @returns {{type, data: *}}
 */
export function getTemplate(type) {
  const endpoint = 'templates?filter[template_type]=' + type
  return Axios.get(endpoint).then(response => {
    return response.data.data[0]
  }).catch(error => {
    console.error(error)
  })
}

/**
 * Loads the content for the key personnel
 * @param id
 * @returns {{type, data: *}}
 */
export function getKeyPersonnel() {
  return (dispatch, getState) => {
    const endpoint = 'key-personnel?page[limit]=500&include=user.avatar'

    return Axios.get(endpoint).then(response => {
      dispatch({
        type: actions.SET_KEY_PERSONNEL,
        data: response.data.data
      })
      return true
    }).catch(error => {
      console.error(error)
    })
  }
}

/**
 * Updates the key personnel
 * @param resource
 * @returns {{type, data: *}}
 */
export function updateKeyPersonnel(resource) {
  return (dispatch, getState) => {
    const endpoint = 'key-personnel?page[limit]=500&include=user.avatar'
    dispatch(isUpdatingKeyPersonnel(true))

    return Axios.post(endpoint, resource).then(response => {
      dispatch({
        type: actions.SET_KEY_PERSONNEL,
        data: response.data.data
      })
      dispatch(isUpdatingKeyPersonnel(false))
      toastr.success('Key Personnel Updated', 'Key personnel has been successfully updated')
      return true
    }).catch(error => {
      dispatch(isUpdatingKeyPersonnel(false))
      handleErrorResponse(error, 'There was an error updating your content')
    })
  }
}

/**
 * Loads the content for the partners
 * @param id
 * @returns {{type, data: *}}
 */
export function getPartners() {
  return (dispatch, getState) => {
    const endpoint = 'partners?page[limit]=500'

    return Axios.get(endpoint).then(response => {
      dispatch({
        type: actions.SET_PARTNERS,
        data: response.data.data
      })
      return true
    }).catch(error => {
      console.error(error)
    })
  }
}

/**
 * Updates the partners
 * @param resource
 * @returns {{type, data: *}}
 */
export function updatePartners(resource, includes = []) {
  return (dispatch, getState) => {
    const endpoint = 'partners?page[limit]=500'
    dispatch(isUpdatingPartners(true))

    return Axios.post(endpoint, resource).then(response => {
      dispatch({
        type: actions.SET_PARTNERS,
        data: response.data.data
      })
      dispatch(isUpdatingPartners(false))
      toastr.success('Partners Updated', 'Partners has been successfully updated')
      return true
    }).catch(error => {
      dispatch(isUpdatingPartners(false))
      handleErrorResponse(error, 'There was an error updating your content')
    })
  }
}
