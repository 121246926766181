import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { SelectField } from '../../../../common/components/ReduxFormField'
import {
  Icon,
  Button,
  SelectAccount,
  SelectBroker,
  SelectProduct,
  SelectCurrency,
  HorizontalFormControl
} from '../../../../common/components'
import './styles.scss'

const journalDefaults = {
  account: '',
  description: '',
  currency: 'GBP',
  debit: 0,
  credit: 0,
  broker: '',
  product: '',
  premium_element_type: ''
}

export default class Journal extends Component {
  static propTypes = {
    fields: PropTypes.object.isRequired,
    entryType: PropTypes.string.isRequired,
    formValues: PropTypes.object
  };

  componentWillMount() {
    this.props.fields.push(journalDefaults)
    this.props.fields.push(journalDefaults)
  }

  render() {
    const { fields, entryType } = this.props

    const premiumElementTypes = [
      { label: 'Net', value: 'net' },
      { label: 'Additional', value: 'additional' },
      { label: 'Medical', value: 'medical' },
      { label: 'Fees', value: 'fees' },
      { label: 'Discount', value: 'discount' },
      { label: 'Commission', value: 'commission' },
      { label: 'Tax', value: 'tax' },
      { label: 'Tax Exclusive', value: 'ex_tax' }
    ]

    return (
        <div>
          {fields.map((entry, index) => {
                return (
                    <div key={index} className="journal-ledger-entry">
                      <div className="journal-ledger-entry-header">
                        {index > 1 && (
                            <span className="remove-entry" onClick={() => fields.remove(index)}>
                        <Icon name="times"/>
                      </span>
                        )}
                        <div className="row form-group">
                          <div className="col-xs-4">
                            <Field
                                name={entry + '.account'}
                                label="Account"
                                labelSize={4}
                                component={SelectAccount}
                            />
                          </div>
                          <div className="col-xs-4">
                            <Field
                                name={entry + '.broker'}
                                label="Broker"
                                labelSize={3}
                                component={SelectBroker}
                            />
                          </div>
                          <div className="col-xs-4">
                            <Field
                                name={entry + '.product'}
                                label="Product"
                                labelSize={3}
                                component={SelectProduct}
                            />
                          </div>
                        </div>
                        <div className="row form-group">
                          <div className="col-xs-8">
                            <Field
                                name={entry + '.description'}
                                label="Description"
                                labelSize={2}
                                component={HorizontalFormControl}
                            />
                          </div>
                          {entryType === 'premium' && (
                              <div className="col-xs-4">
                                <Field
                                    name={entry + '.premium_element_type'}
                                    label="Premium Element"
                                    labelSize={3}
                                    options={premiumElementTypes}
                                    component={SelectField}
                                />
                              </div>
                          )}
                        </div>
                      </div>
                      <div className="journal-ledger-entry-amounts">
                        <div className="row form-group">
                          <div className="col-xs-4">
                            <Field
                                name={entry + '.currency'}
                                label="Currency"
                                labelSize={3}
                                component={SelectCurrency}
                            />
                          </div>
                          <div className="col-xs-4">
                            <Field
                                name={entry + '.debit'}
                                label="Debit"
                                labelSize={3}
                                currency={true}
                                component={HorizontalFormControl}
                            />
                          </div>
                          <div className="col-xs-4">
                            <Field
                                name={entry + '.credit'}
                                label="Credit"
                                labelSize={3}
                                currency={true}
                                component={HorizontalFormControl}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                )
              }
          )}
          <div className="actions">
            <Button bsStyle="primary" size="sm" label="Add a new line"
                    handleClick={() => fields.push(journalDefaults)}/>
          </div>
        </div>
    )
  }
}
