import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Tabs, Tab } from 'react-bootstrap'
import { getUser, getUserOrganisationRoles } from '../redux/userActions'
import getIncludedResource from '../../../helpers/getIncludedResource'
import { isBroker, isOwner } from '../../auth/redux/authActions'
import { Button, Loader } from '../../../common/components'
import InternalContainer from '../../layout/containers/Internal'
import UserDetails from '../components/UserDetails/UserDetails'
import UserRoles from '../components/UserRoles'
import UserNotes from '../components/UserNotes/UserNotes'
import UserAudit from '../components/UserAudit'

class UserShowContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context)

    this.state = {
      activeTab: 1
    }
  }

  componentWillMount() {
    this.props.dispatch(getUser(this.props.params.id))
    this.props.dispatch(getUserOrganisationRoles(this.props.params.id))
  }

  handleTab(key) {
    this.setState({ activeTab: key })
  }

  isInOwnerOrganisation(resource) {
    const organisations = getIncludedResource(resource.data, resource.included, 'organisations')
    let isOwner = false

    if (organisations) {
      organisations.forEach(organisation => {
        if (organisation.attributes.organisation_type_name === 'Owner') {
          isOwner = true
        }
      })
    }

    return isOwner
  }

  render() {
    const resource = this.props.user.resources[this.props.params.id]
    const contact = resource ? resource.data.attributes.status === 'contact' : false
    const buttons = (
      <Button
        bsStyle="default"
        label="Back"
        isBackButton={true}
        leftIcon="caret-left"
      />
    )

    return (
      <InternalContainer title={ contact ? 'Contact' : 'User' } buttons={buttons}>
        {resource ? (
          <Tabs activeKey={this.state.activeTab}
                id="user-tabs"
                unmountOnExit={true}
                onSelect={::this.handleTab}>

            <Tab eventKey={1} title="Details">
              <UserDetails
                {...this.props}
                resource={resource}
                isInOwnerOrganisation={::this.isInOwnerOrganisation(resource)}
              />
            </Tab>

            {!contact && (
              <Tab eventKey={2} title="Organisation Roles">
                <UserRoles {...this.props} resource={resource}/>
              </Tab>
            )}

            {!isBroker() && !contact && (
              <Tab eventKey={3} title="Notes">
                <UserNotes {...this.props} resource={resource}/>
              </Tab>
            )}

            {isOwner() && (
                <Tab eventKey={4} title="Audit">
                  <UserAudit {...this.props} resource={resource}/>
                </Tab>
            )}

          </Tabs>
        ) : (
            <Loader />
        )}
      </InternalContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(UserShowContainer)
