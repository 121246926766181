import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { grantPermission } from '../../../auth/redux/authActions';
import ButtonBar from '../../../../common/components/ButtonBar/ButtonBar';

export class InternalButtonBar extends Component {
  static propTypes = {
    buttons: PropTypes.array.isRequired,
  };

  renderButtons() {
    const { buttons } = this.props;

    return buttons.map((button) => {
      if (button.button && grantPermission(button)) {
        return button.button;
      }
    });
  }

  render() {
    return <ButtonBar children={this.renderButtons()} />;
  }
}
