import React from 'react'

export const accountSummary = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95 95">
    <title>Icons</title>
    <path d="M22.5,28.75A1.29,1.29,0,0,1,21.25,30h-7.5a1.29,1.29,0,0,1-1.25-1.25v-7.5A1.29,1.29,0,0,1,13.75,20h7.5a1.29,1.29,0,0,1,1.25,1.25Zm0,15A1.29,1.29,0,0,1,21.25,45h-7.5a1.29,1.29,0,0,1-1.25-1.25v-7.5A1.29,1.29,0,0,1,13.75,35h7.5a1.29,1.29,0,0,1,1.25,1.25Zm0,15A1.29,1.29,0,0,1,21.25,60h-7.5a1.29,1.29,0,0,1-1.25-1.25v-7.5A1.29,1.29,0,0,1,13.75,50h7.5a1.29,1.29,0,0,1,1.25,1.25Zm0,15A1.29,1.29,0,0,1,21.25,75h-7.5a1.29,1.29,0,0,1-1.25-1.25v-7.5A1.29,1.29,0,0,1,13.75,65h7.5a1.29,1.29,0,0,1,1.25,1.25Zm60-45A1.29,1.29,0,0,1,81.25,30H28.75a1.29,1.29,0,0,1-1.25-1.25v-7.5A1.29,1.29,0,0,1,28.75,20h52.5a1.29,1.29,0,0,1,1.25,1.25Zm0,15A1.29,1.29,0,0,1,81.25,45H28.75a1.29,1.29,0,0,1-1.25-1.25v-7.5A1.29,1.29,0,0,1,28.75,35h52.5a1.29,1.29,0,0,1,1.25,1.25Zm0,15A1.29,1.29,0,0,1,81.25,60H28.75a1.29,1.29,0,0,1-1.25-1.25v-7.5A1.29,1.29,0,0,1,28.75,50h52.5a1.29,1.29,0,0,1,1.25,1.25Zm0,15A1.29,1.29,0,0,1,81.25,75H28.75a1.29,1.29,0,0,1-1.25-1.25v-7.5A1.29,1.29,0,0,1,28.75,65h52.5a1.29,1.29,0,0,1,1.25,1.25Z"/>
  </svg>
)

export default accountSummary
