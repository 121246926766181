import { toastr } from 'react-redux-toastr'
import { SubmissionError } from 'redux-form'
import _ from 'lodash'

export default function handleErrorResponse(error, defaultMessage = 'An application error has occurred') {
  const message = _.get(error.response, 'data.errors[0].detail', defaultMessage)
  toastr.error('Error', message)

  throw new SubmissionError({ _error: message })
}
