import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ScreeningSession from '../components/ScreeningSession'
import { finishScreening, recalculateScore, startScreening } from '../redux/screeningActions'
import { toastr } from 'react-redux-toastr'
import * as _ from 'lodash'

export class ScreeningContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    screening: PropTypes.object.isRequired,
    callback: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
    traveller: PropTypes.object.isRequired,
    region: PropTypes.string.isRequired,
    destinations: PropTypes.array,
    changeConditionsCovered: PropTypes.func,
    conditionsCovered: PropTypes.bool,
  }

  componentWillMount() {
    this.recalculateScore = _.debounce(this.recalculateScore, 1000)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.region !== this.props.region
      || !_.isEmpty(_.difference(nextProps.destinations, this.props.destinations))) {
      // re-screen if region or destinations have changed
      this.recalculateScore(nextProps)
    }
  }

  startScreeningSession() {
    const { index, traveller, dispatch, region, destinations } = this.props

    if (!region && destinations.length === 0) {
      return toastr.error('Trip Details Required', 'Please select a travel destination or region before adding medical conditions.')
    }

    dispatch(startScreening(index, traveller.screening_id, region, destinations, 'cega'))
  }

  finishScreeningSession() {
    const { callback, dispatch } = this.props
    dispatch(finishScreening(callback))
  }

  recalculateScore(nextProps) {
    const { index, traveller, callback, dispatch, region, destinations } = nextProps

    if (traveller.screening_id) {
      console.log('re-screening traveller ' + index)
      dispatch(recalculateScore(callback, traveller.screening_id, region, destinations))
    }
  }

  render() {
    const { index, traveller, screening, dispatch, changeConditionsCovered, conditionsCovered } = this.props

    return (
      <ScreeningSession
        index={index}
        traveller={traveller}
        screening={screening}
        startScreening={::this.startScreeningSession}
        finishScreening={::this.finishScreeningSession}
        changeConditionsCovered={changeConditionsCovered}
        conditionsCovered={conditionsCovered}
        dispatch={dispatch}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    screening: state.screening
  }
}

export default connect(mapStateToProps)(ScreeningContainer)
