import React from 'react'
import { IndexRoute, Redirect, Route } from 'react-router'
import requirePermission from './helpers/requirePermission'
import AppContainer from './modules/layout/containers/App'
import Error404Container from './modules/brochure/containers/Error404'
import Error500Container from './modules/brochure/containers/Error500'
import HomepageContainer from './modules/brochure/containers/Homepage'
import CookiesContainer from './modules/brochure/containers/Cookies'
import PrivacyContainer from './modules/brochure/containers/Privacy'
import TermsContainer from './modules/brochure/containers/Terms'
import RequirementsContainer from './modules/brochure/containers/Requirements'
import ContactContainer from './modules/brochure/containers/Contact'
import DashboardContainer from './modules/dashboard/containers/Dashboard'
import LoginContainer from './modules/auth/containers/Login'
import PasswordReminderContainer from './modules/auth/containers/PasswordReminder'
import ResetPasswordContainer from './modules/auth/containers/ResetPassword'
import AccountListContainer from './modules/account/containers/AccountList'
import AccountSummaryContainer from './modules/account/containers/AccountSummary'
import AccountLedgerContainer from './modules/account/containers/AccountLedger'
import AccountShowContainer from './modules/account/containers/AccountShow'
import AccountMatchingContainer from './modules/account/containers/AccountMatching'
import AccountEditContainer from './modules/account/containers/AccountEdit'
import AccountReportsContainer from './modules/account/containers/AccountReports'
import AccountBookkeepingContainer from './modules/account/containers/AccountBookkeeping'
import AccountCreateContainer from './modules/account/containers/AccountCreate'
import AccountPaymentReceiptsContainer
  from './modules/account/containers/AccountPaymentReceiptsContainer'
import AccountJournalContainer from './modules/account/containers/AccountJournalContainer'
//Do not remove this, it includes styles that are necessary
import BrokersApListContainer
  from './modules/brokerApplication/containers/BrokersApListContainer'
import BrokersApNewContainer
  from './modules/brokerApplication/containers/BrokersApNewContainer'
import BrokersApEditContainer
  from './modules/brokerApplication/containers/BrokersApEditContainer'
import ChallengeListContainer from './modules/challenge/containers/ChallengeList'
import ChallengeShowContainer from './modules/challenge/containers/ChallengeShow'
import CustomerListContainer from './modules/customer/containers/CustomerList'
import CustomerShowContainer from './modules/customer/containers/CustomerShow'
import CustomerEditContainer from './modules/customer/containers/CustomerEdit'
import CustomerCreateContainer from './modules/customer/containers/CustomerCreate'
import ApplicationListContainer from './modules/policy/containers/ApplicationList'
import MtaListContainer from './modules/policy/containers/MtaList'
import RenewalListContainer from './modules/policy/containers/RenewalList'
import UninvitedRenewalListContainer
  from './modules/policy/containers/UninvitedRenewalList'
import ReferralListContainer from './modules/policy/containers/ReferralList'
import PolicyTypesContainer from './modules/policy/containers/PolicyTypesContainer'
import PolicyListContainer from './modules/policy/containers/PolicyList'
import PolicyShowContainer from './modules/policy/containers/PolicyShow'
import AdminListContainer from './modules/admin/containers/AdminListContainer'
import ProductListContainer from './modules/product/containers/ProductListContainer'
import ProductQuoteContainer from './modules/product/containers/ProductQuoteContainer'
import ProductMTAContainer from './modules/product/containers/ProductMTAContainer'
import ContentListContainer from './modules/content/containers/ContentList'
import AnnouncementList from './modules/content/containers/AnnouncementList'
import ContentNewContainer from './modules/content/containers/ContentNew'
import ContentShowContainer from './modules/content/containers/ContentShow'
import ContentEditContainer from './modules/content/containers/ContentEdit'
import BrokerListContainer from './modules/broker/containers/BrokerList'
import BrokerShowContainer from './modules/broker/containers/BrokerShow'
import BrokerEditContainer from './modules/broker/containers/BrokerEdit'
import BrokerCommissionContainer from './modules/broker/containers/BrokerCommission'
import BrokerCreateContainer from './modules/broker/containers/BrokerCreate'
import CompleteYourApplication
  from './modules/brokerApplication/guestApplication/CompleteYourApplication'
import DocumentListContainer from './modules/document/containers/DocumentList'
import DocumentShowContainer from './modules/document/containers/DocumentShow'
import OrganisationListContainer from './modules/organisation/containers/OrganisationList'
import OrganisationShowContainer from './modules/organisation/containers/OrganisationShow'
import OrganisationEditContainer from './modules/organisation/containers/OrganisationEdit'
import OrganisationCreateContainer
  from './modules/organisation/containers/OrganisationCreate'
import RoleListContainer from './modules/role/containers/RoleListContainer'
import RoleShowContainer from './modules/role/containers/RoleShowContainer'
import RoleEditContainer from './modules/role/containers/RoleEditContainer'
import RoleCreateContainer from './modules/role/containers/RoleCreateContainer'
import SchemeProductsContainer from './modules/scheme/containers/SchemeProductsContainer'
import SchemeProductEditContainer
  from './modules/scheme/containers/SchemeProductEditContainer'
import SchemeEditContainer from './modules/scheme/containers/SchemeEditContainer'
import UserListContainer from './modules/user/containers/UserListContainer'
import UserShowContainer from './modules/user/containers/UserShowContainer'
import UserEditContainer from './modules/user/containers/UserEditContainer'
import UserEditRolesContainer from './modules/user/containers/UserEditRolesContainer'
import UserCreateContainer from './modules/user/containers/UserCreateContainer'
import CampaignsList from './modules/campaigns/containers/CampaignsList'
import CampaignContainer from './modules/campaigns/containers/CampaignContainer'
import CampaignsShowContainer from './modules/campaigns/containers/CampaignsShowContainer'
import CampaignEditContainer from './modules/campaigns/containers/CampaignEditContainer'
import TrackingLinksContainer
  from './modules/trackingLinks/containers/TrackingLinksContainer'
import TrackingLinksList from './modules/trackingLinks/containers/TrackingLinksList'
import TrackingLinksShowContainer
  from './modules/trackingLinks/containers/TrackingLinksShowContainer'

import HelpCentre from './modules/helpCentre';
import Faqs from './modules/helpCentre/Faqs';
import SystemInfo from './modules/helpCentre/SystemInfo';

/*
 * @param {Redux Store}
 * We require store as an argument here because we wish to get
 * state from the store after it has been authenticated.
 */
export default (store) => {
  const requireAuth = (nextState, replace, callback) => {
    const { auth: { authenticated } } = store.getState()
    if (!authenticated) {
      replace({
        pathname: '/',
        state: { nextPathname: nextState.location.pathname }
      })
    }
    callback()
  }

  const redirectAuth = (nextState, replace, callback) => {
    const { auth: { authenticated } } = store.getState()
    if (authenticated) {
      replace({
        pathname: '/dashboard'
      })
    }
    callback()
  }
  return (
    <Route path="/" component={AppContainer}>
      <IndexRoute component={HomepageContainer} onEnter={redirectAuth}/>
      <Route path="contact" component={ContactContainer} onEnter={redirectAuth}/>
      <Route path="cookies" component={CookiesContainer} onEnter={redirectAuth}/>
      <Route path="privacy" component={PrivacyContainer} onEnter={redirectAuth}/>
      <Route path="terms" component={TermsContainer} onEnter={redirectAuth}/>
      <Route path="requirements" component={RequirementsContainer} onEnter={redirectAuth}/>

      <Route path="complete-your-application/:token" component={CompleteYourApplication} onEnter={redirectAuth}/>

      <Route path="login" component={LoginContainer} onEnter={redirectAuth}/>
      <Route path="password/remind" component={PasswordReminderContainer} onEnter={redirectAuth}/>
      <Route path="password/reset/:token" component={ResetPasswordContainer} onEnter={redirectAuth}/>

      <Route path="/" onEnter={requireAuth}>
        <Route path="dashboard" component={DashboardContainer}/>

        <Route path="brokers" component={BrokerListContainer} onEnter={requirePermission('broker.view')}/>
        <Route path="brokers/new" component={BrokerCreateContainer} onEnter={requirePermission('broker.create')}/>
        <Route path="brokers/:id" component={BrokerShowContainer} onEnter={requirePermission('broker.view')}/>
        <Route path="brokers/:id/commissions/edit" component={BrokerCommissionContainer} onEnter={requirePermission('broker.view')}/>
        <Route path="brokers/:id/edit" component={BrokerEditContainer} onEnter={requirePermission('broker.edit')}/>

        <Route path="accounts" component={AccountListContainer} onEnter={requirePermission('account.view')}/>
        <Route path="accounts/summary" component={AccountSummaryContainer} onEnter={requirePermission('account.view')}/>
        <Route path="accounts/ledger" component={AccountLedgerContainer} onEnter={requirePermission('account.view')}/>
        <Route path="accounts/ledger/new" component={AccountCreateContainer}
               onEnter={requirePermission('account.create')}/>
        <Route path="accounts/ledger/:id" component={AccountShowContainer}
               onEnter={requirePermission('account.view')}/>
        <Route path="accounts/ledger/:id/edit" component={AccountEditContainer}
               onEnter={requirePermission('account.edit')}/>
        <Route path="accounts/matching" component={AccountMatchingContainer}
               onEnter={requirePermission('account.view')}/>
        <Route path="accounts/:id/matching" component={AccountMatchingContainer}
               onEnter={requirePermission('account.view')}/>
        <Route path="accounts/bookkeeping" component={AccountBookkeepingContainer}
               onEnter={requirePermission('account.view')}/>
        <Route path="accounts/payments-and-receipts/new" component={AccountPaymentReceiptsContainer}
               onEnter={requirePermission('account.edit')}/>
        <Route path="accounts/journal/new" component={AccountJournalContainer}
               onEnter={requirePermission('account.edit')}/>

        <Route path="customers" component={CustomerListContainer} onEnter={requirePermission('customer.view')}/>
        <Route path="customers/new" component={CustomerCreateContainer}
               onEnter={requirePermission('customer.create')}/>
        <Route path="customers/:id" component={CustomerShowContainer} onEnter={requirePermission('customer.view')}/>
        <Route path="customers/:id/edit" component={CustomerEditContainer}
               onEnter={requirePermission('customer.edit')}/>

        <Route path="policies" component={PolicyTypesContainer} onEnter={requirePermission('policy.view')}/>
        <Route path="policies/:type/:id" component={PolicyShowContainer} onEnter={requirePermission('policy.view')}/>
        <Route path="policies/applications" component={ApplicationListContainer}
               onEnter={requirePermission('policy.view')}/>
        <Route path="policies/issued" component={PolicyListContainer} onEnter={requirePermission('policy.view')}/>
        <Route path="policies/mid-term-adjustments" component={MtaListContainer} onEnter={requirePermission('policy.view')}/>
        <Route path="policies/renewals" component={RenewalListContainer} onEnter={requirePermission('policy.view')}/>
        <Route path="policies/uninvited-renewals" component={UninvitedRenewalListContainer} onEnter={requirePermission('policy.view')}/>
        <Route path="policies/referrals" component={ReferralListContainer}
               onEnter={requirePermission('policy.view')}/>

        <Route path="products" component={ProductListContainer} onEnter={requirePermission('policy.quote')}/>
        <Route path="products/:id/adjustment/:applicationId" component={ProductMTAContainer}
               onEnter={requirePermission('policy.quote')}/>
        <Route path="products/:id/quote/:applicationId" component={ProductQuoteContainer}
               onEnter={requirePermission('policy.quote')}/>
        <Route path="products/:id/quote" component={ProductQuoteContainer}
               onEnter={requirePermission('policy.quote')}/>

        <Route path="admin" component={AdminListContainer} onEnter={requirePermission('organisation.view')}/>
        <Route path="admin/challenges" component={ChallengeListContainer}
               onEnter={requirePermission('organisation.view')}/>
        <Route path="admin/challenges/:id" component={ChallengeShowContainer}
               onEnter={requirePermission('organisation.view')}/>

        <Route path="admin/content" component={ContentListContainer}
               onEnter={requirePermission('content_management.view')}/>
        <Route path="admin/content/new" component={ContentNewContainer}
               onEnter={requirePermission('content_management.create')}/>
        <Route path="admin/content/:id" component={ContentShowContainer}
               onEnter={requirePermission('content_management.view')}/>
        <Route path="admin/content/:id/edit" component={ContentEditContainer}
               onEnter={requirePermission('content_management.edit')}/>
        <Route path="admin/announcements" component={AnnouncementList}
                 onEnter={requirePermission('content_management.view')}/>

        <Route path="admin/campaign/new" component={CampaignContainer}
               onEnter={requirePermission('content_management.create')}/>

        <Route path="admin/campaigns" component={CampaignsList}
               onEnter={requirePermission('content_management.view')}/>

        <Route path="admin/campaigns/:id" component={CampaignsShowContainer}
               onEnter={requirePermission('organisation.view')}/>

        <Route path="admin/campaigns/:id/edit" component={CampaignEditContainer}
               onEnter={requirePermission('organisation.edit')}/>

        <Route path="admin/tracking-links/new" component={TrackingLinksContainer}
               onEnter={requirePermission('content_management.create')}/>
        <Route path="admin/tracking-links" component={TrackingLinksList}
               onEnter={requirePermission('content_management.view')}/>
        <Route path="admin/tracking-links/:id" component={TrackingLinksShowContainer}
               onEnter={requirePermission('organisation.view')}/>

        <Route path="admin/broker-applications" component={BrokersApListContainer}
               onEnter={requirePermission('broker.view')}/>
        <Route path="admin/broker-applications/new" component={BrokersApNewContainer}
               onEnter={requirePermission('content_management.create')}/>
        <Route path="admin/broker-applications/:id" component={BrokersApEditContainer}
               onEnter={requirePermission('content_management.create')}/>

        <Route path="admin/organisations" component={OrganisationListContainer}
               onEnter={requirePermission('organisation.view')}/>
        <Route path="admin/organisations/new" component={OrganisationCreateContainer}
               onEnter={requirePermission('organisation.create')}/>
        <Route path="admin/organisations/:id" component={OrganisationShowContainer}
               onEnter={requirePermission('organisation.view')}/>
        <Route path="admin/organisations/:id/edit" component={OrganisationEditContainer}
               onEnter={requirePermission('organisation.edit')}/>

        <Route path="admin/documents" component={DocumentListContainer}
               onEnter={requirePermission('organisation.view')}/>
        <Route path="admin/documents/:id" component={DocumentShowContainer}
               onEnter={requirePermission('organisation.view')}/>

        <Route path="admin/products" component={SchemeProductsContainer} onEnter={requirePermission('scheme.view')}/>
        <Route path="admin/products/:productId/schemes/:id" component={SchemeEditContainer}
               onEnter={requirePermission('scheme.view')}/>
        <Route path="admin/products/:productId/documents/:id" component={DocumentShowContainer}
               onEnter={requirePermission('scheme.view')}/>
        <Route path="admin/products/:id/edit" component={SchemeProductEditContainer}
               onEnter={requirePermission('scheme.view')}/>
        <Redirect from="admin/products/:id/schemes" to="admin/products/:id/edit"/>
        <Redirect from="admin/products/:id/documents" to="admin/products/:id/edit"/>
        <Redirect from="admin/products/:id" to="admin/products/:id/edit"/>

        <Route path="admin/users" component={UserListContainer} onEnter={requirePermission('user.view')}/>
        <Route path="admin/users/new/:organisationId" component={UserCreateContainer}
               onEnter={requirePermission('user.create')}/>
        <Route path="admin/users/new/:organisationId/:brokerId" component={UserCreateContainer}
               onEnter={requirePermission('user.create')}/>
        <Route path="admin/users/:id/organisations/:organisationId/edit" component={UserEditRolesContainer}
               onEnter={requirePermission('user.create')}/>
        <Route path="admin/users/:id/edit" component={UserEditContainer} onEnter={requirePermission('user.edit')}/>
        <Route path="admin/users/:id" component={UserShowContainer} onEnter={requirePermission('user.view')}/>

        <Route path="admin/roles" component={RoleListContainer} onEnter={requirePermission('role.view')}/>
        <Route path="admin/roles/new" component={RoleCreateContainer} onEnter={requirePermission('role.edit')}/>
        <Route path="admin/roles/:id" component={RoleShowContainer} onEnter={requirePermission('role.view')}/>
        <Route path="admin/roles/:id/edit" component={RoleEditContainer} onEnter={requirePermission('role.edit')}/>

        <Route path="help-centre" component={HelpCentre} />
        <Route path="help-centre/faqs" component={Faqs} />
        <Route path="help-centre/system-information" component={SystemInfo} />
        <Route path="reports" component={AccountReportsContainer}/>
      </Route>

      <Route path="500" component={Error500Container}/>
      // Catch any 404s and send them to the homepage
      <Route path="*" component={Error404Container}/>

    </Route>
  )

}
