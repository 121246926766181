import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Popover, OverlayTrigger } from 'react-bootstrap'
import Markdown from 'react-markdown'
import { Icon } from '../'
import './styles.scss'

export default class InfoButton extends Component {
  static propTypes = {
    content: PropTypes.string
  }

  render() {
    const { content } = this.props

    if (!content || !content.replace(/[^a-zA-Z0-9_ ]/g, '')) {
      return (<span/>)
    }

    const popoverContent = (
        <Popover className="popover-content" id={Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 15)}>
          <Markdown source={content}/>
        </Popover>
    )

    return (
        <OverlayTrigger trigger="click" overlay={popoverContent} rootClose>
          <div className="info-button-icon">
            <Icon name="info-circle"/>
          </div>
        </OverlayTrigger>
    )
  }
}
