import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { InfoLabel, DataTable, ButtonBar, Alert, Box, Button } from '../../../../common/components'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { hasPermission } from '../../../auth/redux/authActions'

export default class RoleDetails extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    role: PropTypes.object.isRequired,
    resource: PropTypes.object.isRequired
  };

  formatDate(cell, row) {
    return (
        <FormattedDate value={new Date(cell)}/>
    )
  }

  render() {
    const resource = this.props.resource.data.attributes

    return (
      <Box>
        <h2 className="resource-name">{resource.name}</h2>

        <div className="row">
          <div className="col-xs-12">
            <Alert />
          </div>

          <div className="col-sm-12">
            <div className="form-horizontal">
              <InfoLabel label="Name" value={ resource.name } labelSize={2}/>
            </div>
          </div>

          <div className="col-xs-12">
            <div className="form-horizontal">
              <InfoLabel label="Description" value={ resource.description } labelSize={2}/>
            </div>
          </div>

          <div className="col-xs-12">
            <div className="form-horizontal">
              <InfoLabel label="Organisation Type" value={ resource.organisation_type ? resource.organisation_type : '' } labelSize={2}/>
            </div>
          </div>
        </div>

        <div className="hr transparent"/>

        <div className="row">
          <div className="col-xs-12">
            <div className="form-horizontal">
              <h3>Role Permissions</h3>
              <DataTable
                  source="/permissions"
                  name="permissions"
                  autoFilterType="filter[role]"
                  autoFilter={this.props.resource.data.id}
                  showTotal={true}>

                <TableHeaderColumn dataField="id" isKey={true} width={'250px'}>Name</TableHeaderColumn>
                <TableHeaderColumn dataField="description" dataSort={true} width={'300px'}>Description</TableHeaderColumn>
              </DataTable>
            </div>
          </div>
        </div>

        <ButtonBar>
          {hasPermission('role.edit') && (
            <Button
                link={true}
                to={'/admin/roles/' + this.props.resource.data.id + '/edit'}
                className="pull-right"
                bsStyle="primary"
                rightIcon="pencil-square-o">Edit Role</Button>
          )}
        </ButtonBar>
      </Box>
    )
  }
}
