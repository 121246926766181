import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Tabs, Tab } from 'react-bootstrap'
import { getOrganisation } from '../redux/organisationActions'
import { isOwner } from '../../auth/redux/authActions'
import { Button, Loader } from '../../../common/components'
import InternalContainer from '../../layout/containers/Internal'
import OrganisationDetails from '../components/OrganisationDetails/OrganisationDetails'
import OrganisationBrokers from '../components/OrganisationBrokers/OrganisationBrokers'
import OrganisationInsurers from '../components/OrganisationInsurers'
import OrganisationUsers from '../components/OrganisationUsers/OrganisationUsers'
import OrganisationNotes from '../components/OrganisationNotes/OrganisationNotes'
import OrganisationAudit from '../components/OrganisationAudit'
import getIncludedResource from '../../../helpers/getIncludedResource'

class OrganisationShowContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    organisation: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired
  }

  constructor(props, context) {
    super(props, context)

    this.state = {
      activeTab: 1
    }
  }

  componentWillMount() {
    this.props.dispatch(getOrganisation(this.props.params.id))
  }

  handleTab(key) {
    this.setState({ activeTab: key })
  }

  render() {
    const resource = this.props.organisation.resources[this.props.params.id]
    const organisationType = resource ? getIncludedResource(resource.data, resource.included, 'organisation_type') : {}

    const buttons = (
      <Button
        bsStyle="default"
        label="Back"
        isBackButton={true}
        leftIcon="caret-left"
      />
    )

    return (
      <InternalContainer
        title={'Organisation' + (organisationType.data ? ' - ' + organisationType.data.attributes.name : '')}
        buttons={buttons}>
        {resource && organisationType.data ? (
          <Tabs activeKey={this.state.activeTab}
                id="organisation-tabs"
                unmountOnExit={true}
                onSelect={::this.handleTab}
                className='shadow-box'>
            <Tab eventKey={1} title="Details">
              <OrganisationDetails {...this.props} resource={resource}/>
            </Tab>
            {organisationType && organisationType.data.attributes.name === 'Broker' && (
              <Tab eventKey={2} title="Brokers/Branches">
                <OrganisationBrokers {...this.props} resource={resource}/>
              </Tab>
            )}
            {organisationType && organisationType.data.attributes.name === 'Insurer' && (
              <Tab eventKey={2} title="Brands">
                <OrganisationInsurers {...this.props} resource={resource}/>
              </Tab>
            )}
            {organisationType && organisationType.data.attributes.name !== 'Broker' && (
              <Tab eventKey={3} title="Users/Contacts">
                <OrganisationUsers {...this.props} resource={resource}/>
              </Tab>
            )}
            <Tab eventKey={4} title="Notes">
              <OrganisationNotes {...this.props} resource={resource}/>
            </Tab>
            {isOwner() && (
              <Tab eventKey={5} title="Audit">
                <OrganisationAudit {...this.props} resource={resource}/>
              </Tab>
            )}
          </Tabs>
        ) : (
          <Loader />
        )}
      </InternalContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    organisation: state.organisation
  }
}

export default connect(mapStateToProps)(OrganisationShowContainer)
