import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, SelectEndorsement, HorizontalFormControl } from '../../../../common/components'
import { Field } from 'redux-form'
import * as _ from 'lodash'
import TextAreaAdjustment from '../../../content/components/TextAreaAdjustment/TextAreaAdjustment'
import './styles.scss'

export default class Endorsements extends Component {
  static propTypes = {
    name: PropTypes.string,
    fields: PropTypes.object.isRequired,
    formValues: PropTypes.array.isRequired,
    showSelect: PropTypes.bool
  };

  constructor(props) {
    super(props)

    this.state = {
      value: '',
      data: {},
      dragIndex: null,
      openIndex: 0,
      initial: false
    }
  }

  componentDidMount() {
    const { fields } = this.props

    if (!fields.length ) {
      fields.push({ title: 'Endorsements', content: '', amount: '', metadata: {} });
      this.setState(() => ({ openIndex: 0 }))
    }

    this.setState(() => ({ openIndex: fields.length }));

  }

  componentDidUpdate() {
    const { fields, formValues } = this.props
    if (!this.state.initial && formValues.length && formValues[0].content == '') {
      this.setState({ initial: true })
      fields.remove(0)
    }
  }

  render() {
    const { fields, formValues, showSelect = true } = this.props

    return (
      <div className="endorsements clearfix">
        <h3>Endorsements</h3>
        <div className='template-select-adjustment'>
          <div className="row form-group form-horizontal add-endorsement">
            <div className="col-sm-10">
                {showSelect && (
                  <SelectEndorsement
                    object={true}
                    input={{
                      name: 'add_endorsement',
                      value: this.state.value,
                      onChange: (selected) => this.setState({ value: selected })
                    }}
                    label="Template:"
                    labelSize={2}
                  />
                )}
            </div>
            <div className="col-sm-2">
              {showSelect && (
                <Button
                  label="add"
                  bsStyle="primary"
                  className="endorsements__add-btn"
                  handleClick={() => {
                    if (this.state.value) {
                      fields.push({
                        title: this.state.value.attributes.sections.title,
                        content: this.state.value.attributes.sections.content,
                        type: 'user',
                        metadata: _.get(this.state, 'value.attributes.metadata'),
                        amount: '',
                      })
                      this.setState({ value: '', openIndex: fields.length })
                    } else {
                      return false
                    }
                  }
                  }
                />
              )}
            </div>
          </div>
        </div>
        <div className="sortable-list">
          <div className="list">
            {fields.map((endorsement, index) => {
              if (this.state.openIndex === index) {
                return (
                  <div key={index} className={'endorsement-row clearfix endorsement' + index}>
                        <span className="remove-endorsement float-right" onClick={() => fields.remove(index)}>
                          <Icon name="times"/>
                        </span>
                    <div className="endorsement-form form-horizontal">
                      <div className="row">
                        <div className='col-xs-10'>
                          <Field
                            name={endorsement + '.title'}
                            label="Title:"
                            labelSize={2}
                            component={HorizontalFormControl}
                          />
                        </div>
                      </div>
                      <Field
                        name={endorsement + '.content'}
                        label="Content"
                        labelSize={2}
                        component={TextAreaAdjustment}
                      />
                      <div className="form-group">
                        <Button className="float-right pad-right"
                                size="sm"
                                label="save"
                                bsStyle="primary"
                                handleClick={() => this.setState({ openIndex: null })}
                        />
                      </div>
                    </div>
                  </div>
                )
              }

              if (formValues[index]) {
                return (
                  <div key={index} className={'endorsement-row endorsement' + index}>
                    <span className="remove-endorsement float-right" onClick={() => fields.remove(index)}>
                      <Icon name="times"/>
                    </span>
                    <div className="endorsement-form closed">
                      <Button className="pull-left pad-right"
                              size="sm"
                              label="edit"
                              bsStyle="primary"
                              handleClick={() => this.setState({ openIndex: index })}
                      />
                      <h4>{formValues[index].title}</h4>
                    </div>
                    <div className="clearfix" />
                  </div>
                )
              }
            })}
          </div>
        </div>
      </div>
    )
  }
}
