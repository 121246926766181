import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {FormGroup, Col, ControlLabel} from 'react-bootstrap'
import SelectMenu from '../SelectMenu/SelectMenu'

export const SELECT_IDENTIFIER = 'broker'

class SelectMenuBroker extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    label: PropTypes.string,
    labelSize: PropTypes.number,
    input: PropTypes.object.isRequired,
    infoButton: PropTypes.element,
    name: PropTypes.string,
    id: PropTypes.string,
    meta: PropTypes.object,
    object: PropTypes.bool,
    multi: PropTypes.bool,
    filters: PropTypes.array
  };

  render() {
    const {input, infoButton, name, meta, label, labelSize, filters, object, multi, id} = this.props
    let source = 'brokers'

    if (Array.isArray(filters)) {
      filters.map(filter => {
        source += (source.includes('?') ? '&' : '?') + filter.filter + '=' + filter.value
      })
    }

    return (
      <FormGroup controlId={name} className={((meta && meta.touched && meta.error) ? ' has-error col-sm-6' : 'col-sm-6')}>
        {label && (
          <Col componentClass={ControlLabel} sm={2}>
            {label} {infoButton}
          </Col>
        )}
        <Col sm={10}>
          <SelectMenu
            source={source}
            name={SELECT_IDENTIFIER + id}
            field={input}
            object={object}
            multi={multi}
            async={true}
          />

          {(meta && meta.touched && meta.error) && <span className="input-error">{meta.error}</span>}
        </Col>
      </FormGroup>
    )
  }
}

export default connect()(SelectMenuBroker)
