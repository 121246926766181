export const INIT_TABLE = 'socrates-online/data-table/INIT';
export const CLEAR_DATA = 'socrates-online/data-table/CLEAR_DATA';
export const SET_SOURCE = 'socrates-online/data-table/SET_SOURCE';
export const SET_DATA = 'socrates-online/data-table/SET_DATA';
export const SET_DATA_FIRST_LOADING_COMPLETED = 'socrates-online/data-table/SET_DATA_FIRST_LOADING_COMPLETED';
export const SET_FILTERS = 'socrates-online/data-table/SET_FILTERS';
export const FETCH_DATA = 'socrates-online/data-table/FETCH_DATA';
export const FETCH_DATA_SUCCESS = 'socrates-online/data-table/FETCH_SUCCESS';
export const FETCH_DATA_ERROR = 'socrates-online/data-table/FETCH_FAILURE';
export const REMOVE_FILTER = 'socrates-online/data-table/REMOVE_FILTER';

const initialState = {
  tables: {}
}

const initialTableState = {
  data: [],
  links: {},
  total: 0,
  currentPage: 1,
  filters: {
    ['page[limit]']: 10,
    ['page[offset]']: 0
  },
  source: '',
  isFetching: false
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case INIT_TABLE:
      return {
        ...state,
        tables: {
          ...state.tables,
          [action.table]: {
            ...initialTableState,
            filters: {
              ...initialTableState.filters,
              ['page[limit]']: action.perPage
            },
            source: action.source
          }
        }
      }

    case FETCH_DATA:
      return {
        ...state,
        tables: {
          ...state.tables,
          [action.table]: {
            ...state.tables[action.table],
            isFetching: true
          }
        }
      }


    case FETCH_DATA_ERROR:
      return {
        ...state,
        tables: {
          ...state.tables,
          [action.table]: {
            ...state.tables[action.table],
            isFetching: false
          }
        }
      }

    case CLEAR_DATA:
      return {
        ...state,
        tables: {
          ...state.tables,
          [action.table]: {
            ...initialTableState,
            isFetching: state.tables[action.table].isFetching,
            source: state.tables[action.table].source,
            filters: state.tables[action.table].filters
          }
        }
      }

    case SET_SOURCE:
      return {
        ...state,
        tables: {
          ...state.tables,
          [action.table]: {
            ...state.tables[action.table],
            source: action.source
          }
        }
      }

    case SET_DATA:
      return {
        ...state,
        tables: {
          ...state.tables,
          [action.table]: {
            ...state.tables[action.table],
            source: action.source,
            data: action.data.data,
            links: action.data.links,
            currentPage: action.currentPage,
            total: action.data.meta ? action.data.meta.total : action.data.data.length,
            included: action.data.included,
            isFetching: false
          }
        }
      }

    case SET_DATA_FIRST_LOADING_COMPLETED:
      return {
        ...state,
        tables: {
          ...state.tables,
          [action.table]: {
            ...state.tables[action.table],
            dataFirstLoadingCompleted: action.dataFirstLoadingCompleted
          }
        }
      }

    case SET_FILTERS:
      let value = action.value !== 'any' ? action.value : 0
      const table = state.tables[action.table] ? state.tables[action.table] : {filters: {}}
      return {
        ...state,
        tables: {
          ...state.tables,
          [action.table]: {
            ...table,
            filters: {
              ...table.filters,
              [action.filterType]: value
            }
          }
        }
      }

    case REMOVE_FILTER:
      const filters = state.tables[action.table].filters;
      delete filters[action.filterType];

      return {
        ...state,
        tables: {
          ...state.tables,
          [action.table]: {
            ...state.tables[action.table],
            filters: filters,
          },
        },
      };

    default:
      return state
  }
}
