import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box, DataTable } from '../../../../common/components'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { FormattedDate, FormattedTime } from 'react-intl'
import { refreshData } from '../../../../common/components/DataTable/redux/dataTableActions'

export default class SchemeAudit extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    schemeStatus: PropTypes.string.isRequired,
    schemeLabel: PropTypes.object.isRequired,
  };

  formatDate(cell, row) {
    return (
        <span>
           <FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/>
        </span>
    )
  }

  handleRowClick(row) {
    //this.props.dispatch(push('/admin/users/' + row.id));
    return false
  }

  refreshList() {
    this.props.dispatch(refreshData('audit'))
  }

  render() {
    const { resource, schemeLabel } = this.props

    return (
        <div>
          <Box>
            <h2 className="resource-name">{resource.data.attributes.name}&nbsp;
              ({resource.data.attributes.cover_level}){schemeLabel}</h2>

            <DataTable
                source={'/events'}
                autoFilter={this.props.resource.data.id}
                autoFilterType="filter[resource]"
                name="audit"
                showTotal={true}
                onRowSelect={this.handleRowClick.bind(this)}>

              <TableHeaderColumn dataField="id" isKey={true} hidden={true}>ID</TableHeaderColumn>
              <TableHeaderColumn dataField="occurred_at" dataFormat={this.formatDate} width={'180px'} dataSort={true}>Occurred
                at</TableHeaderColumn>
              <TableHeaderColumn dataField="action" width={'180px'} dataSort={true}>Action</TableHeaderColumn>
              <TableHeaderColumn dataField="description" width={'550px'} dataSort={true}>Description</TableHeaderColumn>
              <TableHeaderColumn dataField="user_name">User</TableHeaderColumn>
            </DataTable>
          </Box>
        </div>
    )
  }
}
