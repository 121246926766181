import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box, DataTable, ButtonBar, Button } from '../../../../common/components'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { FormattedDate, FormattedTime } from 'react-intl'
import { FormControl, ControlLabel } from 'react-bootstrap'
import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions'
import { openModal } from '../../../../common/components/Modal/ModalRedux'
import { LedgerEntriesModal } from '../LedgerEntriesModal'

export default class AccountJournalList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props)

    this.state = {
      journal: {}
    }
  }

  formatDate(cell) {
    return (
        <span><FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/></span>
    )
  }

  formatBool(cell, row) {
    return (
        <span>{row.requires_approval ? 'Approval required' : 'Approved'}</span>
    )
  }

  handleSearchUpdate(event) {
    this.props.dispatch(applyFilter('journals', 'filter[description]', event.target.value))
  }

  handleJournalTypeUpdate(event) {
    this.props.dispatch(applyFilter('journals', 'filter[manual]', event.target.value))
  }

  handleRowClick(row) {
    this.setState({ journal: row })
    this.props.dispatch(openModal(LedgerEntriesModal.MODAL_IDENTIFIER))
  }

  render() {
    return (
      <Box>
        <h2>Journals</h2>
        <div className="row table-filters">
          <div className="col-sm-4">
            <ControlLabel className="filter-label">Description</ControlLabel>
            <FormControl onChange={ ::this.handleSearchUpdate } />
          </div>
          <div className="col-sm-2">
            <ControlLabel className="filter-label">Type</ControlLabel>
            <FormControl
              componentClass="select"
              type="select"
              onChange={ ::this.handleJournalTypeUpdate }
            >
              <option value="true">Manual</option>
              <option value="false">System</option>
              <option value="all">All</option>
            </FormControl>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <DataTable
                source="/accounts/journals"
                name="journals"
                autoFilters={[
                  { type: 'filter[manual]', value: true }
                ]}
                showTotal={true}
                onRowSelect={this.handleRowClick.bind(this)}
            >

              <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
                ID
              </TableHeaderColumn>

              <TableHeaderColumn dataField="description" width={'250px'} dataSort={true}>
                Description
              </TableHeaderColumn>

              <TableHeaderColumn dataField="requires_approval" width={'125px'} dataFormat={this.formatBool} dataSort={true}>
                Requires Approval
              </TableHeaderColumn>

              <TableHeaderColumn dataField="journal_size" width={'75px'}>
                No. Entries
              </TableHeaderColumn>

              <TableHeaderColumn dataField="user_name" width={'100px'}>
                User
              </TableHeaderColumn>

              <TableHeaderColumn dataField="date" dataFormat={this.formatDate} width={'100px'} dataSort={true}>
                Journal Date
              </TableHeaderColumn>

              <TableHeaderColumn dataField="created_at" dataFormat={this.formatDate} width={'100px'} dataSort={true}>
                Created At
              </TableHeaderColumn>

            </DataTable>
            <LedgerEntriesModal dispatch={this.props.dispatch} journal={this.state.journal}/>
            <ButtonBar>
              <Button
                  className="pull-right"
                  link={true}
                  to="/accounts/journal/new"
                  label="New Manual Journal"
                  bsStyle="primary"
              />
            </ButtonBar>
          </div>
        </div>
      </Box>
    )
  }
}
