import React, { Component } from 'react'
import PropTypes from 'prop-types'

const YesNoFormat = ({ data }) => {

  return (
      <span>{ data && (data == 'true' || data === true) ? ('Yes') : ('No') }</span>
  )
}

YesNoFormat.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ])
}

export default YesNoFormat
