import React, { Component }  from 'react'
import PropTypes from 'prop-types'
import { Box, DataTable, SelectOrganisation } from '../../../../common/components'
import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions'
import { isOwner } from '../../../auth/redux/authActions'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { FormControl, ControlLabel } from 'react-bootstrap'
import { push } from 'react-router-redux'
import { FormattedDate, FormattedTime } from 'react-intl'
import CreateUserButton from '../CreateUserButton/CreateUserButton';
import { InternalButtonBar } from '../../../layout/components/InternalButtonBar/InternalButtonBar';

export default class UserList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
  };

  constructor() {
    super()

    this.state = {
      values: {
        organisation: '',
        status: ''
      }
    }
  }

  componentWillMount() {
    this.setState({
      values: {
        ...this.state.values,
        status: this.props.location.query.status ? this.props.location.query.status : ''
      }
    })
  }

  formatDate(cell, row) {
    return (
      <span><FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/></span>
    )
  }

  formatName(cell, row) {
    return row.title + ' ' + row.first_name + ' ' + row.last_name
  }

  handleRowClick(row) {
    this.props.dispatch(push('/admin/users/' + row.id))
  }

  handleSearchUpdate(event) {
    this.props.dispatch(applyFilter('users', 'filter[search]', event.target.value))
  }

  handleOrganisationFilterUpdate(event) {
    this.setState({
      values: {
        ...this.state.values,
        organisation: event
      }
    })

    this.props.dispatch(applyFilter('users', 'filter[organisation]', event))
  }

  handleUserStatusFilterUpdate(event) {
    this.props.dispatch(applyFilter('users', 'filter[status]', event.target.value))
  }

  render() {
    return (
      <div>
        <Box>
          <div className="row">
            <div className="col-sm-5">
              <ControlLabel className="filter-label">Search</ControlLabel>
              <FormControl onChange={ ::this.handleSearchUpdate } />
            </div>

            {isOwner() && (
              <div className="col-sm-5">
                <ControlLabel className="filter-label">Organisation</ControlLabel>
                <SelectOrganisation
                  input={{
                    onChange: ::this.handleOrganisationFilterUpdate,
                    value: this.state.values.organisation
                  }}
                  multi={true}
                />
              </div>
            )}

            <div className="col-sm-2">
              <ControlLabel className="filter-label">Status</ControlLabel>
              <FormControl
                componentClass="select"
                type="select"
                onChange={::this.handleUserStatusFilterUpdate}
                placeholder="select"
                value={ this.state.values.status }
              >
                <option value="">Select...</option>
                <option value="pending">Pending</option>
                <option value="active">Active</option>
                <option value="suspended">Suspended</option>
                <option value="deactivated">Deactivated</option>
              </FormControl>
            </div>
          </div>
        </Box>

        <Box>
          <DataTable
            source="/users"
            name="users"
            showTotal={true}
            onRowSelect={this.handleRowClick.bind(this)}
            autoFilters={[
              { type: 'filter[status]', value: this.state.values.status }
            ]}>

            <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
              ID
            </TableHeaderColumn>

            <TableHeaderColumn dataField="first_name" dataFormat={::this.formatName}>
              Name
            </TableHeaderColumn>

            <TableHeaderColumn dataField="email" dataSort={true} width={'260px'}>
              Email
            </TableHeaderColumn>

            <TableHeaderColumn dataField="phone1" dataSort={true} width={'260px'}>
              Phone
            </TableHeaderColumn>

            <TableHeaderColumn dataField="status" dataSort={true} width={'100px'}>
              Status
            </TableHeaderColumn>

            <TableHeaderColumn dataField="created_at" dataFormat={this.formatDate} width={'180px'}>
              Created
            </TableHeaderColumn>

          </DataTable>

          <div className="row">
            <div className="col-sm-12">
              <InternalButtonBar
                buttons={[
                  {
                    permissions: ['user.create'],
                    button: <CreateUserButton dispatch={this.props.dispatch} />,
                  },
                ]}
              />
            </div>
          </div>
        </Box>
      </div>
    )
  }
}
