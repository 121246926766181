import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import InternalContainer from '../../layout/containers/Internal'
import { hasPermission } from '../../auth/redux/authActions'
import * as Icons from '../../../common/components/SvgIcons'

export class AdminListContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  render() {
    return (
        <InternalContainer title="Admin">
          <div className="row image-links">
            { hasPermission('content_management.view') && (
                <div className="col-xs-6 col-sm-3 image-item">
                  <Link to="/admin/content">
                    {Icons.content}
                    <label>Content Management</label>
                  </Link>
                </div>
            )}
            { hasPermission('scheme.view') && (
                <div className="col-xs-6 col-sm-3 image-item">
                  <Link to="/admin/products">
                    {Icons.productManagement}
                    <label>Product Management</label>
                  </Link>
                </div>
            )}
            { hasPermission('organisation.view') && (
                <div className="col-xs-6 col-sm-3 image-item">
                  <Link to="/admin/organisations">
                    {Icons.organisations}
                    <label>Organisations</label>
                  </Link>
                </div>
            )}
            { hasPermission('role.view') && (
                <div className="col-xs-6 col-sm-3 image-item">
                  <Link to="/admin/roles">
                    {Icons.roles}
                    <label>Roles</label>
                  </Link>
                </div>
            )}
            { hasPermission('user.view') && (
                <div className="col-xs-6 col-sm-3 image-item">
                  <Link to="/admin/users">
                    {Icons.users}
                    <label>Users</label>
                  </Link>
                </div>
            )}
            { hasPermission('user.view') && (
              <div className="col-xs-6 col-sm-3 image-item">
                <Link to="/admin/announcements">
                  {Icons.announcements}
                  <label>Announcements</label>
                </Link>
              </div>
            )}
          </div>
        </InternalContainer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  }
}

export default connect(mapStateToProps)(AdminListContainer)

