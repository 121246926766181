import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { Button, Modal, SelectBroker, SelectUser } from '../../../../common/components'
import { TextField } from '../../../../common/components/ReduxFormField'
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux'
import { transferPolicy } from '../../redux/policyActions'
import { ControlLabel } from 'react-bootstrap'
import _ from 'lodash'
import './styles.scss'

const FORM_NAME = 'transferPolicyForm'

const validate = values => {
  const errors = {}

  if (!_.get(values, 'data.relationships.broker.data.id')) {
    _.set(errors, 'data.relationships.broker.data.id', 'Broker is required')
  }

  if (!_.get(values, 'data.relationships.user.data.id')) {
    _.set(errors, 'data.relationships.user.data.id', 'User is required')
  }

  return errors
}

class TransferPolicyButton extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    resource: PropTypes.object.isRequired,
    resourceType: PropTypes.string.isRequired,
    broker: PropTypes.object,
    user: PropTypes.object,
    callback: PropTypes.func,
    label: PropTypes.string
  };

  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded, callback } = this.props
    if (newProps.submitSucceeded && !submitSucceeded) {
      if (callback) {
        callback('')
      }
      dispatch(closeModal(FORM_NAME))
    }
  }

  handleSubmit(values) {
    return this.props.dispatch(transferPolicy(values, this.props.resource, this.props.resourceType))
  }

  handleOpen() {
    const { dispatch, reset } = this.props
    dispatch(reset())
    dispatch(openModal(FORM_NAME))
  }

  render() {
    const { label, handleSubmit, submitting, formValues } = this.props
    const title = label || 'Transfer'
    const submitButton = (<Button
        type="submit"
        bsStyle="primary"
        isLoading={submitting}
        label="Transfer"
    />)


    return (
      <div className="email-btn-wrapper pull-right">
        <Button
            bsStyle="primary"
            label={title}
            rightIcon="exchange"
            handleClick={::this.handleOpen}
        />

        <Modal
            name={FORM_NAME}
            title={title + ' Policy'}
            close={true}
            handleSubmit={handleSubmit(::this.handleSubmit)}
            footer={submitButton}
        >
          <div className="form-horizontal">
            <div className="col-xs-12">
              <p>Please select a Broker to transfer this policy to.</p>

              <Field
                name={'data.relationships.broker.data.id'}
                label="Broker"
                labelSize={3}
                component={SelectBroker}
              />
              <Field
                name={'data.relationships.user.data.id'}
                label="User"
                filters={[
                  { filter: 'filter[broker]', value: _.get(formValues, 'data.relationships.broker.data.id') }
                ]}
                labelKeys={[
                  'attributes.first_name',
                  'attributes.last_name'
                ]}
                labelSize={3}
                component={SelectUser}
              />
            </div>
          </div>
          <div className="col-xs-12">
            <ControlLabel className="filter-label">Reason for transferring policy</ControlLabel>
            <Field
              name="meta.note.content"
              type="textarea"
              component={TextField}
            />
          </div>
        </Modal>
      </div>
    )
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(TransferPolicyButton)

const selector = formValueSelector(FORM_NAME)
const mapStateToProps = (state, props) => {
  const values = selector(state,
      'data.relationships.broker.data.id',
      'data.relationships.user.data.id',
  )

  return {
    initialValues: {
      data: {
        relationships: {
          broker: {
            data: {
              id: _.get(props.broker, 'data.id', '')
            }
          },
          user: {
            data: {
              id: _.get(props.user, 'data.id', '')
            }
          },
        }
      }
    },
    formValues: values
  }
}

export default connect(mapStateToProps)(form)
