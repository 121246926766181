import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import getIncludedResource from '../../../../helpers/getIncludedResource'
import Slider from 'react-rangeslider'
import * as _ from 'lodash'
import './styles.scss'

class CommissionAdjust extends Component {
  static propTypes = {
    input: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    currentProduct: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    calculate: PropTypes.func.isRequired,
    meta: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.state = {
      productId: null,
      rate: 0
    }
    this.handleChange = this.handleChange.bind(this)
  }

  componentWillUpdate(props) {
    const rate = this.getCommissionRate(props.currentProduct.data.id)
    if (rate !== this.state.rate) {
      if (!props.input.value) {
        props.input.onChange(rate)
      }

      this.setState({
        productId: props.currentProduct.data.id,
        rate
      })
    }
  }

  handleChange() {
    const { calculate } = this.props
    calculate()
  }

  getCommissionRate(productId) {
    const { user } = this.props
    const broker = getIncludedResource(user.data, user.included, 'current_broker')
    const commissions = getIncludedResource(broker.data, user.included, 'active_commissions')
    let rate = 0

    if (Array.isArray(commissions)) {
      commissions.map(commission => {
        if (_.get(commission, 'relationships.product.data.id') === productId) {
          rate = commission.attributes.rate
        }
      })
    }
    return rate
  }

  render() {
    const { input, product, user } = this.props
    const broker = getIncludedResource(user.data, user.included, 'current_broker')

    // Return empty if no current broker is set
    if (!broker.data || Array.isArray(broker.data) && !broker.data.length || this.state.rate === 0) {
      return (
        <div />
      )
    }

    const format = (value) => {
      return value + '%'
    }

    return (
      <div className="commission-adjust">
        <h4>Adjust your commission!</h4>
        <div className="row">
          <div className="col-xs-4 col-md-12 col-lg-3 text-center">
            <span className="icon-wrapper">
              {format(input.value)}
            </span>
          </div>
          <div className="col-xs-8 col-md-12 col-lg-7">
            <span className="information">
              Did you know you can adjust your commission to offer a cheaper price?
            </span>
          </div>
        </div>

        <div className="slider">
          <Slider
            {...input}
            value={parseInt(input.value)}
            disabled={product.isCalculating}
            max={this.state.rate}
            format={format}
            onChangeComplete={() => this.handleChange()}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user
  }
}
export default connect(mapStateToProps)(CommissionAdjust)
