import React from 'react';
import Markdown from 'react-commonmark'

const SignPostingText = ({ isCalculating, content }) => {
  if (isCalculating || !content) {
    return null
  }
  return (
    <div className={'signposting--textbox'}>
      <Markdown source={content || ''} linkTarget="_blank" />
    </div>
  );
}

export default SignPostingText;
