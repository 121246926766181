export const IS_CALCULATING = 'products/IS_CALCULATING'
export const IS_PREMIUM_ADJUSTING = 'products/IS_PREMIUM_ADJUSTING'
export const IS_SAVING = 'products/IS_SAVING'
export const IS_SAVING_FOR_EXIT = 'products/IS_SAVING_FOR_EXIT'
export const IS_ISSUING = 'products/IS_ISSUING'
export const SET_REGIONS = 'products/SET_REGIONS'
export const SET_SCHEMES = 'products/SET_SCHEMES'
export const SET_QUOTE = 'products/SET_QUOTE'
export const CLEAR_QUOTES = 'products/CLEAR_QUOTES'
export const SET_PRODUCT = 'products/SET_PRODUCT'
export const SET_PREMIUM_INDICATION = 'products/SET_PREMIUM_INDICATION'
export const IS_UPDATING_PREMIUM_INDICATION = 'products/SET_IS_UPDATING_PREMIUM_INDICATION'
export const SET_NEXT_QUOTE_REFERENCE = 'products/SET_NEXT_QUOTE_REFERENCE'
export const SET_PREMIUM_OVERRIDE_CALCULATION = 'products/SET_PREMIUM_OVERRIDE_CALCULATION'
export const SET_POLICY_FOR_ADJUSTMENT = 'products/SET_POLICY_FOR_ADJUSTMENT'
export const SUBMIT_SUCCEEDED = 'products/SUBMIT_SUCCEEDED'
export const CLEAR_DATA = 'products/CLEAR_DATA'
export const SET_TRANSACTION_ID = 'products/SET_TRANSACTION_ID'
export const SET_SIGNPOST = 'products/SET_SIGNPOST'

const initialState = {
  isCalculating: false,
  isPremiumAdjusting: false,
  isSaving: false,
  isSavingForExit: false,
  isIssuing: false,
  isUpdatingPremiumIndication: false,
  isCompletingTransaction: false,
  transactionId: null,
  premiumIndication: {},
  premiumIndicationUpdatedAt: 0,
  premiumOverrideCalculation: {},
  submitSucceeded: false,
  nextQuoteReference: '',
  policyForAdjustment: null,
  regions: [],
  schemes: [],
  quotes: {}, // Objects as these are keyed by UUID
  resources: {}, // Objects as these are keyed by UUID
  signpost: null,
}

export default function productReducer(state = initialState, action) {
  switch (action.type) {
    case IS_CALCULATING:
      return {
        ...state,
        isCalculating: action.status
      }
    case IS_PREMIUM_ADJUSTING:
      return {
        ...state,
        isPremiumAdjusting: action.status
      }
    case IS_SAVING:
      return {
        ...state,
        isSaving: action.status
      }
    case IS_SAVING_FOR_EXIT:
      return {
        ...state,
        isSavingForExit: action.status
      }
    case IS_ISSUING:
      return {
        ...state,
        isIssuing: action.status
      }
    case SUBMIT_SUCCEEDED:
      return {
        ...state,
        submitSucceeded: action.status
      }
    case SET_REGIONS:
      return {
        ...state,
        regions: action.data
      }
    case SET_SCHEMES:
      return {
        ...state,
        schemes: action.data
      }
    case SET_TRANSACTION_ID:
      return {
        ...state,
        isCompletingTransaction: action.transactionId !== null,
        transactionId: action.transactionId,
      }
    case SET_QUOTE:
      return {
        ...state,
        quotes: {
          ...state.quotes,
          [action.data.meta.scheme.id]: action.data
        }
      }
    case CLEAR_QUOTES:
      return {
        ...state,
        submitSucceeded: false,
        quotes: []
      }
    case SET_PRODUCT:
      return {
        ...state,
        submitSucceeded: false,
        productId: action.data.data.id,
        resources: {
          ...state.resources,
          [action.data.data.id]: {
            ...action.data
          }
        }
      }
    case IS_UPDATING_PREMIUM_INDICATION:
      return {
        ...state,
        isUpdatingPremiumIndication: action.status
      }
    case SET_PREMIUM_INDICATION:
      return {
        ...state,
        premiumIndication: action.premium,
        premiumIndicationUpdatedAt: Date.now()
      }
    case SET_POLICY_FOR_ADJUSTMENT:
      return {
        ...state,
        policyForAdjustment: action.data
      }
    case SET_NEXT_QUOTE_REFERENCE:
      return {
        ...state,
        submitSucceeded: false,
        nextQuoteReference: action.data
      }
    case SET_PREMIUM_OVERRIDE_CALCULATION:
      return {
        ...state,
        premiumOverrideCalculation: action.data
      }
    case SET_SIGNPOST:
      return {
        ...state,
        signpost: action.data
      }
    case CLEAR_DATA:
      return initialState
    default:
      return state
  }
}
