import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Tabs } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import { Loader } from '../../../common/components'
import InternalContainer from '../../layout/containers/Internal'
import LeisureTravelFormContainer from './LeisureTravelFormContainer'
import ProCycleFormContainer from './ProCycleFormContainer'
import getIncludedResource from '../../../helpers/getIncludedResource'

import {
  getProduct,
  getRegions,
  setPremiumIndication,
  getNextQuoteReference,
  clearNextQuoteReference,
  setPremiumOverrideCalculation
} from '../redux/productActions'
import { getPolicy } from '../../policy/redux/policyActions'
import confirm from 'react-confirm2'
import * as _ from 'lodash'
import { getCustomer } from '../../customer/redux/customerActions';
import { reset } from 'redux-form'

export class ProductQuoteContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    policy: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
  }

  constructor (props, context) {
    super(props, context)
    this.state = {
      nextLocation: null,
      activeTab: 1,
      currentOrganisation: ''
    }
  }

  componentWillMount() {
    const { dispatch, params, auth } = this.props
    const currentOrganisation = getIncludedResource(auth.user.data, auth.user.included, 'current_organisation')
    this.setState({
      currentOrganisation: currentOrganisation.data.attributes.name
    })

    dispatch(getRegions())
    dispatch(setPremiumIndication({}))
    dispatch(setPremiumOverrideCalculation({}))
    dispatch(getNextQuoteReference())
    dispatch(getProduct(params.id))

    if (params.applicationId) {
      dispatch(getPolicy(params.applicationId,
          this.props.location.query.clone ? '' : 'applications',
          ['customer', 'broker', 'user', 'schemes']))
    }

    if (_.get(location, 'query.customer')) {
      Promise.resolve(
        dispatch(getCustomer(location.query.customer, []))
      ).then(() => {
        return dispatch(reset('LeisureTravelForm'))
      })
    }
  }

  componentDidMount() {
    this.props.router.setRouteLeaveHook(this.props.route, ::this.routerWillLeave)
  }

  routerWillLeave (nextLocation) {
    const { auth, screening } = this.props
    const currentOrganisation = getIncludedResource(auth.user.data, auth.user.included, 'current_organisation')

    if (this.state.nextLocation) {
      return this.state.nextLocation
    } else if (this.props.product.submitSucceeded || nextLocation.pathname === '/login') {
      return true
    }

    // Do not display the confirm dialog if the user changed the Organisation by Settings -> Organisation Settings
    if (this.state.currentOrganisation === currentOrganisation.data.attributes.name) {
      if (screening.index > 0) {
        toastr.error(
          'Screening session active',
          'Please finish your current screening session before continuing')
        this.setState({
          nextLocation: null
        })
        return false
      }

      confirm('Are you sure you want to discard your quote?', {
        description: 'Please note: all details will be lost. If you would like to save this quote, simply click cancel and select the Save and Exit option found on the right-hand menu.',
        close: () => {
          this.setState({
            nextLocation: null
          })
        },
        done: () => {
          this.setState({ nextLocation }, () => {
            this.props.router.push(nextLocation)
          })
        }
      })
    } else {
      this.setState({ nextLocation }, () => {
        this.props.router.push(nextLocation)
      })
    }

    return false
  }

  componentWillUpdate (nextProps) {
    const { dispatch, params } = this.props
    if (nextProps.params.id !== params.id) {
      dispatch(clearNextQuoteReference())
      dispatch(setPremiumIndication({}))
    }
  }

  handleTab (key) {
    this.setState({ activeTab: key })
  }

  render () {
    const { product, policy, params, location } = this.props
    const resource = product.resources[params.id]
    const application = policy.resources[params.applicationId]
    const title = resource ? resource.data.attributes.name : ''
    const mta = _.get(application, 'data.attributes.quote_type', 'new business') === 'mid term adjustment'
    const renewal = _.get(application, 'data.attributes.quote_type', 'new business') === 'renewal'

    // Clear any overrides before editing
    // _.set(application, 'data.attributes.metadata.overrides', {})

    // Reset any policy details if cloning
    if (location.query.clone) {
      _.set(application, 'data.id', null)
      _.set(application, 'data.attributes.policy_number', '')
      _.set(application, 'data.attributes.quote_reference', '')
      _.set(application, 'data.relationships.schemes.data', [])
      _.set(application, 'data.attributes.metadata.cloned', true)
    }

    const isApplication = application && application.data.type === 'policies/applications'
    const policyId = isApplication ? _.get(application, 'data.relationships.policy.data.id') : params.applicationId

    return (
      <InternalContainer title={title} bottomBar='true'>
        {(resource && product.nextQuoteReference && (!params.applicationId || application)) ? (
          <Tabs activeKey={this.state.activeTab}
            id="broker-tabs"
            unmountOnExit={false}
            onSelect={::this.handleTab}>
            {(() => {
              switch (true) {
                case resource.data.attributes.product_code === 'PROC':
                  return (
                    <ProCycleFormContainer
                      {...this.props}
                      mta={mta}
                      application={application}
                      currentProduct={resource}
                    />
                  )
                default:
                  return (
                    <LeisureTravelFormContainer
                      {...this.props}
                      mta={mta}
                      renewal={renewal}
                      application={application}
                      currentProduct={resource}
                      policyId={policyId}
                    />
                  )
              }
            })()}
          </Tabs>
      ) : (
        <Loader />
      )}
      </InternalContainer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    product: state.product,
    policy: state.policy,
    auth: state.auth,
    screening: state.screening
  }
}

export default connect(mapStateToProps)(ProductQuoteContainer)
