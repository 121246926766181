import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormGroup, Col, ControlLabel } from 'react-bootstrap'
import SelectMenu from '../SelectMenu/SelectMenu'

export const SELECT_IDENTIFIER = 'campaigns'

class SelectCampaign extends Component {

  render() {
    const { input, infoButton, name, meta, label, labelSize, filters, object, ...props } = this.props
    let source = 'campaigns' // changed

    if (Array.isArray(filters)) {
      filters.map(filter => {
        source += (source.includes('?') ? '&' : '?') + filter.filter + '=' + filter.value
      })
    }

    return (
      <FormGroup controlId={name} className={((meta && meta.touched && meta.error) ? ' has-error' : '')}>
        {label && (
          <Col componentClass={ControlLabel} sm={labelSize}>
            { label } { infoButton }
          </Col>
        )}
        <Col sm={(12 - labelSize)}>
          <SelectMenu
            source={source}
            object={object}
            name={SELECT_IDENTIFIER}
            field={input}
            async={true}
            bracketsOn
            mapOptions={(option) => {
              return {
                label: `${option.attributes.name} (${option.attributes.code})`,
                value: option.id // the property should be unique
              }
            }}
            {...props}
          />
          {(meta && meta.touched && meta.error) && <span className="input-error">{meta.error}</span>}
        </Col>
      </FormGroup>
    )
  }
}

export default connect()(SelectCampaign)
