import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { createRole, updateRole, getPermissions } from '../redux/roleActions'
import RoleForm from '../components/RoleForm/RoleForm'
import mapFields from '../../../helpers/mapFields'
import roleSchema from '../../../schemas/role'

export default class RoleFormContainer extends Component {
  static propTypes = {
    resource: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    role: PropTypes.object.isRequired,
  };

  componentWillMount() {
    this.props.dispatch(getPermissions())
  }

  handleSubmit(resource) {
    if (resource.data.id) {
      return this.props.dispatch(updateRole(resource))
    }

    return this.props.dispatch(createRole(resource))
  }

  render() {
    const fields = mapFields(roleSchema, this.props.resource)

    return (
        <RoleForm
            {...this.props}
            fields={fields}
            permissions={this.props.role.permissions}
            onSubmit={::this.handleSubmit}
        />
    )
  }
}
