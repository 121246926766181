export const policyStatuses = {
  pending: 'pending',
  active: 'active',
  issued: 'issued',
  'on cover': 'On Cover',
  cancelled: 'cancelled',
  renewed: 'renewed',
  onCover: 'on cover',
  expired: 'expired',
  applied: 'applied',
  quote: 'quote',
  renewal: 'renewal',
  renewalInvited: 'renewal invited',
  lapsed: 'lapsed',
  declined: 'declined',
  referred: 'referred',
  referralApproved: 'referral approved',
}

export default function getStatus(status) {
  return policyStatuses[status]
}
