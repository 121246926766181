import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DataTable, HorizontalFormControl } from '../../../../common/components'
import { connect } from 'react-redux'
import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { Col, FormControl, FormGroup, ControlLabel } from 'react-bootstrap'
import { push } from 'react-router-redux'
import { FormattedDate, FormattedTime } from 'react-intl'
import './styles.scss'

class SchemeList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired
  };

  formatDate(cell, row) {
    if (!cell) {
      return '-'
    }

    return (
        <span>
          <FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/>
      </span>
    )
  }

  handleRowClick(row) {
    const { product } = this.props
    this.props.dispatch(push('/admin/products/' + product.data.id + '/schemes/' + row.id))
  }

  handleSearchUpdate(event) {
    this.props.dispatch(applyFilter('schemes', 'filter[name]', event.target.value))
  }

  handleFilterUpdate(event) {
    this.props.dispatch(applyFilter('schemes', 'filter[status]', event.target.value))
  }

  render() {
    const { product } = this.props

    return (
        <div>
          <h2 className="resource-name">Schemes</h2>

          <div className="row table-filters">
            <div className="col-sm-4">
              <HorizontalFormControl labelSize={3} label="Search" input={{ onChange: ::this.handleSearchUpdate }}/>
            </div>
            <div className="col-sm-4"/>
            <div className="col-sm-4">
              <FormGroup controlId="select-status">
                <Col componentClass={ControlLabel} md={2}>Status</Col>
                <Col md={10}>
                  <FormControl componentClass="select"
                               type="select"
                               onChange={::this.handleFilterUpdate}
                               placeholder="select"
                  >
                    <option value="all">All</option>
                    <option value="test">Test</option>
                    <option value="active">Active</option>
                    <option value="active,test" selected>Active & Test</option>
                    <option value="pending">Pending</option>
                    <option value="inactive">Inactive</option>
                    <option value="ended">Ended</option>
                  </FormControl>
                </Col>
              </FormGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12">
              <DataTable
                  source="/products/schemes"
                  name="schemes"
                  showTotal={true}
                  autoFilterType="filter[product]"
                  autoFilters={[
                    { type: 'filter[product]', value: product.data.id },
                    { type: 'filter[status]', value: 'active,test' },
                  ]}
                  onRowSelect={this.handleRowClick.bind(this)}>

                <TableHeaderColumn dataField="id" isKey={true} hidden={true}>ID</TableHeaderColumn>
                <TableHeaderColumn dataField="name"  width={'350'} dataSort={true}>Name</TableHeaderColumn>
                <TableHeaderColumn dataField="cover_level" dataSort={true}>Cover Level</TableHeaderColumn>
                <TableHeaderColumn dataField="version">Version</TableHeaderColumn>
                <TableHeaderColumn dataField="prefix" dataSort={true}>Prefix</TableHeaderColumn>
                <TableHeaderColumn dataField="from_date"  dataSort={true} dataFormat={::this.formatDate}>
                  Start date
                </TableHeaderColumn>
                <TableHeaderColumn dataField="to_date"  dataSort={true} dataFormat={::this.formatDate}>
                  End date
                </TableHeaderColumn>
                <TableHeaderColumn dataField="status" dataSort={true}>Status</TableHeaderColumn>
              </DataTable>
            </div>
          </div>
        </div>
    )
  }
}

export default connect()(SchemeList)
