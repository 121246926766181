import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { InfoLabel } from '../../../../common/components'
import Subjectivities from '../Subjectivities'
import DeclarationQuestions from '../DeclarationQuestions'
import displayName from '../../../../helpers/displayName'
import * as _ from 'lodash'
import './styles.scss'

export default class TravellersDetails extends Component {
  static propTypes = {
    resource: PropTypes.object.isRequired
  };

  render() {
    const { resource, campaignInfo } = this.props
    const campaign = {
      name: _.get(campaignInfo, 'data.attributes.name'),
      code: _.get(campaignInfo, 'data.attributes.code')
    }

    return (
      <div className="form-horizontal">
        <div className="row">
          <div className="hr touch-edge"/>
          <div className="col-xs-12">
            <h3>Travellers</h3>
            {_.get(resource, 'metadata.adults', []).map((traveller, i) => (
              <div key={i} className="row traveller-row">
                <div className="col-sm-6">
                  <InfoLabel
                    label="Name"
                    value={ traveller.title + ' ' + traveller.first_name + ' ' + traveller.last_name }
                    labelSize={4}
                  />
                </div>
                <div className="col-sm-6">
                  <InfoLabel
                    label="Dob"
                    value={ traveller.dob }
                    date={true}
                    labelSize={4}
                  />
                </div>
                {traveller.screening && (
                  <div>
                    {(!traveller.conditions_covered && traveller.screening.meta) && (
                      <div className="col-sm-10 col-sm-offset-2">
                        <p style={{ color: 'red' }}><strong>Excluded</strong></p>
                      </div>
                    )}
                    {traveller.screening.meta.conditions
                    && traveller.screening.meta.conditions.length > 0
                    && (

                      <div className="col-sm-12">
                        <InfoLabel
                          label="Conditions"
                          value={ traveller.screening.meta.conditions.join(', ') }
                          labelSize={2}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}

            {_.get(resource, 'metadata.children', []).map((traveller, i) => (
              <div key={i} className="row">
                <div className="col-sm-6">
                  <InfoLabel
                    label="Name"
                    value={ traveller.title + ' ' + traveller.first_name + ' ' + traveller.last_name }
                    labelSize={4}
                  />
                </div>
                <div className="col-sm-6">
                  <InfoLabel
                    label="Dob"
                    value={ traveller.dob }
                    date={true}
                    labelSize={4}
                  />
                </div>
                {traveller.screening && (
                  <div>
                    {(!traveller.conditions_covered && traveller.screening.meta) && (
                      <div className="col-sm-10 col-sm-offset-2">
                        <p style={{ color: 'red' }}><strong>Excluded</strong></p>
                      </div>
                    )}
                    {traveller.screening.meta.conditions
                    && traveller.screening.meta.conditions.length > 0
                    && (
                      <div className="col-sm-12">
                        <InfoLabel
                          label="Conditions"
                          value={ traveller.screening.meta.conditions.join(', ') }
                          labelSize={2}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        {(_.get(resource, 'metadata.subjectivities', []).length > 0) && (
          <div className="row">
            <div className="hr"/>
            <div className="col-xs-12">
              <Subjectivities
                subjectivities={_.get(resource, 'metadata.subjectivities' , [])}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}
