import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getBroker, getAllProducts, updateBrokerCommissions } from '../redux/brokerActions'
import BrokerCommissionForm from '../components/BrokerCommissionForm'
import { Button, Loader } from '../../../common/components'
import InternalContainer from '../../layout/containers/Internal'
import getIncludedResource from '../../../helpers/getIncludedResource'

class BrokerCommissionContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    broker: PropTypes.object.isRequired,
  };

  componentWillMount() {
    this.props.dispatch(getBroker(this.props.params.id))
    this.props.dispatch(getAllProducts())
  }

  handleSubmit(commissions) {
    return this.props.dispatch(updateBrokerCommissions(commissions, this.props.params.id))
  }

  render() {
    const resource = this.props.broker.resources[this.props.params.id]

    const buttons = (
        <Button
            bsStyle="default"
            label="Back"
            isBackButton={true}
            leftIcon="caret-left"
        />
    )

    return (
        <InternalContainer title="Broker Commissions" buttons={buttons}>
          {resource && resource.data && this.props.broker.allProducts.data ? (
                  <BrokerCommissionForm
                      resource={resource}
                      commissions={getIncludedResource(resource.data, resource.included, 'active_commissions')}
                      onSubmit={::this.handleSubmit}
                  />
              ) : (
                  <Loader />
              )}
        </InternalContainer>
    )
  }
}


function mapStateToProps(state) {
  return {
    broker: state.broker
  }
}

export default connect(mapStateToProps)(BrokerCommissionContainer)
