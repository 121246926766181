import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormGroup, Col, ControlLabel } from 'react-bootstrap'
import SelectMenu from '../SelectMenu/SelectMenu'

export const SELECT_IDENTIFIER = 'template'

class SelectMenuTemplate extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    label: PropTypes.string,
    labelSize: PropTypes.number,
    input: PropTypes.object.isRequired,
    infoButton: PropTypes.element,
    name: PropTypes.string,
    meta: PropTypes.object,
    object: PropTypes.bool,
    multi: PropTypes.bool,
    filters: PropTypes.array
  };

  render() {
    const { input, infoButton, name, meta, label, labelSize, object, multi, filters } = this.props
    let source = 'templates'
    if (Array.isArray(filters)) {
      filters.map(filter => {
        source += (source.includes('?') ? '&' : '?') + filter.filter + '=' + filter.value
      })
    }

    return (
        <FormGroup controlId={name} className={((meta && meta.touched && meta.error) ? ' has-error' : '')}>
          <Col componentClass={ControlLabel} sm={labelSize}>
            { label } { infoButton }
          </Col>
          <Col sm={(12 - labelSize)}>
            <SelectMenu
                source={source}
                name={SELECT_IDENTIFIER}
                field={input}
                object={object}
                multi={multi}
                limit={['article', 'announcement', 'endorsement']}
                limitBy="template_type"
            />

            {(meta && meta.touched && meta.error) && <span className="input-error">{meta.error}</span>}
          </Col>
        </FormGroup>
    )
  }
}

export default connect()(SelectMenuTemplate)
