import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { Panel, Popover, OverlayTrigger } from 'react-bootstrap'
import { Alert, Box, Button, ButtonBar, HorizontalFormControl, Icon } from '../../../../common/components'
import listContains from '../../../../helpers/listContains'
import displayName from '../../../../helpers/displayName'
import { hasPermission } from '../../../auth/redux/authActions'
import SelectMenuOrganisationType from '../../../../common/components/Selects/SelectOrganisationType'
import _ from 'lodash'
import './RoleForm.scss'

const FORM_NAME = 'roleForm'

class RoleForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    array: PropTypes.object.isRequired,
    permissions: PropTypes.array.isRequired,
    resource: PropTypes.object,
    formValues: PropTypes.object
  };

  togglePermission(permission) {
    const values = this.props.formValues.data.relationships.permissions.data
    const index = listContains(permission, values)
    index !== false ? this.props.array.splice('data.relationships.permissions.data', index, 1) : this.props.array.push('data.relationships.permissions.data', permission)
  }

  render() {
    const { formValues, handleSubmit, submitting, permissions } = this.props
    const permissionCategories = {}
    permissions.map(permission => {
      if (!permissionCategories[permission.attributes.category]) permissionCategories[permission.attributes.category] = []
      permissionCategories[permission.attributes.category].push(permission)
    })

    return (
      <Box>
        <h2 className="resource-name">{_.get(formValues, 'data.attributes.name.value')}</h2>
        <form className="role-form" onSubmit={handleSubmit}>

          <div className="row">
            <div className="col-xs-12">
              <Alert />
            </div>

            <div className="col-xs-12">
              <div className="form-horizontal">
                <Field
                    name="data.attributes.name"
                    label="Name"
                    labelSize={2}
                    component={HorizontalFormControl}
                />
              </div>
            </div>

            <div className="col-xs-12">
              <div className="form-horizontal">
                <Field
                    name="data.attributes.description"
                    label="Description"
                    labelSize={2}
                    component={HorizontalFormControl}
                />
              </div>
            </div>
          </div>

          <div className="form-horizontal">
            <Field
              name="data.relationships.organisation_type.data.id"
              label="Organisation Type"
              labelSize={2}
              component={SelectMenuOrganisationType}
            />
          </div>

          <div className="hr transparent"/>

          <div className="row">
            <div className="col-xs-12">
              <div className="form-horizontal">
                <h3>Role Permissions</h3>

                <div className="row">
                  {Object.keys(permissionCategories).map(category => {
                    return (
                      <div key={category} className="col-sm-4 col-md-3 permission-category">
                        <Panel>
                          <Panel.Heading className="permissions-container">{displayName(category)}</Panel.Heading>
                          <Panel.Body>
                            <ul className="permission-list">
                              {permissionCategories[category].map(permission => {
                                const value = { type: permission.type, id: permission.id }
                                return (
                                  <li key={permission.id}>
                                    <div className="checkbox">
                                      <label>
                                        <input type="checkbox" value={permission.id}
                                               onChange={() => this.togglePermission(value)}
                                               checked={false !== listContains(value, _.get(formValues, 'data.relationships.permissions.data', []))}/>
                                        {displayName(permission.attributes.action)}
                                      </label>

                                      <OverlayTrigger trigger="click"
                                                      placement="bottom"
                                                      rootClose
                                                      overlay={<Popover id={permission.id}
                                                                        title={permission.attributes.category + ' ' + permission.attributes.action}>
                                                        {permission.attributes.description}</Popover>}>
                                        <div className="help-icon"><Icon name="info"/></div>
                                      </OverlayTrigger>
                                    </div>
                                  </li>
                                )
                              })}
                            </ul>
                          </Panel.Body>
                        </Panel>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>

          <ButtonBar>
            { hasPermission('role.edit') && (
                <Button
                    className="pull-right"
                    type="submit"
                    bsStyle="primary"
                    label="Save Role"
                    isLoading={submitting}/>
            )}
          </ButtonBar>
        </form>
      </Box>
    )
  }
}

const form = reduxForm({ form: FORM_NAME })(RoleForm)
const selector = formValueSelector(FORM_NAME)
const mapStateToProps = (state, props) => {
  const values = selector(state,
      'data.id',
      'data.attributes.name',
      'data.relationships.permissions.data',
  )

  return {
    initialValues: props.resource,
    formValues: values
  }
}

export default connect(mapStateToProps)(form)
