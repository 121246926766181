import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { sortable } from 'react-sortable'

class SortableItem extends Component {
  render() {
    return (
        <div {...this.props} className="list-item sortable-list-item">
          {this.props.children}
        </div>
    )
  }
}

export default sortable(SortableItem)
