import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, FieldArray, formValueSelector } from 'redux-form'
import { SelectField } from '../../../../common/components/ReduxFormField'
import { Box, Button, DatePicker, ButtonBar, HorizontalFormControl } from '../../../../common/components'
import { postJournal } from '../../redux/accountActions'
import { Journal } from '../Journal'
import _ from 'lodash'
import './styles.scss'

const FORM_NAME = 'JournalForm'

const validate = (values) => {
  let errors = {}

  if (!_.get(values, 'data.attributes.date')) {
    _.set(errors, 'data.attributes.date', 'Date is required')
  }

  if (!_.get(values, 'data.attributes.description')) {
    _.set(errors, 'data.attributes.description', 'Description is required')
  }

  if (!_.get(values, 'data.attributes.entry_type')) {
    _.set(errors, 'data.attributes.entry_type', 'Entry type is required')
  }

  _.get(values, 'data.attributes.metadata.ledger_entries', []).map((entry, i) => {
    if (!entry.account) {
      _.set(errors, 'data.attributes.metadata.ledger_entries[' + i + '].account', 'Account is required')
    }

    if (!entry.description) {
      _.set(errors, 'data.attributes.metadata.ledger_entries[' + i + '].description', 'Description is required')
    }

    if (parseInt(entry.debit) == 0 && parseInt(entry.credit) == 0) {
      _.set(errors, 'data.attributes.metadata.ledger_entries[' + i + '].debit', 'Either debit or credit must be set')
      _.set(errors, 'data.attributes.metadata.ledger_entries[' + i + '].credit', 'Either debit or credit must be set')
    }

    if (parseFloat(entry.debit) > 0 && parseFloat(entry.credit) > 0) {
      _.set(errors, 'data.attributes.metadata.ledger_entries[' + i + '].debit', 'Both debit and credit cant be set')
      _.set(errors, 'data.attributes.metadata.ledger_entries[' + i + '].credit', 'Both debit and credit cant be set')
    }
  })

  return errors
}

class JournalForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props)
  }


  handleSubmit(journal) {
    return this.props.dispatch(postJournal(journal))
  }

  render() {
    const { formValues, handleSubmit, submitting } = this.props

    return (
        <Box>
          <h2 className="resource-name">Add a manual journal</h2>
          <div className="journal-form">
            <form onSubmit={handleSubmit(::this.handleSubmit)}>
              <div className="journal-form-header">
                <div className="row form-horizontal">
                  <div className="col-sm-4">
                    <Field
                        name="data.attributes.date"
                        label="Date"
                        labelSize={3}
                        component={DatePicker}
                    />
                  </div>
                  <div className="col-sm-4">
                    <Field
                        name="data.attributes.description"
                        label="Description"
                        labelSize={3}
                        component={HorizontalFormControl}
                    />
                  </div>
                  <div className="col-sm-4">
                    <Field
                        name="data.attributes.entry_type"
                        label="Type"
                        labelSize={3}
                        options={[
                          { label: 'Premium', value: 'premium' },
                          { label: 'Transaction', value: 'transaction' }
                        ]}
                        component={SelectField}
                    />
                  </div>
                </div>
              </div>

              <div className="row form-section">
                <div className="col-xs-12">
                  <FieldArray
                      name="data.attributes.metadata.ledger_entries"
                      component={Journal}
                      entryType={_.get(formValues, 'data.attributes.entry_type', 'transaction')}
                  />
                </div>
              </div>

              <div className="hr transparent"/>

              <ButtonBar>
                <Button
                    label="Cancel"
                    bsStyle="primary"
                    className="pull-left"
                    link={true}
                    to="/accounts/bookkeeping#journals"
                />

                <Button
                    label="Post Journal"
                    bsStyle="primary"
                    className="pull-right"
                    type="submit"
                    isLoading={submitting}
                />
              </ButtonBar>
            </form>
          </div>
        </Box>
    )
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(JournalForm)
const selector = formValueSelector(FORM_NAME)
const mapStateToProps = (state, props) => {
  const values = selector(state,
      'data.id',
      'data.attributes.entry_type'
  )

  return {
    initialValues: props.resource,
    formValues: values
  }
}

export default connect(mapStateToProps)(form)
