import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { Button, Modal } from '../../../../common/components'
import { TextField } from '../../../../common/components/ReduxFormField'
import { ControlLabel } from 'react-bootstrap'
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux'
import { declineBroker } from '../../redux/brokerActions'
import _ from 'lodash'
import './styles.scss'

const FORM_NAME = 'declineForm'

const validate = values => {
  const errors = {}

  if (!_.get(values, 'meta.note.content', false)) {
    _.set(errors, 'meta.note.content', 'Reason for declining is required')
  }

  return errors
}

class DeclineButton extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    broker: PropTypes.object.isRequired,
    resource: PropTypes.object.isRequired,
    callback: PropTypes.func,
    label: PropTypes.string
  };

  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded, callback } = this.props
    if (newProps.submitSucceeded && !submitSucceeded) {
      if (callback) {
        callback('')
      }
      dispatch(closeModal(FORM_NAME))
    }
  }

  handleSubmit(values) {
    return this.props.dispatch(declineBroker(values, this.props.resource.data.id))
  }

  handleOpen() {
    const { dispatch, reset } = this.props
    dispatch(reset())
    dispatch(openModal(FORM_NAME))
  }

  render() {
    const { handleSubmit, submitting, broker, resource } = this.props

    const submitButton = (<Button
      type="submit"
      bsStyle="primary"
      isLoading={submitting}
      handleClick={handleSubmit(::this.handleSubmit)}
      label="Decline"
    />)


    return (
      <div className="email-btn-wrapper pull-right">
        <Button
          type="button"
          bsStyle="primary"
          label="Decline"
          rightIcon="times"
          isLoading={broker.isDeclining}
          handleClick={::this.handleOpen}
        />

        <Modal
          name={FORM_NAME}
          title={ 'Decline Broker Application for ' + resource.data.attributes.name }
          close={true}
          footer={submitButton}
        >
          <form onSubmit={handleSubmit(::this.handleSubmit)}>
            <div className="col-xs-12">
              <ControlLabel className="filter-label">Reason for declining</ControlLabel>
              <Field
                name="meta.note.content"
                type="textarea"
                component={TextField}
              />
            </div>
            <br/>
          </form>
        </Modal>
      </div>
    )
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(DeclineButton)
const mapStateToProps = (state) => {

  return {
    broker: state.broker,
    initialValues: {
      data: {
        metadata: {
          send_email: false
        }
      },
      meta: {
        note: {
          content: ''
        }
      }
    }
  }
}

export default connect(mapStateToProps)(form)
