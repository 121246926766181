import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { Button, Modal, SelectOrganisation } from '../../../../common/components'
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux'
import { assignHandlerToScheme } from '../../redux/schemeActions'
import _ from 'lodash'

const FORM_NAME = 'assignClaimsHandlerToScheme'


const validate = values => {
  const errors = {}

  if (!_.get(values, 'data.relationships.claims_handler.data.id', false)) {
    _.set(errors, 'data.relationships.claims_handler.data.id', 'A claims handler is required')
  }

  return errors
}

class SchemeAssignHandler extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
  };

  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded, callback } = this.props
    if (newProps.submitSucceeded && !submitSucceeded) {
      if (callback) {
        callback('')
      }
      dispatch(closeModal(FORM_NAME))
    }
  }

  handleSubmit(values) {
    const { resource } = this.props
    return this.props.dispatch(assignHandlerToScheme(resource, 'Claims Handler', values))
  }

  handleOpen() {
    const { dispatch, reset } = this.props
    dispatch(reset())
    dispatch(openModal(FORM_NAME))
  }

  render() {
    const { handleSubmit } = this.props
    const handlerType = 'Claims Handler'
    const submitButton = (<Button
      type="submit"
      bsStyle="primary"
      handleClick={handleSubmit(::this.handleSubmit)}
      label="Assign"
    />)

    return (
      <div>
        <Button
          type="button"
          label={'Assign ' + handlerType}
          handleClick={::this.handleOpen}
        />

        <Modal
          name={FORM_NAME}
          title="Assign Claims Handler"
          close={true}
          footer={submitButton}
        >
          <form onSubmit={handleSubmit(::this.handleSubmit)}>
            <div className="col-xs-12 form-horizontal">
              <div className="col-xs-12">
                <Field
                  name={'data.relationships.claims_handler.data.id'}
                  label={handlerType}
                  labelSize={4}
                  filters={[{ filter: 'filter[type]', value: handlerType }]}
                  component={SelectOrganisation}
                />
              </div>
            </div>
          </form>
        </Modal>
      </div>
    )
  }
}


const form = reduxForm({ form: FORM_NAME, validate })(SchemeAssignHandler)
const mapStateToProps = (state, props) => {

  return {
  }
}

export default connect(mapStateToProps)(form)
