import * as actions from './layoutReducer'
import Axios from 'axios'

export function getOrganisationTypes() {
  return (dispatch) => {
    let endpoint = 'organisations/types'

    return Axios.get(endpoint).then(response => {
      dispatch({
        type: actions.SET_ORGANISATION_TYPES,
        data: response.data.data
      })
      return true
    }).catch(error => {
      console.error(error)
    })
  }
}
