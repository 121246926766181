import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button } from '../../../common/components'
import { isBroker } from '../../auth/redux/authActions'
import InternalContainer from '../../layout/containers/Internal'
import UserList from '../components/UserList/UserList'
import _ from 'lodash'

class UserListContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
  };

  render() {
    let buttons = ('')

    if (isBroker()) {
      const brokerId = _.get(this.props.auth.user, 'data.relationships.current_broker.data.id', null)
      const organisationId = _.get(this.props.auth.user, 'data.relationships.current_organisation.data.id', null)
      if (brokerId && organisationId) {
        buttons = (
            <Button
                link={true}
                to={'/admin/users/new/' + organisationId + '/' + brokerId}
                className="pull-right"
                bsStyle="primary"
                rightIcon="user"
                label="add a new user"
            />
        )
      }
    }

    return (
        <InternalContainer title="Admin / Users" buttons={buttons}>
          <UserList {...this.props} />
        </InternalContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    auth: state.auth
  }
}

export default connect(mapStateToProps)(UserListContainer)
