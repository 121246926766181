export const IS_SUBMITTING = 'socrates-online/brokerApplication/IS_SUBMITTING';
export const SET_BROKER_APPLICATION = 'socrates-online/organisation/SET_BROKER_APPLICATION';
export const SET_PRODUCTS = 'socrates-online/organisation/SET_PRODUCTS';
export const PUBLIC_SUBMIT_SUCCESS = 'socrates-online/organisation/PUBLIC_SUBMIT_SUCCESS';

export const initialState = {
  isSubmitting: false,
  resources: {},
  brokerApplication: {},
  products: [],
  publicSubmitSuccess: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_BROKER_APPLICATION:
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.data.data.id]: {
            ...action.data
          }
        }
      };
    case SET_PRODUCTS:
      return {
        ...state,
        products: action.data.data,
      }
    case IS_SUBMITTING:
      return {
        ...state,
        isSubmitting: action.status
      };
    case PUBLIC_SUBMIT_SUCCESS:
      return {
        ...state,
        publicSubmitSuccess: true,
      };
    default:
      return state
  }
}
