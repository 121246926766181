import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Tabs, Tab } from 'react-bootstrap'
import { hasPermission } from '../../auth/redux/authActions'
import InternalContainer from '../../layout/containers/Internal'
import BordereauxReports from '../components/BordereauxReports'
import PerformanceReports from '../components/PerformanceReports'
import RenewalReports from '../components/RenewalReports'
import LedgerReports from '../components/LedgerReports'

class AccountReportsContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };

  constructor(props, context) {
    super(props, context)

    this.state = {
      activeTab: 1
    }
  }

  handleTab(key) {
    this.setState({ activeTab: key })
  }

  render() {
    return (
      <InternalContainer title="Reporting">
        <Tabs activeKey={this.state.activeTab}
              id="reporting-tabs"
              unmountOnExit={true}
              onSelect={::this.handleTab}
              className='shadow-box'>

          {hasPermission('report.bdx') && (
            <Tab eventKey={1} title="Bordereaux/Statements">
              <BordereauxReports {...this.props} />
            </Tab>
          )}

          {hasPermission('report.predefined') && hasPermission('policy.renew') && (
            <Tab eventKey={2} title="Renewals">
              <RenewalReports {...this.props} />
            </Tab>
          )}

          {hasPermission('report.predefined') && (
            <Tab eventKey={3} title="Performance">
              <PerformanceReports {...this.props} />
            </Tab>
          )}

          {hasPermission('report.predefined') && hasPermission('account.view') &&(
            <Tab eventKey={4} title="Ledger">
              <LedgerReports {...this.props} />
            </Tab>
          )}
        </Tabs>
      </InternalContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    account: state.account
  }
}

export default connect(mapStateToProps)(AccountReportsContainer)
