import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormGroup, Col, ControlLabel } from 'react-bootstrap'
import SelectMenu from '../SelectMenu/SelectMenu'
import { get } from 'lodash'

export const SELECT_IDENTIFIER = 'products'

class SelectProduct extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    label: PropTypes.string,
    labelSize: PropTypes.number,
    input: PropTypes.object.isRequired,
    infoButton: PropTypes.element,
    name: PropTypes.string,
    meta: PropTypes.object,
    labelKeys: PropTypes.array,
    object: PropTypes.bool,
    multi: PropTypes.bool,
    filters: PropTypes.array
  };

  render() {
    const { input, infoButton, meta, label, labelSize, filters, object, multi, labelKeys, ...props } = this.props
    let source = 'products'
    let filterType = ''
    let filter = ''

    if (Array.isArray(filters)) {
      filters.map(filter => {
        source += (source.includes('?') ? '&' : '?') + filter.filter + '=' + filter.value
      })
    }

    return (
        <FormGroup controlId={name} className={((meta && meta.touched && meta.error) ? ' has-error' : '')}>
          <Col componentClass={ControlLabel} sm={labelSize}>
            { label } { infoButton }
          </Col>
          <Col sm={(12 - labelSize)}>
            <SelectMenu
                source={source}
                object={object}
                multi={multi}
                name={SELECT_IDENTIFIER}
                field={input}
                autoFilterType={filterType}
                autoFilter={filter}
                labelKeys={labelKeys}
                {...props}
            />

            {(meta && meta.touched && meta.error) && <span className="input-error">{meta.error}</span>}
          </Col>
        </FormGroup>
    )
  }
}

export default connect()(SelectProduct)
