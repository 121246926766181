import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { Button, SelectDocument } from '../../../../common/components'

const FORM_NAME = 'productDocumentPacksForm'

class ProductDocumentPacksForm extends Component {
  static propTypes = {
    product: PropTypes.object.isRequired,
    documentPacks: PropTypes.object.isRequired
  }

  render() {
    const { product, documentPacks, submitting, handleSubmit } = this.props
    const resource = product

    return (
      <div>
        <form onSubmit={handleSubmit}>
          <h2 className="resource-name">{resource.data.attributes.name}</h2>
          {documentPacks.data.map((pack, index) => {
            return (
              <div className="form-section">
                <h2 className="capitalise">{pack.attributes.for} pack</h2>
                <Field
                  name={'data[' + index + '].relationships.scheme_documents.data'}
                  label={'Documents to be included in the ' + pack.attributes.for + ' document pack'}
                  multi={true}
                  object={true}
                  component={SelectDocument}
                />

                <div className="hr transparent"/>
              </div>
            )
          })}

          <div className="row actions">
            <div className="col-xs-12">
              <Button
                type="submit"
                label="Save Changes"
                bsStyle="primary"
                className="pull-right"
                isLoading={submitting}
              />
            </div>
          </div>
        </form>
      </div>
    )
  }
}

const form = reduxForm({ form: FORM_NAME })(ProductDocumentPacksForm)
const mapStateToProps = (state, props) => {
  return {
    initialValues: props.documentPacks
  }
}

export default connect(mapStateToProps)(form)
