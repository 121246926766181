import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import PlainTextField from './PlainTextField'
import './styles.scss'

const AddressFields = ({ baseName, label, labelSize, mdFieldSize, organisation }) => {
  let labelClass = 'control-label', wrapperClass = 'address-fields'
  if (labelSize) {
    labelClass += ' col-xs-' + labelSize
    wrapperClass += ' col-xs-' + (12 - labelSize)

    if (mdFieldSize) {
      wrapperClass += ' col-md-' + mdFieldSize
    }
  }

  return (
      <div className="row address-group">
        {label && (
            <label className={labelClass}>{label}</label>
        )}

        <div className={wrapperClass}>
          {organisation && (
              <Field
                  name={baseName + '.organisation'}
                  placeholder="Organisation"
                  component={PlainTextField}
              />
          )}
          <Field
              name={baseName + '.line1'}
              placeholder="Line 1"
              component={PlainTextField}
          /><div className="margin-div" />
          <Field
              name={baseName + '.line2'}
              placeholder="Line 2"
              component={PlainTextField}
          /><div className="margin-div" />
          <Field
              name={baseName + '.line3'}
              placeholder="Line 3"
              component={PlainTextField}
          /><div className="margin-div" />
          <Field
              name={baseName + '.town'}
              placeholder="Town"
              component={PlainTextField}
          /><div className="margin-div" />
          <Field
              name={baseName + '.county'}
              placeholder="County"
              component={PlainTextField}
          />
        </div>
      </div>
  )
}

export default AddressFields
