import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  InfoLabel,
  Box,
  Button,
  ButtonBar,
  YesNoFormat,
  CurrencyFormat,
  PercentageFormat
} from '../../../../common/components'
import { Label } from 'react-bootstrap'
import getIncludedResource from '../../../../helpers/getIncludedResource'
import displayAddress from '../../../../helpers/displayAddress'
import { hasPermission } from '../../../auth/redux/authActions'
import { reactivateOrganisation, deactivateOrganisation } from '../../redux/organisationActions'
import displayName from '../../../../helpers/displayName'
import { organisationStatuses } from '../../../../schemas/organisation'
import { EditNetworkButton } from '../EditNetworkButton'
import _ from 'lodash'

export default class OrganisationDetails extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    organisation: PropTypes.object.isRequired,
  }

  reactivateOrganisation() {
    this.props.dispatch(reactivateOrganisation(this.props.resource.data.id))
  }

  deactivateOrganisation() {
    this.props.dispatch(deactivateOrganisation(this.props.resource.data.id))
  }

  convertToUrl(link) {
    if ( /^(?:https?|link):\/\//.test(link) ) {
      return link
    }

    if ( /^(?:http?|link):\/\//.test(link) ) {
      return link
    }

    return 'http://' + link
  }

  render() {
    const { organisation } = this.props
    const resource = this.props.resource.data.attributes
    const insurers = getIncludedResource(this.props.resource.data, this.props.resource.included, 'insurers')
    const network = getIncludedResource(this.props.resource.data, this.props.resource.included, 'network')
    const primaryContacts = getIncludedResource(this.props.resource.data, this.props.resource.included, 'primary_contacts')
    const accountsContacts = getIncludedResource(this.props.resource.data, this.props.resource.included, 'accounts_contacts')
    const organisationType = getIncludedResource(this.props.resource.data, this.props.resource.included, 'organisation_type')
    const legalEntity = getIncludedResource(this.props.resource.data, this.props.resource.included, 'legal_entity_type')
    const standardCommission = getIncludedResource(network.data, this.props.resource.included, 'standard_commission')
    const address = displayAddress(this.props.resource.data.attributes.address)

    return (
      <Box>
        <h2 className="resource-name">
          {resource.name}
          {_.get(resource, 'metadata.principal_company') && (
            <Label className="pull-right" bsStyle='warning'>Appointed Representative</Label>
          )}
        </h2>

        <div className="row">
          <div className="col-sm-6">
            <div className="form-horizontal">
              <InfoLabel label="Name" value={ resource.name } labelSize={4}/>
              <InfoLabel label="Address" value={ address } labelSize={4}/>
              <InfoLabel label="Phone Number" value={ resource.phone1 } labelSize={4}/>
              <InfoLabel label="Phone Number 2" value={ resource.phone2 } labelSize={4}/>
              <InfoLabel label="FCA Number" value={ resource.regulatory_authority_number } labelSize={4}/>
              <InfoLabel label="Legal Entity Type"
                         value={ legalEntity.data.attributes ? legalEntity.data.attributes.name : '' } labelSize={4}/>
              {legalEntity.data.attributes && (legalEntity.data.attributes.code === 'lp' || legalEntity.data.attributes.code === 'llp') && (
                <InfoLabel label="Partners" value={
                  resource.metadata && resource.metadata.partners && resource.metadata.partners.map((partner, i) => {
                    return i > 0 ? ', ' + partner : partner
                  })
                } labelSize={4}/>
              )}
              {legalEntity.data.attributes && legalEntity.data.attributes.code === 'soletrader' && (
                <InfoLabel label="Proprietor" value={ resource.metadata.proprietor } labelSize={4}/>
              )}
              <InfoLabel label="Company Number" value={ resource.legal_entity_number } labelSize={4}/>
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-horizontal">
              <InfoLabel label="Organisation Type"
                         value={ organisationType.data.attributes ? organisationType.data.attributes.name : '' }
                         labelSize={4}/>
              {resource.organisation_type_name === 'Network' ? (
                <InfoLabel label="Network Name"
                           value={ network.data.attributes ? network.data.attributes.name : '' }
                           labelSize={4}/>
              ) : (
                <InfoLabel label="Parent Company"
                           value={ _.get(resource.metadata, 'parent_company', '') }
                           labelSize={4}/>
              )}
              {resource.organisation_type_name === 'Network' && (
                <InfoLabel label="Commission Rate"
                           value={ standardCommission.data.attributes ? standardCommission.data.attributes.rate + '%' : '' }
                           labelSize={4}/>
              )}
              {resource.organisation_type_name === 'Broker' && (
                <InfoLabel label="Principal Company"
                           value={ _.get(resource.metadata, 'principal_company', '') }
                           labelSize={4}/>
              )}

              <InfoLabel label="Website" value={ resource.metadata ? (<a href={this.convertToUrl(resource.metadata.website)} target="__blank">{resource.metadata.website}</a>) : ''} labelSize={4}/>
              <InfoLabel label="Status" value={ displayName(resource.status) } labelSize={4}/>
              <InfoLabel label="Created At" date={true} value={ resource.created_at } labelSize={4}/>
              <InfoLabel label="Updated At" date={true} value={ resource.updated_at } labelSize={4}/>
            </div>
          </div>
        </div>

        {primaryContacts && primaryContacts.length > 0 && (
          <div>
            <div className="hr"/>
            <h2>Primary contacts</h2>
          </div>
        )}

        {Array.isArray(primaryContacts) && primaryContacts.map((contact) => (
          <div key={contact.id} className="form-horizontal">
            <div className="row">
              <div className="col-sm-6">
                <InfoLabel label="Name" value={ contact.attributes.first_name + ' ' + contact.attributes.last_name }
                           labelSize={4}/>
              </div>
              <div className="col-sm-6">
                <InfoLabel label="Phone" value={ contact.attributes.phone1 } labelSize={4}/>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <InfoLabel label="Email" value={ contact.attributes.email } labelSize={4}/>
              </div>
            </div>

            <div className="hr transparent"/>
          </div>
        ))}

        {accountsContacts && accountsContacts.length > 0 && (
          <div>
            <div className="hr"/>
            <h2>Accounts contacts</h2>
          </div>
        )}

        {Array.isArray(accountsContacts) && accountsContacts.map((contact) => (
          <div key={contact.id} className="form-horizontal">
            <div className="row">
              <div className="col-sm-6">
                <InfoLabel label="Name" value={ contact.attributes.first_name + ' ' + contact.attributes.last_name }
                           labelSize={4}/>
              </div>
              <div className="col-sm-6">
                <InfoLabel label="Phone" value={ contact.attributes.phone1 } labelSize={4}/>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <InfoLabel label="Email" value={ contact.attributes.email } labelSize={4}/>
              </div>
            </div>

            <div className="hr transparent"/>
          </div>
        ))}

        {insurers && insurers.length > 0 && (
          <div>
            <div className="hr"/>
            <h2>Insurer Brands</h2>
          </div>
        )}

        {resource.organisation_type_name === 'Insurer' && (
          Array.isArray(insurers) && insurers.map((insurer) => {

            let authorisedUsers = ''
            const insurerAuthorisedUsers = getIncludedResource(insurer, this.props.resource.included, 'authorised_users', 'users')

            if (insurerAuthorisedUsers.length > 0) {
              authorisedUsers = _.reduce(insurerAuthorisedUsers, function(authorisedUsers, user, i) {
                if (i > 0) {
                  authorisedUsers += ', '
                }
                authorisedUsers += user.attributes.first_name + ' ' + user.attributes.last_name
                return authorisedUsers
              }, '')
            }

            return (
              <div key={insurer.id} className="form-horizontal">
                <div className="row">
                  <div className="col-sm-6">
                    <InfoLabel label="Code" value={ insurer.id }
                               labelSize={4}/>
                    <InfoLabel label="Agreement Number" value={ insurer.attributes.agreement_number }
                               labelSize={4}/>
                    <InfoLabel label="Authorised Users" value={ authorisedUsers }
                               labelSize={4}/>
                    <InfoLabel label="Carry Broker Risk" value={ <YesNoFormat data={insurer.attributes.carry_broker_risk}/> }
                               labelSize={4}/>
                    <InfoLabel label="Max Brokerage" value={ _.get(insurer.attributes, 'max_commission') }
                               labelSize={4}/>
                  </div>
                </div>
              </div>
            )})
        )}

        <ButtonBar>
          {hasPermission('organisation.edit') && resource.organisation_type_name === 'Network' && (
            <EditNetworkButton
              networkId={network.data.id}
            />
          )}
          { hasPermission('organisation.edit') && (
            <Button
              link={true}
              to={'/admin/organisations/' + this.props.resource.data.id + '/edit'}
              className="pull-right"
              bsStyle="primary"
              rightIcon="pencil-square-o">Edit Organisation</Button>
          )}
          { (hasPermission('organisation.activate') && resource.status === organisationStatuses.inactive) && (
            <Button
              className="pull-right"
              bsStyle="primary"
              rightIcon="check"
              isLoading={organisation.isReactivating}
              handleClick={::this.reactivateOrganisation}>Reactivate Organisation</Button>
          )}
          { (hasPermission('organisation.deactivate') && resource.status !== organisationStatuses.inactive) && (
            <Button
              className="pull-right"
              bsStyle="primary"
              rightIcon="ban"
              isLoading={organisation.isDeactivating}
              handleClick={::this.deactivateOrganisation}>Deactivate Organisation</Button>
          )}
        </ButtonBar>
      </Box>
    )
  }
}
