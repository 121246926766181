import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal, Icon } from '../../../../common/components'
import { openModal } from '../../../../common/components/Modal/ModalRedux'
import './styles.scss'

class BenefitsModal extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    scheme: PropTypes.object.isRequired,
    benefits: PropTypes.array.isRequired,
    label: PropTypes.string,
  }

  render() {
    const { dispatch, scheme, benefits, label } = this.props

    const modalButtons = (
      <div>
        {scheme.documents && Object.keys(scheme.documents).map((document, i) => {
          if (! document.includes('Information')) {
            return (
              <a key={i} href={scheme.documents[document]} target="_blank" className="btn btn-default">
                {document}
              </a>
            )
          }
        })}
      </div>
    )

    return (
      <>
        <span className="info" onClick={() => dispatch(openModal(scheme.name))}>
          <Icon name="info-circle" size="fa-lg" /> {label}
        </span>

        <Modal
          {...this.props}
          name={scheme.name}
          title="Full Benefits"
          close={true}
          footer={modalButtons}
          bsSize={'large'}
        >
          <div className="benefits-modal">
            <div className="row">
              <div className="col-xs-12">
                <h4>{scheme.name}</h4>
                <div className="row">
                  <div className="col-xs-5"><strong>Benefit</strong></div>
                  <div className="col-xs-4"><strong>Maximum Sum Insured</strong></div>
                  <div className="col-xs-2 text-right"><strong>Excess</strong></div>
                </div>
                <div className="full-benefits-list">
                  {benefits.map((benefit, key) => {
                    return (
                      <div key={'benefit' + key}>
                        <div className="row">
                          <div className="col-xs-5">{benefit.name}</div>
                          <div className="col-xs-4">{benefit.text}</div>
                          <div className="col-xs-2 text-right">{benefit.excess}</div>
                        </div>
                        {(benefit.sub_benefits && Array.isArray(benefit.sub_benefits)) && benefit.sub_benefits.length > 0 && (
                          <div>
                            {benefit.sub_benefits.map((subBenefit, subKey) => {
                              return (
                                <div key={'subBenefit' + subKey} className="row">
                                  <div className="col-xs-5">- {subBenefit.name}</div>
                                  <div className="col-xs-4">{subBenefit.text}</div>
                                  <div className="col-xs-2 text-right">{subBenefit.excess}</div>
                                </div>
                              )
                            })}
                          </div>
                        )}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    )
  }
}

export default connect()(BenefitsModal)
