import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Box, DataTable, SelectBroker, SelectUser } from '../../../../common/components'
import { applyFilter, removeFilter } from '../../../../common/components/DataTable/redux/dataTableActions'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { FormControl, ControlLabel } from 'react-bootstrap'
import ProductFilter from '../ProductFilter'
import { push } from 'react-router-redux'
import { isOwner, isBroker } from '../../../auth/redux/authActions'
import { FormattedDate, FormattedTime } from 'react-intl'
import getStatus from '../../../../schemas/policy'
import getUpdatedAtFrom from '../../../../helpers/getUpdatedAtFrom';

class ApplicationList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    currentBroker: PropTypes.string,
    auth: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props)

    this.state = {
      brokerId: '',
      userId: null
    }
  }

  formatDate(cell) {
    return (
      <span><FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/></span>
    )
  }

  handleFilterUpdate(event) {
    this.props.dispatch(applyFilter('applications', 'filter[product]', event.target.value))
  }

  handleRowClick(row) {
    this.props.dispatch(push('/policies/applications/' + row.id))
  }

  handleSearchUpdate(event) {
    Promise.resolve(
      this.props.dispatch(removeFilter('applications', 'filter[updated_at:from]'))
    ).then(
      this.props.dispatch(applyFilter('applications', 'filter[quote.reference]', event.target.value))
    );
  }

  handleBrokerUpdate(value) {
    this.setState({ brokerId: value })
    this.props.dispatch(applyFilter('applications', 'filter[broker]', value))
  }

  handleUserUpdate(value) {
    this.setState({ userId: value })
    this.props.dispatch(applyFilter('applications', 'filter[user]', value))
  }

  handleStatusUpdate(event) {
    let filter = event.target.value;

    if (isBroker()) {
      this.props.dispatch(applyFilter('applications', 'filter[not_status]', 'renewal,renewal invited,lapsed'))
    } else {
      this.props.dispatch(applyFilter('applications', 'filter[not_status]', ''))
    }

    this.props.dispatch(applyFilter('applications', 'filter[status]', filter))
  }

  render() {
    const autoFilters = [
      { type: 'filter[status]', value: 'all' },
      { type: 'filter[user]', value: this.state.userId },
      { type: 'filter[updated_at:from]', value: getUpdatedAtFrom() },
    ];

    if (isBroker()) {
      autoFilters.push({ type: 'filter[not_status]', value: 'renewal,renewal invited,lapsed' })
    }
    return (
      <div>
        <Box>
          <div className="row">
            <div className="col-sm-3">
              <ControlLabel className="filter-label">Reference</ControlLabel>
              <FormControl onChange={ ::this.handleSearchUpdate } />
            </div>
            { isOwner() && (
              <div className="col-sm-3">
                <ControlLabel className="filter-label">Broker</ControlLabel>
                <SelectBroker
                  labelKeys={['attributes.name']}
                  input={{
                    onChange: ::this.handleBrokerUpdate,
                    value: this.state.brokerId
                  }}/>
              </div>
            )}
            {(isOwner() || isBroker()) && (
              <div className="col-sm-3">
                <ControlLabel className="filter-label">User</ControlLabel>
                <SelectUser
                  labelKeys={['attributes.first_name', 'attributes.last_name']}
                  input={{
                    onChange: ::this.handleUserUpdate,
                    value: this.state.userId
                  }}/>
              </div>
            )}
            <div className="col-sm-3">
              <ControlLabel className="filter-label">Product</ControlLabel>
              <ProductFilter onChange={::this.handleFilterUpdate} />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3">
              <ControlLabel className="filter-label">Status</ControlLabel>
              <FormControl
                componentClass="select"
                type="select"
                onChange={ ::this.handleStatusUpdate }
              >
                <option value="all">All</option>
                <option value="applied">Applied</option>
                <option value="quote">Quote</option>
                <option value="issued">Issued</option>
                <option value="lapsed">Lapsed</option>
                {isOwner() && <option value="renewal">Renewal</option>}
                <option value="renewal invited">Renewal{isOwner() ? ' Invited' : ''}</option>
                <option value="expired">Expired</option>
                <option value="declined">Declined</option>
                <option value="referred">Referred</option>
                <option value="referral approved">Referral Approved</option>
              </FormControl>
            </div>
          </div>
        </Box>

        <Box>
          <DataTable
            source="/policies/applications"
            name="applications"
            showTotal={true}
            autoFilters={autoFilters}
            onRowSelect={this.handleRowClick.bind(this)}>

            <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
              ID
            </TableHeaderColumn>

            <TableHeaderColumn dataField="quote_reference">
              Reference
            </TableHeaderColumn>

            <TableHeaderColumn dataField="product_name">
              Product
            </TableHeaderColumn>

            <TableHeaderColumn dataField="insured" >
              Insured
            </TableHeaderColumn>

            <TableHeaderColumn dataField="broker_name">
              Broker
            </TableHeaderColumn>

            <TableHeaderColumn dataField="user_name">
              User
            </TableHeaderColumn>

            <TableHeaderColumn dataField="status" dataFormat={getStatus} dataSort={true} width={'100px'}>
              Status
            </TableHeaderColumn>

            <TableHeaderColumn dataField="created_at" dataFormat={this.formatDate} dataSort={true}>
              Quote Date
            </TableHeaderColumn>

          </DataTable>
        </Box>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps)(ApplicationList)
