export const SET_CAMPAIGNS = 'socrates-online/campaigns/SET_CAMPAIGNS'
export const IS_SAVING = 'socrates-online/campaigns/IS_SAVING'

export const initialState = {
  isSaving: false,
  resources: {}
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case IS_SAVING:
      return {
        ...state,
        isSaving: action.status
      }
    case SET_CAMPAIGNS:
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.data.data.id]: {
            ...action.data
          }
        }
      }

    default:
      return state
  }
}
