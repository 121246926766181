import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { setAnswer } from '../../redux/screeningActions'
import getIncludedResponse from '../../../../helpers/getIncludedResource'

export default class SingleChoiceQuestion extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    screening: PropTypes.object.isRequired,
    condition: PropTypes.object.isRequired,
    question: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      answer: ''
    }
  }

  componentWillReceiveProps(props) {
    const options = getIncludedResponse(props.question, props.screening.declaration.included, 'options')

    Object.keys(options).map(id => {
      const option = options[id]

      if (option.attributes.isSelected && this.state.answer != option.attributes.number) {
        this.setState({ answer: option.attributes.number })
      }
    })
  }

  render() {
    const { index, screening, condition, question, dispatch } = this.props
    const optionsResource = getIncludedResponse(question, screening.declaration.included, 'options')
    const { answer } = this.state

    const options = optionsResource.map(option => {
      return {
        value: option.attributes.number,
        label: option.attributes.text
      }
    })

    return (
      <div className="single-choice-question">
        <div className="col-xs-12">
          <div className="form-group">
            <label className="control-label">{question.attributes.text}</label>
            {question.attributes.info ? (
              <p className="text-info text-justify" style={{ 'font-size': '14px' }}>
                {question.attributes.info}
                <br/>
              </p>
            ) : ''}
            <Select
              name={'single-choice-question-' + index}
              options={options}
              value={answer}
              clearable={false}
              onChange={(answer) => {
                dispatch(setAnswer(condition, question, answer))
                this.setState({ answer })
              }}
            />
          </div>
        </div>
      </div>
    )
  }
}
