import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box, DataTable, ButtonBar } from '../../../../common/components'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { FormattedDate, FormattedTime } from 'react-intl'
import { hasPermission } from '../../../auth/redux/authActions'
import CreateContractButton from '../CreateContractButton'
import { refreshData } from '../../../../common/components/DataTable/redux/dataTableActions'

export default class BrokerContracts extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    brokerLabel: PropTypes.object.isRequired
  };

  formatDate(cell, row) {
    if (cell == null) {
      return ''
    }

    const date = new Date(cell)
    if (date != 'Invalid Date') {
      return (
          <span><FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/></span>
      )
    }
    return ''
  }

  handleRowClick(row) {
    if (window) {
      window.open(row.file_path, '_blank')
    }

    return false
  }

  refreshList() {
    this.props.dispatch(refreshData('contracts'))
  }

  render() {
    const resource = this.props.resource.data.attributes

    return (
        <div>
          <Box>
            <h2 className="resource-name">{resource.name} {this.props.brokerLabel}</h2>

            <DataTable
                source={'/brokers/contracts'}
                name="contracts"
                showTotal={true}
                autoFilter={this.props.resource.data.id}
                autoFilterType="filter[broker]"
                onRowSelect={this.handleRowClick.bind(this)}>

              <TableHeaderColumn dataField="id" isKey={true} hidden={true}>ID</TableHeaderColumn>
              <TableHeaderColumn dataField="created_at" dataFormat={this.formatDate} width={'180px'} dataSort={true}>Created</TableHeaderColumn>
              <TableHeaderColumn dataField="agreed_at" dataFormat={this.formatDate} width={'180px'}
                                 dataSort={true}>Agreed</TableHeaderColumn>
              <TableHeaderColumn dataField="ended_at" dataFormat={this.formatDate} width={'180px'}
                                 dataSort={true}>Ended</TableHeaderColumn>
              <TableHeaderColumn dataField="status" dataSort={true} width={'180px'}>Status</TableHeaderColumn>
            </DataTable>
            <ButtonBar>
              { hasPermission('broker.edit') && (
                  <CreateContractButton
                      broker={this.props.resource.data}
                      callback={::this.refreshList}
                  />
              )}
            </ButtonBar>
          </Box>
        </div>
    )
  }
}
