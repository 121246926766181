import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import Icon from '../../../../common/components/Icon/Icon'
import Reminders from './Reminders/Reminders'
import Announcements from './Announcements/Announcements'
import Notifications from './Notifications/Notifications'
import { fetchStats, fetchBrokerStats, fetchAnnouncements, fetchReminders } from '../../../dashboard/redux/dashboardActions'
import { hasPermission } from '../../../auth/redux/authActions'
import './InternalNotifications.scss'
import moment from 'moment'


class DropDown extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    counter: PropTypes.number.isRequired
  }

  constructor (props, context) {
    super(props, context)
    // Dropdown block is inactive & hidden by default
    this.state = {
      dropdownIsActive: false,
      dropdownIsVisible: false
    }

    this._hideDropdown = this._hideDropdown.bind(this)
    this._handleBlur = this._handleBlur.bind(this)
    this._handleFocus = this._handleFocus.bind(this)
    this._openDropdown = this._openDropdown.bind(this)
  }

  componentDidMount () {
    // Hide dropdown block on click outside the block
    window.addEventListener('click', this._hideDropdown, false)
  }

  componentWillUnmount () {
    // Remove click event listener on component unmount
    window.removeEventListener('click', this._hideDropdown, false)
  }

  _stopPropagation (e) {
    // Stop bubbling of click event on click inside the dropdown content
    e.stopPropagation()
  }

  _openDropdown () {
    this.setState({ dropdownIsVisible: true })
  }

  _hideDropdown () {
    const { dropdownIsActive } = this.state

    if (!dropdownIsActive) {
      this.setState({ dropdownIsVisible: false })
    }
  }

  _handleFocus () {
    // Make active on focus
    this.setState({ dropdownIsActive: true })
  }

  _handleBlur () {
    // Clean up everything on blur
    this.setState({
      dropdownIsVisible: false,
      dropdownIsActive: false
    })
  }

  render () {
    const { dropdownIsVisible } = this.state
    return (
      <div
        onFocus={this._handleFocus}
        onClick={this._openDropdown}
        className='icon-bt__wrapper'
      >
        <span className='toggler'>
          <Button className='icon-bt btn--no-shadow' >
            <Icon className='notification-icon' name={this.props.content.icon} />
            <span className='badge'>{this.props.counter}</span>
          </Button>
        </span>

        {
          dropdownIsVisible &&
          <div
            className={'dropdown-' + this.props.content.name} >
            <div >
              <span className='close-area' onClick={this._handleBlur} />
              {this.props.content.dropdown}
            </div>
          </div>
        }
      </div>
    )
  }
}

const itemNotification = {
  content: {
    name: 'notifications',
    icon: 'commenting',
    dropdown: <div>
      <h3> Notifications </h3>
      <Notifications />
    </div>
  }
}
const itemReminders = {
  content: {
    name: 'reminders',
    icon: 'bell',
    dropdown: <div>
      <h3> Reminders </h3>
      <Reminders />
    </div>
  }
}
const itemAnnouncements = {
  content: {
    name: 'announcements',
    icon: 'bullhorn',
    dropdown: <div>
      <h3> Announcements </h3>
      <Announcements />
    </div>
  }
}

export class InternalNotifications extends Component {
  componentWillMount () {
    this.props.dispatch(fetchStats())
    this.props.dispatch(fetchBrokerStats())
    this.props.dispatch(fetchAnnouncements())
    this.props.dispatch(fetchReminders())
  }

  render () {
    const { dashboard } = this.props
    const usersAwaitTotal = hasPermission('user.edit') ? _.get(dashboard.stats, 'meta.users_awaiting_activation', 0) : 0
    const brokersAwaitTotal = hasPermission('broker.approve') ? _.get(dashboard.stats, 'meta.brokers_awaiting_approval', 0) : 0
    const referralsTotal = _.get(dashboard.stats, 'meta.referrals_requiring_approval', 0)
    const renewalsAwaitTotal = _.get(dashboard.stats, 'meta.renewals_awaiting_conversion', 0)
    const mtasAwaitTotal = _.get(dashboard.stats, 'meta.mtas_requiring_approval', 0)

    const notificationsTotal = usersAwaitTotal + brokersAwaitTotal + referralsTotal + renewalsAwaitTotal + mtasAwaitTotal
    const announcementsTotal = _.get(dashboard.announcements, 'data', []).filter(announcement => {
        if ((!announcement.attributes.to_date &&
            moment().isBefore(moment(announcement.attributes.from_date).add(announcement.attributes.duration, 'days')) ||
            moment().isBefore(announcement.attributes.to_date))) {
              return announcement;
        }
      }
    );

    const remindersTotal = _.get(dashboard.reminders, 'data.length', 0)
    return (
      <div className="notifications-nav">
        <div className='notification-bar-notifications' >
          <DropDown id={1} counter={notificationsTotal} content={itemNotification.content} />
        </div>
        <div className='notification-bar-reminder'>
          <DropDown id={2} counter={remindersTotal} content={itemReminders.content} />
        </div>
        <div className='notification-bar-announcements'>
          <DropDown id={3} counter={announcementsTotal.length} content={itemAnnouncements.content} />
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    dashboard: state.dashboard
  }
}

export default connect(mapStateToProps)(InternalNotifications)
