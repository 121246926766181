import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button } from '../../../common/components'
import { Tabs, Tab } from 'react-bootstrap'
import { hasPermission } from '../../auth/redux/authActions'
import InternalContainer from '../../layout/containers/Internal'

import Brochure from '../components/Brochure/Brochure'
import Underwriting from '../components/Underwriting/Underwriting'


import { getKeyPersonnel, getPartners } from '../redux/contentActions'

class ContentListContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    content: PropTypes.object.isRequired,
  }

  state = {
    activeTab: 1
  }

  componentWillMount() {
    this.props.dispatch(getKeyPersonnel())
    this.props.dispatch(getPartners())
  }

  handleTab(key) {
    this.setState({ activeTab: key })
  }

  render() {
    return (
      <InternalContainer title="Content Management">


        <Tabs activeKey={this.state.activeTab}
              id="content-tabs"
              unmountOnExit={true}
              onSelect={::this.handleTab}
              className='shadow-box'
        >
          <Tab eventKey={1} title="Brochure">
            <Brochure {...this.props} />
          </Tab>

          <Tab eventKey={2} title="Underwriting">
            <Underwriting {...this.props} />
          </Tab>

        </Tabs>
      </InternalContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    content: state.content
  }
}

export default connect(mapStateToProps)(ContentListContainer)
