import React from 'react'
import { DataTable, CreateNoteButton, NotesList } from '../../../../../../common/components'
import { refreshData } from '../../../../../../common/components/DataTable/redux/dataTableActions';
import { FormattedDate } from 'react-intl'
import { Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import Box from '../../../../../../common/components/Box/Box';

class NotesForm extends React.Component {
  formatDate(cell, row) {
    return (
      <FormattedDate value={new Date(cell)}/>
    )
  }

  handleRowClick(row) {
    //this.props.dispatch(push('/admin/users/' + row.id));
    return false
  }

  refreshList() {
    this.props.dispatch(refreshData('notes'))
  }

  render() {
    const { id, formValues }  = this.props
    return (
      <Box>
        <Row>
          <Col sm={12}>
            <h4 className='page-title'>Supporting Documents</h4>
            <hr/>
            <DataTable
              source={'/notes'}
              autoFilters={[
                { type: 'filter[resource]', value: id },
                { type: 'include', value: 'attachments' }
              ]}
              name="notes"
              showTotal={true}
              onRowSelect={this.handleRowClick.bind(this)}
              component={NotesList}
            />

            <Row className="actions">
              <Col xs={12}>
                <CreateNoteButton
                  label="Add new document"
                  notableResource={formValues}
                  hideReminder={true}
                  notableResourceType="brokers/applications"
                  callback={::this.refreshList}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Box>
    )
  }
}

export default connect()(NotesForm)
