import React from 'react'

export const referral = (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95 95">
        <title>Icons</title>
        <path d="M69.74,18.92a6.2,6.2,0,0,1,4.55,1.87,6,6,0,0,1,1.87,4.4v51a6,6,0,0,1-1.87,4.4,6.2,6.2,0,0,1-4.55,1.87H25.26a6.2,6.2,0,0,1-4.55-1.87,6,6,0,0,1-1.87-4.4v-51a6,6,0,0,1,1.87-4.4,6.2,6.2,0,0,1,4.55-1.87H38.54a9.79,9.79,0,0,1,3.51-4.63,9.18,9.18,0,0,1,10.9,0,9.8,9.8,0,0,1,3.51,4.63Zm0,57.31v-51H63.47v9.55H31.53V25.19H25.26v51Zm-20-56.42a3.25,3.25,0,1,0,.9,2.24A3,3,0,0,0,49.74,19.81Z"/>
        <path d="M64.45,50.79l-9,9a1.11,1.11,0,0,1-.79.33A1.13,1.13,0,0,1,53.59,59V54.48H49.67c-7.54,0-12.5,1.45-12.5,9.8,0,.72,0,1.43.09,2.15,0,.28.09.6.09.87a.57.57,0,0,1-.56.61.55.55,0,0,1-.49-.3,9.32,9.32,0,0,1-.65-1.33c-1-2.24-2.22-5.44-2.22-7.89a15.78,15.78,0,0,1,.93-5.83c2.43-6,9.57-7.05,15.31-7.05h3.92V41a1.13,1.13,0,0,1,1.12-1.12,1.11,1.11,0,0,1,.79.33l9,9a1.1,1.1,0,0,1,0,1.58Z"/>
      </svg>
)

export default referral
