import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Label } from 'react-bootstrap';
import { Link } from 'react-router';
import { InfoLabel, Box, Button, ButtonBar } from '../../../../common/components';
import getIncludedResource from '../../../../helpers/getIncludedResource';
import displayAddress from '../../../../helpers/displayAddress';
import { hasPermission, isOwner } from '../../../auth/redux/authActions';
import LeisureTravelDetails from '../LeisureTravelDetails';
import Endorsements from '../Endorsements';
import TransferPolicyButton from '../TransferPolicyButton';
import SendSmsButton from '../SendSmsButton';
import CancelButton from '../CancelButton';
import IssueButton from '../../../product/components/IssueButton';
import LapseButton from '../LapseButton';
import PremiumBreakdown from '../../../product/components/PremiumBreakdown';
import getStatus from '../../../../schemas/policy';
import Markdown from 'react-markdown';
import * as _ from 'lodash';
import './styles.scss';
import displayCurrency from '../../../../helpers/displayCurrency';
import { inviteRenewal, unlapseQuote } from '../../redux/policyActions';
import DeclineButton from '../../../broker/components/DeclineButton/DeclineButton';
import Tooltip from '../../../../common/components/Tooltip/Tooltip';
import PreventRenewalButton from '../PreventRenewalButton/PreventRenewalButton';
import TravellersDetails from '../TravellerDetails/TravellersDetails';


export default class PolicyDetails extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    policy: PropTypes.object.isRequired,
    resource: PropTypes.object.isRequired,
    reference: PropTypes.string.isRequired,
  };

  handleInvite() {
    return this.props.dispatch(inviteRenewal(this.props.resource.data.id));
  }

  handleUnlapse() {
    return this.props.dispatch(unlapseQuote(this.props.resource));
  }

  getLabelStyle(status, policy) {
    const resource = this.props.resource.data.attributes;

    if (resource.quote_type === 'mid term adjustment' && status === 'quote') {
      return 'warning';
    }

    if (status === 'quote'
      || status === 'referral approved'
      || status === 'on cover'
      || status === 'active'
      || status === 'issued'
      && policy
    ) {
      return 'success';
    }

    if (status === 'declined' || status === 'cancelled') {
      return 'danger';
    }

    if (status === 'referred') {
      return 'warning';
    }

    return 'default';
  }

  getStatus(status) {
    const resource = this.props.resource.data.attributes;
    if (resource.quote_type === 'mid term adjustment' && status === 'quote') {
      return 'awaiting approval';
    }

    return getStatus(status);
  }

  cancelButton() {
    const resource = this.props.resource.data.attributes;

    if (
      hasPermission('policy.underwriter')
      && resource.status === 'expired'
      && _.get(resource, 'policy_number')
    ) {
      return (
        <CancelButton
          className="pull-right"
          resource={this.props.resource}
        />
      );
    }

    if (!hasPermission('policy.cancel') || !resource.policy_number) {
      return;
    }

    if (resource.status !== 'issued' && resource.status !== 'on cover' && resource.status !== 'active') {
      return;
    }

    if (!hasPermission('policy.underwriter') && !resource.is_inside_cancellation_period) {
      return;
    }

    return (
      <CancelButton
        className="pull-right"
        resource={this.props.resource}
      />
    );
  }

  editQuote(product) {
    const resource = this.props.resource.data.attributes;

    if (!hasPermission('policy.quote')) {
      return;
    }

    if (resource.owner_locked && !hasPermission('policy.underwriter')) {
      return;
    }

    if (resource.quote_type === 'mid term adjustment' && !hasPermission('policy.mta')) {
      return;
    }

    const editStatuses = [
      'quote',
      'applied',
      'renewal',
      'renewal invited',
      'referred',
      'referral approved',
    ];

    if (_.get(resource, 'quote_reference')) {
      editStatuses.push('expired');
    }

    if (_.indexOf(editStatuses, resource.status) === -1) {
      return;
    }

    if (resource.status === 'referred' && !hasPermission('policy.handle_all_referrals')) {
      return;
    }

    return (
      <Button
        link={true}
        to={'/products/' + product.data.id + '/quote/' + this.props.resource.data.id}
        className="pull-right"
        bsStyle="primary"
        rightIcon="pencil-square-o"
      >Edit Quote</Button>
    );
  }

  render() {
    const { reference } = this.props;
    const resource = this.props.resource.data.attributes;
    const resourceData = this.props.resource.data;
    const resourceIncluded = this.props.resource.included;
    const customer = getIncludedResource(this.props.resource.data, this.props.resource.included, 'customer');
    const premiums = getIncludedResource(this.props.resource.data, this.props.resource.included, 'premiums');
    const applications = getIncludedResource(this.props.resource.data, this.props.resource.included, 'issued_applications');
    const renewalApplications = getIncludedResource(this.props.resource.data, this.props.resource.included, 'renewal_policy_application');
    const policy = getIncludedResource(this.props.resource.data, this.props.resource.included, 'policy');
    const schemes = getIncludedResource(this.props.resource.data, this.props.resource.included, 'schemes');
    const transactions = getIncludedResource(
      applications && applications.length ? _.last(applications) : this.props.resource.data,
      this.props.resource.included,
      'transactions',
    );
    const product = getIncludedResource(
      schemes && schemes.length ? schemes[0] : this.props.resource.data, this.props.resource.included,
      'product',
    );
    const campaignInfo = getIncludedResource(this.props.resource.data, this.props.resource.included, 'campaign');
    const user = getIncludedResource(this.props.resource.data, this.props.resource.included, 'user');
    const broker = getIncludedResource(this.props.resource.data, this.props.resource.included, 'broker');
    const mtas = getIncludedResource(this.props.resource.data, this.props.resource.included, 'mid_term_adjustment_applications');
    const isAnnual = resource.metadata.scheme_type === 'annual';
    const address = displayAddress(customer.data.attributes.address);
    const legacyPolicy = () => {
      if (_.get(resource, 'quote_type') === 'legacy system') {
        return true;
      }

      return _.get(resource, 'metadata.legacy_policy', false);
    };
    const transactionSource = transactions.length ? _.get(transactions[0], 'attributes.source') : '';

    let renewalStatus = '';
    if (isAnnual) {
      if (resource.policy_number) {
        renewalStatus = resource.renewal_status;
      }
      if (resource.quote_type === 'renewal') {
        renewalStatus = policy.data.attributes.renewal_status;
      }
    }

    return (
      <Box>
        <div className="policy-details">

          <h2 className="resource-name">{reference}
            <Label className="pull-right" bsStyle={this.getLabelStyle(resource.status, resource.policy_number)}>
              {this.getStatus(resource.status)}
            </Label>
          </h2>

          {_.get(product, 'data.attributes.product_code') && (
            <div>
              <LeisureTravelDetails schemes={schemes} premiums={premiums} product={product} renewalStatus={renewalStatus}
                                    dispatch={this.props.dispatch}
                                    resource={resource}
                                    resourceData={resourceData}
                                    resourceIncluded={resourceIncluded}
                                    campaignInfo={campaignInfo}/>
            </div>
          )}

          <div className="row">
            {_.get(resource, 'metadata.referrals', []).length > 0 && (
              <div className="col-xs-12">
                <div className="form-horizontal">
                  <InfoLabel
                    label="Referral Reason(s)"
                    value={_.get(resource, 'metadata.referrals', []).join(',\n')}
                    labelSize={2}
                  />
                </div>
              </div>
            )}

          </div>

          <TravellersDetails resource={resource}/>

          <div className="row">
            <div className="hr touch-edge"/>
            <div className="col-sm-12">
              <h3>Customer Details</h3>
            </div>
            <div className="col-sm-6">
              <div className="form-horizontal">
                <InfoLabel
                  label="Customer name"
                  value={<Link to={'/customers/' + customer.data.id}>{customer.data.attributes.customer_name}</Link>}
                  labelSize={4}
                />
                <InfoLabel
                  label="Address"
                  value={address || 'TBA'}
                  labelSize={4}
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-horizontal">
                <InfoLabel
                  label="Email"
                  value={customer.data.attributes.email || 'TBA'}
                  labelSize={4}
                />
                <InfoLabel
                  label="Phone"
                  value={customer.data.attributes.phone1 || 'TBA'}
                  labelSize={4}
                />
              </div>
            </div>
          </div>


          <div className="form-horizontal">

            {renewalApplications && renewalApplications.length > 0 ||
            applications && applications.length > 0 ||
            (mtas && mtas.length) && (
              <div className="row">
                <div className="col-sm-12">
                  <h3>History</h3>
                </div>
              </div>
            )}

            {applications && applications.length > 0 && (
              <div className="row">
                <div className="col-sm-12">
                  <InfoLabel
                    labelSize={2}
                    label="Quotes"
                    value={applications.map((quote, i) => {
                      return (<li key={i}><Link
                        to={'/policies/applications/' + quote.id}>{quote.attributes.quote_reference}</Link></li>);
                    })}
                  />
                </div>
              </div>
            )}
          </div>


          {renewalApplications && renewalApplications.length > 0 && (
            <div className="form-horizontal">
              <div className="row">
                <div className="col-sm-12">
                  <InfoLabel
                    labelSize={2}
                    label="Renewals"
                    value={renewalApplications.map((quote) => {
                      return (<li key={quote.id}><Link
                        to={'/policies/applications/' + quote.id}>{quote.attributes.quote_reference}</Link></li>);
                    })}
                  />
                </div>
              </div>
            </div>
          )}

          {mtas && mtas.length > 0 && (
            <div className="form-horizontal">
              <div className="row">
                <div className="col-sm-12">
                  <InfoLabel
                    labelSize={2}
                    label="Mid Term Adjustments"
                    value={mtas.map((mta, i) => {
                      return (<li key={i}><Link
                        to={'/policies/applications/' + mta.id}>{mta.attributes.quote_reference}</Link></li>);
                    })}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="form-horizontal">
            <div className="row">
              <div className="hr touch-edge"/>
              <div className="col-sm-12">
                <h3>Broker</h3>
              </div>
              <div className="col-sm-6">
                <InfoLabel
                  label="Broker"
                  value={_.get(broker, 'data.attributes.name', '')}
                  labelSize={4}
                />
              </div>
              <div className="col-sm-6">
                <InfoLabel
                  label="User"
                  value={
                    _.get(user, 'data.attributes.first_name', '') + ' '
                    + _.get(user, 'data.attributes.last_name', '')
                  }
                  labelSize={4}
                />
              </div>
            </div>
          </div>


          {_.get(policy, 'data.attributes') && (
            <div className="form-horizontal">
              <div className="row">
                <div className="hr touch-edge"/>
                <div className="col-sm-12">
                  <InfoLabel
                    labelSize={2}
                    label={resource.status === 'renewal' || resource.status === 'renewal invited' ? 'Expiring Policy' : 'Policy'}
                    value={<Link target={_.includes(status, 'Renewal') ? '_blank' : '_self'}
                                 to={'/policies/issued/' + policy.data.id}>{policy.data.attributes.policy_number + ' (' + this.getStatus(policy.data.attributes.status) + ')'}</Link>}
                  />
                </div>
              </div>
            </div>
          )}

           {/*TODO unsure if we need this or what extra information it is meant to give, since we show the endorsements below*/}
          {resource.quote_type === 'mid term adjustment' && _.get(resource, 'metadata.endorsements[0].content') && (
            <div className="form-horizontal">
              <div className="row">
                <div className="hr touch-edge"/>
                <div className="col-sm-12">
                  <InfoLabel
                    labelSize={2}
                    label="Mid Term Adjustment"
                    value={<Markdown source={_.get(resource, 'metadata.endorsements[0].content', '')}/>}
                  />
                </div>
              </div>
            </div>
          )}


          <div>
            {(_.get(resource, 'metadata.endorsements', []).length > 0) && (
              <div>
                <div className="row">
                  <div className="hr touch-edge"/>
                  <Endorsements endorsements={resource.metadata.endorsements}/>
                </div>
              </div>
            )}


            {(resource.status === 'quote' && !resource.valid) && (
              <h4><strong>More details are required before this policy can be issued</strong></h4>
            )}

            <div className="row">
              <div className="hr touch-edge"/>
              <div className="col-xs-12">
                <h3>Premiums</h3>
              </div>
              {resource.policy_number && (
                <div className="col-sm-12 text-right">
                  <h5>
                    Payment Type: {transactionSource ? _.startCase(transactionSource) : 'Account'}
                  </h5>
                </div>
              )}
              <div className="col-xs-12">
                <div className="col-xs-12 form-horizontal">
                  {Array.isArray(premiums) && premiums.map((premium, i) => {
                    const scheme = getIncludedResource(premium, this.props.resource.included, 'scheme');
                    const documents = getIncludedResource(scheme.data, this.props.resource.included, 'documents');
                    const showButtons = (resource) => {
                      if (!resource.valid) {
                        return false;
                      }

                      if (resource.quote_type === 'mid term adjustment' && !hasPermission('policy.mta')) {
                        return false;
                      }

                      return _.indexOf(['quote', 'renewal invited', 'referral approved'], resource.status) !== -1;
                    };

                    return (
                      <div key={i} className="row quoted-scheme">
                        <div className="col-sm-2 scheme-logo-container">
                          <img className="img-responsive insurer-logo"
                               src={scheme.data.attributes.logo}/>
                        </div>
                        <div className="col-sm-2 text-center">
                          <span className="cover-level">
                            {scheme.data.attributes.cover_level}
                          </span>
                        </div>
                        {premium.attributes && (
                          <div className="col-sm-2 text-center">
                            <span className="scheme-type">
                              {_.get(premium, 'attributes.scheme.raw_scheme_type', '')}
                            </span>
                          </div>)}
                        {!showButtons(resource) && (
                          <div className="col-sm-3">&nbsp;</div>
                        )}
                        <div className="col-sm-3 text-center">
                          {legacyPolicy() ? (
                            <span>{displayCurrency(premium.attributes.gross)}</span>
                          ) : (
                            <PremiumBreakdown
                              premium={premium.attributes}
                              savedPremium={premium.attributes}
                              originalPremium={premium.attributes.premium_before_adjustment}
                              id={premium.id}
                              block={true}
                              showIpt={false}
                              scheme={scheme}
                            />
                          )}
                          <span className="small">{premium.attributes.premium_type}</span>
                        </div>
                        {showButtons(resource) && (
                          <div className="col-sm-3">
                            <IssueButton
                              bsStyle="primary"
                              className="pull-right"
                              label="Issue Policy"
                              application={this.props.resource}
                              schemeId={scheme.data.id}
                              product={product}
                              declaration={
                                _.get(product, 'data.attributes.metadata.purchase_declaration', '')
                              }
                            />
                            {premium.attributes.quote_document && (
                              <a
                                href={premium.attributes.quote_document}
                                target="_blank"
                                className="pull-right"
                              >
                                <Button size="xs" label="Quotation"/>
                              </a>
                            )}
                            {Array.isArray(documents) && documents.map((document, dk) => {
                              return (
                                <a key={dk}
                                   href={document.attributes.file_path}
                                   target="_blank"
                                   className="pull-right"
                                >
                                  <Button size="xs" label={document.attributes.short_name}/>
                                </a>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className="hr transparent"/>

          <ButtonBar>
            {hasPermission('customer.view') && (
              <Button
                link={true}
                to={'/customers/' + customer.data.id}
                className="pull-left"
                bsStyle="primary"
              >
                View{hasPermission('customer.edit') ? '/Edit' : ''} customer
              </Button>
            )}

            {this.cancelButton()}

            {(hasPermission('broker.edit') && resource.policy_number) && (
              <TransferPolicyButton
                className="pull-right"
                resource={this.props.resource}
                user={user}
                resourceType={resource.policy_number ? 'policies' : 'policy-applications'}
              />
            )}

            {(hasPermission('policy.quote')
              && resource.policy_number
              // && !legacyPolicy
              && (
                resource.status === 'issued'
                || resource.status === 'on cover'
                || resource.status === 'active'
              )) && (
              <Button
                link={true}
                to={'/products/' + product.data.id + '/adjustment/' + this.props.resource.data.id}
                className="pull-right"
                bsStyle="primary"
                rightIcon="pencil">Mid-term Adjustment</Button>
            )}

            {(hasPermission('policy.quote') && resource.policy_number
              && resource.status !== 'cancelled') && (
              <SendSmsButton
                className="pull-right"
                resource={this.props.resource}
                phoneNumber={customer.data.attributes.phone1}
              />
            )}

            {(hasPermission('policy.renew') && isOwner() && !resource.policy_number &&
              (resource.status === 'renewal')) && (
              <Button
                className="pull-right"
                rightIcon="share"
                handleClick={::this.handleInvite}
              >Invite</Button>
            )}

            {this.editQuote(product)}
            {(hasPermission('policy.quote') && resource.policy_number && (
              resource.status === 'issued'
              || resource.status === 'on cover'
              || resource.status === 'active'
            ) && isAnnual && _.get(this.props, 'resource.data.type', '') !== 'policies') && (
              <div>
                <PreventRenewalButton
                  className="pull-right"
                  resource={this.props.resource}
                />
              </div>
            )}
            {(hasPermission('policy.quote')
              && resource.policy_number)
            // && !legacyPolicy
            && (
              <div
                className="pull-right"
              >
                <Tooltip
                  content={'Customer details will be read only'}
                  placement={'top'}
                />
                <Button
                  link={true}
                  to={'/products/' + product.data.id + '/quote/' + this.props.resource.data.id + '?clone=true'}
                  bsStyle="primary"
                  rightIcon="clone"
                >Clone</Button>
              </div>
            )}

            {(hasPermission('policy.quote')
              && !resource.policy_number
              && (
                resource.status === 'quote'
                || resource.status === 'applied'
                || resource.status === 'renewal'
                || resource.status === 'renewal invited'
                || resource.status === 'referred'
                || resource.status === 'referral approved')
            ) && (
              <div className="email-btn-wrapper pull-right">
                <LapseButton
                  className="pull-right"
                  resource={this.props.resource}
                />
              </div>
            )}
            {(hasPermission('policy.quote')
              && !resource.policy_number
              && (
                resource.status === 'lapsed')
            ) && (
              <Button
                className="pull-right"
                rightIcon="share"
                handleClick={::this.handleUnlapse}
              >Unlapse</Button>
            )}
            {(hasPermission('policy.underwriter')
              && !resource.policy_number
              && resource.status === 'referred')
            && (
              <DeclineButton
                className="pull-right"
                resource={this.props.resource}
              />
            )}
          </ButtonBar>
        </div>
      </Box>
    );
  }

}
