import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Button, Icon, SelectInsurer, Avatar } from '../../../../common/components'
import SortableItem from './SortableItem'
import './styles.scss'

class Partner extends Component {
  static propTypes = {
    fields: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      value: '',
      partner: null,
      dragIndex: null,
      included: []
    }
  }

  handleSort(values) {
    const { fields, items, dispatch } = this.props
    this.setState({ dragIndex: values.draggingIndex })

    if (values.items) {
      values.items.forEach((value, i) => {
        const index = items.indexOf(value)
        if (index !== i) {
          dispatch(fields.swap(index, i))
        }
      })
    }
  }

  render() {
    const { fields, items } = this.props

    return (
        <div className="content-partners">
          <div className="sortable-list">
            <div className={'list' + (this.state.dragIndex !== null ? ' dragging' : '')}>
              {fields.map((field, index) => {
                const partner = items[index] ? items[index] : { attributes: {} }

                return (
                    <SortableItem
                        key={index}
                        sortId={index}
                        outline="list"
                        items={items}
                        updateState={::this.handleSort}
                        draggingIndex={this.state.dragIndex}>
                      <div key={index} className="row">
                        <div className="col-xs-12">
                          <span className="remove-item pull-right"
                                onClick={() => fields.remove(index)}>
                              <Icon name="times"/>
                          </span>
                          <label className="control-label">{partner.id}</label>
                        </div>
                      </div>
                    </SortableItem>
                )
              })}
            </div>
          </div>
          <div>
            <SelectInsurer
              input={{ value: this.state.partner, onChange: (value, included) => { this.setState({ partner: value, included }) }, onBlur: (value, included) => { this.setState({ partner: value, included }) } }}
              labelKeys={['id']}
              object={true}
            />

            <Button type="button" disabled={!this.state.partner} handleClick={() => {
              fields.push(this.state.partner)
              this.setState({ user: null })
            }}>Add A Partner</Button>
          </div>
        </div>
    )
  }
}
export default connect()(Partner)
