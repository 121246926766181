import Axios from 'axios'
import { push } from 'react-router-redux'
import handleErrorResponse from '../../../helpers/handleErrorResponse'
import queryString from '../../../helpers/queryString'

const SET_TRACKING_LINK = 'socrates-online/trackingLinks/SET_TRACKING_LINK'

export const initialState = {
  isSubmitting: false,
  resources: {},
}

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_TRACKING_LINK:
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.data.data.id]: {
            ...action.data
          }
        }
      };
    default:
      return state
  }
}

const submitForm = (resource) => {
  return dispatch => {
    return Axios.post('brokers', resource).then(response => {
      dispatch(push('/admin/tracking-links/' + response.data.data.id)) //redirect to broker page
      return true
    }).catch(error => {
      handleErrorResponse(error, 'There was an error creating the broker')
    })
  }
}

const getTrackingLink = (id, includes = []) => {
  return (dispatch) => {
    let endpoint = '/brokers/' + id
    endpoint += queryString(includes)

    return Axios.get(endpoint).then(response => {
      dispatch({
        type: SET_TRACKING_LINK,
        data: response.data
      })
      return true
    }).catch(error => {
      console.error(error)
    })
  }
}

const deactivateTrackingLink = (id) => {
  return (dispatch) => {
    const endpoint = 'brokers/' + id + ':deactivate'

    return Axios.post(endpoint).then(() => {
      dispatch(push('admin/tracking-links/'))
    })
  }
}

const reactivateTrackingLink = (id) => {
  return (dispatch) => {
    const endpoint = 'brokers/' + id + ':reactivate'

    return Axios.post(endpoint).then(() => {
      dispatch(push('admin/tracking-links/'))
    })
  }
}

export const actions = {
  submitForm,
  getTrackingLink,
  deactivateTrackingLink,
  reactivateTrackingLink,
}
