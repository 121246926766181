import './style.scss';
import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { actions } from './redux/reducer';
import { bindActionCreators } from 'redux';
import { Col, Row } from 'react-bootstrap';
import { Box, Icon } from '../../common/components';
import InternalContainer from '../layout/containers/Internal';

class HelpCentre extends React.PureComponent {
  render() {
    return (
      <InternalContainer title={'Help Centre'} buttons={null}>
        <Box className="help-centre-container">
          <h3>
            <Icon name="question-circle" /> Need some help?
          </h3>
          <Row>
            <Col sm={4}>
              <Link to={'/help-centre/system-information'}>
                <div className="help-centre-btn">
                  <div className="icon">
                    <Icon name="bar-chart" />
                  </div>
                  <div className="title">System Information</div>
                  <div className="description">View basic information about the system</div>
                </div>
              </Link>
            </Col>
            <Col sm={4}>
              <Link to={'/help-centre/faqs'}>
                <div className="help-centre-btn">
                  <div className="icon">
                    <Icon name="question-circle" />
                  </div>
                  <div className="title">FAQs</div>
                  <div className="description">Commonly asked questions regarding the system</div>
                </div>
              </Link>
            </Col>
            <Col sm={4}>
              <a href={'https://www.socrates.support/'} target="_blank">
                <div className="help-centre-btn">
                  <div className="icon">
                    <Icon name="users" />
                  </div>
                  <div className="title">Support</div>
                  <div className="description">Socrates internal support system, open and view tickets</div>
                </div>
              </a>
            </Col>
          </Row>
        </Box>
      </InternalContainer>
    );
  }
}

export default connect(
  (state) => ({ helpCentre: state.helpCentre, auth: state.auth }),
  (dispatch) => ({ actions: bindActionCreators(actions, dispatch) }),
)(HelpCentre);
