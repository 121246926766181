import * as _ from 'lodash'

export function errorResponseToArray(res) {
  const errors = _.get(res.response, 'data.errors', [])
  const errorArray = []

  errors.map(error => {
    if (errorArray.indexOf(error.detail) === -1) {
      errorArray.push(error.detail)
    }
  })

  return errorArray
}

export default errorResponseToArray
