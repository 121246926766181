import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button } from '../../../../common/components'
import { deleteDeclaration, editDeclaration } from '../../redux/screeningActions'
import getIncludedResponse from '../../../../helpers/getIncludedResource'
import './styles.scss'

export default class DeclaredConditions extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    screening: PropTypes.object.isRequired
  }

  render() {
    const { screening, dispatch } = this.props
    const declaredConditions = getIncludedResponse(screening.declaredConditions.data, screening.declaredConditions.included, 'conditions') || []

    return (
      <div className="declared-conditions">
        {declaredConditions.length ? declaredConditions.map((declaration, declarationIndex) => {
          return (
            <div key={declarationIndex}>
              <div className="col-xs-12 condition">
                <div className="col-xs-8">
                  {declaration.attributes.conditions.map((condition, conditionIndex) => {
                    if (conditionIndex === 0) {
                      return (
                        <h4 key={conditionIndex} className="primary-condition">
                          {condition}
                        </h4>
                      )
                    }
                    return (
                      <span key={conditionIndex} className="linked-condition">{condition}</span>
                    )
                  })}
                </div>
                <div className="col-xs-4">
                  <Button
                    bsStyle="primary"
                    label="Remove"
                    size="sm"
                    className="pull-right"
                    isLoading={screening.isDeleting === declaration.id}
                    handleClick={() => dispatch(deleteDeclaration(declaration.id, declaration.attributes.conditions[0]))}
                  />
                  <Button
                    bsStyle="primary"
                    label="Edit"
                    size="sm"
                    className="pull-right pad-right"
                    isLoading={screening.isEditing === declaration.id}
                    handleClick={() => dispatch(editDeclaration(declaration.id))}
                  />
                </div>
              </div>
            </div>
          )
        }) : null}
      </div>
    )
  }
}
