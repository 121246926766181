import React, { Component } from 'react'
import ReactSelectComponent from 'react-select'

export default class ReactSelect extends Component {
  render() {
    const { value, onBlur, async, ...props } = this.props
    const Select = async ? ReactSelectComponent.Async : ReactSelectComponent

    return (
        <Select
            value={value || ''}          // because react-select doesn't like the initial value of undefined
            // just pass the current value (updated on change) on blur
            // onChange={(val, items) => onChange(items)}
            onBlur={() => onBlur ? onBlur(value) : null}
            autoBlur={true} // Auto close the dropdown after selection
            {...props} />
    )
  }
}
