import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Tabs, Tab } from 'react-bootstrap'
import { getPolicy, getResourceNotesCount } from '../redux/policyActions'
import { Button, Loader } from '../../../common/components'
import InternalContainer from '../../layout/containers/Internal'
import PolicyNotes from '../components/PolicyNotes'
import PolicyAudit from '../components/PolicyAudit'
import PolicyDetails from '../components/PolicyDetails'
import PolicyDocuments from '../components/PolicyDocuments'
import PolicyEndorsements from '../components/PolicyEndorsements'
import PolicyAccountsList from '../components/PolicyAccountsList'
import { hasPermission, isOwner } from '../../auth/redux/authActions'
import './styles/PolicyShow.scss'
import _ from 'lodash'

class PolicyShowContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    policy: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    dataTables: PropTypes.object
  }

  state = {
    activeTab: 1
  }

  componentWillMount() {
    const { params } = this.props
    this.props.dispatch(getPolicy(params.id, params.type === 'applications'))
      .then(() => {
        this.props.dispatch(getResourceNotesCount(params.id))
      })
  }

  handleTab(key) {
    this.setState({ activeTab: key })
  }

  render() {
    const { params, policy } = this.props
    const resource = policy.resources[params.id]
    let policyType = ''

    let ref = ''
    if (resource) {
      if (resource.data.attributes.policy_number) {
        ref = 'Policy number: ' + resource.data.attributes.policy_number + (resource.data.attributes.version > 1 ? ' (v' + resource.data.attributes.version + ')' : '')
        policyType = 'Policy'
      } else {
        ref = 'Quote Reference: ' + resource.data.attributes.quote_reference + '/' + resource.data.attributes.version
        policyType = 'Quote'

        if (_.get(resource, 'data.attributes.metadata.referrals') && _.get(resource, 'data.attributes.metadata.referrals',[]).length) {
          ref = 'Referral Reference: ' + resource.data.attributes.quote_reference
          policyType = 'Referral'
        }

        if (resource.data.attributes.renewing_policy_number) {
          ref += ' - Renewing Policy: ' + resource.data.attributes.renewing_policy_number
          policyType = 'Renewal'
        }
        if (resource.data.attributes.mta_policy_number) {
          ref += ' - MTA on Policy: ' + resource.data.attributes.mta_policy_number
          policyType = 'Mid Term Adjustment'
        }
      }
    }

    if (policyType) {
      policyType += ' Details'
    }

    const reference = !resource ? '' : ref

    const buttons = (
      <Button
        bsStyle="default"
        label="back"
        isBackButton={true}
        leftIcon="caret-left"
      />
    )

    const notesTitle = 'Notes' + (policy.notesCount > 0 ? ' (' + policy.notesCount + ')' : '');

    return (
      <InternalContainer title={policyType} buttons={buttons}>
        {resource ? (
          <div className="policy-nav-tabs shadow-box">
            <Tabs activeKey={this.state.activeTab}
                  id="policy-tabs"
                  unmountOnExit={true}
                  onSelect={::this.handleTab}
            >
              <Tab eventKey={1} title="Details">
                <PolicyDetails
                  {...this.props}
                  resource={resource}
                  reference={reference}
                />
              </Tab>
              <Tab eventKey={2} title="Documents">
                <PolicyDocuments
                  {...this.props}
                  isGeneratingDocs={policy.isGeneratingDocs}
                  resource={resource}
                  reference={reference}
                />
              </Tab>
              {resource.data.attributes.policy_number && hasPermission('policy.underwriter') && (
                <Tab eventKey={3} title="Manage Endorsements">
                  <PolicyEndorsements
                    {...this.props}
                    resource={resource}
                    reference={reference}
                  />
                </Tab>
              )}
              <Tab eventKey={4} title={notesTitle}>
                <PolicyNotes
                  {...this.props}
                  resource={resource}
                  reference={reference}
                />
              </Tab>
              {(isOwner && hasPermission('system.audit_logs_view')) && (
                <Tab eventKey={5} title="Audit">
                  <PolicyAudit
                    {...this.props}
                    resource={resource}
                    reference={reference}
                  />
                </Tab>
              )}
              {(isOwner() && hasPermission('account.view') && resource.data.attributes.policy_number) && (
                <Tab eventKey={6} title="Accounts">
                  <PolicyAccountsList
                    {...this.props}
                    resource={resource}
                    reference={reference}
                  />
                </Tab>
              )}
            </Tabs>
          </div>
        ) : (
          <Loader />
        )}
      </InternalContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    policy: state.policy,
    dataTables: state.dataTables
  }
}

export default connect(mapStateToProps)(PolicyShowContainer)
