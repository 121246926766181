import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Faq from './Faq.jsx'
import { initFaqs, openFaq, closeFaq } from './FaqRedux'

class FaqList extends Component {
  static propTypes = {
    faqs: PropTypes.array,
    dispatch: PropTypes.func.isRequired
  }

  componentWillMount() {
    this.props.dispatch(initFaqs())
  }

  handleClick(index) {
    if (this.props.faqs[index].state == 'open') {
      this.props.dispatch(closeFaq(index))
    } else {
      this.props.dispatch(openFaq(index))
    }
  }

  render() {
    const self = this
    return (
        <div>
          { this.props.faqs ? (this.props.faqs.map(function (faq, index) {
                return
                <Faq key={index} question={faq.q} answer={faq.a} open={faq.state == 'open'}
                     handleClick={self.handleClick.bind(self, index)}/>
              })) : ('') }
        </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    faqs: Array.isArray(state.faq.values) ? state.faq.values : []
  }
}

export default connect(mapStateToProps)(FaqList)
