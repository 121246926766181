import React from 'react'

export const mta = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95 95">
    <title>Icons</title>
    <path d="M69.74,18.92a6.2,6.2,0,0,1,4.55,1.87,6,6,0,0,1,1.87,4.4v51a6,6,0,0,1-1.87,4.4,6.2,6.2,0,0,1-4.55,1.87H25.26a6.2,6.2,0,0,1-4.55-1.87,6,6,0,0,1-1.87-4.4v-51a6,6,0,0,1,1.87-4.4,6.2,6.2,0,0,1,4.55-1.87H38.54a9.79,9.79,0,0,1,3.51-4.63,9.18,9.18,0,0,1,10.9,0,9.8,9.8,0,0,1,3.51,4.63Zm0,57.31v-51H63.47v9.55H31.53V25.19H25.26v51Zm-20-56.42a3.25,3.25,0,1,0,.9,2.24A3,3,0,0,0,49.74,19.81Z"/>
    <path d="M56,52.44,41.2,67.21H33.82V59.83L48.58,45.06ZM41.87,63.32,37.7,59.15l-1.62,1.61v1.9h2.27v2.27h1.9Zm7.28-15.24a.41.41,0,0,0-.3.12l-9.62,9.62a.42.42,0,0,0-.12.3.37.37,0,0,0,.39.39.42.42,0,0,0,.3-.12l9.62-9.62a.42.42,0,0,0,.12-.3A.37.37,0,0,0,49.15,48.08Zm10.9.28L57.1,51.31l-7.38-7.38L52.66,41a2.22,2.22,0,0,1,1.6-.67,2.3,2.3,0,0,1,1.62.67L60,45.15a2.36,2.36,0,0,1,.66,1.61A2.28,2.28,0,0,1,60,48.36Z"/>
  </svg>
)

export default mta
