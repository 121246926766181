import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Tabs, Tab, Label } from 'react-bootstrap'
import { Loader, Box } from '../../../common/components'
import InternalContainer from '../../layout/containers/Internal'
import SchemeDetailsFormContainer from './SchemeDetailsFormContainer'
import SchemeOptionsContainer from './SchemeOptionsContainer'
import SchemeBenefitsContainer from './SchemeBenefitsContainer'
import SchemeNotes from '../components/SchemeNotes'
import SchemeAudit from '../components/SchemeAudit'
import { getScheme, clearData } from '../redux/schemeActions'
import displayName from '../../../helpers/displayName'
import _ from 'lodash'

export class SchemeEditContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    scheme: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context)

    this.state = {
      activeTab: 1
    }
  }

  componentWillMount() {
    this.props.dispatch(getScheme(this.props.params.id))
  }

  /**
   * Clean out the data when the component unmounts
   */
  componentWillUnmount() {
    this.props.dispatch(clearData())
  }

  handleTab(key) {
    this.setState({ activeTab: key })
  }

  render() {
    const { scheme: { isLoading, scheme }, dispatch } = this.props
    const title = (scheme.data && scheme.data.id === this.props.params.id) ? scheme.data.attributes.name : 'Manage'

    const schemeStatus = _.get(scheme, 'data.attributes.status', 'test')
    const labelStyle = () => {
      if (schemeStatus === 'test') {
        return 'warning'
      }
      if (schemeStatus === 'active') {
        return 'success'
      }
      if (schemeStatus === 'ended' || schemeStatus == 'inactive') {
        return 'danger'
      }
    }
    const schemeLabel = (<Label className="pull-right" bsStyle={labelStyle()}>{ displayName(schemeStatus) }</Label>)

    return (
      <InternalContainer title={title + ' Scheme'}>
        {(isLoading || !scheme.data) ? (
          <Loader />
        ) : (
          <Tabs activeKey={this.state.activeTab}
                id="scheme-tabs"
                unmountOnExit={true}
                onSelect={::this.handleTab}
                className='shadow-box'>
            <Tab eventKey={1} title="Details and Rates">
              <Box>
                <SchemeDetailsFormContainer
                  productId={this.props.params.productId}
                  scheme={this.props.scheme}
                  schemeStatus={schemeStatus}
                  schemeLabel={schemeLabel}
                />
              </Box>
            </Tab>
            <Tab eventKey={2} title="Options">
              <Box>
                <SchemeOptionsContainer
                  scheme={scheme}
                  productId={this.props.params.productId}
                  schemeStatus={schemeStatus}
                  schemeLabel={schemeLabel}
                />
              </Box>
            </Tab>
            <Tab eventKey={3} title="Benefits">
              <Box>
                <SchemeBenefitsContainer
                  scheme={scheme}
                  productId={this.props.params.productId}
                  schemeStatus={schemeStatus}
                  schemeLabel={schemeLabel}
                />
              </Box>
            </Tab>
            <Tab eventKey={4} title="Notes">
              <SchemeNotes
                resource={scheme}
                dispatch={dispatch}
                schemeStatus={schemeStatus}
                schemeLabel={schemeLabel}
              />
            </Tab>
            <Tab eventKey={5} title="Audit">
              <SchemeAudit
                resource={scheme}
                dispatch={dispatch}
                schemeStatus={schemeStatus}
                schemeLabel={schemeLabel}
              />
            </Tab>
          </Tabs>
        )}
      </InternalContainer>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    scheme: state.scheme
  }
}

export default connect(mapStateToProps)(SchemeEditContainer)
