import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { Button, Modal, DatePicker } from '../../../../common/components'
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux'
import { endScheme } from '../../redux/schemeActions'
// import _ from 'lodash'
import './styles.scss'

const FORM_NAME = 'endSchemeForm'

// const validate = values => {
//   const errors = {}
//
//   if (!_.get(values, 'meta.date')) {
//     _.set(errors, 'meta.date', 'End date is required')
//   }
//   return errors
// }

class EndSchemeButton extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    callback: PropTypes.func,
    label: PropTypes.string
  };

  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded, callback } = this.props
    if (newProps.submitSucceeded && !submitSucceeded) {
      if (callback) {
        callback('')
      }
      dispatch(closeModal(FORM_NAME))
    }
  }

  handleSubmit(values) {
    return this.props.dispatch(endScheme(values, this.props.resource, this.props.label != 'Deactivate Scheme'))
  }

  handleOpen() {
    const { dispatch, reset } = this.props
    dispatch(reset())
    dispatch(openModal(FORM_NAME))
  }

  render() {
    const { label, handleSubmit, submitting } = this.props
    const title = label || 'Deactivate Scheme'
    const submitButton = (<Button
        type="submit"
        bsStyle="primary"
        isLoading={submitting}
        label={title == 'Deactivate Scheme' ? 'Deactivate' : 'End'}
    />)


    return (
        <div className={'deactivate-scheme-btn-wrapper ' + this.props.className}>
          <Button
              bsStyle="primary"
              label={title}
              isLoading={submitting}
              rightIcon="ban"
              handleClick={::this.handleOpen}
          />

          <Modal
              name={FORM_NAME}
              title={title}
              close={true}
              handleSubmit={handleSubmit(::this.handleSubmit)}
              footer={submitButton}
          >
          <div className="form-horizontal">
            {title == 'End Scheme' ? (
              <div className="col-xs-12">
                  <p>Please select an end date for this scheme</p>

                  <Field
                      name={'meta.date'}
                      label="End date"
                      labelSize={4}
                      component={DatePicker}
                  />
                  <Field
                    name={'meta.quotes_valid_until'}
                    label="Quotes Valid Until"
                    labelSize={4}
                    component={DatePicker}
                  />
                </div>
            ) : (
              <div className="col-xs-12">
                <p>Are you sure you want to deactivate this scheme?</p>
              </div>
            )}
            </div>
          </Modal>
        </div>
    )
  }
}

const form = reduxForm({ form: FORM_NAME })(EndSchemeButton)
export default connect()(form)
