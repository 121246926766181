import React, { Component } from 'react'
import './styles.scss'
import * as _ from 'lodash';
import { connect } from 'react-redux'
import { Col, Row } from 'react-bootstrap';
import { Field, formValueSelector, reduxForm } from 'redux-form'
import { Box, HorizontalFormControl } from '../../../../../../common/components'
import SelectBrokerCategory from '../../../../../../common/components/Selects/SelectBrokerCategory'
import CheckboxUnlabeled from '../../../../../../common/components/ReduxFormField/CheckboxUnlabeled'
import SelectLegalEntityType from '../../../../../../common/components/Selects/SelectLegalEntityType'

const ATTRIBUTES = 'data.attributes.organisation'

class Company extends Component {
  constructor(props) {
    super(props)

    this.state = {
      legalEntity: null,
      disableFields: false
    }
  }

  componentDidMount() {
    const { currentOrganisation } = this.props

    let legalEntity = null
    let disableFields = false

    const organisationId = _.get(currentOrganisation, 'id')
    if (organisationId) {
      disableFields = true
    }

    this.setState({
      legalEntity: legalEntity,
      disableFields: disableFields
    })
  }

  render () {
    const { formValues } = this.props
    const selectOrganisation = _.get(formValues, 'data.attributes.metadata.select_organisation')
    return (
      <Box>
        <Row>
          <Col sm={12}>
            <h4 className='page-title'>Company Information</h4>
            <hr/>
          </Col>
          <Col sm={6}>
            <div className='form-horizontal'>
              <Field
                labelSize={4}
                object={true}
                mdFieldSize={8}
                label="Legal Entity Type"
                placeholder={'please select'}
                disabled={selectOrganisation}
                component={SelectLegalEntityType}
                name="data.relationships.legal_entity_type.data"
                defaultSelected={ this.state.legalEntity ? { value: this.state.legalEntity } : false }
              />
              <Field
                labelSize={4}
                object={true}
                mdFieldSize={8}
                placeholder={'please select'}
                label="Classification of Firm"
                component={SelectBrokerCategory}
                name="data.relationships.broker_category.data"
              />
              <Field
                labelSize={4}
                mdFieldSize={4}
                label='FCA Number'
                disabled={selectOrganisation}
                component={HorizontalFormControl}
                name={`${ATTRIBUTES}.regulatory_authority_number`}
              />
            </div>
          </Col>
          <Col sm={6}>
            <div className='form-horizontal'>
              <Field
                labelSize={4}
                mdFieldSize={4}
                label='Company Number'
                disabled={selectOrganisation}
                component={HorizontalFormControl}
                name={`${ATTRIBUTES}.legal_entity_number`}
              />
            </div>
          </Col>
          <Col sm={12}>
            <div className='form-horizontal'>
              <Field
                inline
                positionTop
                labelSize={5}
                component={CheckboxUnlabeled}
                label='Do you belong to a parent company?'
                name={`${ATTRIBUTES}.has_parent_company`}
                options={[
                  { label: '', value: true }
                ]}
              />
            </div>
          </Col>
          {hasParentCompany && (
            <Col sm={6}>
              <div className='form-horizontal'>
                <Field
                  labelSize={4}
                  label='Parent company'
                  component={HorizontalFormControl}
                  name={`${ATTRIBUTES}.parent_company.name`}
                />
                <Field
                  labelSize={4}
                  mdFieldSize={4}
                  label='FCA Number'
                  disabled={selectOrganisation}
                  component={HorizontalFormControl}
                  name={`${ATTRIBUTES}.parent_company.regulatory_authority_number`}
                />
              </div>
            </Col>
          )}
        </Row>
      </Box>
    )

    const hasParentCompany = _.get(formValues, 'data.attributes.organisation.has_parent_company')
  }
}

const form = reduxForm()(Company)
const mapStateToProps = (state, props) => {
  const values = (formValueSelector(props.form))(state,
    'data',
    'data.relationships.organisation.data.id'
  )

  return {
    auth: state.auth,
    initialValues: props.resource,
    form: props.form,
    formValues: values,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    product: state.product,
    currentOrganisation: state.organisation.currentOrganisation
  }
}

export default connect(mapStateToProps)(form)
