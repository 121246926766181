import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'
import { Box, DataTable, SelectBroker, SelectUser, Button } from '../../../../common/components'
import { applyFilter, fetchData } from '../../../../common/components/DataTable/redux/dataTableActions'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { FormControl, ControlLabel, FormGroup, Checkbox } from 'react-bootstrap'
import ProductFilter from '../ProductFilter'
import { FormattedDate } from 'react-intl'
import { inviteRenewalsArray } from '../../redux/policyActions'
import { Link } from 'react-router'

class UninvitedRenewalList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props)

    this.state = {
      brokerId: '',
      userId: '',
      items: [],
      render: false,
      checkAll: undefined,
      listDataFromChild: null,
      _dataTable: [],
      allRows: [],
      hideExpiredRenewals: true
    }
    this.onClickProductSelected = this.onClickProductSelected.bind(this)
    this.select = this.select.bind(this)
    this.handleInvites = this.handleInvites.bind(this)
    this.selectAll = this.selectAll.bind(this)
    this.linkToProduct = this.linkToProduct.bind(this)
  }

  formatDate(cell) {
    return (
      <span><FormattedDate value={new Date(cell)}/></span>
    )
  }

  formatYesNo(cell) {
    return (
      <span>{cell ? 'YES' : 'no'}</span>
    )
  }

  formatPercentage(cell) {
    return (
      <span>{cell}%</span>
    )
  }

  // Select All Button
  selectAll(row) {
    const {dataTables} = this.props
    const allData = []

    const _allData = _.get(
      this.props.dataTables,
      'tables.uninvitedRenewals.data',
      {}
    )
    _.map(_allData, (item) => {
      allData.push(item.id)
    })

    if (this.state.checkAll === undefined) {
      this.setState({
        checkAll: true,
        items: allData
      })
    } else {
      this.setState({
        checkAll: undefined,
        items: []
      })
    }
  }

  // Checkbox
  select(cell, row, enumObject, rowIndex) {
    return (
      <span>
        <input
          checked={this.state.checkAll ? true : undefined}
          id="checkBox"
          type="checkbox"
          onClick={() => {
            this.onClickProductSelected(cell, row, rowIndex)
          }}
        />
      </span>
    )
  }

  // Checkbox Event
  onClickProductSelected(cell, row, rowIndex) {
    let newArr = this.state.items
    // If element is already on the array remove it, otherwise push to this.state
    if (_.indexOf(this.state.items, row.id) !== -1) {
      _.pull(newArr, row.id)
      this.setState({
        items: newArr
      })
    } else {
      this.setState(prevState => ({
        items: [...prevState.items, row.id]
      }))
    }
  }

  // button: Invite Selected
  handleInvites() {
    const arrayOfApplicationsIds = this.state.items

    Promise.resolve(this.props.dispatch(inviteRenewalsArray(arrayOfApplicationsIds)))
      .then(() => {
          this.props.dispatch(fetchData('uninvitedRenewals'))
          this.setState({
            items: []
          })
        }
      )
  }

  hideExpiredRenewals() {
    const todaysDate = new Date();
    const todaysDateFormatted = todaysDate.getFullYear() + '-' + (todaysDate.getMonth() + 1) + '-' + todaysDate.getDate();

    this.setState({ hideExpiredRenewals: !this.state.hideExpiredRenewals }, () => {
      if (this.state.hideExpiredRenewals) {
        this.props.dispatch(applyFilter('uninvitedRenewals', 'filter[starts_at:from]', todaysDateFormatted))
      } else {
        this.props.dispatch(applyFilter('uninvitedRenewals', 'filter[starts_at:from]', false))
      }
    });
  }

  handleFilterUpdate(event) {
    this.props.dispatch(applyFilter('uninvitedRenewals', 'filter[product]', event.target.value))
  }

  handleRowClick(cell, row, rowIndex) {
    // this.props.dispatch(push('/policies/applications/' + row.id))
  }

  linkToProduct(cell, row) {

    return <Link to={'/policies/applications/' + row.id} target="_blank"> {cell} </Link>
  }

  handleSearchUpdate(event) {
    this.props.dispatch(applyFilter('uninvitedRenewals', 'filter[quote.reference]', event.target.value))
  }

  handlePolicyUpdate(event) {
    this.props.dispatch(applyFilter('uninvitedRenewals', 'filter[policy.number]', event.target.value))
  }

  handleBrokerUpdate(value) {
    this.setState({ brokerId: value })
    this.props.dispatch(applyFilter('uninvitedRenewals', 'filter[broker]', value))
  }

  handleUserUpdate(value) {
    this.setState({ userId: value })
    this.props.dispatch(applyFilter('uninvitedRenewals', 'filter[user]', value))
  }

  render() {

    const todaysDate = new Date();
    const todaysDateFormatted = todaysDate.getFullYear() + '-' + (todaysDate.getMonth() + 1) + '-' + todaysDate.getDate();

    return (
      <div>
        <Box>
          <div className="row">
            <div className="col-sm-3">
              <ControlLabel className="filter-label">Quote Reference</ControlLabel>
              <FormControl onChange={::this.handleSearchUpdate}/>
            </div>
            <div className="col-sm-3">
              <ControlLabel className="filter-label">Policy Number</ControlLabel>
              <FormControl onChange={::this.handlePolicyUpdate}/>
            </div>
            <div className="col-sm-3">
              <ControlLabel className="filter-label">Broker</ControlLabel>
              <SelectBroker
                labelKeys={['attributes.name']}
                input={{
                  onChange: ::this.handleBrokerUpdate,
                  value: this.state.brokerId
                }}/>
            </div>
            <div className="col-sm-3">
              <ControlLabel className="filter-label">User</ControlLabel>
              <SelectUser
                labelKeys={['attributes.first_name', 'attributes.last_name']}
                input={{
                  onChange: ::this.handleUserUpdate,
                  value: this.state.userId
                }}/>
            </div>
            <div className="col-sm-3">
              <ControlLabel className="filter-label">Product</ControlLabel>
              <ProductFilter onChange={::this.handleFilterUpdate}/>
            </div>
            <div className="col-sm-3">
              <FormGroup>
                <Checkbox className="styled-checkbox m-t-30" onChange={::this.hideExpiredRenewals} checked={this.state.hideExpiredRenewals}><span>Hide Expired Renewals</span></Checkbox>
              </FormGroup>
            </div>
          </div>
        </Box>

        <Box>
          <DataTable
            source="/policies/applications"
            name="uninvitedRenewals"
            showTotal={true}
            hover={true}
            autoFilters={[
              { type: 'filter[status]', value: 'renewal' },
              { type: 'filter[quote.type]', value: 'renewal' },
              { type: 'filter[user]', value: this.state.userId },
              { type: 'sort', value: 'starts_at' },
              { type: 'filter[starts_at:from]', value: todaysDateFormatted },
            ]}
            onRowSelect={this.handleRowClick.bind(this)} // Highlighting function
          >
            <TableHeaderColumn dataField="id" isKey={true} hidden={true} onSelect={this.handleRowClick.bind(this)
            }>
              ID
            </TableHeaderColumn>

            <TableHeaderColumn dataField="renewing_policy_number" width={'10%'} dataFormat={this.linkToProduct}>
              Policy Number
            </TableHeaderColumn>

            <TableHeaderColumn dataField="insured" width={'10%'}>
              Insured
            </TableHeaderColumn>

            <TableHeaderColumn dataField="broker_name" width={'10%'}>
              Broker
            </TableHeaderColumn>

            <TableHeaderColumn dataField="has_medical" dataFormat={this.formatYesNo} width={'10%'}>
              Screened
            </TableHeaderColumn>

            <TableHeaderColumn dataField="has_mta" dataFormat={this.formatYesNo} width={'10%'}>
              MTA
            </TableHeaderColumn>

            <TableHeaderColumn dataField="has_endorsements" dataFormat={this.formatYesNo} width={'10%'}>
              Endorsement
            </TableHeaderColumn>

            <TableHeaderColumn dataField="has_override" dataFormat={this.formatYesNo} width={'10%'}>
              Override
            </TableHeaderColumn>

            <TableHeaderColumn dataField="renewal_premium_change" dataFormat={this.formatPercentage} width={'10%'}>
              % Change
            </TableHeaderColumn>

            <TableHeaderColumn dataField="id" dataFormat={this.select} width={'10%'}>
              Select
            </TableHeaderColumn>

            <TableHeaderColumn dataField="starts_at" dataFormat={this.formatDate} width={'10%'}>
              Renewal Date
            </TableHeaderColumn>
          </DataTable>

          <div className='invite-button'>
            <div>
              <Button
                bsStyle="primary"
                label="invite selected"
                handleClick={() => this.handleInvites(this.state.items)}
              >Invite Selected
              </Button>
            </div>
            <div style={{ paddingLeft: 10 }}>
              <Button
                bsStyle="default"
                label="Render"
                handleClick={this.selectAll}
              >
                {this.state.checkAll === undefined ? 'Select All' : 'Deselect All'}
              </Button>
            </div>
          </div>
        </Box>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    dataTables: state.dataTables
  }
}

export default connect(mapStateToProps)(UninvitedRenewalList)
