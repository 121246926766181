import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { AddressFields } from '../../../../common/components/ReduxFormField'
import _ from 'lodash'
import PostcodeList from '../../../../common/components/Postcode/PostcodeList'
import {
  Alert,
  Box,
  Button,
  ButtonBar,
  HorizontalFormControl,
  NameFields,
  DatePicker
} from '../../../../common/components'
import { hasPermission } from '../../../auth/redux/authActions'

const FORM_NAME = 'customerForm'

class CustomerForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    submitting: PropTypes.bool.isRequired,
    resource: PropTypes.object
  };

  render() {
    const { formValues, handleSubmit, submitting } = this.props
    const resource = this.props.resource ? this.props.resource.data.attributes : {}

    return (
        <Box>
          <h2 className="resource-name">{ this.props.resource ? (resource.title + ' ' + resource.first_name + ' ' + resource.last_name) : 'New customer' }</h2>

          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-xs-12">
                <Alert />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-horizontal">
                  <NameFields
                      baseName="data.attributes"
                      label="Name"
                      labelSize={3}
                      fieldComponent={Field}
                  />

                  <Field
                      name="data.attributes.dob"
                      showYear={true}
                      label="Date of birth"
                      labelSize={3}
                      component={DatePicker}
                  />

                  <Field
                      name="data.attributes.email"
                      label="Email"
                      labelSize={3}
                      component={HorizontalFormControl}
                  />

                  <Field
                      name="data.attributes.phone1"
                      label="Phone 1"
                      labelSize={3}
                      component={HorizontalFormControl}
                  />

                  <Field
                      name="data.attributes.phone2"
                      label="Phone 2"
                      labelSize={3}
                      component={HorizontalFormControl}
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <div className="form-horizontal">
                  <div className="col-xs-12">
                    <PostcodeList
                        name="data.attributes.address.postcode"
                        label="Postcode"
                        labelSize={3}
                        change={this.props.change}
                        value={_.get(formValues, 'data.attributes.address.postcode', '')}
                        targetFields={{
                          organisation: 'data.attributes.name',
                          line1: 'data.attributes.address.line1',
                          line2: 'data.attributes.address.line2',
                          line3: 'data.attributes.address.line3',
                          town: 'data.attributes.address.town',
                          county: 'data.attributes.address.county',
                          postcode: 'data.attributes.address.postcode'
                        }}
                    />
                  </div>
                  <div className="col-xs-12">
                    <AddressFields
                        baseName="data.attributes.address"
                        labelSize={3}
                        label="Address"
                    />
                  </div>
                </div>
              </div>
            </div>

            <ButtonBar>
              { hasPermission('customer.edit') && (
                  <Button
                      className="pull-right"
                      type="submit"
                      bsStyle="primary"
                      label="Save Customer"
                      isLoading={submitting}
                  />
              )}
            </ButtonBar>
          </form>
        </Box>
    )
  }
}

const form = reduxForm({ form: FORM_NAME })(CustomerForm)
const selector = formValueSelector(FORM_NAME)
const mapStateToProps = (state, props) => {
  const values = selector(state,
      'data.id',
      'data.attributes.address.postcode'
  )

  return {
    initialValues: props.resource,
    formValues: values
  }
}

export default connect(mapStateToProps)(form)
