import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { passwordReminder } from '../redux/authActions'
import PasswordReminderForm from '../components/PasswordReminderForm/PasswordReminderForm'

class PasswordReminderContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };

  handleSubmit(values) {
    return this.props.dispatch(passwordReminder(values.email))
  }

  render() {
    return (
        <PasswordReminderForm onSubmit={this.handleSubmit.bind(this)}/>
    )
  }
}

export default connect()(PasswordReminderContainer)
