import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Image } from 'react-bootstrap'
import getIncludedResource from '../../../helpers/getIncludedResource'
import * as _ from 'lodash'
import './Avatar.scss'

const Avatar = ({ user, url }) => {
  let image = require('images/avatars/user_default.svg')

  if (url) {
    image = url
  } else {
    const avatar = getIncludedResource(user.data, user.included, 'avatar')
    const avatarUrl = _.get(avatar, 'data.attributes.url')
    if (avatarUrl) {
      image = avatar.data.attributes.url
    }
  }

  return (
      <Image
          src={image}
          className="avatar"
          circle
      />
  )
}

Avatar.propTypes = {
  user: PropTypes.object
}

export default Avatar
