import React from 'react'
import { Box, Button, DataTable } from '../../../common/components'
import { ControlLabel, FormControl, Row, Col } from 'react-bootstrap'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import { applyFilter } from '../../../common/components/DataTable/redux/dataTableActions';
import { TableHeaderColumn } from 'react-bootstrap-table'

class TrackingLinks extends React.Component {
  handleRowClick = (row) => {
    const { dispatch } = this.props
    dispatch(push('/admin/tracking-links/' + row.id))
  }

  handleSearchUpdate(event) {
    this.props.dispatch(applyFilter('trackingLinks', 'filter[name]', event.target.value))
  }

  render () {
    return (
      <div>
        <Box>
          <Row>
            <Col sm={4}>
              <ControlLabel  className="filter-label">Search</ControlLabel>
              <FormControl onChange={ ::this.handleSearchUpdate }/>
            </Col>
          </Row>
        </Box>
      <Box>
        <DataTable
          source="/brokers"
          autoFilter={'tracking'}
          autoFilterType="filter[broker_type]"
          name="trackingLinks"
          showTotal={true}
          onRowSelect={this.handleRowClick}
        >
          <TableHeaderColumn dataField="id" isKey={true} hidden={true}>ID</TableHeaderColumn>
          <TableHeaderColumn dataField="trading_name" dataSort={true} width={'380px'}>Name</TableHeaderColumn>
          <TableHeaderColumn dataField="status" dataSort={false} width={'100px'}>Status</TableHeaderColumn>
        </DataTable>

        <div className="row actions">
          <Button
            link={true}
            to="/admin/tracking-links/new"
            className="pull-right"
            bsStyle="primary"
            label="new tracking link"
          />
        </div>
      </Box>
      </div>
    )
  }
}

export default connect()(TrackingLinks)
