import React, { Component } from 'react'
import { Field, FieldArray } from 'redux-form'
import {
  Button,
  HorizontalFormControl,
  InfoLabel,
  Icon
} from '../../../../common/components'
import { SelectField } from '../../../../common/components/ReduxFormField'
import { Options as SubOptions } from './Options'
import * as _ from 'lodash'
import './styles.scss'

export class Options extends Component {
  render() {
    const { fields, items, editable, subOptions, disableButtons, disableFields } = this.props

    const rateTypes = [
      { label: 'Fixed', value: 'fixed' },
      { label: 'Loading', value: 'loading' },
      { label: 'Ancillary Scheme', value: 'ancillary_scheme' }
    ]

    return (
      <div className={subOptions ? 'sub-options' : 'options'}>
        {fields.map((baseName, index) => {
            let rate = _.get(items, '[' + index + '].rate')
            let isRateString = false;
            if (rate) {
              isRateString = rate.indexOf('$') === 0;
            }
            return (
              <div key={index} className="form-horizontal form-section">
                <div
                  className={subOptions ? 'col-sm-12 sub-option' : 'col-sm-12 scheme-option-row'}>
                  {editable && !disableButtons && (
                    <span className="remove-option" onClick={() => fields.remove(index)}
                          title={subOptions ? 'remove sub-option' : 'remove option'}>
                    <Icon name={subOptions ? 'times' : 'trash'}/>
                  </span>
                  )}
                  <div className="row">
                    {subOptions ?
                      <div className="col-sm-6">
                        <Field
                          name={baseName + '.key'}
                          label="Key"
                          labelSize={4}
                          component={InfoLabel}
                        />
                      </div> :
                      <div className="col-sm-6">
                        <Field
                          name={baseName + '.name'}
                          label="Name"
                          labelSize={4}
                          component={editable && !disableFields ? HorizontalFormControl : InfoLabel}
                        />
                      </div>
                    }
                    <div className="col-sm-6">
                      {!subOptions ? (
                        <Field
                          name={baseName + '.rate_type'}
                          label="Rate Type"
                          labelSize={4}
                          options={rateTypes}
                          component={editable && !disableFields ? SelectField : InfoLabel}
                        />
                      ) : (
                        <Field
                          name={baseName + '.rate'}
                          label="Rate"
                          labelSize={4}
                          component={editable && !isRateString ? HorizontalFormControl : InfoLabel}
                        />
                      )}
                    </div>
                  </div>

                  <div className="row">
                    {(_.get(items, '[' + index + '].rate_type') != 'ancillary_scheme' && _.get(items, '[' + index + '].input') != 'enum' && !subOptions) && (
                      <div>
                        <div className="col-sm-6">
                          <Field
                            name={baseName + '.rate'}
                            label="Rate"
                            labelSize={4}
                            component={editable && !isRateString ? HorizontalFormControl : InfoLabel}
                          />
                        </div>
                        <div className="col-sm-6">
                          <Field
                            name={baseName + '.multiplier'}
                            label="Multiplier"
                            labelSize={4}
                            component={editable && !disableFields ? HorizontalFormControl : InfoLabel}
                          />
                        </div>
                      </div>
                    )}

                    {_.get(items, '[' + index + '].rate_type') === 'ancillary_scheme' && (
                      <div className="col-sm-6">
                        <InfoLabel
                          label="Scheme"
                          labelSize={4}
                          value={_.get(items, '[' + index + '].name')}
                        />
                      </div>
                    )}
                  </div>

                  {!subOptions && (
                    <div className="row">
                      <FieldArray
                        name={baseName + '.options'}
                        items={_.get(items, '[' + index + '].options', [])}
                        subOptions={true}
                        editable={editable}
                        disableButtons={disableButtons}
                        component={SubOptions}
                      />
                    </div>
                  )}

                </div>
              </div>
            )
          }
        )}

        {editable && !disableButtons && (
          <div className="actions">
            <Button type="button" bsStyle={subOptions ? 'default' : 'primary'}
                    className={subOptions ? 'pull-right' : ''}
                    handleClick={() => fields.push({
                      name: '',
                      rate_type: '',
                      rate: '',
                      multiplier: '',
                      options: []
                    })}>{subOptions ? 'Add Sub-Option' : 'Add New Option'}</Button>
          </div>
        )}
      </div>
    )
  }
}

export default Options
