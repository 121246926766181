import Axios from 'axios'
import { SET_USER, LOGIN, LOGIN_SUCCESS, LOGIN_ERROR, UNAUTHENTICATE, LOGOUT, IS_SWITCHING_ORGANISATION } from './authReducer'
import { push } from 'react-router-redux'
import { closeModal } from '../../../common/components/Modal/ModalRedux'
import queryString from '../../../helpers/queryString'
import { doNotUseThisStore } from '../../../redux/configureStore'
import { setAlert } from '../../../common/components/Alert/AlertRedux'
import handleErrorResponse from '../../../helpers/handleErrorResponse'
import getIncludedResource from '../../../helpers/getIncludedResource'
import { toastr } from 'react-redux-toastr'
import * as _ from 'lodash'

export const defaultUserIncludes = [
  'roles', 'permissions', 'current_organisation', 'current_broker.active_commissions',
  'current_broker.active_commissions.product', 'available_products.active_schemes.documents',
  'available_products.active_schemes.insurer', 'avatar', 'organisations', 'brokers'
]

/**
 * Sends the set user event top the reducer
 * @param user
 * @returns {{type, user: *}}
 */
export function setUser(user) {
  return {
    type: SET_USER,
    user
  }
}

/**
 * Fetches a user by the given id
 * @param id If null, will fetch currently authenticated user.
 * @param includes
 */
export function getUser(id = null, includes = defaultUserIncludes, accessToken = null) {
  return (dispatch) => {
    let endpoint = id ? 'users/' + id : 'user'
    endpoint += queryString(includes)

    let config = undefined;

    if (accessToken) {
      config = { headers: { Authorization: 'Bearer ' + accessToken } }
    }

    return Axios.get(endpoint, config).then(response => {
      return dispatch(setUser(response.data))
    }).catch(error => {
      console.error(error)
    })
  }
}

export function setAccessToken(accessToken) {
  return {
    type: LOGIN_SUCCESS,
    token: accessToken
  }
}

export function getUserAndSetAccessToken(accessToken) {
  return dispatch => {
    return dispatch(getUser(null, defaultUserIncludes, accessToken))
      .then(() => {
        return dispatch(setAccessToken(accessToken))
      })
  }
}

/**
 * Checks if the user has a given permission
 * @param name
 * @returns boolean
 */
export function hasPermission(name) {
  const user = doNotUseThisStore.getState().auth.user
  const permissions = getIncludedResource(user.data, user.included, 'permissions')
  let found = false

  if (Array.isArray(permissions)) {
    permissions.map(permission => {
      const permissionName = permission.attributes.category + '.' + permission.attributes.action
      if (permissionName === name) {
        found = true
      }
    })
  }

  return found
}

/**
 * Checks if a user has a given role
 * @param name
 * @returns boolean
 */
export function hasRole(name) {
  const user = doNotUseThisStore.getState().auth.user
  const roles = getIncludedResource(user.data, user.included, 'roles')
  let found = false

  if (Array.isArray(roles)) {
    roles.data.map((data) => {
      if (data.type = 'organisations' && data.attributes.name === name) {
        found = true
      }
    })
  }

  return found
}

/**
 * Return authenticated user id
 */
export function authUserId() {
  const user = doNotUseThisStore.getState().auth.user
  return _.get(user, 'data.id')
}

/**
 * Check if the user is a broker
 * @returns boolean
 */
export function isBroker() {
  const user = doNotUseThisStore.getState().auth.user
  return _.get(user, 'data.relationships.current_broker.data.id', null) !== null
}

/**
 * Check if the broker can put premiums on the broker account
 * @returns {boolean}
 */
export function brokerAccountAllowed() {
  const user = doNotUseThisStore.getState().auth.user
  const broker = _.get(user, 'data.relationships.current_broker.data.attributes', null)

  if (broker === null) {
    return true
  }

  if (!broker.can_transact) {
    return false
  } else if (!broker.hold_client_money) {
    return false
  }

  return true
}

/**
 * Check if the user is an owner
 * @returns boolean
 */
export function isOwner() {
  return currentOrganisationType() === 'Owner' || currentOrganisationType() === 'System Administration'
}

/**
 * Grant Permission
 * Item must be an object with either/or properties arrays orgTypes and permissions
 *
 * @param item
 * @returns {boolean}
 */
export function grantPermission(item) {
  let permission = true;
  if (item.orgTypes) {
    permission = false;
    item.orgTypes.forEach((oT) => {
      switch (oT) {
        case 'owner':
          permission = permission || isOwner();
          break;
        case 'broker':
          permission = permission || isBroker();
          break;
        case 'handler':
          permission = permission || isHandler();
          break;
        case 'insurer':
          permission = permission || isInsurer();
          break;
      }
    });
    if (!permission) {
      return permission;
    }
  }

  if (item.permissions) {
    permission = false;
    item.permissions.forEach((p) => {
      if (hasPermission(p)) {
        permission = true;
      }
    });
  }

  return permission;
}

export function hasReferralPermission(level) {
  const user = doNotUseThisStore.getState().auth.user;
  const userMaxRefLimit = _.get(user, 'data.attributes.max_referral_limit');
  return userMaxRefLimit >= level;
}


/**
 * Get the user's organisation type
 * @returns string
 */
export function currentOrganisationType() {
  const user = doNotUseThisStore.getState().auth.user
  const organisation = getIncludedResource(user.data, user.included, 'current_organisation')
  return _.get(organisation, 'data.attributes.organisation_type_name', '')
}

/**
 * Save a users settings
 * @param resource
 * @param includes
 * @returns {function()}
 */
export function saveMySettings(resource, includes = defaultUserIncludes) {
  return dispatch => {
    const endpoint = 'users/' + resource.data.id + queryString(includes)

    return Axios.patch(endpoint, resource).then(response => {
      dispatch(setUser(response.data))
      dispatch(closeModal('settings'))
      return true
    }).catch(error => {
      handleErrorResponse(error, 'There was an error updating your details')
    })
  }
}

export function switchOrganisation(organisationId) {
  return (dispatch) => {
    dispatch({ type: IS_SWITCHING_ORGANISATION, status: true })

    return Axios.patch('user/organisation/' + organisationId).then(() => {
      dispatch(getUser())
      dispatch({ type: IS_SWITCHING_ORGANISATION, status: false })
      return true
    }).catch(error => {
      dispatch({ type: IS_SWITCHING_ORGANISATION, status: false })
      handleErrorResponse(error, 'There was an error switching organisation')
    })
  }
}

export function switchBroker(brokerId) {
  return (dispatch) => {
    dispatch({ type: IS_SWITCHING_ORGANISATION, status: true })

    return Axios.patch('user/broker/' + brokerId).then(() => {
      dispatch(getUser())
      dispatch({ type: IS_SWITCHING_ORGANISATION, status: false })
      return true
    }).catch(error => {
      dispatch({ type: IS_SWITCHING_ORGANISATION, status: false })
      handleErrorResponse(error, 'There was an error switching broker')
    })
  }
}

/**
 * attempt to login a user
 * @param email
 * @param password
 * @returns {function()}
 */
export function login(email, password) {
  return dispatch => {
    dispatch({ type: LOGIN })

    return Axios.post('/auth/login', {
      username: email,
      password: password
    }, {
      baseURL: '' // This request is being made to the local node server
    }).then(response => {
      Axios.defaults.headers.common = {
        ...Axios.defaults.headers.common,
        Authorization: 'Bearer ' + response.data.access_token
      }

      dispatch(getUser(null)).then(() => {
        dispatch(setAccessToken(response.data.access_token))
        dispatch(push('/dashboard'))
        return true
      })
      return true
    }).catch(error => {
      dispatch({ type: LOGIN_ERROR })
      handleErrorResponse(error, 'Invalid email address or password')
    })
  }
}

/**
 * Logout from the current session
 * @returns {function()}
 */
export function logOut() {
  return dispatch => {
    delete Axios.defaults.headers.common.Authorization
    dispatch({ type: UNAUTHENTICATE })

    // Fire and forget
    return Axios.post('/auth/logout', {}, {
      baseURL: '' // This request is being made to the local node server
    }).then(() => {
      dispatch(push('/'))

      setTimeout(() => {
        dispatch({ type: LOGOUT })
      }, 1000)

      toastr.success('Goodbye', 'You have successfully logged out')

      return true
    }).catch(error => {
      console.error(error)
    })
  }
}

/**
 * Send a password reminder email
 * @param email
 * @returns {function()}
 */
export function passwordReminder(email) {
  return dispatch => {
    return Axios.post('./password/remind', {
      email: email
    }).then(() => {
      dispatch(setAlert('info', 'Instructions on resetting your password have been emailed to ' + email))
      return true
    }).catch(error => {
      handleErrorResponse(error, 'No user was found with that email address')
    })
  }
}

/**
 * Reset a users password
 * @param token
 * @param email
 * @param password
 * @param confirmation
 * @returns {function()}
 */
export function resetPassword(token, email, password, confirmation) {
  return dispatch => {
    return Axios.post('./password/reset', {
      token: token,
      email: email,
      password: password,
      password_confirmation: confirmation
    }).then(() => {
      // login using the newly submitted password
      dispatch(login(email, password))
      return true
    }).catch(error => {
      handleErrorResponse(error, 'Your password could not be reset at this time')
    })
  }
}


/**
 * Check if the user is a System Admin
 * @returns boolean
 */
export function isSystemAdmin() {
  return currentOrganisationType() === 'System Administration';
}
