import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ProductDetailsForm from '../components/ProductDetailsForm'
import { updateProduct } from '../redux/schemeActions'
import _ from 'lodash'

class ProductDetailsFormContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired
  }

  handleSubmit(product) {
    const trip_durations_annual = {}
    const regions = {}
    const options = {}

    const localProduct = _.cloneDeep(product)

    localProduct.data.attributes.metadata.trip_durations_annual.forEach(({ label: key, value }) => {
      trip_durations_annual[key] = value
    })

    localProduct.data.attributes.metadata.regions.forEach(({ value: key, label: value }) => {
      regions[key] = value
    })

    localProduct.data.attributes.metadata.options.forEach(({ value: key, label: value }) => {

      const items = {}

      if (! _.isEmpty(value.items)) {
        value.items.forEach(({ value: key, label: value }) => {
          items[key] = value
        })
      }

      options[key] = {
        name: value.name,
        question: value.question,
        type: value.type,
        default: value.default,
        items: items
      }
    })

    localProduct.data.attributes.metadata.trip_durations_annual = trip_durations_annual
    localProduct.data.attributes.metadata.regions = regions
    localProduct.data.attributes.metadata.options = options

    return this.props.dispatch(updateProduct(localProduct))
  }

  render() {
    const localProduct = _.cloneDeep(this.props.product)
    const trip_durations_annual = _.map(localProduct.data.attributes.metadata.trip_durations_annual, (value, key) => {
      return {
        label: key,
        value: value,
      }
    })

    const regions = _.map(localProduct.data.attributes.metadata.regions, ((name, key) => {
      return {
        label: name,
        value: key,
      }
    }))

    const options = _.map(localProduct.data.attributes.metadata.options, ((name, key) => {
      if (! _.isEmpty(name.items)) {
        name.items = _.map(name.items, ((name, key) => {
          return {
            label: name,
            value: key,
          }
        }))
      }

      return {
        label: name,
        value: key,
      }
    }))

    localProduct.data.attributes.metadata.trip_durations_annual = trip_durations_annual
    localProduct.data.attributes.metadata.regions = regions
    localProduct.data.attributes.metadata.options = options

    return (
      <ProductDetailsForm
        product={localProduct}
        onSubmit={::this.handleSubmit}
      />
    )
  }
}

export default connect()(ProductDetailsFormContainer)
