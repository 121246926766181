import request from 'axios'
import { get, without } from 'lodash'
export const INIT_SELECT = 'socrates-online/select/INIT'
export const CLEAR_DATA = 'socrates-online/select/CLEAR_DATA'
export const SET_SOURCE = 'socrates-online/select/SET_SOURCE'
export const SET_DATA = 'socrates-online/select/SET_DATA'
export const SET_FILTERS = 'socrates-online/select/SET_FILTERS'
export const FETCH_DATA = 'socrates-online/select/FETCH_DATA'
export const FETCH_DATA_SUCCESS = 'socrates-online/select/FETCH_SUCCESS'
export const FETCH_DATA_ERROR = 'socrates-online/select/FETCH_FAILURE'

export const initialState = {
  selects: {}
}

const initialSelectState = {
  data: [],
  included: [],
  filters: {
    'page[limit]': 1000
  },
  source: '',
  isFetching: false
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case INIT_SELECT:
      return {
        ...state,
        selects: {
          ...state.selects,
          [action.select]: {
            ...initialSelectState,
            source: action.source
          }
        }
      }

    case FETCH_DATA:
      return {
        ...state,
        selects: {
          ...state.selects,
          [action.select]: {
            ...state.selects[action.select],
            isFetching: true
          }
        }
      }


    case FETCH_DATA_ERROR:
      return {
        ...state,
        selects: {
          ...state.selects,
          [action.select]: {
            ...state.selects[action.select],
            isFetching: false
          }
        }
      }

    case CLEAR_DATA:
      return {
        ...state,
        selects: {
          ...state.selects,
          [action.select]: {
            ...initialSelectState,
            source: state.selects[action.select].source,
            filters: state.selects[action.select].filters
          }
        }
      }

    case SET_SOURCE:
      return {
        ...state,
        selects: {
          ...state.selects,
          [action.select]: {
            ...state.selects[action.select],
            source: action.source
          }
        }
      }

    case SET_DATA:
      return {
        ...state,
        selects: {
          ...state.selects,
          [action.select]: {
            ...state.selects[action.select],
            source: action.source,
            data: action.data,
            included: action.included,
            isFetching: false
          }
        }
      }

    case SET_FILTERS:
      let value = action.value !== 'any' ? action.value : 0

      return {
        ...state,
        selects: {
          ...state.selects,
          [action.select]: {
            ...state.selects[action.select],
            filters: {
              ...state.selects[action.select].filters,
              [action.filterType]: value
            }
          }
        }
      }

    default:
      return state
  }
}

export function clearData(name) {
  return {
    type: CLEAR_DATA,
    select: name
  }
}

export function initSelect(name, source) {
  return {
    type: INIT_SELECT,
    select: name,
    source: source
  }
}

export function setSource(name, source) {
  return {
    type: SET_SOURCE,
    select: name,
    source: source
  }
}

export function setData(name, response, source) {
  return {
    type: SET_DATA,
    select: name,
    source: source,
    data: response.data,
    included: response.included || []
  }
}

export function setPreloadedData(name, data) {
  return {
    type: SET_DATA,
    select: name,
    data: data
  }
}

export function applyFilter(name, type, value) {
  return (dispatch) => {
    dispatch(setFilters(name, type, value))
    dispatch(fetchData(name))
  }
}

export function refreshData(name) {
  return (dispatch) => {
    dispatch(fetchData(name))
  }
}


export function setFilters(name, type, value) {
  return {
    type: SET_FILTERS,
    select: name,
    filterType: type,
    value: value
  }
}

function beginFetch(name) {
  return {
    type: FETCH_DATA,
    select: name
  }
}

function fetchError(name) {
  return {
    type: FETCH_DATA_ERROR,
    select: name
  }
}

export function fetchData(name) {
  return (dispatch, getState) => {
    const state = getState()

    dispatch(clearData(name))

    let select = state.selectMenus.selects[name]
    const source = select.source
    let url = source

    // add filters to the source url
    if (select.filters) {
      let keys = Object.keys(select.filters)

      if (keys.length) {
        keys.forEach((key) => {
          if (select.filters[key] != 0) {
            url += (url.includes('?') ? '&' : '?') + key + '=' + select.filters[key]
          }
        })
      }
    }

    if (url) {
      let endpoint = url
      return request.get(endpoint).then(response => {
        const filtered = filterSelectMenuResponse(name, response.data);
        return dispatch(setData(name, filtered, source))
      }).catch(error => {
        console.error(error)
      })
    }
  }
}

function filterSelectMenuResponse(name, responseData) {
  if (name === 'products' && responseData.data) {
    const products = {};
    products['data'] = without(responseData.data.map((product) => {
      if (get(product, 'attributes.metadata.isActive', false)) {
        return product
      } else {
        return null;
      }
    }), null)
    return products;
  }
  return responseData;
}
