import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FormGroup, Col, ControlLabel } from 'react-bootstrap'
import SelectMenu from '../SelectMenu/SelectMenu'

export const SELECT_IDENTIFIER = 'network'

class SelectMenuNetwork extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    label: PropTypes.string,
    labelSize: PropTypes.number,
    input: PropTypes.object.isRequired,
    infoButton: PropTypes.element,
    name: PropTypes.string,
    meta: PropTypes.object,
    brochure: PropTypes.bool,
    object: PropTypes.bool,
    multi: PropTypes.bool,
    filters: PropTypes.array
  };

  render() {
    const { input, infoButton, name, meta, label, labelSize, mdFieldSize, filters, brochure, object, multi, other } = this.props
    let source = brochure ? 'public/broker-networks' : 'brokers/networks'

    if (Array.isArray(filters)) {
      filters.map(filter => {
        source += (source.includes('?') ? '&' : '?') + filter.filter + '=' + filter.value
      })
    }
    return (
        <FormGroup controlId={name} className={((meta && meta.touched && meta.error) ? ' has-error' : '')}>
          {
            labelSize ? (
                    <Col componentClass={ControlLabel} sm={labelSize}>
                      { label } { infoButton }
                    </Col>
                ) : (
                    <ControlLabel>{ label }</ControlLabel>
                )
          }
          <Col sm={(12 - labelSize)} md={mdFieldSize ? mdFieldSize : 0}>
            <SelectMenu
                source={source}
                object={object}
                multi={multi}
                name={SELECT_IDENTIFIER}
                field={input}
                other={true}
            />

            {(meta && meta.touched && meta.error) && <span className="input-error">{meta.error}</span>}
          </Col>
        </FormGroup>
    )
  }
}

export default connect()(SelectMenuNetwork)
