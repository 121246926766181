import React from 'react';
import { Col, Row } from 'react-bootstrap';

class GeneralInfo extends React.PureComponent {
  render() {
    return (
      <Row>
        <Col sm={6}>
          <strong>Launch Date:</strong>
          <span className="pull-right">15/01/2020</span>
        </Col>
        <Col sm={6}>
          <strong>Version:</strong>
          <span className="pull-right">1.0.0</span>
        </Col>
        <Col sm={6}>
          <strong>Primary Contact</strong>
          <span className="pull-right">support@socrates.systems</span>
        </Col>
      </Row>
    );
  }
}

export default GeneralInfo;
