import * as actions from './schemeReducer'
import Axios from 'axios'
import { push } from 'react-router-redux'
import { toastr } from 'react-redux-toastr'
import queryString from '../../../helpers/queryString'
import handleErrorResponse from '../../../helpers/handleErrorResponse'

const defaultSchemeIncludes = ['product', 'insurer', 'claims_handler', 'assistance_handler']
const defaultMeta = ['config', 'options', 'benefits']
import {
  isEqual,
  isObject,
  transform,
} from 'lodash';

export function isLoading(status) {
  return {
    type: actions.IS_LOADING,
    status
  }
}

export function clearData() {
  return {
    type: actions.CLEAR_DATA
  }
}

export function isCopying(status) {
  return {
    type: actions.IS_COPYING,
    status
  }
}

export function isEditing(status) {
  return {
    type: actions.IS_EDITING,
    status
  }
}

export function isImporting(status) {
  return {
    type: actions.IS_IMPORTING,
    status
  }
}

export function isExporting(status) {
  return {
    type: actions.IS_EXPORTING,
    status
  }
}

export function getProducts() {
  return dispatch => {
    dispatch(isLoading(true))
    return Axios.get('products?page[limit]=500').then(response => {
      dispatch({
        type: actions.SET_PRODUCTS,
        data: response.data
      })
      dispatch(isLoading(false))
      return true
    }).catch(error => {
      dispatch(isLoading(false))
      console.error(error)
    })
  }
}

export function getProduct(id, includes) {
  let endpoint = 'products/' + id
  if (includes) {
    endpoint += queryString(includes)
  }

  return dispatch => {
    dispatch(isLoading(true))
    return Axios.get(endpoint).then(response => {
      dispatch({
        type: actions.SET_PRODUCT,
        data: response.data
      })
      dispatch(isLoading(false))
      return true
    }).catch(error => {
      dispatch(isLoading(false))
      console.error(error)
    })
  }
}

export function updateProduct(product) {
  return dispatch => {
    dispatch(isLoading(true))
    return Axios.patch('products/' + product.data.id, product).then(response => {
      dispatch({
        type: actions.SET_PRODUCT,
        data: response.data
      })
      dispatch(isLoading(false))
      toastr.success('Success', 'Your changes have been saved')
      return true
    }).catch(error => {
      dispatch(isLoading(false))
      handleErrorResponse(error, 'There was an error updating the product')
    })
  }
}

export function getScheme(id, includes = defaultSchemeIncludes, meta = defaultMeta) {
  return dispatch => {
    dispatch(isLoading(true))
    return Axios.get('products/schemes/' + id + queryString(includes, meta)).then(response => {
      dispatch({
        type: actions.SET_SCHEME,
        data: response.data
      })
      dispatch(isLoading(false))
      return true
    }).catch(error => {
      dispatch(isLoading(false))
      console.error(error)
    })
  }
}

// This is from PP and JTC
const difference = (newValues, existingValues) => {
  return transform(newValues, function (result, value, key) {
    if (!isEqual(value, existingValues[key])) {
      //TODO may need to make arrays that are excluded from diff more flexible
      const compareObjects =
        isObject(value) &&
        isObject(existingValues[key]);
      result[key] = compareObjects ? difference(value, existingValues[key]) : value;
    }
  });
};

export function updateScheme(scheme, productId, schemeBeforeUpdate, includes = defaultSchemeIncludes, meta = defaultMeta) {

  const dataDifferences = difference(scheme.data, schemeBeforeUpdate.scheme.data);
  const metaDifferences = difference(scheme.meta, schemeBeforeUpdate.scheme.meta);

  const allValuesToUpdate = {
    data: {
      ...dataDifferences,
    },
    meta: {
      patch: {
        ...metaDifferences,
      },
    },
  };

  return dispatch => {
    return Axios.patch('products/schemes/' + scheme.data.id + queryString(includes, meta), allValuesToUpdate).then(response => {
      dispatch({
        type: actions.SET_SCHEME,
        data: response.data
      })

      if (scheme.data.id != response.data.data.id) {
        dispatch(push('/admin/products/' + productId + '/schemes/' + response.data.data.id))
        toastr.success('Success', 'New version setup successfully')
      } else {
        toastr.success('Success', 'Your changes have been saved')
      }
      return true
    }).catch(error => {
      handleErrorResponse(error, 'There was an error updating the scheme')
    })
  }
}

export function updateSchemeOptions(scheme, includes = defaultSchemeIncludes, meta = defaultMeta) {

  return dispatch => {
    return Axios.put(
      'products/schemes/' + scheme.data.id + '/configuration',
      { meta: { config: { options: scheme.meta.options } } }
    ).then(response => {
      dispatch(getScheme(scheme.data.id))
      toastr.success('Success', 'Options saved successfully')
      return true
    }).catch(error => {
      handleErrorResponse(error, 'There was an error updating scheme options')
    })
  }
}

export function updateSchemeBenefits(scheme, includes = defaultSchemeIncludes, meta = defaultMeta) {
  return dispatch => {
    return Axios.patch(
      'products/schemes/' + scheme.data.id + '/configuration/benefits' + queryString(includes, meta),
      { meta: { benefits: scheme.meta.benefits } }
    ).then(response => {
      dispatch(getScheme(scheme.data.id))
      toastr.success('Success', 'Benefits saved successfully')
      return true
    }).catch(error => {
      handleErrorResponse(error, 'There was an error updating scheme benefits')
    })
  }
}

export function copyScheme(id, scheme, productId, includes = defaultSchemeIncludes, meta = defaultMeta) {
  return dispatch => {
    dispatch(isCopying(true))
    return Axios.post('products/schemes/' + id + ':copy' + queryString(includes, meta), scheme).then(response => {
      dispatch({
        type: actions.SET_SCHEME,
        data: response.data
      })
      dispatch(isCopying(false))
      dispatch(push('/admin/products/' + productId + '/schemes/' + response.data.data.id))
      toastr.success('Success', 'Scheme copied successfully')
      return true
    }).catch(error => {
      dispatch(isCopying(false))
      handleErrorResponse(error, 'There was an error copying the scheme')
    })
  }
}

export function updateProductDocumentPacks(packs, productId) {
  return dispatch => {
    return Axios.patch('products/' + productId + '/document-packs' + queryString(['document_packs.scheme_documents']), packs).then(response => {
      dispatch({
        type: actions.SET_SCHEME,
        data: response.data
      })
      toastr.success('Success', 'Your changes have been saved')
      return true
    }).catch(error => {
      handleErrorResponse(error, 'There was an error updating the product document packs')
    })
  }
}

export function updateSchemeDocumentPacks(packs, schemeId, includes = defaultSchemeIncludes, meta = defaultMeta) {
  return dispatch => {
    return Axios.patch('products/schemes/' + schemeId + '/document-packs' + queryString(includes, meta), packs).then(response => {
      dispatch({
        type: actions.SET_SCHEME,
        data: response.data
      })
      toastr.success('Success', 'Your changes have been saved')
      return true
    }).catch(error => {
      handleErrorResponse(error, 'There was an error updating the scheme document packs')
    })
  }
}

export function activateScheme(values, scheme) {
  return dispatch => {
    return Axios.post('products/schemes/' + scheme.data.id + ':activate', values).then(response => {
      dispatch(getScheme(scheme.data.id))
      toastr.success('Success', 'Scheme has been activated')
      return true
    }).catch(error => {
      handleErrorResponse(error, 'Failed to activate scheme')
    })
  }
}

export function endScheme(values, scheme, end = false) {
  const endpoint = end ? 'end' : 'deactivate'
  return dispatch => {
    return Axios.post('products/schemes/' + scheme.data.id + ':' + endpoint, values).then(response => {
      dispatch(getScheme(scheme.data.id))
      toastr.success('Success', 'Scheme has been deactivated')
      return true
    }).catch(error => {
      handleErrorResponse(error, 'Failed to deactivate scheme')
    })
  }
}

export function editRates(scheme) {
  return dispatch => {
    dispatch(isEditing(true))
    return Axios.post('products/schemes/' + scheme.data.id + ':edit-rates', {}).then(response => {
      dispatch(isEditing(false))

      if (window && response.data.meta.url) {
        window.open(response.data.meta.url, '_blank')
      }
      return true
    }).catch(error => {
      dispatch(isEditing(false))
      handleErrorResponse(error, 'Failed to get scheme document URL')
    })
  }
}

export function importRates(scheme) {
  return dispatch => {
    dispatch(isImporting(true))
    return Axios.post('products/schemes/' + scheme.data.id + ':import-rates', {}).then(response => {
      dispatch(getScheme(scheme.data.id))
      dispatch(isImporting(false))
      toastr.success('Success', 'Your rates have been imported')
      return true
    }).catch(error => {
      dispatch(isImporting(false))
      handleErrorResponse(error, 'Failed to get scheme document URL')
    })
  }
}

export function assignHandlerToScheme(scheme, handlerType, values) {
  let action = 'assign-'

  if (handlerType === 'Claims Handler') {
    action += 'claims-handler'
  }

  if (handlerType === 'Assistance Handler') {
    action += 'assistance-handler'
  }

  return dispatch => {
    dispatch(isEditing(true))
    return Axios.post('products/schemes/' + scheme.data.id + ':' + action, values).then(response => {
      dispatch(getScheme(scheme.data.id))
      dispatch(isEditing(false))
      toastr.success('Success', handlerType + ' assigned')
      return true
    }).catch(error => {
      dispatch(isEditing(false))
      handleErrorResponse(error, 'Failed to assign ' + handlerType)
    })
  }
}


