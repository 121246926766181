import React, { Component } from 'react'
import _ from 'lodash'
import './StatsSectionConversionLabel.scss'

export default class StatsSectionConversion extends Component {
  render() {
    const { stats } = this.props
    return (
      <div className="stats-section-Conversion-Label">
        <div>
          <span className="conversion-label">
              Conversion rate:
            <span>{Math.round(_.get(stats, 'conversion_rate', 0))}%</span>
          </span>
        </div>
      </div>
    )
  }
}