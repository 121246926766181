import React from 'react'
import '../styles.scss'
import * as _ from 'lodash'
import { Field } from 'redux-form'
import { Row, Col } from 'react-bootstrap'
import SelectRole from '../../../../../../../common/components/Selects/SelectRole'
import InfoButton from '../../../../../../../common/components/InfoButton/InfoButton'
import { Button, HorizontalFormControl, NameFields, Icon } from '../../../../../../../common/components'

const USERS = 'data.attributes.users'

export default class BrokerUsers extends React.Component {
  constructor(props) {
    super(props)
  }
  componentDidMount () {
    const { fields, formValues } = this.props
    if  (! _.get(formValues, USERS) || _.get(formValues, USERS) === [] ) {
      fields.push()
    }
  }
  render() {
    const { fields, label, isLogedIn } = this.props
    let showButton = true
    return (
      <div className='brokerap-new-user'>
        <div>
          {fields.map((user, index) => {
            const USER = `${USERS}[${index}]`
            return (
              <Col sm={12} key={index}>
                <Row className="brokerap-users-row">
                  <Col sm={12}>
                    {!index && !label ? null : (
                      <span className='pull-right' onClick={() => fields.remove(index)}>
                         <Icon name='times'/>
                      </span>
                    )}
                    <h4>
                      <strong>New User</strong>
                    </h4>
                  </Col>
                  <Row>
                    <Col sm={12} >
                      <NameFields
                        label='Name'
                        labelSize={2}
                        disableOnBlur
                        baseName={user}
                        fieldComponent={Field}
                      />
                    </Col>
                  </Row>
                  <Row className="margin-top">
                    <Col sm={6}>
                      <Field
                        type='email'
                        label='Email'
                        labelSize={4}
                        mdFieldSize={8}
                        name={`${USER}.email`}
                        component={HorizontalFormControl}
                      />
                    </Col>
                    {isLogedIn && (
                      <Col sm={6}>
                        <Field
                          labelSize={3}
                          mdFieldSize={9}
                          label='User Role'
                          component={SelectRole}
                          name={`${USER}.role_id`}
                          filters={[{ filter: 'filter[organisation_type]', value: 'Broker' }]}
                          infoButton={
                            <InfoButton content={'Set at least one user to Admin so as to make a primary contact'} />
                          }
                        />
                      </Col>
                    )}
                  </Row>
                </Row>
            </Col>
            )
          })}

          {showButton && (
            <div>
              <Button
                size='sm'
                bsStyle='primary'
                label={'Add ' + ('User')}
                handleClick={() => fields.push()}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}
