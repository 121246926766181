export const IS_SUBMITTING = 'socrates-online/policy/IS_SUBMITTING'
export const SET_POLICY = 'socrates-online/policy/SET_POLICY'
export const CLEAR_DATA = 'socrates-online/policy/CLEAR_DATA'
export const IS_CHECKING_CANCEL = 'socrates-online/policy/IS_CHECKING_CANCEL'
export const IS_GENERATING_DOCS = 'socrates-online/policy/IS_GENERATING_DOCS'
export const SET_RESOURCE_NOTES_COUNT = 'socrates-online/policy/SET_RESOURCE_NOTES_COUNT'
export const IS_GETTING_POLICY = 'socrates-online/policy/IS_GETTING_POLICY'

export const initialState = {
  isSubmitting: false,
  isCheckingCancel: false,
  isGeneratingDocs: false,
  resources: {},
  notesCount: 0
}

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case IS_CHECKING_CANCEL:
      return {
        ...state,
        isCheckingCancel: action.status
      }
    case IS_GENERATING_DOCS:
      return {
        ...state,
        isGeneratingDocs: action.status
      }
    case SET_POLICY:
      return {
        ...state,
        resources: {
          ...state.resources,
          [action.data.data.id]: {
            ...action.data
          }
        }
      }
    case IS_GETTING_POLICY:
      return {
        ...state,
        isGettingPolicy: action.status
      }
    case SET_RESOURCE_NOTES_COUNT:
      return {
        ...state,
        notesCount: action.count
      }
    case CLEAR_DATA:
      return initialState
    default:
      return state
  }
}
