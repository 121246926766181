import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { SelectField } from '../../../../common/components/ReduxFormField'
import {
  Button,
  Modal,
  SelectBroker,
  SelectOrganisation
} from '../../../../common/components'
import * as _ from 'lodash'
import { switchOrganisation, switchBroker, currentOrganisationType } from '../../redux/authActions'
import getIncludedResource from '../../../../helpers/getIncludedResource'
import { closeModal } from '../../../../common/components/Modal/ModalRedux'
import { push } from 'react-router-redux'

const FORM_NAME = 'organisation-settings'

export class OrganisationSettingsForm extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isSwitchingOrganisation: PropTypes.bool.isRequired,
    submitting: PropTypes.bool.isRequired
  }

  constructor (props) {
    super(props)
    this.selectOrganisationMenu = this.selectOrganisationMenu.bind(this)
    this.state = {
      isOrganisationChanged: false,
      isBrokerChanged: false
    }
  }

  selectOrganisationMenu() {
    const { resource, submitting, formValues } = this.props
    const includedOrganisations = getIncludedResource(resource.data, resource.included, 'organisations')
    const organisations = includedOrganisations.map(organisation => {
      return { value: organisation.id, label: organisation.attributes.name }
    })

    if (_.get(resource, 'data.relationships.current_organisation.data.id')) {
      return (
        <Field
          name="data.relationships.current_organisation.data.id"
          label="Organisation"
          labelSize={2}
          filters={[
            { filter: 'filter[users]', value: _.get(formValues, 'data.id') }
          ]}
          component={SelectOrganisation}
          disabled={submitting}
          onChange={() => this.setState({ isOrganisationChanged: false })}
        />
      )
    }

    return (
      <Field
        name="data.relationships.current_organisation.data.id"
        label="Organisation"
        labelSize={2}
        component={SelectField}
        options={organisations}
        disabled={submitting}
        onChange={() => this.setState({ isOrganisationChanged: false })}
      />
    )
  }

  selectBroker() {
    const { resource, submitting, formValues } = this.props

    if (currentOrganisationType() !== 'Broker') {
      return
    }

    return (
      <Field
        name="data.relationships.current_broker.data.id"
        label="Broker"
        labelSize={2}
        filters={[
          {
            filter: 'filter[organisation]',
            value: _.get(resource, 'data.relationships.current_organisation.data.id')
          },
          { filter: 'filter[users]', value: _.get(formValues, 'data.id') }
        ]}
        component={SelectBroker}
        disabled={submitting}
      />
    )
  }

  footerButtonOrganisation() {
    const { resource, formValues, isSwitchingOrganisation } = this.props

    if (_.get(formValues, 'data.relationships.current_organisation.data.id') === _.get(resource, 'data.relationships.current_organisation.data.id')) {
      return null
    }

    if (!_.get(formValues, 'data.relationships.current_organisation.data.id', false)) {
      return null
    }

    return (
      <Button
        type="button"
        bsStyle="default"
        size="sm"
        className="pull-right"
        label="Set organisation"
        handleClick={() => {
          Promise.resolve(this.props.dispatch(switchOrganisation(_.get(formValues, 'data.relationships.current_organisation.data.id'))))
            .then(() =>
              this.setState({ isOrganisationChanged: true })
            )}
        }
        isLoading={isSwitchingOrganisation}
      />
    )
  }

  footerButtonBroker() {
    const { resource, formValues, isSwitchingOrganisation, dispatch } = this.props

    if (currentOrganisationType() !== 'Broker') {
      return
    }

    if (_.get(formValues, 'data.relationships.current_broker.data.id') === _.get(resource, 'data.relationships.current_broker.data.id')) {
      return
    }

    if (!_.get(formValues, 'data.relationships.current_broker.data.id', false)) {
      return
    }

    return (
      <Button
        type="button"
        bsStyle="default"
        size="sm"
        className="pull-right"
        label="Set Broker"
        handleClick={() => {
          Promise.resolve(this.props.dispatch(switchBroker(_.get(formValues, 'data.relationships.current_broker.data.id'))))
            .then(() => {
              dispatch(push('/'))
              dispatch(closeModal(FORM_NAME))
            }
            )
        }
        }
        isLoading={isSwitchingOrganisation}
      />
    )
  }

  render() {
    const { formValues, handleSubmit } = this.props

    return (
      <Modal {...this.props}
        name={FORM_NAME}
        title="Organisation Settings"
        close
        handleSubmit={handleSubmit}
        footer={this.state.isOrganisationChanged ? this.footerButtonBroker() : this.footerButtonOrganisation()}
      >
        <div className="row">
          <div className="col-xs-12">
            <div className="form-horizontal">
              { this.selectOrganisationMenu() }
            </div>
          </div>
        </div>
        {this.state.isOrganisationChanged && _.get(formValues, 'data.relationships.current_organisation.data.id') && (
          <div className="row">
            <div className="col-xs-12">
              <div className="form-horizontal">
                { this.selectBroker() }
              </div>
            </div>
          </div>
        )}
      </Modal>
    )
  }
}

const form = reduxForm({
  form: FORM_NAME
})(OrganisationSettingsForm)

const selector = formValueSelector(FORM_NAME)
export default connect(
  (state, props) => {
    const values = selector(state,
      'data.id',
      'data.relationships.current_organisation.data.id',
      'data.relationships.current_broker.data.id',
    )
    return {
      initialValues: props.resource,
      formValues: values,
      files: state.upload.files,
      isSwitchingOrganisation: state.auth.isSwitchingOrganisation
    }
  }
)(form)
