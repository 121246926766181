import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import LeisureTravelForm from '../components/LeisureTravelForm'
import { getQuotes, clearData } from '../redux/productActions'
import _ from 'lodash'
import moment from 'moment';

class LeisureTravelFormContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    screening: PropTypes.object.isRequired,
    product: PropTypes.object.isRequired,
    currentProduct: PropTypes.object.isRequired,
    application: PropTypes.object,
    mta: PropTypes.bool.isRequired,
    renewal: PropTypes.bool.isRequired,
  };

  /**
   * Clean out the data when the component unmounts
   */
  componentWillUnmount() {
    this.props.dispatch(clearData())
  }

  handleSubmit(resource) {
    return this.props.dispatch(getQuotes(this.props.product.schemes, resource))
  }

  formatDate(date) {
    if (! date) {
      return ''
    }
    const formattedDate = moment(date)
    return formattedDate !== 'Invalid Date' ? formattedDate.format() : ''
  }

  render() {
    const { product, currentProduct, screening, application, mta, renewal, customer, location, params } = this.props

    const currentCustomer = _.get(customer, `resources.${location.query.customer}.data.attributes`)

    let adults = [{
      title: _.get(currentCustomer, 'title', ''),
      first_name: _.get(currentCustomer, 'first_name', ''),
      last_name: _.get(currentCustomer, 'last_name', ''),
      dob: this.formatDate(_.get(currentCustomer, 'dob')) || '',
      email:  _.get(currentCustomer, 'email', ''),
      phone1: _.get(currentCustomer, 'phone1', ''),
      phone2: _.get(currentCustomer, 'phone2', ''),
      conditions_covered: true,
      screening: '',
      screening_id: '',
      address: {
        line1: _.get(currentCustomer, 'address.line1', ''),
        line2: _.get(currentCustomer, 'address.line2', ''),
        line3: _.get(currentCustomer, 'address.line3', ''),
        town: _.get(currentCustomer, 'address.town', ''),
        county: _.get(currentCustomer, 'address.county', ''),
        postcode: _.get(currentCustomer, 'address.postcode', ''),
      },
    }]
    let children = []

    const medicalQuestions = []
    _.get(currentProduct, 'data.attributes.metadata.medical_declaration_questions', []).map((declaration) => {
      medicalQuestions.push({ type: declaration.type, content: declaration.content, answer: '' })
    })

    if (application) {
      adults = _.get(application, 'data.attributes.metadata.adults', adults)
      children = _.get(application, 'data.attributes.metadata.children', children)

      // Add options if not set i.e. saved before option on b2c
      if (_.keys(_.get(application, 'data.attributes.metadata.options', {})).length === 0) {
        _.set(
          application,
          'data.attributes.metadata.options',
          {
            winter_sports: '',
            golf_cover: '',
          }
        )
      }

      if (_.get(application, 'data.attributes.metadata.legacy_policy', false) === true) {
        //Remove end date from long stay legacy policies
        if (_.get(application, 'data.attributes.metadata.scheme_type') === 'longstay') {
          _.set(application, 'data.attributes.metadata.end_date', '')
        }

        if (_.get(application, 'data.attributes.metadata.legacy_medical', true) === true) {
          //Clear legacy medical
          adults.map((adult, key) => {
            _.set(adults[key], 'screening_id', '')
            _.set(adults[key], 'screening', '')
          })
          children.map((adult, key) => {
            _.set(children[key], 'screening_id', '')
            _.set(children[key], 'screening', '')
          })
          //Set as not legacy if cloning
          if (! mta && ! renewal) {
            _.set(application, 'data.attributes.metadata.legacy_policy', false)
            _.set(application, 'data.attributes.metadata.medical_declaration_questions', medicalQuestions)
          }
          //Prevent clearing of medical if edited again
          _.set(application, 'data.attributes.metadata.legacy_medical', false)
        }
      }
    }

    const screeningProduct = _.get(currentProduct, 'data.attributes.metadata.screening', true)
    const isServingCountry = _.get(currentProduct, 'data.attributes.metadata.serving_country', false)
    const isSendingField = _.get(currentProduct, 'data.attributes.metadata.sending_field', false)

    const resource = {
      data: {
        currentTab: 1,
        type: 'application',
        attributes: {
          quote_reference: product.nextQuoteReference,
          quote_type: '',
          metadata: {
            scheme_type: '',
            traveller_group: '',
            region: '',
            destinations: [],
            max_trip_duration: '',
            start_date: '',
            end_date: '',
            referred_activities: '',
            activities: '',
            occupations: '',
            adults: adults,
            children: children,
            options: {
              winter_sports: '',
              // hazardous_activities: 'A',
              golf_cover: '',
              // excess_waiver: 'false',
              // double_excess: 'false',
              // business_equipment: 'false',
              // natural_catastrophe: 'false',
              // travel_dispute: 'false',
              // scheduled_airline_failure: 'false',
              // manual_business_trips: 'false',
              cruise: '',
              // wedding_cover: 'false',
              // breakdown_cover: 'false',
              // gadget_cover: 'false',
              // pet_cover: 'false'
            },
            subjectivities: [],
            screeningProduct: screeningProduct,
            medical_declaration_questions: medicalQuestions,
            overrides: {}
          }
        },
        relationships: {
          schemes: {
            data: []
          }
        }
      }
    }

    if (! application && isServingCountry) {
      _.set(resource, 'data.attributes.metadata.serving_country', '')
    }

    if (! application && isSendingField) {
      _.set(resource, 'data.attributes.metadata.sending_field', '')
    }

    // Overrides the property from an empty array to an empty object
    const localApplication = _.cloneDeep(application)
    const localOverrides = _.get(localApplication, 'data.attributes.metadata.overrides')
    if (_.isEmpty(localOverrides)) {
      _.set(localApplication, 'data.attributes.metadata.overrides', {})
    }

    if (currentProduct.data.attributes.metadata && currentProduct.data.attributes.metadata.subjectivities) {
      resource.data.attributes.metadata.subjectivities = currentProduct.data.attributes.metadata.subjectivities
    }

    const isApplication = application && application.data.type === 'policies/applications'
    const policyId = isApplication ? _.get(application, 'data.relationships.policy.data.id') : params.applicationId

    return (
      <LeisureTravelForm
        resource={localApplication ? localApplication : resource}
        screening={screening}
        product={product}
        mta={mta}
        currentProduct={currentProduct}
        handleSave={::this.handleSubmit}
        policyId={policyId}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    product: state.product,
    screening: state.screening,
    customer: state.customer,
  }
}

export default connect(mapStateToProps)(LeisureTravelFormContainer)
