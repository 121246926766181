import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box, DataTable } from '../../../../common/components'
import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { Button } from '../../../../common/components'
import { hasPermission } from '../../../auth/redux/authActions'
import { FormControl, ControlLabel } from 'react-bootstrap'
import { push } from 'react-router-redux'
import { FormattedDate, FormattedTime } from 'react-intl'

export default class CustomerList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };

  formatDate(cell, row) {
    return (
        <span><FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/></span>
    )
  }

  formatAddress(cell, row) {
    return (
        row.address.postcode
    )
  }

  handleRowClick(row) {
    this.props.dispatch(push('/customers/' + row.id))
  }

  handleSearchUpdate(event) {
    this.props.dispatch(applyFilter('customers', 'filter[name]', event.target.value))
  }

  render() {
    const buttons = (hasPermission('customer.create') ? (
      <Button
        link={true}
        to="/customers/new"
        className="pull-right"
        bsStyle="primary"
        label="new customer"
      />
    ) : (''))

    return (
        <div>
          <Box>
            <div className="row">
              <div className="col-sm-4">
                <ControlLabel className="filter-label">Search</ControlLabel>
                <FormControl onChange={ ::this.handleSearchUpdate }/>
              </div>
              <div className="col-sm-4"/>
              <div className="col-sm-4"/>
            </div>
          </Box>

          <Box>
            <DataTable
                source="/customers"
                name="customers"
                showTotal={true}
                onRowSelect={this.handleRowClick.bind(this)}>

              <TableHeaderColumn dataField="id" isKey={true} hidden={true}>ID</TableHeaderColumn>
              <TableHeaderColumn dataField="customer_name" width={'300px'}>Name</TableHeaderColumn>
              <TableHeaderColumn dataField="email" width={'200px'} dataSort={true}>Email</TableHeaderColumn>
              <TableHeaderColumn dataField="address" width={'100px'} dataFormat={this.formatAddress}>Postcode</TableHeaderColumn>
              <TableHeaderColumn dataField="created_at"  width={'100px'} dataFormat={this.formatDate} dataSort={true}>Customer
                Since</TableHeaderColumn>
            </DataTable>
            <div className="button-bar-inside">
              {buttons}
            </div>
          </Box>
        </div>
    )
  }
}
