import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TextField } from '../../../../common/components/ReduxFormField'
import { Field } from 'redux-form'
import { HorizontalFormControl, Button, Icon, InfoLabel } from '../../../../common/components'
import './styles.scss'

export default class Handlers extends Component {

  static propTypes = {
    fields: PropTypes.object.isRequired,
    disabled: PropTypes.bool
  };

  render() {
    const { fields, disabled } = this.props

    return (
      <div className="form-horizontal">
        {fields.map((handler, index) => {
          return (
            <div key={index} className={'handler-row col-xs-12 handler' + index}>

              {index > 0 && !disabled && (
                <span className="remove-handler pull-right" onClick={() => fields.remove(index)}>
                  <Icon name="times"/>
                </span>
              )}

              <h4>Version {(index + 1)}</h4>

              <div className="col-xs-12">
                <div className={disabled ? '' : 'col-xs-12'}>
                  <Field
                    name={handler + '.details'}
                    type="textarea"
                    label="Details"
                    labelSize={3}
                    component={disabled ? InfoLabel : TextField}
                  />
                </div>
              </div>

              <div className="col-xs-12">
                <div className={disabled ? '' : 'col-xs-12'}>
                  <Field
                    name={handler + '.content'}
                    type="textarea"
                    label="Content"
                    labelSize={3}
                    component={disabled ? InfoLabel : TextField}
                  />
                </div>
              </div>

              <div className="col-sm-6">
                <Field
                  name={handler + '.phone'}
                  label="Phone"
                  labelSize={3}
                  component={disabled ? InfoLabel : HorizontalFormControl}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  name={handler + '.email'}
                  label="Email"
                  labelSize={2}
                  component={disabled ? InfoLabel : HorizontalFormControl}
                />
              </div>

            </div>
          )
        })}

        {!disabled && (
          <Button className="pull-left" bsStyle="primary" size="sm" label="Add a Policy Text Version"
                  handleClick={() => fields.push({
                    details: '',
                    content: '',
                    phone: '',
                    email: '',
                  })}
          />
        )}
      </div>
    )
  }
}
