import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import { FormattedDate } from 'react-intl'
import displayName from '../../../../helpers/displayName'
import { Box, Button, ButtonBar } from '../../../../common/components'
import { hasPermission } from '../../../auth/redux/authActions'
import getIncludedResource from '../../../../helpers/getIncludedResource'
import './styles.scss'

export default class ContentShow extends Component {
  static propTypes = {
    resource: PropTypes.object.isRequired
  }

  renderSection(section, key) {
    const { resource } = this.props

    switch (true) {
      case key.indexOf('date') !== -1:
        return <FormattedDate value={new Date(section)}/>
      case key === 'hero' || key === 'attachments':
        if (!section) {
          return null
        }

        const files = getIncludedResource(resource.data, resource.included, 'files')
        let hero = null

        files.forEach(file => {
          if (file.id === section) {
            hero = file
          }
        })

        return (
            <img src={hero.attributes.url}/>
        )
      default:
        return <ReactMarkdown source={section}/>
    }
  }

  render() {
    const resource = this.props.resource.data.attributes
    const sections = resource.sections
    return (
      <Box>
        <h2>{resource.name}</h2>

        {Object.keys(sections).map(section => {
          return (
            <div key={section} className="content-show-section">
              <label className="control-label">{displayName(section)}</label>
              <div className="RichEditor-root">
                {this.renderSection(sections[section], section)}
              </div>
            </div>
          )
        })}

        <ButtonBar>
          { hasPermission('content_management.edit') && (
            <Button
                link={true}
                to={'/admin/content/' + this.props.resource.data.id + '/edit'}
                className="pull-right"
                bsStyle="primary"
                rightIcon="pencil-square-o"
                label="edit content"
            />
          )}
        </ButtonBar>
      </Box>
    )
  }
}
