import * as actions from './productReducer'
import Axios from 'axios'
import Moment from 'moment'
import queryString from '../../../helpers/queryString'
import filterString from '../../../helpers/filterString'
import changesToContent from '../../../helpers/changesToContent'
import { push } from 'react-router-redux'
import { toastr } from 'react-redux-toastr'
import { change as formChange } from 'redux-form'
import * as _ from 'lodash'
import handleErrorResponse from '../../../helpers/handleErrorResponse'
import errorResponseToArray from '../../../helpers/errorResponseToArray'
import { isOwner } from '../../auth/redux/authActions'
import { formValueSelector } from 'redux-form'
import { getFormValues } from 'redux-form'
import { concat, forEach, get } from 'lodash';


const defaultIncludes = []

export function isCalculating(status) {
  return {
    type: actions.IS_CALCULATING,
    status
  }
}

export function isPremiumAdjusting(status) {
  return {
    type: actions.IS_PREMIUM_ADJUSTING,
    status
  }
}

export function clearData() {
  return {
    type: actions.CLEAR_DATA,
  }
}

export function isUpdatingPremiumIndication(status) {
  return {
    type: actions.IS_UPDATING_PREMIUM_INDICATION,
    status
  }
}

export function submitSucceeded(status) {
  return {
    type: actions.SUBMIT_SUCCEEDED,
    status
  }
}

export function isSaving(status) {
  return {
    type: actions.IS_SAVING,
    status
  }
}

export function isSavingForExit(status) {
  return {
    type: actions.IS_SAVING_FOR_EXIT,
    status
  }
}

export function isIssuing(status) {
  return {
    type: actions.IS_ISSUING,
    status
  }
}

export function setRegions(data) {
  return {
    type: actions.SET_REGIONS,
    data
  }
}

export function setSchemes(data) {
  return {
    type: actions.SET_SCHEMES,
    data
  }
}

export function setProduct(data) {
  return {
    type: actions.SET_PRODUCT,
    data
  }
}

export function setPremiumOverrideCalculation(data) {
  return {
    type: actions.SET_PREMIUM_OVERRIDE_CALCULATION,
    data
  }
}

export function setPremiumIndication(premium) {
  return {
    type: actions.SET_PREMIUM_INDICATION,
    premium
  }
}

export function clearNextQuoteReference() {
  return {
    type: actions.SET_NEXT_QUOTE_REFERENCE,
    data: ''
  }
}

export function setNextQuoteReference(data) {
  return {
    type: actions.SET_NEXT_QUOTE_REFERENCE,
    data
  }
}

export function setPolicyForAdjustment(data) {
  return {
    type: actions.SET_POLICY_FOR_ADJUSTMENT,
    data
  }
}

export function setQuote(data) {
  return {
    type: actions.SET_QUOTE,
    data
  }
}

export function clearQuotes() {
  return {
    type: actions.CLEAR_QUOTES
  }
}

export function setSignpost(signpostContent) {
  return {
    type: actions.SET_SIGNPOST,
    data: signpostContent
  }
}

const hasScreeningErrors = (errors) => {
  return errors && !!errors.find((error) => {
    return error.includes('Exceeds maximum medical score') ||
      error.includes('Medical Condition has annual exclusion') ||
      error.includes('Medical Condition has winter sports exclusion') ||
      error.includes('Max age for winter sports')
  })
}

const hasMedicalDeclines = (declines) => {
  return declines && !!declines.find((decline) => {
    return decline.includes('Medical Declaration Question 4 is not covered') ||
      decline.includes('Medical Declaration Question 5 is not covered')
  })
}

const hasMedicalExclusions = (medicalExclusions) => {
  return medicalExclusions.length > 0;
}

export function getSignPosting(name, policyApplicationId) {
  return () => {
    const endpoint = 'public/contents/signpost';

    return Axios
      .post(endpoint, {
        meta: {
          name: name,
          policy_application_id: policyApplicationId
        }
      })
      .then(res => {
        return res
      }).catch(error => {
        console.error(error)
      })
  }
}

export function getRegions() {
  return dispatch => {
    return Axios
      .get('products/regions')
      .then(res => {
        dispatch(setRegions(res.data.data))
        return true
      }).catch(error => {
        console.error(error)
      })
  }
}

export function getNextQuoteReference() {
  return dispatch => {
    dispatch(clearNextQuoteReference())
    return Axios.get('/policies/applications/quote-reference')
      .then(res => {
        dispatch(setNextQuoteReference(res.data.meta.quote_reference))
        return true
      }).catch(error => {
        console.error(error)
      })
  }
}

export function getSchemes(productId, filter) {
  return dispatch => {
    const endpoint = 'products/schemes?filter[product]=' + productId + (filter ? '&filter[status]=' + filter : '')

    return Axios
      .get(endpoint)
      .then(res => {
        dispatch(setSchemes(res.data.data))
        return true
      }).catch(error => {
        console.error(error)
      })
  }
}

export function getQuotes(productId, application, filters, formName) {
  return (dispatch) => {
    // Set max duration for upsell
    if (_.get(application, 'data.attributes.metadata.scheme_type') === 'single') {
      if (application.data.attributes.metadata.trip_duration) {
        application.data.attributes.metadata.max_trip_duration = application.data.attributes.metadata.trip_duration
      } else {
        const start = Moment(application.data.attributes.metadata.start_date)
        const end = Moment(application.data.attributes.metadata.end_date)
        application.data.attributes.metadata.max_trip_duration = end.diff(start, 'days')
      }
    }

    let endpoint = 'products/' + productId + ':calculate'

    if (filters) {
      endpoint += filterString(filters)
    }

    dispatch(isCalculating(true))
    dispatch({ type: actions.CLEAR_QUOTES })
    return Promise.resolve(dispatch(getQuotesAndSignposting(endpoint, application, formName))).then((quotes) => {
      quotes.map((quote) => {
        dispatch(setQuote({ meta: quote }))
      })
      dispatch(isCalculating(false))
      return true;
    }).catch(() => {
      dispatch(isCalculating(false))
    })
  }
}

export function getQuotesAndSignposting(endpoint, application, formName) {

  return (dispatch, getState) => {
    if (getState().screening.isRecalculatingScore > 0) {
      toastr.error('Re-score in progress', 'Please try again in a few moments')
      return false
    }

    dispatch(setSignpost(null));
    return Axios.post(endpoint, application).then(res => {
      if (Array.isArray(res.data.meta)) {
        const quotes = processQuotes(res.data.meta, isOwner(), application);
        if (quotes.signposted) {
          //get sign posting
          return Promise.resolve(dispatch(getSignPosting(quotes.signposted, application.data.id)))
            .then((resp) => {
              dispatch(formChange(formName, 'data.attributes.metadata.signposted', quotes.signposted))
              dispatch(setSignpost(resp.data.data.attributes.content));
              return quotes.quotes;
            });
        }

        dispatch(formChange(formName, 'data.attributes.metadata.signposted', ''))
        return quotes.quotes;
      }

    });
  }
}

const processQuotes = (quotes, showQuotesWithErrors, application) => {
  const hasOverrides = !_.isEmpty(get(application, 'data.attributes.metadata.overrides.gross_premium', {})) || !_.isEmpty(get(application, 'data.attributes.metadata.overrides.net', {}));
  const hasMedical = quoteHasMedical(application);
  const sortedquotes = {
    'quotes': [],
    'signposted': false
  };

  const quotesForSignposting = quotes.filter((quote) => {
    const errors = _.get(quote, 'errors', [])
    const hasErrors = errors && !!errors.length;
    const declines = _.get(quote, 'declines', []);
    const hasDeclines = declines && !!declines.length;
    const informationMedical = _.get(quote, 'information.medical', []);
    if (!hasErrors || showQuotesWithErrors) {
      sortedquotes.quotes.push(quote)
    }

    const hasMedicalExclusion = errors && errors.find((error) => {
      return error.includes('Exceeds maximum medical score') ||
        error.includes('Medical Condition has annual exclusion') ||
        error.includes('Medical Condition has winter sports exclusion') ||
        error.includes('Medical Declaration Question 4 is not covered') ||
        error.includes('Medical Declaration Question 5 is not covered') ||
        error.includes('Medical Exclusion is not allowed') ||
        error.includes('Max age for winter sports')
    })

    const hasInfoMedicalExclusion = informationMedical.length > 0;
    const hasMedicalErrors = (hasErrors && hasMedicalExclusion) || hasInfoMedicalExclusion;
    return (!hasErrors || hasMedicalErrors) && !hasDeclines;

  });

  if (!quotesForSignposting.length) {
    sortedquotes.signposted = false;
    return sortedquotes
  }

  const signposting = quotesForSignposting.reduce((carry, quote) => {
    const premiumSignposted = _.get(quote, 'premium.is_signposted', false);
    const errors = _.get(quote, 'errors', []);
    const informationMedical = _.get(quote, 'information.medical', []);
    const hasScreeningError = hasScreeningErrors(errors)
    const hasMedicalDecline = hasMedicalDeclines(errors)
    const hasMedicalExclusion = hasMedicalExclusions(informationMedical)
    carry.screening_errors = carry.screening_errors && hasScreeningError;
    carry.medical_declines = carry.medical_declines && hasMedicalDecline
    carry.medical_premiums = carry.medical_premiums && (!hasScreeningError && !hasMedicalDecline && premiumSignposted);
    carry.medical_exclusions = carry.medical_exclusions && (!hasScreeningError && !hasMedicalDecline && hasMedicalExclusion);

    return carry;

  }, {
    medical_premiums: true,
    screening_errors: true,
    medical_declines: true,
    medical_exclusions: true,

  });

  if (signposting.medical_premiums || hasOverrides && hasMedical) {
    sortedquotes.signposted = 'medical_premiums_b2b'
  }

  if (signposting.medical_exclusions) {
    sortedquotes.signposted = 'medical_exclusions_b2b'
  }
  if (signposting.screening_errors || signposting.medical_declines) {
    sortedquotes.signposted = 'medical_declines_b2b'
  }
  return sortedquotes;

}

export function getPremiumIndication(scheme, formName) {
  return (dispatch, getState) => {
    dispatch(isUpdatingPremiumIndication(true))
    const application = { data: formValueSelector(formName)(getState(), 'data') }

    return Axios.post('products/schemes/' + scheme.id + ':calculate', application).then(res => {
      dispatch(setPremiumIndication(res.data))
      dispatch(isUpdatingPremiumIndication(false))
      return true
    }).catch(() => {
      dispatch(isUpdatingPremiumIndication(false))
    })
  }
}

export function getPremiumOverrideCalculation(scheme, formName, cb) {
  return (dispatch, getState) => {
    const application = { data: formValueSelector(formName)(getState(), 'data') }
    dispatch(isPremiumAdjusting(true))
    return Axios.post('products/schemes/' + scheme.id + ':calculate', application).then(res => {
      dispatch(setPremiumOverrideCalculation(res.data))
      dispatch(isPremiumAdjusting(false))
      cb ? cb(true, res) : false;
      return true
    }).catch((error) => {
      dispatch(isPremiumAdjusting(false))
      cb ? cb(false, error) : false;
      handleErrorResponse(error)
    })
  }
}

export function calculateAdjustment(policyId, application, change, formName) {
  return dispatch => {
    dispatch(isCalculating(true))
    dispatch(setSignpost(null));
    dispatch({ type: actions.CLEAR_QUOTES })

    Axios.post('policies/' + policyId + '/premiums/mid-term-adjustment', application).then(res => {
      if (change) {
        const quote = res.data.meta;
        const medicalInfo = _.get(res.data, 'meta.information.medical', [])
        const changes = _.get(res.data, 'meta.mta.changes', [])
        const endorsementContent = _.get(application.data, 'attributes.metadata.endorsements[0].content', '');

        dispatch(change('data.relationships.schemes.data', [{ ...res.data.meta.scheme }]))
        dispatch(change('data.attributes.metadata.endorsements[0].title', 'Mid-term Adjustment'))
        dispatch(change('data.attributes.metadata.endorsements[0].content', changesToContent([...changes, medicalInfo], endorsementContent)))

        const signPosting = getMtaSignPosting(quote, application);
        if (signPosting) {
          return Promise.resolve(dispatch(getSignPosting(signPosting, application.data.id)))
            .then((resp) => {
              dispatch(formChange(formName, 'data.attributes.metadata.signposted', signPosting))
              dispatch(setSignpost(resp.data.data.attributes.content));
              if (!quote.declines.length) {
                dispatch(setQuote(res.data))
              }
            });
        }

      }
      dispatch(formChange(formName, 'data.attributes.metadata.signposted', ''))
      dispatch(setQuote(res.data))
      dispatch(isCalculating(false))
      return true
    }).catch(error => {
      const errors = error.response.data.errors.map((error) => error.detail);
      const signPosting = processMtaErrors(errors);
      if (signPosting) {
        return Promise.resolve(dispatch(getSignPosting(signPosting, application.data.id)))
          .then((resp) => {
            dispatch(formChange(formName, 'data.attributes.metadata.signposted', signPosting))
            dispatch(setSignpost(resp.data.data.attributes.content));
          });
      }
    })
      .finally(() => {
        dispatch(isCalculating(false))
      })
  }
}

const getMtaSignPosting = (quote, application) => {
  const isOldPremiumSignposted = quote.mta.old_premium.is_signposted;
  const isNewPremiumIsSignposted = quote.premium.is_signposted
  const hasMTAOverrides = !_.isEmpty(get(application, 'data.attributes.metadata.mta_overrides.gross_premium', {}));
  const hasMedical = quoteHasMedical(application);
  let isIncreaseOfMedicalLoad = false;

  if (isOldPremiumSignposted) {
    isIncreaseOfMedicalLoad = quote.mta.old_premium.medical < quote.mta.new_premium.medical;
  }

  const hasMTAOverrideWithMedical = hasMTAOverrides && hasMedical;
  const showPremiumSignposting = isNewPremiumIsSignposted && !isOldPremiumSignposted;
  // test for types of signposting
  if (showPremiumSignposting || isIncreaseOfMedicalLoad || hasMTAOverrideWithMedical) {
    return 'medical_premiums_b2b';
  }

  return false;
}

const quoteHasMedical = (formValues) => {
  const travellers = concat(get(formValues, 'data.attributes.metadata.adults', []), get(formValues, 'data.attributes.metadata.children', []));
  let hasScreening = false;
  forEach(travellers, (traveller) => {
    if (traveller.screening) {
      hasScreening = true;
    }
  });
  return hasScreening;
};

const processMtaErrors = (errors) => {

  // test for types of signposting - there's only one quote so this is similar than for the full panel
  if (hasScreeningErrors(errors)) {
    return 'screening_errors';
  }
  if (hasMedicalDeclines(errors)) {
    return 'medical_declines_b2b';
  }
  return false;
}

export function getProduct(id, includes = defaultIncludes) {
  return dispatch => {
    const endpoint = 'products/' + id + queryString(includes)

    return Axios
      .get(endpoint).then(res => {
        dispatch(setProduct(res.data))
        return true
      }).catch(error => {
        console.error(error)
      })
  }
}

export function saveAndExitQuote(formName, product) {
  return (dispatch, getState) => {
    dispatch(isSavingForExit(true))
    dispatch(submitSucceeded(false))
    const application = getFormValues(formName)(getState())

    if (product) {
      _.set(application, 'data.relationships.product.data.id', product.data.id)
    }

    if (isOwner() && (!_.get(application, 'data.relationships.broker.data.id') || !_.get(application, 'data.relationships.user.data.id'))) {
      dispatch(dispatch(isSavingForExit(false)))
      return toastr.error('Error', 'A valid broker is required')
    }

    return Axios.post('policies/applications?include=customer,schemes,premiums,policy,transactions', application).then(res => {
      dispatch(submitSucceeded(true))
      dispatch(push('/policies/applications/' + res.data.data.id))
      dispatch(clearQuotes())
      dispatch(isSavingForExit(false))
      return true
    }).catch(error => {
      dispatch(dispatch(isSavingForExit(false)))
      handleErrorResponse(error, 'There was an error saving your quote(s)')
    })
  }
}

export function saveQuotes(application, product, cb) {
  return dispatch => {
    if (!cb) {
      dispatch(submitSucceeded(false))
      dispatch(isSaving(true))
    }

    if (product) {
      _.set(application, 'data.relationships.product.data.id', product.data.id)
    }

    return Axios.post('policies/applications?include=customer,schemes,premiums,policy,transactions', application).then(res => {
      if (cb) {
        cb(res.data)
      } else {
        dispatch(submitSucceeded(true))
        dispatch(push('/policies/applications/' + res.data.data.id))
        dispatch(clearQuotes())
      }

      dispatch(isSaving(false))
      return true
    }).catch(error => {
      dispatch(isSaving(false))

      if (cb) {
        cb(null, true, errorResponseToArray(error))
      } else {
        handleErrorResponse(error, 'There was an error saving your quote(s)')
      }
    })
  }
}

export function saveQuotesOnly(application, productId, formName) {
  _.set(application, 'data.attributes.metadata.generate_documents', true);
  _.set(application, 'data.attributes.metadata.purchase_channel', null);

  return dispatch => {
    dispatch(isCalculating(true))

    if (productId) {
      _.set(application, 'data.relationships.product.data.id', productId);
    }

    return Axios.post('policies/applications?calculate=false', application).then(res => {
      dispatch(formChange(formName, 'data.id', res.data.data.id));
      dispatch(isCalculating(false))
      return res.data;
    }).catch(error => {
      dispatch(isCalculating(false))
      handleErrorResponse(error, 'There was an error saving your quote(s)');
    });
  };
}

export function saveReferral(application, product, cb) {
  return dispatch => {
    if (!cb) {
      dispatch(isSaving(true))
      dispatch(submitSucceeded(false))
    }

    if (product) {
      _.set(application, 'data.relationships.product.data.id', product.data.id)
    }

    return Axios.post('policies/applications?include=customer,schemes,premiums,policy', application).then(res => {
      return Axios.post('policies/applications/' + res.data.data.id + ':refer?include=customer,schemes,premiums,policy', application).then(referral => {
        if (cb) {
          cb(referral.data)
        } else {
          dispatch(submitSucceeded(true))
          dispatch(push('/policies/applications/' + referral.data.data.id))
          dispatch(clearQuotes())
        }

        dispatch(isSaving(false))
        return true
      }).catch(error => {
        if (cb) {
          cb(null)
        }
        dispatch(isSaving(false))
        handleErrorResponse(error, 'There was an error sending your quote for approval')
      })
      return true
    }).catch(error => {
      if (cb) {
        cb(null)
      }
      dispatch(isSaving(false))
      handleErrorResponse(error, 'There was an error saving your quote')
    })
  }
}

export function patchQuotes(application, cb) {
  return dispatch => {
    if (!cb) {
      dispatch(isSaving(true))
      dispatch(submitSucceeded(false))
    }

    return Axios.put('policies/applications/' + application.data.id + '?include=customer,schemes,premiums,policy', application).then(res => {
      if (cb) {
        cb(res.data)
      } else {
        dispatch(submitSucceeded(true))
        dispatch(push('/policies/applications/' + res.data.data.id))
        dispatch(clearQuotes())
      }

      dispatch(isSaving(false))
      return true
    }).catch(error => {
      dispatch(isSaving(false))

      if (cb) {
        cb(null, true, errorResponseToArray(error))
      } else {
        handleErrorResponse(error, 'There was an error saving your quote(s)')
      }
    })
  }
}

export function setTransactionId(transactionId) {
  transactionId = transactionId ? transactionId : null;

  return {
    type: actions.SET_TRANSACTION_ID,
    transactionId
  }
}

export function transactionEmergencyBrakes() {
  return (dispatch) => {
    dispatch(setTransactionId(null));
    dispatch(isIssuing(false))
  }
}

export function completeTransaction(policyId) {
  return (dispatch) => {
    dispatch(setTransactionId(null));
    dispatch(submitSucceeded(true))
    dispatch(push('/policies/issued/' + policyId))
    dispatch(isIssuing(false))
  }
}

export function failTransaction(message) {
  return (dispatch) => {
    dispatch(setTransactionId(null))
    dispatch(submitSucceeded(false))
    dispatch(isIssuing(false))

    toastr.error('Error', 'Transaction failed: ' + message)
  }
}

export function issuePolicy(applicationId, values) {
  return (dispatch) => {
    dispatch(isIssuing(true))
    dispatch(submitSucceeded(false))

    return Axios.post('policies/applications/' + applicationId + ':issue', values).then(res => {
      dispatch(submitSucceeded(true))
      dispatch(push('/policies/issued/' + res.data.data.id))
      dispatch(isIssuing(false))
      toastr.confirm('Please email or print the customer’s policy documents.',
        {
          okText: 'OK',
          disableCancel: true
        });
      return true
    }).catch(error => {
      let stopIssuing = true;

      _.each(error.response.data.errors, (e) => {
        if (e.id !== 'redirect-to-provider') {
          return;
        }

        dispatch(setTransactionId(e.data.transactionId));
        stopIssuing = false;
      });

      if (stopIssuing) {
        dispatch(isIssuing(false))
        handleErrorResponse(error, 'There was an error when issuing your policy')
      }
    })
  }
}
