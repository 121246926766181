import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DataTable, HorizontalFormControl } from '../../../../common/components'
import { connect } from 'react-redux'
import { refreshData, applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { push } from 'react-router-redux'
import { FormattedDate, FormattedTime } from 'react-intl'
import './styles.scss'

class DocumentsList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    product: PropTypes.object
  };

  formatDate(cell, row) {
    if (!cell) {
      return '-'
    }

    return (
        <span>
                <FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/>
            </span>
    )
  }

  handleRowClick(row) {
    const { product } = this.props
    if (product) {
      return this.props.dispatch(push('/admin/products/' + product.data.id + '/documents/' + row.id))
    }
    this.props.dispatch(push('/admin/documents/' + row.id))
  }

  handleSearchUpdate(event) {
    this.props.dispatch(applyFilter('documents', 'filter[name]', event.target.value))
  }

  refreshList() {
    this.props.dispatch(refreshData('documents'))
  }

  handleFilterUpdate(event) {
    this.props.dispatch(applyFilter('documents', 'filter[product]', event.target.value))
  }

  render() {
    const { product } = this.props

    return (
        <div>
          <h2 className="resource-name">Documents</h2>
          <div className="row  table-filters">
            <div className="col-sm-4">
              <HorizontalFormControl labelSize={3} label="Search" input={{ onChange: ::this.handleSearchUpdate }}/>
            </div>
          </div>

          <DataTable
              source={product ? '/products/schemes/documents' : '/documents'}
              autoFilterType="filter[product]"
              autoFilters={product ? [
                    { type: 'filter[product]', value: product.data.id },
                  ] : []}
              name="documents"
              showTotal={true}
              onRowSelect={this.handleRowClick.bind(this)}>

            <TableHeaderColumn dataField="id" isKey={true} hidden={true}>ID</TableHeaderColumn>
            <TableHeaderColumn dataField="name" dataSort={true}>Name</TableHeaderColumn>
            <TableHeaderColumn dataField="created_at" width={'170px'} dataSort={true} dataFormat={::this.formatDate}>Created
              at</TableHeaderColumn>
          </DataTable>

        </div>
    )
  }
}

export default connect()(DocumentsList)
