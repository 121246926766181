import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { SelectField } from '../../../../common/components/ReduxFormField'
import {
  Box,
  ButtonBar,
  Button,
  DatePicker,
  SelectProduct,
  SelectInsurer,
  SelectAccount,
  SelectBroker,
  SelectAccountingPeriod
} from '../../../../common/components'
import { generateReport } from '../../redux/accountActions'
import _ from 'lodash'
import './styles.scss'

const FORM_NAME = 'bordereauxReportsForm'

const validate = (values, endDateOnlyRequired) => {
  const errors = {}

  if (!_.get(values, 'report')) {
    errors.report = 'Report is required'
  }

  if (_.indexOf(endDateOnlyRequired, _.get(values, 'report')) === -1 && !_.get(values, 'from_date')) {
    errors.from_date = 'From date is required'
  }

  if (!_.get(values, 'to_date')) {
    errors.to_date = 'To date is required'
  }

  if (_.get(values, 'report') === 'broker_statement' && !_.get(values, 'account')) {
    errors.account = 'Account required'
  }

  return errors
}

class BordereauxReports extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    isGeneratingReport: PropTypes.bool.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      queue: false
    };
  }


  handleSubmit(values) {
    this.props.dispatch(generateReport(values))
  }

  handleGenerateOnQueue() {
    const { change, dispatch } = this.props;

    if (this.state.queue) {
      dispatch(change('generator', null));
    } else {
      dispatch(change('generator', 'queue'));
    }

    this.setState({ queue: !this.state.queue });
  }

  render() {
    const { formValues, handleSubmit, isGeneratingReport, endDateOnlyRequired } = this.props

    const reports = [
      { label: 'Month End Report', value: 'month_end_report:bespoke' },
      { label: 'Basic Report', value: 'basic_report:bespoke' },
      { label: 'Bordereaux', value: 'bordereaux' },
      { label: 'Paid Bordereaux', value: 'bordereaux_paid' },
      { label: 'Unpaid Bordereaux', value: 'bordereaux_unpaid' },
      { label: 'Broker Statement', value: 'broker_statement' },
      { label: 'Receipts List', value: 'receipts_list' },
      { label: 'Aged Debtors List', value: 'aged_debt' },
      { label: 'Deals Day Book', value: 'deals_day_book' }
    ]

    const reportTypes = [
      { label: 'None', value: '' },
      { label: 'Travel', value: 'travel' },
      // { label: 'Personal Accident', value: 'personal_accident' },
      // { label: 'Corporate Travel', value: 'corporate_travel' },
      // { label: 'Corporate Personal Accident', value: 'corporate_personal_accident' }
    ]

    const currencies = [
      { label: 'GBP', value: 'GBP' },
      { label: 'EURO', value: 'EUR' },
    ]

    const bordereauxReports = [
      'month_end_report:bespoke',
      'basic_report:bespoke',
      'bordereaux',
      'bordereaux_paid',
      'bordereaux_unpaid',
    ]

    return (
      <Box>
        <h2 className="resource-name">Bordereaux/Statements</h2>
        <form onSubmit={handleSubmit(::this.handleSubmit)}>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-horizontal">
                <Field
                    name="report"
                    label="Report"
                    labelSize={3}
                    component={SelectField}
                    options={reports}
                />

                {_.indexOf(bordereauxReports, _.get(formValues, 'report')) !== -1 && (
                  <Field
                      name="report_type"
                      label="Report Type"
                      labelSize={3}
                      component={SelectField}
                      options={reportTypes}
                  />
                )}

                {_.indexOf(bordereauxReports, _.get(formValues, 'report')) !== -1 && (
                  <Field
                      name="currency"
                      label="Currency"
                      labelSize={3}
                      component={SelectField}
                      options={currencies}
                  />
                )}

                {_.indexOf(bordereauxReports, _.get(formValues, 'report')) !== -1 && (
                  <Field
                      name="insurer"
                      label="Insurer"
                      labelSize={3}
                      component={SelectInsurer}
                  />
                )}

                {_.indexOf(endDateOnlyRequired, _.get(formValues, 'report')) !== -1 ? (
                  <div className="row">
                    <div className="col-sm-7">
                      <Field
                        name="to_date"
                        label="As at date"
                        labelSize={5}
                        component={DatePicker}
                      />
                    </div>
                  </div>
                ) : (
                  <div>

                    <Field
                      name="accounting_period"
                      label="Accounting Period"
                      labelSize={3}
                      component={SelectAccountingPeriod}
                    />

                    {!_.get(formValues, 'accounting_period') && (
                      <div className="row">
                        <div className="col-sm-12 date-group">
                          <div className="title-1">
                            From Date
                          </div>
                          <div className="date-box">
                            <div className="date-1">
                              <Field
                                  name="from_date"
                                  label=""
                                  labelSize={1}
                                  component={DatePicker}
                              />
                            </div>
                            <div className="title-2">
                              To
                            </div>
                            <div className="date-2">
                              <Field
                                  name="to_date"
                                  label=""
                                  minDate={_.get(formValues, 'from_date')}
                                  labelSize={1}
                                  component={DatePicker}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <div className="form-group">
                  <div className="col-sm-3">
                    Queue
                  </div>
                  <div className="col-sm-9">
                    <div className="queue-checkbox">
                      <div className="checkbox-container inline">
                        <div className="form-radio">
                          <input
                            id="Queue"
                            type="checkbox"
                            checked={this.state.queue}
                            onChange={() => this.handleGenerateOnQueue()}
                            value="generator"
                          />
                          <label htmlFor="Queue"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-sm-6">
              <div className="form-horizontal">

                {_.indexOf(bordereauxReports, _.get(formValues, 'report')) !== -1 && (
                  <Field
                      name="product"
                      label="Product"
                      labelSize={3}
                      component={SelectProduct}
                  />
                )}

                {(_.indexOf(bordereauxReports, _.get(formValues, 'report')) !== -1 || _.get(formValues, 'report') === 'broker_statement') && (
                  <Field
                      name="account"
                      label="Broker Account"
                      labelSize={3}
                      filters={[{ filter: 'filter[type]', value: 'debtor' }]}
                      component={SelectAccount}
                  />
                )}

                {(_.indexOf(bordereauxReports, _.get(formValues, 'report')) !== -1 || _.get(formValues, 'report') === 'broker_statement') && (
                  <Field
                      name="broker"
                      label="Sub Account (Broker/Branch)"
                      labelSize={3}
                      component={SelectBroker}
                  />
                )}

              </div>
            </div>
          </div>
          <ButtonBar>
            <Button
              className="pull-right"
              type="submit"
              label="Generate Report"
              isLoading={isGeneratingReport}
              bsStyle="primary"
            />
          </ButtonBar>
        </form>
      </Box>
    )
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(BordereauxReports)
const selector = formValueSelector(FORM_NAME)
const mapStateToProps = state => {
  const values = selector(state,
    'from_date',
    'to_date',
    'report',
    'accounting_period'
  )
  const endDateOnlyRequired = [
    'broker_statement',
    'aged_debt',
    'underwriters_statement:bespoke',
  ]
  return {
    endDateOnlyRequired: endDateOnlyRequired,
    formValues: values,
    isGeneratingReport: state.account.isGeneratingReport
  }
}
export default connect(mapStateToProps)(form)
