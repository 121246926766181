import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, FieldArray, formValueSelector } from 'redux-form'
import { Box, Button, SelectAccount } from '../../../../common/components'
import { PaymentReceipt } from '../PaymentReceipt'
import { savePaymentsAndReceipts } from '../../redux/accountActions'
import _ from 'lodash'
import './styles.scss'

const FORM_NAME = 'PaymentReceiptsForm'

const validate = (values) => {
  let errors = {}

  if (!_.get(values, 'meta.cash_account')) {
    _.set(errors, 'meta.cash_account', 'Account is required')
  }

  _.get(values, 'meta.ledger_entries', []).map((entry, i) => {
    if (!entry.transaction_type) {
      _.set(errors, 'meta.ledger_entries[' + i + '].transaction_type', 'Transaction type is required')
    }

    if (!entry.description) {
      _.set(errors, 'meta.ledger_entries[' + i + '].description', 'Description is required')
    }

    if (!entry.date) {
      _.set(errors, 'meta.ledger_entries[' + i + '].date', 'Date is required')
    }

    if (!entry.payment_type) {
      _.set(errors, 'meta.ledger_entries[' + i + '].payment_type', 'Type is required')
    }

    if (!entry.account) {
      _.set(errors, 'meta.ledger_entries[' + i + '].account', 'Account is required')
    }

    if (!entry.amount) {
      _.set(errors, 'meta.ledger_entries[' + i + '].amount', 'Amount is required')
    }
  })

  return errors
}

class PaymentReceiptsForm extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    formValues: PropTypes.array
  };

  handleSubmit(paymentsAndReceipts) {
    return this.props.dispatch(savePaymentsAndReceipts(paymentsAndReceipts))
  }

  render() {
    const { formValues, handleSubmit, submitting } = this.props

    return (
        <Box>
          <h2 className="resource-name">Add a payment/receipt</h2>
          <div className="payments-and-receipts-form">
            <form onSubmit={handleSubmit(::this.handleSubmit)}>
              <div className="payments-and-receipts-form-header">
                <div className="row form-horizontal">
                  <div className="col-sm-6">
                    <Field
                        name="meta.cash_account"
                        label="Cash Account"
                        labelSize={4}
                        component={SelectAccount}
                        filters={[{ filter: 'filter[type]', value: 'cash' }]}
                    />
                  </div>
                </div>
              </div>

              <div className="row form-section">
                <div className="col-xs-12">
                  <FieldArray
                      name="meta.ledger_entries"
                      component={PaymentReceipt}
                      formValues={_.get(formValues, 'meta.ledger_entries', [])}
                  />
                </div>
              </div>

              <div className="hr transparent"/>

              <div className="row actions">
                <div className="col-xs-12 clearfix">
                  <Button
                      label="Cancel"
                      bsStyle="primary"
                      className="pull-left"
                      link={true}
                      to="/accounts/bookkeeping"
                  />

                  <Button
                      label="Save Transactions"
                      bsStyle="primary"
                      className="pull-right"
                      type="submit"
                      isLoading={submitting}
                  />
                </div>
              </div>

            </form>
          </div>
        </Box>
    )

  }
}

const form = reduxForm({ form: FORM_NAME, validate })(PaymentReceiptsForm)
const selector = formValueSelector(FORM_NAME)
const mapStateToProps = (state, props) => {
  const values = selector(state,
      'meta.cash_account',
      'meta.ledger_entries',
  )

  return {
    formValues: values
  }
}
export default connect(mapStateToProps)(form)
