import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box, DataTable, SelectOrganisationType } from '../../../../common/components'
import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { FormControl, ControlLabel } from 'react-bootstrap'
import { Button } from '../../../../common/components'
import { hasPermission } from '../../../auth/redux/authActions'
import { push } from 'react-router-redux'
import { FormattedDate, FormattedTime } from 'react-intl'

export default class OrganisationList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    organisation: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props)

    this.state = {
      organisation_type: ''
    }
  }

  componentWillMount() {
    if (this.props.location.query.filter) {
      this.handleTypeFilterUpdate(this.props.location.query.filter)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.query.filter != this.props.location.query.filter) {
      this.handleTypeFilterUpdate(nextProps.location.query.filter)
    }
  }

  formatDate(cell, row) {
    return (
        <span><FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/></span>
    )
  }

  handleRowClick(row) {
    this.props.dispatch(push('/admin/organisations/' + row.id))
  }

  handleFilterUpdate(event) {
    this.props.dispatch(applyFilter('organisations', 'filter[status]', event.target.value))
  }

  handleSearchUpdate(event) {
    this.props.dispatch(applyFilter('organisations', 'filter[name]', event.target.value))
  }

  handleTypeFilterUpdate(value) {
    this.setState({ organisation_type: value })
    this.props.dispatch(applyFilter('organisations', 'filter[organisation.type]', value))
  }

  render() {
    const buttons = (hasPermission('organisation.create') ? (
      <Button
        link={true}
        to="/admin/organisations/new"
        className="pull-right"
        bsStyle="primary"
        label="New Organisation"
      />
    ) : (''))

    return (
      <div>
        <Box>
          <div className="row">
            <div className="col-sm-5">
              <ControlLabel className="filter-label">Search</ControlLabel>
              <FormControl onChange={ ::this.handleSearchUpdate } />
            </div>
            <div className="col-sm-5">
              <ControlLabel className="filter-label">Type</ControlLabel>
              <SelectOrganisationType
                input={{
                  onChange: ::this.handleTypeFilterUpdate,
                  value: this.state.organisation_type
                }}
              />
            </div>
            <div className="col-sm-2">
              <ControlLabel className="filter-label">Status</ControlLabel>
              <FormControl
                 componentClass="select"
                 type="select"
                 onChange={::this.handleFilterUpdate}
                 placeholder="select"
              >
                <option value="">Select...</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </FormControl>
            </div>
          </div>
        </Box>

        <Box>
          <DataTable
              source="/organisations"
              name="organisations"
              showTotal={true}
              autoFilters={[
                { type: 'filter[organisation.type]', value: this.state.organisation_type }
              ]}
              onRowSelect={this.handleRowClick.bind(this)}>

            <TableHeaderColumn dataField="id" isKey={true} hidden={true}>ID</TableHeaderColumn>
            <TableHeaderColumn dataField="name" dataSort={true}>Name</TableHeaderColumn>
            <TableHeaderColumn dataField="organisation_type_name" width={'180px'}>Type</TableHeaderColumn>
            <TableHeaderColumn dataField="phone1" width={'180px'}>Phone</TableHeaderColumn>
            <TableHeaderColumn dataField="status" dataSort={true} width={'180px'}>Status</TableHeaderColumn>
            <TableHeaderColumn dataField="created_at" dataFormat={this.formatDate} width={'180px'} dataSort={true}>Created</TableHeaderColumn>
          </DataTable>
          <div className="button-bar-inside">
            {buttons}
          </div>
        </Box>
      </div>
    )
  }
}
