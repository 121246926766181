import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import  './BrokerApplicationForm.scss'
import { Loader } from '../../../common/components'
import { getBrokerApplication } from '../redux/brokerApplicationActions'
import { updateBrokerApplication } from '../redux/brokerApplicationActions'
import BrokersApNewForm from '../components/BrokerApNewForm/BrokerApNewForm'
import InternalContainer from '../../layout/containers/Internal'

class BrokersApEditContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    brokerApplication: PropTypes.object.isRequired,
  }

  componentWillMount() {
    this.props.dispatch(getBrokerApplication(this.props.params.id ))
  }

  handleSubmit(resource) {
    if (resource.data.id) {
      return this.props.dispatch(updateBrokerApplication(resource))
    }
    return false
  }

  render() {
    const resource = this.props.brokerApplication.resources[this.props.params.id]

    return (
      <InternalContainer title={'Broker Application'}>
        {resource ? (
          <BrokersApNewForm
            {...this.props}
            resource={resource}
            onSubmit={this.handleSubmit.bind(this)}
          />
        ) : (
          <Loader />
        )}
      </InternalContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    brokerApplication: state.brokerApplication
  }
}

export default connect(mapStateToProps)(BrokersApEditContainer)
