import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { Button, Modal, DatePicker, HorizontalFormControl } from '../../../../common/components'
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux'
import { postAdjustment } from '../../redux/accountActions'
import _ from 'lodash'
import './styles.scss'

const FORM_NAME = 'makeAdjustmentForm'

const validate = values => {
  const errors = {}

  if (!_.get(values, 'data.attributes.date')) {
    _.set(errors, 'data.attributes.date', 'Date is required')
  }

  if (!_.get(values, 'data.attributes.amount')) {
    _.set(errors, 'data.attributes.amount', 'Amount is required')
  }

  return errors
}

class MakeAdjustmentButton extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    handleSubmit: PropTypes.func,
    resource: PropTypes.object.isRequired,
    amount: PropTypes.number.isRequired,
    callback: PropTypes.func,
    label: PropTypes.string,
    block: PropTypes.bool
  };

  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded, callback } = this.props
    if (newProps.submitSucceeded && !submitSucceeded) {
      if (callback) {
        callback('')
      }
      dispatch(closeModal(FORM_NAME))
    }
  }

  handleSubmit(values) {
    return this.props.dispatch(postAdjustment(values, this.props.resource))
  }

  handleOpen() {
    const { dispatch, change, reset, amount } = this.props
    dispatch(reset())
    dispatch(change('data.attributes.amount', amount))
    dispatch(openModal(FORM_NAME))
  }

  render() {
    const { label, handleSubmit, submitting, block } = this.props
    const title = label || 'Post Adjustment'
    const submitButton = (<Button
        type="submit"
        bsStyle="primary"
        isLoading={submitting}
        label="Save Adjustment"
    />)


    return (
        <div className="adjustment-btn-wrapper pull-right">
          <Button
              bsStyle="primary"
              label={title}
              handleClick={::this.handleOpen}
              block={block}
          />

          <Modal
              name={FORM_NAME}
              title={title}
              close={true}
              handleSubmit={handleSubmit(::this.handleSubmit)}
              footer={submitButton}
          >
            <div className="row form-horizontal">
              <div className="col-sm-8">
                <Field
                    name={'data.attributes.date'}
                    label="Date"
                    labelSize={3}
                    component={DatePicker}
                />
              </div>
            </div>
            <div className="row form-horizontal">
              <div className="col-sm-8">
                <Field
                    name={'data.attributes.amount'}
                    label="Amount"
                    labelSize={3}
                    component={HorizontalFormControl}
                />
              </div>
            </div>
          </Modal>
        </div>
    )
  }
}

const form = reduxForm({ form: FORM_NAME, validate })(MakeAdjustmentButton)
export default connect()(form)
