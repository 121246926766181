import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, formValueSelector } from 'redux-form'
import { StickyContainer, Sticky } from 'react-sticky'
import { Button, InfoLabel } from '../../../../common/components'
import { saveAccountMatching } from '../../redux/accountActions'
import MakeAdjustmentButton from '../MakeAdjustmentButton'
import MakePaymentButton from '../MakePaymentButton'
import LedgerEntry from './LedgerEntry'
import './styles.scss'
import displayAccountAmount from '../../../../helpers/displayAccountAmount'
import * as _ from 'lodash'

const FORM_NAME = 'accountMatching'

class AccountMatching extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    formValues: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    resource: PropTypes.object.isRequired,
    accountLabel: PropTypes.object.isRequired,
    matchingDate: PropTypes.string
  }

  state = {
    dateSet: false,
    unmatchedTransactions: [],
    selectedUnmatchedTransactions: [],
    unmatchedPremiums: [],
    selectedUnmatchedPremiums: []
  }

  componentWillUpdate(nextProps) {
    if (!_.isEqual(nextProps.account.transactions, this.props.account.transactions)
        || !_.isEqual(nextProps.account.premiums, this.props.account.premiums)
        || nextProps.account.filters !== this.props.account.filters
        || !_.isEqual(nextProps.resource, this.props.resource)) {
      this.resetMatching(nextProps)
    }
  }

  handleSubmit(matchedValues) {
    const accountId = this.props.resource.data.id
    _.set(matchedValues, 'meta.date', this.props.matchingDate)
    return this.props.dispatch(saveAccountMatching(matchedValues, accountId, ::this.reset))
  }

  reset() {
    this.props.reset()
    this.resetMatching(this.props)
  }

  getBalance(items, formatted = true) {
    let balance = 0.00

    if (items.length > 0) {
      items.map(item => {
        balance += parseFloat(item.attributes.amount)
      })
    }

    if (formatted) {
      return displayAccountAmount(balance)
    }

    return balance
  }

  onUnmatchedTransactionSelect(item) {
    if (_.some(this.state.selectedUnmatchedTransactions, item)) {
      let itemIndex = 0
      this.state.selectedUnmatchedTransactions.map((includedItem, i) => {
        if (includedItem.id === item.id) {
          itemIndex = i
        }
      })
      this.state.selectedUnmatchedTransactions.splice(itemIndex, 1)

      return this.setState({
        selectedUnmatchedTransactions: this.state.selectedUnmatchedTransactions
      })
    }

    this.state.selectedUnmatchedTransactions.push(item)

    return this.setState({
      selectedUnmatchedTransactions: this.state.selectedUnmatchedTransactions.slice()
    })
  }

  removeUnmatchedTransaction(item) {
    const { dispatch, formValues, change } = this.props
    const nextItems = _.get(formValues, 'meta.matched_transactions', [])

    if (_.some(nextItems, item)) {
      let itemIndex = 0
      nextItems.map((includedItem, i) => {
        if (includedItem.id === item.id) {
          itemIndex = i
        }
      })
      nextItems.splice(itemIndex, 1)
    }

    this.state.unmatchedTransactions.push(item)
    this.setState({
      unmatchedTransactions: this.state.unmatchedTransactions
    })

    return dispatch(change('meta.matched_transactions', nextItems.slice()))
  }

  matchTransactions() {
    const { dispatch, formValues, change } = this.props
    const nextItems = _.get(formValues, 'meta.matched_transactions', []).concat(this.state.selectedUnmatchedTransactions)
    const nextUnmatchedItems = this.state.unmatchedTransactions.slice()

    nextItems.map(item => {
      let itemIndex = false
      nextUnmatchedItems.map((includedItem, i) => {
        if (includedItem.id === item.id) {
          itemIndex = i
        }
      })
      if (itemIndex !== false) {
        nextUnmatchedItems.splice(itemIndex, 1)
      }
    })

    this.setState({
      unmatchedTransactions: nextUnmatchedItems.slice(),
      selectedUnmatchedTransactions: []
    })

    return dispatch(change('meta.matched_transactions', nextItems.slice()))
  }

  onUnmatchedPremiumSelect(item) {
    if (_.some(this.state.selectedUnmatchedPremiums, item)) {
      let itemIndex = 0
      this.state.selectedUnmatchedPremiums.map((includedItem, i) => {
        if (includedItem.id === item.id) {
          itemIndex = i
        }
      })
      this.state.selectedUnmatchedPremiums.splice(itemIndex, 1)

      return this.setState({
        selectedUnmatchedPremiums: this.state.selectedUnmatchedPremiums.slice()
      })
    }

    this.state.selectedUnmatchedPremiums.push(item)
    return this.setState({
      selectedUnmatchedPremiums: this.state.selectedUnmatchedPremiums.slice()
    })
  }

  removeUnmatchedPremium(item) {
    const { dispatch, formValues, change } = this.props
    const nextItems = _.get(formValues, 'meta.matched_premiums', [])

    if (_.some(nextItems, item)) {
      let itemIndex = 0
      nextItems.map((includedItem, i) => {
        if (includedItem.id === item.id) {
          itemIndex = i
        }
      })
      nextItems.splice(itemIndex, 1)
    }

    this.state.unmatchedPremiums.push(item)
    this.setState({
      unmatchedPremiums: this.state.unmatchedPremiums
    })

    return dispatch(change('meta.matched_premiums', nextItems.slice()))
  }

  matchPremiums() {
    const { dispatch, formValues, change } = this.props
    const nextItems = _.get(formValues, 'meta.matched_premiums', []).concat(this.state.selectedUnmatchedPremiums)
    const nextUnmatchedItems = this.state.unmatchedPremiums.slice()

    nextItems.map(item => {
      let itemIndex = false
      nextUnmatchedItems.map((includedItem, i) => {
        if (includedItem.id === item.id) {
          itemIndex = i
        }
      })
      if (itemIndex !== false) {
        nextUnmatchedItems.splice(itemIndex, 1)
      }
    })

    this.setState({
      unmatchedPremiums: nextUnmatchedItems.slice(),
      selectedUnmatchedPremiums: []
    })

    return dispatch(change('meta.matched_premiums', nextItems.slice()))
  }

  resetMatching(nextProps) {
    const { dispatch, change, account } = nextProps

    this.setState({
      unmatchedTransactions: account.transactions.data.slice(),
      selectedUnmatchedTransactions: [],
      unmatchedPremiums: account.premiums.data.slice(),
      selectedUnmatchedPremiums: []
    })

    dispatch(change('meta.matched_transactions', []))
    dispatch(change('meta.matched_premiums', []))
  }

  render() {
    const { handleSubmit, submitting, formValues } = this.props
    const resource = this.props.resource.data.attributes
    const matchedPremiums = _.get(formValues, 'meta.matched_premiums', [])
    const matchedTransactions = _.get(formValues, 'meta.matched_transactions', [])
    const matchedPremiumAmount = this.getBalance(_.get(formValues, 'meta.matched_premiums', []), false)
    const matchedTransactionAmount = this.getBalance(_.get(formValues, 'meta.matched_transactions', []), false)
    const difference = (matchedPremiumAmount + matchedTransactionAmount).toFixed(2)
    const balancesMatch = matchedTransactions.length + matchedPremiums.length > 0 && parseFloat(difference) === 0

    return (
      <form className="form-horizontal" onSubmit={handleSubmit(::this.handleSubmit)}>
        <h2 className="resource-name">{resource.name}{this.props.accountLabel}</h2>

        <div className="row accounts-matching">
          <div className="col-sm-10">
            <div className="row premium-container">
              <div className="col-sm-6">
                <div className={'sortable-container'}>
                  <h3>Payments/Receipts&nbsp;<span className="pull-right">{this.getBalance(this.state.unmatchedTransactions)}</span></h3>
                  {this.state.selectedUnmatchedTransactions.length > 0 && (
                      <div className="clearfix">
                        <p className="pull-left">Selected: {this.getBalance(this.state.selectedUnmatchedTransactions)}</p>
                        <Button size="xs" className="pull-right" label="Match Selected" bsStyle="success" handleClick={::this.matchTransactions} />
                      </div>
                  )}

                  <div className="items">
                    {this.state.unmatchedTransactions.map((item, i) => (
                      <LedgerEntry
                          key={i}
                          item={item}
                          matched={false}
                          selected={_.some(this.state.selectedUnmatchedTransactions, item)}
                          onItemSelect={() => this.onUnmatchedTransactionSelect(item)}
                      />
                    ))}
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className={'sortable-container'}>
                  <h3>Matched Payments/Receipts&nbsp;<span className="pull-right">{this.getBalance(matchedTransactions)}</span></h3>
                  <div className="items">
                    {matchedTransactions.length > 0 && matchedTransactions.map((item, i) => (
                      <LedgerEntry
                          key={i}
                          item={item}
                          matched={true}
                          selected={true}
                          onItemSelect={() => this.removeUnmatchedTransaction(item)}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="hr"/>

            <div className="row transaction-container">
              <div className="col-sm-6">
                <div className={'sortable-container'}>
                  <h3>Premiums <span className="pull-right">{this.getBalance(this.state.unmatchedPremiums)}</span></h3>

                  {this.state.selectedUnmatchedPremiums.length > 0 && (
                    <div className="clearfix">
                      <p className="pull-left">Selected: {this.getBalance(this.state.selectedUnmatchedPremiums)}</p>
                      <Button size="xs" className="pull-right" label="Match Selected" bsStyle="success" handleClick={::this.matchPremiums} />
                    </div>
                  )}

                  <div className="items">
                    {this.state.unmatchedPremiums.map((item, i) => (
                      <LedgerEntry
                          key={i}
                          item={item}
                          selected={_.some(this.state.selectedUnmatchedPremiums, item)}
                          onItemSelect={() => this.onUnmatchedPremiumSelect(item)}
                      />
                    ))}
                  </div>
                </div>
              </div>

              <div className="col-sm-6">
                <div className={'sortable-container'}>
                  <h3>Matched Premiums <span className="pull-right">{this.getBalance(matchedPremiums)}</span></h3>
                  <div className="items">
                    {matchedPremiums.length > 0 && matchedPremiums.map((item, i) => (
                      <LedgerEntry
                          key={i}
                          item={item}
                          matched={true}
                          selected={true}
                          onItemSelect={() => this.removeUnmatchedPremium(item)}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <StickyContainer className="matching-sidebar col-sm-2">
            <Sticky>
              <div className="matching-sidebar-header">
                <h3 className="align-center">Matched</h3>
              </div>
              <div className="matched-stats">
                <div className="col-xs-12 align-center">
                  <InfoLabel
                      label="Payments/Receipts"
                      labelSize={12}
                      value={matchedTransactions.length}
                  />
                </div>
                <div className="col-xs-12 align-center">
                  <InfoLabel
                      label="Premiums"
                      labelSize={12}
                      value={matchedPremiums.length}
                  />
                </div>
                <div className="col-xs-12 align-center">
                  <InfoLabel
                      label="Difference"
                      labelSize={12}
                      value={displayAccountAmount(difference)}
                  />
                </div>
              </div>

              {balancesMatch && (
                <Button
                    className="pull-right"
                    type="submit"
                    bsStyle="success"
                    label="Match"
                    isLoading={submitting}
                    block
                />
              )}

              {matchedPremiumAmount + matchedTransactionAmount < 0 && (
                <div>
                  <MakePaymentButton
                      className="pull-right"
                      bsStyle="primary"
                      label="Make Payment"
                      matchedPremiums={_.get(formValues, 'meta.matched_premiums', [])}
                      matchedTransactions={_.get(formValues, 'meta.matched_transactions', [])}
                      resource={this.props.resource}
                      amount={difference * -1}
                      callback={::this.reset}
                      block
                  />
                </div>
              )}

              {(!balancesMatch && matchedPremiumAmount > 0) && (
                <div>
                  <MakeAdjustmentButton
                      className="pull-right"
                      bsStyle="primary"
                      label="Post Adjustment"
                      resource={this.props.resource}
                      amount={difference}
                      block
                  />
                </div>
              )}

              <div className="actions">
                <div className="row">
                  {(matchedTransactions.length > 0 || matchedPremiums.length > 0) && (
                    <Button
                        label="Reset"
                        handleClick={() => this.resetMatching(this.props)}
                        block
                    />
                  )}
                </div>
              </div>
            </Sticky>
          </StickyContainer>
        </div>
      </form>
    )
  }
}

const form = reduxForm({ form: FORM_NAME })(AccountMatching)
const selector = formValueSelector(FORM_NAME)
function mapStateToProps(state) {
  const values = selector(state,
      'meta.matched_premiums',
      'meta.matched_transactions',
      'meta.date'
  )

  return {
    formValues: values,
    account: state.account
  }
}
export default connect(mapStateToProps)(form)
