import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box, DataTable, SelectOrganisation } from '../../../../common/components'
import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions'
import { brokerStatuses } from '../../../../schemas/broker'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { FormControl, ControlLabel } from 'react-bootstrap'
import { push } from 'react-router-redux'
import { FormattedDate, FormattedTime } from 'react-intl'

export default class BrokerList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    broker: PropTypes.object.isRequired
  };

  constructor() {
    super()

    this.state = {
      values: {
        search: '',
        organisation: '',
        status: ''
      }
    }
  }

  componentWillMount() {
    this.setState({
      values: {
        ...this.state.values,
        status: this.props.location.query.status ? this.props.location.query.status : ''
      }
    })
  }

  formatDate(cell, row) {
    return (
      <span><FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/></span>
    )
  }

  handleRowClick(row) {
    this.props.dispatch(push('/brokers/' + row.id))
  }

  handleFilterStatus(event) {
    this.setState({
      values: {
        ...this.state.values,
        status: event.target.value
      }
    })
    this.props.dispatch(applyFilter('brokers', 'filter[status]', event.target.value))
  }

  handleOrganisationFilterUpdate(event) {
    this.setState({
      values: {
        ...this.state.values,
        organisation: event
      }
    })

    this.props.dispatch(applyFilter('brokers', 'filter[organisation]', event))
  }

  handleSearchUpdate(event) {
    this.setState({
      values: {
        ...this.state.values,
        search: event.target.value
      }
    })
    this.props.dispatch(applyFilter('brokers', 'filter[name]', event.target.value))
  }

  brokerStatus(status) {
    return brokerStatuses[status]
  }

  render() {
    return (
      <div>
        <Box>
          <div className="row">
            <div className="col-sm-5">
              <ControlLabel className="filter-label">Search</ControlLabel>
              <FormControl onChange={ ::this.handleSearchUpdate } value={ this.state.search }/>
            </div>
            <div className="col-sm-5">
              <ControlLabel className="filter-label">Organisation</ControlLabel>
              <SelectOrganisation
                filters={[{ filter: 'filter[type]', value: 'Broker' }]}
                input={{
                  onChange: ::this.handleOrganisationFilterUpdate,
                  value: this.state.values.organisation
                }}
              />
            </div>
            <div className="col-sm-2">
              <div className="form-group">
                <ControlLabel className="filter-label">Status</ControlLabel>
                <FormControl
                  componentClass="select"
                  type="select"
                  onChange={::this.handleFilterStatus}
                  value={ this.state.values.status }
                  placeholder="select"
                >
                  <option value="">Select...</option>
                  {Object.keys(brokerStatuses).map((status, key) => {
                    return ( <option key={key} value={status}>{brokerStatuses[status]}</option>)
                  })}
                </FormControl>
              </div>
            </div>
          </div>
        </Box>

        <Box>
          <DataTable
            source="/brokers"
            name="brokers"
            autoFilter={this.state.values.status}
            autoFilterType="filter[status]"
            showTotal={true}
            onRowSelect={this.handleRowClick.bind(this)}>

            <TableHeaderColumn dataField="id" isKey={true} hidden={true}>ID</TableHeaderColumn>
            <TableHeaderColumn dataField="name" width={'380px'}>Name</TableHeaderColumn>
            <TableHeaderColumn dataField="branch_name" width={'200px'}>Branch</TableHeaderColumn>
            <TableHeaderColumn dataField="status" dataFormat={this.brokerStatus} dataSort={true}
                               width={'100px'}>Status</TableHeaderColumn>
            <TableHeaderColumn dataField="created_at" dataFormat={this.formatDate} width={'180px'} dataSort={true}>Created</TableHeaderColumn>
          </DataTable>
        </Box>
      </div>
    )
  }
}
