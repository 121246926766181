import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { saveQuotes } from '../../redux/productActions'
import displayCurrency from '../../../../helpers/displayCurrency'
import { Button, HorizontalFormControl, InfoLabel } from '../../../../common/components'
import { hasPermission } from '../../../auth/redux/authActions'
import PremiumBreakdown from '../PremiumBreakdown'


import * as _ from 'lodash'
import './styles.scss'

class AdjustmentForm extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
    currentProduct: PropTypes.object.isRequired,
    applicationId: PropTypes.string.isRequired,
    selectedSchemes: PropTypes.array.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    image: PropTypes.string,
    setStateOverride: PropTypes.func.isRequired,

  }

  constructor (props) {
    super(props)
    this.state = {
      override: ''
    }
    this.saveQuotes = this.saveQuotes.bind(this)
  }
  newPremium(override, meta) {
    if (override !== '') {
      return meta.mta.old_premium.gross + parseFloat(override)
    }

    return meta.mta.new_premium.gross
  }

  saveQuotes(application) {
    return this.props.dispatch(saveQuotes(application, this.props.currentProduct))
  }

  render() {
    const { product, currentProduct, selectedSchemes, handleSubmit } = this.props

    const selectedScheme = selectedSchemes && selectedSchemes.length === 1 && product.quotes[selectedSchemes[0].id ? selectedSchemes[0].id : selectedSchemes[0].data.id]
    const medicalInformation = _.get(selectedScheme, 'meta.information.medical', [])
    const errors = _.get(selectedScheme, 'meta.errors')

    const taxType = currentProduct.data.attributes.metadata.tax_type
    return (
      <div className="adjustment-form">
        {selectedScheme && errors.length === 0 ? (
          <div className="quote-actions">
            {medicalInformation.length > 0 && (
              <div className="alert alert-danger" role="alert">
                Please note there are travellers with excluded medical conditions on this MTA
              </div>
            )}
            <h3>Adjustments Made</h3>
            {hasPermission('policy.mta') ? (
              <div>
                <div className="row no-left-gutter adjustment-details">
                  <div className="col-sm-5 scheme-for-purchase">
                    <div className="row">
                      <div className="col-xs-9">
                        <div>
                          <h4>Total amount due</h4>
                          {_.has(selectedScheme, 'meta') ? (
                            <PremiumBreakdown
                              premium={_.get(selectedScheme, 'meta') }
                              block
                              showIpt
                              taxType={taxType}
                            />) : <div> {product.isPremiumAdjusting ? 'Loading...' : ''} </div>}
                          <p className='scheme-name'>
                            {_.get(selectedScheme, 'meta.scheme.name')}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-7">
                    <div className="adjustment-premiums">
                      {selectedScheme.meta.mta.old_premium && (
                        <InfoLabel
                          label="Original premium"
                          value={displayCurrency(selectedScheme.meta.mta.old_premium.gross, selectedScheme.meta.premium.currency)}
                          className="disabled"
                          labelSize={6}
                        />
                      )}
                      {selectedScheme.meta.mta.new_premium && (
                        <InfoLabel
                          label="New total premium"
                          value={displayCurrency(
                            this.newPremium(this.state.override, selectedScheme.meta),
                            selectedScheme.meta.premium.currency
                          )}
                          labelSize={6}
                        />
                      )}
                      <HorizontalFormControl
                        label="Override (total due)"
                        currency={true}
                        labelSize={6}
                        input={{
                          onChange: (e) => {
                            const override = e.target.value
                            this.setState({ override })
                            const { setStateOverride } = this.props;

                            setStateOverride({ override })

                            this.props.change(
                              'data.attributes.metadata.mta_overrides.gross_premium',
                              override === '' ? null : this.newPremium(override, selectedScheme.meta)
                            )
                          },
                          value: this.props.overrideState.override
                        }}
                      />
                    </div>
                  </div>
                </div>
                {/*<div className="row">*/}
                {/*  <div className="col-sm-offset-7 col-sm-5">*/}
                {/*    <IssueButton*/}
                {/*      label="Proceed with Adjustment"*/}
                {/*      className="pull-right"*/}
                {/*      bsStyle="success"*/}
                {/*      block={true}*/}
                {/*      mta={true}*/}
                {/*      policyId={this.props.applicationId}*/}
                {/*      saveApplication={handleSubmit}*/}
                {/*      product={currentProduct}*/}
                {/*      schemeId={selectedScheme.meta.scheme.id}*/}
                {/*      declaration={*/}
                {/*        _.get(currentProduct, 'data.attributes.metadata.purchase_declaration', '')*/}
                {/*      }*/}
                {/*    />*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>
            ) : (
              <div>
                <ul>
                  {_.get(selectedScheme, 'meta.mta.changes', []).length === 0 && (
                    <li>None</li>
                  )}
                  {_.get(selectedScheme, 'meta.mta.changes', []).map((adjustment, i) => (
                    <li key={i}>{adjustment}</li>
                  ))}
                </ul>
                <div className="row">
                  <div className="actions col-sm-4">
                    <Button
                      label="Request Approval"
                      bsStyle="primary"
                      rightIcon="check-square-o"
                      block={true}
                      isLoading={product.isSaving}
                      handleClick={handleSubmit(::this.saveQuotes)}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>
            {!product.isCalculating && (
              <div>
                <h3>No cover available</h3>
                <p>There is no cover available based on the quotation criteria for your mid-term adjustment.</p>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default connect()(AdjustmentForm)
