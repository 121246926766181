import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field } from 'redux-form'
import flow from 'lodash/flow'
import { Button, Icon, HorizontalFormControl } from '../../../../common/components'
import './TripDurationForm.scss'

class TripDurationForm extends Component {
  static propTypes = {
    fields: PropTypes.object.isRequired
  }

  render() {
    const { fields, meta } = this.props

    return (
      <div className='admin-trip-options'>

        {fields.map((tripDuration, index) => {
          return (
            <div key={index} className='row'>
              <div className='col-xs-12'>
                  <span
                    className='remove-trip-options pull-right'
                    onClick={() => fields.remove(index)}>
                    <Icon name='times' />
                  </span>
                {<label className="control-label">{'Annual Trip Duration ' + (index + 1)}</label>}
              </div>
              <div className="row">
                <div className="col-xs-12">
                  <Field
                    label="Label"
                    name={tripDuration + '.value'}
                    type="textarea"
                    component={HorizontalFormControl}
                  />
                </div>
              </div>
              <div>
                <div className="row">
                  <div className="col-xs-12">
                    <Field
                      label="Value"
                      name={tripDuration + '.label'}
                      type="textarea"
                      component={HorizontalFormControl}
                    />
                  </div>
                </div>
              </div>
            </div>
          )
        })}
        <div>
          <div className="row">
            <div className="col-xs-2 col-md-2 col-lg-2"/>
            <div className="col-xs-4 col-md-3 col-lg-2 add-region-button">
              <Button type="button" handleClick={() => fields.push({ type: 'trip' })}>Add Annual Trip Duration</Button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default flow(
  connect()
)(TripDurationForm)
