import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {logOut, hasPermission, isOwner} from '../../../auth/redux/authActions'
import {openModal} from '../../../../common/components/Modal/ModalRedux'
import pluralize from 'pluralize'
import Icon from '../../../../common/components/Icon/Icon'
import './styles.scss'
import {browserHistory} from 'react-router'
import {push} from 'react-router-redux'


export default class InternalNavigation extends Component {
  static propTypes = {
    auth: PropTypes.object.isRequired,
    layout: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props)

    this.route = this.route.bind(this)
    this.openSettingsModal = this.openSettingsModal.bind(this)
    this.toggleDropdownSettings = this.toggleDropdownSettings.bind(this)
    this.toggleDropdownAccounts = this.toggleDropdownAccounts.bind(this)
    this.toggleDropdownOrganisations = this.toggleDropdownOrganisations.bind(this)
    this.toggleDropdownPolicy = this.toggleDropdownPolicy.bind(this)
    this.toggleDropdownAdmin = this.toggleDropdownAdmin.bind(this)
    this.toggleReports = this.toggleReports.bind(this)
    this.toggleHome = this.toggleHome.bind(this)

    this.state = {
      dropdownSettings: false,
      dropdownAccounts: false,
      dropdownOrganisations: false,
      dropdownPolicy: false,
      dropdownAdmin: false
    }
  }

  componentDidMount() {
    if (this.props.auth.user.data.relationships.current_organisation.data === null) {
      this.props.dispatch(openModal('organisation-settings'))
    }
  }

  toggleDropdownSettings() {
    this.setState(prevState => ({
      dropdownSettings: !prevState.dropdownSettings,
      dropdownAccounts: false,
      dropdownstions: false,
      dropdownPolicy: false,
      dropdownAdmin: false
    }))
  }

  toggleDropdownAccounts() {
    this.setState(prevState => ({
      dropdownAccounts: !prevState.dropdownAccounts,
      dropdownSettings: false,
      dropdownOrganisations: false,
      dropdownPolicy: false,
      dropdownAdmin: false
    }))
  }

  toggleDropdownOrganisations() {
    this.setState(prevState => ({
      dropdownOrganisations: !prevState.dropdownOrganisations,
      dropdownSettings: false,
      dropdownAccounts: false,
      dropdownPolicy: false,
      dropdownAdmin: false
    }))
  }

  toggleDropdownPolicy() {
    this.setState(prevState => ({
      dropdownPolicy: !prevState.dropdownPolicy,
      dropdownSettings: false,
      dropdownAccounts: false,
      dropdownOrganisations: false,
      dropdownAdmin: false
    }))
  }

  toggleDropdownAdmin() {
    this.setState(prevState => ({
      dropdownAdmin: !prevState.dropdownAdmin,
      dropdownSettings: false,
      dropdownAccounts: false,
      dropdownOrganisations: false,
      dropdownPolicy: false
    }))
  }

  toggleReports() {
    this.setState({
      dropdownSettings: false,
      dropdownAccounts: false,
      dropdownOrganisations: false,
      dropdownPolicy: false,
      dropdownAdmin: false
    })
  }

  toggleHome() {
    this.setState({
      dropdownSettings: false,
      dropdownAccounts: false,
      dropdownOrganisations: false,
      dropdownPolicy: false,
      dropdownAdmin: false
    })
  }

  openSettingsModal(e) {
    e.preventDefault()
    this.props.dispatch(openModal('settings'))
  }

  openOrganisationModal(e) {
    e.preventDefault()
    this.props.dispatch(openModal('organisation-settings'))
  }

  handleLogout(e) {
    e.preventDefault()
    this.props.dispatch(logOut())
  }

  route(id) {
    const {dispatch} = this.props
    dispatch(push('/' + id))

  }

  render() {
    const {auth, layout} = this.props
    const {dropdownSettings, dropdownAccounts, dropdownOrganisations, dropdownPolicy, dropdownAdmin} = this.state
    const getCurrentUrl = browserHistory ? browserHistory.getCurrentLocation() : ''

    const menuActiveProducts = function (url) {
      if (getCurrentUrl.pathname === '/' + url) {
        return 'menu-item selected'
      }
      if (getCurrentUrl.pathname && getCurrentUrl.pathname.indexOf('quote') !== -1) {
        return 'menu-item selected'
      } else {
        return 'menu-item'
      }
    }

    const menuActive = function (url) {
      if (getCurrentUrl.pathname === '/' + url) {
        return 'menu-item selected'
      } else {
        return 'menu-item'
      }
    }

    const subMenuActive = function (url) {
      if (getCurrentUrl.pathname === '/' + url) {
        return 'dropdown-menu-item selected'
      }
      if (getCurrentUrl.pathname.indexOf(url) !== -1) {
        return 'dropdown-menu-item selected'
      } else {
        return 'dropdown-menu-item'
      }
    }

    const subMenuActiveOrganisations = function (url) {
      if (getCurrentUrl.pathname + getCurrentUrl.search === '/' + url) {
        return 'dropdown-menu-item selected'
      } else {
        return 'dropdown-menu-item'
      }
    }

    return (
      <div>
        <div onClick={this.toggleHome}>
          <div className={menuActive('dashboard')} onClick={() => this.route('dashboard')}>
            <div className="menu-icon"><Icon name="home"/></div>
            <div className="menu-text"> Home</div>
          </div>
        </div>

        <div className="menu-item" onClick={this.toggleDropdownSettings}>
          <div className="menu-icon"><Icon name="cog"/></div>
          <div className="menu-text"> Settings</div>
          <div className={dropdownSettings ? 'chevron-icon rotate' : 'chevron-icon'}><Icon name="chevron-right"/></div>
        </div>

        {dropdownSettings && (
          <div className="dropdown-box">
            <div className="dropdown-menu-item" onClick={this.openSettingsModal.bind(this)}>
              <div className="dropdown-menu-text"> Personal Settings</div>
            </div>
            <div className="dropdown-menu-item" onClick={this.openOrganisationModal.bind(this)}>
              <div className="dropdown-menu-text"> Organisation Settings</div>
            </div>
          </div>
        )}

        <div className="divider"/>

        {hasPermission('policy.quote') && (
          <div className={menuActiveProducts('products')} onClick={() => this.route('products')}>
            <div className="menu-icon"><Icon name="plane"/></div>
            <div className="menu-text"> Get Quotes</div>
          </div>
        )}

        {hasPermission('policy.view') && (
          <div>
            <div className="menu-item" onClick={this.toggleDropdownPolicy}>
              <div className="menu-icon"><Icon name="tasks"/></div>
              <div className="menu-text"> Policy Management</div>
              <div className={dropdownPolicy ? 'chevron-icon rotate' : 'chevron-icon'}><Icon name="chevron-right"/>
              </div>
            </div>

            {dropdownPolicy && (
              <div className="dropdown-box">
                <div className={subMenuActive('policies/applications')}
                     onClick={() => this.route('policies/applications')}>
                  <div className="dropdown-menu-text"> Quotes</div>
                </div>
                <div className={subMenuActive('policies/issued')} onClick={() => this.route('policies/issued')}>
                  <div className="dropdown-menu-text"> Policies</div>
                </div>
                <div className={subMenuActive('policies/mid-term-adjustments')}
                     onClick={() => this.route('policies/mid-term-adjustments')}>
                  <div className="dropdown-menu-text"> Mid Term Adjustments</div>
                </div>
                <div className={subMenuActive('policies/referrals')} onClick={() => this.route('policies/referrals')}>
                  <div className="dropdown-menu-text"> Referrals</div>
                </div>

                {isOwner() &&
                <div className={subMenuActive('policies/uninvited-renewals')}
                     onClick={() => this.route('policies/uninvited-renewals')}>
                  <div className="dropdown-menu-text"> Uninvited Renewals</div>
                </div>
                }

                <div className={subMenuActive('policies/renewals')} onClick={() => this.route('policies/renewals')}>
                  <div className="dropdown-menu-text">{isOwner() ? 'Active Renewals' : 'Renewals'}</div>
                </div>

                <div className={subMenuActive('customers')} onClick={() => this.route('customers')}>
                  <div className="dropdown-menu-text"> Customers</div>
                </div>
              </div>
            )}
          </div>
        )}

        {isOwner() && hasPermission('broker.view') && (
          <div>
            <div className="menu-item" onClick={this.toggleDropdownOrganisations}>
              <div className="menu-icon"><Icon name="sitemap"/></div>
              <div className="menu-text"> Organisations</div>
              <div className={dropdownOrganisations ? 'chevron-icon rotate' : 'chevron-icon'}><Icon
                name="chevron-right"/></div>
            </div>

            {dropdownOrganisations && (
              <div className="dropdown-box">
                {hasPermission('organisation.view') && layout.organisationTypes && layout.organisationTypes.map((type, i) => {
                  return (
                    <div key={i + 'type'}
                         className={subMenuActiveOrganisations('admin/organisations?filter=' + type.id)}
                         onClick={() => this.route('admin/organisations?filter=' + type.id)}>
                      <div className="dropdown-menu-text"> {pluralize(type.attributes.name)} </div>
                    </div>
                  )
                })}
                <div className={subMenuActive('admin/broker-applications')}
                     onClick={() => this.route('admin/broker-applications')} style={{marginBottom: '0'}}>
                  <div className="dropdown-menu-text"> Broker Applications</div>
                </div>
              </div>
            )}
          </div>
        )}


        {isOwner() && hasPermission('account.view') && (
          <div>
            <div className="menu-item" onClick={this.toggleDropdownAccounts}>
              <div className="menu-icon"><Icon name="university"/></div>
              <div className="menu-text"> Accounts</div>
              <div className={dropdownAccounts ? 'chevron-icon rotate' : 'chevron-icon'}><Icon name="chevron-right"/>
              </div>
            </div>

            {dropdownAccounts && (
              <div className="dropdown-box">
                <div className={subMenuActive('accounts/summary')} onClick={() => this.route('accounts/summary')}>
                  <div className="dropdown-menu-text"> Summary</div>
                </div>
                <div className={subMenuActive('accounts/ledger')} onClick={() => this.route('accounts/ledger')}>
                  <div className="dropdown-menu-text"> Ledger</div>
                </div>
                <div className={subMenuActive('accounts/matching')} onClick={() => this.route('accounts/matching')}>
                  <div className="dropdown-menu-text"> Matching</div>
                </div>
                <div className={subMenuActive('accounts/bookkeeping')}
                     onClick={() => this.route('accounts/bookkeeping')}>
                  <div className="dropdown-menu-text"> Bookkeeping</div>
                </div>
              </div>
            )}
          </div>
        )}

        {(hasPermission('report.bdx') || hasPermission('report.predefined')) && (
          <div onClick={this.toggleReports}>
            <div className={menuActive('reports')} onClick={() => this.route('reports')}>
              <div className="menu-icon"><Icon name="bar-chart"/></div>
              <div className="menu-text"> Reports</div>
            </div>
          </div>
        )}

        {(hasPermission('content_management.view') || hasPermission('scheme.view') || hasPermission('organisation.view') || hasPermission('user.view') || hasPermission('role.view')) && (
          <div>
            <div className="menu-item" onClick={this.toggleDropdownAdmin}>
              <div className="menu-icon"><Icon name="cogs"/></div>
              <div className="menu-text"> Admin</div>
              <div className={dropdownAdmin ? 'chevron-icon rotate' : 'chevron-icon'}><Icon name="chevron-right"/></div>
            </div>

            {dropdownAdmin && (
              <div className="dropdown-box">
                {isOwner() && hasPermission('content_management.view') && (
                  <div>
                    <div className={subMenuActive('admin/announcements')}
                         onClick={() => this.route('admin/announcements')}>
                      <div className="dropdown-menu-text"> Announcements</div>
                    </div>
                    <div className={subMenuActive('admin/content')} onClick={() => this.route('admin/content')}>
                      <div className="dropdown-menu-text"> Content</div>
                    </div>
                  </div>
                )}
                <div className={subMenuActive('admin/campaigns')} onClick={() => this.route('admin/campaigns')}>
                  <div className="dropdown-menu-text"> Campaigns</div>
                </div>
                {isOwner() && hasPermission('marketing.campaign_view') && (
                  <div className={subMenuActive('admin/tracking-links')}
                       onClick={() => this.route('admin/tracking-links')}>
                    <div className="dropdown-menu-text"> Tracking Links</div>
                  </div>
                )}
                {isOwner() && hasPermission('scheme.view') && (
                  <div className={subMenuActive('admin/products')} onClick={() => this.route('admin/products')}>
                    <div className="dropdown-menu-text"> Products</div>
                  </div>
                )}
                {isOwner() && hasPermission('role.view') && (
                  <div className={subMenuActive('admin/roles')} onClick={() => this.route('admin/roles')}>
                    <div className="dropdown-menu-text"> Roles</div>
                  </div>
                )}
                {isOwner() && (hasPermission('content_management.view') || hasPermission('scheme.view')) && (
                  <div className="divider"/>
                )}
                {hasPermission('user.view') && (
                  <div className={subMenuActive('admin/users')} onClick={() => this.route('admin/users')}>
                    <div className="dropdown-menu-text"> Users</div>
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        <div onClick={this.toggleReports}>
          <div className={menuActive('help-centre')} onClick={() => this.route('help-centre')}>
            <div className="menu-icon"><Icon name="question-circle"/></div>
            <div className="menu-text"> Help Center</div>
          </div>
        </div>

      </div>
    )
  }
}
