export default function renewalLapseReasons() {
  return [
    { label: 'Fraud', value: 'Fraud' },
    { label: 'Customer not travelling soon', value: 'Customer not travelling soon' },
    { label: 'Not enough trips planned for AMT', value: 'Not enough trips planned for AMT' },
    { label: 'Too expensive', value: 'Too expensive' },
    { label: 'No longer required', value: 'No longer required' },
    { label: 'Not travelling', value: 'Not travelling' },
    { label: 'Alternative insurance', value: 'Alternative insurance' },
    { label: 'Product not suitable', value: 'Product not suitable' },
    { label: 'Max Premium', value: 'Max Premium' },
    { label: 'Poor Service', value: 'Poor Service' },
    { label: 'Waiting list', value: 'Waiting list' },
    { label: 'Not known', value: 'Not known' },
    { label: 'Deceased Policy Holder', value: 'Deceased Policy Holder' },
    { label: 'Exceeded age limit', value: 'Exceeded age limit' },
    { label: 'Underwriting Criteria', value: 'Underwriting Criteria' },
    { label: 'Other', value: false },
  ];
}
