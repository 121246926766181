import React from 'react'

export const organisations = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95 95">
    <title>Icons</title>
    <path d="M82.5,73.75a3.75,3.75,0,0,1-3.75,3.75H66.25a3.75,3.75,0,0,1-3.75-3.75V61.25a3.75,3.75,0,0,1,3.75-3.75H70V50H50v7.5h3.75a3.75,3.75,0,0,1,3.75,3.75v12.5a3.75,3.75,0,0,1-3.75,3.75H41.25a3.75,3.75,0,0,1-3.75-3.75V61.25a3.75,3.75,0,0,1,3.75-3.75H45V50H25v7.5h3.75a3.75,3.75,0,0,1,3.75,3.75v12.5a3.75,3.75,0,0,1-3.75,3.75H16.25a3.75,3.75,0,0,1-3.75-3.75V61.25a3.75,3.75,0,0,1,3.75-3.75H20V50a5,5,0,0,1,5-5H45V37.5H41.25a3.75,3.75,0,0,1-3.75-3.75V21.25a3.75,3.75,0,0,1,3.75-3.75h12.5a3.75,3.75,0,0,1,3.75,3.75v12.5a3.75,3.75,0,0,1-3.75,3.75H50V45H70a5,5,0,0,1,5,5v7.5h3.75a3.75,3.75,0,0,1,3.75,3.75Z"/>
  </svg>
)

export default organisations
