import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {isOwner} from '../../auth/redux/authActions'
import {Box, Loader} from '../../../common/components'
import StatsSection from '../components/StatsSection'
import StatsSectionTable from '../components/StatsSectionTable'
import StatsSectionConversionLabel from '../components/StatsSectionConversionLabel'
import InternalContainer from '../../layout/containers/Internal'
import StarBrokerStats from '../components/StarBrokerStats'
import _ from 'lodash'
import displayCurrency from '../../../helpers/displayCurrency'
import getIncludedResource from '../../../helpers/getIncludedResource'
import {FormControl} from 'react-bootstrap'
import {fetchStats, clearData, fetchBrokerStats} from '../redux/dashboardActions'
import './Dashboard.scss'
import Icon from '../../../common/components/Icon/Icon'
import {Link} from 'react-router'
import Slider from 'react-slick';
import ProductsCarousel from "../components/ProductsCarousel/ProductsCarousel";


class DashboardContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    auth: PropTypes.object.isRequired,
    dashboard: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      day: true,
      week: false,
      month: false,
      loginToday: true,
      loginWeek: false,
      brokerToday: true,
      brokerWeek: false,
      brokerMonth: false,
      selection: '',
      loginSelection: '',
      brokerSelection: '',
      range: 'day'
    }
  }

  componentWillMount() {
    this.props.dispatch(fetchStats())
    this.props.dispatch(fetchBrokerStats(this.state.range))
  }

  setRange(range) {
    this.setState({range}, () => {
      this.props.dispatch(fetchBrokerStats(range))
    })
  }

  componentWillUnmount() {
    this.props.dispatch(clearData())
  }

  handleFilterLogins(event) {
    this.setState({loginSelection: event.target.value})
    switch (event.target.value) {
      case 'today':
        this.setState({loginToday: true, loginWeek: false})
        break
      case 'week':
        this.setState({loginToday: false, loginWeek: true})
        break
      default:
        return
    }
  }

  handleBrokerSelection(event) {
    this.setState({brokerSelection: event.target.value})
    switch (event.target.value) {
      case 'today':
        this.setRange('day')
        this.setState({brokerToday: true, brokerWeek: false, brokerMonth: false})
        break
      case 'week':
        this.setRange('week')
        this.setState({brokerWeek: true, brokerToday: false, brokerMonth: false})
        break
      case 'month':
        this.setRange('month')
        this.setState({brokerMonth: true, brokerToday: false, brokerWeek: false})
        break
      default:
        return
    }
  }

  handleFilterUpdate(event) {
    this.setState({selection: event.target.value})
    switch (event.target.value) {
      case 'today':
        this.setState({day: true, month: false, week: false})
        break
      case 'week':
        this.setState({week: true, day: false, month: false})
        break
      case 'month':
        this.setState({month: true, week: false, day: false})
        break
      default:
        return
    }
  }

  customSelection() {
    return (
      <div className="select-box">
        <FormControl
          componentClass="select"
          className="select"
          type="select"
          onChange={::this.handleFilterUpdate}
          value={this.state.selection}
          placeholder="select"
        >
          <option key="0" value="today">Today</option>
          <option key="1" value="week">This week</option>
          <option key="2" value="month">This month</option>
        </FormControl>
      </div>
    )
  }

  customSelectionLogins() {
    return (
      <FormControl
        componentClass="select"
        className="select"
        type="select"
        onChange={::this.handleFilterLogins}
        value={this.state.loginSelection}
        placeholder="select"
      >
        <option key="0" value="today">Today</option>
        <option key="1" value="week">This week</option>
      </FormControl>
    )
  }

  calcPercentage(n1, n2) {
    let percentChange = null
    if ((n1 !== 0) && (n2 !== 0)) {
      percentChange = (n1 / n2) * 100
    } else percentChange = null
    return percentChange
  }

  renderBroker() {
    const {dashboard} = this.props
    const stats = _.get(dashboard.broker_stats, 'meta[0].broker', null) || ''
    const statistics = stats.statistics || ''
    return (
      <Box minHeight={false} className="middlebar">
        <div className="select-box">
          <FormControl
            componentClass="select"
            className="select"
            type="select"
            onChange={::this.handleBrokerSelection}
            value={this.state.brokerSelection}
            placeholder="select"
          >
            <option key="0" value="today">Top Broker (Today)</option>
            <option key="1" value="week">Top Broker (Week)</option>
            <option key="2" value="month">Top Broker (Month)</option>
          </FormControl>
        </div>
        {stats ? (
          <div className="list-group list-group-horizontal">
            <div className="list-group-item">
              <div className="list-group-item-text"><h4>{stats.name}{'\n'}</h4></div>
              <br/>
            </div>
            <div className="list-group-item">
              <div className="list-group-item-text">
                <small> No. of Quotes</small>
              </div>
              {statistics.no_quoted || null}
            </div>
            <div className="list-group-item">
              <div className="list-group-item-text">
                <small> No. of Policies</small>
              </div>
              {statistics.no_issued || null}
            </div>
            <div className="list-group-item">
              <div className="list-group-item-text">
                <small> Conversion Rate</small>
              </div>
              <span>{Math.round(::this.calcPercentage(statistics.no_issued, statistics.no_quoted))}%</span>
            </div>
            <div className="list-group-item">
              <div className="list-group-item-text">
                <small> Premium issued</small>
              </div>
              {displayCurrency(statistics.premiums_gross) || ''}
            </div>
          </div>
        ) : (
          <div className="list-group list-group-horizontal">
            <div className="list-group-item">
              <div className="list-group-item-text"><h4>No broker activity for the given period{'\n'}</h4></div>
              <br/>
            </div>
            <div className="list-group-item">
              <div className="list-group-item-text">
                <small> No. of Quotes</small>
              </div>
            </div>
            <div className="list-group-item">
              <div className="list-group-item-text">
                <small> No. of Policies</small>
              </div>
            </div>
            <div className="list-group-item">
              <div className="list-group-item-text">
                <small> Conversion Rate</small>
              </div>
            </div>
            <div className="list-group-item">
              <div className="list-group-item-text">
                <small> Premium issued</small>
              </div>
            </div>
          </div>
        )}
      </Box>
    )
  }

  renderUniqueDays() {
    const {dashboard} = this.props
    return (
      <div>
        <span className='extra-bold'>({_.get(dashboard.stats, 'meta.day.unique_logins', 0)} unique)</span>
      </div>
    )
  }

  renderWeekDays() {
    const {dashboard} = this.props
    return (
      <div>
        <span className='extra-bold'>({_.get(dashboard.stats, 'meta.week.logins', 0)})</span>
      </div>
    )
  }

  render() {
    const {auth, dashboard} = this.props
    const products = _.sortBy(getIncludedResource(auth.user.data, auth.user.included, 'available_products'), 'attributes.name')


    if (!_.get(auth.user, 'data.relationships.current_organisation')) {
      return (
        <div>Sort it out</div>
      )
    }
    return (
      <InternalContainer title={'Welcome Back, ' + auth.user.data.attributes.first_name + '!'}>
        {auth.user ? (
          <div>
            <div className="row">
              <div className="col-sm-6 internal-container ">
                <ProductsCarousel products={products}/>
              </div>
              <div className="col-md-6 internal-container">
                <div className='shadow-box'>
                  {isOwner() && (
                    <div className="width-100 divider-border">
                      <div className="width-100">
                        {this.customSelection()}
                      </div>
                    </div>
                  )}
                  {!isOwner() && (
                    <div>
                      <div className="width-100 get-quotes-header">
                        <h3> Quick Links </h3>
                      </div>
                      <Box className="quotes-box">
                        <div className="tabbable">
                          <ul className="nav nav-pills nav-stacked">
                            <li className="quotes-menu-item">
                              <Link to={'/policies/applications'}>
                                <div className='quotes-menu-item__wrapper'>
                                  <div className='quotes-menu-item__title'>
                                    <Icon circle={true} name={'plane'}/>
                                    View Quotes
                                  </div>
                                  <span className="icon-right"><Icon name="caret-right"/></span>
                                </div>
                              </Link>
                            </li>
                            <li className="quotes-menu-item">
                              <Link to={'/policies/issued'}>
                                <div className='quotes-menu-item__wrapper'>
                                  <div className='quotes-menu-item__title'>
                                    <Icon circle={true} name={'tasks'}/>
                                    View Policies
                                  </div>
                                  <span className="icon-right"><Icon name="caret-right"/></span>
                                </div>
                              </Link>
                            </li>
                            <li className="quotes-menu-item">
                              <Link to={'policies/renewals'}>
                                <div className='quotes-menu-item__wrapper'>
                                  <div className='quotes-menu-item__title'>
                                    <Icon circle={true} name={'repeat'}/>
                                    View Renewals
                                  </div>
                                  <span className="icon-right"><Icon name="caret-right"/></span>
                                </div>
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </Box>
                    </div>
                  )}
                  { /* Graphic - Box 1/2 */}
                  {isOwner() && (
                    <Box className="double-height">
                      <div className="row">
                        <div className="col-md-6 vertical-align">
                          {this.state.day && (
                            <StatsSection stats={_.get(dashboard.stats, 'meta.day', {})}/>
                          )}
                          {this.state.week && (
                            <StatsSection stats={_.get(dashboard.stats, 'meta.week', {})}/>
                          )}
                          {this.state.month && (
                            <StatsSection stats={_.get(dashboard.stats, 'meta.month', {})}/>
                          )}
                        </div>
                        { /*  Table - Box 2/2  */}
                        <div className="col-md-6 vertical-align">
                          {this.state.day && (
                            <div>
                              <StatsSectionTable stats={_.get(dashboard.stats, 'meta.day', {})}/>
                              <StatsSectionConversionLabel label="Today"
                                                           stats={_.get(dashboard.stats, 'meta.day', {})}/>
                            </div>
                          )}
                          {this.state.week && (
                            <div>
                              <StatsSectionTable stats={_.get(dashboard.stats, 'meta.week', {})}/>
                              <StatsSectionConversionLabel label="Week"
                                                           stats={_.get(dashboard.stats, 'meta.week', {})}/>
                            </div>
                          )}
                          {this.state.month && (
                            <div>
                              <StatsSectionTable stats={_.get(dashboard.stats, 'meta.month', {})}/>
                              <StatsSectionConversionLabel label="Month"
                                                           stats={_.get(dashboard.stats, 'meta.month', {})}/>
                            </div>
                          )}
                        </div>
                      </div>
                    </Box>
                  )}

                </div>
              </div>
            </div>
            { /* Middle Bar - 1 / 2 Login */}
            {isOwner() && (
              <div className="row">
                <div className="col-md-4 internal-container">
                  <div className='shadow-box'>
                    <Box minHeight={false} className="middlebar">
                      <div className="select-box">
                        <FormControl
                          componentClass="select"
                          className="select"
                          type="select"
                          onChange={::this.handleFilterLogins}
                          value={this.state.loginSelection}
                          placeholder="select"
                        >
                          <option key="0" value="today">Today</option>
                          <option key="1" value="week">This week</option>
                        </FormControl>
                      </div>
                      <div className="list-group list-group-horizontal2">
                        <div className="list-group-item">
                          <div className="list-group-item-text">
                            <small> Internal</small>
                          </div>
                          {this.state.loginToday ?
                            (this.renderUniqueDays()) :
                            (this.renderWeekDays())
                          }
                        </div>
                        <div className="list-group-item">
                          <div className="list-group-item-text">
                            <small> External</small>
                          </div>
                        </div>
                      </div>
                    </Box>
                  </div>
                </div>
                {/*  Middle Bar 2/2 - Broker Info  */}
                <div className="col-md-8 internal-container">
                  <div className='shadow-box'>
                    { /* Selection Top Broker DropDown */}
                    {this.state.brokerToday && this.renderBroker()}
                    {this.state.brokerWeek && this.renderBroker()}
                    {this.state.brokerMonth && this.renderBroker()}
                  </div>
                </div>
              </div>
            )}
            {/* Stats Box */}
            {isOwner() && (
              <div className="row">
                <div className="col-sm-12 internal-container broker-stats">
                  <StarBrokerStats/>
                </div>
              </div>
            )}

          </div>
        ) : (
          <Loader/>
        )}
      </InternalContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    dashboard: state.dashboard
  }
}

export default connect(mapStateToProps)(DashboardContainer)
