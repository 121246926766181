import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getCampaigns } from '../redux/campaignActions'
import { Button, Loader } from '../../../common/components'
import InternalContainer from '../../layout/containers/Internal'
import CampaignFormContainer from './CampaignFormContainer'

class CampaignEditContainer extends Component {
  componentWillMount () {
    this.props.dispatch(getCampaigns(this.props.params.id))
  }

  render () {
    const resource = this.props.campaign.resources[this.props.params.id]
    const buttons = (
      <Button
        bsStyle='default'
        label='Back'
        isBackButton
        leftIcon='caret-left'
        />
    )

    return (
      <InternalContainer title='Campaign' buttons={buttons}>
        {resource ? (
          <CampaignFormContainer {...this.props} resource={resource} />
              ) : (
                <Loader />
              )}
      </InternalContainer>
    )
  }
}

function mapStateToProps (state) {
  return {
    campaign: state.campaign
  }
}

CampaignEditContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  campaign: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired
}

export default connect(mapStateToProps)(CampaignEditContainer)
