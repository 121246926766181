import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  InfoLabel,
  Box,
  Button,
  ButtonBar,
  Icon
} from '../../../../common/components'
import getIncludedResource from '../../../../helpers/getIncludedResource'
import { hasPermission } from '../../../auth/redux/authActions'
import {
  approveBroker,
  deactivateBroker,
  reactivateBroker,
  resendApproval
} from '../../redux/brokerActions'
import TransferBrokerButton from '../TransferBrokerButton'
import { brokerStatuses } from '../../../../schemas/broker'
import displayName from '../../../../helpers/displayName'
import displayAddress from '../../../../helpers/displayAddress'
import DeclineButton from '../DeclineButton/DeclineButton'
import _ from 'lodash'

export default class BrokerDetails extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    broker: PropTypes.object.isRequired,
    resource: PropTypes.object.isRequired,
    brokerLabel: PropTypes.object.isRequired
  };

  approveBroker() {
    this.props.dispatch(approveBroker(this.props.resource.data.id))
  }

  deactivateBroker() {
    this.props.dispatch(deactivateBroker(this.props.resource.data.id))
  }

  reactivateBroker() {
    this.props.dispatch(reactivateBroker(this.props.resource.data.id))
  }

  resendApprovalEmail() {
    this.props.dispatch(resendApproval(this.props.resource.data.id))
  }

  render() {
    const resource = this.props.resource.data.attributes
    const organisation = getIncludedResource(this.props.resource.data, this.props.resource.included, 'organisation')
    const region = getIncludedResource(this.props.resource.data, this.props.resource.included, 'broker_region')
    const networks = getIncludedResource(this.props.resource.data, this.props.resource.included, 'networks')
    const commissions = getIncludedResource(this.props.resource.data, this.props.resource.included, 'active_commissions')
    const address = displayAddress(this.props.resource.data.attributes.address)

    return (
      <Box>
        <h2 className="resource-name">{resource.name} {this.props.brokerLabel}</h2>

        <div className="row">
          <div className="col-sm-6">
            <div className="form-horizontal">
              <InfoLabel
                  label="Organisation"
                  value={ organisation.data.attributes.name }
                  link={'/admin/organisations/' + organisation.data.id }
                  linkLabel="View organisation"
                  labelSize={4}/>
              <InfoLabel label="Trading Name" value={ resource.trading_name } labelSize={4}/>
              <InfoLabel label="Branch Name" value={ resource.branch_name } labelSize={4}/>
              <InfoLabel label="Region" value={ region.data.attributes ? region.data.attributes.name : '' }
                         labelSize={4}/>
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-horizontal">
              <InfoLabel label="Agency Number"
                         value={ _.get(resource, 'metadata.agency_number', '') }
                         labelSize={4}/>
              <InfoLabel label="Hold Client Money"
                         value={ _.get(resource, 'metadata.hold_client_money', '') }
                         labelSize={4}/>
              {/*<InfoLabel label="FCA Number"*/}
                         {/*value={organisation.data.attributes.regulatory_authority_number}*/}
                         {/*labelSize={4}/>*/}
              <InfoLabel label="Address" value={ address } labelSize={4}/>
              <InfoLabel label="Phone Number" value={ resource.phone_number } labelSize={4}/>
            </div>
          </div>
        </div>

        <div className="hr"/>

        <div className="row">
          <div className="col-sm-6">
            <div className="form-horizontal">
              { this.props.resource.data.relationships.networks.data && (
                <InfoLabel label="Networks"
                           value={networks.map((network, i) => {
                             let detail = i ? ', ' : ''
                             return (detail + network.attributes.name)
                           })}
                           labelSize={4}/>
              )}
              <InfoLabel label="Network Reference"
                         value={ _.get(resource, 'metadata.network_reference', '') }
                         labelSize={4}/>
              <InfoLabel label="Other Networks"
                         value={ _.get(resource, 'metadata.other_networks') }
                         labelSize={4}/>
            </div>
          </div>

          <div className="col-sm-6">
            <div className="form-horizontal">
              <InfoLabel label="Status" value={ displayName(brokerStatuses[_.get(resource, 'status', 'applied')]) } labelSize={4}/>
              <InfoLabel label="Created At" date={true} value={ resource.created_at } labelSize={4}/>
              <InfoLabel label="Updated At" date={true} value={ resource.updated_at } labelSize={4}/>
              <InfoLabel label="Last Meeting" date={true} value={ _.get(resource, 'metadata.last_meeting', '') } labelSize={4}/>
            </div>
          </div>
        </div>

        <div className="hr"/>

        {resource.status === 'applied' && (
          <div className="row">
            <div className="col-sm-3">
              <h3>Broker application process:</h3>
            </div>
            <div className="col-sm-3">
              <p className="pull-center">
                <span className="task completed"><Icon name="check-square"/></span> Application submitted
              </p>
            </div>
            <div className="col-sm-3">
              <p className="pull-center">
                <span className={'task' + (commissions.length > 0 ? ' completed' : '')}>
                  <Icon name={commissions.length > 0 ? 'check-square' : 'square-o'}/>
                </span> Products assigned
              </p>
            </div>
            <div className="col-sm-3">
              <p className="pull-center">
                <span className="task"><Icon name="square-o"/></span> Approve broker
              </p>
            </div>
          </div>
        )}

        {resource.status === 'applied' && (
          <div className="hr"/>
        )}

        <ButtonBar>

          { hasPermission('broker.edit') && (
            <Button
                link={true}
                to={'/brokers/' + this.props.resource.data.id + '/edit'}
                className="pull-right"
                bsStyle="primary"
                rightIcon="pencil-square-o">Edit Broker</Button>
          )}
          { (hasPermission('broker.edit')) && (
            <TransferBrokerButton
                className="pull-right"
                label="Merge Agencies"
                resource={this.props.resource}
                resourceType={'brokers'}
            />
          )}
          { (hasPermission('broker.approve') && resource.status === 'approved') && (
            <Button
                className="pull-right"
                bsStyle="primary"
                rightIcon="envelope"
                isLoading={this.props.broker.isSendingApproval}
                handleClick={::this.resendApprovalEmail}>Resend Approval</Button>
          )}
          { (hasPermission('broker.reject') && (resource.status === 'applied' || resource.status === 'approved')) && (
            <DeclineButton className="pull-right" broker={this.props.broker} resource={this.props.resource}/>
          )}
          { (hasPermission('broker.approve') && commissions.length > 0 && (resource.status === 'applied' || resource.status === 'declined')) && (
            <Button
                className="pull-right"
                bsStyle="primary"
                rightIcon="check"
                isLoading={this.props.broker.isApproving}
                handleClick={::this.approveBroker}>Approve</Button>
          )}
          { (hasPermission('broker.deactivate') && resource.status === 'active') && (
            <Button
                className="pull-right"
                bsStyle="primary"
                rightIcon="ban"
                isLoading={this.props.broker.isDeactivating}
                handleClick={::this.deactivateBroker}>Deactivate</Button>
          )}
          { (hasPermission('broker.deactivate') && resource.status === 'inactive') && (
            <Button
                className="pull-right"
                bsStyle="primary"
                rightIcon="check"
                isLoading={this.props.broker.isReactivating}
                handleClick={::this.reactivateBroker}>Reactivate</Button>
          )}
        </ButtonBar>
      </Box>
    )
  }
}
