import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import BrokerUsers from './components/BrokerUsers'
import Box from '../../../../../../common/components/Box/Box'
import { FieldArray, formValueSelector, reduxForm } from 'redux-form'
import './styles.scss'

class Users extends Component {
  constructor(props) {
    super(props)
  }

  render () {
    const { formValues, isLogedIn } = this.props
    return (
      <Box>
        <Row>
          <Col sm={12}>
            <h4 className='page-title'>Users</h4>
            <hr/>
          </Col>
          <Col sm={12}>
            <FieldArray
              isLogedIn={isLogedIn}
              formValues={formValues}
              component={BrokerUsers}
              name='data.attributes.users'
            />
          </Col>
        </Row>
      </Box>
    )
  }
}

const form = reduxForm()(Users)
const mapStateToProps = (state, props) => {
  const values = (formValueSelector(props.form))(state,
    'data',
    'data.attributes.users',
  )

  return {
    auth: state.auth,
    form: props.form,
    formValues: values,
    product: state.product,
    destroyOnUnmount: false,
    initialValues: props.resource,
    forceUnregisterOnUnmount: true
  }
}

export default connect(mapStateToProps)(form)
