import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Box, DataTable, SelectBroker, SelectUser } from '../../../../common/components'
import { applyFilter } from '../../../../common/components/DataTable/redux/dataTableActions'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { FormControl, ControlLabel, Checkbox, FormGroup } from 'react-bootstrap'
import ProductFilter from '../ProductFilter'
import { push } from 'react-router-redux'
import { isOwner, isBroker, authUserId } from '../../../auth/redux/authActions'
import { FormattedDate, FormattedTime } from 'react-intl'

class RenewalList extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    currentBroker: PropTypes.object,
    auth: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props)

    this.state = {
      brokerId: '',
      userId: '',
      hideExpiredRenewals: true
    }
  }

  formatDate(cell) {
    return (
      <span><FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/></span>
    )
  }

  hideExpiredRenewals() {
    const todaysDate = new Date();
    const todaysDateFormatted = todaysDate.getFullYear() + '-' + (todaysDate.getMonth() + 1) + '-' + todaysDate.getDate();

    this.setState({ hideExpiredRenewals: !this.state.hideExpiredRenewals }, () => {
      if (this.state.hideExpiredRenewals) {
        this.props.dispatch(applyFilter('renewals', 'filter[starts_at:from]', todaysDateFormatted))
      } else {
        this.props.dispatch(applyFilter('renewals', 'filter[starts_at:from]', false))
      }
    });
  }

  handleFilterUpdate(event) {
    this.props.dispatch(applyFilter('renewals', 'filter[product]', event.target.value))
  }

  handleRowClick(row) {
    this.props.dispatch(push('/policies/applications/' + row.id))
  }

  handleSearchUpdate(event) {
    this.props.dispatch(applyFilter('renewals', 'filter[quote.reference]', event.target.value))
  }

  handleBrokerUpdate(value) {
    this.setState({ brokerId: value })
    this.props.dispatch(applyFilter('renewals', 'filter[broker]', value))
  }

  handleUserUpdate(value) {
    this.setState({ userId: value })
    this.props.dispatch(applyFilter('renewals', 'filter[user]', value))
  }

  handlePolicyUpdate(event) {
    this.props.dispatch(applyFilter('renewals', 'filter[policy.number]', event.target.value))
  }

  render() {
    const todaysDate = new Date();
    const todaysDateFormatted = todaysDate.getFullYear() + '-' + (todaysDate.getMonth() + 1) + '-' + todaysDate.getDate();

    return (
      <div>
        <Box>
          <div className="row">
            <div className="col-sm-3">
              <ControlLabel className="filter-label">Reference</ControlLabel>
              <FormControl onChange={ ::this.handleSearchUpdate } />
            </div>
            <div className="col-sm-3">
              <ControlLabel className="filter-label">Policy Number</ControlLabel>
              <FormControl onChange={ ::this.handlePolicyUpdate } />
            </div>
            {isOwner() && (
              <div className="col-sm-3">
                <ControlLabel className="filter-label">Broker</ControlLabel>
                <SelectBroker
                  labelKeys={['attributes.name']}
                  input={{
                    onChange: ::this.handleBrokerUpdate,
                    value: this.state.brokerId
                  }}/>
              </div>
            )}
            {(isOwner() || isBroker()) && (
              <div className="col-sm-3">
                <ControlLabel className="filter-label">User</ControlLabel>
                <SelectUser
                  labelKeys={['attributes.first_name', 'attributes.last_name']}
                  input={{
                    onChange: ::this.handleUserUpdate,
                    value: this.state.userId
                  }}/>
              </div>
            )}
            <div className="col-sm-3">
              <ControlLabel className="filter-label">Product</ControlLabel>
              <ProductFilter onChange={::this.handleFilterUpdate} />
            </div>
            <div className="col-sm-3">
              <FormGroup>
                <Checkbox className="styled-checkbox m-t-30" onChange={::this.hideExpiredRenewals} checked={this.state.hideExpiredRenewals}><span>Hide Expired Renewals</span></Checkbox>
              </FormGroup>
            </div>
          </div>
        </Box>

        <Box>
          <DataTable
              source="/policies/applications"
              name="renewals"
              showTotal={true}
              autoFilters={[
                { type: 'filter[status]', value: 'renewal invited,quote' },
                { type: 'filter[quote.type]', value: 'renewal' },
                { type: 'filter[starts_at:from]', value: todaysDateFormatted },
              ]}
              onRowSelect={this.handleRowClick.bind(this)}>

            <TableHeaderColumn dataField="id" isKey={true} hidden={true}>
              ID
            </TableHeaderColumn>

            <TableHeaderColumn dataField="quote_reference">
              Reference
            </TableHeaderColumn>

            <TableHeaderColumn dataField="renewing_policy_number">
              Policy Number
            </TableHeaderColumn>

            <TableHeaderColumn dataField="insured">
              Insured
            </TableHeaderColumn>

            <TableHeaderColumn dataField="broker_name">
              Broker
            </TableHeaderColumn>

            <TableHeaderColumn dataField="user_name">
              User
            </TableHeaderColumn>

            <TableHeaderColumn dataField="starts_at" dataFormat={this.formatDate} dataSort={true}>
              Renewal Date
            </TableHeaderColumn>

          </DataTable>
        </Box>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  }
}

export default connect(mapStateToProps)(RenewalList)
