import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { Button } from '../../../../common/components'
import { findCondition, startDeclaration } from '../../redux/screeningActions'
import _ from 'lodash'
import { Alert } from 'react-bootstrap'

export default class ConditionSearch extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    screening: PropTypes.object.isRequired
  }

  constructor(props) {
    super(props)
    this.state = {
      condition: ''
    }

    this.search = _.debounce(this.search, 300)
  }

  search(query) {
    this.props.dispatch(findCondition(query))
  }

  render() {
    const { index, screening, dispatch } = this.props
    const { condition } = this.state

    let isSurgical = false

    screening.conditions.map(function (condition) {
      if (condition.label === 'SURPROC') {
        isSurgical = true
      }
    })

    return (
      <div className="search-conditions form-horizontal">
        <div className="form-group">
          {isSurgical ? (
            <div className="col-xs-12">
              <Alert bsStyle="danger">
                You have searched for a procedure or a surgery, please enter the name for the medical condition.
              </Alert>
            </div>
          ) : null}
          {screening.hasMisspelledConditions ? (
            <div className="col-xs-12">
              <Alert bsStyle="danger">
                No medical condition was found matching your search. However, did you mean:
              </Alert>
            </div>
          ) : null}
          <label className="control-label col-sm-4 col-md-3">Search conditions</label>
          <div className="col-sm-8 col-md-7">
            <Select
              name={'conditions-search-' + index}
              cache="false"
              value={condition}
              isLoading={screening.isSearching}
              options={isSurgical ? [] : screening.conditions}
              placeholder="Enter Condition"
              filterOptions={false}
              onInputChange={(search) => this.search(search)}
              onChange={(condition) => this.setState({ condition })}
            />
          </div>
          <div className="col-sm-12 col-md-2">
            <br className="visible-xs visible-sm"/>
            {condition && (
              <Button
                bsStyle="primary"
                isLoading={screening.isSubmitting}
                label="Screen"
                handleClick={() => dispatch(startDeclaration(condition))}
                block={true}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}
