import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box, DataTable, Button, ButtonBar } from '../../../../common/components'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { push } from 'react-router-redux'
import { FormattedDate, FormattedTime } from 'react-intl'
import getIncludedResource from '../../../../helpers/getIncludedResource'
import BrokerAssignUser from '../BrokerAssignUser/BrokerAssignUser'
import { refreshData } from '../../../../common/components/DataTable/redux/dataTableActions'

export default class BrokerUsers extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    brokerLabel: PropTypes.object.isRequired
  };

  formatDate(cell, row) {
    return (
        <span><FormattedDate value={new Date(cell)}/> <FormattedTime value={new Date(cell)}/></span>
    )
  }

  formatName(cell, row) {
    return row.title + ' ' + row.first_name + ' ' + row.last_name
  }

  handleRowClick(row) {
    this.props.dispatch(push('/admin/users/' + row.id))
  }

  refreshList() {
    this.props.dispatch(refreshData('users'))
  }

  render() {
    const resource = this.props.resource.data.attributes
    const organisation = getIncludedResource(this.props.resource.data, this.props.resource.included, 'organisation')

    return (
      <div>
        <Box>
          <h2 className="resource-name">{resource.name} {this.props.brokerLabel}</h2>

          <DataTable
              source="/users"
              name="users"
              showTotal={true}
              autoFilter={this.props.resource.data.id}
              autoFilterType="filter[broker]"
              onRowSelect={this.handleRowClick.bind(this)}>

            <TableHeaderColumn dataField="id" isKey={true} hidden={true}>ID</TableHeaderColumn>
            <TableHeaderColumn dataField="first_name" dataFormat={::this.formatName}>Name</TableHeaderColumn>
            <TableHeaderColumn dataField="email" dataSort={true}>Email</TableHeaderColumn>
            <TableHeaderColumn dataField="status" dataSort={true} width={'180px'}>Status</TableHeaderColumn>
            <TableHeaderColumn dataField="created_at" dataFormat={this.formatDate} width={'180px'} dataSort={true}>Created</TableHeaderColumn>
          </DataTable>

          <ButtonBar>
            <BrokerAssignUser
              className="pull-right"
              resourceId={this.props.resource.data.id}
              callback={::this.refreshList}
            />
            <Button
                link={true}
                to={'/admin/users/new/' + organisation.data.id + '/' + this.props.resource.data.id}
                className="pull-right"
                bsStyle="primary"
                rightIcon="user"
                label="add a new user"
            />
          </ButtonBar>
        </Box>
      </div>
    )
  }
}
