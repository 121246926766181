import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, FieldArray } from 'redux-form'
import { Button, HorizontalFormControl } from '../../../../common/components'
import TextArea from '../../../content/components/TextArea'
import TripDurationForm from '../TripDurationForm'
import RegionsForm from '../RegionsForm'
import OptionsForm from '../OptionsForm'

const FORM_NAME = 'productDetailsForm'

class ProductDetailsForm extends Component {
  static propTypes = {
    product: PropTypes.object.isRequired
  }

  render() {
    const { product, submitting, handleSubmit } = this.props
    return (
      <div>
        <form onSubmit={handleSubmit}>
          <h3 className='resource-name'>{product.data.attributes.name}</h3>

          <div className='row form-horizontal'>
            <div className='col-sm-6'>
              <Field
                name="data.attributes.name"
                label="Product Name"
                labelSize={4}
                component={HorizontalFormControl}
              />
            </div>
          </div>

          <div className='row'>
            <label className='control-label col-sm-2'>Product Information</label>
            <div className='col-sm-10'>
              <Field
                name="data.attributes.description"
                component={TextArea}
              />
            </div>
          </div>

          <div className='row'>
            <h3 className='resource-name'>Annual Trip Duration</h3>
            <FieldArray
              name="data.attributes.metadata.trip_durations_annual"
              component={TripDurationForm}
            />
          </div>
          <div>
            <div className='row'>
              <h3 className='resource-name'>Region</h3>
              <FieldArray
                name="data.attributes.metadata.regions"
                component={RegionsForm}
              />
            </div>
          </div>
          <div className='row'>
            <h3 className='resource-name'>Cover Options</h3>
            <FieldArray
              name="data.attributes.metadata.options"
              component={OptionsForm}
            />
          </div>
          <div className='row actions'>
            <div className='col-xs-12'>
              <Button
                type="submit"
                label="Save Changes"
                bsStyle="primary"
                className="pull-right"
                isLoading={submitting}
              />
            </div>
          </div>
        </form>
      </div>
    )
  }
}

const form = reduxForm({ form: FORM_NAME })(ProductDetailsForm)
const mapStateToProps = (state, props) => {
  return {
    initialValues: props.product
  }
}

export default connect(mapStateToProps)(form)
