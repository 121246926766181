import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Box } from '../'
import './Loader.scss'
//
export default class Loader extends Component {
  render() {
    const { message } = this.props

    return (
        <Box>
          <div className="loader-container">
            {message && (
                <p className="message"><strong>{message}</strong></p>
            )}
            <img src={require('images/icons/loaders/ring-alt.svg')}/>
          </div>
        </Box>
    )
  }
}
