import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { TextField, AddressFields } from '../../../../common/components/ReduxFormField'
import _ from 'lodash'
import { isOwner, currentOrganisationType } from '../../../auth/redux/authActions'
import PostcodeList from '../../../../common/components/Postcode/PostcodeList'
import TextArea from '../../../content/components/TextArea/TextArea'
import {
  Box,
  Button,
  ButtonBar,
  HorizontalFormControl,
  NameFields,
  SelectOrganisation,
  SelectProduct,
  SelectRole
} from '../../../../common/components'

const FORM_NAME = 'userForm'

class UserForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    fields: PropTypes.object,
    submitting: PropTypes.bool.isRequired,
    resource: PropTypes.object,
    formValues: PropTypes.object,
    isInOwnerOrganisation: PropTypes.bool,
    contact: PropTypes.bool
  };

  render() {
    const { formValues, handleSubmit, submitting, contact, isInOwnerOrganisation } = this.props
    const resource = this.props.resource.data.attributes
    const newType = contact ? 'contact' : 'user'

    return (
      <Box>
        <h2>{ resource ? resource.first_name + ' ' + resource.last_name : 'New ' + newType }</h2>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-horizontal">
                <NameFields
                  baseName="data.attributes"
                  label="Name"
                  hideTitle={true}
                  labelSize={3}
                  fieldComponent={Field}
                />

                <Field
                  name="data.attributes.email"
                  label="Email"
                  labelSize={3}
                  component={HorizontalFormControl}
                />

                <Field
                  name="data.attributes.phone1"
                  label="Phone 1"
                  labelSize={3}
                  component={HorizontalFormControl}
                />

                <Field
                  name="data.attributes.phone2"
                  label="Phone 2"
                  labelSize={3}
                  component={HorizontalFormControl}
                />

                <Field
                  name="data.attributes.position"
                  label="Position"
                  labelSize={3}
                  component={HorizontalFormControl}
                />

              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-horizontal">
                <div className="">
                  <PostcodeList
                    name="data.attributes.address.postcode"
                    label="Postcode"
                    labelSize={3}
                    change={this.props.change}
                    value={_.get(formValues, 'data.attributes.address.postcode', '')}
                    targetFields={{
                      organisation: 'data.attributes.name',
                      line1: 'data.attributes.address.line1',
                      line2: 'data.attributes.address.line2',
                      line3: 'data.attributes.address.line3',
                      town: 'data.attributes.address.town',
                      county: 'data.attributes.address.county',
                      postcode: 'data.attributes.address.postcode'
                    }}
                  />
                </div>

                <AddressFields
                  baseName="data.attributes.address"
                  label="Address"
                  labelSize={3}
                />
              </div>
            </div>
          </div>

          {isOwner() && this.props.resource.data.id && (
            <div>
              <div className="hr"/>

              <div className="row">

                <div className="col-sm-12">
                  <div className="form-horizontal">
                    <Field
                      name="data.relationships.organisations.data"
                      label="Organisations"
                      labelSize={3}
                      multi={true}
                      object={true}
                      component={SelectOrganisation}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {!this.props.resource.data.id && (
            <div>
              <div className="hr"/>
              <div className="row">
                <div className="col-xs-12">
                  <div className="form-horizontal">
                    <Field
                      name="data.relationships.roles.data"
                      label="Roles"
                      labelSize={2}
                      multi={true}
                      object={true}
                      autoFilterType={isOwner() ? '' : 'filter[name]'}
                      autoFilter={isOwner() ? '' : currentOrganisationType()}
                      component={SelectRole}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {isOwner() && !contact && isInOwnerOrganisation && (
            <div>
              <div className="hr"/>

              <div className="row">

                <div className="col-sm-12">
                  <div className="form-horizontal">
                    <Field
                      name="data.relationships.available_products.data"
                      label="Products"
                      labelSize={3}
                      multi={true}
                      object={true}
                      component={SelectProduct}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {isOwner() && !contact && (
            <div>
              {isInOwnerOrganisation && (
                <div>
                  <div className="hr"/>

                  <div className="row">

                    <div className="col-sm-12">
                      <div className="form-horizontal">
                        <Field
                          name="data.attributes.metadata.biography"
                          label="Biography"
                          component={TextArea}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}

          <ButtonBar>
            <Button
              className="pull-right"
              type="submit"
              bsStyle="primary"
              label={ 'Save ' + newType }
              isLoading={submitting}
            />
          </ButtonBar>
        </form>
      </Box>
    )
  }
}

const form = reduxForm({ form: FORM_NAME })(UserForm)
const selector = formValueSelector(FORM_NAME)
const mapStateToProps = (state, props) => {
  const values = selector(state,
    'data.id',
    'data.attributes.address.postcode'
  )

  if (Array.isArray(props.resource.data.attributes.metadata) && !props.resource.data.attributes.metadata.length) {
    props.resource.data.attributes.metadata = {}
  }

  if (! _.get(props.resource.data.attributes.user_type)) {
    props.resource.data.attributes.user_type = props.contact ? 'contact' : 'regular'
  }

  return {
    initialValues: props.resource,
    formValues: values
  }
}

export default connect(mapStateToProps)(form)
