import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DragSource, DropTarget } from 'react-dnd'
import flow from 'lodash/flow'

const itemSource = {
    beginDrag(props) {
        props.isReadOnly(true)
        return {
            id: props.sortId,
        }
    },
    endDrag(props, monitor) {
        const originalPosition = monitor.getItem().id
        const newPosition = monitor.getDropResult().id
        props.isReadOnly(false)
        props.updateState(originalPosition, newPosition)
    },
}

const itemTarget = {
    drop(props) {
        return {
            id: props.sortId,
        }
    },
}

class SortableItem extends Component {
  render() {
    const { connectDragSource, isDragging, connectDropTarget } = this.props
    const style = isDragging ? { opacity: 0 } : null
    return connectDragSource(connectDropTarget(
        <div style={style}  className="list-item">
          {this.props.children}
        </div>
    ))
  }
}

export default flow(
    DropTarget(
        'sortable-item',
        itemTarget,
        (connect) => ({
            connectDropTarget: connect.dropTarget(),
        })
    ),
    DragSource(
        'sortable-item',
        itemSource,
        (connect, monitor) => ({
            connectDragSource: connect.dragSource(),
            isDragging: monitor.isDragging(),
        })
    ),
    )(SortableItem)
