import { APPLICATION_SUBMITTED, CHALLENGE_SUBMITTED, SET_CONTENT } from './brochureReducer'
import Axios from 'axios'
import { push } from 'react-router-redux'
import { login } from '../../auth/redux/authActions'
import handleErrorResponse from '../../../helpers/handleErrorResponse'

export function submitBrokerApplication(resource) {
  return dispatch => {
    return Axios.post('public/broker-application?include=users', resource).then(response => {
      dispatch({ type: APPLICATION_SUBMITTED, values: response })
      dispatch(push('/become-an-Axiom-broker/thank-you'))
      return true
    }).catch(error => {
      handleErrorResponse(error, 'There was an error submitting your broker application, please contact Axiom.')
    })
  }
}

export function submitBrokerActivation(resource) {
  return dispatch => {
    return Axios.post('public/broker-activate', resource).then(response => {
      dispatch({ type: APPLICATION_SUBMITTED, values: response.data })
      dispatch(login(resource.data.attributes.user.email, resource.data.attributes.user.password))
      return true
    }).catch(error => {
      handleErrorResponse(error, 'There was an error submitting your broker activation, please contact Axiom.')
    })
  }
}

export function submitChallenge(resource) {
  return dispatch => {
    return Axios.post('public/challenge-Axiom', resource).then(response => {
      dispatch({ type: CHALLENGE_SUBMITTED, values: response.data })
      return true
    }).catch(error => {
      handleErrorResponse(error, 'There was an error submitting your challenge, please contact Axiom.')
    })
  }
}

export function setContent(content) {
  return {
    type: SET_CONTENT,
    content: content
  }
}

export function getHomepageContent() {
  return dispatch => {
    const promises = []
    const content = {
      key: 'homepage',
      news: [],
      page: { attributes: { sections: {} } }
    }

    promises.push(
        Axios.get('public/contents?filter[slug]=home')
            .then(response => {
              content.page = response.data.data[0]
              return true
            }).catch(error => {
          //
        })
    )

    promises.push(
        Axios.get('public/contents?filter[template.type]=article')
            .then(response => {
              content.news = response.data
              return true
            }).catch(error => {
          //
        })
    )

    return Promise.all(promises).then(() => {
      dispatch(setContent(content))
    })
  }
}

export function getBrokerApplicationQuestions() {
  return dispatch => {
    const promises = []
    const content = {
      key: 'broker-application',
      news: [],
      data: { attributes: { sections: {} } }
    }

    promises.push(
      Axios.get('public/contents?filter[template.type]=question')
        .then(response => {
          content.data = response.data.data[0]
          return true
        }).catch(error => {
        //
      })
    )
    return Promise.all(promises).then(() => {
      dispatch(setContent(content))
    })
  }
}
