import Axios from 'axios';
import { map, filter } from 'lodash';
import queryString from '../../../helpers/queryString';
import handleErrorResponse from '../../../helpers/handleErrorResponse';

const LOADING = '[help Centre] => LOADING';
const SUBMITTING = '[help Centre] => SUBMITTING';
const SET_CONTENT = '[help Centre] => SET_CONTENT';
const SET_TEMPLATE = '[help Centre] => SET_TEMPLATE';
const CLEAR_CONTENT = '[help Centre] => CLEAR_CONTENT';

const initialState = {
  categories: [
    { label: 'Misc', value: 'misc' },
    { label: 'Admin', value: 'admin' },
    { label: 'Getting Quotes', value: 'quotes' },
    { label: 'Organisations', value: 'organisations' },
    { label: 'Policy Management', value: 'policy-management' },
    { label: 'Product Management', value: 'product-management' },
  ],
  content: [],
  template: null,
  loading: false,
  submitting: false,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SUBMITTING:
      return {
        ...state,
        submitting: action.payload,
      };
    case SET_CONTENT:
      return {
        ...state,
        content: [...state.content, action.payload],
      };
    case SET_TEMPLATE:
      return {
        ...state,
        template: action.payload,
      };
    case CLEAR_CONTENT:
      return {
        ...state,
        content: [],
      };
    default:
      return state;
  }
}

const clearContent = () => ({ type: CLEAR_CONTENT });
const loading = (payload) => ({ type: LOADING, payload });
const submitting = (payload) => ({ type: SUBMITTING, payload });

const addNewContent = (category) => {
  return (dispatch) => {
    const payload = {
      id: '',
      name: '',
      answer: '',
      question: '',
      display: true,
      category: category,
    };

    dispatch({ type: SET_CONTENT, payload });
  };
};

const getContent = () => {
  return (dispatch) => {
    dispatch(loading(true));
    dispatch(clearContent());
    return Axios.get('/contents?page[limit]=20000&filter[template.key]=faqs')
      .then((response) => {
        if (response.data.data.length > 0) {
          map(response.data.data, (page) => {
            const payload = {
              id: page.id,
              name: page.attributes.name,
              answer: page.attributes.sections.answer,
              display: page.attributes.sections.display,
              question: page.attributes.sections.question,
              category: page.attributes.sections.category,
            };

            dispatch(loading(false));
            dispatch({ type: SET_CONTENT, payload });
          });
        }

        dispatch(loading(false));
      })
      .catch((error) => {
        dispatch(loading(false));
        console.error(error);
        // handle error here
      });
  };
};

const getTemplate = (type) => {
  const endpoint = 'templates?filter[template_type]=' + type;
  return (dispatch) => {
    return Axios.get(endpoint)
      .then((response) => {
        const template = filter(response.data.data, (t) => t.attributes.key === 'faqs');
        const payload = {
          key: template[0].attributes.key,
          id: template[0].id,
        };

        dispatch({ type: SET_TEMPLATE, payload });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

const createNewFaqContent = (resource, includes = ['template', 'files']) => {
  return (dispatch, getState) => {
    dispatch(submitting(true));
    const endpoint = 'contents/' + queryString(includes);
    const template = getState().helpCentre.template;

    const data = {
      data: {
        attributes: resource.data.attributes,
        relationships: {
          template: {
            data: {
              id: template.id,
            },
          },
        },
      },
    };

    return Axios.post(endpoint, data)
      .then(() => {
        dispatch(submitting(false));
        dispatch(getContent());
      })
      .catch((error) => {
        dispatch(submitting(false));
        handleErrorResponse(error, 'There was an error updating your content');
      });
  };
};

const updateFaqContent = (resource, includes = ['template', 'files']) => {
  return (dispatch) => {
    dispatch(submitting(true));
    const endpoint = 'contents/' + resource.data.id + queryString(includes);

    return Axios.patch(endpoint, resource)
      .then(() => {
        dispatch(submitting(false));
        dispatch(getContent());
      })
      .catch((error) => {
        dispatch(submitting(false));
        handleErrorResponse(error, 'There was an error updating your content');
      });
  };
};

export const actions = {
  addNewContent,
  //clearContent,
  createNewFaqContent,
  getContent,
  getTemplate,
  updateFaqContent,
};
