import React from 'react'
import Axios from 'axios'
import CenteredSection from '../../brochure/components/CenteredSection/CenteredSection';
import { Box, HorizontalFormControl, Button } from '../../../common/components/';
import { Form, Field, reduxForm } from 'redux-form';
import { replace, get, set } from 'lodash';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { submitBrokerActivation } from '../redux/brokerApplicationActions';
import getIncludedResource from '../../../helpers/getIncludedResource';
import userSchema from '../../../schemas/user'
import brokerSchema from '../../../schemas/broker'
import organisationSchema from '../../../schemas/organisation'

class CompleteYourApplication extends React.Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      content: {
        attributes: {}
      },
      user: {
        ...userSchema.data.attributes
      },
      broker: {
        ...brokerSchema
      },
      organisation: {
        ...organisationSchema
      },
      legalEntity: {}
    }
  };

  handleActivate(resource) {
    const { dispatch, params: { token } } = this.props
    dispatch(submitBrokerActivation(token, resource))
  }

  render() {
    const { handleSubmit, submitting } = this.props
    return (
      <Form onSubmit={handleSubmit(::this.handleActivate)}>
        <div className="guest-section">
          <div className="guest-title"> Complete Your Application</div>
          <CenteredSection className="page-content">
            <Box>
              <h2>Your application has been approved</h2>
              <p>Create a password below to log in to the system.</p>
              <h2>Create your password</h2>
              <Row>
                <Col sm={12}>
                  <div className="form-horizontal">
                    <Field
                      name="meta.email"
                      label="Your email"
                      type="email"
                      labelSize={4}
                      component={HorizontalFormControl}
                    />
                    <Field
                      name="meta.password"
                      type="password"
                      label="Password"
                      labelSize={4}
                      placeholder="New password"
                      component={HorizontalFormControl}
                    />
                    <Field
                      name="meta.password_confirmation"
                      type="password"
                      label="Confirm password"
                      labelSize={4}
                      placeholder="Confirm new password"
                      component={HorizontalFormControl}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Button
                    type="submit"
                    size='large'
                    className='pull-right'
                    label={'Complete Application'}
                    bsStyle='primary'
                    disabled={submitting}
                  />
                </Col>
              </Row>
            </Box>
          </CenteredSection>
        </div>
      </Form>
    )
  }
}

const validate = values => {
  const errors = {}

  if (!get(values, 'meta.password')) {
    set(errors, 'meta.password', 'The new password is required')
  } else if (values.meta.password.length < 8) {
    set(errors, 'meta.password', 'Password must be 8 characters or more')
  }
  if (!get(values, 'meta.password_confirmation')) {
    set(errors, 'meta.password_confirmation', 'The password must be confirmed')
  }
  if (get(values, 'meta.password') !== get(values, 'meta.password_confirmation')) {
    set(errors, 'meta.password_confirmation', 'Password does not match confirmation')
  }

  errors._error = errors.data

  return errors
}

const FORM_NAME = 'BrokerActivation'
const form = reduxForm({ form: FORM_NAME, validate })(CompleteYourApplication)
export default connect()(form)
