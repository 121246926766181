import React from 'react'
import { Box, Button, InfoLabel } from '../../../common/components'
import { Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import _ from 'lodash'
import { bindActionCreators } from 'redux'
import { actions } from '../redux/TrackingLinkReducer'

class TrackingLinkDetails extends React.Component {
  deactivateLink() {
    const { actions, resource } = this.props
    actions.deactivateTrackingLink(resource.data.id)
  }

  reactivateLink() {
    const { actions, resource } = this.props
    actions.reactivateTrackingLink(resource.data.id)
  }

  render() {
    const { resource } = this.props

    return (
      <Box>
        <div className='campaign-details-header'>
          <h4>{resource.data.attributes.name}</h4>
        </div>
        <div className="campaign-details-container margin-bottom">
          <form autoComplete='off'>
            <Row>
              <Col sm={6}>
                <div className='form-horizontal'>
                  <InfoLabel label="Tracking Url:" labelSize={4} value={resource.data.attributes.url}/>
                </div>
              </Col>
              <Col sm={6}>
                <div className='form-horizontal'>
                  <InfoLabel label="Status:" labelSize={4} value={resource.data.attributes.status}/>
                </div>
              </Col>
            </Row>
          </form>
        </div>
        <Row>
          <Col sm={12}>
            {resource.data.attributes.status === 'active' && (
              <Button
                className="pull-right"
                bsStyle="primary"
                rightIcon="ban"
                // isLoading={this.props.broker.isDeactivating}
                handleClick={::this.deactivateLink}
              >
                Deactivate
              </Button>
            )}
            {resource.data.attributes.status === 'inactive' && (
              <Button
                className="pull-right"
                bsStyle="primary"
                rightIcon="check"
                // isLoading={this.props.broker.isReactivating}
                handleClick={::this.reactivateLink}
              >
                Reactivate
              </Button>
            )}
          </Col>
        </Row>
      </Box>
    )
  }
}

export default connect(
  null,
  (dispatch) => ({
    actions: bindActionCreators(actions, dispatch),
  }),
)(TrackingLinkDetails)
