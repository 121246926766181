import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getAccount, getPremiums, getTransactions } from '../redux/accountActions'
import { Label, ButtonGroup } from 'react-bootstrap'
import { Button, DatePicker, Box, SelectAccount } from '../../../common/components'
import { SelectField } from '../../../common/components/ReduxFormField'
import InternalContainer from '../../layout/containers/Internal'
import AccountMatching from '../components/AccountMatching'
import moment from 'moment'
import _ from 'lodash'

class AccountMatchingContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    account: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired
  };

  constructor(props, context) {
    super(props, context)

    this.state = {
      activeTab: 1,
      accountId: '',
      accountType: 'debtor',
      matchingDate: '',
      startDate: '',
      endDate: ''
    }
  }

  componentWillMount() {
    if (_.get(this.props, 'params.id')) {
      this.setState({ accountId: this.props.params.id })
      this.props.dispatch(getAccount(this.props.params.id))
      this.props.dispatch(getTransactions(this.props.params.id))
      this.props.dispatch(getPremiums(this.props.params.id))
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.accountId && nextState.accountId !== this.state.accountId) {
      this.props.dispatch(getAccount(nextState.accountId))
      this.props.dispatch(getTransactions(nextState.accountId))
      this.props.dispatch(getPremiums(nextState.accountId))
    } else if (nextState.accountId && (nextState.startDate !== this.state.startDate || nextState.endDate !== this.state.endDate)) {
      const filters = nextState.startDate && nextState.endDate ?
          'filter[from]=' + moment(nextState.startDate).format('YYYY-MM-DD')
          + '&filter[to]=' + moment(nextState.endDate).format('YYYY-MM-DD') : ''

      this.props.dispatch(getTransactions(nextState.accountId, filters))
      this.props.dispatch(getPremiums(nextState.accountId, nextProps.account.premiumType, filters))
    }
  }

  handleTab(key) {
    this.setState({ activeTab: key })
  }

  render() {
    const resource = this.props.account.resources[this.state.accountId]

    const labelStyle = (account) => {
      if (account.data.attributes.suspended) {
        return 'danger'
      } else if (account.data.attributes.limit < account.data.attributes.balance) {
        return 'warning'
      } else {
        return 'success'
      }
    }

    const accountLabel = (account) => {
      return (
        <Label className="pull-right"
               bsStyle={labelStyle(account)}>{account.data.attributes.formatted_balance}
         </Label>
      )
    }

    const buttons = (
        <Button
            bsStyle="default"
            label="back"
            isBackButton={true}
            leftIcon="caret-left"
        />
    )

    return (
      <InternalContainer title="Matching" buttons={buttons}>
        <Box>
          <div className="clearfix">
            <div className="col-xs-12">
              <div className="row pre-matching-form">
                <div className="row">
                  <div className="col-sm-4">
                    <SelectField
                        label="Type"
                        labelSize={2}
                        input={{
                          value: this.state.accountType,
                          meta: {},
                          onChange: (value) => this.setState({ accountType: value })
                        }}
                        options={[
                          { label: 'Debtor', value: 'debtor' },
                          { label: 'Creditor', value: 'creditor' },
                          { label: 'Cash', value: 'cash' },
                          { label: 'Control', value: 'control' },
                        ]}
                    />
                  </div>
                  <div className="col-sm-5">
                    <SelectAccount
                        labelSize={2}
                        label="Account"
                        filters={[
                          { filter: 'filter[type]', value: this.state.accountType }
                        ]}
                        input={{
                          value: this.state.accountId,
                          meta: {},
                          onChange: (value) => this.setState({ accountId: value })
                        }}
                    />
                  </div>
                  <div className="col-sm-3">
                    <DatePicker
                        labelSize={3}
                        label="Date"
                        minDate={this.state.matchingDate}
                        input={{
                          value: this.state.matchingDate,
                          meta: {},
                          onChange: (value) => this.setState({ matchingDate: value })
                        }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12">
              <div className="row pre-matching-form">
                <div className="col-sm-3">
                  <label className="control-label">Premium Type: </label>&nbsp;&nbsp;
                  <ButtonGroup className="net-gross-filter" bsSize="sm">
                    <Button bsStyle={this.props.account.premiumType === 'net-premiums' ? 'primary' : 'default'}
                            handleClick={() => {
                              this.props.dispatch(getPremiums(this.state.accountId, 'net-premiums'))
                            }}>Net</Button>
                    <Button bsStyle={this.props.account.premiumType === 'premiums' ? 'primary' : 'default'}
                            handleClick={() => {
                              this.props.dispatch(getPremiums(this.state.accountId, 'premiums'))
                            }}>Gross</Button>
                  </ButtonGroup>
                </div>
                <div className="col-sm-7">
                  <div className="row">
                    <div className="col-xs-6">
                      <DatePicker
                          labelSize={5}
                          label="Filter From"
                          input={{
                            value: this.state.startDate,
                            meta: {},
                            onChange: (value) => this.setState({ startDate: value })
                          }}
                      />
                    </div>
                    <div className="col-xs-6">
                      <DatePicker
                          labelSize={5}
                          label="Filter To"
                          minDate={this.state.startDate}
                          input={{
                            value: this.state.endDate,
                            meta: {},
                            onChange: (value) => this.setState({ endDate: value })
                          }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>

          <div className="hr small-bottom-margin"/>

          {resource && (
            <AccountMatching
                {...this.props}
                resource={resource}
                matchingDate={this.state.matchingDate}
                accountLabel={accountLabel(resource)}
            />
          )}
        </Box>
      </InternalContainer>
    )
  }
}

function mapStateToProps(state) {
  return {
    account: state.account
  }
}

export default connect(mapStateToProps)(AccountMatchingContainer)
