import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SchemeBenefitsForm from '../components/SchemeBenefitsForm'
import { updateSchemeBenefits } from '../redux/schemeActions'

class SchemeBenefitsContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    scheme: PropTypes.object.isRequired,
    productId: PropTypes.string.isRequired,
    schemeStatus: PropTypes.string.isRequired,
    schemeLabel: PropTypes.object.isRequired,
  };

  handleSubmit(scheme) {
    return this.props.dispatch(updateSchemeBenefits(scheme))
  }

  render() {
    const { scheme, schemeStatus, schemeLabel } = this.props

    return (
      <SchemeBenefitsForm
        scheme={scheme}
        schemeStatus={schemeStatus}
        schemeLabel={schemeLabel}
        onSubmit={::this.handleSubmit}
      />
    )
  }
}

export default connect()(SchemeBenefitsContainer)
