import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { Button, Modal } from '../../../../common/components'
import { openModal, closeModal } from '../../../../common/components/Modal/ModalRedux'
import { updateRenewalStatus } from '../../redux/policyActions'
import _ from 'lodash'
import './styles.scss'
import { SelectField, TextField } from '../../../../common/components/ReduxFormField';
import { required } from 'redux-form-validators';
import renewalLapseReasons from '../../../../helpers/renewalLapseReasons';

const FORM_NAME = 'preventRenewalForm'

// const validate = values => {
//   const errors = {}
//
//   if (!_.get(values, 'meta.reason', false) && !_.get(values, 'meta.note.content', false)) {
//     _.set(errors, 'meta.reason', 'Reason is required')
//   }
//
//   return errors
// }

class PreventRenewalButton extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    resource: PropTypes.object.isRequired,
    callback: PropTypes.func,
    label: PropTypes.string
  };

  componentWillUpdate(newProps) {
    const { dispatch, submitSucceeded, callback } = this.props
    if (newProps.submitSucceeded && !submitSucceeded) {
      if (callback) {
        callback('')
      }
      dispatch(closeModal(FORM_NAME))
    }
  }

  handleSubmit(values) {
    this.props.dispatch(updateRenewalStatus(this.props.resource, values, 'lapse'))
  }

  handleOpen() {
    const { dispatch, reset } = this.props
    dispatch(reset())
    dispatch(openModal(FORM_NAME))
  }

  render() {
    const { label, handleSubmit, submitting, formValues } = this.props
    const title = label || 'Lapse Renewal'
    const submitButton = (<Button
        type="submit"
        bsStyle="primary"
        isLoading={submitting}
        label="Lapse Renewal"
    />)

    const reasons = renewalLapseReasons();

    return (
        <div className="prevent-renewal-btn-wrapper pull-right">
          <Button
              bsStyle="primary"
              label={title}
              handleClick={::this.handleOpen}
              isLoading={submitting}
          />

          <Modal
              name={FORM_NAME}
              title={title}
              close={true}
              handleSubmit={handleSubmit(::this.handleSubmit)}
              footer={submitButton}
          >
            <Field
              name="meta.reason"
              options={reasons}
              component={SelectField}
              label={'Reason'}
              validate={_.get(formValues, 'meta.reason') !== false ? [required({ msg: 'Reason is required' })] : []}
            />

            {_.get(formValues, 'meta.reason') === false && (
              <Field
                name="meta.note.content"
                type="textarea"
                component={TextField}
                label={'Other reason not listed'}
                validate={[required({ msg: 'Reason is required' })]}
              />
            )}
          </Modal>
        </div>
    )
  }
}

const form = reduxForm({ form: FORM_NAME })(PreventRenewalButton)
const selector = formValueSelector(FORM_NAME)
const mapStateToProps = (state, props) => {
  const values = selector(state,
    'meta.note.content',
    'meta.reason'
  )
  return {
    policy: state.policy,
    formValues: values,
    initialValues: {
      meta: {
        policy_id: props.resource.data.id
      }
    }
  }
}

export default connect(mapStateToProps)(form)
