import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Modal } from '../../../../common/components'
import { openModal } from '../../../../common/components/Modal/ModalRedux'
import displayCurrency from '../../../../helpers/displayCurrency'
import displayName from '../../../../helpers/displayName'
import { isOwner, isBroker } from '../../../auth/redux/authActions'
import * as _ from 'lodash'
import './styles.scss'

class PremiumBreakdown extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    premium: PropTypes.object.isRequired,
    originalPremium: PropTypes.object,
    savedPremium: PropTypes.object,
    id: PropTypes.string,
    className: PropTypes.string,
    estimate: PropTypes.bool,
    block: PropTypes.bool,
    showIpt: PropTypes.bool,
    scheme: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      showCommissions: false,
      showCommissionsOriginal: false
    }
  }

  brokerCommission(commissions) {
    let bc = {
      rate: 0,
      amount: 0,
      adjusted: false
    }

    if (! commissions) {
      return bc
    }

    if (_.get(commissions, 'broker')) {
      bc.rate = _.get(commissions ,'broker.rate', 0) * 100
      bc.amount = _.get(commissions, 'broker.amount')
    } else {
      commissions.forEach(commission => {
        if (commission.commission_type === 'broker') {
          bc.rate = _.get(commission, 'effective_rate', commission.rate) * 100
          bc.amount = commission.amount
          bc.adjusted = _.get(commission, 'effective_rate', commission.rate) !== commission.rate
        }
      })
    }

    return bc
  }

  render() {
    const { dispatch, premium, savedPremium, className, originalPremium, scheme } = this.props

    if (! premium) {
      return
    }

    const currency = savedPremium ? savedPremium.currency : _.get(premium, 'premium.currency');
    const premiumClassName = className ? className : 'premium'
    const modalName = this.props.id ? this.props.id : (scheme ? scheme.id : premium.scheme.id)
    const schemeName = scheme ? scheme.name : premium.scheme.name
    const taxRate = premium && premium.rates ? _.get(premium, 'rates.tax_rate', 20) : _.get(savedPremium, 'tax_rate', 20);

    let totalCommission = _.get(premium, 'rates.commission_rate', 0)
    if (savedPremium) {
      totalCommission = savedPremium.effective_commission_rate
    } else {
      totalCommission = parseFloat(_.get(premium, 'rates.effective_commission_rate', totalCommission)).toFixed(1)
    }
    let originalCommission = _.get(originalPremium, 'commission_rate')

    const commissions = (premium) => {
      let c = _.get(premium, 'commissions', [])
      if (Array.isArray(c)) {
        return c
      }

      let d = []
      Object.keys(c).map((key) => { d.push(c[key]) })
      return d
    }

    let breakdown = _.get(premium, 'breakdown', {})
    if (savedPremium && savedPremium.breakdown !== undefined && savedPremium.breakdown !== null) {
      breakdown = savedPremium.breakdown
    }

    return (
      <div>
        <span
          className={premiumClassName + ' premium-breakdown-button' + (this.props.block ? ' block-premium' : '')} onClick={() => dispatch(openModal(modalName)) }
        >
          {displayCurrency(savedPremium ? savedPremium.gross : premium.premium.gross, currency)}
        </span>
        {this.props.estimate && (
          <span className={'small' + (this.props.block ? ' block-premium' : '')}>* Estimated premium including IPT</span>
        )}
        {!this.props.estimate && this.props.showIpt && (
          <span className={'ipt-rate' + (this.props.block ? ' block-premium' : '')}>&nbsp;Including IPT at {taxRate}&#37;</span>
        )}
        { /* Rendering a specific Modal in case the Premium had Adjustments */ }
        {! _.isEmpty(originalPremium) ? (
          <Modal {...this.props} name={modalName} title="Premium Breakdown" close={true}>
            {isOwner() && (
              <div className="breakdown">
                <div className="row">
                  <div className="col-xs-12 premium-summary-container">
                    <h4>{schemeName}</h4>
                    <div className="row">
                      <div className="col-xs-6 text-right"/>
                      <div className="col-xs-2 text-right">
                        Original
                      </div>
                      <div className="col-xs-2 text-right">
                        Adjusted
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xs-6">
                        Net
                      </div>
                      <div className="col-xs-2 text-right">
                        {displayCurrency(originalPremium.net, currency)}
                      </div>
                      <div className="col-xs-2 text-right">
                        {displayCurrency(savedPremium ? savedPremium.net : premium.premium.net, currency)}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-6">
                        Additional
                      </div>
                      <div className="col-xs-2 text-right">
                        {displayCurrency(originalPremium.additional, currency)}
                      </div>
                      <div className="col-xs-2 text-right">
                        {displayCurrency(savedPremium ? savedPremium.additional : premium.premium.additional, currency)}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-6">
                        Medical
                      </div>
                      <div className="col-xs-2 text-right">
                        {displayCurrency(originalPremium.medical, currency)}
                      </div>
                      <div className="col-xs-2 text-right">
                        {displayCurrency(savedPremium ? savedPremium.medical : premium.premium.medical, currency)}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-6">
                        Discount
                      </div>
                      <div className="col-xs-2 text-right">
                        {displayCurrency(originalPremium.discount, currency)}
                      </div>
                      <div className="col-xs-2 text-right">
                        {displayCurrency(savedPremium ? savedPremium.discount : premium.premium.discount, currency)}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-6">
                        Fees
                      </div>
                      <div className="col-xs-2 text-right">
                        {displayCurrency(originalPremium.fees, currency)}
                      </div>
                      <div className="col-xs-2 text-right">
                        {displayCurrency(savedPremium ? savedPremium.fees : premium.premium.fees, currency)}
                      </div>
                    </div>

                    {this.state.showCommissions && _.get(premium, 'commissions') && (
                      <div>
                        {_.get(premium, 'commissions') && (
                          <div className="commission-breakdown">
                            { commissions(savedPremium ? savedPremium : premium).map((commission, index) => {
                              const type = commission.commission_type === 'owner' ? 'Axiom' : commission.commission_type
                              const orgCommission = _.get(commission, 'effective_rate') ? _.get(commission, 'effective_rate') : commission.rate
                              const originalCommission = commissions(originalPremium)[index]
                              return (
                                <div key={commission.organisation_id} className="row commission-insert">
                                  <div className="col-xs-6 commission-type">
                                    {type} Commission @ {(_.get(originalCommission, 'effective_rate', originalCommission.rate) * 100).toFixed(1)}&#37; / {(orgCommission * 100).toFixed(1)}&#37;
                                  </div>
                                  <div className="col-xs-2 text-right">
                                    {displayCurrency(originalCommission.amount, currency)}
                                  </div>
                                  <div className="col-xs-2 text-right">
                                    {displayCurrency(commission.amount, currency)}
                                  </div>
                                </div>
                              )}
                            )}
                          </div>
                        )}
                      </div>
                    )}

                    <div className="row">
                      <div className="col-xs-6">
                        Commission @ {originalCommission}&#37; / {totalCommission}&#37;
                      </div>
                      <div className="col-xs-2 text-right">
                        {displayCurrency(originalPremium.commission, currency)}
                      </div>

                      <div className="col-xs-2 text-right">
                        {displayCurrency(savedPremium ? savedPremium.commission : premium.premium.commission, currency)}
                      </div>
                      <div className="col-xs-1 text-right">
                        <i style={{ fontSize: 'small' }} className={'expand-commissions fa fa-' + (this.state.showCommissions ? 'minus-square' : 'plus-square')} onClick={()=> this.setState({ showCommissions: !this.state.showCommissions })}/>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-xs-6 sub-total">
                        Principal
                      </div>
                      <div className="col-xs-2 sub-total text-right">
                        {displayCurrency(originalPremium ? originalPremium.principal : originalPremium.premium.principal, currency)}
                      </div>
                      <div className="col-xs-2 sub-total text-right">
                        {displayCurrency(savedPremium ? savedPremium.principal : premium.premium.principal, currency)}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-6">
                        IPT @ {taxRate}&#37;
                      </div>
                      <div className="col-xs-2 text-right">
                        {displayCurrency(originalPremium ? originalPremium.tax : originalPremium.premium.tax, currency)}
                      </div>
                      <div className="col-xs-2 text-right">
                        {displayCurrency(savedPremium ? savedPremium.tax : premium.premium.tax, currency)}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-6">
                        Items Excluding Tax
                      </div>
                      <div className="col-xs-2 text-right">
                        {displayCurrency(originalPremium ? originalPremium.ex_tax : originalPremium.premium.ex_tax, currency)}
                      </div>
                      <div className="col-xs-2 text-right">
                        {displayCurrency(savedPremium ? savedPremium.ex_tax : premium.premium.ex_tax, currency)}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-6 sub-total">
                        Gross Premium
                      </div>
                      <div className="col-xs-2 sub-total text-right">
                        {displayCurrency(originalPremium ? originalPremium.gross : originalPremium.premium.gross, currency)}
                      </div>
                      <div className="col-xs-2 sub-total text-right">
                        {displayCurrency(savedPremium ? savedPremium.gross : premium.premium.gross, currency)}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-12 calculation-container">
                    <div className="row">
                      <div className="col-xs-2">
                        Formula
                      </div>
                      <div className="col-xs-9">
                        {savedPremium ? savedPremium.formula : premium.formula}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-2">
                        Calculation
                      </div>
                      <div className="col-xs-9">
                        {savedPremium ? savedPremium.calculation : premium.calculation}
                      </div>
                    </div>

                    {_.get(premium, 'errors.length', 0) > 0 && (
                      <div className="row">
                        <div className="col-xs-2">
                          Errors
                        </div>
                        <div className="col-xs-9">
                          <ul className="option-list">
                            {premium.errors.map((error, key) => {
                              return (
                                <li key={'error' + key}>{error}</li>
                              )
                            })}
                          </ul>
                        </div>
                      </div>
                    )}
                    {_.get(breakdown, 'categories') && (
                      <div className="row">
                        <div className="col-xs-2">
                          Categories
                        </div>
                        <div className="col-xs-9">
                          {breakdown.categories.map((category, key) => {
                            return (
                              <div key={'category' + key}>
                                <div className="col-xs-2 no-gutter">Calculation:</div>
                                <div className="col-xs-10">{category.calc}</div>
                                <div className="col-xs-2 no-gutter">Rates:</div>
                                <div className="col-xs-10">
                                  {category.rates.map((rate, rateKey) => {
                                    return (<span key={'rate' + rateKey}>{rate}<br/></span>)
                                  })}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    )}
                    {_.map(breakdown, (value, key) => {
                      const keysToIgnore = ['categories', 'rate_base', 'rate_screening', 'options']
                      const breakdownItem = _.get(premium, 'breakdown', {})[key]
                      if (keysToIgnore.indexOf(key) === -1) {
                        return (
                          <div key={'breakdown-row ' + key} className="row">
                            <div className="col-xs-2">
                              {displayName(key)}
                            </div>
                            <div className="col-xs-9">
                              <ul className="option-list">
                                {breakdownItem && Object.keys(breakdownItem).map(breakdownItemKey => (
                                  <li key={'breakdown-item ' + breakdownItemKey}>
                                    {breakdownItem[breakdownItemKey]}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )
                      }
                    })}
                    {_.get(breakdown, 'rate_base') && (
                      <div className="row">
                        <div className="col-xs-2">
                          Base Rate
                        </div>
                        <div className="col-xs-9">
                          <ul className="option-list">
                            {breakdown.rate_base.map((rate, key) => {
                              return (
                                <li key={'baseRate' + key}>{rate}</li>
                              )
                            })}
                          </ul>
                        </div>
                      </div>
                    )}
                    {_.get(breakdown, 'rate_screening') && (
                      <div className="row">
                        <div className="col-xs-2">
                          Screening Rate
                        </div>
                        <div className="col-xs-9">
                          <ul className="option-list">
                            {breakdown.rate_screening.map((rate, key) => {
                              return (
                                <li key={'screeningRate' + key}>{rate}</li>
                              )
                            })}
                          </ul>
                        </div>
                      </div>
                    )}

                    {_.get(breakdown, 'options') && (
                      <div className="row">
                        <div className="col-xs-2">
                          Options
                        </div>
                        <div className="col-xs-9">
                          <ul className="option-list">
                            {breakdown.options.map((option, key) => {
                              return (
                                <li key={'option' + key}>{option}</li>
                              )
                            })}
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {isBroker() && (
              <div className="row">
                <div className="col-xs-12 premium-summary-container">
                  <h4>{schemeName}</h4>
                  <div className="row">
                    <div className="col-xs-7 sub-total">
                      Principal
                    </div>
                    <div className="col-xs-4 sub-total text-right">
                      {displayCurrency(savedPremium ? savedPremium.principal : premium.premium.principal, currency)}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-7">
                      Including Medical
                    </div>
                    <div className="col-xs-4 text-right">
                      {displayCurrency(savedPremium ? _.get(savedPremium, 'gross_medical') : _.get(premium, 'premium.gross_medical'), currency)}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-7">
                      IPT @ {taxRate}&#37;
                    </div>
                    <div className="col-xs-4 text-right">
                      {displayCurrency(savedPremium ? savedPremium.tax : premium.premium.tax, currency)}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-7 sub-total">
                      Gross Premium
                    </div>
                    <div className="col-xs-4 sub-total text-right">
                      {displayCurrency(savedPremium ? savedPremium.gross : premium.premium.gross, currency)}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-7 sub-total">
                      Commission @ {this.brokerCommission(premium.commissions).rate}&#37; {this.brokerCommission(premium.commissions).adjusted ? '(adjusted)' : ''}
                    </div>
                    <div className="col-xs-4 sub-total text-right">
                      {displayCurrency(this.brokerCommission(premium.commissions).amount, currency)}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Modal>
        ) : ( <Modal {...this.props} name={modalName} title="Premium Breakdown" close={true}>
          {isOwner() && (
            <div className="breakdown">
              <div className="row">
                <div className="col-xs-12 premium-summary-container">
                  <h4>{schemeName}</h4>
                  <div className="row">
                    <div className="col-xs-7">
                      Net
                    </div>
                    <div className="col-xs-4 text-right">
                      {displayCurrency(savedPremium ? savedPremium.net : premium.premium.net, currency)}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-7">
                      Additional
                    </div>
                    <div className="col-xs-4 text-right">
                      {displayCurrency(savedPremium ? savedPremium.additional : premium.premium.additional, currency)}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-7">
                      Medical
                    </div>
                    <div className="col-xs-4 text-right">
                      {displayCurrency(savedPremium ? savedPremium.medical : premium.premium.medical, currency)}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-7">
                      Discount
                    </div>
                    <div className="col-xs-4 text-right">
                      {displayCurrency(savedPremium ? savedPremium.discount : premium.premium.discount, currency)}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-7">
                      Fees
                    </div>
                    <div className="col-xs-4 text-right">
                      {displayCurrency(savedPremium ? savedPremium.fees : premium.premium.fees, currency)}
                    </div>
                  </div>

                  {this.state.showCommissions && _.get(premium, 'commissions') && (
                    <div className="commission-breakdown">
                      { commissions(savedPremium ? savedPremium : premium).map((commission, index) => {
                        const type = commission.commission_type === 'owner' ? 'Axiom' : commission.commission_type
                        const orgCommission = _.get(commission, 'effective_rate') ? _.get(commission, 'effective_rate') : commission.rate
                        return (
                          <div key={commission.organisation_id} className="row">
                            <div className="col-xs-7 commission-type">
                              {type} Commission @ {(orgCommission * 100).toFixed(1)}&#37; {orgCommission !== commission.rate ? '(adjusted)' : ''}
                            </div>
                            <div className={'col-xs-2 text-right' + (index === premium.commissions.length -1 ? ' commission-amount-last' : '')}>
                              {displayCurrency(commission.amount, currency)}
                            </div>
                          </div>
                        )}
                      )}
                    </div>
                  )}

                  <div className="row">
                    <div className="col-xs-7">
                      Commission @ {totalCommission}&#37;
                    </div>
                    <div className="col-xs-4 text-right">
                      {displayCurrency(savedPremium ? savedPremium.commission : premium.premium.commission, currency)}
                    </div>
                    <div className="col-xs-1">
                      <i className={'expand-commissions fa fa-' + (this.state.showCommissions ? 'minus-square' : 'plus-square')} onClick={()=> this.setState({ showCommissions: !this.state.showCommissions })}/>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-xs-7 sub-total">
                      Principal
                    </div>
                    <div className="col-xs-4 sub-total text-right">
                      {displayCurrency(savedPremium ? savedPremium.principal : premium.premium.principal, currency)}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-7">
                      IPT @ {taxRate}&#37;
                    </div>
                    <div className="col-xs-4 text-right">
                      {displayCurrency(savedPremium ? savedPremium.tax : premium.premium.tax, currency)}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-7">
                      Items Excluding Tax
                    </div>
                    <div className="col-xs-4 text-right">
                      {displayCurrency(savedPremium ? savedPremium.ex_tax : premium.premium.ex_tax, currency)}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-7 sub-total">
                      Gross Premium
                    </div>
                    <div className="col-xs-4 sub-total text-right">
                      {displayCurrency(savedPremium ? savedPremium.gross : premium.premium.gross, currency)}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xs-12 calculation-container">
                  <div className="row">
                    <div className="col-xs-2">
                      Formula
                    </div>
                    <div className="col-xs-9">
                      {savedPremium ? savedPremium.formula : premium.formula}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-2">
                      Calculation
                    </div>
                    <div className="col-xs-9">
                      {savedPremium ? savedPremium.calculation : premium.calculation}
                    </div>
                  </div>

                  {_.get(premium, 'errors.length', 0) > 0 && (
                    <div className="row">
                      <div className="col-xs-2">
                        Errors
                      </div>
                      <div className="col-xs-9">
                        <ul className="option-list">
                          {premium.errors.map((error, key) => {
                            return (
                              <li key={'error' + key}>{error}</li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                  )}

                  {_.get(breakdown, 'categories') && (
                    <div className="row">
                      <div className="col-xs-2">
                        Categories
                      </div>
                      <div className="col-xs-9">
                        {breakdown.categories.map((category, key) => {
                          return (
                            <div key={'category' + key}>
                              <div className="col-xs-2 no-gutter">Calculation:</div>
                              <div className="col-xs-10">{category.calc}</div>
                              <div className="col-xs-2 no-gutter">Rates:</div>
                              <div className="col-xs-10">
                                {category.rates.map((rate, rateKey) => {
                                  return (<span key={'rate' + rateKey}>{rate}<br/></span>)
                                })}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )}
                  {_.map(breakdown, (value, key) => {
                    const keysToIgnore = ['categories', 'rate_base', 'rate_screening', 'options']
                    const breakdownItem = _.get(premium, 'breakdown', {})[key]
                    if (keysToIgnore.indexOf(key) === -1) {
                      return (
                        <div key={'breakdown-row ' + key} className="row">
                          <div className="col-xs-2">
                            {displayName(key)}
                          </div>
                          <div className="col-xs-9">
                            <ul className="option-list">
                              {breakdownItem && Object.keys(breakdownItem).map(breakdownItemKey => (
                                <li key={'breakdown-item ' + breakdownItemKey}>
                                  {breakdownItem[breakdownItemKey]}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      )
                    }
                  })}
                  {_.get(breakdown, 'rate_base') && (
                    <div className="row">
                      <div className="col-xs-2">
                        Base Rate
                      </div>
                      <div className="col-xs-9">
                        <ul className="option-list">
                          {breakdown.rate_base.map((rate, key) => {
                            return (
                              <li key={'baseRate' + key}>{rate}</li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                  )}

                  {_.get(breakdown, 'rate_screening') && (
                    <div className="row">
                      <div className="col-xs-2">
                        Screening Rate
                      </div>
                      <div className="col-xs-9">
                        <ul className="option-list">
                          {breakdown.rate_screening.map((rate, key) => {
                            return (
                              <li key={'screeningRate' + key}>{rate}</li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                  )}

                  {_.get(breakdown, 'options') && (
                    <div className="row">
                      <div className="col-xs-2">
                        Options
                      </div>
                      <div className="col-xs-9">
                        <ul className="option-list">
                          {breakdown.options.map((option, key) => {
                            return (
                              <li key={'option' + key}>{option}</li>
                            )
                          })}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {isBroker() && (
            <div className="row">
              <div className="col-xs-12 premium-summary-container">
                <h4>{schemeName}</h4>
                <div className="row">
                  <div className="col-xs-7 sub-total">
                    Principal
                  </div>
                  <div className="col-xs-4 sub-total text-right">
                    {displayCurrency(savedPremium ? savedPremium.principal : premium.premium.principal, currency)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-7">
                    Medical
                  </div>
                  <div className="col-xs-4 text-right">
                    {displayCurrency(savedPremium ? _.get(savedPremium, 'gross_medical') : _.get(premium, 'premium.gross_medical'), currency)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-7">
                    IPT @ {taxRate}&#37;
                  </div>
                  <div className="col-xs-4 text-right">
                    {displayCurrency(savedPremium ? savedPremium.tax : premium.premium.tax, currency)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-7 sub-total">
                    Gross Premium
                  </div>
                  <div className="col-xs-4 sub-total text-right">
                    {displayCurrency(savedPremium ? savedPremium.gross : premium.premium.gross, currency)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-7 sub-total">
                    Commission @ {this.brokerCommission(premium.commissions).rate}&#37; {this.brokerCommission(premium.commissions).adjusted ? '(adjusted)' : ''}
                  </div>
                  <div className="col-xs-4 sub-total text-right">
                    {displayCurrency(this.brokerCommission(premium.commissions).amount, currency)}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal>)
        }
      </div>
    )
  }
}

export default connect()(PremiumBreakdown)
